import {Project} from "../../../../../../models/project";
import {Quote} from "../../../../../../models/quote";
import {getLogoUrl} from "../../../../../../utils/image";
import {Link} from "react-router-dom";
import convertToDecimal from "../../../../../../utils/currencyConverter";
import QuoteStatusPlaceholder from "../../../../common/quote/status";
import React, {useState} from "react";
import ModalComponent from "../../../../../modal";
import ProviderQuoteShow from "../../show";
import {APP_URL} from "../../../../../../constants/global";

const ProviderProjectQuoteListItem = ({project, quote}: {project: Project, quote: Quote}) => {

    const [showModal, setShowModal] = useState(false);
    const [copied, setCopied] = useState(false)

    let timer: NodeJS.Timeout;
    function copyLink(){
        clearTimeout(timer);
        setCopied(true)
        navigator.clipboard.writeText(`${APP_URL}quote/${quote.uuid}`);
        timer = setTimeout(() => setCopied(false), 1500);
    }

    return <li className={'list-group-item'}>
        <div className="row align-items-center">
            <div className="col-auto">
                            <span className="avatar avatar-sm me-2">
                                 <img src={getLogoUrl(quote.organization)} alt={quote.organization.name} className="avatar-img rounded-circle" />
                             </span>
                <Link to={`/corporate/organization/${quote.organization.id}`} target={'_blank'}>
                    {quote.organization.name}
                </Link>
                <span className={"mx-3"}>|</span>
                <span>{quote.number}</span>
            </div>
            <div className="col-auto">
                            <span>
                                Montant: {convertToDecimal(quote.priceData.total_amount_excluding_tax, quote.priceData.currency)} HT
                            </span>
                <span className={"mx-3"}>|</span>
                <span>Commission: {convertToDecimal(quote.priceData.provider_fee, quote.priceData.currency)} ({quote.condition.fee}%)</span>
            </div>
            <div className="col">
                <QuoteStatusPlaceholder status={quote.status} />
            </div>
            {project.status === "Published" && <div className={"col-auto"}>
                <button className="btn btn-xxs text-info text-decoration-underline" onClick={() => setShowModal(true)}>
                    Afficher le devis <i className={'bi bi-arrow-right'}></i>
                </button>
            </div>}
        </div>
        {showModal && <ModalComponent
            size={"modal-lg"}
            title={`Détail du devis ${quote.number}`}
            show={showModal}
            onHide={() => setShowModal(false)}
            footerContent={<button className="btn btn-primary" onClick={() => copyLink()}>
                {copied ? <><i className={'bi bi-check'}></i> Lien copié</> : <><i className={'bi bi-link'}></i> Copier le lien de partage</>}
            </button>}
        >
            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>Prestataire</h6>
                    </div>
                    <div className="card-body">
                        <span className="avatar avatar-xxl me-2"><img src={getLogoUrl(quote.organization)} alt={quote.organization.name} className="avatar-img rounded-circle" /></span> <Link to={`/corporate/organization/${quote.organization.id}`}>{quote.organization.name}</Link>
                    </div>
                </div>
            </div>
            <ProviderQuoteShow quote={quote} />
        </ModalComponent>}
    </li>
}

export default ProviderProjectQuoteListItem;