import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import authSlice from "../../../../reducers/auth";

const OnBoardingLayout = () => {

    const user = useAppSelector(state => state.auth.user);
    const dispatch = useAppDispatch();

    if (!user){
        return <Navigate to={"/login"} replace />
    }

    if (user.activeAccount.type === "customer")
    {
        return <Navigate to={"/customer"} replace />
    }

    if (['Completed', 'Verified'].includes(user.activeAccount.organization.status)){
        return <Navigate to={"/corporate"} replace />
    }

    return <div className={'container mx-auto h-100'}>
        <div className="col-12 mb-3 border-bottom border-1 py-3">
            <div className="row align-items-center">
                <div className="col d-flex">
                    <img src={process.env.PUBLIC_URL + '/img/logo.png'} className={'logo-sm me-2'} alt=""/>
                    <div className="vr m-2"></div>
                    <p className={'mb-0 fw-semibold text-body-secondary'}>
                        Activez votre compte
                    </p>
                </div>
                <div className="col-auto">
                    <button className={'btn btn-xxs btn-dark'} onClick={() => dispatch(authSlice.actions.logout())}>
                        <i className="bi bi-toggle-off"></i> Deconnexion
                    </button>
                </div>
            </div>
        </div>
        <Outlet />
    </div>
}

export default OnBoardingLayout;