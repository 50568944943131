import React, {useEffect, useState} from "react";
import * as api from "../../../../../adapters/shop/product";
import * as models from "../../../../../models/product";
import Loader from "../../../../../utils/loader";
import {Country} from "../../../../../models/country";
import {Currency} from "../../../../../models/currency";
import ShopProductCard from "../../card";

const ShopProductSuggested = ({uuid, country, currency, categoryId}: {uuid: string, country: Country, currency: Currency, categoryId: number|null}) => {

    const [payload, setPayload] = useState<{count: number, rows: (models.Product & models.SellableProduct)[]}>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true);
        api.list(uuid, {query: "", order: "BestSeller", offset: 0, categoryId: categoryId, countryId: country!.id, currencyId: currency!.id})
            .then(resp => setPayload(resp.data))
            .finally(() => setLoading(false))
    }, [uuid, country, currency, categoryId])

    return <div className={"row"}>
        <div className="col-12">
            {loading && <div className={'col-12 py-10 text-center'}>
                <Loader />
            </div>}
            {!loading && <div className="row row-cols-1 row-cols-md-4">
                {payload.rows.map(r => <div className={'col'}>
                    <ShopProductCard product={r} uuid={uuid} currency={currency} key={r.id} />
                </div>)}
            </div>}
        </div>
    </div>
}

export default ShopProductSuggested;