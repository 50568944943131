import * as models from "../../../../../models/product";
import * as api from "../../../../../adapters/corporate/supplier/product/inventory";
import React, {useEffect, useState} from "react";
import Pagination from "../../../../../utils/pagination";
import Loader from "../../../../../utils/loader";
import InventoryAdd from "./add";
import ShippingAddressSelect from "../../../../../utils/shippingAddressSelect";

const SupplierProductInventoryList = ({product}: {product: models.Product & models.SellableProduct}) => {

    const [loading, setLoading] = useState(true)
    const [payload, setPayload] = useState<{count: number, rows: models.ProductInventories}>({count: 0, rows: []})
    const [fetchParams, setFetchParams] = useState<{choices: number[], address_id?: number, offset: number}>({choices: [], offset: 0})

    useEffect(() => {
        setLoading(true)
        api.list(product.id, fetchParams)
            .then(r => setPayload(r.data))
            .then(() => setLoading(false))
    }, [fetchParams, product])

    function handleUpsert(inventory: models.ProductInventory){

        const index = payload.rows.findIndex(r => r.id === inventory.id);

        if (index !== -1){
            const rows_copy = [...payload.rows];
            rows_copy[index] = inventory
            setPayload(prev => ({...prev, rows: rows_copy}));
        }else{
            setPayload(prev => ({count: prev.count + 1, rows: [inventory, ...prev.rows]}))
        }
    }

    function handleChoiceFilterChange(choice: models.ProductDeclinationChoice){
        if(fetchParams.choices.includes(choice.id)){
            setFetchParams(prev => ({...prev, offset: 0, choices: prev.choices.filter(pc => pc !== choice.id)}))
        }else{
            setFetchParams(prev => ({...prev, offset: 0, choices: [...prev.choices, choice.id]}))
        }
    }

    return <div className={"row h-100"}>
        <div style={{maxWidth: 300}} className="col-auto mb-3 border-end border-1 h-100">
            <div className="row">
                <div className="col-12 mb-3">
                    <ShippingAddressSelect handleChange={a => setFetchParams(prev => ({...prev, offset: 0, address_id: a}))} shipping={product.shipping} required={false} />
                </div>
                {product.declinations.filter(d => d.stockLinked).map(d => <div className={"col-12 mb-3"}>
                   <p className={'fw-semibold'}>{d.name}</p>
                    {d.choices.map(c => <div key={c.id} className="form-check">
                        <input name={`declination_${d.id}`}
                               disabled={loading}
                               onChange={() => handleChoiceFilterChange(c)}
                               className="form-check-input"
                               type="checkbox"
                               value={c.id}
                               id={`declination_${d.id}_choices_${c.id}`}
                        />
                        <label className="form-check-label" htmlFor={`declination_${d.id}_choices_${c.id}`}>
                            {c.value}
                        </label>
                    </div>)}
                </div>)}
            </div>
        </div>
        <div className="col mb-3">
            <div className="col-12 mb-3 text-center">
                <div className="row">
                    <div className="col">
                        {loading ? <Loader /> : <>{payload.count} résultats</>}
                    </div>
                    <div className="col-auto">
                        <button className={'btn btn-dark'} data-bs-toggle={"offcanvas"} data-bs-target={"#offcanvasInventoryAdd"}>
                            <i className={'bi bi-plus-circle'}></i> Ajouter
                        </button>
                    </div>
                </div>
            </div>
            <div className="table-responsive">
                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>
                            Traitement
                        </th>
                        {product.declinations.filter(d => d.stockLinked).map(d => <th>{d.name}</th>)}
                        <th>
                            Disponible
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {payload.rows.map(r => <tr>
                        <td>
                            {r.address.city}
                        </td>
                        {product.declinations.filter(d => d.stockLinked).map(d => {
                            const choice = r.declinationChoices.find(c => c.declination.id === d.id);
                            return <th>{choice?.value || <i className={'bi bi-x'}></i>}</th>
                        })}
                        <td>
                            {r.quantity}
                        </td>
                    </tr>)}
                    </tbody>
                </table>
            </div>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        </div>
        <InventoryAdd product={product} handleSuccess={handleUpsert} />
    </div>
}

export default SupplierProductInventoryList;