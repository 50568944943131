import React, {useEffect, useState} from "react";
import {OrderItem, OrderItems} from "../../../../../models/order";
import * as api from "../../../../../adapters/customer/order/item"
import SearchInput from "../../../../../utils/searchInput";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import CustomerReturnAddItem from "./item";

const CustomerReturnAddItems = ({handleSelectItem}: {handleSelectItem: (item: OrderItem) => void}) => {

    const [loading, setLoading] = useState(true);
    const [payload, setPayload] = useState<{count: number, rows: OrderItems}>({count: 0, rows: []});
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string}>({offset: 0, query: ""});
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        api.list({...fetchParams, order_status: "Completed"})
            .then(r => setPayload(r.data))
            .catch(error => setError(error))
            .finally(() => setLoading(false))
    }, [])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams(prev => ({...prev, query: e.target.value, offset: 0})), 1000)
    }

    return <div className={"col-12 mb-3"}>
        <div className="card border border-1">
            <div className="card-header">
                <h6>
                    Avec quelle article avez vous besoin d'aide ?
                </h6>
            </div>
            <div className="card-body">
                <div className="col-12 mb-3 flex-grow-0">
                    <div className="row align-items-center">
                        <div className="col">
                            <SearchInput placeholder={"Rechercher un article commandé..."} onChange={handleQueryChange} size={"sm"} />
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-3 text-center">
                    {loading ? <Loader /> : <>{payload.count} résultats</>}
                </div>
                {error && <ErrorCard error={error} />}
                <div className="row row-cols-1 row-cols-md-4">
                    {payload.rows.map(item => <CustomerReturnAddItem item={item} selectItem={(item) => handleSelectItem(item)}/>)}
                </div>
            </div>
        </div>
    </div>
}

export default CustomerReturnAddItems;