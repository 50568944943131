import {getLogoUrl} from "../../../../../../utils/image";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import {Application} from "../../../../../../models/application";
import * as api from "../../../../../../adapters/corporate/supplier/application"
import ErrorCard from "../../../../../error";

const SupplierApplicationListItem = ({item}: {item: Application}) => {

    const [_item, setItem] = useState(item)
    const [error, setError] = useState(null)
    const [updating, setUpdating] = useState(false)

    function handleStatusChange(status: 'Accepted' | 'Refused') {
        setUpdating(true)
        api.update(item.id, {status: status})
            .then(r => setItem(r.data))
            .catch(e => setError(e))
            .finally(() => setUpdating(false))
    }

    return <div className="col-12 mb-3">
        <div className="card border border-1">
            <div className="card-body">
                <div className="row align-items-center">
                    <div className="col-auto">
                        <div className="avatar avatar-lg">
                            <img src={getLogoUrl(_item.organization)} alt="" className="avatar-img rounded-circle"/>
                        </div>
                    </div>
                    <div className="col">
                        <Link to={`/corporate/organization/${_item.organization.id}`} className={'mb-1 h6 text-info'} target={'_blank'}>{_item.organization.name} <i className={'bi bi-arrow-right'}></i></Link>
                        <p className={"mb-0"}>{_item.assignment.name}</p>
                    </div>
                    <div className="col-auto">
                        {_item.status === "Pending" && <span className={'badge bg-warning'}><i className={'bi bi-clock'}></i> En attente</span>}
                        {_item.status === "Accepted" && <span className={'badge bg-success'}><i className={'bi bi-check'}></i> Intéressé</span>}
                        {_item.status === "Refused" && <span className={'badge bg-danger'}><i className={'bi bi-x'}></i> Pas Intéressé</span>}
                    </div>
                </div>
                {_item.status === 'Pending' && <>
                    <div className="col-12 mb-3">
                        <p className={'fw-semibold mb-1'}>Message</p>
                        <p className={'text-muted mb-1'} style={{whiteSpace: "pre-wrap"}}>{_item.message}</p>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-light btn-xxs">
                                    <i className={'bi bi-chat'}></i> Contacter
                                </button>
                            </div>
                            <div className="col-auto">
                                <button className="btn btn-light btn-xxs" disabled={updating} onClick={() => handleStatusChange("Accepted")}>
                                    <i className={'bi bi-check text-success'}></i> Accepter
                                </button>
                            </div>
                            <div className="col-auto">
                                <button className="btn btn-light btn-xxs" disabled={updating} onClick={() => handleStatusChange("Refused")}>
                                    <i className={'bi bi-x text-danger'}></i> Refuser
                                </button>
                            </div>
                        </div>
                    </div>
                </>}
                {error && <ErrorCard error={error} />}
            </div>
        </div>
    </div>
}

export default SupplierApplicationListItem;