import React, {useState} from "react";
import SupplierSearchFilter from "./filter";
import SupplierSearchList from "./list";
import {Specialisations} from "../../../../models/specialisation";
import {Sectors} from "../../../../models/sector";
import SearchInput from "../../../../utils/searchInput";
import OffcanvasComponent from "../../../offcanvas";

const SupplierSearch = () => {

    const [sectors, setSectors] = useState<Sectors>([])
    const [specialisations, setSpecialisations] = useState<Specialisations>([])
    const [query, setQuery] = useState("")
    const [sort, setSort] = useState<'ranking'|'trending'|'latest'>("ranking")
    const [showFilterOffcanvas, setShowFilterOffcanvas] = useState(false)

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setQuery( e.target.value), 1000)
    }

    return <div className={'container-fluid'}>
        <div className="row">
            <div className="col-12 col-md-4 col-lg-3 d-none d-md-block">
                <div className="card border border-1 h-100">
                    <div className="card-header">
                        <h6>Filtrer</h6>
                    </div>
                    <div className="card-body">
                        <SupplierSearchFilter sort={sort} setSort={setSort} specialisations={specialisations} setSpecialisations={setSpecialisations} sectors={sectors} setSectors={setSectors} />
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-8 col-lg-9">
                <div className="row">
                    <div className="col mb-3">
                        <SearchInput placeholder={"Rechercher..."} size={"sm"} onChange={handleQueryChange} />
                    </div>
                    <div className="col-auto mb-3 d-block d-md-none">
                        <button className={'btn btn-xs rounded btn-light'} onClick={() => setShowFilterOffcanvas(true)}>
                            <i className="bi bi-sliders"></i>
                        </button>
                    </div>
                </div>
                <SupplierSearchList sectors={sectors} specialisations={specialisations} query={query} sort={sort} count={20} />
            </div>
        </div>
        <OffcanvasComponent title={"Filtrer"} show={showFilterOffcanvas} onHide={() => setShowFilterOffcanvas(false)} footerContent={<button className={'btn btn-light w-100'} onClick={() => setShowFilterOffcanvas(false)}>
            Terminer
        </button>}>
            <SupplierSearchFilter sort={sort} setSort={setSort} specialisations={specialisations} setSpecialisations={setSpecialisations} sectors={sectors} setSectors={setSectors}   />
        </OffcanvasComponent>
    </div>
}

export default SupplierSearch;