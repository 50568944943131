import {useAppSelector} from "../../../../hooks";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import React from "react";

const AppSupplierBl = () => {

    const user = useAppSelector(state => state.auth.user);
    const _location = useLocation();

    if (!user){
        return <Navigate to={'/login?redirect=' + encodeURIComponent(_location.pathname)} />
    }

    if (user.activeAccount.type === "customer"){
        return <Navigate to={'/customer'} />
    }

    if (user.activeAccount.organization.type === "provider"){
        return <Navigate to={'/corporate'} />
    }

    return <Outlet />
}

export default AppSupplierBl