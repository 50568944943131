import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import * as models from "../../../../../models/order";
import * as api from "../../../../../adapters/corporate/supplier/order";
import Loader from "../../../../../utils/loader";
import {S3_BUCKET_URL, S3_ORGANIZATION_BUCKET} from "../../../../../constants/global";
import OrderStatusDropdown from "../status/dropdown";
import convertToDecimal from "../../../../../utils/currencyConverter";
import SupplierParcelList from "../../parcel/list";
import CommonTransferList from "../../../common/transfer/list";
import CorporateCommonOrderItemList from "../../../common/order/item/list";
import CorporateInvoiceDownload from "../../../common/invoice/download";
import SupplierOrderRefundList from "../refund/list";
import ErrorCard from "../../../../error";

const SupplierOrderShow = () => {

    const params = useParams();
    const [order, setOrder] = useState<models.Order>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        api.show(Number(params.id))
            .then(r => setOrder(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [params.id])

    if (loading){
        return <div className={'container-fluid py-10 text-center'}>
            <Loader />
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!order){
        return <div className={'container-fluid py-10 text-center'}>
            <span className={'display-6'}>
                <i className={"bi bi-search"}></i> Commande non trouvé
            </span>
        </div>
    }

    return <div className={'container-fluid'}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row">
                <div className="col">
                    <ol className="breadcrumb mb-3 fs-xs">
                        <li className="breadcrumb-item">
                            <Link to="/corporate/supplier/order">Commande</Link>
                        </li>
                        <li className="breadcrumb-item">
                            {order.number}
                        </li>
                    </ol>
                </div>
                <div className="col-auto">
                    <OrderStatusDropdown setOrder={setOrder} order={order} />
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>
                            Informations
                        </h6>
                    </div>
                    <div className="card-body">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>
                                        N° de commande
                                    </th>
                                    <td>
                                        {order.number}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Date
                                    </th>
                                    <td>
                                        {new Date(order.checkout.orderedAt).toLocaleString()}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Vendeur
                                    </th>
                                    <td>
                                        <div className="avatar avatar-xs me-2">
                                            <img src={`${S3_BUCKET_URL}${S3_ORGANIZATION_BUCKET}${order.partnership.provider.id}/logo/${order.partnership.provider.logoName}`} alt={order.partnership.provider.name} className="avatar-img rounded-circle" />
                                        </div>
                                        <span>
                                            {order.partnership.provider.name}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h6>Récapitulatif de la commande</h6>
                            </div>
                            <div className="col-auto">
                                {order.invoice && <CorporateInvoiceDownload invoice={order.invoice} />}
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <table className={'table'}>
                            <tbody>
                            <tr>
                                <th>Total HT</th>
                                <td>{convertToDecimal(order.priceData.amount_excluding_tax, order.currency)}</td>
                            </tr>
                            <tr>
                                <th>Tax</th>
                                <td>{convertToDecimal(order.priceData.tax_amount, order.currency)}</td>
                            </tr>
                            <tr>
                                <th>Total TTC</th>
                                <td>{convertToDecimal(order.priceData.amount_including_tax, order.currency)}</td>
                            </tr>
                            <tr>
                                <th>Remboursé</th>
                                <td>{convertToDecimal(order.priceData.refunded_amount, order.currency)}</td>
                            </tr>
                            <tr>
                                <th>Montant Net</th>
                                <td>{convertToDecimal(order.priceData.net_amount, order.currency)}</td>
                            </tr>
                            <tr>
                                <th>Frais de paiement <Link to={'https://stripe.com/fr/pricing'} target={"_blank"}>stripe <i className="bi bi-box-arrow-up-right"></i></Link></th>
                                <td>{convertToDecimal(order.feeData.payment, order.currency)}</td>
                            </tr>
                            <tr>
                                <th>Comission vendeur</th>
                                <td>{convertToDecimal(order.feeData.provider, order.currency)}</td>
                            </tr>
                            <tr>
                                <th>Frais de plateforme</th>
                                <td>{convertToDecimal(order.feeData.platform, order.currency)}</td>
                            </tr>
                            <tr>
                                <th>Montant après frais et commission net</th>
                                <td>{convertToDecimal(order.feeData.supplier, order.currency)}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-md-6 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>
                            Addresse de facturation
                        </h6>
                    </div>
                    <div className="card-body">
                        {order.billing && <table className={'table table-borderless'}>
                            <tbody>
                            <tr>
                                <th>Pays</th>
                                <td>{order.billing.country.name}</td>
                            </tr>
                            <tr>
                                <th>Adresse</th>
                                <td>{order.billing.line1}</td>
                            </tr>
                            <tr>
                                <th>Ville</th>
                                <td>{order.billing.city}</td>
                            </tr>
                            <tr>
                                <th>Code postal</th>
                                <td>{order.billing.postalCode}</td>
                            </tr>
                            </tbody>
                        </table>}
                    </div>
                </div>
            </div>
            <div className="col-md-6 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>
                            Addresse de livraison
                        </h6>
                    </div>
                    <div className="card-body">
                        {order.shipping ? <table className={'table table-borderless'}>
                            <tbody>
                            <tr>
                                <th>Pays</th>
                                <td>{order.shipping.country.name}</td>
                            </tr>
                            <tr>
                                <th>Adresse</th>
                                <td>{order.shipping.line1}</td>
                            </tr>
                            <tr>
                                <th>Ville</th>
                                <td>{order.shipping.city}</td>
                            </tr>
                            <tr>
                                <th>Code postal</th>
                                <td>{order.shipping.postalCode}</td>
                            </tr>
                            </tbody>
                        </table> : <p className={'text-muted'}>Commande non livrable</p>}
                    </div>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>Transfers</h6>
                    </div>
                    <div className="card-body">
                        <CommonTransferList order={order} />
                    </div>
                </div>
            </div>
            <div className="col-12 mb-3">
                <SupplierOrderRefundList order={order} />
            </div>
            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>
                            Articles
                        </h6>
                    </div>
                    <div className="card-body">
                        <CorporateCommonOrderItemList order={order} />
                    </div>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Colis
                    </h6>
                </div>
                <div className="card-body">
                    <SupplierParcelList order={order} />
                </div>
            </div>
        </div>
    </div>
}

export default SupplierOrderShow;