import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import authSlice from "../../../../reducers/auth";
import OnBoardingAccount from "./account";
import {Link, Navigate} from "react-router-dom";
import OnBoardingOrganization from "./organization";
import OnBoardingInformation from "./information";
import OnBoardingAddress from "./address";
import OnBoardingVerification from "./verification";
import * as api from "../../../../adapters/corporate/common/onboarding";
import Loader from "../../../../utils/loader";
import OnBoardingBio from "./bio";
import ErrorCard from "../../../error";
import {CorporateAccount} from "../../../../models/account";
import OnBoardingType from "./type";

const OnBoarding = ({step}: {step?: "type"|"account"|"organization"|"information"|"bio"|"address"|"verification"}) => {

    const account = useAppSelector(state => state.auth.user?.activeAccount) as CorporateAccount;
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null)

    useEffect(() => {
        api.getStatus()
            .then(r => dispatch(authSlice.actions.editAccountOrganizationStatus(r.data)))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [])

    if (loading){
        return <div className={'container mx-auto'}>
            <div className="col-12 py-10 d-flex align-items-center">
                <div className="mx-auto">
                    <Loader />
                </div>
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!step){
        return <Navigate to={'/onboarding/type'} />
    }

    return <>
        <div className="row">
            <div className="col-md-3 py-5 border-end border-1">
                <ul className="list-unstyled position-sticky top-0">
                    <Link className={"d-flex align-items-center mb-3 cursor-pointer"} to={"/onboarding/type"}>
                        <div className={"btn btn-xxs me-2"}>
                            {isTypeCompleted(account) ? <i className={"bi bi-check-circle text-success"}></i> : <i className={"bi bi-circle"}></i>}
                        </div>
                        <span className={(step === "type" ? " text-primary " : " text-body-secondary ")}>
                            Type de compte
                        </span>
                    </Link>
                    <Link className={"d-flex align-items-center mb-3 cursor-pointer"} to={"/onboarding/account"}>
                        <div className={"btn btn-xxs me-2"}>
                            {isAccountCompleted(account) ? <i className={"bi bi-check-circle text-success"}></i> : <i className={"bi bi-circle"}></i>}
                        </div>
                        <span className={(step === "account" ? " text-primary " : " text-body-secondary ")}>
                            Votre profile
                        </span>
                    </Link>
                    <Link className={"d-flex align-items-center mb-3 cursor-pointer"} to={"/onboarding/information"}>
                        <div className={"btn btn-xxs me-2"}>
                            {isOrganizationInformationCompleted(account) ? <i className={"bi bi-check-circle text-success"}></i> : <i className={"bi bi-circle"}></i>}
                        </div>
                        <span className={(step === "information" ? " text-primary " : " text-body-secondary ")}>
                            Votre activité
                        </span>
                    </Link>
                    <Link className={"d-flex align-items-center mb-3 cursor-pointer"} to={"/onboarding/organization"}>
                        <div className={"btn btn-xxs me-2"}>
                            {isOrganizationCompleted(account) ? <i className={"bi bi-check-circle text-success"}></i> : <i className={"bi bi-circle"}></i>}
                        </div>
                        <span className={(step === "organization" ? " text-primary " : " text-body-secondary ")}>
                            Votre entreprise
                        </span>
                    </Link>
                    <Link className={"d-flex align-items-center mb-3 cursor-pointer"} to={"/onboarding/bio"}>
                        <div className={"btn btn-xxs me-2"}>
                            {isOrganizationInformationCompleted(account) ? <i className={"bi bi-check-circle text-success"}></i> : <i className={"bi bi-circle"}></i>}
                        </div>
                        <span className={(step === "bio" ? " text-primary " : " text-body-secondary ")}>
                            A propos
                        </span>
                    </Link>
                    <Link className={"d-flex align-items-center mb-3 cursor-pointer"} to={"/onboarding/address"}>
                        <div className={"btn btn-xxs me-2"}>
                            {isOrganizationAddressCompleted(account) ? <i className={"bi bi-check-circle text-success"}></i> : <i className={"bi bi-circle"}></i>}
                        </div>
                        <span className={(step === "address" ? " text-primary " : " text-body-secondary ")}>
                            Votre localisation
                        </span>
                    </Link>
                </ul>
            </div>
            <div className="col-md-8">
                {step === "type" && <OnBoardingType />}
                {step === "account" && <OnBoardingAccount />}
                {step === "organization" && <OnBoardingOrganization />}
                {step === "information" && <OnBoardingInformation />}
                {step === "bio" && <OnBoardingBio />}
                {step === "address" && <OnBoardingAddress />}
                {step === "verification" && <OnBoardingVerification />}
            </div>
        </div>
    </>

    function isTypeCompleted(account: CorporateAccount){
        return !!account.organization.type;
    }

    function isAccountCompleted(account: CorporateAccount){
        return account.firstname && account.lastname;
    }

    function isOrganizationCompleted(account: CorporateAccount){
        let organization = account.organization;

        if (organization.type)
        {
            switch (organization.type){
                case "provider":
                    return organization.country && organization.name && organization.size && organization.sectors.length && organization.specialisations.length;
                case "supplier":
                    return organization.country && organization.name && organization.size && organization.sectors.length;
            }

        }

        return false;
    }

    function isOrganizationInformationCompleted(account: CorporateAccount){
        let organization = account.organization;

        if (organization.information){
            switch (organization.information.type){
                case "company":
                    return organization.information.name && organization.information.type && organization.information.email && organization.information.code
                case "individual":
                    return organization.information.type && organization.information.email;
                default:
                    return false
            }
        }

        return false;
    }

    function isOrganizationAddressCompleted(account: CorporateAccount){
        let organization = account.organization;

        if (organization.billing){
            return organization.billing.postalCode && organization.billing.city
        }

        return false;
    }
}

export default OnBoarding;



