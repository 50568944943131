import {QuoteRow} from "../../../../../../models/quote";
import convertToDecimal from "../../../../../../utils/currencyConverter";
import React from "react";

const ProviderQuoteRowShow = ({row}: {row: QuoteRow}) => {

    const RowName = () => {

        switch (row.type){
            case "custom":
                return <span>
                    {row.name}
                </span>
        }

        return <></>
    }

    const RowDescription = () => {

        switch (row.type){
            case "custom":
                return <span>{row.description}</span>
        }

        return <></>
    }

    const RowUnit = () => {

        switch (row.type){
            case "custom":
                return <span>{row.unit?.label || ""}</span>
        }

        return <></>
    }

    return <tr>
        <td>
            <RowName />
        </td>
        <td>
            <RowDescription />
        </td>
        <td>
            {convertToDecimal(row.priceData.unit_amount_excluding_tax, row.priceData.currency)}
        </td>
        <td>
            {row.priceData.tax_rate ? <>
                {row.priceData.tax_rate.percentage}%
                <div className="clearfix"></div>
                <span className={"small text-muted"}>{row.priceData.tax_rate.name}</span>
            </> : "N/A"}
        </td>
        <td>
            {convertToDecimal(row.priceData.unit_amount_including_tax, row.priceData.currency)}
        </td>
        <td>
            {row.quantity} <RowUnit />
        </td>
        <td>
            {convertToDecimal(row.priceData.total_amount_including_tax, row.priceData.currency)}
        </td>
    </tr>
}

export default ProviderQuoteRowShow;