import * as api from "../../../../../../adapters/corporate/common/order/item"
import {useEffect, useState} from "react";
import {OrderItems, Order} from "../../../../../../models/order";
import Loader from "../../../../../../utils/loader";
import Pagination from "../../../../../../utils/pagination";
import convertToDecimal from "../../../../../../utils/currencyConverter";
import ErrorCard from "../../../../../error";

const CorporateCommonOrderItemList = ({order}: {order: Order}) => {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const [payload, setPayload] = useState<{count: number, rows: OrderItems}>({count: 0, rows: []})
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string}>({offset: 0, query: ""})

    useEffect(() => {
        setLoading(true)
        api.list({...fetchParams, order_id: order.id})
            .then(r => setPayload(r.data))
            .catch(error => setError(error))
            .finally(() => setLoading(false))
    }, [order, fetchParams])

    return (
        <>
            {error && <ErrorCard error={error} />}
            <table className={"table"}>
                <thead>
                <tr>
                    <th>
                        Produit
                    </th>
                    <th>
                        Description
                    </th>
                    <th>
                        Prix unité HT
                    </th>
                    <th>
                        Tax
                    </th>
                    <th>
                        Prix unité TTC
                    </th>
                    <th>
                        Quantité
                    </th>
                    <th>
                        Prix total TTC
                    </th>
                </tr>
                </thead>
                <tbody>
                {loading ? <tr>
                    <td colSpan={6}>
                        <Loader /> chargement en cours...
                    </td>
                </tr> : (payload.rows.length ? payload.rows.map(r => <tr>
                    <td>
                        {r.name}
                    </td>
                    <td>
                        {r.description}
                    </td>
                    <td>
                        {convertToDecimal(r.priceData.unit_amount_excluding_tax, r.priceData.currency)}
                    </td>
                    <td>
                        {r.priceData.tax_rate?.percentage || 0}%
                    </td>
                    <td>
                        {convertToDecimal(r.priceData.unit_amount_including_tax, r.priceData.currency)}
                    </td>
                    <td>
                        {r.quantity} {r.unit?.label}
                    </td>
                    <td>
                        {convertToDecimal(r.priceData.total_amount_including_tax, r.priceData.currency)}
                    </td>
                </tr>) : <tr>
                    <td colSpan={6}>
                    <span className="text-muted">
                        <i className={"bi bi-search"}></i> Aucun résultat
                    </span>
                    </td>
                </tr>)}

                </tbody>
            </table>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        </>
    )

}

export default CorporateCommonOrderItemList;