import React from "react";
import {useAppSelector} from "../../../../../../hooks";
import ProductDimensionAdd from "../add";
import ProductDimensionEdit from "../edit";
import * as models from "../../../../../../models/product";

const SupplierProductDimensionShow = ({ product, setProduct }: { product: models.Product & models.SellableProduct, setProduct: React.Dispatch<any> }) => {

    return <>
        <div className="col-md-12 mb-3" id={"dimension"}>
            <div className="bg-body-tertiary p-3 h-100">
                <h6>
                    <i className={'bi bi-box'}></i> Dimensions
                </h6>
                <div className="col-12 mb-3">
                    {product.dimension ? <button type={"button"} className={'btn text-decoration-underline'}
                                                 data-bs-toggle="offcanvas"
                                                 data-bs-target="#offcanvasSupplierProductDimensionEdit"
                                                 aria-controls="offcanvasProductDimensionEdit"><i
                            className={'bi bi-pencil'}></i> Mettre à jour </button> :
                        <button type={"button"} className={'btn text-decoration-underline'}
                                data-bs-toggle="offcanvas" data-bs-target="#offcanvasSupplierProductDimensionAdd"
                                aria-controls="offcanvasSupplierProductDimensionAdd"><i
                            className={'bi bi-pencil'}></i> Mettre à jour </button>}
                </div>
                <div className="col-12">
                    {product.dimension && <div className={'row row-cols-2'}>
                        <div className="col d-flex flex-column">
                            <div className="form-text">
                                Hauteur
                            </div>
                            <span>
                                {product.dimension.height} cm
                            </span>
                        </div>
                        <div className="col d-flex flex-column">
                            <div className="form-text">
                                Largeur
                            </div>
                            <span>
                                {product.dimension.width} cm
                            </span>
                        </div>
                        <div className="col d-flex flex-column">
                            <div className="form-text">
                                Profondeur
                            </div>
                            <span>
                                {product.dimension.depth} cm
                            </span>
                        </div>
                        <div className="col d-flex flex-column">
                            <div className="form-text">
                                Longueur
                            </div>
                            <span>
                                {product.dimension.length} cm
                            </span>
                        </div>
                        <div className="col d-flex flex-column">
                            <div className="form-text">
                                Nombre de produits par colis
                            </div>
                            <span>
                                {product.dimension.count}
                            </span>
                        </div>
                        <div className="col d-flex flex-column">
                            <div className="form-text">
                                Poids
                            </div>
                            <span>
                                {product.dimension.weight} g
                            </span>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
        <ProductDimensionAdd key={product.id} product={product} setProduct={setProduct} />
        <ProductDimensionEdit key={product.id} product={product} setProduct={setProduct} />
    </>
}

export default SupplierProductDimensionShow;