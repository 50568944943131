import React, {useEffect, useState} from "react";
import ChatShowMessageAdd from "./message/add";
import * as chatApi from "../../../../../adapters/corporate/common/chat";
import * as models from "../../../../../models/chat";
import {useParams} from "react-router-dom";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import ChatMessageList from "./message/list";
import ChatShowHeader from "./header";

const ChatShow = () => {

    const {id} = useParams();
    const [chat, setChat] = useState<models.Chat>()

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(id){
            setLoading(true)
            chatApi.show(Number(id))
                .then(r => setChat(r.data))
                .catch(e => setError(e))
                .finally(() => setLoading(false))
        }
    }, [id])

    if (!id){
        return <div className={"col-md-8 h-100 px-0" + (!id ? " d-none d-md-block " : "")}>
            <div className="col-12 py-10 text-center">
                <h6 className={"text-muted"}>
                    <i className={'bi bi-chat'}></i> Séléctionnez une conversation.
                </h6>
            </div>
        </div>
    }

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!chat){
        return <></>
    }

    return <div className={'col-md-8 h-100 px-0'}>
        <div className="card h-100">
            <ChatShowHeader chat={chat} />
            <ChatMessageList chat={chat} />
        </div>
    </div>
}

export default ChatShow;