import React from "react";
import ProviderProductList from "../product/list";
import ProviderBundleList from "../bundle/list";
import ProviderPackList from "../pack/list";
import {Link, Navigate} from "react-router-dom";

const ProviderCatalog = ({tab}: {tab?: "product"|"bundle"|"pack"}) => {

    const Content = () => {
        switch (tab){
            case "product":
                return <ProviderProductList />
            case "pack":
                return <ProviderPackList />
            case "bundle":
                return <ProviderBundleList />
            default:
                return <Navigate to={"/corporate/provider/catalog/product"} />
        }
    }

    return <div className={'container'}>
        <div className="col-12 mb-3">
            <div className="mb-2 mb-lg-0 border-bottom-lg">
                <nav className="nav nav-tabs nav-overflow fs-xs border-bottom border-bottom-lg-0">
                    <Link to={'/corporate/provider/catalog/product'} className={"nav-link text-uppercase" + (tab === 'product' ? " active " : "")}>
                        Produits
                    </Link>
                    <Link to={'/corporate/provider/catalog/pack'} className={"nav-link text-uppercase" + (tab === 'pack' ? " active " : "")}>
                        Packages
                    </Link>
                    <Link to={'/corporate/provider/catalog/bundle'} className={"nav-link text-uppercase" + (tab === "bundle" ? " active " : "")}>
                        Grilles tarifaires
                    </Link>
                </nav>
            </div>
        </div>
        <Content />
    </div>
}

export default ProviderCatalog;