import React, {useState} from "react";
import {Link} from "react-router-dom";
import CorporateSupplierNavSales from "./sales";

const CorporateSupplierNav = () => {

    const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>({});

    const toggleMenu = (menu: string) => {
        setOpenMenus((prev) => ({ ...prev, [menu]: !prev[menu] }));
    };

    return <div id={"collapseNav"} className="sticky-top pt-3 collapse d-md-block">
        <nav className={'nav-top'}>
            <ul className={'list-unstyled'}>
                <li>
                    <Link to={'/'} className={'nav-link'}>
                        <i className="bi bi-house"></i> Accueil
                    </Link>
                </li>
                <li>
                    <Link to={'/corporate/community'} className={'nav-link'}>
                        <i className="bi bi-rss"></i> Communauté
                    </Link>
                </li>
                <li>
                    <Link to={'/corporate/supplier/search'} className={'nav-link'}>
                        <i className="bi bi-search"></i> Rechercher
                    </Link>
                </li>
                <li>
                    <Link to={'/corporate/partnership'} className="nav-link">
                        <i className={'bi bi-person-check'}></i> Partenaires
                    </Link>
                </li>
                <li>
                    <Link to={'/corporate/supplier/fee'} className="nav-link">
                        <i className={'bi bi-cash-coin'}></i> Commissions
                    </Link>
                </li>
                <li>
                    <Link to={'/corporate/supplier/assignment'} className="nav-link">
                        <i className="bi bi-card-heading"></i>  Annonces
                    </Link>
                </li>
                <li>
                    <span className="cursor-pointer nav-link dropdown-toggle" onClick={() => toggleMenu('contributor')}>
                        <i className="bi bi-person-raised-hand"></i> Apports d'affaires
                    </span>
                    <div className={`collapse ${openMenus['contributor'] ? 'show' : ''}`}>
                        <ul className={"list-unstyled"}>
                            <li>
                                <Link to={'/corporate/supplier/touch'} className="nav-link">
                                    Mise en relation
                                </Link>
                            </li>
                            <li>
                                <Link to={'/corporate/supplier/project'} className="nav-link">
                                    Appels d'offres
                                </Link>
                            </li>
                            <li>
                                <Link to={'/corporate/supplier/quote'} className="nav-link">
                                    Devis
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <Link to={'/corporate/supplier/catalog'} className="nav-link">
                        <i className={'bi bi-book'}></i> Catalogue
                    </Link>
                </li>
                <li>
                    <Link to={'/corporate/supplier/affiliation'} className="nav-link">
                        <i className={'bi bi-link'}></i> Affiliations
                    </Link>
                </li>
                <li>
                    <span className="cursor-pointer nav-link dropdown-toggle" onClick={() => toggleMenu('commerce')}>
                        <i className="bi bi-cart"></i> Commerce
                    </span>
                    <div className={`collapse ${openMenus['commerce'] ? 'show' : ''}`}>
                        <ul className={"list-unstyled"}>
                            <li>
                                <Link to={'/corporate/customer'} className="nav-link">
                                    Clients
                                </Link>
                            </li>
                            <CorporateSupplierNavSales toggleMenu={toggleMenu} openMenus={openMenus} />
                            <li>
                                <span className="cursor-pointer nav-link dropdown-toggle" onClick={() => toggleMenu('commerce_legal')}>
                                    Légale
                                </span>
                                <div className={`collapse ${openMenus['commerce_legal'] ? 'show' : ''}`}>
                                    <ul className={"list-unstyled"}>
                                        <li>
                                            <Link to={'/corporate/supplier/term'} className="nav-link">
                                                CGV
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/corporate/supplier/returns-policy'} className="nav-link">
                                                Politique de retour
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <span className="cursor-pointer nav-link dropdown-toggle" onClick={() => toggleMenu('commerce_logistic')}>
                                    Logistique
                                </span>
                                <div className={`collapse ${openMenus['commerce_logistic'] ? 'show' : ''}`}>
                                    <ul className={"list-unstyled"}>
                                        <li>
                                            <Link to={'/corporate/supplier/shipping/address'} className="nav-link">
                                                Adresse de Traitement
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/corporate/supplier/shipping'} className="nav-link">
                                                Zone d'expédition
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/corporate/supplier/returns-address'} className="nav-link">
                                                Adresse de retour
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <span className="cursor-pointer nav-link dropdown-toggle" onClick={() => toggleMenu('transaction')}>
                        <i className="bi bi-credit-card-2-front"></i> Transactions
                    </span>
                    <div className={`collapse ${openMenus['transaction'] ? 'show' : ''}`}>
                        <ul className={"list-unstyled"}>
                            <li>
                                <Link to={'/corporate/balance'} className="nav-link">
                                    Solde
                                </Link>
                            </li>
                            <li>
                                <Link to={'/corporate/transfer'} className="nav-link">
                                    Transfers
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <span className="cursor-pointer nav-link dropdown-toggle" onClick={() => toggleMenu('developer')}>
                        <i className="bi bi-code"></i> Développeur
                    </span>
                    <div className={`collapse ${openMenus['developer'] ? 'show' : ''}`}>
                        <ul className={"list-unstyled"}>
                            <li>
                                <Link to={'/corporate/supplier/webhook'} className="nav-link">
                                    Webhooks
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </nav>
    </div>
}

export default CorporateSupplierNav;