import React, {useEffect, useState} from "react";
import * as models from "../../../../../../../../models/shipping";
import * as api from "../../../../../../../../adapters/corporate/supplier/shipping/rate";
import Pagination from "../../../../../../../../utils/pagination";
import Loader from "../../../../../../../../utils/loader";
import {Link, useNavigate} from "react-router-dom";
import SupplierShippingRateAdd from "../add";

const SupplierShippingRateList = ({area}: {area: models.ShippingArea}) => {

    const [loading, setLoading] = useState(true)
    const [payload, setPayload] = useState<{count: number, rows: models.ShippingRates}>({count: 0, rows: []})
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string}>({offset: 0, query: ""})

    useEffect(() => {
        setLoading(true)
        api.list({...fetchParams, area_id: area.id})
            .then(r => setPayload(r.data))
            .then(() => setLoading(false))
    }, [fetchParams, area])

    return <div className="col-12 mb-3">
        <div className="card border border-1">
            <div className="card-header">
                <div className="row align-items-center">
                    <div className="col">
                        <h6>
                            Forfaits
                        </h6>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-light" data-bs-toggle={"offcanvas"} data-bs-target={"#offcanvasShippingRateAdd"}>
                           <i className={'bi bi-plus-circle'}></i> Ajouter un forfait
                        </button>
                    </div>
                </div>

            </div>
            <div className="card-body">
                <div className="col-12 mb-3 text-center">
                    {loading ? <Loader /> : <>{payload.count} résultats</>}
                </div>
                <ul className={'list-group'}>
                    {payload.rows.map(r => <li className={'list-group-item'}>
                       <Link to={`/corporate/supplier/shipping/rate/${r.id}`}>
                           {r.name}, {r.delayMin} à {r.delayMax} jours
                       </Link>
                    </li>)}
                </ul>
            </div>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        </div>
        <SupplierShippingRateAdd area={area} handleSuccess={area => setPayload(prev => ({count: prev.count + 1, rows: [area, ...prev.rows]}))} />
    </div>

}

export default SupplierShippingRateList;