import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import React from "react";
import {Link} from "react-router-dom";
import authSlice from "../../../../../reducers/auth";
import {CorporateAccount} from "../../../../../models/account";

const CommonNavAccount = () => {

    const dispatch = useAppDispatch();
    const account = useAppSelector(state => state.auth.user?.activeAccount) as CorporateAccount;

    return <div className="dropdown">
        <button className="btn btn-xxs bg-body-secondary rounded" type="button" data-bs-toggle="dropdown"
                aria-expanded="false">
           <i className={"bi bi-person-circle"}></i> <span className={'d-none d-md-inline'}>Mon compte</span>
        </button>
        <ul className="dropdown-menu">
            <li>
                <Link to={`/corporate/organization/${account.organization.id}`} className="dropdown-item">
                    <i className={'bi bi-buildings'}></i> {account.organization.name}
                </Link>
            </li>
            <li>
                <Link to={'/corporate/setting/account'}  className="dropdown-item">
                    <i className={'bi bi-gear'}></i> Réglages
                </Link>
            </li>
            <li>
                <Link to={'/corporate/invoice'}  className="dropdown-item">
                    <i className={'bi bi-file-earmark'}></i> Factures
                </Link>
            </li>
            <li>
                <Link to={'/account?type=corporate'}  className="dropdown-item">
                    <i className={"bi bi-arrow-left-right"}></i> Changer de compte
                </Link>
            </li>
            <li>
                <Link to={'/account?type=customer'} className="dropdown-item">
                    <i className={"bi bi-arrow-left-right"}></i> Interface client
                </Link>
            </li>
            <li className="dropdown-item" onClick={() => dispatch(authSlice.actions.logout())}>
                <i className={"bi bi-x-circle"}></i> Déconnexion
            </li>
        </ul>
    </div>
}

export default CommonNavAccount;