import {Link, useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../adapters/corporate/supplier/shipping";
import React from "react";
import FormError from "../../../../../utils/formError";
import Loader from "../../../../../utils/loader";

const SupplierShippingAdd = () => {

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: "",
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
        }),
        onSubmit: (values, formikHelpers) => {
            api.add(values)
                .then(r => navigate(`/corporate/supplier/shipping/${r.data.id}/area`))
        }
    })


    return <div className={"container-fluid"}>
        <div className="col-12 mb-3">
            <ol className="breadcrumb fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/corporate/supplier/shipping">Livraison</Link>
                </li>
                <li className="breadcrumb-item">
                    Ajouter
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3">
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="text" className="form-control" name={'name'} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                        <label htmlFor="name">
                            Nom
                        </label>
                        <FormError form={formik} field={"name"} />
                    </div>
                </div>
                <button disabled={formik.isSubmitting} type={'submit'} className={"btn btn-light w-100"}>
                    {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-plus-circle'}></i> Ajouter</>}
                </button>
            </form>
        </div>
    </div>

}

export default SupplierShippingAdd;