import React, {useEffect, useState} from "react";
import * as api from '../../../../../adapters/corporate/supplier/click';
import {Affiliation, Clicks, Click} from "../../../../../models/affiliation";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";
import {Payload} from "../../../../../models/global";
import CommonClickStatus from "../../../common/click/status";
import ModalComponent from "../../../../modal";
import SupplierClickPay from "../pay";
import SupplierClickListItem from "./item";

const SupplierClickList = ({affiliation, status}: {affiliation?: Affiliation, status: "All"|"Pending"|"Accepted"|"Refused"|"Paid"}) => {

    const [payload, setPayload] = useState<Payload<Clicks>>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number}>({query: "", offset: 0})
    const [selecting, setSelecting] = useState(false)
    const [selected, setSelected] = useState<Clicks>([]);
    const [showPaidModal, setShowPaidModal] = useState(false);
    const [submitting, setSubmitting] = useState(false)
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [showRefusedModal, setShowRefusedModal] = useState(false);

    useEffect(() => {
        setLoading(true)
        api.list({offset: fetchParams.offset, count: 50, query: fetchParams.query, affiliation_id: affiliation?.id, status: status})
            .then(resp => setPayload(resp.data))
            .finally(() => setLoading(false))
    }, [fetchParams, affiliation?.id, status])

    function handleSelect(click: Click)
    {
        if (selected.map(s => s.id).includes(click.id)){
            setSelected(prev => [...prev.filter(p => p.id !== click.id)])
        }else{
            setSelected(prev => [...prev, click])
        }
    }

    function selectAll(){
        setSelecting(true)
        api.list({offset: fetchParams.offset, count: payload.count, affiliation_id: affiliation?.id, status: status})
            .then(resp => setSelected(resp.data.rows))
            .finally(() => setSelecting(false))
    }

    function submitSelection(status: "Accepted"|"Refused"){
        if (selected.length){
            setSubmitting(true)
            api.bulkUpdate({ids: selected.map(s => s.id), status: status})
                .then(resp => {
                    const ids = resp.data.map((c: Click) => c.id);
                    setPayload(prev => ({count: prev.count - ids.length, rows: [...prev.rows.filter(r => !ids.includes(r.id))]}))
                    setSelected(prev => [...prev.filter(p => !ids.includes(p.id))])
                    setShowAcceptModal(false)
                })
                .finally(() => {
                    setSubmitting(false)
                })
        }
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        {["Pending", "Accepted"].includes(status) && <div className={'col-12.mb-3'}>
            <div className="card border border-1">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col fw-semibold">
                            {selected.length} click(s) séléctionné(s)
                        </div>
                        <div className="col-auto">
                            <button className={'btn btn-xs btn-light'} onClick={() => selectAll()} disabled={selecting || !payload.count || selected.length === payload.count}>
                                {selecting ? <Loader /> : <>
                                    {selected.length === payload.count ? <>Tout les clics {status !== "All" && <CommonClickStatus status={status}/>} sont séléctionnés</> : <>Séléctionner tout les clicks {status !== "All" && <CommonClickStatus status={status}/>} ({payload.count})</>}

                                </>}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <p>
                        Avec la séléction:
                    </p>
                    {status === "Pending" && <div className="btn-group">
                        <button className={"btn btn-xs btn-light"} disabled={selected.length === 0} onClick={() => setShowAcceptModal(true )}>
                            <i className={'bi bi-check text-success'}></i> Accepter
                        </button>
                        <button className={"btn btn-xs btn-light"} disabled={selected.length === 0} onClick={() => setShowRefusedModal(true)}>
                            <i className={'bi bi-x text-danger'}></i> Refuser
                        </button>
                    </div>}
                    {status === "Accepted" && <div className="btn-group">
                        <button className={"btn btn-xs btn-light"} disabled={selected.length === 0} onClick={() => setShowPaidModal(true)}>
                            <i className={'bi bi-credit-card-2-front'}></i> Payer
                        </button>
                    </div>}
                </div>
            </div>
        </div>}
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        <div className="col-md-12 flex-grow-1">
            {!loading && <div className={'table-responsive'}>
                <table className={'table'}>
                    <thead>
                    <tr>
                        {["Pending", "Accepted"].includes(status) && <th>

                        </th>}
                        <th>
                            Affiliation
                        </th>
                        <th>
                            Règle
                        </th>
                        <th>
                            Affilié
                        </th>
                        <th>
                            Date
                        </th>
                        <th>
                            Statut
                        </th>
                        <th>

                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {payload.rows.map(r => <SupplierClickListItem status={status} handleSelect={handleSelect} selected={selected} item={r} />)}
                    </tbody>
                </table>
            </div>}
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        </div>
        {showPaidModal && affiliation && <ModalComponent size={"modal-lg"} title={"Payer les clics"} show={showPaidModal} onHide={() => setShowPaidModal(false)}>
            <SupplierClickPay clicks={selected} affiliation={affiliation} handlePaymentSucceed={clicks => {
                const ids = clicks.map(c => c.id);
                setPayload(prev => ({count: prev.count - ids.length, rows: [...prev.rows.filter(r => !ids.includes(r.id))]}))
                setSelected(prev => [...prev.filter(p => !ids.includes(p.id))])
                setShowPaidModal(false)
            }} />
        </ModalComponent>}
        {showAcceptModal && affiliation && <ModalComponent size={"modal-lg"} title={"Accepter les clics"} show={showAcceptModal} onHide={() => setShowAcceptModal(false)}>
            <div className="col-12 text-center">
                <p className={'fw-semibold'}>
                    Êtes vous sur de vouloir accepté les {selected.length} clics ?
                </p>
                <div className="btn-group">
                    <button className="btn btn-light" disabled={submitting} onClick={() => submitSelection("Accepted")}>
                       <i className={'bi bi-check text-success'}></i> Oui, je suis sur.
                    </button>
                    <button className="btn btn-light" disabled={submitting} onClick={() => setShowAcceptModal(false)}>
                       <i className={'bi bi-x text-danger'}></i> Annuler
                    </button>
                </div>
            </div>
        </ModalComponent>}
        {showRefusedModal && affiliation && <ModalComponent size={"modal-lg"} title={"Refuser les clics"} show={showRefusedModal} onHide={() => setShowRefusedModal(false)}>
            <div className="col-12 text-center">
                <p className={'fw-semibold'}>
                    Êtes vous sur de vouloir refuser les {selected.length} clics ?
                </p>
                <div className="btn-group">
                    <button className="btn btn-light" disabled={submitting} onClick={() => submitSelection("Refused")}>
                       <i className={'bi bi-check text-success'}></i> Oui, je suis sur.
                    </button>
                    <button className="btn btn-light" disabled={submitting} onClick={() => setShowRefusedModal(false)}>
                       <i className={'bi bi-x text-danger'}></i> Annuler
                    </button>
                </div>
            </div>
        </ModalComponent>}
    </div>
}

export default SupplierClickList;