import {reason_labels} from "../../../../../../constants/returns";

const CustomerReturnAddReason1 = ({handleNext, handlePrev}: {handleNext: (reason: "reason1", value: string) => void, handlePrev: (reason: string) => void}) => {

    return <div className="card h-100">
        <div className="card-header">
            <div className="row">
                <div className="col-auto">
                    <div className="btn btn-xxs btn-light" onClick={() => handlePrev("reason1")}>
                        <i className={'bi bi-arrow-left'}></i>
                    </div>
                </div>
                <div className="col">
                    <h6>Comment pouvons-nous vous aider avec cet article ?</h6>
                </div>
            </div>
        </div>
        <div className="card-body">
            <ul className="list-group list-group-flush">
                <li className={'list-group-item list-group-item-action'} onClick={() => handleNext('reason1', "return" )}>
                    {reason_labels.return} <i className={'bi bi-chevron-right'}></i>
                </li>
                <li className={'list-group-item list-group-item-action'} onClick={() => handleNext('reason1', "undelivered" )}>
                    {reason_labels.undelivered} <i className={'bi bi-chevron-right'}></i>
                </li>
                <li className={'list-group-item list-group-item-action'} onClick={() => handleNext('reason1', "defective" )}>
                    {reason_labels.defective} <i className={'bi bi-chevron-right'}></i>
                </li>
                <li className={'list-group-item list-group-item-action'} onClick={() => handleNext('reason1', "damaged" )}>
                    {reason_labels.damaged} <i className={'bi bi-chevron-right'}></i>
                </li>
                <li className={'list-group-item list-group-item-action'} onClick={() => handleNext('reason1', "late" )}>
                    {reason_labels.late} <i className={'bi bi-chevron-right'}></i>
                </li>
            </ul>
        </div>
    </div>
}

export default CustomerReturnAddReason1;
