import React, {useState} from "react";
import {OrderItem} from "../../../../../../models/order";
import {getCoverUrl} from "../../../../../../utils/image";
import convertToDecimal from "../../../../../../utils/currencyConverter";

const CustomerReturnAddItem = ({item, selectItem}: {item: OrderItem, selectItem: (item: OrderItem) => void;}) => {

    return <div className={"col"}>
        <div className="card border border-1 shadow-hover cursor-pointer" onClick={() => selectItem(item)}>
            <img src={`${getCoverUrl(item.price.product)}`} alt="" className="card-img-top"/>
            <div className="card-body bg-white">
                <div className="row">
                    <div className="col">
                        <p className="d-block fw-bold text-body mb-1">
                            {item.name}
                        </p>
                        <p className="fs-xs text-muted">
                            Quantité: {item.quantity}
                        </p>
                    </div>
                    <div className="col-auto">
                        <div className="fs-sm fw-bold text-primary">
                            {convertToDecimal(item.priceData.total_amount_including_tax, item.priceData.currency)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default CustomerReturnAddItem;