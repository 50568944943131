import React, {useState} from "react";
import {Affiliation} from "../../../../../../models/affiliation";
import {getCurrencyChoices} from "../../../../../../utils/currencies";
import {getCountryChoices} from "../../../../../../utils/countries";
import {getAttributionLabel, getUrlTypeLabel} from "../../../../../../utils/affiliation";

const ProviderAffiliationShowInformation = ({affiliation}: { affiliation: Affiliation}) => {

    return <>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Détails du programme
                    </h6>
                </div>
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col-md-3">
                            <div className="form-text">
                                Nom
                            </div>
                            <p  className={'mb-0'} >
                                {affiliation.name}
                            </p>
                        </div>
                        <div className="col-md-3">
                            <div className="form-text">
                                Url
                            </div>
                            <p className={"text-primary mb-0"}>
                                <i className="bi bi-box-arrow-up-right"></i> <span>{affiliation.url}</span>
                            </p>
                        </div>
                        <div className="col-md-3">
                            <div className="form-text">
                                Url de conversion
                            </div>
                            <p className={"text-primary mb-0"}>
                                <i className="bi bi-box-arrow-up-right"></i> <span>{affiliation.conversionUrl}</span>
                            </p>
                        </div>
                        <div className="col-md-3">
                            <div className="form-text">
                                Type d'url
                            </div>
                            <p>
                                {getUrlTypeLabel(affiliation.urlType)}
                            </p>
                        </div>
                        <div className="col-md-3">
                            <div className="form-text">
                                Politique de commissionnement
                            </div>
                            <p>{getAttributionLabel(affiliation.attribution)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h6>Pays</h6>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {getCountryChoices(affiliation.countries.map(c => c.alpha2)).map(c => <span className={'badge bg-primary me-2 mb-2 text-nowrap'}>
                        {c.img && <img style={{height: 18, width: "auto"}} src={c.img} alt={c.label} />} {c.label}
                    </span>)}
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h6>Origine de  traffic autorisé</h6>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {getCountryChoices(affiliation.allowedCountries.map(c => c.alpha2)).map(c => <span className={'badge bg-primary me-2 mb-2 text-nowrap'}>
                        {c.img && <img style={{height: 18, width: "auto"}} src={c.img} alt={c.label} />} {c.label}
                    </span>)}
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h6>Devises</h6>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {getCurrencyChoices(affiliation.currencies.map(c => c.code)).map(c => <span className={'badge bg-primary me-2 mb-2 text-nowrap'}>
                        {c.label}
                    </span>)}
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h6>Config</h6>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <p className="fw-semibold mb-1">
                        Durée entre deux clics de même provenance
                    </p>
                    <p>
                        {affiliation.config.sessionTTLDay} jours, {affiliation.config.sessionTTLHour} heures, {affiliation.config.sessionTTLMinute} minutes, {affiliation.config.sessionTTLSecond} secondes
                    </p>
                </div>
            </div>
        </div>
    </>
}

export default ProviderAffiliationShowInformation;