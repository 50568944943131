import React, {useEffect, useState} from "react";
import {Product} from "../../../../../models/product";
import ProductShowCarousel from "../carousel";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import {getLogoUrl} from "../../../../../utils/image";
import {Link} from "react-router-dom";
import * as api from "../../../../../adapters/corporate/common/product";
import ProviderProductSupportList from "../../../provider/product/support/list";

const CommonProductShow = ({id}: {id: number}) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);
    const [product, setProduct] = useState<Product>();

    useEffect(() => {
        setLoading(true)
        api.show(id)
            .then(r => setProduct(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
        ;
    }, [id])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error)
    {
        return <ErrorCard error={error} />
    }

    if (!product){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <h6 className="text-muted">
                    Aucun produit séléctionné
                </h6>
            </div>
        </div>
    }

    return <>
        <ProductShowCarousel product={product} key={product.id} />
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h3>Fournisseur et commissions</h3>
                </div>
                <div className="card-body">
                    <div className="d-flex align-items-center">
                        <span className="avatar avatar-xl me-2">
                            <img className="avatar-img rounded-circle" src={getLogoUrl(product.organization)} alt={product.organization.name}/>
                        </span>
                        <Link to={`/corporate/organization/${product.organization.id}`}>
                            {product.organization.name}
                        </Link>
                    </div>
                    <p>
                        Règle de commission:  {product.feeRule?.description}
                    </p>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h3>Description</h3>
                </div>
                <div className="card-body">
                    <div dangerouslySetInnerHTML={{__html: product.longDescription}} />
                </div>
            </div>
        </div>
        {/*<div className="col-12 mb-3">*/}
        {/*    <div className="card border border-1">*/}
        {/*        <div className="card-header">*/}
        {/*            <h3>*/}
        {/*                Supports de vente*/}
        {/*            </h3>*/}
        {/*        </div>*/}
        {/*        <div className="card-body">*/}
        {/*            <ProviderProductSupportList product={product} />*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {product.sellable && <div className={"card border border-1"}>
            <div className="card-header">
                <h3>Vente direct</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className="col-12 flex-grow-0 mb-3">
                            <div className={"mb-1 text-muted fs-xs d-flex flex-column"}>
                                Type de prix
                            </div>
                            <p className={'text-primary fw-bold mb-1'}>
                                {product.price.recurring ? <>Abonnement</> : <>Ponctuel</>}
                            </p>
                            {product.price.recurring && <>
                                <p className={'small text-primary mb-1'}>Tout les {product.price.recurring.intervalCount} {product.price.recurring.interval}</p>
                            </>}
                            <div className={"mb-1 text-muted fs-xs d-flex flex-column"}>
                                Commission
                            </div>
                            <p className={'text-success fw-bold mb-1'}>
                                {product.price.feePercent}%
                            </p>
                            <div className={"mb-1 text-muted fs-xs d-flex flex-column"}>
                                Score
                            </div>
                            <p className={'text-primary fw-bold mb-1'}>
                                <i className={'bi bi-trophy'}></i> {product.ranking?.score.toLocaleString() || 0}
                            </p>
                            <div className={"mb-1 text-muted fs-xs d-flex flex-column"}>
                                Catégorie
                            </div>
                            <p className={'badge bg-info mb-1'}>
                                {product.category ? product.category.name : "Non catégorisé"}
                            </p>
                        </div>
                        <div className="col-12 mb-3 flex-grow-1">
                            <div className="row">
                                {product.declinations.map(declination => <div className={"col-12 mb-3"}>
                                    <p className={"fw-semibold mb-2"}>
                                        {declination.name}
                                    </p>
                                    <div className="row gx-1">
                                        {declination.choices.map(choice => <div className={"col-auto"}>
                                            <button className={`btn btn-xs btn-light`}>
                                                {choice.value}
                                            </button>
                                        </div>)}
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseCurrency" aria-expanded="false"
                                            aria-controls="flush-collapseCurrency">
                                        Devises
                                    </button>
                                </h2>
                                <div id="flush-collapseCurrency" className="accordion-collapse collapse"
                                     data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <ul className={"list-group"}>
                                            <li className={"list-group-item"}>
                                                {product.price.currency.symbol} {product.price.currency.name}
                                            </li>
                                            {product.price.options.length > 0 && product.price.options.map(o => <li className={"list-group-item"}>
                                                {o.currency.symbol} {o.currency.name}
                                            </li>)}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {product.shippable && <>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseShipping" aria-expanded="false"
                                                aria-controls="flush-collapseShipping">
                                            Zones de livraison
                                        </button>
                                    </h2>
                                    <div id="flush-collapseShipping" className="accordion-collapse collapse"
                                         data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <ul className={"list-group"}>
                                                {product.shipping.areas.map(a => <>
                                                    {a.countries.map(c => <li className={"list-group-item"}>
                                                        <img style={{height: 18, width: "auto"}} src={c.flag} alt={c.name} /> {c.name}
                                                    </li>)}
                                                </>)}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {product.dimension && <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseDimension" aria-expanded="false"
                                                aria-controls="flush-collapseDimension">
                                            Dimensions
                                        </button>
                                    </h2>
                                    <div id="flush-collapseDimension" className="accordion-collapse collapse"
                                         data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <table className={"table table-borderless"}>
                                                <tbody>
                                                <tr>
                                                    <th>
                                                        Hauteur
                                                    </th>
                                                    <td>
                                                        {product.dimension.height} Cm
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        Largeur
                                                    </th>
                                                    <td>
                                                        {product.dimension.width} Cm
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        Profondeur
                                                    </th>
                                                    <td>
                                                        {product.dimension.depth} Cm
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        Poids
                                                    </th>
                                                    <td>
                                                        {product.dimension.weight} grammes
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        Unité par colis
                                                    </th>
                                                    <td>
                                                        {product.dimension.count}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>}
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </div>}
    </>

}

export default CommonProductShow;