import React from "react";
import SupplierQuoteList from "../../../components/corporate/supplier/quote/list";

const SupplierQuoteListPage = () => {

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    Devis
                </li>
            </ol>
        </div>
        <SupplierQuoteList />
    </div>
}

export default SupplierQuoteListPage;