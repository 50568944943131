import {useEffect, useState} from "react";
import {Payload} from "../../../../../models/global";
import {Touches} from "../../../../../models/touch";
import {list} from "../../../../../adapters/corporate/supplier/touch";
import Loader from "../../../../../utils/loader";
import {useNavigate} from "react-router-dom";
import ErrorCard from "../../../../error";

const SupplierDashboardTouch = () => {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [payload, setPayload] = useState<Payload<Touches>>({count: 0, rows: []});

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        list({status: "Pending", offset: 0})
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [])

    return <div className="card h-100 shadow-hover cursor-pointer" onClick={() => navigate('/corporate/supplier/touch')}>
        <div className="card-body border border-1">
            <div className="row align-items-center">
                <div className="col">
                    <p className={'fw-semibold mb-0'}>
                        Mises en relations
                    </p>
                </div>
                <div className="col-auto">
                    <i className={'bi bi-arrow-right'}></i>
                </div>
            </div>
            <p className={'mb-0 mt-1 fw-bold'}>
                {loading ? <Loader /> : error ? <ErrorCard error={error} /> : <><span className={'fs-4'}>{payload.count}</span> <span className={'text-muted'}>En attente</span></>}
            </p>
        </div>
    </div>
}

export default SupplierDashboardTouch;