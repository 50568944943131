import FormError from "../../../../utils/formError";
import Loader from "../../../../utils/loader";
import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../adapters/common/paymentMethod";
import {PaymentMethod} from "../../../../models/paymentMethod";
import InputMask from 'react-input-mask';
import ErrorCard from "../../../error";

const CommonPaymentMethodAdd = ({onSuccess}: {onSuccess: (address: PaymentMethod) => void}) => {

    const [error, setError] = useState(null)

    const formik = useFormik({
        initialValues: {
            number: "",
            expDate: "",
            cvc: "",
            default: true,
        },
        validationSchema: Yup.object().shape({
            number: Yup.string()
                .required('Card number is required')
                .matches(/^\d{4} \d{4} \d{4} \d{4}$/, 'Invalid card number'),
            expDate: Yup.string()
                .required('Expiration date is required')
                .matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, 'Invalid expiration date format (MM/YY)')
                .test('expiration-date', 'Card has expired', function (value) {
                    if (!value) return false;
                    const [expMonth, expYear] = value.split('/').map(Number);
                    const currentYear = new Date().getFullYear() % 100; // last two digits of current year
                    const currentMonth = new Date().getMonth() + 1; // Adding 1 since getMonth returns zero-based index

                    if (expYear < currentYear) return false;
                    if (expYear === currentYear && expMonth < currentMonth) return false;

                    return true;
                }),
            cvc: Yup.string()
                .required('CVC is required')
                .matches(/^\d{3,4}$/, 'Invalid CVC'),
            default: Yup.boolean().required()
        }),
        onSubmit: (values, formikHelpers) => {
            api.add(values)
                .then(r => onSuccess(r.data))
                .catch(e => setError(e))
                .finally(() => formikHelpers.setSubmitting(false))
        }
    });

    return (
        <form onSubmit={formik.handleSubmit} className="card p-4 shadow-sm border-0">
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <InputMask
                        mask="9999 9999 9999 9999"
                        maskChar=" "
                        className="form-control"
                        name="number"
                        id="number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.number}
                        placeholder="Numéro de carte"
                    />
                    <label htmlFor="number">Numéro de carte *</label>
                </div>
                <FormError form={formik} field="number" />
            </div>
            <div className="col-12 mb-3">
                <div className="row">
                    <div className="col-8">
                        <div className="form-floating">
                            <InputMask
                                mask="99/99"
                                maskChar=" "
                                className="form-control"
                                name="expDate"
                                id="expDate"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.expDate}
                                placeholder="mm/yy"
                            />
                            <label htmlFor="expDate">Expiration (MM/YY)</label>
                        </div>
                        <FormError form={formik} field="expDate" />
                    </div>
                    <div className="col-4">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                name="cvc"
                                id="cvc"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.cvc}
                                placeholder="CVC"
                            />
                            <label htmlFor="cvc">CVC</label>
                        </div>
                        <FormError form={formik} field="cvc" />
                    </div>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="col-12 mb-3">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="default" name={"default"} onBlur={formik.handleBlur} checked={formik.values.default} onChange={e => formik.setFieldValue('default', e.target.checked)} />
                        <label className="form-check-label" htmlFor="default">
                            Définir par défaut
                        </label>
                    </div>
                </div>
            </div>
            {error && <ErrorCard error={error} />}
            <button type="submit" className="btn btn-primary w-100">
                {formik.isSubmitting ? <Loader size="sm" /> : <><i className="bi bi-check"></i> Enregistrer</>}
            </button>
        </form>
    );
};

export default CommonPaymentMethodAdd;