import React, {useEffect, useState} from "react";
import {FetchParams, Payload} from "../../../../../../models/global";
import {Organizations} from "../../../../../../models/organization";
import * as api from "../../../../../../adapters/corporate/common/sponsorship";
import SearchInput from "../../../../../../utils/searchInput";
import ErrorCard from "../../../../../error";
import Loader from "../../../../../../utils/loader";
import {Link} from "react-router-dom";
import Pagination from "../../../../../../utils/pagination";

const SponsorshipTracking = () => {

    const [payload, setPayload] = useState<Payload<Organizations>>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [fetchParams, setFetchParams] = useState<FetchParams>({query: "", offset: 0})

    useEffect(() => {
        setLoading(true)
        api.list({offset: fetchParams.offset, query: fetchParams.query})
            .then(resp => setPayload(resp.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher un filleul..."} onChange={handleQueryChange} size={"default"} />
                </div>
            </div>
        </div>
        {error && <div className={'col-12 mb-3'}>
            <ErrorCard error={error} />
        </div>}
        <div className="col-md-12 flex-grow-1 position-relative">
            {loading && <div className={"h-100 w-100 position-absolute"}>
                <div className="row justifi-content-center">
                    <div className="col-auto py-10">
                        <Loader />
                    </div>
                </div>
            </div>}
            <div className={'table-responsive'}>
                <table className={'table'}>
                    <thead>
                    <tr>
                        <th>
                            Nom
                        </th>
                        <th>
                            Période
                        </th>
                        <th>
                            Revenue généré
                        </th>
                        <th>
                            Commissions (20%)
                        </th>
                        <th>
                            Statut
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {payload.count ? payload.rows.map(a => <tr>
                        <td>
                            <Link className="col mb-3" to={`/corporate/organization/${a.id}`}>{a.name}</Link>
                        </td>
                        <td>
                            {new Date(a.createdAt).toLocaleDateString()}
                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>) : <tr>
                        <td className={"text-muted"} colSpan={5}>
                            <i className={'bi bi-search'}></i> Aucun résultat
                        </td>
                    </tr>}
                    </tbody>
                </table>
            </div>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        </div>
    </div>
}

export default SponsorshipTracking