
const CommonClickStatus = ({status}: {status: string}) => {

    switch (status){
        case "Pending":
            return <span className={"badge bg-warning text-dark"}>En attente</span>
        case "Accepted":
            return <span className={"badge bg-info"}>Accepté</span>
        case "Refused":
            return <span className={"badge bg-danger"}>Refusé</span>
        case "Paid":
            return <span className={"badge bg-success"}>Payé</span>
    }

    return <></>
}

export default CommonClickStatus;