import React, {useEffect, useState} from "react";
import * as api from '../../../../../adapters/corporate/common/invoice';
import SearchInput from "../../../../../utils/searchInput";
import * as models from "../../../../../models/invoice";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";
import InvoiceStatusPlaceholder from "../../../../corporate/common/invoice/status";
import ErrorCard from "../../../../error";
import CommonInvoiceListItem from "./item";

const CommonInvoiceList = () => {

    const [payload, setPayload] = useState<{ count: number, rows: models.Invoices }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState();
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number, status: "All"|"Pending"|"Paid"|"PastDue"|"Canceled"}>({query: "", offset: 0, status: "All"})
    const status_array: ("All"|"Pending"|"Paid"|"PastDue"|"Canceled")[] = ["All", "Pending","Paid","PastDue"];

    useEffect(() => {
        setLoading(true)
        api.list({...fetchParams})
            .then(resp => setPayload(resp.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams(p => ({...p, query: e.target.value, offset: 0})), 1000)
    }

    return (
        <>
            <div className="col-12 mb-3 flex-grow-0">
                <div className="row align-items-center">
                    <div className="col-12 mb-3">
                        <SearchInput placeholder={"Rechercher une facture..."} onChange={handleQueryChange} size={"sm"} />
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-auto">
                                <div className="dropdown">
                                    <button className="btn btn-light btn-xxs dropdown-toggle" type="button"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                        Statut: <InvoiceStatusPlaceholder status={fetchParams.status} />
                                    </button>
                                    <ul className="dropdown-menu">
                                        {status_array.filter(s => s !== fetchParams.status).map((s) => <li>
                                        <span className="dropdown-item cursor-pointer" onClick={() => setFetchParams(prev => ({...prev, status: s}))}>
                                            <InvoiceStatusPlaceholder status={s} />
                                        </span>
                                        </li>)}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-auto">
                            <span className="text-muted">
                                {loading ? <Loader size={"sm"} /> : <>{payload.count} résultats</>}
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12 flex-grow-1">
                {error && <ErrorCard error={error} />}
                <div className={'table-responsive'}>
                    <table className={'table table-hover'}>
                        <thead>
                        <tr>
                            <th>
                                Ref
                            </th>
                            <th>
                                Statut
                            </th>
                            <th>

                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {payload.rows.map(invoice => <CommonInvoiceListItem invoice={invoice} key={invoice.id} />)}
                        </tbody>
                    </table>
                </div>
                <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
            </div>
        </>
    )
}

export default CommonInvoiceList;