import {destroy, get, post} from "../../../../xhr";
import encodeQueryData from "../../../../../utils/encodeQueryData";

export function list(pack: number, requestData: {query?: string, offset: number}){
    return get(`/corporate/supplier/pack/${pack}/item${encodeQueryData(requestData)}`);
}

export function show(pack: number, item: number)
{
    return get(`/corporate/supplier/pack/${pack}/item/${item}`)
}

export function add(pack: number, requestData: {product_id: number|null, quantity: number, editable: boolean})
{
    return post(`/corporate/supplier/pack/${pack}/item`, requestData)
}

export function update(pack: number, item: number, requestData: {name?: string, quantity?: number})
{
    return post(`/corporate/supplier/pack/${pack}/item/${item}`, requestData)
}

export function remove(pack: number, item: number)
{
    return destroy(`/corporate/supplier/pack/${pack}/item/${item}`)
}

