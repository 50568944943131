import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function add(requestData: {name: string, currency: string, customer: number|null})
{
    return post(`corporate/provider/order/add`, requestData)
}

export function list(requestData: {query: string, offset: number, status: string})
{
    return get(`corporate/provider/order${encodeQueryData(requestData)}`)
}

export function show(order: number)
{
    return get(`corporate/provider/order/${order}`)
}

export function close(order: number)
{
    return get(`corporate/provider/order/${order}/close`)
}

export function open(order: number)
{
    return get(`corporate/provider/order/${order}/open`)
}



export function send(order: number, requestData: {email: string})
{
    return post(`corporate/provider/order/${order}/send`, requestData)
}

