import {useFormik} from "formik";
import * as Yup from 'yup';
import Select from "../../../../../utils/select";
import FormError from "../../../../../utils/formError";
import React, {useState} from "react";
import {useAppSelector} from "../../../../../hooks";
import {CorporateAccount} from "../../../../../models/account";
import {getCountries} from "../../../../../utils/countries";
import ReactQuill from "react-quill";
import * as api from "../../../../../adapters/corporate/supplier/assignment"
import {Assignment} from "../../../../../models/assignment";
import ErrorCard from "../../../../error";
import Loader from "../../../../../utils/loader";
import {sectors} from "../../../../../utils/sectors";
import {getCurrencies, getCurrencyChoice} from "../../../../../utils/currencies";
import {services} from "../../../../../utils/service";

const SupplierAssignmentAdd = ({handleSuccess}: {handleSuccess: (assignment: Assignment) => void}) => {

    const activeAccount = useAppSelector(state => state.auth.user?.activeAccount) as CorporateAccount
    const authOrganization = activeAccount.organization;
    const defaultAddress = authOrganization.billing;

    const [error, setError] = useState(null)

    const [initialValues, setInitialValues] = useState<any>({
        name: "",
        description: "",
        location: 'OnSite',
        country_id: defaultAddress?.country?.id,
        line1: defaultAddress?.line1,
        line2: defaultAddress?.line2,
        city: defaultAddress?.city,
        postalCode: defaultAddress?.postalCode,
        compensation: "Hybrid",
        currency_id: 3,
        fixRangeMin: 0,
        fixRangeMax: 0,
        fixRangeDuration: 'Daily',
        feeRangeMin: 0,
        feeRangeMax: 0,
        feeTrigger: 'Sale',
        sectors: [],
        specialisations: []
    })

    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        name: Yup.string().required(),
        description: Yup.string().required(),
        location: Yup.string().required(),
        country_id: Yup.number().required(),
        line1: Yup.string().required(),
        line2: Yup.string().nullable(),
        city: Yup.string().required(),
        postalCode: Yup.string().required(),
        compensation: Yup.string().required(),
        currency_id: Yup.number().required(),
        fixRangeMin: Yup.number().required(),
        fixRangeMax: Yup.number().required(),
        fixRangeDuration: Yup.string().required(),
        feeRangeMin: Yup.number().required(),
        feeRangeMax: Yup.number().required(),
        feeTrigger: Yup.string().required(),
        sectors: Yup.array().min(0).required(),
        specialisations:  Yup.array().min(0).required(),
    }))

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, {setSubmitting}) => {
            setError(null)
            api.add(values)
                .then(r => handleSuccess(r.data))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    })

    function handleLocationChange(location: string){

        let _values: any = {...formik.values}
        let _fields: any = {...validationSchema.fields}
        _values.location = location;

        const keysToDelete = []
        const keysToAdd = []

        switch (location){
            case 'OnSite':
            case 'Hybrid':
                keysToDelete.push('area')
                keysToAdd.push('country_id', 'city', 'postalCode', 'line1', 'line2', 'displayLocation')
                break;
            case 'Remote':
                keysToDelete.push('country_id', 'city', 'postalCode', 'line1', 'line2', 'area', 'displayLocation')
                break;
            case 'Area':
            case 'Travel':
                keysToDelete.push('city', 'postalCode', 'line1', 'line2', 'area', 'displayLocation')
                keysToAdd.push('area')
                break;
        }

        for (const keyToAdd of keysToAdd) {
            switch (keyToAdd){
                case 'displayLocation':
                    _values[keyToAdd] = true;
                    _fields[keyToAdd] = Yup.boolean().required();
                    break;
                case 'country_id':
                    _values[keyToAdd] = null;
                    _fields[keyToAdd] = Yup.number().required();
                    break;
                default:
                    _values[keyToAdd] = null;
                    _fields[keyToAdd] = Yup.string().required();
            }
        }


        for (const keyToDelete of keysToDelete) {
            delete _values[keyToDelete]
            delete _fields[keyToDelete]
        }

        setInitialValues({..._values})
        setValidationSchema(Yup.object().shape({
            ..._fields
        }))
    }

    function handleCompensationChange(compensation: string){

        let _values: any = {...formik.values}
        let _fields: any = {...validationSchema.fields}
        _values.compensation = compensation;

        if (['Hybrid', 'Fee'].includes(compensation)){
            _values.feeRangeMin = 0;
            _fields.feeRangeMin = Yup.number().required();

            _values.feeRangeMax = 0;
            _fields.feeRangeMax = Yup.number().required();

            _values.feeTrigger = 'Sale';
            _fields.feeTrigger = Yup.string().required();
        }else{
            delete _values.feeRangeMin
            delete _fields.feeRangeMin

            delete _values.feeRangeMax
            delete _fields.feeRangeMax

            delete _values.feeTrigger
            delete _fields.feeTrigger
        }

        if (['Hybrid', 'Fix'].includes(compensation)){
            _values.fixRangeMin = 0;
            _fields.fixRangeMin = Yup.number().required();

            _values.fixRangeMax = 0;
            _fields.fixRangeMax = Yup.number().required();

            _values.fixRangeDuration = "Daily"
            _fields.fixRangeDuration = Yup.string().required()
        }else{
            delete _values.fixRangeMin
            delete _fields.fixRangeMin

            delete _values.fixRangeMax
            delete _fields.fixRangeMax

            delete _values.fixRangeDuration
            delete _fields.fixRangeDuration
        }

        setInitialValues({..._values})
        setValidationSchema(Yup.object().shape({
            ..._fields
        }))
    }

    function handleBadgeClick(type: "sectors"|"specialisations", id: number){
        if (formik.values[type].includes(id)){
            formik.setFieldValue(type, [...formik.values[type].filter((prevId: number) => prevId !== id)])
        }else{
            formik.setFieldValue(type, [...formik.values[type], id])
        }
    }

    return <form onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className="form-control" name={'name'} id={"name"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                <label htmlFor="name">
                    Intitulé de l'annonce
                </label>
            </div>
            <FormError form={formik} field={'name'} />
        </div>
        <div className="col-12 mb-3">
            <Select
                label={"Lieux de la mission"}
                required={true}
                value={{label: "En personne (lieux précis)", value: 'OnSite'}}
                options={[
                    {label: "En personne (lieux précis)", value: 'OnSite'},
                    {label: "En personne, dans une zone délimitée", value: 'Area'},
                    {label: "Télétravail", value: 'Remote'},
                    {label: "Présentiel et télétravail", value: 'Hybrid'},
                    {label: "Déplacement fréquent", value: 'Travel'},
                ]}
                onChange={c => handleLocationChange(c.value)}
            />
            <FormError form={formik} field={'location'} />
        </div>
        {formik.values.location !== "Remote" && <div className="col-12 mb-3">
            <Select
                required={true}
                label={"Pays"}
                options={getCountries()}
                value={defaultAddress?.country ? {value: defaultAddress.country.id, label: defaultAddress.country.name, img: defaultAddress.country.flag} : undefined}
                onChange={o => console.log(o)}
            />
        </div>}
        {['OnSite', 'Hybrid'].includes(formik.values.location) && <>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className={'form-control'} defaultValue={defaultAddress?.line1} name={"line1"} id={"line1"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                    <label htmlFor="line1">
                        Adresse *
                    </label>
                </div>
                <FormError form={formik} field={"line1"} />
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className={'form-control'} defaultValue={defaultAddress?.line2} name={"line2"} id={"line2"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                    <label htmlFor="line2">
                        Complément d'adresse (optionel)
                    </label>
                </div>
                <FormError form={formik} field={"line2"} />
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className={'form-control'} defaultValue={defaultAddress?.city} name={"city"} id={"city"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                    <label htmlFor="city">
                        Ville
                    </label>
                </div>
                <FormError form={formik} field={"city"} />
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className={'form-control'} defaultValue={defaultAddress?.postalCode} name={"postalCode"} id={"postalCode"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                    <label htmlFor="postalCode">
                        Code postale
                    </label>
                </div>
                <FormError form={formik} field={"postalCode"} />
            </div>
            <div className="col-12 mb-3">
                <div className="form-check form-switch">
                    <input className="form-check-input" onChange={e => formik.setFieldValue('displayLocation', e.target.checked)} type="checkbox" role="switch" id="displayLocation" />
                    <label className="form-check-label" htmlFor="displayLocation">Afficher l'adresse postale dans l'annonce</label>
                </div>
            </div>
        </>}
        {['Area', 'Travel'].includes(formik.values.location) && <div className={'col-12 mb-3'}>
            <div className="form-floating">
                <input type="text" name={"area"} id={'area'} className={'form-control'} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                <label htmlFor="area">
                    {formik.values.location === 'Area' && "Lieu de la mission"}
                    {formik.values.location === 'Travel' && "Zone d'exercice de la mission"}
                </label>
            </div>
            <FormError form={formik} field={'area'} />
        </div>}
        <div className="col-12 mb-3">
            <div className="row">
                <div className="col-md-8 mb-3">
                    <Select
                        label={"Rémunération"}
                        required={true}
                        value={{label: "Fix et commission", value: 'Hybrid'}}
                        options={[
                            {label: "Fix", value: 'Fix'},
                            {label: "Commission", value: 'Fee'},
                            {label: "Fix et commission", value: 'Hybrid'}
                        ]}
                        onChange={c => handleCompensationChange(c.value)}
                    />
                    <FormError form={formik} field={'compensation'} />
                </div>
                <div className="col-md-4 mb-3">
                    <Select
                        label={"Devise de rémunération"}
                        required={true}
                        value={getCurrencyChoice('EUR')}
                        options={getCurrencies()}
                        onChange={c => handleCompensationChange(c.value)}
                    />
                    <FormError form={formik} field={'currency_id'} />
                </div>
            </div>

        </div>
        {['Hybrid', 'Fix'].includes(formik.values.compensation) && <div className={'col-12 mb-3'}>
            <h6>Fourchette de rémunération fix</h6>
            <div className="row align-items-center">
                <div className="col">
                    <div className="form-floating">
                        <input type="text" name={"fixRangeMin"} id={'fixRangeMin'} onChange={formik.handleChange} onBlur={formik.handleBlur} className="form-control"/>
                        <label htmlFor="fixRangeMin">
                            Minimum
                        </label>
                    </div>
                    <FormError form={formik} field={'fixRangeMin'} />
                </div>
                <div className="col-auto"><i className={'bi bi-arrow-left-right'}></i></div>
                <div className="col">
                    <div className="form-floating">
                        <input type="text" name={"fixRangeMax"} id={'fixRangeMax'} onChange={formik.handleChange} onBlur={formik.handleBlur} className="form-control"/>
                        <label htmlFor="fixRangeMax">
                            Maximum
                        </label>
                    </div>
                    <FormError form={formik} field={'fixRangeMax'} />
                </div>
                <div className="col-auto">
                    <Select
                        required={true}
                        label={"Par"}
                        value={{label: "Jour", value: "daily"}}
                        options={[
                            {label: "Heure", value: "hourly"},
                            {label: "Jour", value: "daily"},
                            {label: "Semaine", value: "weekly"},
                            {label: "Mois", value: "monthly"},
                            {label: "Année", value: "annualy"},
                        ]}
                        onChange={c => formik.setFieldValue('fixRangeDuration', c.value)}
                    />
                </div>
            </div>
        </div>}
        {['Hybrid', 'Fee'].includes(formik.values.compensation) && <div className={'col-12 mb-3'}>
            <h6>Fourchette de rémunération par commission</h6>
            <div className="row align-items-center">
                <div className="col">
                    <div className="form-floating">
                        <input type="text" name={"feeRangeMin"} id={'feeRangeMin'} onChange={formik.handleChange} onBlur={formik.handleBlur} className="form-control"/>
                        <label htmlFor="feeRangeMin">
                            Minimum
                        </label>
                    </div>
                    <FormError form={formik} field={'feeRangeMin'} />
                </div>
                <div className="col-auto"><i className={'bi bi-arrow-left-right'}></i></div>
                <div className="col">
                    <div className="form-floating">
                        <input type="text" name={"feeRangeMax"} id={'feeRangeMax'} onChange={formik.handleChange} onBlur={formik.handleBlur} className="form-control"/>
                        <label htmlFor="feeRangeMax">
                            Maximum
                        </label>
                    </div>
                    <FormError form={formik} field={'feeRangeMax'} />
                </div>
                <div className="col-auto">
                    <Select
                        required={true}
                        label={"Par"}
                        value={{label: "Vente", value: "Sale"}}
                        options={[
                            {label: "Vente", value: "Sale"},
                            {label: "Rendez-vous", value: "Appointment"},
                            {label: "Autre", value: "Other"}
                        ]}
                        onChange={c => formik.setFieldValue('feeTrigger', c.value)}
                    />
                </div>
            </div>
        </div>}
        <div className="col-12 mb-3">
            <h6>Sécteurs cible</h6>
            {sectors.map(s => <span className={`badge me-2 mb-2 cursor-pointer ${formik.values.sectors.includes(s.id) ? "bg-success" : 'bg-body-secondary text-dark'}`} onClick={() => handleBadgeClick('sectors' ,s.id)}>{s.name}</span>)}
        </div>
        <div className="col-12 mb-3">
            <h6>Compétences recherchés</h6>
            <div className="col-12 mb-3">
                <FormError form={formik} field={"specialisations"} />
                <ul className={"list-unstyled ps-3"}>
                    {services.map(s => <li>
                        <p className={"fw-semibold my-3"}>
                            {s.title}
                        </p>
                        <ul className={'list-unstyled ps-3'}>
                            {s.items.map(i => <li>
                                <div className="form-check">
                                    <input className="form-check-input" name={'specialisations'} type="checkbox" value={i.id} id={`service_${i.id}`} onChange={formik.handleChange} />
                                    <label className="form-check-label" htmlFor={`service_${i.id}`}>
                                        {i.title}
                                        <span className="clearfix"></span>
                                        <span className="form-text">{i.description}</span>
                                    </label>
                                </div>
                            </li>)}
                        </ul>
                    </li>)}
                </ul>
            </div>        </div>
        <div className="col-12 mb-3">
            <h6>Description de l'annonce</h6>
            <ReactQuill value={formik.values.description} onChange={value => formik.setFieldValue('description', value)} />
            <FormError form={formik} field={"description"} />
        </div>
        {error && <div className={'col-12 mb-3'}><ErrorCard error={error} /></div>}
        <div className="row">
            <div className="col-md-6 mb-3">
                <button type={'button'} onClick={() => {
                    formik.setFieldValue('status', 'Pending')
                    formik.submitForm()
                }} className="btn btn-light w-100">
                    {formik.isSubmitting ? <Loader size={"sm"} /> : <><i className={"bi bi-check"}></i> Enregistrer</>}
                </button>
            </div>
            <div className="col-md-6 mb-3">
                <button type={'button'} onClick={() => {
                    formik.setFieldValue('status', 'Published')
                    formik.submitForm()
                }} className="btn btn-primary w-100">
                    {formik.isSubmitting ? <Loader size={"sm"} /> : <><i className={"bi bi-send"}></i> Enregistrer et publier</>}
                </button>
            </div>
        </div>
    </form>
}

export default SupplierAssignmentAdd;