import React, {useEffect, useState} from "react";
import * as models from "../../../../../models/partnership";
import * as api from "../../../../../adapters/corporate/common/partnership";
import Loader from "../../../../../utils/loader";
import {Link, useNavigate} from "react-router-dom";
import SearchInput from "../../../../../utils/searchInput";
import {useAppSelector} from "../../../../../hooks";
import Pagination from "../../../../../utils/pagination";
import {getLogoUrl} from "../../../../../utils/image";
import ErrorCard from "../../../../error";
import {CorporateAccount} from "../../../../../models/account";

const CommonPartnershipList = () => {

    const activeAccount = useAppSelector(state => state.auth.user?.activeAccount) as CorporateAccount
    const authOrganization = activeAccount.organization;
    const key = authOrganization.type === "supplier" ? "provider" : "supplier";
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState(null);
    const [payload, setPayload] = useState<{count: number, rows: models.Partnerships}>({count: 0, rows: []});
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number }>({query: "", offset: 0})
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(resp => setPayload(resp.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb fs-xs">
                <li className="breadcrumb-item">
                    Partenaires
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher un partenaire..."} onChange={handleQueryChange} size={"default"} />
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        {error && <ErrorCard error={error} />}
        <ul className={'list-group'}>
            {payload.rows.map(r => <li className={"list-group-item shadow-hover cursor-pointer"} onClick={() => navigate(`/corporate/partnership/${r.id}`)}>
                <div className="row align-items-center">
                    <div className="col-auto">
                        <div className="avatar avatar-xxl">
                            <img src={getLogoUrl(r[key])} className="avatar-img rounded-circle"  alt={r[key].name}/>
                        </div>
                    </div>
                    <div className="col">
                        <p className="fw-bold mb-1">
                            {r[key].name}
                        </p>
                        <p className="d-flex align-items-center text-body mb-3 text-muted fs-xxs">
                            {r[key].billing?.city}, {r[key].billing?.postalCode}, {r[key].billing?.country.name}
                        </p>
                        <div className="col-12 mb-3">
                            {r[key].sectors.slice(0,2).map(s => <span className={"mb-2 me-2 badge bg-primary"}>{s.name}</span>)}
                        </div>
                    </div>
                    <div className="col-auto">
                        <i className={'bi bi-arrow-right'}></i>
                    </div>
                </div>
            </li>)}
        </ul>
        <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
    </div>
}

export default CommonPartnershipList;