import {get, post} from "../../../../xhr";
import encodeQueryData from "../../../../../utils/encodeQueryData";

interface Interface {
    amount?: number,
    weightLimit?: number,
    volumeLimit?: number,
    freeTreshold?: number|null,
    currencyId?: number,
    rate_id?: number
}

export function add(requestData: Interface){
    return post(`corporate/supplier/shipping/price`, requestData)
}

export function edit(price: number, requestData: Interface){
    return post(`corporate/supplier/shipping/price/${price}`, requestData);
}

export function list(requestData: {offset: number, rate_id: number}){
    return get(`corporate/supplier/shipping/price${encodeQueryData(requestData)}`);
}

export function show(price: number){
    return get(`corporate/supplier/shipping/price/${price}`);
}


