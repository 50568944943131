import React, {useState} from "react";
import * as api from "../../../../../adapters/corporate/common/stripe"
import {loadConnectAndInitialize} from '@stripe/connect-js';
import {
    ConnectPayments,
    ConnectPayouts,
    ConnectComponentsProvider,
} from '@stripe/react-connect-js';
import {TEST_PUBLISHABLE_KEY} from "../../../../../constants/stripe";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";

const StripeBalance = () => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null)

    const fetchClientSecret = async () => {
        try {
            const { data } = await api.balance();
            return data.client_secret
        }catch (e) {
            setError(e)
        }finally {
            setTimeout(() => {
                setLoading(false)
            }, 1500)
        }
    };

    const connectInstance = loadConnectAndInitialize({
        publishableKey: TEST_PUBLISHABLE_KEY,
        fetchClientSecret: fetchClientSecret,
        appearance: {
            variables: {
                colorPrimary: '#ff6f61',
                fontFamily: "Arial"
            },
        },
    });

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    return (
        <ConnectComponentsProvider connectInstance={connectInstance}>
            <ConnectPayments />
            <ConnectPayouts />
        </ConnectComponentsProvider>
    )

}

export default StripeBalance;