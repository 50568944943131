import React, {useEffect, useState} from "react";
import * as api from "../../../../adapters/shop/product";
import * as models from "../../../../models/product";
import Loader from "../../../../utils/loader";
import ShopProductCard from "../card";
import Pagination from "../../../../utils/pagination";
import ShopProductListFilterCategory from "./filter/category";
import {Country} from "../../../../models/country";
import {Currency} from "../../../../models/currency";

const ShopProductList = ({uuid, country, currency}: {uuid: string, country: Country, currency: Currency}) => {

    const [payload, setPayload] = useState<{count: number, rows: (models.Product & models.SellableProduct)[]}>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string, categoryId: number|null, order: "Popular"|"BestSeller"|"Latest"}>({offset: 0, query: "", categoryId: null, order: "Popular"})

    useEffect(() => {
        setLoading(true);
        api.list(uuid, {query: fetchParams.query, order: fetchParams.order, offset: fetchParams.offset, categoryId: fetchParams.categoryId, countryId: country!.id, currencyId: currency!.id})
            .then(resp => setPayload(resp.data))
            .then(() => setLoading(false))
    }, [uuid, fetchParams, country, currency])

    return <div className={"row"}>
        <div className="col-12 overflow-auto">
            <ShopProductListFilterCategory uuid={uuid} disabled={loading} setCategory={c => setFetchParams(prev => ({...prev, query: "", offset: 0, categoryId: c?.id || null}))} />
        </div>
        <div className="row justify-content-center mb-3">
            <div className="col-auto">
                <nav className="nav nav-tabs nav-overflow fs-xs mb-3">
                    <button onClick={() => setFetchParams(prev => ({...prev, order: "Popular", offset: 0}))} className={"nav-link text-uppercase" + (fetchParams.order === "Popular" ? " active " : "")}>
                        Plus populaire
                    </button>
                    <button onClick={() => setFetchParams(prev => ({...prev, order: "BestSeller", offset: 0}))} className={"nav-link text-uppercase" + (fetchParams.order === "BestSeller" ? " active " : "")}>
                        Meilleurs ventes
                    </button>
                    <button onClick={() => setFetchParams(prev => ({...prev, order: "Latest", offset: 0}))} className={"nav-link text-uppercase" + (fetchParams.order === "Latest" ? " active " : "")}>
                        Nouveautés
                    </button>
                </nav>
            </div>
        </div>
        <div className="col-12">
            {loading && <div className={'col-12 py-10 text-center'}>
                <Loader />
            </div>}
            {!loading && <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                {payload.rows.map(r => <div className={'col mb-3'}>
                    <ShopProductCard product={r} uuid={uuid} currency={currency} key={r.id} />
                </div>)}
                <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))}/>
            </div>}
        </div>
    </div>
}

export default ShopProductList;