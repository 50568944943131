import FormError from "../../../../utils/formError";
import Loader from "../../../../utils/loader";
import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../adapters/customer/address";
import {getCountries, getCountryChoice} from "../../../../utils/countries";
import Select from "../../../../utils/select";
import {CustomerAddress} from "../../../../models/account";
import {useAppSelector} from "../../../../hooks";
import {Country} from "../../../../models/country";

const CustomerAddressAdd = ({onSuccess, values}: {onSuccess: (address: CustomerAddress) => void, values?: {country: Country, city: string, postalCode: string}}) => {

    const auth = useAppSelector(state => state.auth)

    const formik = useFormik({
        initialValues: {
            country_id: values?.country.id || "",
            fullName: `${auth.user!.activeAccount.firstname} ${auth.user!.activeAccount.lastname}`,
            city: values?.city || "",
            postalCode: values?.postalCode || "",
            line1: "",
            line2: "",
            default: true,
        },
        validationSchema: Yup.object().shape({
            country_id: Yup.number().required(),
            fullName: Yup.string().required(),
            city: Yup.string().required(),
            postalCode: Yup.string().required(),
            line1: Yup.string().required(),
            line2: Yup.string().nullable(),
            default: Yup.boolean().required(),
        }),
        enableReinitialize: true,
        onSubmit:(values, formikHelpers) => {
            api.add(values)
                .then(r => onSuccess(r.data))
                .then(() => formikHelpers.setSubmitting(false))
        }
    })

    return <form onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <Select
                label={"Pays *"}
                disabled={!!values}
                required={true}
                options={getCountries()}
                value={values ? getCountryChoice(values.country.alpha2) : undefined}
                onChange={c => formik.setFieldValue("country_id", c.id)}
            />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className="form-control" defaultValue={`${auth.user!.activeAccount.firstname} ${auth.user!.activeAccount.lastname}`} name={"fullName"} id={"fullName"} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                <label htmlFor="fullName">
                    Nom complet
                </label>
            </div>
            <FormError form={formik} field={"fullName"} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className="form-control" name={"line1"} id={"line1"} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                <label htmlFor="line1">
                    Addresse *
                </label>
            </div>
            <FormError form={formik} field={"line1"} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className="form-control" name={"line2"} id={"line2"} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                <label htmlFor="line2">
                    Complement d'adresse
                </label>
            </div>
            <FormError form={formik} field={"line2"} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" defaultValue={values?.city} disabled={!!values} className="form-control" name={"city"} id={"city"} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                <label htmlFor="city">
                    Ville
                </label>
            </div>
            <FormError form={formik} field={"city"} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" defaultValue={values?.postalCode} disabled={!!values} className="form-control" name={"postalCode"} id={"postalCode"} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                <label htmlFor="postalCode">
                    Code postale
                </label>
            </div>
            <FormError form={formik} field={"postalCode"} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-check form-switch">
                <input defaultChecked={true} className="form-check-input" type="checkbox" role="switch" id="default" name={'default'} onChange={e => formik.setFieldValue("default", e.target.checked)} />
                <label className="form-check-label" htmlFor="default">
                    Adresse par défaut
                </label>
            </div>
        </div>
        <button className="btn w-100 btn-light">
            {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-check'}></i> Enregistrer</>}
        </button>
    </form>
}

export default CustomerAddressAdd;