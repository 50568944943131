import {useEffect, useState} from "react";
import * as api from "../../../../../adapters/corporate/supplier/conversion";
import {Currency} from "../../../../../models/currency";
import {Affiliation, Conversion, ConversionRule, Conversions} from "../../../../../models/affiliation";
import convertToDecimal from "../../../../../utils/currencyConverter";
import Loader from "../../../../../utils/loader";
import {PaymentMethod} from "../../../../../models/paymentMethod";
import CommonPaymentMethodSelect from "../../../../common/paymentMethod/select";
import ConversionPayment from "./payment";

const SupplierConversionPay = ({conversions, affiliation, handlePaymentSucceed}: {conversions: Conversions, affiliation: Affiliation, handlePaymentSucceed: (conversions: Conversions) => void}) => {

    const [loading, setLoading] = useState(true);
    const [currency, setCurrency] = useState<Currency>(affiliation.currencies[0]);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();

    const [payment, setPayment] = useState<{
        datum: {rule: ConversionRule, conversion: Conversion, amount: number, fee: number, currency: Currency}[];
        total_fee: number;
        currency: Currency;
    }>()

    useEffect(() => {
        setLoading(true)
        api.paymentData({currency_id: currency.id, ids: conversions.map(c => c.id)})
            .then(r => setPayment(r.data))
            .finally(() => setLoading(false))
    }, [currency.id, conversions])

    return <>
        <div className="col-12 mb-5">
            <h6>
                Devise
            </h6>
            <div className="btn-group">
                {affiliation.currencies.map(c => <button className={`btn btn-xs ${c.id === currency.id ? "btn-primary" : 'btn-light'}`} onClick={() => setCurrency(c)}>
                    {c.name} ({c.symbol})
                </button>)}
            </div>
        </div>
        <div className={"col-12 mb-5"}>
            <h6>
                Récapitulatif du paiement
            </h6>
            {loading ? <Loader /> : <>
                {payment && <table className={"table"}>
                    <thead>
                    <tr>
                        <th>
                            id
                        </th>
                        <th>
                            Règle
                        </th>
                        <th>
                            Commission
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {payment.datum.map(data => <tr>
                        <td>{data.conversion.id}</td>
                        <td>{data.rule.name}</td>
                        <td>{convertToDecimal(data.fee, payment.currency)}</td>
                    </tr>)}
                    </tbody>
                    <tbody>
                        <tr>
                            <td></td>
                            <th>
                                Total
                            </th>
                            <td>
                                {convertToDecimal(payment.total_fee, payment.currency)}
                            </td>
                        </tr>
                    </tbody>
                </table>}
                </>}
            <div className="col-12 mb-3">
                <h6>Moyen de paiement</h6>
                <CommonPaymentMethodSelect handleSelect={pm => setPaymentMethod(pm)} selected={paymentMethod} />
            </div>
            <ConversionPayment handlePaymentSucceed={handlePaymentSucceed} conversions={payment?.datum.map(data => data.conversion)} currency={currency} paymentMethod={paymentMethod} />
        </div>
    </>
}

export default SupplierConversionPay;