import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import * as api from "../../../../adapters/customer/quote"
import Loader from "../../../../utils/loader";
import * as models from "../../../../models/quote";
import convertToDecimal from "../../../../utils/currencyConverter";
import QuoteCondition from "../../../quote/condition";
import QuoteShowRows from "../../../quote/rows";
import CorporateInvoiceDownload from "../../../corporate/common/invoice/download";
import ErrorCard from "../../../error";

const CustomerQuoteShow = () => {

    const [loading, setLoading] = useState(true);
    const [quote, setQuote] = useState<models.Quote>();
    const [error, setError] = useState(null)
    const {id} = useParams();

    useEffect(() => {
        setLoading(true)
        api.show(Number(id))
            .then(r => setQuote(r.data))
            .then(() => setLoading(false))
    }, [id])

    function complete(){
        if (quote){
            api.update(quote.id, {status: "Completed"})
                .then(r => setQuote(r.data))
                .catch(e => setError(e))
        }
    }

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!quote){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <div className="display-5">
                    <i className={'bi bi-search'}></i> Devis introuvable
                </div>
            </div>
        </div>
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/customer/quote">Vos devis</Link>
                </li>
                <li className="breadcrumb-item">
                    {quote.number}
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3">
            <div className="row">
                <div className="col-md-3">
                    <div className="col-12 mb-3">
                        <div className="card border border-1">
                            <div className="card-header">
                                <h6>
                                    Représentant commercial
                                </h6>
                            </div>
                            <div className="card-body">
                                <table className={"table-borderless"}>
                                    <tbody>
                                    <tr>
                                        <th>
                                            <i className={'bi bi-person'}></i>
                                        </th>
                                        <td>
                                            {quote.project.account.firstname} {quote.project.account.lastname}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <i className={'bi bi-phone'}></i>
                                        </th>
                                        <td>
                                            {quote.project.account.countryCode} {quote.project.account.tel}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {quote.signature && quote.signature.signedAt && <div className={'col-12 mb-3'}>
                        <div className={"card border border-1 border-success bg-success-soft text-success"}>
                            <div className="card-body">
                                <p>
                                    Devis signé le {new Date(quote.signature.signedAt).toLocaleDateString()} à {new Date(quote.signature.signedAt).toLocaleTimeString()}.
                                </p>
                                <p>
                                    Signature: {quote.signature.signature}
                                </p>
                            </div>
                        </div>
                    </div>}
                    <div className={'col-12 mb-3'}>
                        <div className="card border border-1">
                            <div className="card-header">
                                <h6>
                                    Paiement
                                </h6>
                            </div>
                            <div className="card-body">
                                <table className="table-borderless">
                                    <tbody>
                                        <tr>
                                            <th>
                                                Méthode de paiement
                                            </th>
                                            <td>
                                                **** **** **** {quote.payment.paymentMethod.last4}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Montant du paiement
                                            </th>
                                            <td>
                                                {convertToDecimal(quote.payment.amount, quote.payment.currency)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Date
                                            </th>
                                            <td>
                                                {quote.payment.paidAt ? new Date(quote.payment.paidAt).toLocaleDateString() + " à " + new Date(quote.payment.paidAt).toLocaleTimeString() : <i className={'bi bi-x'}></i>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Référence de transaction
                                            </th>
                                            <td>
                                                {quote.payment.uuid}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className={'col-12 mb-3'}>
                        <div className="card border border-1">
                            <div className="card-header">
                                <h6>
                                    Factures
                                </h6>
                            </div>
                            <div className="card-body">
                                <ul className="list-group list-group-flush">
                                    <li className={'list-group-item'}>
                                        {quote.invoice && <div className="row align-items-center">
                                            <div className="col">
                                                Facture
                                            </div>
                                            <div className="col-auto">
                                                <CorporateInvoiceDownload invoice={quote.invoice}/>
                                            </div>
                                        </div>}
                                        {quote.deposit && quote.deposit.invoice && <div className="row align-items-center">
                                            <div className="col">
                                                Facture d'acompte
                                            </div>
                                            <div className="col-auto">
                                                <CorporateInvoiceDownload invoice={quote.deposit.invoice}/>
                                            </div>
                                        </div>}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="row">
                        {quote.status === "Validation" && <div className={'col-12 mb-3'}>
                            <div className="card border border-1 border-success">
                                <div className="card-body">
                                    <h6>
                                        Votre prestataire à terminé.
                                    </h6>
                                    <p>
                                        Vous pouvez désormais validé la prestation pour débloquer le paiement.
                                    </p>
                                    <button className={"btn btn-primary"} onClick={() => complete()}>
                                        <i className={"bi bi-check"}></i> Valider la prestation
                                    </button>
                                </div>
                            </div>
                        </div>}
                        <div className="col-12 mb-3">
                            <QuoteShowRows quote={quote} />
                        </div>
                        <div className="col-12 mb-3">
                            <QuoteCondition quote={quote} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default CustomerQuoteShow;