import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Parcel} from "../../../../../models/order";
import * as api from "../../../../../adapters/corporate/supplier/parcel";
import Loader from "../../../../../utils/loader";
import ParcelStatusDropdown from "../status/dropdown";
import {getCoverUrl} from "../../../../../utils/image";
import convertToDecimal from "../../../../../utils/currencyConverter";

const SupplierParcelShow = () => {

    const params = useParams();
    const [parcel, setParcel] = useState<Parcel>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true)
        api.show(Number(params.id))
            .then(r => setParcel(r.data))
            .then(() => setLoading(false))
    }, [params.id])

    if (loading){
        return <div className={'container-fluid py-10 text-center'}>
            <Loader />
        </div>
    }

    if (!parcel){
        return <div className={'container-fluid py-10 text-center'}>
            <span className={'display-6'}>
                <i className={"bi bi-search"}></i> Livraison non trouvé
            </span>
        </div>
    }

    return <div className={'container-fluid'}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/corporate/supplier/parcel">Livraison</Link>
                </li>
                <li className="breadcrumb-item">
                    {parcel.trackingNumber}
                </li>
            </ol>
        </div>
        <div className="row">
            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>
                            Détails de la livraison
                        </h6>
                    </div>
                    <div className="card-body">
                        <table className={"table table-borderless"}>
                            <tbody>
                            <tr>
                                <th>
                                    Statut
                                </th>
                                <td>
                                    <ParcelStatusDropdown parcel={parcel} setParcel={setParcel} />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Commande
                                </th>
                                <td>
                                    <Link to={`/corporate/supplier/order/${parcel.order.id}`}>
                                        {parcel.order.number}
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Tracking number
                                </th>
                                <td>
                                    {parcel.trackingNumber}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Date de commande
                                </th>
                                <td>
                                    {new Date(parcel.order.orderedAt).toLocaleString()}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Date de livraison
                                </th>
                                <td>
                                    {parcel.shippedAt ? new Date(parcel.shippedAt).toLocaleString() : <i className={'bi bi-x'}></i>}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Date de récéption
                                </th>
                                <td>
                                    {parcel.deliveredAt ? new Date(parcel.deliveredAt).toLocaleString() : <i className={'bi bi-x'}></i>}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Poids
                                </th>
                                <td>
                                    {parcel.weight.toLocaleString('FR-fr')} g
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Volume
                                </th>
                                <td>
                                    {parcel.volume.toLocaleString('FR-fr')} cm3
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-md-6 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>
                            Lieux de traitement
                        </h6>
                    </div>
                    <div className="card-body">
                        {parcel.address ? <>{parcel.address.line1}, {parcel.address.city}, {parcel.address.postalCode}, {parcel.address.country.name}</> : <>Gestion des stock désactivé</>}
                    </div>
                </div>
            </div>
            <div className="col-md-6 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>
                            Destination
                        </h6>
                    </div>
                    {parcel.order.shipping && <div className="card-body">
                        {parcel.order.shipping.line1}, {parcel.order.shipping.city}, {parcel.order.shipping.postalCode}, {parcel.order.shipping.country.name}
                    </div>}
                </div>
            </div>
            <div className="col-md-6 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>Forfait</h6>
                    </div>
                    <div className="card-body">
                        <table className="table table-borderless">
                            <tbody>
                            <tr>
                                <th>
                                    Forfait
                                </th>
                                <td>
                                    <Link to={`/corporate/supplier/shipping/rate/${parcel.options[0].price.rate.id}`} target={'_blank'}>
                                        {parcel.options[0].price.rate.name} <i className="bi bi-box-arrow-up-right"></i>
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Délai minimum
                                </th>
                                <td>
                                    {parcel.options[0].price.rate.delayMin} jour(s)
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Délai maximum
                                </th>
                                <td>
                                    {parcel.options[0].price.rate.delayMax} jour(s)
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-md-6 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>Tarif</h6>
                    </div>
                    <div className="card-body">
                        <table className="table table-borderless">
                            <tbody>
                            <tr>
                                <th>
                                    Frais de livraison HT
                                </th>
                                <td>
                                    {convertToDecimal(parcel.options[0].priceData.amount_excluding_tax, parcel.options[0].priceData.currency)}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Taxes
                                </th>
                                <td>
                                    {convertToDecimal(parcel.options[0].priceData.tax_amount, parcel.options[0].priceData.currency)}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Frais de livraison TTC
                                </th>
                                <td>
                                    {convertToDecimal(parcel.options[0].priceData.amount_including_tax, parcel.options[0].priceData.currency)}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>Articles</h6>
                    </div>
                    <div className="card-body">
                        <ul className="list-group list-group-lg list-group-flush overflow-auto">
                            {parcel.items.map(i => <li className="list-group-item" key={i.id}>
                                <div className="row align-items-center">
                                    <div className="col-3">
                                        <img className="img-fluid" src={getCoverUrl(i.item.price.product)} alt="..."/>
                                    </div>
                                    <div className="col-8">
                                        <p className="fs-sm fw-bold mb-6">
                                        <Link to={`/corporate/supplier/product/${i.item.price.product.id}`} target={'_blank'}>
                                            {i.item.price.product.name} <i className="bi bi-box-arrow-up-right"></i>
                                        </Link>
                                            <br />
                                            <span className="text-body">
                                            Quantité: {i.quantity}
                                        </span>
                                            <br />
                                            {i.item.declinationChoices?.length > 0 && i.item.declinationChoices.map(d => <>
                                                <br />
                                                <span className="form-text">{d.declination.name}: <span className={'fw-semibold'}>{d.value}</span></span>
                                            </>)}
                                        </p>
                                    </div>
                                </div>
                            </li>)}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default SupplierParcelShow;