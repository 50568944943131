import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from 'yup';
import * as api from "../../../../../adapters/corporate/supplier/bundle";
import SupplierProductPriceSelect from "../../product/price/select";
import {Product, SellableProduct} from "../../../../../models/product";
import {Price, Prices} from "../../../../../models/price";
import SupplierBundleAddItem from "./item";
import FormError from "../../../../../utils/formError";
import Loader from "../../../../../utils/loader";
import {Bundle} from "../../../../../models/bundle";
import ErrorCard from "../../../../error";
import SupplierBundlePreview from "../preview";
import {useAppSelector} from "../../../../../hooks";
import {CorporateAccount} from "../../../../../models/account";

const SupplierBundleAdd = ({onSuccess}: {onSuccess: (bundle: Bundle) => void}) => {


    const activeAccount = useAppSelector(state => state.auth.user?.activeAccount) as CorporateAccount
    const authOrganization = activeAccount.organization;

    const [error, setError] = useState(null)

    const [items, setItems] = useState<{product: Product & SellableProduct, prices: Prices}[]>([]);

    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            items: []
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            description: Yup.string().optional(),
            items: Yup.array().of(Yup.object().shape({
                product_id: Yup.number().required(),
                prices: Yup.array().of(Yup.object().shape({
                    price_id: Yup.number().required()
                })).min(1).required()
            })).min(1).required()
        }),
        onSubmit: (values, {setSubmitting}) => {
            api.add(values)
                .then(r => onSuccess(r.data))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    });

    useEffect(() => {
        formik.setFieldValue('items', items.map(i => ({product_id: i.product.id, prices: i.prices.map(p => ({price_id: p.id}))})))
    }, [items])

    function handleProductSelect(product: Product & SellableProduct, price: Price){
        setItems(prev => [...prev, {product, prices: [price]}])
    }

    return <form className={"col-12"} onSubmit={formik.handleSubmit}>
        <div className={"row"}>
            <div className="col-md-5 border-end-md border-1 mb-3">
                <div className="col-12 mb-3">
                    <h6>
                        Détails de l'offre
                    </h6>
                    <div className="col-12 mb-3">
                        <div className="form-floating">
                            <input type="text" className="form-control" name={"name"} id={"name"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            <label htmlFor="name">Nom</label>
                        </div>
                        <FormError form={formik} field={'name'} />
                    </div>
                    <div className="col-12 mb-3">
                        <div className="form-floating">
                            <input type="text" className="form-control" name={"description"} id={"description"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            <label htmlFor="name">Description</label>
                        </div>
                        <FormError form={formik} field={'description'} />
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <h6>
                        Produits
                    </h6>
                    <FormError form={formik} field={'items'} />
                    <div className="row">
                        {items.map((item, index) => <SupplierBundleAddItem
                            item={item}
                            removeItem={() => {
                                const updatedArray = [...items]
                                updatedArray.splice(index, 1)
                                setItems(updatedArray)
                            }}
                            updateItem={updated => {
                                const updatedArray = [...items]
                                updatedArray[index] = updated;
                                setItems(updatedArray)
                            }}
                        />)}
                    </div>
                    <div className="col-12 mb-3">
                        <SupplierProductPriceSelect label={"Rechercher un produit"} handleChange={handleProductSelect} />
                    </div>
                </div>
                {error && <div className={"col-12 mb-3"}><ErrorCard error={error} /></div>}
                <button disabled={formik.isSubmitting} type={'submit'} className={"btn btn-primary w-100"}>
                    {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-plus-circle'}></i> Ajouter</>}
                </button>
            </div>
            <div className="col-md-7 mb-3">
                <SupplierBundlePreview organization={authOrganization} items={items} />
            </div>
        </div>
    </form>
}

export default SupplierBundleAdd;