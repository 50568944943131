import * as models from "../../../../../../../../models/shipping";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../../../../adapters/corporate/supplier/shipping/rate";
import FormError from "../../../../../../../../utils/formError";
import Loader from "../../../../../../../../utils/loader";
import {Offcanvas} from "bootstrap";

const SupplierShippingRateEdit = ({rate, handleSuccess}: {rate: models.ShippingRate, handleSuccess: (r: models.ShippingRate) => void}) => {

    const formik = useFormik({
        initialValues: {
            name: rate.name,
            delayMin: rate.delayMin,
            delayMax: rate.delayMax,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            delayMin: Yup.number().required().min(1),
            delayMax: Yup.number()
                .required()
                .min(1)
                .test('is-greater', 'delayMax must be greater than delayMin', function (value) {
                    const { delayMin } = this.parent; // Accessing delayMin from the parent object
                    return value > delayMin;
                }),
        }),
        onSubmit: (values, formikHelpers) => {
            api.edit(rate.id, values)
                .then(r => handleSuccess(r.data))
                .then(() => formikHelpers.setSubmitting(false))
                .then(() => close())
        }
    })

    function close(){
        let el = document.getElementById("offcanvasShippingRateEdit")

        if (el){
            let offcanvas = new Offcanvas(el)
            offcanvas.hide();
        }
    }

    return <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasShippingRateEdit"
                aria-labelledby="offcanvasShippingRateEditLabel">
        <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasShippingRateEditLabel"><i className={'bi bi-pencil'}></i> Ajouter un forfait</h5>
            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvasShippingRateEdit" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            {rate && <form onSubmit={formik.handleSubmit}>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="text" defaultValue={rate.name} className={'form-control'} name={"name"} id={"name"}
                               onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                        <label htmlFor="name">
                            Nom *
                        </label>
                    </div>
                    <FormError form={formik} field={"name"}/>
                </div>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="number" defaultValue={rate.delayMin} className={'form-control'} name={"delayMin"}
                               id={"delayMin"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                        <label htmlFor="delayMin">
                            Délai minimum (jour) *
                        </label>
                    </div>
                    <FormError form={formik} field={"delayMin"}/>
                </div>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="number" defaultValue={rate.delayMax} className={'form-control'} name={"delayMax"}
                               id={"delayMax"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                        <label htmlFor="delayMax">
                            Délai maximum (jour) *
                        </label>
                    </div>
                    <FormError form={formik} field={"delayMax"}/>
                </div>
                <button className="btn btn-light w-100 mb-3">
                    {formik.isSubmitting ? <Loader/> : <><i className={'bi bi-check'}></i> Valider</>}
                </button>
            </form>}
        </div>
    </div>
}

export default SupplierShippingRateEdit;