import * as models from "../../../../../../../../../models/shipping";
import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../../../../../adapters/corporate/supplier/shipping/price";
import {Offcanvas} from "bootstrap";
import FormError from "../../../../../../../../../utils/formError";
import Loader from "../../../../../../../../../utils/loader";
import Select from "../../../../../../../../../utils/select";
import {getCurrencies, getCurrencyChoice} from "../../../../../../../../../utils/currencies";
import {getCentAmount} from "../../../../../../../../../utils/currencyConverter";

const SupplierShippingRatePriceEdit = ({price, handleSuccess}: {price: models.ShippingRatePrice, handleSuccess: (p: models.ShippingRatePrice) => void}) => {

    const formik = useFormik({
        initialValues: {
            amount: getCentAmount(price.amount, price.currency),
            weightLimit: price.weightLimit,
            volumeLimit: price.volumeLimit,
            freeTreshold: price.freeTreshold,
            currency_id: price.currency.id,
        },
        validationSchema: Yup.object().shape({
            amount: Yup.number().required(),
            weightLimit: Yup.number().required(),
            volumeLimit: Yup.number().required(),
            freeTreshold: Yup.number().nullable(),
            currency_id: Yup.number().required().min(1),
        }),
        onSubmit: (values, formikHelpers) => {
            api.edit(price.id, values)
                .then(r => handleSuccess(r.data))
                .then(() => formikHelpers.setSubmitting(false))
                .then(() => close())
        }
    })

    function close(){
        let el = document.getElementById(`offcanvasShippingRatePriceEdit_${price.id}`)

        if (el){
            (new Offcanvas(el)).hide()
        }
    }

    return <div className="offcanvas offcanvas-end" tabIndex={-1} id={`offcanvasShippingRatePriceEdit_${price.id}`}
                aria-labelledby={`offcanvasShippingRatePriceEdit_${price.id}Label`}>
        <div className="offcanvas-header">
            <h5 className="offcanvas-title" id={`offcanvasShippingRatePriceEdit_${price.id}Label`}><i className={'bi bi-pencil'}></i> Mettre à jour un prix</h5>
            <button type="button" className="btn-close btn-close-white" data-bs-dismiss={`offcanvasShippingRatePriceEdit_${price.id}`} aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12 mb-3">
                    <Select
                        multiple={false}
                        required={true}
                        label={"Devise *"}
                        options={getCurrencies()}
                        value={getCurrencyChoice(price.currency.code)}
                        onChange={o => formik.setFieldValue('currency_id', o.id)}
                    />
                </div>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="number" step={0.01} defaultValue={price.amount} className={'form-control'} name={"amount"} id={"amount"}
                               onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                        <label htmlFor="amount">
                            Prix de base *
                        </label>
                    </div>
                    <FormError form={formik} field={"amount"}/>
                </div>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="number" step={0.01} defaultValue={price.weightLimit} className={'form-control'} name={"weightLimit"}
                               id={"weightLimit"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                        <label htmlFor="weightLimit">
                            Limite de poid (grammes)
                        </label>
                    </div>
                    <FormError form={formik} field={"weightLimit"}/>
                </div>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="number" step={0.01} defaultValue={price.volumeLimit} className={'form-control'} name={"volumeLimit"}
                               id={"volumeLimit"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                        <label htmlFor="volumeLimit">
                            Limite de dimension (cm cube)
                        </label>
                    </div>
                    <FormError form={formik} field={"volumeLimit"}/>
                </div>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="number" defaultValue={price.freeTreshold || 0} step={0.01} className={'form-control'} name={"freeTreshold"}
                               id={"freeTreshold"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                        <label htmlFor="discount">
                            Livraison offerte à partir de
                        </label>
                    </div>
                    <FormError form={formik} field={"freeTreshold"}/>
                </div>
                <button className="btn btn-light w-100 mb-3">
                    {formik.isSubmitting ? <Loader/> : <><i className={'bi bi-check'}></i> Valider</>}
                </button>
            </form>
        </div>
    </div>
}

export default SupplierShippingRatePriceEdit;