import {Quote, QuoteRow} from "../../../../../../models/quote";
import convertToDecimal from "../../../../../../utils/currencyConverter";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import OffcanvasComponent from "../../../../../offcanvas";
import SupplierQuoteRowUpdate from "../update";

const SupplierQuoteRowShow = ({quote, row, setRow}: {quote: Quote, row: QuoteRow, setRow: (row: QuoteRow) => void}) => {

    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const RowName = () => {

        switch (row.type){
            case "custom":
                return <span>
                    {row.name}
                </span>
        }

        return <></>
    }

    const RowDescription = () => {

        switch (row.type){
            case "custom":
                return <span>{row.description}</span>
        }

        return <></>
    }

    const RowUnit = () => {

        switch (row.type){
            case "custom":
                return <span>{row.unit?.label || ""}</span>
        }

        return <></>
    }

    return <tr>
        <td>
            <RowName />
        </td>
        <td>
            <RowDescription />
        </td>
        <td>
            {convertToDecimal(row.priceData.unit_amount_excluding_tax, row.priceData.currency)}
        </td>
        <td>
            {row.priceData.tax_rate ? <>
                {row.priceData.tax_rate.percentage}%
                <div className="clearfix"></div>
                <span className={"small text-muted"}>{row.priceData.tax_rate.name}</span>
            </> : "N/A"}
        </td>
        <td>
            {convertToDecimal(row.priceData.unit_amount_including_tax, row.priceData.currency)}
        </td>
        <td>
            {row.quantity} <RowUnit />
        </td>
        <td>
            {convertToDecimal(row.priceData.total_amount_including_tax, row.priceData.currency)}
        </td>
        {quote.status === "Pending" && <td>
            <div className="dropdown">
                <button className="btn btn-xxs" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                    <i className={"bi bi-three-dots-vertical"}></i>
                </button>
                <ul className="dropdown-menu">
                    <li className={"dropdown-item"} onClick={() => setShowOffcanvas(true)}>
                        <i className={'bi bi-pencil text-info'}></i> Modifier
                    </li>
                    <li className={"dropdown-item"}>
                        <i className={'bi bi-dash-circle text-danger'}></i> Retirer
                    </li>
                </ul>
            </div>
            <OffcanvasComponent title={`Modifier une ligne`} show={showOffcanvas}
                                onHide={() => setShowOffcanvas(false)}>
                <SupplierQuoteRowUpdate quoteRow={row} handleSuccess={row => {
                    setRow(row)
                    setShowOffcanvas(false)
                }} quote={quote}/>
            </OffcanvasComponent>
        </td>}
    </tr>
}

export default SupplierQuoteRowShow;