import React, {useEffect, useState} from "react";
import {QuoteRows, Quote} from "../../../models/quote";
import Loader from "../../../utils/loader";
import * as api from "../../../adapters/quote";
import ErrorCard from "../../error";
import QuoteRowShow from "./show";
import QuoteSummary from "../summary";

const QuoteShowRows = ({quote}: {quote: Quote}) => {

    const [loading, setLoading] = useState(true)
    const [payload, setPayload] = useState<{count: number, rows: QuoteRows}>({count: 0, rows: []})
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true)
        api.rows(quote.uuid)
            .then(r => setPayload(r.data))
            .catch(error => setError(error))
            .finally(() => setLoading(false))
    }, [])

    return <div className={"card border border-1"}>
        <div className="card-header">
            <h6>
                Détail du devis
            </h6>
        </div>
        <div className="card-body">
            {loading && <div className={'col-12'}>
                <Loader />
            </div>}
            <div className="table-responsive">
                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>
                            Produit
                        </th>
                        <th>
                            Description
                        </th>
                        <th>
                            PU HT
                        </th>
                        <th>
                            Tax
                        </th>
                        <th>
                            PU TTC
                        </th>
                        <th>
                            Quantité
                        </th>
                        <th>
                            Total TTC
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {payload.rows.length ? payload.rows.map((r, key) => <QuoteRowShow key={key} row={r} />) : <tr className={"list-group-item"}>
                        <td colSpan={6}><i className={'bi bi-search'}></i> Aucun résultat</td>
                    </tr>}
                    </tbody>
                </table>
            </div>
            <div className="row justify-content-end">
                <div className="col-md-4 mb-3">
                    <QuoteSummary quote={quote} />
                </div>
            </div>
            {error && <ErrorCard error={error} />}
        </div>
    </div>
}

export default QuoteShowRows;