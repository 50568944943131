import {Returns} from "../../../../../models/returns";
import {getCoverUrl} from "../../../../../utils/image";
import convertToDecimal from "../../../../../utils/currencyConverter";
import ReturnsStatusPlaceholder from "../../../../common/returns/status/placeholder";
import {reason_labels} from "../../../../../constants/returns";
import React, {useState} from "react";
import ModalComponent from "../../../../modal";
import ReturnStatusPlaceholder from "../../../../common/return/status/placeholder";

const CustomerReturnListItem = ({returns}: {returns: Returns}) => {

    const [showModal, setShowModal] = useState(false);

    return <>
        <div className={"col-12 mb-3"}>
            <div className="card border border-1">
                <div className="row">
                    <div className="col-md-5">
                        <div className="card br-0">
                            <img src={`${getCoverUrl(returns.item.price.product)}`} alt="" className="card-img-top br-0"/>
                            <div className="card-body bg-white">
                                <div className="row">
                                    <div className="col">
                                        <p className="d-block fw-bold text-body mb-1">
                                            {returns.item.name}
                                        </p>
                                        <p className="fs-xs text-muted mb-0">
                                            Quantité: {returns.quantity}
                                        </p>
                                    </div>
                                    <div className="col-auto">
                                        <div className="fs-sm fw-bold text-primary">
                                            {convertToDecimal(returns.item.priceData.total_amount_including_tax, returns.item.priceData.currency)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="card-body">
                            <div className="col-12 mb-3">
                                <ReturnsStatusPlaceholder status={returns.status} />
                            </div>
                            <div className="col-12 mb-3">
                                <p className={'fw-semibold'}>
                                    Description du problème
                                </p>
                                <ul>
                                    <li>
                                        {reason_labels[returns.reason1]}
                                    </li>
                                    {returns.reason2 && <li>
                                        {reason_labels[returns.reason2]}
                                    </li>}
                                    {returns.reason3 && <li>
                                        {reason_labels[returns.reason3]}
                                    </li>}
                                </ul>
                            </div>
                            <div className="col-12 text-end">
                                {returns.status === "Accepted" && <button className={"btn btn-primary"} onClick={() => setShowModal(true)}>
                                    Suivre mon retour
                                </button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {returns.status === "Accepted" && <ModalComponent size={"modal-lg"} title={"Suivre mon retour"} show={showModal} onHide={() => setShowModal(false)}>
            {returns.hasReturn && <div className={"col-12 mb-3"}>
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>Renvoyer vos articles à l'adresse ci-dessous :</h6>
                    </div>
                    <div className="card-body">
                        <p>
                            {returns.address.line1}, {returns.address.city}, {returns.address.postalCode}, {returns.address.country.name}
                        </p>
                    </div>
                    <div className="card-footer">
                        <ReturnStatusPlaceholder status={returns.status} />
                    </div>
                </div>
            </div>}
            {returns.type === "Replacement" && <div className={"col-12"}>
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>Votre colis de remplacement</h6>
                    </div>
                    <div className="card-body">
                        <p>
                            Le marchand à choisi de remplacer vos articles.
                        </p>
                        <p>
                            Le statut de votre colis est <span><ReturnStatusPlaceholder status={returns.replacementStatus} /></span>
                        </p>
                        <p>
                            Le statut N° de tracking de votre colis est {returns.replacementTrackingNumber}
                        </p>
                    </div>
                </div>
            </div>}
            {returns.type === "Refund" && <div className={"col-12"}>
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>Remboursement</h6>
                    </div>
                    <div className="card-body">
                        <p>
                            Le marchand à choisi de rembourser vos articles.
                        </p>
                        <p>
                            Le statut N° de tracking de votre colis est {returns.replacementTrackingNumber}
                        </p>
                    </div>
                </div>
            </div>}
        </ModalComponent>}
    </>
}

export default CustomerReturnListItem;