import * as api from "../../../../../adapters/corporate/supplier/bundle";
import React, {useEffect, useState} from "react";
import {Bundle} from "../../../../../models/bundle";
import {Link, useParams} from "react-router-dom";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import convertToDecimal from "../../../../../utils/currencyConverter";
import SupplierBundlePreview from "../preview";
import ModalComponent from "../../../../modal";
import SupplierBundleUpdate from "../update";
import {useAppSelector} from "../../../../../hooks";
import {CorporateAccount} from "../../../../../models/account";

const SupplierBundleShow = () => {

    const activeAccount = useAppSelector(state => state.auth.user?.activeAccount) as CorporateAccount
    const authOrganization = activeAccount.organization;

    const {id} = useParams()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [bundle, setBundle] = useState<Bundle>()
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    useEffect(() => {
        setLoading(true)
        api.show(Number(id))
            .then(r => setBundle(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [id])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!bundle){
        return <></>
    }

    return <div className={"container-fluid"}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row">
                <div className="col">
                    <ol className="breadcrumb mb-3 fs-xs">
                        <li className="breadcrumb-item">
                            <Link to="/corporate/supplier/catalog/bundle">Grilles tarifaire</Link>
                        </li>
                        <li className="breadcrumb-item">
                            {bundle.name}
                        </li>
                    </ol>
                </div>
                <div className="col-auto">
                    <button className="btn btn-xxs btn-light" onClick={() => setShowUpdateModal(true)}>
                        <i className={"bi bi-pencil text-info"}></i> Mettre à jour
                    </button>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Produits
                    </h6>
                </div>
                <div className="card-body">
                    <ul className={"list-group list-group-flush"}>
                        {bundle.items.map(i => <li className={"list-group-item"}>
                            <p className={'fw-semibold'}>
                                {i.product.name}
                            </p>
                            <ul className={"list-group"}>
                                {i.prices.map(p => <li className={"list-group-item"}>
                                    {convertToDecimal(p.price.amount, p.price.currency)} {p.price.recurring && <>/ {p.price.recurring.intervalCount} {p.price.recurring.interval}</>}
                                </li>)}
                            </ul>
                        </li>)}
                    </ul>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Preview
                    </h6>
                </div>
                <div className="card-body">
                    <SupplierBundlePreview organization={authOrganization} items={bundle.items.map(item => ({product: item.product, prices: item.prices.map(p => p.price)}))} />
                </div>
            </div>
        </div>
        <ModalComponent title={"Mettre à jour la grille tarifaire"} size={"modal-fullscreen"} show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
            <SupplierBundleUpdate bundle={bundle} onSuccess={updated => {
                setBundle(updated)
                setShowUpdateModal(false)
            }}/>
        </ModalComponent>
    </div>
}

export default SupplierBundleShow;