import FormError from "../../../../../utils/formError";
import Loader from "../../../../../utils/loader";
import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../adapters/corporate/supplier/returns/policy";
import ErrorCard from "../../../../error";
import {Link, useNavigate} from "react-router-dom";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";

const SupplierReturnsPolicyAdd = () => {

    const [error, setError] = useState(null)
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            name: "",
            delay: 14,
            details: "",
            default: true,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            delay: Yup.number().required(),
            details: Yup.string().required(),
            default: Yup.boolean().required(),
        }),
        onSubmit: (values, formikHelpers) => {
            api.add({...values})
                .then(r => navigate(`/corporate/supplier/returns-policy/${r.data.id}`))
                .catch(error => setError(error))
                .finally(() => formikHelpers.setSubmitting(false))
        }
    })

    return <div className={"container h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to={"/corporate/supplier/returns-policy"} >
                        Politique de retour
                    </Link>
                </li>
                <li className="breadcrumb-item">
                    Ajouter
                </li>
            </ol>
        </div>
        <form onSubmit={formik.handleSubmit}>
        {error && <ErrorCard error={error} />}
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className={'form-control'} name={"name"} id={"name"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                <label htmlFor="line1">
                    Nom *
                </label>
            </div>
            <FormError form={formik} field={"name"} />
        </div>
            <div className="col-12 mb-3">
                <div className="form-check form-switch">
                    <input name={"default"} onChange={e => formik.setFieldValue("default", e.target.checked)} className="form-check-input" type="checkbox" role="switch" id="default" />
                    <label className="form-check-label" htmlFor="default">
                        Définir comme Politique de retour par défaut
                    </label>
                </div>
                <FormError form={formik} field={"default"} />
            </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className={'form-control'} name={"delay"} id={"delay"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                <label htmlFor="line1">
                    Délai de renvoi d'un article *
                </label>
            </div>
            <FormError form={formik} field={"delay"} />
        </div>
        <div className="col-12 mb-3">
            <FormError form={formik} field={"details"} />
            <ReactQuill value={formik.values.details} onChange={value => formik.setFieldValue('details', value)} />
        </div>
        <button className="btn btn-primary w-100 mb-3">
            {formik.isSubmitting ? <Loader /> : <><i className={'bi bi-check'}></i> Enregistrer</>}
        </button>
    </form>
    </div>
}

export default SupplierReturnsPolicyAdd;