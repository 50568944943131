import React, {useState} from "react";
import Select from "../../../../../../../../utils/select";
import FormError from "../../../../../../../../utils/formError";
import * as Yup from "yup";
import {useFormik} from "formik";
import * as models from "../../../../../../../../models/affiliation";
import * as api from "../../../../../../../../adapters/corporate/supplier/affiliation/clickRule";
import ErrorCard from "../../../../../../../error";
import Loader from "../../../../../../../../utils/loader";

const SupplierAffiliationClickRuleAdd = ({affiliation, onSuccess}: {affiliation: models.Affiliation, onSuccess: (rule: models.ClickRule) => void}) => {

    const [error, setError] = useState(null)

    const formik = useFormik({
        initialValues: {
            name: "Tout les clicks",
            validation: "Automatic",
            lifetime: 30,
            lifetimeType: "Day",
            compensations: affiliation.currencies.map(c => ({
                currency_id: c.id,
                amount: 0
            }))
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            validation: Yup.string().required(),
            lifetime: Yup.number().required(),
            lifetimeType: Yup.string().required(),
            compensations: Yup.array().of(Yup.object().shape({
                currency_id: Yup.number().required(),
                amount: Yup.number().required().min(0.01)
            })).required()
        }),
        enableReinitialize: false,
        onSubmit: (values, {setSubmitting}) => {
            api.add(affiliation.id, values)
                .then(r => onSuccess(r.data))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    })

    return <form onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className="form-control" defaultValue={formik.values.name} name={'name'} id={'name'} onChange={formik.handleChange}/>
                <label htmlFor="name">
                    Nom *
                </label>
            </div>
            <FormError form={formik} field={"name"} />
        </div>
        <div className="col-12 mb-3">
            <Select
                required={true}
                label={"Validation *"}
                value={{label: "Automatique", value: 'Automatic'}}
                options={[{label: "Automatique", value: 'Automatic'}, {
                    label: "Manuelle",
                    value: 'Manual'
                }]}
                onChange={c => formik.setFieldValue("validation", c.value)}
            />
            <FormError form={formik} field={'validation'}/>
        </div>
        <div className="col-12 mb-3">
            <div className="input-group">
                <div className="form-floating">
                    <input type="number" className="form-control" defaultValue={30} name={'lifetime'}
                           id={'lifetime'} onChange={formik.handleChange}/>
                    <label htmlFor="lifetime">
                        Durée de vie du cookie *
                    </label>
                </div>
                <div className={"w-25"}>
                    <Select label={"Type de durée *"}
                            required={true}
                            value={{label: "Jours", value: "day"}}
                            options={[
                                {label: "Minutes", value: "minute"},
                                {label: "Heures", value: "hour"},
                                {label: "Jours", value: "day"},
                                {label: "Mois", value: "month"},
                                {label: "Année", value: "year"}
                            ]}
                            onChange={c => formik.setFieldValue('lifetimeType', c.value)}/>
                </div>
            </div>
            <FormError form={formik} field={'lifetime'}/>
            <FormError form={formik} field={'lifetimeType'}/>
        </div>
        <div className="col-12 mb-3">
            <h6>
                Cout par click
            </h6>
            <ul className="list-unstyled">
                {affiliation.currencies.map((c, index) => <li className={'mb-3'}>
                    <div className="input-group">
                                        <span className="input-group-text">
                                            {c.name} {c.symbol}
                                        </span>
                        <input type="number" step={0.01} className="form-control" name={`compensations[${index}].amount`} onChange={formik.handleChange}/>
                    </div>
                </li>)}
            </ul>
        </div>
        {error && <ErrorCard error={error} />}
        <button className={'btn btn-primary w-100'}>
            {formik.isSubmitting ? <Loader /> : <><i className={'bi bi-plus-circle'}></i> Ajouter</>}
        </button>
    </form>
}

export default SupplierAffiliationClickRuleAdd;