import {Subscription} from "../../../../../../models/subscription";
import React from "react";
import {Link} from "react-router-dom";
import {getLogoUrl} from "../../../../../../utils/image";

const SupplierSubscriptionShowInformation = ({subscription}: {subscription: Subscription}) => {


    return <div className="card border border-1">
        <div className="card-header">
            <h6>
                Détails de l'abonnement
            </h6>
        </div>
        <div className="card-body">
            <div className="row">
                <div className="col-md-6 mb-3">
                    <table className={"table table-borderless"}>
                        <tbody>
                        <tr>
                            <th>Dates</th>
                            <td>{new Date(subscription.startDate).toLocaleDateString()} <i className={"bi bi-arrow-left-right"}></i> {subscription.endDate ? new Date(subscription.endDate).toLocaleDateString() : <i className={'bi bi-infinity'}></i>}</td>
                        </tr>
                        <tr>
                            <th>Prochaine facture</th>
                            <td>{new Date(subscription.startDate).toLocaleDateString()}</td>
                        </tr>
                        <tr>
                            <th>Période actuelle</th>
                            <td>{new Date(subscription.startDate).toLocaleDateString()}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-md-6 mb-3">
                    <table className={"table table-borderless"}>
                        <tbody>
                        <tr>
                            <th>Client</th>
                            <td>
                                <Link to={`/corporate/supplier/customer/${subscription.account.id}`}>
                                    {subscription.account.user.email}
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <th>Apporteur</th>
                            <td>
                                <Link to={`/corporate/organization/${subscription.partnership.provider.id}`}>
                                    <span className="avatar avatar-xs me-2">
                                        <img src={getLogoUrl(subscription.partnership.provider)} alt={''} className="avatar-img rounded-circle" />
                                    </span>
                                    <span>
                                        {subscription.partnership.provider.name}
                                    </span>
                                </Link>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
}

export default SupplierSubscriptionShowInformation;