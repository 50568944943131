import * as models from "../../../../models/quote";
import React, {useState} from "react";
import QuoteAddressShippingSelector from "./selector";

const QuoteAddressShipping = ({quote, setQuote}: {quote: models.Quote, setQuote: React.Dispatch<any>}) => {

    return <div className={"col-12 mb-3"}>
        <div className="row align-items-center">
            <div className="col">
                <p className={'mb-0 fw-semibold'}>
                    Addresse de livraison
                </p>
            </div>
        </div>
        <QuoteAddressShippingSelector quote={quote} setQuote={setQuote}/>
    </div>
}

export default QuoteAddressShipping;