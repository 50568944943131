import * as models from "../../../../../../../../../models/subscription";
import React, {useEffect, useState} from "react";
import * as api from "../../../../../../../../../adapters/corporate/supplier/subscription/occurrence/item";
import SearchInput from "../../../../../../../../../utils/searchInput";
import Loader from "../../../../../../../../../utils/loader";
import ErrorCard from "../../../../../../../../error";
import SupplierSubscriptionOccurrenceShowItemListItem from "./item";

const SupplierSubscriptionOccurrenceShowItemList = ({subscription, occurrence}: {subscription: models.Subscription, occurrence: models.SubscriptionOccurrence}) => {

    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string}>({offset: 0, query: ''})
    const [payload, setPayload] = useState<{count: number, rows: models.SubscriptionOccurrenceItems}>({count: 0, rows: []})
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        setError(null)
        api.list(subscription.id, occurrence.id, fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [subscription.id, fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={'col-12 mb-3'}>
        <div className="row align-items-center">
            <div className="col">
                <SearchInput placeholder={"Rechercher un article..."} onChange={handleQueryChange} size={"sm"} />
            </div>
        </div>
        {loading && <div className={"col-12 mb-3 text-center"}><Loader size={"sm"} /></div>}
        {error && <ErrorCard error={error} />}
        <table className={'table'}>
            <thead>
            <tr>
                <th>Produit</th>
                <th>Quantité</th>
                <th>Tarif</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
                {payload.rows.map(r => <SupplierSubscriptionOccurrenceShowItemListItem subscription={subscription} item={r} key={r.id} />)}
            </tbody>
        </table>
    </div>
}

export default SupplierSubscriptionOccurrenceShowItemList;