import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import * as api from "../../../../adapters/customer/order"
import * as invoiceApi from "../../../../adapters/customer/invoice"
import Loader from "../../../../utils/loader";
import * as models from "../../../../models/order";
import {Invoice} from "../../../../models/invoice";
import FileSaver from "file-saver";
import OrderStatusPlaceholder from "../../../common/order/status/placeholder";
import convertToDecimal from "../../../../utils/currencyConverter";
import CustomerOrderItemList from "../item/list";
import CustomerParcelList from "../parcel/list";

const CustomerOrderShow = () => {

    const [loading, setLoading] = useState(true);
    const [order, setSubOrder] = useState<models.Order>();
    const {id} = useParams();

    const [downloading, setDownloading] = useState<Invoice>();
    function downloadInvoice(invoice: Invoice){
        setDownloading(invoice)
        invoiceApi.download(invoice.id)
            .then(r => {
                let newBlob = new Blob([r.data], { type: `application/pdf` })
                FileSaver.saveAs(newBlob, `INV-${invoice.id}`)
            }).then(() => setDownloading(undefined))
    }

    useEffect(() => {
        setLoading(true)
        api.show(Number(id))
            .then(r => setSubOrder(r.data))
            .then(() => setLoading(false))
    }, [id])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (!order){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <div className="display-5">
                    <i className={'bi bi-search'}></i> Commande introuvable
                </div>
            </div>
        </div>
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/customer">Mon compte</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to="/customer/order">Mes commandes</Link>
                </li>
                <li className="breadcrumb-item">
                    {order.number}
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3">
            <h6>
                Détails de la commande
            </h6>
            <div className="row">
                <div className="col-auto">
                    Commandé le: {new Date(order.orderedAt).toLocaleDateString()}
                </div>
                <div className="col-auto">
                    N° de commande: {order.number}
                </div>
                <div className="col-auto">
                    <OrderStatusPlaceholder status={order.status} />
                </div>
                {order.invoice && <div className="col text-end">
                    <button className="btn btn-xxs text-decoration-underline" onClick={() => downloadInvoice(order.invoice)}>
                        {downloading ? <Loader size={"sm"} /> : <><i className={'bi bi-download me-1'}></i> Télécharger la facture</>}
                    </button>
                </div>}
                <div className="col-12">
                    <p className={"form-text"}>
                        {new Date(order.returnsLimitAt).getTime() < new Date().getTime() ? <>Fenetre de retour fermé {new Date(order.returnsLimitAt).toLocaleDateString()}</> : <>Retour possible jusqu'au {new Date(order.returnsLimitAt).toLocaleDateString()}</>}
                    </p>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-body">
                    <div className="row row-cols-1 row-cols-md-3">
                        <div className="col">
                            <p className="fw-semibold">
                                Adresse de livraison
                            </p>
                            <table className={'table table-borderless'}>
                                <tbody>
                                    {order.shipping ? <>
                                        <tr>{order.shipping.line1}</tr>
                                        <tr>{order.shipping.city}, {order.shipping.postalCode}</tr>
                                        <tr>{order.shipping.country.name}</tr>
                                    </> : <tr>
                                        <td>
                                            <span className={"text-muted"}>Non renseigné</span>
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                        <div className="col">
                            <p className="fw-semibold">
                                Mode de paiement
                            </p>
                            <p>
                                {order.checkout.payment.paymentMethod.brand}, **** **** **** {order.checkout.payment.paymentMethod.last4}
                            </p>
                        </div>
                        <div className="col">
                            <p className="fw-semibold">
                                Récapitulatif de la commande
                            </p>
                            <table className={"table table-borderless"}>
                                <tr>
                                    <th>
                                        Articles
                                    </th>
                                    <td>
                                        {convertToDecimal(order.priceData.cart.amount_including_tax, order.checkout.currency)}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Livraison
                                    </th>
                                    <td>
                                        {convertToDecimal(order.priceData.shipping.amount_including_tax, order.checkout.currency)}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Total
                                    </th>
                                    <td>
                                        {convertToDecimal(order.priceData.amount_including_tax, order.checkout.currency)}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Articles
                    </h6>
                </div>
                <div className="card-body">
                    <CustomerOrderItemList order={order} />
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Colis
                    </h6>
                </div>
                <div className="card-body">
                    <CustomerParcelList order={order} />
                </div>
            </div>
        </div>
    </div>
}

export default CustomerOrderShow;