import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import FormError from "../../../../../utils/formError";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../adapters/corporate/common/onboarding";
import React from "react";
import authSlice from "../../../../../reducers/auth";
import Loader from "../../../../../utils/loader";
import {getCountryCodes, getCountryCodesChoice} from "../../../../../utils/tel";
import Select from "../../../../../utils/select";
import {CorporateAccount} from "../../../../../models/account";

const SettingAccount = () => {

    const user = useAppSelector(state => state.auth.user)!
    const account = useAppSelector(state => state.auth.user!.activeAccount) as CorporateAccount;
    const dispatch = useAppDispatch();

    const formik = useFormik({
        initialValues: {
            firstname: account.firstname,
            lastname: account.lastname,
        },
        validationSchema: Yup.object().shape({
            firstname: Yup.string().required(),
            lastname: Yup.string().required(),
        }),
        onSubmit: (values, formikHelpers) => {
            api.account(values)
                .then(r => dispatch(authSlice.actions.editAccount(r.data)))
                .then(() => formikHelpers.setSubmitting(false))
        }
    })

    return <>
        <form onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className="form-control" defaultValue={account.firstname} id={"firstname"} name={"firstname"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                    <label htmlFor="firstname">
                        Prénom
                    </label>
                </div>
                <FormError form={formik} field={"firstname"} />
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className="form-control" defaultValue={account.lastname} id={"lastname"} name={"lastname"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                    <label htmlFor="lastname">
                        Nom
                    </label>
                </div>
                <FormError form={formik} field={"lastname"} />
            </div>
            <button className="btn btn-dark w-100 mb-3">
                {formik.isSubmitting ? <Loader /> : <><i className={'bi bi-check'}></i> Valider </>}
            </button>
        </form>
    </>

}

export default SettingAccount;