import * as models from "../../../models/checkout";
import React, {useState} from "react";
import * as api from "../../../adapters/checkout";
import ErrorCard from "../../error";
import {Organization} from "../../../models/organization";
import {ReturnPolicy} from "../../../models/returns";
import {Term} from "../../../models/term";
import ModalComponent from "../../modal";

const CheckoutTermsOfService = ({checkout, setCheckout}: {checkout: models.CheckoutResponse, setCheckout: React.Dispatch<any>}) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentTerms, setCurrentTerms] = useState<{organization: Organization, returnsPolicy: ReturnPolicy, term: Term}>()
    const [showModal, setShowModal] = useState(false)
    const [tab, setTab] = useState<"term"|"returnsPolicy"|"contact">("term");

    function handleChange(checked: boolean){
        setLoading(true)
        api.termsOfServiceAcceptance(checkout.uuid, {termsOfServiceAcceptance: checked})
            .then(r => setCheckout(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }

    return <>
        <div className="form-check">
                <input disabled={loading} className="form-check-input" type="checkbox" onChange={e => handleChange(e.target.checked)} id="termsOfServiceAcceptance" />
                <label className="form-check-label" htmlFor="termsOfServiceAcceptance">
                    J'accepte les conditions générales de vente de {checkout.termsOfService.map(t => <span onMouseDown={(e) => {
                        e.preventDefault();
                        setCurrentTerms(t)
                }} onClick={(e) => {
                        e.preventDefault();
                    setShowModal(true)
                }} className={'text-link text-decoration-underline cursor-pointer'}>{t.organization.name}</span>)}
                </label>
        </div>
        {error && <ErrorCard error={error} />}
        <ModalComponent title={currentTerms?.organization.name || ""} size={'modal-lg'} show={showModal} onHide={() => setShowModal(false)}>
            {currentTerms ? <>
                <div className="mb-2 mb-lg-0 border-bottom-lg">
                    <nav className="nav nav-tabs nav-overflow fs-xs border-bottom border-bottom-lg-0">
                        <button onClick={() => setTab('term')} className={"nav-link text-uppercase" + (tab === 'term' ? " active " : "")}>
                            Condition général de vente
                        </button>
                        <button onClick={() => setTab('returnsPolicy')} className={"nav-link text-uppercase" + (tab === "returnsPolicy" ? " active " : "")}>
                            Politique de retour
                        </button>
                        <button onClick={() => setTab('contact')} className={"nav-link text-uppercase" + (tab === "contact" ? " active " : "")}>
                            Contact
                        </button>
                    </nav>
                </div>
                {tab === 'term' && <div dangerouslySetInnerHTML={{__html: currentTerms.term?.details}} />}
                {tab === 'returnsPolicy' && <div dangerouslySetInnerHTML={{__html: currentTerms.returnsPolicy?.details}} />}
                {tab === 'contact' && <div className={"col-12"}>
                    <table className="table table-borderless">
                        <tbody>
                            <tr>
                                <th>
                                    Email
                                </th>
                                <td>
                                    {currentTerms.organization.information.email}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Tel
                                </th>
                                <td>
                                    {currentTerms.organization.information.countryCode} {currentTerms.organization.information.tel}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>}
            </> : <>
                <p className={"text-center text-muted"}>
                    Aucun résultat
                </p>
            </>}
        </ModalComponent>
    </>
}

export default CheckoutTermsOfService;