import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import FormError from "../../../../../utils/formError";
import {Organization} from "../../../../../models/organization";
import * as api from "../../../../../adapters/corporate/common/partnership/request";
import {PartnershipRequest} from "../../../../../models/partnership";
import {Modal} from "bootstrap";

const PartnershipRequestAdd:React.FC<{organization: Organization, handleSuccess: (p: PartnershipRequest) => void;}> = ({organization, handleSuccess}) => {

    const formik = useFormik({
        initialValues: {
            message: "",
        },
        validationSchema: Yup.object().shape({
            message: Yup.string().required(),
        }),
        onSubmit: (values) => {
            api.create(organization.id, values)
                .then(resp => handleSuccess(resp.data))
                .then(() => {
                    let el = document.getElementById('partnershipRequestAddModal')
                    if (el){
                        let modal = new Modal(el);
                        modal.toggle();
                    }
                })
        }
    })

    return  <form onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <textarea style={{minHeight: 200}} className={'form-control'} name={"message"} id={"message"} onBlur={formik.handleBlur} onChange={formik.handleChange}></textarea>
                <label htmlFor="message">
                    Écrivez un message à {organization.name}
                </label>
            </div>
            <FormError form={formik} field={"network"} />
        </div>
        <button type="submit" className="btn btn-primary w-100">
            <i className={'bi bi-send'}></i> Envoyer
        </button>
    </form>
}

export default PartnershipRequestAdd;