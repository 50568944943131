import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Affiliation} from "../../../../../models/affiliation";
import * as api from "../../../../../adapters/corporate/provider/affiliation";
import Loader from "../../../../../utils/loader";
import ProviderAffiliationShowInformation from "./information";
import ErrorCard from "../../../../error";
import ProviderAffiliationTracking from "./tracking";
import ProviderAffiliationRules from "./rules";
import ProviderAffiliationSupportList from "./support/list";
import ProviderAffiliationShowStatistics from "./statistics";
import {useAppSelector} from "../../../../../hooks";
import {CorporateAccount} from "../../../../../models/account";

const ProviderAffiliationShow = ({tab}: {tab?: "support"|"statistics"|"tracking"|'rules'}) => {

    const activeAccount = useAppSelector(state => state.auth.user?.activeAccount) as CorporateAccount
    const authOrganization = activeAccount.organization;

    const params = useParams();
    const [affiliation, setAffiliation] = useState<Affiliation>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        api.show(Number(params.id))
            .then(r => setAffiliation(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [params.id])

    const [copied, setCopied] = useState(false)
    let timer: NodeJS.Timeout;
    function copyLink(){
        if (affiliation){
            clearTimeout(timer);
            setCopied(true)
            navigator.clipboard.writeText(`${affiliation.url}?aid=${authOrganization.uuid}`);
            timer = setTimeout(() => setCopied(false), 1500);
        }
    }

    if (loading){
        return <div className={'container-fluid py-10 text-center'}>
            <Loader />
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!affiliation){
        return <></>
    }

    return <div className={'container-fluid'}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/corporate/provider/affiliation">Affiliation</Link>
                </li>
                <li className="breadcrumb-item">
                    {affiliation.name}
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Mon lien d'affilié
                    </h6>
                </div>
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            {`${affiliation.url}?aid=${authOrganization.uuid}`}
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-xxs btn-primary" onClick={() => copyLink()}>
                                {copied ? <><i className={'bi bi-check'}></i> Lien copié</> : <><i className={'bi bi-link'}></i> Copier le lien</>}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row justify-content-center">
            <div className="col">
                <div className="mb-2 mb-lg-0 border-bottom-lg">
                    <nav className="nav nav-tabs nav-overflow fs-xs border-bottom border-bottom-lg-0">
                        <Link to={`/corporate/provider/affiliation/${affiliation.id}`} className={"nav-link text-uppercase" + (!tab ? " active " : "")}>
                            Informations
                        </Link>
                        <Link to={`/corporate/provider/affiliation/${affiliation.id}/rules`} className={"nav-link text-uppercase" + (tab === "rules" ? " active " : "")}>
                            Règles
                        </Link>
                        <Link to={`/corporate/provider/affiliation/${affiliation.id}/support`} className={"nav-link text-uppercase" + (tab === "support" ? " active " : "")}>
                            Support
                        </Link>
                        <Link to={`/corporate/provider/affiliation/${affiliation.id}/tracking`} className={"nav-link text-uppercase" + (tab === "tracking" ? " active " : "")}>
                            Tracking
                        </Link>
                        <Link to={`/corporate/provider/affiliation/${affiliation.id}/statistics`} className={"nav-link text-uppercase" + (tab === "statistics" ? " active " : "")}>
                            Statistiques
                        </Link>
                    </nav>
                </div>
            </div>
        </div>
        {!tab && <ProviderAffiliationShowInformation affiliation={affiliation} />}
        {tab === "tracking" && <ProviderAffiliationTracking affiliation={affiliation} />}
        {tab === "rules" && <ProviderAffiliationRules affiliation={affiliation} />}
        {tab === "support" && <ProviderAffiliationSupportList affiliation={affiliation} />}
        {tab === "statistics" && <ProviderAffiliationShowStatistics affiliation={affiliation} />}
    </div>
}

export default ProviderAffiliationShow;