import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import QuoteNavHeader from "../nav/header";
import {Quote} from "../../../models/quote";
import {AxiosError} from "axios";
import * as api from "../../../adapters/quote";
import Loader from "../../../utils/loader";
import ErrorCard from "../../error";
import QuoteShowRows from "../rows";

const QuoteConfirm = () => {

    const {uuid} = useParams()
    const [loading, setLoading] = useState(true)
    const [quote, setQuote] = useState<Quote>()
    const [error, setError] = useState<AxiosError>()

    useEffect(() => {
        setLoading(true)
        api.show(uuid)
            .then(r => setQuote(r.data))
            .catch((error: AxiosError) => setError(error))
            .finally(() => setLoading(false))
    }, [uuid])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!quote){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <h6>
                    Devis introuvable
                </h6>
            </div>
        </div>
    }


    return <div className={"container-fluid h-100 d-flex flex-column px-0"}>
        <QuoteNavHeader quote={quote} />
        <div className="container mx-auto">
            <div className={"col-12 "}>
                <div className="col-12 mb-3">
                    <h6>
                        Confirmation de votre devis
                    </h6>
                    <p>
                        Merci de votre confiance !
                    </p>
                    <p>
                        Le prestataire a été informé de votre décision et prendra contact avec vous dans les meilleurs délais.
                    </p>
                </div>
                <div className="col-12 mb-3">
                    <QuoteShowRows quote={quote} />
                </div>
                <div className="col-12 mb-3">
                    <Link to={`/customer/quote`} className={"btn btn-light w-100"}>
                        Suivre et gérer mes devis <i className={'bi bi-arrow-right'}></i>
                    </Link>
                </div>
            </div>
        </div>
    </div>
}

export default QuoteConfirm;