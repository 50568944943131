import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import OffcanvasComponent from "../../../offcanvas";
import ChatList from "../chat/list";
import { useLocation } from 'react-router-dom';
import {socket} from "../../../../socket";
import * as chatApi from "../../../../adapters/corporate/common/chat";
import * as requestApi from "../../../../adapters/corporate/common/partnership/request";
import ErrorCard from "../../../error";
import CommonNavAccount from "./account";
import {PartnershipRequest, PartnershipRequests} from "../../../../models/partnership";
import {Payload} from "../../../../models/global";

const CommonNav = () => {

    const location = useLocation();
    const [unreadMessages, setUnreadMessages] = useState<number[]>([])
    const [pendingPartnershipRequest, setPendingPartnershipRequest] = useState<Payload<PartnershipRequests>>({count: 0, rows: []})
    const [error, setError] = useState<any>(null)
    const [showChatOffcanvas, setShowChatOffcanvas] = useState(false)

    useEffect(() => {
       setShowChatOffcanvas(false)
    }, [location]);

    useEffect(() => {
        fetchUnreadMessages()
            .then(data => setUnreadMessages(data))
            .catch(e => console.log(e))

        fetchPendingPartnershipRequest()
            .then(data => setPendingPartnershipRequest(data))
            .catch(e => console.log(e))
    }, [])

    async function fetchUnreadMessages(){
        try {
            const {data} = await chatApi.unreads();
            return data;
        }catch (error) {
            setError(error)
            return error;
        }
    }

    async function fetchPendingPartnershipRequest(){
        try {
            const {data} = await requestApi.list();
            return data;
        }catch (error) {
            setError(error)
            return error;
        }
    }

    useEffect(() => {
        socket.connect();
        socket.on(`message.received`, handleMessageReceived)
        socket.on(`message.reads`, handleMessageReads)

        socket.on(`request.received`, handlePartnershipRequestReceived)
        socket.on(`request.treated`, handlePartnershipRequestTreated)

        return () => {
            socket.disconnect()
            socket.off(`message.received`, handleMessageReceived)
            socket.off(`message.reads`, handleMessageReads)
        }
    }, [])

    function handleMessageReceived(messageId: number){
        setUnreadMessages(prev => [...prev, messageId])
    }

    function handleMessageReads(messageIds: number[]){
        console.log("messageIds", messageIds)
        setUnreadMessages(prev => [...prev.filter(oldMessage => !messageIds.includes(oldMessage))])
    }

    function handlePartnershipRequestReceived(request: PartnershipRequest){
        setPendingPartnershipRequest(prev => ({count: prev.count + 1, rows: [request, ...prev.rows]}))
    }

    function handlePartnershipRequestTreated(request: PartnershipRequest){
        setPendingPartnershipRequest(prev => ({count: prev.count - 1, rows: [...prev.rows.filter(p => p.id !== request.id)]}))
    }

    return <><nav className="navbar bg-body-tertiary py-1 flex-grow-0">
        <div className="container mx-auto">
            <Link className="navbar-brand" to={"/"}>
                <img src={process.env.PUBLIC_URL + '/img/logo.png'} className={'logo'} alt=""/>
            </Link>
            <div className="row align-items-center ms-auto">
                <div className="col-auto">
                    <Link to={'/corporate/sponsorship'} className="btn rounded btn-xxs bg-body-secondary">
                        <i className={'bi bi-star'}></i> <span className={'d-none d-md-inline'}>Parrainage</span>
                    </Link>
                </div>
                <div className="col-auto">
                    <CommonNavAccount />
                </div>
                {error && <div className="col-auto">
                    <ErrorCard error={error}/>
                </div>}
                <div className="col-auto">
                    <button data-cart-items={unreadMessages.length > 0 ? unreadMessages.length : null} onClick={() => setShowChatOffcanvas(true)} className="btn btn-xxs rounded bg-body-secondary">
                        <i className={'bi bi-chat'}></i> <span className={'d-none d-md-inline'}>Messagerie</span>
                    </button>
                </div>
                <div className="col-auto">
                    <Link to={'/corporate/partnership/request'} data-cart-items={pendingPartnershipRequest.count > 0 ? pendingPartnershipRequest.count : null} className="btn btn-xxs rounded bg-body-secondary">
                        <i className={'bi bi-send'}></i> <span className={'d-none d-md-inline'}>Invitations</span>
                    </Link>
                </div>
                {/*<div className="col-auto">*/}
                {/*    <Link to={'/corporate/notification'} data-cart-item={35}  className="btn btn-xxs btn-circle bg-body-secondary">*/}
                {/*        <i className={'bi bi-bell'}></i>*/}
                {/*    </Link>*/}
                {/*</div>*/}
                <div className="col-auto">
                    <button className="btn btn-xxs btn-circle bg-body-secondary ms-auto d-block d-md-none" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseNav" aria-expanded="false" aria-controls="collapseNav">
                        <i className={"bi bi-list"}></i>
                    </button>
                </div>
            </div>
        </div>
    </nav>
        {showChatOffcanvas && <OffcanvasComponent
            title={"Discussions"}
            show={showChatOffcanvas}
            onHide={() => setShowChatOffcanvas(false)}
            footerContent={<Link to={'/corporate/chat'} className={"btn btn-primary w-100"}>Tout voir dans la messagerie<i className={"bi bi-arrow-right"}></i></Link>}
        >
            <ChatList />
        </OffcanvasComponent>}
    </>
}

export default CommonNav;