import {Product} from "../../../../../models/product";
import {useState} from "react";
import ShopProductShowQuantityInput from "./quantityInput";
import {Country} from "../../../../../models/country";
import * as api from "../../../../../adapters/cart"
import * as inventoryApi from "../../../../../adapters/inventory"
import convertToDecimal from "../../../../../utils/currencyConverter";
import {Popover} from "bootstrap";
import cartSlice from "../../../../../reducers/cart";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {Currency} from "../../../../../models/currency";
import {useNavigate} from "react-router-dom";
import * as models from "../../../../../models/product";


const ShopProductShowAddToCart = ({product, country, currency, uuid}: {product: models.Product & models.SellableProduct, country: Country, currency: Currency, uuid: string}) => {

    const cart = useAppSelector(state => state.cart);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [choices, setChoices] = useState<{[key: number]: number}>({});
    const [quantity, setQuantity] = useState(1);
    const [showError, setShowError] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [addedToCart, setAddedToCart] = useState(false)

    async function validate(){
        setShowError(false)

        for (const declination of product.declinations) {
            if (!choices[declination.id]){
                setShowError(true)
                return false;
            }
        }

        const {data} = await inventoryApi.availableQuantity({
            country_id: country.id,
            product_id: product.id,
            choices: Object.values(choices)
        })

        const available_quantity = data.available_quantity;

        if (available_quantity !== -1 && available_quantity < quantity){

            const el = document.getElementById(`container_quantity_${product.id}`);

            if (el) {
                const popover = new Popover(el, {
                    trigger: "focus",
                    placement: "top",
                    content: `Le nombre d'article disponible est limité à ${available_quantity}`,
                });
                popover.show();
            }

            setQuantity(available_quantity)
            return false;
        }

        return true;
    }

    async function addToCart(){
        setSubmitting(true)
        const validated = await validate();

        if (validated){
            await api.addToCart(uuid, {
                local_cart_id: cart.id,
                source: "shop",
                source_uuid: uuid,
                product_id: product!.id,
                quantity: quantity,
                declinations: Object.values(choices),
                country_id: country.id,
                currency_id: currency.id
            }).then(r => dispatch(cartSlice.actions.setCart({
                cart: r.data,
                storageKey: `shop-${uuid}`
            })))
                .then(() => setAddedToCart(true))
                .finally(() => setSubmitting(false))
        }else{
            setSubmitting(false)
        }
    }

    return <div className={"d-flex flex-column h-100"}>
        <div className="col-12 flex-grow-0 mb-3">
            <p className={'mb-1 text-muted'}>
                {product.category ? product.category.name : "Non catégorisé"}
            </p>
            <h3>
                {product.name}
            </h3>
            <p className={'text-primary fw-bold'}>
                {convertToDecimal(product.priceData.total_amount_including_tax, product.priceData.currency)}
            </p>
        </div>
        <div className="col-12 mb-3 flex-grow-1">
            <div className="row">
                {product.declinations.map(declination => <div className={"col-12 mb-3"}>
                    <p className={"fw-semibold mb-2"}>
                        {declination.name}
                    </p>
                    {showError && !choices[declination.id] && <p className={'mb-2 text-danger'}>
                        <i className={"bi bi-exclamation-circle"}></i> Veuillez séléctionner une option.
                    </p>}
                    <div className="row gx-1">
                        {declination.choices.map(choice => <div className={"col-auto"}>
                            <button onClick={() => setChoices(prev => ({...prev, [declination.id]: choice.id}))} className={`btn btn-xs ${choices[declination.id] === choice.id ? 'btn-primary' : 'btn-light'}`}>
                                {choice.value}
                            </button>
                        </div>)}
                    </div>
                </div>)}
            </div>
        </div>
        <div className="col-12 mb-3">
            <ShopProductShowQuantityInput product={product} quantity={quantity} setQuantity={setQuantity} country={country} choices={choices} />
        </div>
        <div className="col-12 mb-3">
            <button disabled={submitting} className={`btn ${addedToCart ? "btn-light" : "btn-primary"} w-100`} onClick={() => addedToCart ? navigate(`/shop/${uuid}/cart`) : addToCart()}>
                {addedToCart ? `Voir mon panier (${cart.items.length})` : "Ajouter au panier"}
            </button>
        </div>
    </div>
}

export default ShopProductShowAddToCart;