import * as models from "../../../../models/product";
import {getCoverUrl} from "../../../../utils/image";
import {useNavigate} from "react-router-dom";
import convertToDecimal from "../../../../utils/currencyConverter";
import {Currency} from "../../../../models/currency";

const ShopProductCard = ({product, uuid, currency}: {product: models.Product & models.SellableProduct, uuid: string, currency: Currency}) => {

    const navigate = useNavigate();

    return <div className="card border border-1 shadow-hover cursor-pointer h-100" onClick={() => navigate(`/shop/${uuid}/product/${product.id}`)}>
        <img src={`${getCoverUrl(product)}`} alt="" className="card-img-top"/>
        <div className="card-body bg-white">
            <div className="row">
                <div className="col">
                    <p className="d-block fw-bold text-body mb-1">
                        {product.name}
                    </p>
                    {product.category && <p className="fs-xs text-muted">
                        {product.category.name}
                    </p>}
                </div>
                <div className="col-auto">
                    <div className="fs-sm fw-bold text-primary">
                        {product.priceData ? <>{convertToDecimal(product.priceData.unit_amount_including_tax, product.priceData.currency)}</> : <span className={"text-danger"}>Indisponible en {currency.name}</span>}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ShopProductCard;