import React, {useEffect, useRef, useState} from "react";
import {Projects} from "../../../../../models/project";
import * as api from "../../../../../adapters/corporate/supplier/project"
import SearchInput from "../../../../../utils/searchInput";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";
import SupplierProjectListItem from "./item";

const SupplierProjectList = () => {

    const [loading, setLoading] = useState(true)
    const [payload, setPayload] = useState<{count: number, rows: Projects}>({count: 0, rows: []})
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string}>({offset: 0, query: ""})
    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => console.log(e))
            .finally(() => setLoading(false))
    }, [fetchParams])


    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams(p => ({...p, query: e.target.value})), 1000)
    }



    let scrollTimer: NodeJS.Timer;
    function handleScroll() {
        if (container.current) {
            const scrollTop = container.current.scrollTop;
            const scrollHeight = container.current.scrollHeight;
            const innerHeight = container.current.clientHeight;

            const scrolledBottom = scrollTop + innerHeight + 10 >= scrollHeight;

            if (scrolledBottom && payload.rows.length < payload.count) {
                clearTimeout(scrollTimer);
                scrollTimer = setTimeout(() => setFetchParams(prev => ({offset: prev.offset + 1, query: prev.query})), 500);
            }
        }
    }

    function checkAndFetchMoreData() {
        if (container.current) {
            const scrollHeight = container.current.scrollHeight;
            const innerHeight = container.current.clientHeight;

            if (scrollHeight <= innerHeight && payload.rows.length < payload.count) {
                setFetchParams(prev => ({offset: prev.offset + 1, query: prev.query}));
            }
        }
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col-12 mb-3">
                    <div className="row">
                        <div className="col">
                            <SearchInput placeholder={"Rechercher un appel d'offre..."} onChange={handleQueryChange} size={"default"} />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <span className="text-muted">
                        {loading ? <Loader size={"sm"} /> : <>{payload.count} résultats</>}
                    </span>
                </div>
            </div>
        </div>
        <div className="col-md-12 flex-grow-1 overflow-auto" onScroll={handleScroll}>
            {payload.rows.length > 0 ? payload.rows.map(r => <SupplierProjectListItem project={r} />) : <span className={'text-muted'}><i className={'bi bi-search'}></i> Aucun résultat</span>}
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        </div>
    </div>
}

export default SupplierProjectList;