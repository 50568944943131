
const ListEmpty = ({message}: {message?: string}) => {

    return <ul className={'list-group'}>
        <li className={'list-group-item'}>
            <i className={'bi bi-search'}></i> {message || "Aucun résultat"}
        </li>
    </ul>
}

export default ListEmpty;