import React from "react";
import * as models from "../../../../../../../models/chat";

const ChatShowGroup = ({chat}: {chat: models.Chat & models.GroupChat}) => {

    return <div className="row align-items-center">
        <div className="col-12">
            <p className="mb-1 fw-semibold">
                {chat.name}
            </p>
            <div className="row">
                <div className="col-auto">
                    <i className={'bi bi-person'}></i> {chat.accounts.length}
                </div>
                <div className="col">
                    <div className="text-nowrap form-text" style={{textOverflow: "ellipsis"}}>
                        {chat.accounts.map(p => `${p.firstname} ${p.lastname}`).join(", ")}
                    </div>
                </div>
            </div>

        </div>
    </div>
}

export default ChatShowGroup;