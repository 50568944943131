import encodeQueryData from "../../../../utils/encodeQueryData";
import {post, get} from "../../../xhr";

export function list(requestData: { query: string, offset: number}){
    return get(`corporate/provider/contact${encodeQueryData(requestData)}`)
}

export function show(contact: number){
    return get(`corporate/provider/contact/${contact}`)
}

export function add(requestData: any){
    return post(`corporate/provider/contact`, requestData)
}

export function update(contact: number, requestData: any){
    return post(`corporate/provider/contact/${contact}`, requestData);
}

