import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import FormError from "../../../../../utils/formError";
import PartnershipSelect from "../../../../../utils/partnershipSelect";
import CustomerSelect from "../../../common/customer/select";
import Select from "../../../../../utils/select";
import {getCurrencies} from "../../../../../utils/currencies";
import * as Yup from "yup";
import {format} from "date-fns";
import Loader from "../../../../../utils/loader";
import SupplierProductPriceSelect from "../../product/price/select";
import {Product, SellableProduct} from "../../../../../models/product";
import {Price} from "../../../../../models/price";
import convertToDecimal from "../../../../../utils/currencyConverter";
import {Subscription} from "../../../../../models/subscription";
import * as api from "../../../../../adapters/corporate/supplier/subscription"
import ErrorCard from "../../../../error";
import {CustomerAccount} from "../../../../../models/account";

const SupplierSubscriptionAdd = ({onSuccess, defaultValues}: {onSuccess: (subscription: Subscription) => void, defaultValues?: {customer?: CustomerAccount}}) => {

    const [error, setError] = useState(null)
    const [items, setItems] = useState<{product: Product & SellableProduct, price: Price, quantity: number}[]>([]);

    const formik = useFormik({
        initialValues: {
            startDate: new Date(),
            endDate: null,
            partnership_id: null,
            currency_id: null,
            account_id: defaultValues?.customer ? defaultValues.customer.id : null,
            items: []
        },
        validationSchema: Yup.object().shape({
            startDate: Yup.date().required(),
            endDate: Yup.date().nullable().min(Yup.ref('startDate'), 'End date must be greater than start date'),
            partnership_id: Yup.number().required(),
            currency_id: Yup.number().required(),
            account_id: Yup.number().required(),
            items: Yup.array().of(Yup.object().shape({
                quantity: Yup.number().required(),
                price_id: Yup.number().required()
            })).min(1).required()
        }),
        onSubmit:(values, {setSubmitting}) => {
            api.add(values)
                .then(r => onSuccess(r.data))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    });

    useEffect(() => {
        formik.setFieldValue('items', items.map(item => ({quantity: item.quantity, price_id: item.price.id})))
    }, [items])

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <div className="form-floating">
                        <input
                            type="date"
                            className="form-control"
                            name={"startDate"}
                            id={"startDate"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={format(formik.values.startDate, "uuuu-MM-dd")}
                        />
                        <label htmlFor="startDate">Date de début *</label>
                    </div>
                    <FormError form={formik} field={'startDate'} />
                </div>
                <div className="col-md-6 mb-3">
                    <div className="form-floating">
                        <input
                            type="date"
                            className="form-control"
                            name={"endDate"}
                            id={"endDate"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.endDate ? format(formik.values.endDate, "uuuu-MM-dd") : undefined}
                        />
                        <label htmlFor="endDate">Date de fin</label>
                    </div>
                    <FormError form={formik} field={'endDate'} />
                </div>
            </div>
            <div className="col-12 mb-3">
                <PartnershipSelect handleChange={p => formik.setFieldValue('partnership_id', p)} pkey={'provider'} />
                <FormError form={formik} field={'partnership_id'} />
            </div>
            <div className="col-12 mb-3">
                <CustomerSelect defaultValue={defaultValues?.customer} handleChange={p => formik.setFieldValue('customer_id', p)} />
                <FormError form={formik} field={'customer_id'} />
            </div>

            <div className="col-12 mb-3">
                <Select
                    label={"Devise"}
                    disabled={formik.isSubmitting}
                    placeholder={"Séléctionnez la devise de l'abonnement"}
                    options={getCurrencies()}
                    multiple={false}
                    required={true}
                    onChange={choices => formik.setFieldValue('currency_id', choices.id)}
                />
                <FormError form={formik} field={"currency_id"} />
            </div>

            {/* Dynamic Items Section */}
            <div className="col-12 mb-3">
                <h6>Produits</h6>
                {items.map((item, index) => (
                    <div key={index} className="row mb-3">
                        <div className="col-12">
                            <p className="mb-1 fw-semibold">
                                {item.product.name}
                            </p>
                        </div>
                        <div className="col-md-5">
                            <div className="form-floating">
                                <input
                                    type="number"
                                    className="form-control"
                                    name={`items[${index}].quantity`}
                                    id={`items[${index}].quantity`}
                                    placeholder="Quantité"
                                    onChange={e => setItems(prevState => [...prevState.map((p, i) => i === index ? {...p, quantity: Number(e.target.value)} : p)])}
                                    onBlur={formik.handleBlur}
                                    value={item.quantity}
                                />
                                <label htmlFor={`items[${index}].quantity`}>
                                    Quantité
                                </label>
                            </div>
                            <FormError form={formik} field={`items[${index}].quantity`} />
                        </div>
                        <div className="col-md-5">
                            <Select
                                label={"Price"}
                                disabled={formik.isSubmitting}
                                placeholder={"Séléctionnez le prix"}
                                options={item.product.prices.map(price => ({label: `${convertToDecimal(price.amount, price.currency)} ${price.recurring ? `${price.recurring.intervalCount} ${price.recurring.interval}` : ''}`, value: price}))}
                                value={{label: `${convertToDecimal(item.price.amount, item.price.currency)} ${item.price.recurring ? `${item.price.recurring.intervalCount} ${item.price.recurring.interval}` : ''}`, value: item.price}}
                                multiple={false}
                                required={true}
                                onChange={choice => setItems(prevState => [...prevState.map((p, i) => i === index ? choice.value : p)])}
                            />
                            <FormError form={formik} field={`items[${index}].price_id`} />
                        </div>
                        <div className="col-md-2 d-flex align-items-center">
                            <button type="button" className="btn btn-light w-100" onClick={() => setItems(prevState => [...prevState.filter((p, i) => i !== index)])}><i className={"bi bi-x text-danger"}></i> Retirer</button>
                        </div>
                    </div>
                ))}
                <div className="col-12 mb-3">
                    <SupplierProductPriceSelect label={"Rechercher un produit"} handleChange={(product, price) => setItems(prevState => [{product, price, quantity: 1}, ...prevState])} />
                </div>
            </div>
            {error && <div className={"col-12 mb-3"}><ErrorCard error={error} /></div>}
            <button type="submit" className="btn btn-primary w-100" disabled={formik.isSubmitting}>
                {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-plus-circle'}></i> Ajouter</>}
            </button>
        </form>
    );
}

export default SupplierSubscriptionAdd;
