import React, {useEffect, useState} from "react";
import * as api from "../../../../../adapters/corporate/common/customer";
import Select from "../../../../../utils/select";
import {Payload} from "../../../../../models/global";
import {CustomerAccount, CustomerAccounts} from "../../../../../models/account";
import ErrorCard from "../../../../error";

const CustomerSelect = ({handleChange, required, defaultValue}: {handleChange: (id: number) => void, required?: boolean, defaultValue?: CustomerAccount}) => {

    const [payload, setPayload] = useState<Payload<CustomerAccounts>>({rows: [], count: 0})
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null)
    const [fetchParams, setFetchParams] = useState<{query: string, offset: number}>({query: "", offset: 0})

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    if (error){
        return <ErrorCard error={error} />
    }

    return <Select
        multiple={false}
        disabled={loading}
        required={required}
        label={"Client"}
        value={defaultValue ? ({label: defaultValue.user.email, value: defaultValue.id}) : undefined}
        options={payload.rows.map(s => ({label: s.user.email, value: s.id}))}
        onChange={(c: any) => handleChange(c.value)}
        handleScrollBottom={() => setFetchParams(p => ({...p, offset: p.offset + 1}))}
        handleQueryChange={(query) => setFetchParams(p => ({query: query, offset: 0}))}
    />
}

export default CustomerSelect;