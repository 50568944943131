import React, {useEffect, useState} from "react";
import * as api from "../../../../../../adapters/corporate/common/chat";
import * as models from "../../../../../../models/chat";
import ChatListItem from "../../list/item";
import ErrorCard from "../../../../../error";
import Loader from "../../../../../../utils/loader";
import {useNavigate} from "react-router-dom";

const ChatSearchChat = ({query, setShowSearch}: {query: string, setShowSearch: React.Dispatch<any>}) => {

    const [loading, setLoading] = useState(false)
    const [payload, setPayload] = useState<{count: number, rows: models.Chats}>({count: 0, rows: []})
    const [error, setError] = useState(null)
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string}>({offset: 0, query: ""});
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(r => setPayload(prev => ({count: r.data.count, rows: [...prev.rows, r.data.rows]})))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams.offset, fetchParams.query])

    useEffect(() => {
        handleQueryChange(query)
    }, [query])

    let timer: NodeJS.Timer;
    function handleQueryChange(_query: string){
        clearTimeout(timer)
        timer = setTimeout(() => {

            if (query !== _query){
                setPayload({count: 0, rows: []})
            }

            setFetchParams({query: _query, offset: 0})
        }, 1000)
    }

    function handleClick(chat: models.Chat){
        navigate(`/corporate/chat/${chat.id}`)
        setShowSearch(false)
    }

    return <div className="col-12 px-0">
        {loading && <Loader size={"sm"} />}
        {error && <ErrorCard error={error} />}
        {payload.rows.length && <>
            <ul className={"list-group list-group-flush"}>
                {payload.rows.map(r => <ChatListItem handleClick={() => handleClick(r)} chat={r} key={r.id}/>)}
                {payload.rows.length < payload.count && <li className={"list-group-item"}>
                    <span className={"text-decoration-underline cursor-pointer"} onClick={() => setFetchParams(prev => ({...prev, offset: prev.offset + 1}))}>Charger plus de conversations</span>
                </li>}
            </ul>
        </>}
    </div>
}

export default ChatSearchChat;