import React, {useState} from "react";
import * as models from "../../../../../../models/organization";
import FeedOrganization from "../feed";
import OrganizationProductList from "./product";
import OrganizationAffiliationList from "./affiliation";
import {useAppSelector} from "../../../../../../hooks";
import {CorporateAccount} from "../../../../../../models/account";
import OrganizationAssignment from "./assignment";

const SupplierShow:React.FC<{ organization: models.Organization } > = ({organization}) => {

    const activeAccount = useAppSelector(state => state.auth.user?.activeAccount) as CorporateAccount
    const authOrganization = activeAccount.organization;
    const [tab, setTab] = useState<"feed"|"product"|"assignment"|"affiliation">("assignment")

    const Content = () => {
        switch (authOrganization.type){
            case "provider":
                return <>
                    <div className="row justify-content-center">
                        <div className="col-auto">
                            <div className="mb-2 mb-lg-0 border-bottom-lg">
                                <nav className="nav nav-tabs nav-overflow fs-xs">
                                    <button className={"nav-link text-uppercase" + (tab === "assignment" ? " active " : "")} data-bs-toggle="tab" onClick={() => setTab("assignment")}>
                                        Annonces
                                    </button>
                                    <button className={"nav-link text-uppercase" + (tab === "product" ? " active " : "")} data-bs-toggle="tab" onClick={() => setTab("product")}>
                                        Catalogue
                                    </button>
                                    <button className={"nav-link text-uppercase" + (tab === "feed" ? " active " : "")} data-bs-toggle="tab" onClick={() => setTab("feed")}>
                                        Actualités
                                    </button>
                                    <button className={"nav-link text-uppercase" + (tab === "affiliation" ? " active " : "")} data-bs-toggle="tab" onClick={() => setTab("affiliation")}>
                                        Affiliations
                                    </button>
                                </nav>
                            </div>
                        </div>
                    </div>
                    {tab === "assignment" && <OrganizationAssignment organization={organization} />}
                    {tab === "product" && <OrganizationProductList organization={organization} />}
                    {tab === "feed" && <FeedOrganization organization={organization} />}
                    {tab === "affiliation" && <OrganizationAffiliationList organization={organization} />}
                </>
            case "supplier":
                return <FeedOrganization organization={organization} />
        }
    }

    return <div className={'container-fluid h-100'}>
        <div className="row">
            <div className="col-md-3 mt-3">
                <div className="col-12 mb-3">
                    <div className="card card-sm border border-1">
                        <div className="card-header">
                            <h6>
                                Sécteurs
                            </h6>
                        </div>
                        <div className="card-body">
                            {organization.sectors.map(s => <span className={'badge bg-primary me-2 mb-2'}>{s.name}</span>)}
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <div className="card card-sm border border-1">
                        <div className="card-header">
                            <h6>
                                Adresse
                            </h6>
                        </div>
                        <div className="card-body">
                            {organization.billing ? <p>
                                {organization.billing.city}, {organization.billing.postalCode}, {organization.billing.country.name}
                            </p> : <><i className={'bi bi-x'}></i> Adresse non renseigné</>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-9 mb-3">
                <Content />
            </div>
        </div>
    </div>
}

export default SupplierShow;