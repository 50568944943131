import React from "react";
import * as models from "../../../../../../models/organization";
import FeedOrganization from "../feed";
import CommonOrganizationService from "./service";

const ProviderShow:React.FC<{ organization: models.Organization & models.OrganizationProvider}> = ({organization}) => {

    return <div className={'row'}>
        <div className="col-md-4 mb-3">
            <div className="col-md-12 mb-3">
                <div className="card border border-1">
                    <div className="card-body">
                        <h6>Sécteurs</h6>
                        {organization.sectors.map(s => <span className={'badge bg-primary me-2 mb-2'}>{s.name}</span>)}
                    </div>
                </div>
            </div>
            <div className="col-md-12 mb-3">
                <div className="card border border-1">
                    <div className="card-body">
                        <h6>Tarification</h6>
                        {organization.pricing === "Fix" && <>Comprends un montant fix</>}
                        {organization.pricing === "Fee" && <>A la commission</>}
                        {organization.pricing === "Hybrid" && <>Prestation montant fix et commissions</>}
                    </div>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-body">
                        <h6>
                            Adresse
                        </h6>
                        {organization.billing ? <div className="row">
                            <div className="col-6">
                                <small className="text-body-secondary">
                                    Pays
                                </small>
                                <p>
                                    France
                                </p>
                            </div>
                            <div className="col-6">
                                <small className="text-body-secondary">
                                    Ville
                                </small>
                                <p>
                                    {organization.billing.city}
                                </p>
                            </div>

                            <div className="col-6">
                                <small className="text-body-secondary">
                                    Code postal
                                </small>
                                <p>
                                    {organization.billing.postalCode}
                                </p>
                            </div>
                        </div> : <><i className={'bi bi-x'}></i> Adresse non renseigné</>}
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-8 mb-3">
            <div className="col-12 mb-3">
                <CommonOrganizationService organization={organization} />
            </div>
            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-body">
                        <h6>Actualités</h6>
                        <FeedOrganization organization={organization} />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ProviderShow;