import {Product} from "../../../../../models/product";
import {getProductFileUrl} from "../../../../../utils/image";

const ProductShowCarousel = ({product}: {product: Product}) => {

    const files = product.files.sort(f => f.cover ? -1 : 1)

    return <div className={"position-relative mb-3"} id={"productCarousel"}>
        <div id="carouselProductFileDark" className="carousel slide carousel-fade">
            <div className="carousel-inner">
                {files.map((f, k) => <div className={`carousel-item ${k === 0 ? 'active' : ''}`}>
                    <img src={getProductFileUrl(product, f)} className="d-block product-carousel-img" alt={f.name} />
                </div>)}
            </div>
            <div className="btn-group position-absolute" style={{right: 20, bottom: 20, zIndex: 100}}>
                <button className="btn btn-sm btn-circle btn-light shadow-sm" type="button" data-bs-target="#carouselProductFileDark" data-bs-slide="prev">
                    <i className={'bi bi-chevron-left'}></i>
                </button>
                <button className="btn btn-sm btn-circle btn-light shadow-sm" type="button" data-bs-target="#carouselProductFileDark" data-bs-slide="next">
                    <i className={'bi bi-chevron-right'}></i>
                </button>
            </div>
        </div>
        <div className="row mt-1 gx-1">
            {files.map((f, k) => <div className={'col-2 carousel-indicator-image'}>
                <div data-bs-target="#carouselProductFileDark" data-bs-slide-to={k} className={`w-100 ${k === 0 ? 'active' : ''}`} aria-current="true" aria-label="Slide 1">
                    <img src={getProductFileUrl(product, f)} className="d-block product-carousel-indicator-img" alt={f.name} />
                </div>
            </div>)}
        </div>
    </div>
}

export default ProductShowCarousel