import {SubscriptionItem} from "../../../../../../models/subscription";
import {getCoverUrl} from "../../../../../../utils/image";
import React from "react";

const CustomerSubscriptionItemListItem = ({item}: {item: SubscriptionItem}) => {

    return <div className={"col-12 col-md-4 col-lg-3 mb-3"}>
        <div className="card border border-1">
            <img src={`${getCoverUrl(item.price.product)}`} alt="" className="card-img-top br-0"/>
            <div className="card-body bg-white">
                <div className="row">
                    <div className="col">
                        <p className="d-block fw-bold text-body mb-1">
                            {item.price.product.name}
                        </p>
                        <p className="fs-xs text-muted mb-0">
                            Quantité: {item.quantity}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default CustomerSubscriptionItemListItem;