import React, {useEffect, useState} from "react";
import * as api from "../../../../../../adapters/corporate/supplier/feature";
import * as productTaxApi from "../../../../../../adapters/corporate/supplier/product/feature";
import {Feature, Features} from "../../../../../../models/feature";
import Pagination from "../../../../../../utils/pagination";
import Loader from "../../../../../../utils/loader";
import * as models from "../../../../../../models/product";
import ModalComponent from "../../../../../modal";
import SupplierFeatureAdd from "../../../feature/add";

const SupplierProductFeatureAdd = ({ product, setProduct, unlink }: { product: models.Product & models.SellableProduct, setProduct: React.Dispatch<any>, unlink: (feature: Feature) => void }) => {

    const [loading, setLoading] = useState(true)
    const [showAddModal, setShowAddModal] = useState(false)
    const [payload, setPayload] = useState<{rows: Features, count: number}>({rows: [], count: 0})
    const [fetchParams, setFetchParams] = useState<{query: string, offset: number}>({query: "", offset: 0})

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(r => setPayload(r.data))
            .then(() => setLoading(false))
    }, [fetchParams])

    function handleChange(feature: Feature, checked: boolean){
        if (checked){
            productTaxApi.link(product.id, feature.id)
                .then(() => setProduct((prev: models.Product & models.SellableProduct) => ({...prev, features: [...prev.features, feature]})))
        }else{
            productTaxApi.unlink(product.id, feature.id)
                .then(() => unlink(feature))
        }
    }

    return <>
        <div className="col-12 mb-3">
            <div className="row">
                <div className="col">
                    {loading ? <Loader /> : <>{payload.count} résultats</>}
                </div>
                <div className="col-auto">
                    <button className={'btn btn-xs btn-primary rounded'} onClick={() => setShowAddModal(true)}>
                        <i className={'bi bi-plus-circle'}></i> Ajouter
                    </button>
                </div>
            </div>
        </div>
        <table className={'table'}>
            <thead>
            <tr>
                <th>

                </th>
                <th>
                    Description
                </th>
            </tr>
            </thead>
            <tbody>
            {payload.rows.map(item => <tr>
                <td>
                    <div className="form-check">
                        <input
                            key={item.id}
                            defaultChecked={product.features.findIndex(i => i.id === item.id) !== -1}
                            className="form-check-input"
                            type="checkbox"
                            onChange={e => handleChange(item, e.target.checked)}
                            id={`item_${item.id}`}
                        />
                    </div>
                </td>
                <td>
                    {item.description}
                </td>
            </tr>)}
            </tbody>
        </table>
        <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        <ModalComponent title={"Ajouter une fonctionnalité"} show={showAddModal} onHide={() => setShowAddModal(false)}>
            <SupplierFeatureAdd handleSuccess={feature => {
                handleChange(feature, true)
                setPayload(prevState => ({count: prevState.count + 1, rows: [feature, ...prevState.rows]}))
                setShowAddModal(false)
            }} />
        </ModalComponent>
    </>
}

export default SupplierProductFeatureAdd;