import {Proposal} from "../../../../../models/proposal";
import React, {useState} from "react";
import ProposalPayment from "./stripe";
import CommonPaymentMethodSelect from "../../../../common/paymentMethod/select";
import {PaymentMethod} from "../../../../../models/paymentMethod";
import convertToDecimal from "../../../../../utils/currencyConverter";

const SupplierProposalPayment = ({proposal, handleSuccess}: {proposal: Proposal, handleSuccess: (proposal: Proposal) => void}) => {

    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>()

    return <>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-body">
                    <h6>Récapitulatif du paiement</h6>
                    <table className={'table table-borderless'}>
                        <tbody>
                        <tr>
                            <th>Montant HT</th>
                            <td>{convertToDecimal(proposal.priceData.amount_excluding_tax, proposal.currency)}</td>
                        </tr>
                        <tr>
                            <th>Montant taxes</th>
                            <td>{convertToDecimal(proposal.priceData.tax_amount, proposal.currency)}</td>
                        </tr>
                        <tr>
                            <th>Montant TTC</th>
                            <td>{convertToDecimal(proposal.priceData.amount_including_tax, proposal.currency)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <h6>Methode de paiement</h6>
            <CommonPaymentMethodSelect handleSelect={pm => setPaymentMethod(pm)} selected={paymentMethod} />
        </div>
        <ProposalPayment handlePaymentSucceed={handleSuccess} proposal={proposal} paymentMethod={paymentMethod} />
    </>

}

export default SupplierProposalPayment;