import FormError from "../../../../../utils/formError";
import Loader from "../../../../../utils/loader";
import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../adapters/corporate/supplier/feeRule";
import ErrorCard from "../../../../error";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";
import {FeeRule} from "../../../../../models/feeRule";

const SupplierFeeRuleAdd = ({handleSuccess}: {handleSuccess: (feeRule: FeeRule) => void}) => {

    const [error, setError] = useState(null)

    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            description: Yup.string().required()
        }),
        onSubmit: (values, formikHelpers) => {
            api.add({...values})
                .then(r => handleSuccess(r.data))
                .catch(error => setError(error))
                .finally(() => formikHelpers.setSubmitting(false))
        }
    })

    return <form onSubmit={formik.handleSubmit}>
        {error && <ErrorCard error={error} />}
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className={'form-control'} name={"name"} id={"name"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                <label htmlFor="line1">
                    Nom *
                </label>
            </div>
            <FormError form={formik} field={"name"} />
        </div>
        <div className="col-12 mb-3">
            <FormError form={formik} field={"description"} />
            <ReactQuill value={formik.values.description} onChange={value => formik.setFieldValue('description', value)} />
        </div>
        <button className="btn btn-primary w-100 mb-3">
            {formik.isSubmitting ? <Loader /> : <><i className={'bi bi-check'}></i> Enregistrer</>}
        </button>
    </form>
}

export default SupplierFeeRuleAdd;