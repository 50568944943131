import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query: string, offset: number}){
    return get(`corporate/supplier/feature${encodeQueryData(requestData)}`);
}

export function show(feature: number){
    return get(`corporate/supplier/feature/${feature}`);
}

export function add(requestData: {description: string}){
    return post(`corporate/supplier/feature`, requestData);
}

export function update(feature: number, requestData: {active: boolean}){
    return post(`corporate/supplier/feature/${feature}`, requestData);
}
