import React from "react";
import {FormikProps, getIn} from "formik";

const FormError = (props: {form: FormikProps<any>, field: string}) => {
    const {form, field} = props;

    const {touched, errors} = form;

    let touched_bool = getIn(touched, field)
    let error_str = getIn(errors, field)

    return touched_bool && error_str ? <>
        <div className={'form-text text-danger'}>
            <i className={'bi bi-exclamation-circle'}> </i>
            {error_str}
        </div></> : <></>
}

export default FormError;