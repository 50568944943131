import {useFormik} from "formik";
import * as Yup from "yup";
import FormError from "../../../../../../../utils/formError";
import * as api from "../../../../../../../adapters/corporate/supplier/project"
import {Project} from "../../../../../../../models/project";
import React, {useState} from "react";
import Loader from "../../../../../../../utils/loader";
import ErrorCard from "../../../../../../error";

const SupplierProjectListItemContact = ({project}: {project: Project}) => {

    const [messageSent, setMessageSent] = useState(false)
    const [error, setError] = useState(null)

    const formik = useFormik({
        initialValues: {
            content: ""
        },
        validationSchema: Yup.object().shape({
            content: Yup.string().required()
        }),
        onSubmit: (values, {setSubmitting}) => {
            setError(null)
            api.contact(project.id, values)
                .then(r => setMessageSent(true))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    })

    if (messageSent){
        return <div className={"col-12 text-center"}>
            <h2>
                <i className={"bi bi-check2-all text-success fs-2"}></i> Message envoyé.
            </h2>
        </div>
    }

    return <form onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <h6>
                Votre message
            </h6>
            <textarea disabled={formik.isSubmitting} className={'form-control'} style={{minWidth: 200}} name={"content"} onChange={formik.handleChange}></textarea>
            <FormError form={formik} field={'content'} />
        </div>
        {error && <div className={'col-12 mb-3'}>
            <ErrorCard error={error} />
        </div>}
        <button className="btn btn-primary w-100" type={'submit'}>
            {formik.isSubmitting ? <Loader /> : <><i className={'bi bi-send'}></i> Envoyer</>}
        </button>
    </form>
}

export default SupplierProjectListItemContact;