import React from "react";

interface Interface {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    size: "sm"|"default"|"lg"
    placeholder?: string,
    autoFocus?: boolean,
    defaultValue?: string,
    value?: string,
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void,

}

const SearchInput:React.FC<Interface> = (props) => {

    const {onChange, defaultValue, placeholder, size, autoFocus, onFocus, value} = props;

    function getBtn(){
        switch (size){
            case "sm":
                return " btn-xxs "
            case "default":
                return " btn-sm "
            case "lg":
                return " "
        }
    }

    return <div className={'search-input bg-white col row align-item-center border border-1 rounded-pill p-1 p-md-2 p-xl-3 shadow-sm'}>
        <div className="col">
            <input autoFocus={autoFocus} value={value} onFocus={onFocus} defaultValue={defaultValue} onChange={onChange} placeholder={placeholder} type="text" className={`form-control form-control-${size} border-0`} />
        </div>
        <div className="col-auto">
            <div className={"shadow-sm bg-light border border-1 h-100 text-center d-flex align-items-center position-relative"} style={{borderRadius: '50%', aspectRatio: 1, right: 10}}>
                <i className={'bi bi-search mx-auto'}></i>
            </div>
        </div>
    </div>
}

export default SearchInput;