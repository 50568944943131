import React, {useEffect, useState} from "react";
import * as api from '../../../../../adapters/corporate/common/transfer';
import SearchInput from "../../../../../utils/searchInput";
import * as models from "../../../../../models/transfer";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";
import TransferStatusPlaceholder from "../../../../corporate/common/transfer/status";
import * as orderModels from "../../../../../models/order";
import * as subscriptionModels from "../../../../../models/subscription";
import ErrorCard from "../../../../error";
import CommonTransferListItem from "./item";
import {Quote} from "../../../../../models/quote";

const CommonTransferList = ({order, occurrence, quote}: {order?: orderModels.Order, occurrence?: subscriptionModels.SubscriptionOccurrence, quote?: Quote}) => {

    const [payload, setPayload] = useState<{ count: number, rows: models.Transfers }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState();
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number, status: "All"|"Pending"|"Completed"|"Canceled"}>({query: "", offset: 0, status: "All"})
    const status_array: ("All"|"Pending"|"Completed"|"Canceled")[] = ["All", "Pending","Completed","Canceled"];

    useEffect(() => {
        setLoading(true)
        api.list({...fetchParams, order_id: order?.id, subscription_occurrence_id: occurrence?.id, quote_id: quote?.id})
            .then(resp => setPayload(resp.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams, order])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams(p => ({...p, query: e.target.value, offset: 0})), 1000)
    }

    return (
        <>
            <div className="col-12 mb-3 flex-grow-0">
                <div className="row align-items-center">
                    <div className="col-12 mb-3">
                        <SearchInput placeholder={"Rechercher un transfer..."} onChange={handleQueryChange} size={"sm"} />
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-auto">
                                <div className="dropdown">
                                    <button className="btn btn-light btn-xxs dropdown-toggle" type="button"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                        Statut: <TransferStatusPlaceholder status={fetchParams.status} />
                                    </button>
                                    <ul className="dropdown-menu">
                                        {status_array.filter(s => s !== fetchParams.status).map((s) => <li>
                                        <span className="dropdown-item cursor-pointer" onClick={() => setFetchParams(prev => ({...prev, status: s}))}>
                                            <TransferStatusPlaceholder status={s} />
                                        </span>
                                        </li>)}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-auto">
                            <span className="text-muted">
                                {loading ? <Loader size={"sm"} /> : <>{payload.count} résultats</>}
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12 flex-grow-1">
                {error && <ErrorCard error={error} />}
                <div className={'table-responsive'}>
                    <table className={'table table-hover'}>
                        <thead>
                        <tr>
                            <th>
                                Ref
                            </th>
                            <th>
                                Montant
                            </th>
                            <th>
                                Frais de plateforme
                            </th>
                            <th>
                                Montant net
                            </th>
                            <th>
                                Date
                            </th>
                            <th>
                                Statut
                            </th>
                            <th>
                                Facture
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {payload.rows.map(t => <CommonTransferListItem t={t} key={t.id} />)}
                        </tbody>
                    </table>
                </div>
                <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
            </div>
        </>
    )
}

export default CommonTransferList;