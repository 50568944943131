import React from "react";
import CheckoutItemList from "../list";
import {Link} from "react-router-dom";
import * as models from "../../../../models/checkout";
import convertToDecimal from "../../../../utils/currencyConverter";

const CheckoutItemOffcanvas = ({checkout}: {checkout: models.CheckoutResponse}) => {

    return <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasCheckoutItem" aria-labelledby="offcanvasCheckoutItemLabel">
        <div className="offcanvas-header lh-fixed fs-lg">
            <h5 className="offcanvas-title" id="offcanvasCheckoutItemLabel">Articles</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <>
            <CheckoutItemList lineItems={checkout.items} currency={checkout.currency} country={checkout.account_data.shipping?.country} />
            <div className="offcanvas-footer justify-between lh-fixed fs-sm bg-light mt-auto">
                <strong>Total</strong> <strong className="ms-auto">{convertToDecimal(checkout.price_data.cart.amount_including_tax, checkout.currency)}</strong>
            </div>
            <div className="offcanvas-body">
                <Link className="btn w-100 btn-dark" to={checkout.source.model === "bundle" ? `/bundle/${checkout.source.id}`  : `/${checkout.source.model}/${checkout.source.id}/cart`}>Modifier la commande <i className={"bi bi-arrow-right"}></i></Link>
            </div>
        </>
    </div>
}

export default CheckoutItemOffcanvas