import Select from "../../../../../utils/select";
import React, {useEffect, useState} from "react";
import {FeeRules} from "../../../../../models/feeRule";
import * as api from "../../../../../adapters/corporate/supplier/feeRule";
import ErrorCard from "../../../../error";
import {FetchParams, Payload} from "../../../../../models/global";
import ModalComponent from "../../../../modal";
import SupplierFeeRuleAdd from "../add";

const SupplierFeeRuleSelect = (props: {allowAdd?: boolean, label: string, required: boolean, handleChange: (choice: number) => void, defaultValue?: {label: string, value: any}}) => {

    const [loading, setLoading] = useState(true)
    const [payload, setPayload] = useState<Payload<FeeRules>>({count: 0, rows: []})
    const [fetchParams, setFetchParams] = useState<FetchParams>({offset: 0, query: ""})
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false)
    const [defaultValue, setDefaultValue] = useState({label: props.defaultValue?.label || "", value: props.defaultValue?.value || null});

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    return (<>
        <div className="row">
            <div className="col-12">
                <Select
                    multiple={false}
                    disabled={loading}
                    required={props.required}
                    label={props.label}
                    options={payload.rows.map(c => ({label: `${c.name}`, value: c.id}))}
                    onChange={(c: any) => props.handleChange(c.value)}
                    handleScrollBottom={() => setFetchParams(p => ({...p, offset: p.offset + 1}))}
                    handleQueryChange={(query) => setFetchParams(p => ({query: query, offset: 0}))}
                />
            </div>
            {props.allowAdd && <div className={'col-12 text-end form-text text-decoration-underline cusrsor-pointer'} onClick={() => setShowModal(true)}>
                Ajouter une règle de commission
            </div>}
        </div>
        {error && <div className={"col-12 mb-3"}>
            <ErrorCard error={error} />
        </div>}
        <ModalComponent size={"modal-lg"} title={"Ajouter une règle de commission"} show={showModal} onHide={() => setShowModal(false)}>
            <SupplierFeeRuleAdd handleSuccess={(rule) => {
                setDefaultValue({label: rule.name, value: rule.id})
                setPayload(prev => ({count: prev.count + 1, rows: [rule, ...prev.rows]}))
                props.handleChange(rule.id)
                setShowModal(false)
            }}/>
        </ModalComponent>
    </>)


}

export default SupplierFeeRuleSelect;