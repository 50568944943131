import React, {useEffect, useState} from "react";
import {Organizations} from "../../../../../models/organization";
import * as api from "../../../../../adapters/corporate/common/organization";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import {Link} from "react-router-dom";
import {getLogoUrl} from "../../../../../utils/image";

const HomeSuggestion = ({query, sort, count}: {query: string, sort: "ranking"|'latest'|'trending', count: number}) => {

    const [loading, setLoading] = useState(true)
    const [payload, setPayload] = useState<{count: number, rows: Organizations}>({count: 0, rows: []});
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        api.list({offset: 0, query: query, sort: sort, count: count, excludePartners: true})
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [query, count])

    if (loading){
        return <div className={'container-fluid py-10 text-center'}>
            <Loader />
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }


    if (!payload.count){
        return <div className={'container-fluid py-10 text-center'}>
            <h6 className="text-muted">
                <i className={"bi bi-search"}></i> Aucun réultat
            </h6>
        </div>
    }

    return <>
        <div className={'row row-cols-1'}>
            {payload.rows.map(item => <div className={"col mb-3"}>
                <Link className="card h-100 border border-1 shadow-hover cursor-pointer position-relative" to={`/corporate/organization/${item.id}`} target={'_blank'}>
                    {item.myPartnership && <span style={{top: 10, left: -10}} className={'badge bg-success position-absolute'}>
                <i className={'bi bi-check'}></i> Partenaire
            </span>}
                    <img src={`${getLogoUrl(item)}`} alt="" className="card-img-top"/>
                    <div className="card-body p-2">
                        <div className="card-body">
                            <p className="d-block fw-semibold text-body mb-1">
                                {item.name} {item.status === 'Verified' && <i className={'bi bi-check-circle text-success'}></i>}
                            </p>
                            <div className="fs-sm fw-bold text-primary">
                                <i className={"bi bi-trophy"}></i> {item.ranking?.score.toLocaleString() || 0}
                            </div>
                            <div className="fs-sm text-muted">
                                {item.billing?.city}, {item.billing?.country.name}
                            </div>
                            <div className="col-12">
                                {item.sectors.slice(0,2).map(s => <span className={'badge bg-light text-dark me-2 mb-2'}>{s.name}</span>)} {item.sectors.length > 2 && <span className={'badge bg-light text-dark me-2 mb-2'}>+ {item.sectors.length - 2}</span>}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>)}
        </div>
    </>
}

export default HomeSuggestion;