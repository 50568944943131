import React, {useEffect, useState} from "react";
import {ShippingAddresses, ShippingAddress, Shipping} from "../../models/shipping";
import * as addressApi from "../../adapters/corporate/supplier/shipping/address";
import Select from "../select";

const ShippingAddressSelect = ({handleChange, required, shipping}: {handleChange: (a: number) => void, shipping?: Shipping, required?: boolean}) => {

    const [addresses, setAddresses] = useState<{count: number, rows: ShippingAddresses}>({rows: [], count: 0})
    const [loading, setLoading] = useState(false);
    const [fetchParams, setFetchParams] = useState<{query: string, offset: number, shipping?: number}>({query: "", offset: 0, shipping: shipping?.id})

    useEffect(() => {
        setLoading(true)
        addressApi.list(fetchParams)
            .then(r => setAddresses(r.data))
            .then(() => setLoading(false))
    }, [fetchParams])

    return <Select
        multiple={false}
        disabled={loading}
        required={required}
        label={'Lieu de traitement'}
        options={addresses.rows.map(a => ({label: `${a.line1}, ${a.city}, ${a.country.name}`, value: a.id}))}
        onChange={(c: any) => handleChange(c.value)}
        handleScrollBottom={() => setFetchParams(p => ({...p, offset: p.offset + 1}))}
        handleQueryChange={(query) => setFetchParams(p => ({query: query, offset: 0}))}
    />
}

export default ShippingAddressSelect;