import {get, post} from "../../xhr";
import encodeQueryData from "../../../utils/encodeQueryData";

export function show(id: number) {
    return get(`/common/payment-method/${id}`);
}

export function list(requestData: {offset: number}) {
    return get(`/common/payment-method${encodeQueryData(requestData)}`);
}

export function add(requestData: any) {
    return post(`/common/payment-method`, requestData);
}

export function update(pmId: number, requestData: { activated?: boolean, default?: boolean }) {
    return post(`/common/payment-method/${pmId}`, requestData);
}