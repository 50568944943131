import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import FormError from "../../../../../utils/formError";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../adapters/corporate/common/onboarding";
import React, {useState} from "react";
import authSlice from "../../../../../reducers/auth";
import Loader from "../../../../../utils/loader";
import {useNavigate} from "react-router-dom";
import ErrorCard from "../../../../error";
import {CorporateAccount} from "../../../../../models/account";

const OnBoardingBio = () => {

    const activeAccount = useAppSelector(state => state.auth.user?.activeAccount) as CorporateAccount
    const authOrganization = activeAccount.organization;
    const bio = authOrganization.bio

    const dispatch = useAppDispatch();
    const [error, setError] = useState(null)
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            description: bio?.description || "",
            website:  bio?.website || "",
            instagram:  bio?.instagram || "",
            x:  bio?.x || "",
            facebook: bio?.facebook || "",
            linkedin: bio?.linkedin || "",
        },
        validationSchema: Yup.object().shape({
            description: Yup.string().required(),
            website: Yup.string().nullable(),
            instagram: Yup.string().nullable(),
            x: Yup.string().nullable(),
            facebook: Yup.string().email().nullable(),
            linkedin: Yup.string().nullable(),
        }),
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            api.bio(values)
                .then(resp => dispatch(authSlice.actions.upsertAccountOrganizationBio(resp.data)))
                .catch(e => setError(e))
                .then(() => navigate('/onboarding/address'))
        }
    })

    return <form onSubmit={formik.handleSubmit}>
        <h3 className={"mb-8"}>
            Remplissez vos informations pour trouver des partenaires adaptés
        </h3>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <textarea style={{minHeight: 200}} className={'form-control'} name={"description"} id={"description"} onBlur={formik.handleBlur} onChange={formik.handleChange}></textarea>
                <label htmlFor="description">
                    Décrivez votre entreprise
                </label>
            </div>
            <FormError form={formik} field={"description"} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className="form-control" defaultValue={bio?.website} name={"website"} id={'website'} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                <label htmlFor="website">
                    Lien de votre site internet
                </label>
            </div>
            <FormError form={formik} field={"website"} />
        </div>
        <h6>
            Réseaux sociaux
        </h6>
        <div className="col-12 mb-3">
            <div className="input-group">
                <span className="input-group-text">
                    <i className={"bi bi-linkedin"}></i>
                </span>
                <div className="form-floating">
                    <input type="text" className="form-control" defaultValue={bio?.linkedin} name={"linkedin"} id={'linkedin'} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                    <label htmlFor="website">
                        Lien Linkedin
                    </label>
                </div>
            </div>
            <FormError form={formik} field={"linkedin"} />
        </div>
        <div className="col-12 mb-3">
            <div className="input-group">
                <span className="input-group-text">
                    <i className={"bi bi-instagram"}></i>
                </span>
                <div className="form-floating">
                    <input type="text" className="form-control" defaultValue={bio?.instagram} name={"instagram"} id={'instagram'} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                    <label htmlFor="website">
                        Lien instagram
                    </label>
                </div>
            </div>
            <FormError form={formik} field={"instagram"} />
        </div>
        {error && <ErrorCard error={error} />}
        <button className="btn btn-outline-primary w-100 mb-3">
            {formik.isSubmitting ? <Loader /> : <>Suivant <i className={'bi bi-arrow-right'}></i></>}
        </button>
    </form>

}

export default OnBoardingBio;