import * as models from "../../../../../../models/link"
import * as api from "../../../../../../adapters/corporate/provider/link/item"
import React, {useEffect, useState} from "react";
import Pagination from "../../../../../../utils/pagination";
import Loader from "../../../../../../utils/loader";
import ProviderLinkItemShow from "../show";
import ProviderLinkItemSelector from "../selector";
import ModalComponent from "../../../../../modal";
import {Product, SellableProduct} from "../../../../../../models/product";
import ProviderLinkItemAdd from "../add";
import {LinkItem} from "../../../../../../models/link";

const ProviderLinkItemList = ({link}: {link: models.Link}) => {

    const [loading, setLoading] = useState(true)
    const [payload, setPayload] = useState<{count: number, rows: models.LinkItems}>({count: 0, rows: []})
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string}>({offset: 0, query: ""});
    const [showSelector, setShowSelector] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [selected, setSelected] = useState<Product & SellableProduct>();

    useEffect(() => {
        setLoading(true)
        api.list(link.id, fetchParams)
            .then(r => setPayload(r.data))
            .then(() => setLoading(false))
    }, [link, fetchParams])

    function handleRemove(item: LinkItem){
        api._destroy(link.id, item.id)
            .then(() => setPayload(prev => ({...prev, rows: [...prev.rows.filter(i => i.id !== item.id)]})))
    }

    function handleChange(item: LinkItem){
        setPayload(prev => ({...prev, rows: [...prev.rows.map(i => i.id === item.id ? item : i)]}))
    }

    return <div className={'card border border-1'}>
        <div className="card-header">
            <div className="row align-items-center">
                <div className="col">
                    <h6>
                        Articles
                    </h6>
                </div>
                <div className="col-auto">
                    <button className={'btn btn-xs btn-primary'} onClick={() => setShowSelector(true)}>
                       <i className={'bi bi-plus-circle'}></i> Ajouter un article
                    </button>
                </div>
            </div>
        </div>
        <div className="card-body">
            {loading && <div className={'col-12 py-5 text-center'}><Loader /></div>}
            <ul className="list-group list-group-lg list-group-flush overflow-auto">
                {payload.rows.length ? payload.rows.map(r => <ProviderLinkItemShow link={link} item={r} handleRemove={handleRemove} handleChange={handleChange} key={r.id} />) : <li className={'list-group-item'}>
                    <i className={'bi bi-search'}></i> <span className="text-muted">Aucun article</span>
                </li>}
            </ul>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        </div>
        <ModalComponent title={"Rechercher un produit"} size={"modal-xl"} show={showSelector} onHide={() => setShowSelector(false)}>
            <ProviderLinkItemSelector handleSelect={product => {
                setSelected(product)
                setShowSelector(false)
                setShowAddModal(true)
            }} />
        </ModalComponent>
        {selected && <ProviderLinkItemAdd link={link} handleAdd={item => {
            setPayload(prev => ({count: prev.count + 1, rows: [item, ...prev.rows]}))
            setShowAddModal(false)
        }} selected={selected} showModal={showAddModal} setShowModal={setShowAddModal}/>}
    </div>

}

export default ProviderLinkItemList;