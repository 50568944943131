import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import * as api from "../../../../../adapters/corporate/provider/shop";
import * as models from "../../../../../models/shop";
import Loader from "../../../../../utils/loader";
import ProviderShopShowProductList from "../products";

const ProviderShopShow = ({tab}: {tab?: "cart"|"order"|"product"}) => {

    const {id} = useParams();
    const [shop, setShop] = useState<models.Shop>()
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (id){
            setLoading(true)
            api.show(Number(id))
                .then(r => setShop(r.data))
                .then(() => setLoading(false))
                .catch(e => console.log(e))
        }
    }, [id])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (!shop)
    {
        return <div className={'h-100 w-100'}>
            Shop non trouvé
        </div>
    }

    return <div className={'container-fluid'}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/corporate/provider/shop">Boutique</Link>
                </li>
                <li className="breadcrumb-item">
                    {shop.name}
                </li>
            </ol>
        </div>
        <ProviderShopShowProductList shop={shop} />
    </div>
}

export default ProviderShopShow;