import * as models from "../../../../models/quote";
import React, {useState} from "react";
import QuoteAddressBillingSelector from "./selector";

const QuoteAddressBilling = ({quote, setQuote}: {quote: models.Quote, setQuote: React.Dispatch<any>}) => {

    return <div className={"col-12 mb-3"}>
        <div className="row align-items-center">
            <div className="col">
                <p className={'mb-0 fw-semibold'}>
                    Addresse de facturation
                </p>
            </div>
        </div>
        <QuoteAddressBillingSelector quote={quote} setQuote={setQuote}/>
    </div>
}

export default QuoteAddressBilling;