import React, {useEffect, useState} from "react";
import * as api from '../../../../../adapters/corporate/supplier/feature';
import SearchInput from "../../../../../utils/searchInput";
import {Features} from "../../../../../models/feature";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";
import SupplierFeatureAdd from "../add";
import ModalComponent from "../../../../modal";
import ErrorCard from "../../../../error";

const SupplierFeatureList = () => {

    const [payload, setPayload] = useState<{ count: number, rows: Features }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number}>({query: "", offset: 0})
    const [error, setError] = useState(null)
    const [showAddModal, setShowAddModal] = useState(false)

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(resp => setPayload(resp.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    function deactivate(id: number){
        api.update(id, {active: false})
            .then(r => setPayload(p => ({count: p.count - 1, rows: [...p.rows.filter(item => item.id !== id)]})));
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher une donctionnalité..."} onChange={handleQueryChange} size={"default"} />
                </div>
                <div className="col-auto">
                    <button className={'btn btn-primary btn-xs rounded'}
                            onClick={() => setShowAddModal(true)}
                    >
                        <i className={'bi bi-plus-circle'}></i> Ajouter
                    </button>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        {error && <ErrorCard error={error} />}
        <div className="col-md-12 flex-grow-1">
            {!loading && <div className={'table-responsive'}>
                <table className={'table'}>
                    <thead>
                        <tr>
                            <th>
                                Description
                            </th>
                            <th>

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {payload.rows.map(item => <tr>
                            <td>
                                {item.description}
                            </td>
                            <td>
                                <button className="btn btn-xxs btn-light text-danger"
                                        onClick={() => deactivate(item.id)}>
                                    <i className={'bi bi-dash-circle'}></i> Archiver
                                </button>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>}
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        </div>
        <ModalComponent title={"Ajouter une fonctionnalité"} show={showAddModal} onHide={() => setShowAddModal(false)}>
            <SupplierFeatureAdd handleSuccess={feature => {
                setPayload(prev => ({count: prev.count + 1, rows: [feature, ...prev.rows]}))
                setShowAddModal(false)
            }} />
        </ModalComponent>
    </div>
}

export default SupplierFeatureList;