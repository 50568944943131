export const sectors = [
    {
        name: "Aéronautique & aérospatiale",
        id: 1
    },
    {
        name: "Agence & SSII",
        id: 2
    },
    {
        name: "Agroalimentaire",
        id: 3
    },
    {
        name: "Architecture & urbanisme",
        id: 4
    },
    {
        name: "Arts & artisanat",
        id: 5
    },
    {
        name: "Associatif et syndical",
        id: 6
    },
    {
        name: "Automobile",
        id: 7
    },
    {
        name: "Banque & assurances",
        id: 8
    },
    {
        name: "Biotechnologies",
        id: 9
    },
    {
        name: "BTP & construction",
        id: 10
    },
    {
        name: "Centres de recherche",
        id: 11
    },
    {
        name: "Chimie",
        id: 12
    },
    {
        name: "Cinéma & audiovisuel",
        id: 13
    },
    {
        name: "Commerce de détail",
        id: 14
    },
    {
        name: "Conseil & audit",
        id: 15
    },
    {
        name: "Culture",
        id: 16
    },
    {
        name: "Défense & armée",
        id: 17
    },
    {
        name: "Divertissements & loisirs",
        id: 18
    },
    {
        name: "E-commerce",
        id: 19
    },
    {
        name: "Edition",
        id: 20
    },
    {
        name: "Education & e-learning",
        id: 21
    },
    {
        name: "Edition de logiciels",
        id: 22
    },
    {
        name: "Energie",
        id: 23
    },
    {
        name: "Environnement",
        id: 24
    },
    {
        name: "Grande distribution",
        id: 25
    },
    {
        name: "High tech",
        id: 26
    },
    {
        name: "Hôtellerie",
        id: 27
    },
    {
        name: "Immobilier",
        id: 28
    },
    {
        name: "Import & export",
        id: 29
    },
    {
        name: "Ingénierie mécanique",
        id: 30
    },
    {
        name: "Industrie matières premières",
        id: 31
    },
    {
        name: "Industrie pharmaceutique",
        id: 32
    },
    {
        name: "Jeux vidéo & animation",
        id: 33
    },
    {
        name: "Luxe",
        id: 34
    },
    {
        name: "Mode & cosmétiques",
        id: 35
    },
    {
        name: "Nanotechnologies",
        id: 36
    },
    {
        name: "Internet des objets",
        id: 37
    },
    {
        name: "Presse & médias",
        id: 38
    },
    {
        name: "Réseaux sociaux",
        id: 39
    },
    {
        name: "Ressources humaines",
        id: 40
    },
    {
        name: "Restauration",
        id: 41
    },
    {
        name: "Santé & bien-être",
        id: 42
    },
    {
        name: "Secteur médical",
        id: 43
    },
    {
        name: "Secteur public & collectivités",
        id: 44
    },
    {
        name: "Sécurité civile",
        id: 45
    },
    {
        name: "Sport",
        id: 46
    },
    {
        name: "Télécommunications",
        id: 47
    },
    {
        name: "Transports",
        id: 48
    },
    {
        name: "Logistique & Supply Chain",
        id: 49
    },
    {
        name: "Voyage & tourisme",
        id: 50
    },
    {
        name: "Vins & Spiritueux",
        id: 51
    }
]