import React, {useState} from "react";
import SponsorshipFeature from "./feature";
import SponsorshipTracking from "./tracking/list";

const Sponsorship = () => {

    const [tab, setTab] = useState<'feature'|'tracking'>('feature')

    return <>
        <div className="row justify-content-center">
            <div className="col mb-3">
                <div className="mb-2 mb-lg-0 border-bottom-lg">
                    <nav className="nav nav-tabs nav-overflow fs-xs border-bottom border-bottom-lg-0">
                        <button onClick={() => setTab('feature')} className={"nav-link text-uppercase" + (tab === 'feature' ? " active " : "")}>
                            Fonctionnement
                        </button>
                        <button onClick={() => setTab('tracking')} className={"nav-link text-uppercase" + (tab === "tracking" ? " active " : "")}>
                            Suivi
                        </button>
                    </nav>
                </div>
            </div>
        </div>
        {tab === "feature" && <SponsorshipFeature />}
        {tab === "tracking" && <SponsorshipTracking />}
    </>
}

export default Sponsorship;