import encodeQueryData from "../../../../utils/encodeQueryData";
import {post, get} from "../../../xhr";

export function list(requestData: { query?: string, offset: number}){
    return get(`corporate/supplier/assignment${encodeQueryData(requestData)}`)
}

export function show(assignment: number){
    return get(`corporate/supplier/assignment/${assignment}`)
}

export function add(requestData: any){
    return post(`corporate/supplier/assignment`, requestData)
}

export function update(assignment: number, requestData: any){
    return post(`corporate/supplier/assignment/${assignment}`, requestData);
}

