import React from "react";

const ParcelStatusPlaceholder = ({status, displayCaret}: {status: string, displayCaret?: boolean}) => {

    switch (status){
        case "Pending":
            return <span className={'badge bg-light text-dark'}>
                    En attente
                </span>
        case "All":
            return <span className={'badge bg-light text-dark'}>
                    Tous les statuts
                </span>
        case "Confirmed":
            return <span className={'badge bg-info'}>
                    Confirmé {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Processing":
            return <span className={'badge bg-warning'}>
                    Traitement {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Shipped":
            return <span className={'badge bg-info'}>
                    Envoyé {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Delivered":
            return <span className={'badge bg-success'}>
                    Délivré {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "OnHold":
            return <span className={'badge bg-dark'}>
                    Bloqué {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Canceled":
            return <span className={'badge bg-danger'}>
                    <i className={'bi bi-x'}></i> Annulé {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
    }

    return <>Invalid status</>
}

export default ParcelStatusPlaceholder;