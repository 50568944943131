import React, {useEffect, useState} from "react";
import * as api from '../../../../../adapters/corporate/supplier/pack';
import SearchInput from "../../../../../utils/searchInput";
import Loader from "../../../../../utils/loader";
import {Packs} from "../../../../../models/pack";
import Pagination from "../../../../../utils/pagination";
import ErrorCard from "../../../../error";
import {Link, useNavigate} from "react-router-dom";
import {FetchParams, Payload} from "../../../../../models/global";
import ModalComponent from "../../../../modal";
import SupplierPackAdd from "../add";

const SupplierPackList = () => {

    const [payload, setPayload] = useState<Payload<Packs>>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<FetchParams>({query: "", offset: 0})
    const [error, setError] = useState(null)
    const [showAddModal, setShowAddModal] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        api.list({offset: fetchParams.offset, query: fetchParams.query})
            .then(resp => setPayload(resp.data))
            .catch(error => setError(error))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher un package..."} onChange={handleQueryChange} size={"default"} />
                </div>
                <div className="col-auto">
                    <button onClick={() => setShowAddModal(true)} className={'btn btn btn-xs rounded btn-primary'}>
                        <i className={'bi bi-plus-circle'}></i> Ajouter
                    </button>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        {error && <ErrorCard error={error} />}
        <div className="col-md-12 flex-grow-1">
            {!loading && <ul className={'list-group'}>
                {payload.rows.map(pack => <li className={"list-group-item"}>
                    <div className="row align-items-center">
                        <div className="col">
                            {pack.name} | {pack.items.length} articles | {pack.activated ? <><i className={"bi bi-check-circle text-success"}></i>  Activé</> : <><i className={"bi bi-x-circle text-danger"}></i> Désactivé</>}
                        </div>
                        <div className="col-auto">
                            <Link to={`/corporate/supplier/pack/${pack.id}`} className={'btn btn-light'}>
                                Afficher <i className={'bi bi-arrow-right'}></i>
                            </Link>
                        </div>
                    </div>

                </li>)}
            </ul>}
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        </div>
        <ModalComponent title={'Ajouter un package'} show={showAddModal} onHide={() => setShowAddModal(false)}>
            <SupplierPackAdd handleSuccess={pack => navigate(`/corporate/supplier/pack/${pack.id}`)} />
        </ModalComponent>
    </div>
}

export default SupplierPackList;