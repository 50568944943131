import React, {useEffect, useRef, useState} from "react";
import * as api from "../../../../../adapters/corporate/common/chat";
import SearchInput from "../../../../../utils/searchInput";
import Loader from "../../../../../utils/loader";
import ChatAdd from "../add";
import * as models from "../../../../../models/chat";
import {useNavigate, useParams} from "react-router-dom";
import ChatListItem from "./item";
import ModalComponent from "../../../../modal";
import {socket} from "../../../../../socket";
import ChatSearch from "../search";
import ErrorCard from "../../../../error";


const ChatList = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [payload, setPayload] = useState<{ count: number, rows: models.Chats }>({ count: 0, rows: [] });
    const [offset, setOffset] = useState<number>(0);
    const [showGroupAddModal, setShowGroupAddModal] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const navigate = useNavigate();
    const chatContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setLoading(true);
        api.list({ offset: offset })
            .then(r => setPayload(prev => ({ count: r.data.count, rows: [...prev.rows, ...r.data.rows] })))
            .catch(e => setError(e))
            .finally(() => setLoading(false));
    }, [offset]);

    function handleChatUpdate(chat: models.Chat) {
        setPayload(prev => ({
            count: prev.count,
            rows: [chat, ...prev.rows.filter(p => p.id !== chat.id)].sort((a, b) => new Date(b.latestMessage?.createdAt || b.createdAt).getTime() - new Date(a.latestMessage?.createdAt || a.createdAt).getTime())
        }));
    }

    function handleChatCreate(chat: models.Chat) {
        setPayload(prev => ({ count: prev.count + 1, rows: [chat, ...prev.rows] }));
    }

    useEffect(() => {
        socket.connect();
        socket.on(`chat.updated`, handleChatUpdate);
        socket.on(`chat.created`, handleChatCreate);

        return () => {
            socket.disconnect();
            socket.off(`chat.updated`, handleChatUpdate);
            socket.off(`chat.created`, handleChatCreate);
        };
    }, []);

    let timer: NodeJS.Timer;
    function handleScroll() {
        if (chatContainer.current) {
            const scrollTop = chatContainer.current.scrollTop;
            const scrollHeight = chatContainer.current.scrollHeight;
            const innerHeight = chatContainer.current.clientHeight;

            const scrolledBottom = scrollTop + innerHeight + 10 >= scrollHeight;

            if (scrolledBottom && payload.rows.length < payload.count) {
                clearTimeout(timer);
                timer = setTimeout(() => setOffset(p => p + 1), 500);
            }
        }
    }

    function checkAndFetchMoreData() {
        if (chatContainer.current) {
            const scrollHeight = chatContainer.current.scrollHeight;
            const innerHeight = chatContainer.current.clientHeight;

            if (scrollHeight <= innerHeight && payload.rows.length < payload.count) {
                setOffset(p => p + 1);
            }
        }
    }

    useEffect(() => {
        checkAndFetchMoreData();
    }, [payload]);

    if (showSearch) {
        return <ChatSearch setShowSearch={setShowSearch} />;
    }

    return (
        <div className={'row h-100'}>
            <div className="col-12 h-100 overflow-auto" ref={chatContainer} onScroll={handleScroll}>
                <div className="col-12 mb-3 position-sticky top-0 bg-white" style={{ zIndex: 10 }}>
                    <div className="row align-items-center">
                        <div className="col">
                            <SearchInput onFocus={() => setShowSearch(true)} size={"sm"} onChange={() => console.log('')} placeholder={"Rechercher ou démarrer une discussion..."} />
                        </div>
                        <div className="col-auto">
                            <div className="dropdown">
                                <button className={'btn btn-light btn-xs btn-circle'} type="button"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className={'bi bi-plus-circle'}></i>
                                </button>
                                <ul className="dropdown-menu">
                                    <li className="dropdown-item cursor-pointer">
                                        <i className={"bi bi-person"}></i> Nouvelle discussion
                                    </li>
                                    <li className="dropdown-item cursor-pointer" onClick={() => setShowGroupAddModal(true)}>
                                        <i className={"bi bi-people"}></i> Nouveau groupe de discussion
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className={"list-group list-group-flush"} style={{ zIndex: 1 }}>
                    {payload.rows.map(r => <ChatListItem handleClick={() => navigate(`/corporate/chat/${r.id}`)} chat={r} key={r.id} />)}
                </ul>
                {loading && <div className={'col-12 py-3 text-center'}><Loader /></div>}
                {error && <ErrorCard error={error} />}
            </div>

            <ModalComponent title={"Ajouter un groupe"} size={"modal-lg"} show={showGroupAddModal} onHide={() => setShowGroupAddModal(false)}>
                <ChatAdd onSuccess={chat => {
                    setPayload(prev => ({ count: prev.count + 1, rows: [chat, ...prev.rows] }));
                    setShowGroupAddModal(false);
                }} />
            </ModalComponent>
        </div>
    );
};


export default ChatList;