import React, { useRef, useState } from 'react';
import * as api from '../../../../adapters/quote';
import { Quote } from '../../../../models/quote';
import Loader from '../../../../utils/loader';

interface QuoteSignatureVerifyProps {
    quote: Quote;
    setQuote: React.Dispatch<React.SetStateAction<Quote>>;
    verificationEmail: string;
}

const QuoteSignatureVerify = ({ quote, setQuote, verificationEmail }: QuoteSignatureVerifyProps) => {
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<any>(null)
    const [code, setCode] = useState(new Array(6).fill(''));
    const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const newCode = [...code];
        newCode[index] = e.target.value;
        setCode(newCode);

        // Move focus to the next input field if the current field is filled
        if (e.target.value !== '' && index < 5) {
            inputsRef.current[index + 1]?.focus();
        }

        // Trigger verification if the code is complete
        if (newCode.join('').length === 6) {
            await verifyCode(newCode.join(''));
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        // Handle backspace key
        if (e.key === 'Backspace' && code[index] === '' && index > 0) {
            inputsRef.current[index - 1]?.focus();
        }
    };

    const verifyCode = async (code: string) => {
        try {
            setError(null);
            setSubmitting(true);
            api.verifySignature(quote.uuid, { verificationCode: Number(code) })
                .then((r) => setQuote((prev) => ({ ...prev, signature: r.data })))
                .catch((error) => setError(error))
                .finally(() => setSubmitting(false));
        } catch (error) {
            console.error('Error verifying code:', error);
            alert('An error occurred during verification.');
        }
    };

    return (
        <div className='card border border-1'>
            <div className='card-header'>
                <h6>Confirmez votre signature</h6>
            </div>
            <div className='card-body'>
                <div className='col-12 mb-3 text-center'>
                    <p>
                        Un email contenant un code de verification a été envoyé à l'email{' '}
                        <span className='fw-semibold'>{verificationEmail}</span>
                    </p>
                    <p>Veuillez saisir le code reçu ci-dessous pour confirmer la signature.</p>
                </div>
                {error && (
                    <div className='col-12 mb-3 text-center'>
                              <span className='text-danger'>
                                    {error.response.data.error}
                              </span>
                    </div>
                )}
                {submitting && (
                    <div className='col-12 mb-3 text-center'>
                              <span className='text-muted'>
                                <Loader size={'sm'} /> Verification en cours...
                              </span>
                    </div>
                )}
                <div className='col-12 mb-3'>
                    <div className='input-group'>
                        {code.map((digit, index) => (
                            <input
                                key={index}
                                type='text'
                                maxLength={1}
                                className='form-control'
                                value={digit}
                                disabled={submitting}
                                onChange={(e) => handleChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                ref={(el) => (inputsRef.current[index] = el)}
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    fontSize: '24px',
                                    textAlign: 'center',
                                    marginRight: '10px',
                                }}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuoteSignatureVerify;
