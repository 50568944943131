import {Product, ProductInventory} from "../../../../../../models/product";
import * as api from "../../../../../../adapters/corporate/supplier/product/inventory";
import {useFormik} from "formik";
import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import FormError from "../../../../../../utils/formError";
import Loader from "../../../../../../utils/loader";
import ShippingAddressSelect from "../../../../../../utils/shippingAddressSelect";
import * as models from "../../../../../../models/product";

const InventoryAdd = ({product, handleSuccess}: {product: Product & models.SellableProduct, handleSuccess: (inventory: ProductInventory) => void}) => {

    const [initialValues, setInitialValues] = useState<any>({});
    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({}));

    useEffect(() => {

        let values: any = {
            quantity: 0,
            address_id: null,
        }

        let fields: any = {
            quantity: Yup.number().required(),
            address_id: Yup.number().required()
        };

        const declinations = product.declinations.filter(d => d.stockLinked);

        if (declinations.length){
            for (const declination of declinations) {
                const choices = declination.choices;
                values[`declination_${declination.id}`] = choices.length > 0 ? choices[0].id : undefined;
                fields[`declination_${declination.id}`] = Yup.number().required();
            }
        }

        setInitialValues(values);
        setValidationSchema(Yup.object().shape({...fields}))
    }, [])

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit:(values, formikHelpers) => {

            const declinations = product.declinations.filter(d => d.stockLinked);

            const selectedDeclinationChoices = []
            for (const declination of declinations) {
                selectedDeclinationChoices.push(values[`declination_${declination.id}`]);
            }

            api.upsert(product.id, {declinationChoices: selectedDeclinationChoices, quantity: values.quantity, address_id: values.address_id})
                .then(r => handleSuccess(r.data))
                .then(() => formik.setSubmitting(false))
        }
    })

    return <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasInventoryAdd"
                       aria-labelledby="offcanvasInventoryAddLabel">
        <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasInventoryAddLabel"><i className={'bi bi-plus-circle'}></i> Ajouter un stock</h5>
            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvasInventoryAdd" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <form onSubmit={formik.handleSubmit}>
                {product.declinations.map((d) => <div key={d.id} className={'col-12 mb-3'}>
                    <p className={'fw-semibold'}>
                        {d.name}
                    </p>
                    <FormError form={formik} field={`declination_${d.id}`} />
                    <div className="col-12 mb-3">
                        {d.choices.map((c, index) => <div key={c.id} className="form-check">
                            <input name={`declination_${d.id}`}
                                   disabled={formik.isSubmitting}
                                   onChange={formik.handleChange}
                                   onBlur={formik.handleBlur}
                                   defaultChecked={index === 0}
                                   className="form-check-input"
                                   type="radio"
                                   value={c.id}
                                   id={`declination_${d.id}_choices_${c.id}`}
                            />
                            <label className="form-check-label" htmlFor={`declination_${d.id}_choices_${c.id}`}>
                                {c.value}
                            </label>
                        </div>)}
                    </div>
                </div>)}
                <div className="col-12 mb-3">
                    <ShippingAddressSelect handleChange={a => formik.setFieldValue('address_id', a)} shipping={product.shipping} required={true} />
                    <FormError form={formik} field={"address_id"} />
                </div>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input disabled={formik.isSubmitting}
                               type="number"
                               step={1}
                               className="form-control"
                               id={'quantity'}
                               name={'quantity'}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               defaultValue={0}
                        />
                        <label htmlFor="name">
                            Quantité
                        </label>
                    </div>
                    <FormError form={formik} field={'quantity'} />
                </div>
                <button disabled={formik.isSubmitting} type={'submit'} className={"btn btn-outline-dark w-100"}>
                    {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-plus-circle'}></i> Ajouter</>}
                </button>
            </form>
        </div>
    </div>
}

export default InventoryAdd;