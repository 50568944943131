import {Product} from "../../../../../models/product";
import SupplierPriceShow from "../../price/show";
import React, {useState} from "react";
import ModalComponent from "../../../../modal";
import SupplierPriceList from "../../price/list";
import {Price} from "../../../../../models/price";
import * as api from "../../../../../adapters/corporate/supplier/product/price";
import OffcanvasComponent from "../../../../offcanvas";
import SupplierProductPriceAdd from "./add";
import * as models from "../../../../../models/product";

const SupplierProductPrice = ({product, setProduct}: {product: Product & models.SellableProduct, setProduct: React.Dispatch<any>}) => {

    const [showListModal, setShowListModal] = useState(false)
    const [showAddOffcanvas, setShowAddOffcanvas] = useState(false)

    function setDefault(price: Price){
        api._default(product.id, price.id)
            .then(r => setProduct((prev: Product) => ({...prev, price: r.data})))
            .catch(e => console.log(e))
    }

    return <div className={'col-12 mb-3'}>
        <div className="card border border-1">
            <div className="card-header">
                <div className="row">
                    <div className="col">
                        <h6>Tarif</h6>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-xxs text-decoration-underline" onClick={() => setShowListModal(true)}>
                            <i className={'bi bi-list-ul'}></i> Autres tarifs
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <SupplierPriceShow price={product.price} />
            </div>
        </div>
        <ModalComponent
            size={"modal-lg"}
            title={"Liste des tarifs"}
            show={showListModal}
            onHide={() => setShowListModal(false)}
            footerContent={<button className={'btn btn-primary'} onClick={() => {
                setShowListModal(false)
                setShowAddOffcanvas(true)
            }}>
                <i className={"bi bi-plus-circle"}></i> Ajouter un tarif
            </button>}
        >
            <SupplierPriceList setDefault={setDefault} product={product} type={"list"} />
        </ModalComponent>
        <OffcanvasComponent
            title={"Ajouter un tarif"}
            show={showAddOffcanvas}
            onHide={() => setShowAddOffcanvas(false)}
        >
            <SupplierProductPriceAdd product={product} handleSuccess={price => {
                setProduct((prev: Product) => ({...prev, price: price}))
                setShowAddOffcanvas(false)
            }} />
        </OffcanvasComponent>
    </div>
}

export default SupplierProductPrice;