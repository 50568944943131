import * as models from "../../../../../../../../models/subscription";
import React, {useState} from "react";
import ModalComponent from "../../../../../../../modal";
import ProviderSubscriptionShowOccurrenceShow from "../../show";
import convertToDecimal from "../../../../../../../../utils/currencyConverter";

const ProviderSubscriptionShowOccurrenceListItem = ({subscription, occurrence}: {subscription: models.Subscription, occurrence: models.SubscriptionOccurrence}) => {

    const [showModal, setShowModal] = useState(false)

    return <li className={'list-group-item'}>
        <div className="row align-items-center">
            <div className="col">
                <p className={'mb-0'}>
                    {new Date(occurrence.dueDate).toLocaleDateString()} | {convertToDecimal(occurrence.priceData.amount_including_tax, occurrence.priceData.currency)}
                </p>
            </div>
            <div className="col-auto">
                <button className={'btn btn-light btn-xxs'} onClick={() => setShowModal(true)}><i className={'bi bi-eye'}></i> Afficher le détail</button>
            </div>
        </div>
        <ModalComponent
            size={'modal-xl'}
            title={`Occurrence du ${new Date(occurrence.dueDate).toLocaleDateString()}`}
            show={showModal}
            onHide={() => setShowModal(false)}
        >
            {showModal && <ProviderSubscriptionShowOccurrenceShow subscription={subscription} occurrence={occurrence} key={occurrence.id} />}
        </ModalComponent>
    </li>
}

export default ProviderSubscriptionShowOccurrenceListItem;