import {useState} from "react";
import ProviderFeeList from "./list";

const ProviderFee = () => {

    const [status, setStatus] = useState<'All'|'Pending'|'Accepted'|'Refused'|'Paid'>('All')
    return <>
        <div className={'row justify-content-between'}>
            <div className="col-auto my-3">
                <ul className="nav nav-pills mx-auto">
                    <li className="nav-item">
                        <button className={`nav-link ${status === "All" ? 'active' : ''}`} onClick={() => setStatus('All')}>
                            Tout les statuts
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link ${status === "Pending" ? 'active' : ''}`} onClick={() => setStatus('Pending')}>
                            En attente
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link ${status === "Accepted" ? 'active' : ''}`} onClick={() => setStatus('Accepted')}>
                            Accepté
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link ${status === "Refused" ? 'active' : ''}`} onClick={() => setStatus('Refused')}>
                            Refusé
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link ${status === "Paid" ? 'active' : ''}`} onClick={() => setStatus('Paid')}>
                            Payé
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <div className="col-12">
            <ProviderFeeList status={status} />
        </div>
    </>
}

export default ProviderFee;