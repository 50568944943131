import {useEffect, useState} from "react";
import * as api from "../../../../../adapters/corporate/supplier/fee";
import {Currency} from "../../../../../models/currency";
import {Fees} from "../../../../../models/fee";
import Loader from "../../../../../utils/loader";
import {PaymentMethod} from "../../../../../models/paymentMethod";
import CommonPaymentMethodSelect from "../../../../common/paymentMethod/select";
import FeePayment from "./payment";
import convertToDecimal from "../../../../../utils/currencyConverter";

const SupplierFeePay = ({fees, handlePaymentSucceed}: {fees: Fees, handlePaymentSucceed: (fees: Fees) => void}) => {

    const [loading, setLoading] = useState(true);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
    const currency = fees[0].currency;

    const [payment, setPayment] = useState<{
        amount_excluding_tax: number;
        amount_including_tax: number;
        tax_amount: number;
        currency: Currency;
        fees: Fees
    }>()

    useEffect(() => {
        setLoading(true)
        api.paymentData({currency_id: currency.id, ids: fees.map(c => c.id)})
            .then(r => setPayment(r.data))
            .finally(() => setLoading(false))
    }, [currency.id, fees])

    return <>
        <div className={"col-12 mb-5"}>
            <h6>
                Récapitulatif du paiement
            </h6>
            {loading ? <Loader /> : <>
                {payment && <table className={"table"}>
                    <tbody>
                        <tr>
                            <th>
                                Montant HT
                            </th>
                            <td>
                                {convertToDecimal(payment.amount_excluding_tax, payment.currency)}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Taxes
                            </th>
                            <td>
                                {convertToDecimal(payment.tax_amount, payment.currency)}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Montant TTC
                            </th>
                            <td>
                                {convertToDecimal(payment.amount_including_tax, payment.currency)}
                            </td>
                        </tr>
                    </tbody>
                </table>}
                </>}
            <div className="col-12 mb-3">
                <h6>Moyen de paiement</h6>
                <CommonPaymentMethodSelect handleSelect={pm => setPaymentMethod(pm)} selected={paymentMethod} />
            </div>
            <FeePayment handlePaymentSucceed={handlePaymentSucceed} fees={payment?.fees} currency={currency} paymentMethod={paymentMethod} />
        </div>
    </>
}

export default SupplierFeePay;