import {get, post} from "../../xhr";
import encodeQueryData from "../../../utils/encodeQueryData";

export function list(requestData: {query: string, offset: number, status: string}){
    return get(`/customer/quote${encodeQueryData(requestData)}`);
}

export function show(id: number){
    return get(`/customer/quote/${id}`);
}

export function update(id: number, requestData: {status: "Completed"}){
    return post(`/customer/quote/${id}`, requestData);
}

export function summary(id: number){
    return get(`/customer/quote/${id}/summary`);
}