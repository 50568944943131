import {get} from "../../../../xhr";
import encodeQueryData from "../../../../../utils/encodeQueryData";

export function list(requestData: {quote_id: number}){
    return get(`/corporate/provider/quote-row${encodeQueryData(requestData)}`);
}

export function show(id: number){
    return get(`/corporate/provider/quote-row/${id}`);
}
