import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import * as api from "../../../../../adapters/corporate/supplier/product";
import * as models from "../../../../../models/product";
import Loader from "../../../../../utils/loader";
import SupplierProductDetailShow from "../detail";
import SupplierProductFileShow from "../file/show";
import SupplierProductDimensionShow from "../dimension/show";
import SupplierProductDeclinationShow from "../declination/show";
import SupplierProductInventoryList from "../inventory/index";
import SupplierProductTaxRateShow from "../taxRate/show";
import SupplierProductPublish from "../publish";
import SupplierProductPrice from "../price";
import ErrorCard from "../../../../error";
import SupplierProductDescription from "../description";
import SupplierProductFeatureShow from "../feature/show";
import SupplierProductSupportList from "../support/list";

const SupplierProductShow = ({tab}: {tab?: "gallery"|"declination"|"support"|"inventory"|"description"}) => {

    const { id } = useParams();

    const [product, setProduct] = useState<models.Product>()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        if (id){
            setLoading(true)
            api.show(Number(id))
                .then(resp => setProduct(resp.data))
                .catch(e => setError(e))
                .finally(() => setLoading(false))
        }
    }, [id])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if(error){
        return <ErrorCard error={error} />
    }

    if (!product){
        return <div className={'h-100 w-100'}>
            Produit non trouvé
        </div>
    }

    return <div className={'container-fluid h-100'}>
        <div className="d-flex flex-column h-100">
            <div className="col-12 mb-3 flex-grow-0">
                <ol className="breadcrumb mb-3 fs-xs">
                    <li className="breadcrumb-item">
                        <Link to="/corporate/supplier/catalog">Produits</Link>
                    </li>
                    <li className="breadcrumb-item">
                        {product.name}
                    </li>
                </ol>
            </div>
            <SupplierProductPublish product={product} setProduct={setProduct} />
            <div className="col-12 mb-3">
                <div className="mb-2 mb-lg-0 border-bottom-lg">
                    <nav className="nav nav-tabs nav-overflow fs-xs border-bottom border-bottom-lg-0 w-100 text-nowrap">
                        <Link to={`/corporate/supplier/product/${id}`} className={"nav-link text-uppercase" + (!tab ? " active " : "")}>
                           <i className={'bi bi-information-circle'}></i> <span>Informations</span>
                        </Link>
                        <Link to={`/corporate/supplier/product/${id}/description`} className={"nav-link text-uppercase" + (tab === 'description' ? " active " : "")}>
                            <i className={'bi bi-blockquote-left'}></i> <span>Description</span>
                        </Link>
                        <Link to={`/corporate/supplier/product/${id}/support`} className={"nav-link text-uppercase" + (tab === 'support' ? " active " : "")}>
                            <i className={'bi bi-blockquote-left'}></i> <span>Supports</span>
                        </Link>
                        {product.sellable && <>
                            <Link to={`/corporate/supplier/product/${id}/declination`} className={"nav-link text-uppercase" + (tab === "declination" ? " active " : "")}>
                                <i className={'bi bi-list-ol'}></i> <span>Déclinaisons <span className="badge bg-primary">{product.declinations?.length || 0}</span></span>
                            </Link>
                            {product.shippable && <>
                                <Link to={`/corporate/supplier/product/${id}/inventory`} className={"nav-link text-uppercase" + (tab === "inventory" ? " active " : "")}>
                                    <i className={"bi bi-box"}></i> <span>Stock</span>
                                </Link>
                            </>}
                        </>}
                    </nav>
                </div>
            </div>
            <div className="row flex-grow-1">
                <div className="col h-100 overflow-auto">
                    {!tab && <>
                        <SupplierProductDetailShow key={product.id} product={product} setProduct={setProduct} />
                        <SupplierProductFileShow key={product.id} product={product} setProduct={setProduct} />
                        {product.sellable && <>
                            <SupplierProductPrice product={product} setProduct={setProduct} />
                            <SupplierProductFeatureShow key={product.id} product={product} setProduct={setProduct} />
                            <SupplierProductTaxRateShow key={product.id} product={product} setProduct={setProduct} />
                            {product.shippable && product.storable && <SupplierProductDimensionShow key={product.id} product={product} setProduct={setProduct} />}
                        </>}
                    </>}
                    {tab === "description" && <SupplierProductDescription key={product.id} product={product} setProduct={setProduct} />}
                    {tab === "support" && <SupplierProductSupportList key={product.id} product={product}/>}
                    {product.sellable && <>
                        {tab === "declination" && <SupplierProductDeclinationShow key={product.id} product={product} setProduct={setProduct} />}
                        {product.shippable && tab === "inventory" && <SupplierProductInventoryList product={product} key={product.id} />}
                    </>}
                </div>
            </div>
        </div>
    </div>
}

export default SupplierProductShow;