import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import * as models from "../../models/auth"
import * as organizationModels from "../../models/organization"
import {User} from "../../models/user";
import {AxiosError} from "axios";
import {Account, CorporateAccount, CustomerAccount, CustomerAddress} from "../../models/account";

interface AuthState {
    token: string|null,
    user: User|null,
    loading: boolean,
    errorMessage: string|null
}

const initialState = {
    token: getToken(),
    user: getUser(),
    loading: false,
    errorMessage: ''
} as AuthState

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state) {
            state.user = null;
            state.token = null;
            state.loading = true;
            state.errorMessage = '';
        },
        loginSuccess(state: AuthState, action: PayloadAction<{token: string, user: User}>) {
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.loading = false;
            state.errorMessage = '';
            saveAuth(action.payload)
        },
        refreshAuth(state: AuthState, action: PayloadAction<User>) {
            state.user = action.payload;
            updateUser(action.payload)
        },
        loginFailure(state: AuthState, action: PayloadAction<AxiosError>) {
            state.user = null;
            state.token = null;
            state.loading = false;
            //@ts-ignore
            state.errorMessage = action.payload.response?.data.error;
        },
        register(state) {
            state.user = null;
            state.token = null;
            state.loading = true;
            state.errorMessage = '';
        },
        registerSuccess(state: AuthState, action: PayloadAction<{token: string, user: User}>) {
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.loading = false;
            state.errorMessage = '';
            saveAuth(action.payload)
        },
        registerFailure(state: AuthState, action: PayloadAction<AxiosError>) {
            state.user = null;
            state.token = null;
            state.loading = false;
            //@ts-ignore
            state.errorMessage = action.payload.response?.data.error;
        },
        logout(state: AuthState) {
            state.user = null;
            state.token = null;
            state.loading = false;
            state.errorMessage = "";
            clearAuth();
        },
        activate(state) {
            state.loading = true;
        },
        activateSuccess(state: AuthState) {
            state.user!.activated = true;
            state.loading = false;
            state.errorMessage = '';
            updateUser(state.user!)
        },
        activateFailure(state: AuthState, action: PayloadAction<AxiosError>) {
            state.loading = false;
            //@ts-ignore
            state.errorMessage = action.payload.response?.data.error;
        },
        complete(state: AuthState, action: PayloadAction<{user: User}>) {
            state.user = action.payload.user;
            updateUser(action.payload.user)
        },
        editAccountOrganization(state: AuthState, action: PayloadAction<organizationModels.Organization>){
            if (state.user){
                (state.user.activeAccount as CorporateAccount).organization = {...(state.user.activeAccount as CorporateAccount).organization, ...action.payload};
                updateUser(state.user)
            }
        },
        editAccountOrganizationStatus(state: AuthState, action: PayloadAction<"Pending"|"Completed"|"Verified"|"Blocked">){
            if (state.user){
                (state.user.activeAccount as CorporateAccount).organization.status = action.payload;
                updateUser(state.user)
            }
        },
        editAccount(state: AuthState, action: PayloadAction<Account>){
            if (state.user){
                state.user.activeAccount = {...state.user.activeAccount,
                    firstname: action.payload.firstname,
                    lastname: action.payload.lastname,
                    tel: action.payload.tel,
                    countryCode: action.payload.countryCode
                };
                updateUser(state.user)
            }
        },
        setAccount(state: AuthState, action: PayloadAction<Account>){
            if (state.user){
                state.user.activeAccount = action.payload;
                updateUser(state.user)
            }
        },
        upsertAccountOrganizationAddress(state: AuthState, action: PayloadAction<organizationModels.OrganizationAddress>){
            if (state.user){
                (state.user.activeAccount as CorporateAccount).organization.billing = action.payload;
                updateUser(state.user)
            }
        },
        upsertAccountOrganizationInformation(state: AuthState, action: PayloadAction<organizationModels.OrganizationInformation>){
            if (state.user){
                (state.user.activeAccount as CorporateAccount).organization.information = action.payload;
                updateUser(state.user)
            }
        },
        upsertAccountOrganizationBio(state: AuthState, action: PayloadAction<organizationModels.OrganizationBio>){
            if (state.user){
                (state.user.activeAccount as CorporateAccount).organization.bio = action.payload;
                updateUser(state.user)
            }
        },
        setActiveCustomer(state: AuthState, action: PayloadAction<CustomerAccount>){
            state.user!.activeAccount = action.payload;
        },
        addActiveCustomer(state: AuthState, action: PayloadAction<CustomerAccount>){
            state.user!.activeAccount = action.payload;
            updateUser(state.user!)
        },
        addCustomerAddress(state: AuthState, action: PayloadAction<CustomerAddress>){
            (state.user!.activeAccount as CustomerAccount).addresses.push(action.payload);
            updateUser(state.user!)
        },
    }
})

function getUser(){

    let auth = localStorage.getItem("auth");

    if (auth){
        return JSON.parse(auth).user
    }

    return null;
}

export function getToken(){

    let auth = localStorage.getItem("auth");

    if (auth){
        return JSON.parse(auth).token;
    }

    return null;
}

function saveAuth(auth: models.Auth){
    localStorage.setItem('auth', JSON.stringify(auth))
}

function updateUser(user: User){
    if (getToken()){
        localStorage.setItem('auth', JSON.stringify({
            token: getToken(),
            user: user
        }))
    }
}

function clearAuth(){
    localStorage.removeItem('auth')
}

export default authSlice