import {getCoverUrl} from "../../../../../../utils/image";
import convertToDecimal from "../../../../../../utils/currencyConverter";
import React from "react";
import {Link, LinkItem} from "../../../../../../models/link";
import QuantityInput from "./quantity";
import * as api from "../../../../../../adapters/corporate/provider/link/item"

const ProviderLinkItemShow = ({link, item, handleRemove, handleChange}: {link: Link, item: LinkItem, handleRemove: (item: LinkItem) => void, handleChange: (item: LinkItem) => void}) => {

    function handleEditableChange(checked: boolean){
        api.update(link.id, item.id, {editable: checked})
            .then(r => handleChange(r.data))
    }

    function handleQuantityChange(qt: number){
        handleChange({...item, quantity: qt})
    }

    return <li className={'list-group-item'}>
        <div className="row align-items-center">
            <div className="col-md-4">
                <img className="img-fluid" src={getCoverUrl(item.product)} alt="..."/>
            </div>
            <div className="col-md-8">
                <p className="fs-sm fw-bold mb-6">
                    <span className="text-body">
                        {item.product.name}
                    </span>
                    <br />
                    <span className="text-muted">{convertToDecimal(item.product.price.amount, item.product.price.currency)}</span>
                </p>
                <div className="row align-items-center">
                    <div className="col-12 col-md-auto">
                        <div className="col-12 mb-3">
                            <div className="form-check form-switch">
                                <input checked={item.editable} onChange={e => handleEditableChange(e.target.checked)} className="form-check-input" type="checkbox" role="switch" id={`editable_${item.id}`} />
                                <label className="form-check-label" htmlFor={`editable_${item.id}`}>
                                    Le client peut ajuster la quantité ou retirer ce produit
                                </label>
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <QuantityInput item={item} key={item.id} link={link} handleQuantityChange={qt => handleQuantityChange(qt)} />
                        </div>
                    </div>
                    <div className="col">
                        <button className="btn btn-xxs fs-xs text-gray-400 ms-auto" onClick={() => handleRemove(item)}>
                            <i className="bi bi-x"></i> Retirer
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </li>
}

export default ProviderLinkItemShow;