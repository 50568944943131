import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Affiliation} from "../../../../../models/affiliation";
import * as api from "../../../../../adapters/corporate/supplier/affiliation";
import Loader from "../../../../../utils/loader";
import SupplierAffiliationShowInformation from "./information";
import SupplierAffiliationShowSettings from "./settings";
import SupplierAffiliationShowStatistics from "./statistics";
import ErrorCard from "../../../../error";
import SupplierAffiliationRules from "./rules";
import SupplierAffiliationTracking from "./tracking";
import SupplierAffiliationUpdate from "../update";
import OffcanvasComponent from "../../../../offcanvas";
import SupplierAffiliationSupportList from "./support/list";

const SupplierAffiliationShow = ({tab}: {tab?: "support"|"rules"|"tracking"|"statistics"|"settings"}) => {

    const params = useParams();
    const [affiliation, setAffiliation] = useState<Affiliation>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [showUpdateOffcanvas, setShowUpdateOffcanvas] = useState(false)

    useEffect(() => {
        setLoading(true)
        api.show(Number(params.id))
            .then(r => setAffiliation(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [params.id])

    if (loading){
        return <div className={'container-fluid py-10 text-center'}>
            <Loader />
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!affiliation){
        return <div className={'container-fluid py-10 text-center'}>
            <span className={'display-6'}>
                <i className={"bi bi-search"}></i> Affiliation non trouvé
            </span>
        </div>
    }

    return <div className={'container-fluid'}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row">
                <div className="col">
                    <ol className="breadcrumb mb-3 fs-xs">
                        <li className="breadcrumb-item">
                            <Link to="/corporate/supplier/catalog/affiliation">Affiliation</Link>
                        </li>
                        <li className="breadcrumb-item">
                            {affiliation.name}
                        </li>
                    </ol>
                </div>
                <div className="col-auto">
                    <button className="btn btn-xxs btn-light" onClick={() => setShowUpdateOffcanvas(true)}>
                        <i className={"bi bi-pencil text-info"}></i> Mettre à jour
                    </button>
                </div>
            </div>

        </div>
        <div className="row justify-content-center">
            <div className="col">
                <div className="mb-2 mb-lg-0 border-bottom-lg">
                    <nav className="nav nav-tabs nav-overflow fs-xs border-bottom border-bottom-lg-0">
                        <button className={"nav-link text-uppercase" + (!tab ? " active " : "")} onClick={() => navigate(`/corporate/supplier/affiliation/${affiliation.id}`)}>
                            Informations
                        </button>
                        <button className={"nav-link text-uppercase" + (tab === 'support' ? " active " : "")} onClick={() => navigate(`/corporate/supplier/affiliation/${affiliation.id}/support`)}>
                            Support
                        </button>
                        <button className={"nav-link text-uppercase" + (tab === "rules" ? " active " : "")} onClick={() => navigate(`/corporate/supplier/affiliation/${affiliation.id}/rules`)}>
                            Règles
                        </button>
                        <button className={"nav-link text-uppercase" + (tab === "tracking" ? " active " : "")} onClick={() => navigate(`/corporate/supplier/affiliation/${affiliation.id}/tracking`)}>
                            Tracking
                        </button>
                        <button className={"nav-link text-uppercase" + (tab === "statistics" ? " active " : "")} onClick={() => navigate(`/corporate/supplier/affiliation/${affiliation.id}/statistics`)}>
                            Statistiques
                        </button>
                        <button className={"nav-link text-uppercase" + (tab === "settings" ? " active " : "")} onClick={() => navigate(`/corporate/supplier/affiliation/${affiliation.id}/settings`)}>
                            Intégration
                        </button>
                    </nav>
                </div>
            </div>
        </div>
        {!tab && <SupplierAffiliationShowInformation affiliation={affiliation} setAffiliation={setAffiliation} />}
        {tab === "support" && <SupplierAffiliationSupportList affiliation={affiliation} />}
        {tab === "rules" && <SupplierAffiliationRules affiliation={affiliation} />}
        {tab === "tracking" && <SupplierAffiliationTracking affiliation={affiliation} />}
        {tab === "settings" && <SupplierAffiliationShowSettings affiliation={affiliation} />}
        {tab === "statistics" && <SupplierAffiliationShowStatistics affiliation={affiliation} />}
        <OffcanvasComponent title={`Mettre à jour ${affiliation.name}`} show={showUpdateOffcanvas} onHide={() => setShowUpdateOffcanvas(false)}>
            <SupplierAffiliationUpdate affiliation={affiliation} onSuccess={a => {
                setAffiliation(a)
                setShowUpdateOffcanvas(false)
            }} />
        </OffcanvasComponent>
    </div>
}

export default SupplierAffiliationShow;