import React from "react";
import SupplierAssignmentAdd from "../../../../../components/corporate/supplier/assignment/add";
import {Link, useNavigate} from "react-router-dom";

const SupplierAssignmentAddPage = () => {

    const navigate = useNavigate();

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to={'/corporate/supplier/assignment'}>
                        Annonces
                    </Link>
                </li>
                <li className="breadcrumb-item">
                    Ajouter
                </li>
            </ol>
        </div>
        <SupplierAssignmentAdd handleSuccess={assignment => navigate(`/corporate/supplier/assignment/${assignment.id}`)} />
    </div>
}

export default SupplierAssignmentAddPage;