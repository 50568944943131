import React, {useState} from "react";
import * as models from "../../../../../models/product";
import SupplierProductCategory from "../category";
import ModalComponent from "../../../../modal";
import SupplierProductUpdate from "../update";

const SupplierProductDetailShow = ({product, setProduct}: {product: models.Product, setProduct: React.Dispatch<any>}) => {

    const [showModal, setShowModal] = useState(false)

    return <div className={'col-12 mb-3'}>
        <div className="card border border-1">
            <div className="card-header">
                <div className="row align-items-center">
                    <div className="col">
                        <h6>Détails du produit</h6>
                    </div>
                    <div className="col-auto">
                        <button className={'btn btn-xxs btn-primary'} onClick={() => setShowModal(true)}>
                            <i className={'bi bi-pencil'}></i> Mettre à jour
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-text">
                            Nom
                        </div>
                        <p>
                            {product.name}
                        </p>
                    </div>
                    <div className="col-md-6">
                        <div className="form-text">
                            Catégorie
                        </div>
                        <p>
                            {product.category ? <span className={"badge bg-primary text-white"}>
                             {product.category.name}
                        </span> : <i className={'bi bi-x'}></i>}
                            <button className={'btn btn-xxs btn-circle btn-light'} data-bs-toggle="modal" data-bs-target="#productCategoryModal">
                                <i className={'bi bi-pencil'}></i>
                            </button>
                        </p>
                    </div>
                    <div className="col-md-6">
                        <div className="form-text">
                            Description courte
                        </div>
                        <p>
                            {product.description || <i className={'bi bi-x'}></i>}
                        </p>
                    </div>
                    {product.sellable && <>
                        <div className="col-md-6">
                            <div className="form-text">
                                Livrable
                            </div>
                            <p>
                                {product.shippable ? "Oui" : "Non"}
                            </p>
                        </div>
                        <div className="col-md-6">
                            <div className="form-text">
                                Gestion des stocks
                            </div>
                            <p>
                                {product.storable ? "Oui" : "Non"}
                            </p>
                        </div>
                        <div className="col-md-6">
                            <div className="form-text">
                                Limites de quantité par commande
                            </div>
                            <p className={"mb-1"}>
                                <span className={"text-muted"}>Minimum</span>: {product.minQuantity}
                            </p>
                            <p>
                                <span className={"text-muted"}>Maximum</span>: {product.maxQuantity ? product.maxQuantity : <i className={"bi bi-infinite"}></i>}
                            </p>
                        </div>
                    </>}
                </div>
            </div>
        </div>
        <SupplierProductCategory product={product} setProduct={setProduct} />
        <ModalComponent title={"Mettre à jour"} show={showModal} onHide={() => setShowModal(false)}>
            <SupplierProductUpdate product={product} onSuccess={p => {
                setProduct(p)
                setShowModal(false)
            }} />
        </ModalComponent>
    </div>
}

export default SupplierProductDetailShow;