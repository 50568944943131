import React from "react";
import {LineItems} from "../../../../models/lineItem";
import CheckoutItemShow from "../show";
import {Country} from "../../../../models/country";
import {Currency} from "../../../../models/currency";

const CheckoutItemList = ({lineItems, country, currency}: { lineItems: LineItems, country?: Country, currency: Currency }) => {

    if (!lineItems.length){
        return <>
            <p>
                Aucun article.
            </p>
        </>
    }

    return <ul className="list-group list-group-lg list-group-flush overflow-auto">
        {lineItems.map(item => <CheckoutItemShow item={item} key={item.id} country={country} currency={currency} />)}
    </ul>
}

export default CheckoutItemList;