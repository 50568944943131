import React, {useEffect, useState} from "react";
import CustomerReturnAddReason1 from "./reason1";
import CustomerReturnAddReason2 from "./reason2";
import CustomerReturnAddReason3 from "./reason3";
import {OrderItem} from "../../../../../models/order";
import * as Yup from "yup";

const CustomerReturnAddReason = ({initialValues, setInitialValues, validationSchema, setValidationSchema, setParentStep, item}: {
    initialValues: any, setInitialValues: React.Dispatch<any>,
    validationSchema: any, setValidationSchema: React.Dispatch<any>,
    item: OrderItem,
    setParentStep: React.Dispatch<any>
}) => {

    const [step, setStep] = useState<1|2|3>(1)

    useEffect(() => {
        setInitialValues((prev: any) => ({
            ...prev,
            reason1: null,
            reason2: null,
            reason3: null,
        }))

        let _fields = {...validationSchema.fields}
        _fields.reason2 = Yup.string().nullable()
        _fields.reason3 = Yup.string().nullable()
        setValidationSchema(Yup.object().shape({..._fields}))
    }, [])

    function handleNext(reason: "reason1"|"reason2"|"reason3", value: string)
    {

        const _fields = {...validationSchema.fields};

        switch (reason){
            case "reason1":
                switch (value) {
                    case 'return':
                        setStep(2);
                        _fields.reason2 = Yup.string().required('Le motif du retour est requis');
                        break;
                    default:
                        setParentStep(`confirm`)
                        break;
                }
            break;
            case "reason2":
                switch (value){
                    case "return_mismatch":
                    case "return_unwanted":
                        setStep(3);
                        _fields.reason3 = Yup.string().required('La précision sur le motif du retour est requis');
                        break;
                    default:
                        setParentStep(`confirm`)
                }
                break;
            case "reason3":
                setParentStep(`confirm`)
                break;
        }

        setInitialValues((prev: any) => ({...prev, [reason]: value}));
        setValidationSchema(Yup.object().shape({..._fields}))
    }

    function handlePrev(reason: any){

        setInitialValues((prev: any) => ({...prev, [reason]: null}));
        const _fields = {...validationSchema.fields};

        switch (reason) {
            case "reason1":
                setParentStep(`item`)
                break;
            case "reason2":
                setStep(1)
                _fields.reason2 = Yup.string().nullable();
                setValidationSchema(Yup.object().shape({..._fields}))
                break;
            case "reason3":
                setStep(2)
                _fields.reason2 = Yup.string().nullable();
                setValidationSchema(Yup.object().shape({..._fields}))
                break;
        }


    }

    return <div className={'col-12 mb-3'}>
        {step === 1 && <CustomerReturnAddReason1 handleNext={handleNext} handlePrev={handlePrev} />}
        {step === 2 && <CustomerReturnAddReason2 handleNext={handleNext} handlePrev={handlePrev} />}
        {step === 3 && <CustomerReturnAddReason3 reason2={initialValues.reason2!} handleNext={handleNext} handlePrev={handlePrev} />}
    </div>
}

export default CustomerReturnAddReason;