import React, {useEffect, useState} from "react";
import {Payload} from "../../../../../models/global";
import {Partnerships} from "../../../../../models/partnership";
import * as api from "../../../../../adapters/corporate/common/partnership"
import {useNavigate} from "react-router-dom";

const DashboardPartnership = () => {

    const [loading, setLoading] = useState(true)
    const [payload, setPayload] = useState<Payload<Partnerships>>({count: 0, rows: []});
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        api.list({offset: 0})
            .then(r => setPayload(r.data))
            .catch(e => console.log(e))
            .finally(() => setLoading(false))
    }, [])

    return <span onClick={() => navigate(`/corporate/partnership`)} className={"cursor-pointer"}><i className={"bi bi-people"}></i> {loading ? '...' : payload.count}</span>
}

export default DashboardPartnership;