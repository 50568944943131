import {get, post} from "../../../../xhr";
import encodeQueryData from "../../../../../utils/encodeQueryData";

export function list(subscriptionId: number, requestData: {query?: string, offset: number}){
    return get(`corporate/supplier/subscription/${subscriptionId}/item${encodeQueryData(requestData)}`);
}
export function add(subscriptionId: number, requestData: any){
    return post(`corporate/supplier/subscription/${subscriptionId}/item`, requestData);
}
export function update(subscriptionId: number, itemId: number, requestData: any){
    return post(`corporate/supplier/subscription/${subscriptionId}/item/${itemId}`, requestData);
}