import React, {useEffect, useRef, useState} from "react";
import Loader from "../../../../../utils/loader";
import * as api from "../../../../../adapters/corporate/common/post";
import * as models from "../../../../../models/post";
import PostAdd from "../add";
import {Organization} from "../../../../../models/organization";
import PostListItem from "./item";
import ErrorCard from "../../../../error";

interface PostListInterfaceBase {
    type: "personalized"|"partners"|"organization"
}

interface PostListInterfaceOrganization {
    type: "organization",
    organization: Organization
}

interface PostListInterfaceFeed {
    type: "personalized"|"partners",
    organization?: undefined
}

type PostListInterface = PostListInterfaceBase & (PostListInterfaceOrganization | PostListInterfaceFeed)

const useUpdatedRef = (value: any) => {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref;
};

const PostList = (props: PostListInterface) => {

    const [payload, setPayload] = useState< {count: number, rows: models.Posts}>({count: 0, rows: []})
    const [offset, setOffset] = useState<number>(0)
    const [loading, setLoading] = useState(true);
    const loadingRef = useUpdatedRef(loading);
    const payloadRef = useUpdatedRef(payload);
    const [error, setError] = useState<any>(null)

    useEffect(() => {
        setLoading(true);
        loadingRef.current = true;
        api.list({offset: offset, type: props.type, organization_id: props.organization?.id})
            .then(r => setPayload(prev => ({count: r.data.count, rows: [...prev.rows, ...r.data.rows]})))
            .catch(e => setError(e))
            .finally(() => {
                setLoading(false)
                loadingRef.current = false;
            })
    }, [offset, props.type, props.organization?.id])

    const handleScrollBottom = () => {


        const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        const clientHeight = document.documentElement.clientHeight || window.innerHeight;

        if (scrollTop + clientHeight + 10 >= scrollHeight) {
            if (!loadingRef.current && payloadRef.current.rows.length < payloadRef.current.count) {
                setOffset(p => p + 1);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScrollBottom);
        return () => window.removeEventListener('scroll', handleScrollBottom);
    }, []);

    if (error){
        return <ErrorCard error={error} />
    }

    return <div className={'col-12 h-100'}>
        {props.type !== "organization" && <PostAdd handleSuccess={post => setPayload(prev => ({count: prev.count + 1, rows: [post, ...prev.rows]}))}/>}
        <div className="col-12 overflow-auto">
            {payload.rows.map((p, index) => <PostListItem post={p} key={index} />)}
        </div>
        {loading && <div className={'col-12 py-8 text-center'}>
            <Loader />
        </div>}
        {!loading && payload.count <= payload.rows.length && <div className={'col-12 text-center text-muted'}>
            Aucun post supplémentaire disponible
        </div>}
    </div>
}

export default PostList;