import {get, post} from "../xhr";
import encodeQueryData from "../../utils/encodeQueryData";

export function list(requestData: {type: 'corporate'|'customer'}) {
    return get(`/account${encodeQueryData(requestData)}`);
}
export function show(id: number) {
    return get(`/account/${id}`);
}
export function add(requestData: any) {
    return post(`/account`, requestData);
}
export function update(id: number, requestData: any) {
    return post(`/account/${id}`, requestData);
}

export function select(id: number) {
    return get(`/account/${id}/select`);
}