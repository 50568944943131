import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import * as api from "../../../../../adapters/corporate/supplier/assignment";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import SupplierFeeList from "../../fee/list";
import SupplierApplicationList from "../../application/list";
import {Assignment} from "../../../../../models/assignment";
import ModalComponent from "../../../../modal";
import SupplierProposalList from "../../proposal/list";

const SupplierAssignmentShow = () => {

    const {id} = useParams();
    const [assignment, setAssignment] = useState<Assignment>()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [showApplicationModal, setShowApplicationModal] = useState(false)

    const [feeStatus, setFeeStatus] = useState<'All'|'Pending'|'Accepted'|'Refused'|'Paid'>('All')

    useEffect(() => {
        setLoading(true)
        setError(null)
        api.show(Number(id))
            .then(r => setAssignment(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [id])

    function updateStatus(status: 'Published'|'Hidden'){
        if (assignment){
            api.update(assignment.id, {status: status})
                .then(r => setAssignment(r.data))
                .catch(e => setError(e))
        }
    }

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!assignment){
        return <></>
    }

    return <div className={'container-fluid'}>
        <div className="col-12 mb-3">
            <div className="row">
                <div className="col">
                    <ol className="breadcrumb mb-3 fs-xs">
                        <li className="breadcrumb-item">
                            <Link to="/corporate/supplier/assignment">Annonces</Link>
                        </li>
                        <li className="breadcrumb-item">
                            {assignment.name}
                        </li>
                    </ol>
                </div>
                <div className="col-auto">
                    {assignment.status === 'Published' ? <button onClick={() => updateStatus('Hidden')} className={'btn btn-xxs btn-light'}>Fermer l'annonce</button> : <button onClick={() => updateStatus('Published')} className={'btn btn-xxs btn-light'}>Publier l'annonce</button>}
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-8 mb-3">
                <div className="card border border-1">
                    <div className="card-body">
                        <h6>Informations</h6>
                        <table className="table table-borderless">
                            <tbody>
                            <tr>
                                <th>Lieux</th>
                                <td>
                                    {assignment.location}
                                </td>
                            </tr>
                            <tr>
                                <th>Type de rémunération</th>
                                <td>
                                    {assignment.compensation === 'Fee' && <>Comissions</>}
                                    {assignment.compensation === 'Fix' && <>Fix</>}
                                    {assignment.compensation === 'Hybrid' && <>Fix + Commissions</>}
                                </td>
                            </tr>
                            {['Fix', 'Hybrid'].includes(assignment.compensation) && <tr>
                                <th>Montant du fix</th>
                                <td>Entre {assignment.fixRangeMin}{assignment.currency.symbol} et {assignment.fixRangeMax}{assignment.currency.symbol} par {assignment.fixRangeDuration} </td>
                            </tr>}
                            {['Fee', 'Hybrid'].includes(assignment.compensation) && <tr>
                                <th>Montant des commissions</th>
                                <td>Entre {assignment.feeRangeMin}{assignment.currency.symbol} et {assignment.feeRangeMax}{assignment.currency.symbol} par {assignment.feeTrigger}</td>
                            </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-md-4 mb-3">
                <div className="card border border-1 h-100">
                    <div className="card-body text-center d-flex flex-column">
                        <h6>Candidatures</h6>
                        <p className={'display-2 text-info flex-grow-1'}>{assignment.applications.length}</p>
                        <button className={'btn btn-xxs'} onClick={() => setShowApplicationModal(true)}>
                            Afficher les candidatures
                        </button>
                    </div>
                </div>
                {showApplicationModal && <ModalComponent size={'modal-lg'} title={"Candidatures"} show={showApplicationModal}
                                 onHide={() => setShowApplicationModal(false)}>
                    <SupplierApplicationList assignment={assignment}/>
                </ModalComponent>}
            </div>
        </div>
        <div className="row">
            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-body">
                        <h6>Devis</h6>
                        <SupplierProposalList assignment={assignment} />
                    </div>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col mb-3">
                                <h6>Commissions</h6>
                            </div>
                            <div className="col-auto mb-3">
                                <div className="dropdown">
                                    <button className="btn btn-light btn-xs dropdown-toggle" type="button"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                        {feeStatus === 'All' && <>Tout les statuts</>}
                                        {feeStatus === 'Pending' && <>En attente</>}
                                        {feeStatus === 'Accepted' && <>Accepté</>}
                                        {feeStatus === 'Refused' && <>Refusé</>}
                                        {feeStatus === 'Paid' && <>Payé</>}
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li className="dropdown-item" onClick={() => setFeeStatus('All')}>Tout les statuts</li>
                                        <li className="dropdown-item" onClick={() => setFeeStatus('Pending')}>En attente</li>
                                        <li className="dropdown-item" onClick={() => setFeeStatus('Accepted')}>Accepté</li>
                                        <li className="dropdown-item" onClick={() => setFeeStatus('Refused')}>Refusé</li>
                                        <li className="dropdown-item" onClick={() => setFeeStatus('Paid')}>Payé</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <SupplierFeeList status={feeStatus} assignment={assignment} />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default SupplierAssignmentShow;