import * as models from "../../../../../../../../models/subscription";
import CorporateInvoiceDownload from "../../../../../../common/invoice/download";
import React, {useState} from "react";
import ModalComponent from "../../../../../../../modal";
import SupplierSubscriptionShowOccurrenceShow from "../../show";
import convertToDecimal from "../../../../../../../../utils/currencyConverter";
import SupplierSubscriptionOccurrenceRefundAdd from "../../refund/add";

const SupplierSubscriptionShowOccurrenceListItem = ({subscription, occurrence}: {subscription: models.Subscription, occurrence: models.SubscriptionOccurrence}) => {

    const [showModal, setShowModal] = useState(false)
    const [showRefundModal, setShowRefundModal] = useState(false)

    return <li className={'list-group-item'}>
        <div className="row align-items-center">
            <div className="col">
                <p className={'mb-0'}>
                    {new Date(occurrence.dueDate).toLocaleDateString()} | {convertToDecimal(occurrence.priceData.amount_including_tax, occurrence.priceData.currency)}
                </p>
            </div>
            <div className="col-auto">
                <button className={'btn btn-light btn-xxs'} onClick={() => setShowModal(true)}><i className={'bi bi-eye'}></i> Afficher le détail</button>
            </div>
            <div className="col-auto">
                <CorporateInvoiceDownload invoice={occurrence.invoice} />
            </div>
        </div>
        <ModalComponent
            footerContent={<div className={"btn-group"}>
                <button className={"btn btn-xxs btn-light me-2"} onClick={() => {
                    setShowModal(false)
                    setShowRefundModal(true)
                }}>
                    <i className={'bi bi-plus-circle-dotted'}></i> Ajouter un remboursement
                </button>
                <CorporateInvoiceDownload invoice={occurrence.invoice} />
            </div>}
            size={'modal-xl'}
            title={`Occurrence du ${new Date(occurrence.dueDate).toLocaleDateString()}`}
            show={showModal}
            onHide={() => setShowModal(false)}
        >
            {showModal && <SupplierSubscriptionShowOccurrenceShow subscription={subscription} occurrence={occurrence} key={occurrence.id} />}
        </ModalComponent>
        <ModalComponent title={'Rembourser des articles'} show={showRefundModal} onHide={() => setShowRefundModal(false)}>
            <SupplierSubscriptionOccurrenceRefundAdd subscription={subscription} occurrence={occurrence} onSuccess={refund => setShowRefundModal(false)} />
        </ModalComponent>
    </li>
}

export default SupplierSubscriptionShowOccurrenceListItem;