import React, {useEffect, useState} from "react";
import {FetchParams, Payload} from "../../../../../models/global";
import {Applications} from "../../../../../models/application";
import * as api from "../../../../../adapters/corporate/provider/application";
import ErrorCard from "../../../../error";
import Loader from "../../../../../utils/loader";
import {getLogoUrl} from "../../../../../utils/image";
import {Link} from "react-router-dom";

const ProviderApplicationList = () => {

    const [loading, setLoading] = useState(true);
    const [payload, setPayload] = useState<Payload<Applications>>({count: 0, rows: []});
    const [fetchParams, setFetchParams] = useState<FetchParams>({offset: 0})
    const [error, setError] = useState(null);

    useEffect(() => {
        api.list(fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    return <>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <ol className="breadcrumb mb-3 fs-xs">
                        <li className="breadcrumb-item">
                            Candidatures
                        </li>
                    </ol>
                </div>
                <div className="col-auto">
                    <Link to={'/corporate/provider/search/assignment'} className={'btn btn-light btn-xxs'}>
                        <i className={'bi bi-search'}></i> Parcourir les annonces
                    </Link>
                </div>
            </div>
        </div>

        <div className="row">
            {payload.rows.map((r, index) => <div key={index} className="col-12 mb-3">
                <Link to={`/corporate/provider/assignment/${r.assignment.id}`} className="card border border-1 shadow-hover">
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div className="avatar avatar-lg">
                                    <img src={getLogoUrl(r.assignment.organization)} alt="" className="avatar-img rounded-circle"/>
                                </div>
                            </div>
                            <div className="col">
                                <h6 className={'mb-1'}>{r.assignment.name}</h6>
                                <p className={"text-muted mb-0"}>{r.assignment.organization.name}</p>
                            </div>
                            <div className="col-auto">
                                {r.status === "Pending" && <span className={'badge bg-warning'}><i className={'bi bi-clock'}></i> En attente</span>}
                                {r.status === "Accepted" && <span className={'badge bg-success'}><i className={'bi bi-check'}></i> Intéressé</span>}
                                {r.status === "Refused" && <span className={'badge bg-danger'}><i className={'bi bi-x'}></i> Pas Intéressé</span>}
                            </div>
                            <div className="col-auto">
                                <i className={'bi bi-arrow-right'}></i>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>)}
        </div>
    </>
}

export default ProviderApplicationList;