import React, {useEffect, useState} from "react";
import * as models from "../../../../../models/partnership";
import * as api from "../../../../../adapters/corporate/common/partnership/request";
import Loader from "../../../../../utils/loader";
import {Payload} from "../../../../../models/global";
import ErrorCard from "../../../../error";
import ListEmpty from "../../../../list/empty";
import PartnershipRequestListItem from "./item";

const PartnershipRequestList = ({displayBreadcrumb}: {displayBreadcrumb: boolean}) => {

    const [loading, setLoading] = useState<boolean>(true)
    const [payload, setPayload] = useState<Payload<models.PartnershipRequests>>({count: 0, rows: []});
    const [error, setError] = useState(false)

    useEffect(() => {
        setLoading(true)
        api.list()
            .then(resp => setPayload(resp.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [])

    if (loading){
        return <div className={'container-fluid py-10 text-center'}>
            <Loader />
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    return <div className={'container-fluid'}>
        {displayBreadcrumb && <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    Invitations
                </li>
            </ol>
        </div>}
        {payload.count ? <div className="row">
            {payload.rows.map(r => <PartnershipRequestListItem item={r} setPayload={setPayload} />)}
        </div> : <ListEmpty /> }
    </div>
}

export default PartnershipRequestList;