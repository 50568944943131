import React, {useState} from "react";
import SearchInput from "../../../../../utils/searchInput";
import ChatSearchChat from "./chat";
import ChatSearchAccount from "./account";

const ChatSearch = ({setShowSearch}: {setShowSearch: React.Dispatch<any>}) => {

    const [query, setQuery] = useState<string>('');

    return <>
        <div className="row">
            <div className="col-12 mb-3">
                <div className="row gx-1 align-items-center">
                    <div className="col-auto" onClick={() => setShowSearch(false)}>
                        <i className={"bi bi-arrow-left"}></i>
                    </div>
                    <div className="col">
                        <SearchInput value={query} autoFocus={true} size={"sm"} onChange={e => setQuery(e.target.value)} placeholder={"Rechercher ou démarrer une discussion..."} />
                    </div>
                </div>
            </div>
            <ChatSearchChat setShowSearch={setShowSearch} query={query} />
            <ChatSearchAccount query={query} setShowSearch={setShowSearch} />
        </div>
        </>
}

export default ChatSearch;