import {Quote, QuoteCondition} from "../../../../../../models/quote";
import {useFormik} from "formik";
import * as Yup from "yup";
import React, {useState} from "react";
import {format} from "date-fns";
import FormError from "../../../../../../utils/formError";
import * as api from "../../../../../../adapters/corporate/supplier/quote";
import Loader from "../../../../../../utils/loader";
import ErrorCard from "../../../../../error";

const SupplierQuoteConditionUpdate = ({quote, onSuccess}: {quote: Quote, onSuccess: (condition: QuoteCondition) => void}) => {

    const [error, setError] = useState(null);

    const formik = useFormik({
        initialValues: {
            expiredAt: quote.condition.expiredAt,
            delay: quote.condition.delay,
            deposit: quote.condition.deposit,
            fee: quote.condition.fee
        },
        validationSchema: Yup.object().shape({
            expiredAt: Yup.date().min(new Date()),
            delay: Yup.string().max(10000).nullable(),
            deposit: Yup.number().required(),
            fee: Yup.number().required().min(1)
        }),
        onSubmit:(values, {setSubmitting}) => {
            api.condition(quote.id, values)
                .then(r => onSuccess(r.data))
                .catch(error => setError(error))
                .finally(() => setSubmitting(false));
        }
    });

    return  <form onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="date" defaultValue={format(new Date(quote.condition.expiredAt), "uuuu-MM-dd")} name={"expiredAt"} onChange={formik.handleChange} onBlur={formik.handleBlur} className={"form-control"} id={"expiredAt"} />
                <label htmlFor="expiredAt">
                    Date d'expiration du devis
                </label>
            </div>
            <FormError form={formik} field={"expiredAt"} />
        </div>
        <div className="col-12 mb-3">
            <div className="input-group">
                <div className="form-floating">
                    <input type="number" defaultValue={quote.condition.fee} name={"fee"} onChange={formik.handleChange} onBlur={formik.handleBlur} className={"form-control"} id={"fee"} />
                    <label htmlFor="fee">
                        Commission apporteur
                    </label>
                </div>
                <span className="input-group-text">
                    <i className={'bi bi-percent'}></i>
                </span>
            </div>
            <div className="form-text">
                L'apporteur à indiqué {quote.project.fee}% dans son appel d'offre
            </div>
            <FormError form={formik} field={"deposit"} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="number" defaultValue={quote.condition.deposit} name={"deposit"} onChange={formik.handleChange} onBlur={formik.handleBlur} className={"form-control"} id={"deposit"} />
                <label htmlFor="deposit">
                    Pourcentage d'accompte
                </label>
            </div>
            <FormError form={formik} field={"deposit"} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <textarea style={{height: 400}} name="delay" id="delay" className={'form-control'} onChange={formik.handleChange} onBlur={formik.handleBlur}></textarea>
                <label htmlFor="delay">
                    Délais de réalisation/livraison
                </label>
            </div>
            <FormError form={formik} field={"delay"} />
        </div>
        {error && <ErrorCard error={error} />}
        <button type={"submit"} className={"btn btn-primary w-100"}>
            {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-check'}></i> Enregistrer</>}
        </button>
    </form>
}

export default SupplierQuoteConditionUpdate;