import * as models from "../../../../models/checkout";
import React, {useState} from "react";
import CheckoutAddressShippingSelector from "./selector";

const CheckoutAddressShipping = ({checkout, setCheckout}: {checkout: models.CheckoutResponse, setCheckout: React.Dispatch<any>}) => {

    return <div className={"col-12 mb-3"}>
        <div className="row align-items-center">
            <div className="col">
                <p className={'mb-0 fw-semibold'}>
                    Addresse de livraison
                </p>
            </div>
        </div>
        <CheckoutAddressShippingSelector checkout={checkout} setCheckout={setCheckout}/>
    </div>
}

export default CheckoutAddressShipping;