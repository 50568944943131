import {get, post, destroy} from "../../../../xhr";
import encodeQueryData from "../../../../../utils/encodeQueryData";

export function upsert(pid: number, requestData: {declinationChoices?: number[], quantity: number, address_id: number}){
    return post(`/corporate/supplier/product/${pid}/inventory/upsert`, requestData);
}

export function show(pid: number, requestData: {choices: number[], addressId: number}){
    return post(`/corporate/supplier/product/${pid}/inventory/find`, requestData);
}

export function list(productId: number, requestData: {choices: number[], address_id?: number, offset: number}){
    return get(`/corporate/supplier/product/${productId}/inventory${encodeQueryData(requestData)}`);
}

export function remove(pid: number, sid: number){
    return destroy(`/corporate/supplier/product/${pid}/inventory/${sid}`);
}