import * as models from "../../../../../../../models/pack";
import {getCoverUrl} from "../../../../../../../utils/image";
import React from "react";
import * as api from "../../../../../../../adapters/corporate/supplier/pack/item"

const SupplierPackItemListItem = ({pack, item, onRemove}: {pack: models.Pack, item: models.PackItem, onRemove: (item: models.PackItem) => void}) => {

    function remove(){
        api.remove(pack.id, item.id)
            .then(() => onRemove(item))
    }

    return <div className={"col"}>
        <div className={"card border border-1 h-100"}>
            <img src={`${getCoverUrl(item.product)}`} alt="" className="card-img-top"/>
            <div className="card-body">
                <p className="fw-semibold mb-1">
                    {item.product.name}
                </p>
                <p className={"small mb-1"}>Quantité: {item.quantity}</p>
                <p className={"small mb-3"}>Modifiable: {item.editable ? "Oui" : "Non"}</p>
                <div className="row">
                    <div className="col-auto">
                        <button className={"btn btn-xs btn-light"}>
                            <i className={'bi bi-pencil'}></i>
                        </button>
                    </div>
                    <div className="col-auto">
                        <button className={"btn btn-xs btn-light"} onClick={() => remove()}>
                            <i className={'bi bi-x text-danger'}></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default SupplierPackItemListItem;