import convertToDecimal from "../../../utils/currencyConverter";
import React, {useState} from "react";
import {BundleItem, BundleItemPrice} from "../../../models/bundle";
import * as api from "../../../adapters/bundle";
import {useNavigate} from "react-router-dom";
import Loader from "../../../utils/loader";
import {Country} from "../../../models/country";
import {Currency} from "../../../models/currency";

const BundleItemComponent = ({token, item, price, country, currency, setError}: {token: string, item: BundleItem, price: BundleItemPrice, country: Country, currency: Currency, setError: React.Dispatch<any>}) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    function checkout(price: BundleItemPrice){
        setLoading(true)
        api.checkout(token!, price.id, {country_id: country!.id, currency_id: currency!.id})
            .then(r => navigate(`/checkout/${r.data.uuid}`))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }

    return <div className={"col mb-3"}>
        <div className="card border border-1 h-100">
            <div className="card-body">
                <h5 className="text-center">
                    {item.product.name}
                </h5>
                <h2 className="text-nowrap fw-bold text-center">
                    {convertToDecimal(price.data.amount_including_tax, price.data.currency)}
                </h2>
                {price.data.recurring && <p className="text-nowrap text-muted text-center small">
                    Tout les {price.data.recurring.intervalCount} {price.data.recurring.interval}
                </p>}
                <table className="table table-borderless align-middle">
                    <tbody>
                    {item.product.features.map(f => <tr>
                        <th><i className={"bi bi-check text-primary"}></i></th>
                        <td className={"text-muted small"}>
                            {f.description}
                        </td>
                    </tr>)}
                    </tbody>
                </table>
            </div>
            <div className="card-footer">
                <button disabled={loading} type={"button"} className={"btn w-100 btn-primary"} onClick={() => checkout(price)}>
                    {loading ? <Loader size={"sm"} /> : <>
                        {price.data.recurring ? (price.data.recurring.trialPeriodDays > 0 ? "Démarrer l'essai gratuit" : "S'abonner") : 'Commander'}
                    </>}
                </button>
            </div>
        </div>
    </div>
}

export default BundleItemComponent;