import React, {useState} from "react";
import * as models from "../../../../../../models/product";
import * as api from "../../../../../../adapters/corporate/supplier/product/taxRate";
import SupplierProductTaxRateAdd from "../add";
import {TaxRate} from "../../../../../../models/taxRate";
import ModalBtn from "../../../../../../utils/modalBtn";
import ModalComponent from "../../../../../modal";

const SupplierProductTaxRateShow = ({ product, setProduct }: { product: models.Product & models.SellableProduct, setProduct: React.Dispatch<any> }) => {

    function unlink(taxRate: TaxRate){
        api.unlink(product.id, taxRate.id)
            .then(() => setProduct((prev: models.Product & models.SellableProduct) => ({...prev, taxRates: prev.taxRates.filter(tr => tr.id !== taxRate.id)})))
    }

    const [showLinkModal, setShowLinkModal] = useState(false)

    return <div className={'col-12 mb-3'} id={'TaxRates'}>
        <div className="card border border-1">
            <div className="card-header">
                <div className="row align-items-center">
                    <div className="col">
                        <h6>
                            <i className={'bi bi-percent'}></i> Taxes
                        </h6>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-xxs btn-primary" onClick={() => setShowLinkModal(true)}>
                            Lier des taux
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="table-responsive">
                    <table className={'table'}>
                        <thead>
                        <tr>
                            <th>
                                Nom
                            </th>
                            <th>
                                Description
                            </th>
                            <th>
                                Pourcentage
                            </th>
                            <th>
                                Juridiction
                            </th>
                            <th>
                                Pays
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {product.taxRates.map(item => <tr>
                            <td>
                                {item.name}
                            </td>
                            <td>
                                {item.description}
                            </td>
                            <td>
                                {item.percentage}%
                            </td>
                            <td>
                                {item.jurisdiction}
                            </td>
                            <td>
                                <img style={{height: 18, width: "auto"}} src={item.country.flag} alt={item.country.name}/> {item.country.name}
                            </td>
                            <td>
                                <button className="btn btn-xxs btn-light text-danger"
                                        onClick={() => unlink(item)}>
                                    <i className={'bi bi-dash-circle'}></i>
                                </button>
                            </td>
                        </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <ModalComponent title={"Lier des taux de taxes"} show={showLinkModal} onHide={() => setShowLinkModal(false)}>
            <SupplierProductTaxRateAdd product={product} setProduct={setProduct} unlink={unlink} />
        </ModalComponent>
    </div>
}

export default SupplierProductTaxRateShow;