import {Link} from "react-router-dom";
import convertToDecimal from "../../../../../../utils/currencyConverter";
import TransferStatusPlaceholder from "../../status";
import CorporateInvoiceDownload from "../../../invoice/download";
import React from "react";
import {Transfer} from "../../../../../../models/transfer";
import CommonTransferType from "../../type";

const CommonTransferListItem = ({t}: {t: Transfer}) => {

    return <tr>
        <td>
            <CommonTransferType transfer={t} key={t.id} />
        </td>
        <td>
            {convertToDecimal(t.amount, t.currency)}
        </td>
        <td>
            {convertToDecimal(t.platformFee, t.currency)}
        </td>
        <td>
            {convertToDecimal(t.amount - t.platformFee, t.currency)}
        </td>
        <td>
            {new Date(t.dueDate).toLocaleDateString()}
        </td>
        <td>
            <TransferStatusPlaceholder status={t.status} />
        </td>
        <td>
            <CorporateInvoiceDownload invoice={t.invoice} />
        </td>
    </tr>
}

export default CommonTransferListItem;