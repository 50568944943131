import {Project} from "../../../../../models/project";
import * as api from "../../../../../adapters/corporate/supplier/quote"
import React, {useEffect, useState} from "react";
import {Quotes} from "../../../../../models/quote";
import QuoteStatusPlaceholder from "../../../common/quote/status";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";
import SearchInput from "../../../../../utils/searchInput";
import {Link} from "react-router-dom";
import {CustomerAccount} from "../../../../../models/account";

const SupplierQuoteList = ({project, customer}: {project?: Project, customer?: CustomerAccount}) => {

    const [loading, setLoading] = useState(true);
    const [fetchParams, setFetchParams] = useState({offset: 0, query: "", status: "All"});
    const [payload, setPayload] = useState<{count: number, rows: Quotes}>({count: 0, rows: []});

    useEffect(() => {
        setLoading(true)
        api.list({...fetchParams, project_id: project?.id, customer_id: customer?.id})
            .then(r => setPayload(r.data))
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }, [project?.id, fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams(p => ({...p, query: e.target.value})), 1000)
    }

    return <>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col-12 mb-3">
                    <div className="row">
                        <div className="col">
                            <SearchInput placeholder={"Rechercher un devis..."} onChange={handleQueryChange} size={"default"} />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <span className="text-muted">
                        {loading ? <Loader size={"sm"} /> : <>{payload.count} résultats</>}
                    </span>
                </div>
            </div>
        </div>
        <div className={'col-12 mb-3'}>
            <ul className={'list-group list-group-flush'}>
                {loading ? <li className={'list-group-item'}><Loader /> chargement en cours...</li> : <>
                    {payload.rows.length ? payload.rows.map(quote => <li className={'list-group-item'}>
                        <div className="row">
                            <div className="col-auto">
                                <Link to={`/corporate/supplier/quote/${quote.id}`}>
                                    {quote.number}
                                </Link>
                            </div>
                            <div className="col">
                                <Link to={`/corporate/supplier/project/${quote.project.id}`}>
                                    {quote.project.title}
                                </Link>
                            </div>
                            <div className="col-auto">
                                <QuoteStatusPlaceholder status={quote.status} />
                            </div>
                        </div>
                    </li>) : <li className={'list-group-item text-muted'}><i className={'bi bi-search'}></i> Aucun résultat</li>}</>}
            </ul>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        </div>
        </>
}

export default SupplierQuoteList;