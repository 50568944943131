import {getLogoUrl} from "../../../../../../utils/image";
import CommonConversionStatus from "../../../../common/conversion/status";
import React, {useState} from "react";
import {Conversion} from "../../../../../../models/affiliation";
import convertToDecimal from "../../../../../../utils/currencyConverter";
import OffcanvasComponent from "../../../../../offcanvas";

const ProviderConversionListItem = ({item}: {item: Conversion}) => {

    const [showDetailModal, setShowDetailModal] = useState(false)

    return <>
        <tr>
            <td>
                {item.affiliation.name}
            </td>
            <td>
                {item.rule.name}
            </td>
            <td>
                {item.priceData ? convertToDecimal(item.priceData.amount, item.priceData.currency) : <i className={"bi bi-x"}></i>}
            </td>
            <td>
                {item.priceData ? convertToDecimal(item.priceData.fee, item.priceData.currency) : <i className={"bi bi-x"}></i>}
            </td>
            <td>
                <span className="avatar avatar-sm">
                    <img src={getLogoUrl(item.affiliation.organization)} alt={item.affiliation.organization.name} className="avatar-img rounded-circle" />
                </span>
                <span>
                    {item.affiliation.organization.name}
                </span>
            </td>
            <td>
                {new Date(item.createdAt).toLocaleDateString()} à {new Date(item.createdAt).toLocaleTimeString()}
            </td>
            <td>
                <CommonConversionStatus status={item.status} />
            </td>
            <td>
                <button className="btn btn-xxs byn-light" onClick={() => setShowDetailModal(true)}>
                    <i className={'bi bi-eye'}></i>
                </button>
                <OffcanvasComponent title={"Détails de la conversion"} show={showDetailModal} onHide={() => setShowDetailModal(false)}>
                    <table className={"table"}>
                        <tbody>
                        <tr>
                            <th>Statut</th>
                            <td><CommonConversionStatus status={item.status} /></td>
                        </tr>
                        <tr>
                            <th>Affiliation</th>
                            <td>{item.affiliation.name}</td>
                        </tr>
                        <tr>
                            <th>Règle</th>
                            <td>{item.rule.name}</td>
                        </tr>
                        {item.rule.type === "Percent" && <>
                            <tr>
                                <th>Montant</th>
                                <td>{convertToDecimal(item.priceData.amount, item.priceData.currency)}</td>
                            </tr>
                            <tr>
                                <th>Commission</th>
                                <td>{convertToDecimal(item.priceData.fee, item.priceData.currency)}</td>
                            </tr>
                        </>}
                        <tr>
                            <th>Pays</th>
                            <td>{item.country || <i className={"bi bi-x"}></i>}</td>
                        </tr>
                        <tr>
                            <th>Ville</th>
                            <td>{item.city || <i className={"bi bi-x"}></i>}</td>
                        </tr>
                        <tr>
                            <th>Date</th>
                            <td>{new Date(item.createdAt).toLocaleDateString()} à {new Date(item.createdAt).toLocaleTimeString()}</td>
                        </tr>
                        <tr>
                            <th>Date</th>
                            <td>{new Date(item.createdAt).toLocaleDateString()} à {new Date(item.createdAt).toLocaleTimeString()}</td>
                        </tr>
                        <tr>
                            <th>Fournisseur</th>
                            <td>
                                <span className="avatar avatar-sm">
                                    <img src={getLogoUrl(item.affiliation.organization)} alt={item.affiliation.organization.name} className="avatar-img rounded-circle" />
                                </span>
                                <span>
                                    {item.affiliation.organization.name}
                                </span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </OffcanvasComponent>
            </td>
        </tr>
        </>
}

export default ProviderConversionListItem;