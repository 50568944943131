import React, {useEffect, useState} from "react";
import {Shippings} from "../../models/shipping";
import * as api from "../../adapters/corporate/supplier/shipping";
import Select from "../select";
import {Link} from "react-router-dom";

const ShippingSelect = ({handleChange, required, defaultValue}: {handleChange: (id: number) => void, required?: boolean, defaultValue?: {label: string, value: number}}) => {

    const [shippings, setShippings] = useState<{count: number, rows: Shippings}>({rows: [], count: 0})
    const [loading, setLoading] = useState(false);
    const [fetchParams, setFetchParams] = useState<{query: string, offset: number}>({query: "", offset: 0})

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(r => setShippings(r.data))
            .then(() => setLoading(false))
    }, [fetchParams])

    return <><Select
        multiple={false}
        disabled={loading}
        required={required}
        label={"Profil d'expédition"}
        options={shippings.rows.map(s => ({label: s.name, value: s.id}))}
        onChange={(c: any) => handleChange(c.value)}
        handleScrollBottom={() => setFetchParams(p => ({...p, offset: p.offset + 1}))}
        handleQueryChange={(query) => setFetchParams(p => ({query: query, offset: 0}))}
    />
        {!loading && shippings.count === 0 && <Link to={'/corporate/supplier/shipping/add'} className={'text-underline'}>Aucun profile de livraison trouvé. Cliquez ici pour en ajouter un <i className={'bi bi-arrow-right'}></i></Link>}
    </>
}

export default ShippingSelect;