import React from "react";
import {Touch} from "../../../../../../models/touch";
import {getLogoUrl} from "../../../../../../utils/image";
import {useNavigate} from "react-router-dom";
import CommonTouchStatus from "../../../../common/touch/status";

const SupplierTouchListItem = ({touch}: {touch: Touch}) => {

    const navigate = useNavigate();

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const now = new Date();

        const isToday = date.toDateString() === now.toDateString();
        const isYesterday = date.toDateString() === new Date(now.setDate(now.getDate() - 1)).toDateString();

        if (isToday) {
            return date.toLocaleTimeString(undefined, {timeStyle: "short"});
        } else if (isYesterday) {
            return date.toLocaleDateString(undefined, {dateStyle: 'short'});
        } else {
            return date.toLocaleDateString();
        }
    };

    return <div className={"col-12 mb-3"}>
        <div className="card border border-1 shadow-hover" onClick={() => navigate(`/corporate/supplier/touch/${touch.id}`)}>
            <div className="card-body">
                <div className="row align-items-center">
                    <div className="col">
                        <p className={'fw-semibold mb-0'}>
                            {touch.title}
                        </p>
                        <p className={'text-muted mb-0'}>
                            {formatDate(touch.createdAt)}
                        </p>
                    </div>
                    <div className="col-auto">
                        <CommonTouchStatus status={touch.touchSuppliers[0].status} />
                    </div>
                    <div className="col-auto">
                        <span className="avatar avatar-lg">
                            <img src={getLogoUrl(touch.organization)} alt={touch.organization.name} className="avatar-img rounded-circle" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default SupplierTouchListItem;