import {useFormik} from "formik";
import React, {useState} from "react";
import * as Yup from 'yup'
import FormError from "../../../../../utils/formError";
import {Contact} from "../../../../../models/contact";
import Select from "../../../../../utils/select";
import {getCountryCodes, getCountryCodesChoice} from "../../../../../utils/tel";
import Loader from "../../../../../utils/loader";
import * as api from "../../../../../adapters/corporate/provider/contact";

const ProviderContactAdd = ({onSuccess}: { onSuccess: (contact: Contact) => void }) => {

    const [error, setError] = useState(null)

    const [initialValues, setInitialValues] = useState<any>({
        firstname: "",
        lastname: "",
        jobTitle: "",
        countryCode: "",
        tel: "",
        email: "",
        type: "company",
        companyName: ""
    })

    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        firstname: Yup.string().required(),
        lastname: Yup.string().required(),
        jobTitle: Yup.string().optional(),
        countryCode: Yup.string().required(),
        tel: Yup.string().required(),
        email: Yup.string().required(),
        type: Yup.string().required(),
        companyName:Yup.string().required()
    }))

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, {setSubmitting}) => {
            api.add(values)
                .then(r => onSuccess(r.data))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    })

    function handleTypeChange(type: string){

        let _values = {...formik.values}
        let _schema = {...validationSchema.fields}

        _values.type = type;

        switch (type){
            case 'company':
                _values.companyName = '';
                _schema.companyName = Yup.string().required()
                _values.jobTitle = '';
                _schema.jobTitle = Yup.string().nullable()
                break;
            case "individual":
                delete _values.companyName;
                delete _schema.companyName;
                delete _values.jobTitle;
                delete _schema.jobTitle;
                break;
        }

        setInitialValues({..._values})
        setValidationSchema(Yup.object().shape(_schema))
    }

    return <form onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className="form-control" id={"firstname"} name={"firstname"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                <label htmlFor="firstname">Prénom</label>
            </div>
            <FormError form={formik} field={'firstname'} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className="form-control" id={"lastname"} name={"lastname"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                <label htmlFor="lastname">Nom</label>
            </div>
            <FormError form={formik} field={'lastname'} />
        </div>
        <div className="col-12 mb-3">
            <div className="input-group">
                <Select
                    label={"Code pays"}
                    required={true}
                    options={getCountryCodes()}
                    onChange={c => formik.setFieldValue('countryCode', c.value)}
                />
                <div className="form-floating">
                    <input type="text" className="form-control" id={"tel"} name={"tel"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                    <label htmlFor="firstname">
                        Tel
                    </label>
                </div>
            </div>
            <FormError form={formik} field={"tel"} />
            <FormError form={formik} field={'countryCode'} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className="form-control" id={"email"} name={"email"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                <label htmlFor="email">Email</label>
            </div>
            <FormError form={formik} field={'email'} />
        </div>
        <div className="col-12 mb-3">
            <Select
                label={"Type"}
                options={[{label: "Entreprise", value: "company"}, {label: "Particulier", value: 'individual'}]}
                onChange={c => handleTypeChange(c.value)}
                required={true}
                value={{label: "Entreprise", value: "company"}}
            />
            <FormError form={formik} field={'type'} />
        </div>
        {formik.values.type === "company" && <>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className="form-control" id={"jobTitle"} name={"jobTitle"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                    <label htmlFor="jobTitle">Fonction</label>
                </div>
                <FormError form={formik} field={'jobTitle'} />
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className="form-control" id={"companyName"} name={"companyName"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                    <label htmlFor="companyName">Nom de l'entreprise</label>
                </div>
                <FormError form={formik} field={'companyName'} />
            </div>
        </>}
        <button type="button" onClick={() => formik.submitForm()} className="btn btn-primary w-100">
            {formik.isSubmitting ? <Loader size="sm" /> : <><i className="bi bi-check"></i> Enregistrer</>}
        </button>
    </form>
}

export default ProviderContactAdd;