import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Subscription} from "../../../../../models/subscription";
import * as api from "../../../../../adapters/corporate/provider/subscription";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import ProviderSubscriptionShowInformation from "./information";
import ProviderSubscriptionShowOccurrenceList from "./occurrence/list";
import SubscriptionStatusPlaceholder from "../../../../common/subscription/status/placeholder";
import ProviderSubscriptionShowItemList from "./item/list";
import ProviderSubscriptionCalendar from "./calendar";

const ProviderSubscriptionShow = () => {

    const params = useParams();
    const [subscription, setSubscription] = useState<Subscription>();
    const [error, setError] = useState();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true)
        setError(undefined);
        api.show(Number(params.id))
            .then(r => setSubscription(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [params.id])

    const [tab, setTab] = useState<"items"|"occurrences"|"calendar">("items")

    if (loading){
        return <div className={'container-fluid py-10 text-center'}>
            <Loader />
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!subscription){
        return <div className={'container-fluid py-10 text-center'}>
            <span className={'display-6'}>
                <i className={"bi bi-search"}></i> Abonnement non trouvé
            </span>
        </div>
    }

    return <div className={'container-fluid'}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <ol className="breadcrumb mb-3 fs-xs">
                        <li className="breadcrumb-item">
                            <Link to="/corporate/provider/subscription">Abonnement</Link>
                        </li>
                        <li className="breadcrumb-item">
                            {subscription.account.user.email}
                        </li>
                    </ol>
                </div>
                <div className="col-auto">
                    <SubscriptionStatusPlaceholder status={subscription.status} />
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <ProviderSubscriptionShowInformation subscription={subscription} />
        </div>
        <div className="col-12 mb-3">
            <div className="mb-2 mb-lg-0 border-bottom-lg">
                <nav className="nav nav-tabs nav-overflow fs-xs border-bottom border-bottom-lg-0">
                    <button onClick={() => setTab('items')} className={"nav-link text-uppercase" + (tab === 'items' ? " active " : "")}>
                        Articles
                    </button>
                    <button onClick={() => setTab('occurrences')} className={"nav-link text-uppercase" + (tab === "occurrences" ? " active " : "")}>
                        Occurrences
                    </button>
                    <button onClick={() => setTab('calendar')} className={"nav-link text-uppercase" + (tab === "calendar" ? " active " : "")}>
                        Calendrier
                    </button>
                </nav>
            </div>
        </div>
        {tab === "items" && <ProviderSubscriptionShowItemList subscription={subscription} />}
        {tab === "occurrences" && <ProviderSubscriptionShowOccurrenceList subscription={subscription} />}
        {tab === "calendar" && <ProviderSubscriptionCalendar subscription={subscription} />}
    </div>
}

export default ProviderSubscriptionShow;