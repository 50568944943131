import React from "react";
import {Sectors} from "../../../../../models/sector";
import {sectors as allSectors} from "../../../../../utils/sectors";
import {useNavigate} from "react-router-dom";
const ProviderSearchFilter = ({sort, setSort, type, sectors, setSectors}: {sort: 'ranking'|'trending'|'latest', setSort: React.Dispatch<any>, type: 'supplier'|'product'|'affiliation'|'assignment', sectors: Sectors, setSectors: React.Dispatch<any>}) => {

    const navigate = useNavigate();

    return <>
        <div className="col-12">
            <h6 className={'fw-semibold'}>
                Rechercher des
            </h6>
            <div className="form-check">
                <input key={type} defaultChecked={type === 'supplier'} className="form-check-input" type="checkbox" name="type" id="supplier" onChange={() => navigate('/corporate/provider/search/supplier')} />
                <label className="form-check-label" htmlFor="supplier">
                    Entreprises
                </label>
            </div>
            <div className="form-check">
                <input key={type} defaultChecked={type === 'assignment'} className="form-check-input" type="checkbox" name="type" id="assignment" onChange={() => navigate('/corporate/provider/search/assignment')} />
                <label className="form-check-label" htmlFor="assignment">
                    Annonces
                </label>
            </div>
            <div className="form-check">
                <input key={type} defaultChecked={type === 'product'} className="form-check-input" type="checkbox" name="type" id="product" onChange={() => navigate('/corporate/provider/search/product')} />
                <label className="form-check-label" htmlFor="product">
                    Produits / Services
                </label>
            </div>
            <div className="form-check">
                <input key={type} defaultChecked={type === 'affiliation'} className="form-check-input" type="checkbox" name="type" id="affiliation" onChange={() => navigate('/corporate/provider/search/affiliation')} />
                <label className="form-check-label" htmlFor="affiliation">
                    Programmes d'affiliations
                </label>
            </div>
        </div>
        <hr/>
        <div className="col-12">
            <h6 className={'fw-semibold'}>
                Trier par
            </h6>
            <div className="form-check">
                <input defaultChecked={sort === 'ranking'} key={sort} className="form-check-input" type="checkbox" name="sort" id="ranking" onChange={() => setSort('ranking')} />
                <label className="form-check-label" htmlFor="ranking">
                    Classement
                </label>
            </div>
            <div className="form-check">
                <input defaultChecked={sort === 'trending'} key={sort} className="form-check-input" type="checkbox" name="sort" id="trending" onChange={() => setSort('trending')} />
                <label className="form-check-label" htmlFor="trending">
                    Populaires
                </label>
            </div>
            <div className="form-check">
                <input defaultChecked={sort === 'latest'} key={sort} className="form-check-input" type="checkbox" name="sort" id="latest" onChange={() => setSort('latest')} />
                <label className="form-check-label" htmlFor="latest">
                    Nouveautés
                </label>
            </div>
        </div>
        <hr/>
        <div className="col-12">
            <h6 className={'fw-semibold'}>
                Sécteurs
            </h6>
            {allSectors.map(sector => <div className="form-check">
                <input
                    checked={sectors.map(s => s.id).includes(sector.id)}
                    className="form-check-input" type="checkbox" name={"sectors"}
                    id={`sec_${sector.id}`}
                    onChange={e => {
                        if (e.target.checked){
                            setSectors((prev: Sectors) => [...prev, sector])
                        }else{
                            setSectors((prev: Sectors) => [...prev.filter(p => p.id !== sector.id)])
                        }
                    }}
                />
                <label className="form-check-label" htmlFor={`sec_${sector.id}`}>
                    {sector.name}
                </label>
            </div>)}
        </div>
    </>
}

export default ProviderSearchFilter;