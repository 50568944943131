import React, {useEffect, useState} from "react";
import * as api from "../../../adapters/quote";
import * as models from "../../../models/quote";
import ErrorCard from "../../error";
import QuoteAddressShipping from "./shipping";
import QuoteAddressBilling from "./billing";

const QuoteAddresses = ({quote, setQuote}: {quote: models.Quote, setQuote: React.Dispatch<any>}) => {

    const {shippable, uuid} = quote;

    const [error, setError] = useState(null);
    const [billingSameAsShipping, setBillingSameAsShipping] = useState(true);
    const [submitting, setSubmitting] = useState(false)

    useEffect(() => {
        if (billingSameAsShipping && quote.shipping?.id && quote.shipping.id !== quote.billing?.id){
            api.billing(uuid, quote.shipping?.id)
                .then(r => setQuote((prev: models.Quote) => ({...prev, billing: r.data})))
        }
    }, [quote.shipping?.id, billingSameAsShipping])

    return <div className={"col-12"}>
        {error && <ErrorCard error={error} />}
        {shippable && <>
            <QuoteAddressShipping quote={quote} setQuote={setQuote} />
            {quote.shipping && <>
                <div className="col-12 mb-3">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" checked={billingSameAsShipping} id={"billingSameAsShipping"} onChange={e => setBillingSameAsShipping(e.target.checked)} />
                        <label className="form-check-label" htmlFor="billingSameAsShipping">
                            L'Adresse de facturation est identique à l'adresse de livraison
                        </label>
                    </div>
                </div>
            </>}
        </>}
        {(!shippable || !billingSameAsShipping) && <QuoteAddressBilling quote={quote} setQuote={setQuote} />}
    </div>
}

export default QuoteAddresses;