import {Quote, QuoteRows} from "../../../../../../models/quote";
import React, {useEffect, useState} from "react";
import * as api from "../../../../../../adapters/corporate/supplier/quote/row";
import Loader from "../../../../../../utils/loader";
import SupplierQuoteRowShow from "../show";
import SupplierQuoteRowAdd from "../add";
import CommonQuoteSummary from "../../../../common/quote/summary";
import ErrorCard from "../../../../../error";
import ModalComponent from "../../../../../modal";

const SupplierQuoteRowList = ({quote}: {quote: Quote}) => {

    const [loading, setLoading] = useState(true)
    const [payload, setPayload] = useState<{count: number, rows: QuoteRows}>({count: 0, rows: []})
    const [error, setError] = useState(null)
    const [summaryKey, setSummaryKey] = useState(0);
    const [showAddModal, setShowAddModal] = useState(false)

    useEffect(() => {
        setLoading(true)
        api.list(quote.id)
            .then(r => setPayload(r.data))
            .catch(reason => setError(reason))
            .finally(() => setLoading(false))
    }, [quote.id])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    return <>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h6 className={'mb-0'}>
                                Détail de la prestation
                            </h6>
                        </div>
                        {["Pending"].includes(quote.status) && <div className="col-auto">
                            <button className={"btn btn-xs btn-primary"} onClick={() => setShowAddModal(true)}>
                                <i className={'bi bi-plus-circle'}></i> Ajouter une ligne
                            </button>
                        </div>}
                    </div>
                </div>
                <div className="card-body">
                    <div className={'col-12 mb-3'}>
                        <div className="table-responsive">
                            <table className={"table"}>
                                <thead>
                                <tr>
                                    <th>
                                        Produit
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        PU HT
                                    </th>
                                    <th>
                                        Tax
                                    </th>
                                    <th>
                                        PU TTC
                                    </th>
                                    <th>
                                        Quantité
                                    </th>
                                    <th>
                                        Total TTC
                                    </th>
                                    <th>

                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {payload.rows.length ? payload.rows.map((r, targetIndex) => <SupplierQuoteRowShow key={targetIndex} quote={quote} setRow={updatedRow => {
                                    setPayload(prev => ({...prev,
                                        rows: prev.rows.map((row, index) => index === targetIndex ? updatedRow : row)
                                    }))
                                    setSummaryKey(prev => prev + 1)
                                }} row={r} />) : <tr className={"list-group-item"}>
                                    <td colSpan={6}><i className={'bi bi-search'}></i> Aucun résultat</td>
                                </tr>}
                                </tbody>
                            </table>
                            {error && <ErrorCard error={error} />}
                        </div>
                    </div>
                </div>
                {showAddModal && <ModalComponent size={"modal-lg"} title={"Ajouter une ligne"} show={showAddModal} onHide={() => setShowAddModal(false)}>
                    <SupplierQuoteRowAdd handleSuccess={row => {
                        setPayload(prev => ({count: prev.count + 1, rows: [...prev.rows, row]}))
                        setSummaryKey(prev => prev + 1)
                        setShowAddModal(false)
                    }} quote={quote}/>
                </ModalComponent>}
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Récapitulatif du devis
                    </h6>
                </div>
                <div className="card-body">
                    <CommonQuoteSummary quote={quote} displayFeeData={true} displayPriceData={true} key={summaryKey} />
                </div>
            </div>
        </div>
        </>
}

export default SupplierQuoteRowList;