import {useFormik} from "formik";
import {Link, useNavigate} from "react-router-dom";
import * as Yup from "yup";
import * as api from "../../../../../adapters/corporate/supplier/webhook";
import React, {useState} from "react";
import FormError from "../../../../../utils/formError";
import {webhook_scope_options} from "../../../../../constants/webhook";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import {Webhook} from "../../../../../models/webhook";

const SupplierWebhookUpdate = ({webhook, setWebhook}: {webhook: Webhook, setWebhook: React.Dispatch<any>}) => {

    const [error, setError] = useState(null)
    const formik = useFormik({
        initialValues: {
            endpoint: webhook.endpoint,
            description: webhook.description,
            scope: webhook.scope
        },
        validationSchema: Yup.object().shape({
            endpoint: Yup.string().url().required(),
            description: Yup.string().nullable(),
            scope: Yup.array().of(Yup.string()).min(1).required(),
        }),
         onSubmit: (values, formikHelpers) => {
            setError(null)
            api.update(webhook.id, values)
                .then(r => setWebhook(r.data))
                .catch(error => setError(error))
                .finally(() => formikHelpers.setSubmitting(false))
         }
    })

    return <div className={'container-fluid'}>
        <div className="col-12 mb-3">
            <ol className="breadcrumb mb-3 fs-xs">
                <Link to={"/corporate/supplier/webhook"} className="breadcrumb-item">
                    Webhooks
                </Link>
                <li className="breadcrumb-item">
                    Mettre à jour
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3">
            {error && <ErrorCard error={error} />}
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input className="form-control" defaultValue={webhook.endpoint} type="text" name={"endpoint"} id={"endpoint"} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                        <label htmlFor="endpoint">
                            Endpoint
                        </label>
                    </div>
                    <FormError form={formik} field={'endpoint'} />
                </div>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input className="form-control" defaultValue={webhook.description} type="text" name={"description"} id={"description"} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                        <label htmlFor="endpoint">
                            Description
                        </label>
                    </div>
                    <FormError form={formik} field={'description'} />
                </div>
                <div className="col-12 mb-3">
                    <h6>Évènements</h6>
                    <FormError form={formik} field={'scope'} />
                    <ul className="list-group list-group-flush">
                        {webhook_scope_options.map(s => <li className={'list-group-item'} key={s.slug}>
                            <div className="form-check">
                                <input className="form-check-input" defaultChecked={webhook.scope.includes(s.slug)} type="checkbox" onChange={formik.handleChange} name={'scope'} value={s.slug} id={s.slug} />
                                    <label className="form-check-label" htmlFor={s.slug}>
                                        <span>{s.slug}</span>
                                        <span className="clearfix"></span>
                                        <span className="form-text">{s.description}</span>
                                    </label>
                            </div>
                        </li>)}
                    </ul>
                </div>
                <button disabled={formik.isSubmitting} className={'btn btn-primary w-100'} type={"submit"}>
                    {formik.isSubmitting ? <Loader size={"sm"} /> : <>Enregistrer</>}
                </button>
            </form>
        </div>
    </div>
}

export default SupplierWebhookUpdate;