import {post, get} from "../../../../xhr";
import encodeQueryData from "../../../../../utils/encodeQueryData";

interface Interface {
    shipping_id?: number
    name?: string,
    countries?: number[]
}

export function list(requestData: {shipping_id: number, query?: string, offset: number})
{
    return get(`corporate/supplier/shipping/area${encodeQueryData(requestData)}`)
}

export function add(requestData: Interface)
{
    return post(`corporate/supplier/shipping/area`, requestData)
}

export function show(area: number)
{
    return get(`corporate/supplier/shipping/area/${area}`)
}

export function edit(area: number, requestData: Interface){
    return post(`corporate/supplier/shipping/area/${area}`, requestData);
}

