import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Subscription} from "../../../../../models/subscription";
import * as api from "../../../../../adapters/corporate/supplier/subscription";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import SupplierSubscriptionShowInformation from "./information";
import SupplierSubscriptionShowOccurrenceList from "./occurrence/list";
import SubscriptionStatusPlaceholder from "../../../../common/subscription/status/placeholder";
import SupplierSubscriptionShowItemList from "./item/list";
import ModalComponent from "../../../../modal";
import SupplierSubscriptionCalendar from "./calendar";

const SupplierSubscriptionShow = () => {

    const params = useParams();
    const [subscription, setSubscription] = useState<Subscription>();
    const [error, setError] = useState();
    const [loading, setLoading] = useState<boolean>(true);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    useEffect(() => {
        setLoading(true)
        setError(undefined);
        api.show(Number(params.id))
            .then(r => setSubscription(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [params.id])

    const [tab, setTab] = useState<"items"|"occurrences"|"calendar">("items")

    const [updating, setUpdating] = useState(false)

    function updateStatus(status: "Paused"|"Active"|"Canceled")
    {
        if (subscription){
            setUpdating(true)
            api.update(subscription.id, {status: status})
                .then(r => setSubscription(prev => ({...prev, ...r.data})))
                .then(() => setShowConfirmModal(false))
                .catch(error => setError(error))
                .finally(() => setUpdating(false))
        }
    }

    if (loading){
        return <div className={'container-fluid py-10 text-center'}>
            <Loader />
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!subscription){
        return <div className={'container-fluid py-10 text-center'}>
            <span className={'display-6'}>
                <i className={"bi bi-search"}></i> Abonnement non trouvé
            </span>
        </div>
    }

    return <div className={'container-fluid'}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <ol className="breadcrumb mb-3 fs-xs">
                        <li className="breadcrumb-item">
                            <Link to="/corporate/supplier/subscription">Abonnement</Link>
                        </li>
                        <li className="breadcrumb-item">
                            {subscription.account.user.email}
                        </li>
                    </ol>
                </div>
                <div className="col-auto">
                    <div className="row">
                        <div className="col-auto">
                            <SubscriptionStatusPlaceholder status={subscription.status} />
                        </div>
                        {["Active", "Incomplete", "Trialing"].includes(subscription.status) && <div className="col-auto">
                            <button className="btn btn-xxs btn-light" disabled={updating} onClick={() => updateStatus('Paused')}>
                                <i className={'bi bi-pause text-warning'}></i> Mettre en pause
                            </button>
                        </div>}
                        {subscription.status === "Paused" && <div className="col-auto">
                            <button className="btn btn-xxs btn-light" disabled={updating} onClick={() => updateStatus('Active')}>
                                <i className={'bi bi-play text-success'}></i> Reprendre
                            </button>
                        </div>}
                        {["Active", "Incomplete", "Trialing", "IncompleteExpired", "PastDue", "UnPaid"].includes(subscription.status) && <div className="col-auto">
                             <button className="btn btn-xxs btn-light"  disabled={updating} onClick={() => setShowConfirmModal(true)}>
                                <i className={'bi bi-x text-danger'}></i> Terminer l'abonnement
                            </button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <SupplierSubscriptionShowInformation subscription={subscription} />
        </div>
        <div className="col-12 mb-3">
            <div className="mb-2 mb-lg-0 border-bottom-lg">
                <nav className="nav nav-tabs nav-overflow fs-xs border-bottom border-bottom-lg-0">
                    <button onClick={() => setTab('items')} className={"nav-link text-uppercase" + (tab === 'items' ? " active " : "")}>
                        Articles
                    </button>
                    <button onClick={() => setTab('occurrences')} className={"nav-link text-uppercase" + (tab === "occurrences" ? " active " : "")}>
                        Occurrences
                    </button>
                    <button onClick={() => setTab('calendar')} className={"nav-link text-uppercase" + (tab === "calendar" ? " active " : "")}>
                        Calendrier
                    </button>
                </nav>
            </div>
        </div>
        {tab === "items" && <SupplierSubscriptionShowItemList subscription={subscription} />}
        {tab === "occurrences" && <SupplierSubscriptionShowOccurrenceList subscription={subscription} />}
        {tab === "calendar" && <SupplierSubscriptionCalendar subscription={subscription} />}
        <ModalComponent
            title={"Confirmer l'arrêt de l'abonnement"}
            show={showConfirmModal}
            onHide={() => setShowConfirmModal(false)}
            footerContent={<button className={'btn btn-light'} onClick={() => setShowConfirmModal(false)}>Annuler</button>}
        >
            <div className="col-12 text-center">
                <p className={"text-center"}>
                    Attention ! Arrêter un abonnement est irréversible.
                </p>
                <button className={'btn btn-danger mx-auto'} onClick={() => updateStatus('Canceled')}>
                    <i className={'bi bi-x'}></i> Arrêter quand même
                </button>
            </div>
        </ModalComponent>
    </div>
}

export default SupplierSubscriptionShow;