import {post, get, destroy} from "../../../../xhr";
import encodeQueryData from "../../../../../utils/encodeQueryData";

export function list(link: number, requestData: {offset: number, query: string})
{
    return get(`/corporate/provider/link/${link}/item${encodeQueryData(requestData)}`)
}

export function add(link: number, requestData: {product_id: number, quantity: number, editable: boolean})
{
    return post(`/corporate/provider/link/${link}/item`, requestData)
}

export function update(link: number, item: number, requestData: {quantity?: number, editable?: boolean})
{
    return post(`/corporate/provider/link/${link}/item/${item}`, requestData)
}

export function _destroy(link: number, item: number)
{
    return destroy(`/corporate/provider/link/${link}/item/${item}`)
}

