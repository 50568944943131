import convertToDecimal from "../../../../../../utils/currencyConverter";
import {Product, SellableProduct} from "../../../../../../models/product";
import {Price, Prices} from "../../../../../../models/price";
import React, {useState} from "react";

const SupplierBundleAddItem = ({item, updateItem, removeItem}: {item: {product: Product & SellableProduct, prices: Prices}, updateItem: (item: {product: Product & SellableProduct, prices: Prices}) => void, removeItem: () => void}) => {

    const [showMenu, setShowMenu] = useState(false)

    function removePrice(index: number){

        if (item.prices.length === 1){
            removeItem();
            return;
        }

        const prices = [...item.prices]
        prices.splice(index, 1);
        updateItem({...item, prices: prices})
    }

    function addPrice(price: Price){
        updateItem({...item, prices: [...item.prices, price]})
    }

    return <div className={"col-12 mb-3"}>
        <div className="card">
            <div className="border border-1">
                <div className="card-header">
                    <div className="row">
                        <div className="col">
                            {item.product.name}
                        </div>
                        <div className="col-auto">
                            <i className={"bi bi-x-circle"} onClick={() => removeItem()}></i>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <ul className={"list-group"}>
                        {item.prices.map((price, index) => <li className={'list-group-item'}>
                            <div className="row">
                                <div className="col">
                                    {convertToDecimal(price.amount, price.currency)} {price.recurring && <>/ {price.recurring.intervalCount} {price.recurring.interval}</>}
                                </div>
                                <div className="col-auto">
                                    <i className={'bi bi-x-circle'} onClick={() => removePrice(index)}></i>
                                </div>
                            </div>
                        </li>)}
                        {!showMenu && <li className={"list-group-item text-decoration-underline cursor-pointer"}
                             onClick={() => setShowMenu(true)}>
                            <i className={"bi bi-plus-circle"}></i> Ajouter un tarif
                        </li>}
                    </ul>
                    {showMenu && <div className={'w-100'}>
                        <div className="form-floating mb-0">
                            <input type="text" autoFocus={true} onFocus={() => setShowMenu(true)} onBlur={() => setShowMenu(false)} className="form-control" name={"query"} />
                            <label htmlFor="query">
                                Ajouter un tarif
                            </label>
                        </div>
                        <div className="position-relative pt-2">
                            <div className={`position-absolute top-0 overflow-auto collapse p-3 shadow border border-1 w-100 bg-white ${showMenu ? "show" : ""}`} style={{maxHeight: 400, zIndex: 10}}>
                                <ul className={"list-group list-group-flush"}>
                                    {item.product.prices.filter(p => !item.prices.map(ip => ip.id).includes(p.id)).length > 0 ? item.product.prices.filter(p => !item.prices.map(ip => ip.id).includes(p.id)).map(price => <li className={"list-group-item list-group-item-action"} onMouseDown={() => addPrice(price)}>
                                        {convertToDecimal(price.amount, price.currency)} {price.recurring && <>/ {price.recurring.intervalCount} {price.recurring.interval}</>}
                                    </li>) : <li className={"text-muted"}>Aucun tarif disponible</li>}
                                </ul>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    </div>
}

export default SupplierBundleAddItem;