
export const reason_labels = {
    return: "Retour ou remplacement",
    undelivered: "Il est écris que l'article a été livré mais ce n'est pas le cas",
    defective: "Article défectueux / Ne fonctionne pas",
    damaged: "Article endommagé",
    late: "Article reçu trop tard",

    return_mismatch: "Différent de ce qui a été commandé",
    return_unwanted: "Je n'en veux pas",
    return_missing: "Il manque des pièces",
    return_else: "Autre chose",

    mismatch_item: "J'ai reçu un article différent de ce qui a été commandé",
    mismatch_description: "L'article ne correspond pas à la description sur le site web",
    unwanted_switch_mind: "J'ai changé d'avis",
    unwanted_mistake: "Acheté par erreur",
}