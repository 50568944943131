import React from "react";

const SubscriptionStatusPlaceholder = ({status, displayCaret}: {status: string, displayCaret?: boolean}) => {

    switch (status){
        case "All":
            return <span className={'badge bg-light text-dark'}>
                    Tous les statuts
                </span>
        case "Active":
            return <span className={'badge bg-success'}>
                    En cours
                </span>
        case "Trialing":
            return <span className={'badge bg-info'}>
                    Période d'essai {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Incomplete":
            return <span className={'badge bg-warning'}>
                    Incomplet {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "IncompleteExpired":
            return <span className={'badge bg-warning'}>
                    Expiré {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "PastDue":
            return <span className={'badge bg-warning'}>
                    En retard {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Canceled":
            return <span className={'badge bg-danger'}>
                   Annulé {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "UnPaid":
            return <span className={'badge bg-danger'}>
                    Non payé {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Paused":
            return <span className={'badge bg-info'}>
                En pause {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
    }

    return <>Invalid status</>
}

export default SubscriptionStatusPlaceholder;