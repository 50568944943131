import * as models from "../../../../../../../models/shipping";
import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../../../adapters/corporate/supplier/shipping/area";
import {Shipping} from "../../../../../../../models/shipping";
import {Offcanvas} from "bootstrap";
import Select from "../../../../../../../utils/select";
import {getCountries} from "../../../../../../../utils/countries";
import FormError from "../../../../../../../utils/formError";
import Loader from "../../../../../../../utils/loader";

const SupplierShippingAreaAdd = ({shipping, handleSuccess}: {shipping: models.Shipping, handleSuccess: (area: models.ShippingArea) => void}) => {

    const formik = useFormik({
        initialValues: {
            name: '',
            countries: []
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            countries: Yup.array().of(Yup.number()).min(1).required(),
        }),
        onSubmit: (values, formikHelpers) => {
            api.add({...values, shipping_id: shipping.id})
                .then(r => handleSuccess(r.data))
                .then(() => formikHelpers.setSubmitting(false))
                .then(() => close())
        }
    })

    function close(){
        let el = document.getElementById("offcanvasShippingAreaAdd")

        if (el){
            (new Offcanvas(el)).hide()
        }
    }

    return <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasShippingAreaAdd"
                aria-labelledby="offcanvasShippingAreaAddLabel">
        <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasShippingAreaAddLabel"><i className={'bi bi-plus-circle'}></i> Ajouter une zone d'expédition</h5>
            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvasShippingAreaAdd" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="text" className={'form-control'} name={"name"} id={"name"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                        <label htmlFor="name">
                            Nom *
                        </label>
                    </div>
                    <FormError form={formik} field={"name"} />
                </div>
                <div className="col-12 mb-3">
                    <Select
                        multiple={true}
                        required={true}
                        label={"Pays *"}
                        options={getCountries()}
                        onChange={o => formik.setFieldValue('countries', o.map(v => v.id))}
                    />
                </div>
                <button className="btn btn-light w-100 mb-3">
                    {formik.isSubmitting ? <Loader /> : <><i className={'bi bi-check'}></i> Valider</>}
                </button>
            </form>
        </div>
    </div>
}

export default SupplierShippingAreaAdd;