import {Partnership, PartnershipRequest, PartnershipRequests} from "../../../../../../models/partnership";
import {useAppSelector} from "../../../../../../hooks";
import {CorporateAccount} from "../../../../../../models/account";
import React, {useState} from "react";
import * as api from "../../../../../../adapters/corporate/common/partnership/request";
import Loader from "../../../../../../utils/loader";

const PartnershipRequestAccept = ({partnershipRequest, onSuccess}: {partnershipRequest: PartnershipRequest,onSuccess: (request: Partnership) => void}) => {

    const activeAccount = useAppSelector(state => state.auth.user?.activeAccount) as CorporateAccount
    const authOrganization = activeAccount.organization;
    const [accessSale, setAccessSale] = useState<'Granted'|'Denied'|'Requested'>('Denied')
    const [accessAffiliation, setAccessAffiliation] = useState<'Granted'|'Denied'|'Requested'>('Denied')
    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState(null)

    function accept(){
        api.accept(partnershipRequest.id, {accessSale: accessSale, accessAffiliation: accessAffiliation})
            .then(r => onSuccess(r.data))
            .catch(e => setError(e))
            .finally(() => setSubmitting(false))
    }

    return <>
        {authOrganization.type === 'supplier' && <>
            <div className="col-12 mb-3">
                <div className="row align-items-center">
                    <div className="col">
                        <span className="fw-semibold">
                            Accorder l'accès aux produits / services en vente direct ?
                        </span>
                    </div>
                    <div className="col-auto">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <button className={`btn btn-sm ${accessSale === 'Granted' ? 'btn-success' : 'btn-light'}`} onClick={() => setAccessSale('Granted')}>
                                    Oui
                                </button>
                            </div>
                            <div className="col-6">
                                <button className={`btn btn-sm ${accessSale === 'Denied' ? 'btn-success' : 'btn-light'}`} onClick={() => setAccessSale('Denied')}>
                                    Non
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="col-12 mb-3">
                <div className="row align-items-center">
                    <div className="col">
                        <span className="fw-semibold">
                            Accorder l'accès aux programmes d'affiliations ?
                        </span>
                    </div>
                    <div className="col-auto">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <button className={`btn btn-sm ${accessAffiliation === 'Granted' ? 'btn-success' : 'btn-light'}`} onClick={() => setAccessAffiliation('Granted')}>
                                    Oui
                                </button>
                            </div>
                            <div className="col-6">
                                <button className={`btn btn-sm ${accessAffiliation === 'Denied' ? 'btn-success' : 'btn-light'}`} onClick={() => setAccessAffiliation('Denied')}>
                                    Non
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>}
        {authOrganization.type === 'provider' && <>
            <div className="col-12 mb-3">
                <div className="row align-items-center">
                    <div className="col">
                        Demander l'accès aux produits / services en vente direct ?
                    </div>
                    <div className="col-auto">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <button className={`btn btn-sm ${accessSale === 'Requested' ? 'btn-success' : 'btn-light'}`} onClick={() => setAccessSale('Requested')}>
                                    Oui
                                </button>
                            </div>
                            <div className="col-6">
                                <button className={`btn btn-sm ${accessSale === 'Denied' ? 'btn-success' : 'btn-light'}`} onClick={() => setAccessSale('Denied')}>
                                    Non
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="col-12 mb-3">
                <div className="row align-items-center">
                    <div className="col">
                        Demander l'accès aux programmes d'affiliations ?
                    </div>
                    <div className="col-auto">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <button className={`btn btn-sm ${accessAffiliation === 'Requested' ? 'btn-success' : 'btn-light'}`} onClick={() => setAccessAffiliation('Requested')}>
                                    Oui
                                </button>
                            </div>
                            <div className="col-6">
                                <button className={`btn btn-sm ${accessAffiliation === 'Denied' ? 'btn-success' : 'btn-light'}`} onClick={() => setAccessAffiliation('Denied')}>
                                    Non
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>}
        <button className={'btn btn-sm w-100 btn-success'} onClick={() => accept()} disabled={submitting}>
            {submitting ? <Loader size={'sm'}/> : <>Enregistrer et accepter <i className={'bi bi-check'}></i></>}
        </button>
    </>
}

export default PartnershipRequestAccept;