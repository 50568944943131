import {Product} from "../../../../../models/product";
import React, {useState} from "react";
import ReactQuill from "react-quill";
import * as api from "../../../../../adapters/corporate/supplier/product";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";

const SupplierProductDescription = ({product, setProduct}: {product: Product, setProduct: React.Dispatch<any>}) => {

    const [description, setDescription] = useState(product.longDescription);
    const [updating, setUpdating] = useState(false)
    const [error, setError] = useState(null)

    function handleChange(){
        setUpdating(true)
        api.update(product.id, {longDescription: description})
            .then(r => setProduct((prev: Product) => ({...prev, longDescription: description})))
            .catch(e => setError(e))
            .finally(() => setUpdating(false))
    }

    return <div className="col-12 mb-3" style={{minHeight: 600}}>
        <div className="col-12 mb-3">
            <button type={"button"} className={'btn btn-primary'} onClick={() => handleChange()} disabled={updating || description === product.description}>
                {updating && <Loader size={'sm'} />} Mettre à jour
            </button>
        </div>
        {error && <ErrorCard error={error} />}
        <ReactQuill style={{height: 600}} value={description} onChange={value => setDescription(value)} />
    </div>
}

export default SupplierProductDescription;