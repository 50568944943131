import {Quote} from "../../../../../../models/quote";
import SupplierQuoteConditionUpdate from "../update";
import React, {useState} from "react";
import ModalComponent from "../../../../../modal";

const QuoteConditionShow = ({quote, setQuote}: {quote: Quote, setQuote: React.Dispatch<any>}) => {

    const [showUpdateModal, setShowUpdateModal] = useState(false)

    return <div className={'card border border-1'}>
        <div className="card-header">
            <div className="row">
                <div className="col">
                    <h6>
                        Conditions du devis
                    </h6>
                </div>
                {quote.status === "Pending" && <div className="col-auto">
                    <button className="btn btn-xxs btn-primary" onClick={() => setShowUpdateModal(true)}>
                        <i className={'bi bi-pencil'}></i> Mettre à jour
                    </button>
                </div>}
            </div>
        </div>
        <div className="card-body">
            {quote.status === "Published" && <div className={"col-12 mb-3"}>
                <div className="alert alert-info">
                    <i className={"bi bi-info-circle"}></i> Repasser le statut en brouillon pour modifier.
                </div>
            </div>}
            <table className="table-borderless">
                <div className={"row"}>
                    <div className={"col-12 mb-3"}>
                        <p className={"fw-semibold mb-1"}>
                            Date d'expiration
                        </p>
                        <p className={"mb-0"}>
                            {new Date(quote.condition.expiredAt).toLocaleDateString()}
                        </p>
                    </div>
                    <div className={"col-12 mb-3"}>
                        <p className={"fw-semibold mb-1"}>
                            Commission apporteur
                        </p>
                        <p className={"mb-0"} style={{whiteSpace: "pre-wrap"}}>
                            {quote.condition.fee}%
                        </p>
                    </div>
                    <div className={"col-12 mb-3"}>
                        <p className={"fw-semibold mb-1"}>
                            Accompte
                        </p>
                        <p className={"mb-0"}>
                            {quote.condition.deposit}%
                        </p>
                    </div>
                    <div className={"col-12 mb-3"}>
                        <p className={"fw-semibold mb-1"}>
                            Délais de réalisation / livraison
                        </p>
                        <p className={"mb-0"} style={{whiteSpace: "pre-wrap"}}>
                            {quote.condition.delay}
                        </p>
                    </div>
                </div>
            </table>
        </div>
        {quote.status === "Pending" && showUpdateModal && <ModalComponent size={'modal-lg'} title={"Mettre à jour les conditions"} show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
            <SupplierQuoteConditionUpdate quote={quote} onSuccess={condition => {
                setQuote((prev: Quote) => ({...prev, condition: condition}))
                setShowUpdateModal(false)
            }}/>
        </ModalComponent>}
    </div>
}

export default QuoteConditionShow