import React from "react";
import ProposalPaymentForm from "./form";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {Proposal} from "../../../../../../models/proposal";
import {PaymentMethod} from "../../../../../../models/paymentMethod";

const stripePromise = loadStripe("pk_live_51OHqm6H46Kmwvu6gtsFGdaMUw4mgkXMquclVtkENiHwv4DN4s00c3i7pHXP5db9Vo6SS0omYqRIvDFiHC7oxtWPx00GupdBdOQ");

const ProposalPayment = ({proposal, paymentMethod, handlePaymentSucceed}: {proposal: Proposal, paymentMethod?: PaymentMethod, handlePaymentSucceed: (proposal: Proposal) => void}) => {

    return <Elements options={{appearance: {theme: 'stripe'}}} stripe={stripePromise}>
        <ProposalPaymentForm proposal={proposal} paymentMethod={paymentMethod} handlePaymentSucceed={handlePaymentSucceed} />
    </Elements>
}

export default ProposalPayment