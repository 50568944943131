import React, {useEffect, useState} from "react";
import {FetchParams, Payload} from "../../../../../models/global";
import {Applications} from "../../../../../models/application";
import * as api from "../../../../../adapters/corporate/supplier/application";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import Pagination from "../../../../../utils/pagination";
import SearchInput from "../../../../../utils/searchInput";
import {Link} from "react-router-dom";
import {getLogoUrl} from "../../../../../utils/image";
import {Assignment} from "../../../../../models/assignment";
import SupplierApplicationListItem from "./item";

const SupplierApplicationList = ({assignment, status}: {assignment?: Assignment, status?: "Pending"|'Accepted'|'Refused'}) => {

    const [loading, setLoading] = useState(true);
    const [payload, setPayload] = useState<Payload<Applications>>({count: 0, rows: []});
    const [fetchParams, setFetchParams] = useState<FetchParams>({offset: 0})
    const [error, setError] = useState(null);

    useEffect(() => {
        api.list({...fetchParams, assignment_id: assignment?.id})
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    return <>
        <div className="col-12 mb-3 flex-grow-0">
            <SearchInput placeholder={"Rechercher une candidature..."} onChange={handleQueryChange} size={"default"} />
        </div>
        <div className="row">
            {payload.rows.map(r => <SupplierApplicationListItem item={r} />)}
        </div>
        <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
    </>
}

export default SupplierApplicationList;