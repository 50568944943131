import React from "react";
import {useAppSelector} from "../../../../../../../hooks";
import * as models from "../../../../../../../models/chat";

const ChatShowHeaderPrivate = ({chat}: {chat: models.Chat & models.PrivateChat}) => {

    const auth = useAppSelector(state => state.auth);

    return <div className="row align-items-center">
        <div className="col-auto">
            <div className="avatar-group">
                {chat.accounts.filter(p => p.id !== auth.user?.activeAccount.id).map(p => <span>{p.firstname} {p.lastname}</span>)}
            </div>
        </div>
    </div>
}

export default ChatShowHeaderPrivate;