import encodeQueryData from "../../../../utils/encodeQueryData";
import {post, get} from "../../../xhr";

interface Interface {
    name?: string,
    details?: string,
    default?: boolean
}

export function list(requestData: { query: string, offset: number}){
    return get(`corporate/supplier/term${encodeQueryData(requestData)}`)
}

export function show(term: number){
    return get(`corporate/supplier/term/${term}`)
}

export function add(requestData: Interface){
    return post(`corporate/supplier/term`, requestData)
}

export function update(term: number, requestData: Interface){
    return post(`corporate/supplier/term/${term}`, requestData);
}

