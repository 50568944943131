import * as models from "../../../../../../../models/subscription";
import React, {useEffect, useState} from "react";
import * as api from "../../../../../../../adapters/corporate/provider/subscription/item";
import SearchInput from "../../../../../../../utils/searchInput";
import ErrorCard from "../../../../../../error";
import Loader from "../../../../../../../utils/loader";
import ProviderSubscriptionShowItemListItem from "./item";

const ProviderSubscriptionShowItemList = ({subscription}: {subscription: models.Subscription}) => {

    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string}>({offset: 0, query: ''})
    const [payload, setPayload] = useState<{count: number, rows: models.SubscriptionItems}>({count: 0, rows: []})
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        setError(null)
        api.list(subscription.id, fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [subscription.id, fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={'col-12 mb-3'}>
        <div className="row align-items-center">
            <div className="col">
                <SearchInput placeholder={"Rechercher un article..."} onChange={handleQueryChange} size={"sm"} />
            </div>
        </div>
        <div className={"col-12 mb-3 text-center"}>{loading ? <Loader size={"sm"} /> : <>{payload.count} résultats</>}</div>
        {error && <ErrorCard error={error} />}
        <table className={'table'}>
            <thead>
            <tr>
                <th>Produit</th>
                <th>Quantité</th>
                <th>Tarif</th>
            </tr>
            </thead>
            <tbody>
                {payload.rows.map(r => <ProviderSubscriptionShowItemListItem item={r} key={r.id} />)}
            </tbody>
        </table>
    </div>
}

export default ProviderSubscriptionShowItemList;