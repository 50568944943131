import React from 'react';

interface ModalProps {
    title: string;
    size?: 'modal-sm' | 'modal-lg' | 'modal-xl' | 'modal-fullscreen';
    show: boolean;
    onHide: () => void;
    children: React.ReactNode;
    footerContent?: React.ReactNode
}

const ModalComponent: React.FC<ModalProps> = ({ title, size, show, onHide, children, footerContent }) => {
    const sizeClass = size ? `${size}` : '';

    return (
        <>
            {show && <div className="modal-backdrop fade show" onClick={onHide}></div>}
            <div className={`modal ${show ? 'd-block' : 'd-none'}`} tabIndex={-1} role="dialog" style={{ display: show ? 'block' : 'none' }}>
                <div className={`modal-dialog modal-dialog-scrollable ${sizeClass}`} role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{title}</h5>
                            <button type="button" className="btn btn-xxs" aria-label="Close" onClick={onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {children}
                        </div>
                        {footerContent && <div className={"modal-footer"}>
                            {footerContent}
                        </div>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ModalComponent;
