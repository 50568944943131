import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query: string, offset: number}){
    return get(`corporate/supplier/bundle${encodeQueryData(requestData)}`);
}

export function show(bundle: number){
    return get(`corporate/supplier/bundle/${bundle}`);
}

export function add(requestData: {name: string, description: string}){
    return post(`corporate/supplier/bundle`, requestData);
}

export function update(bundle: number, requestData: {active?: boolean, name?: string, description?: string}){
    return post(`corporate/supplier/bundle/${bundle}`, requestData);
}
