import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import * as api from "../../adapters/shop";
import Loader from "../../utils/loader";
import ShopNavHeader from "./nav/header";
import ShopNavFooter from "./nav/footer";
import Shop404 from "./404";
import ShopProductList from "./product/list";
import ShopProductShow from "./product/show";
import ShopCart from "./cart";
import {ShopDetails} from "../../models/shop";
import {useAppDispatch} from "../../hooks";
import cartSlice from "../../reducers/cart";

const ShopShow = ({tab}: {tab: "home"|"cart"|"product"}) => {

    const dispatch = useAppDispatch();
    const {uuid} = useParams();
    const [loading, setLoading] = useState(!!uuid);
    const [shopDetails, setShopDetails] = useState<ShopDetails>();

    useEffect(() => {

        function getLocalCartId(_uuid: string){
            const cart = localStorage.getItem(`shop-${_uuid}`);
            if (cart)
            {
                return JSON.parse(cart).id;
            }

            return null;
        }

        if (uuid){
            setLoading(true)

            api.show(uuid, {local_cart_id: getLocalCartId(uuid)})
                .then(r => {
                    setShopDetails(r.data)
                    if (r.data.cart){
                        dispatch(cartSlice.actions.setCart(r.data));
                    }
                })
                .then(() => setLoading(false))
        }
    } ,[uuid])

    const Content = () => {

        if (!uuid || !shopDetails){
            return <></>
        }

        switch (tab){
            case "home":
                return <ShopProductList uuid={uuid} country={shopDetails.country} currency={shopDetails.currency} />
            case "product":
                return <ShopProductShow uuid={uuid} shopDetails={shopDetails} />
            case "cart":
                return <ShopCart shopDetails={shopDetails} uuid={uuid} />
        }

        return <Shop404 />
    }

    if (!uuid){
        return <div className={'col-12 py-10 text-center'}>
            <h6>
                <i className={'bi bi-exclamation-circle'}></i> Lien invalide.
            </h6>
        </div>
    }

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (!shopDetails){
        return <div className={'col-12 py-10 text-center'}>
            <h6>
                <i className={'bi bi-search'}></i> Boutique non trouvé
            </h6>
        </div>
    }


    return <div className={'container-fluid h-100 d-flex flex-column px-0'}>
        <ShopNavHeader uuid={uuid} country={shopDetails.country} currency={shopDetails.currency} provider={shopDetails.provider} />
            <div className="container mx-auto flex-grow-1">
                <Content />
            </div>
        <ShopNavFooter setCurrency={currency => setShopDetails(prev => ({...prev!, currency: currency}))} setCountry={country => setShopDetails(prev => ({...prev!, country: country}))} />
    </div>
}

export default ShopShow;