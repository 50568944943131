import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query?: string, offset: number}){
    return get(`/corporate/provider/pack${encodeQueryData(requestData)}`);
}

export function show(pack: number)
{
    return get(`/corporate/provider/pack/${pack}`)
}
