import React, {useEffect, useState} from "react";
import * as models from "../../../../../../../../../models/shipping";
import * as api from "../../../../../../../../../adapters/corporate/supplier/shipping/price";
import Pagination from "../../../../../../../../../utils/pagination";
import Loader from "../../../../../../../../../utils/loader";
import SupplierShippingRatePriceAdd from "../add";
import SupplierShippingRatePriceEdit from "../edit";
import convertToDecimal from "../../../../../../../../../utils/currencyConverter";

const SupplierShippingRatePriceList = ({rate}: {rate: models.ShippingRate}) => {

    const [loading, setLoading] = useState(true)
    const [payload, setPayload] = useState<{count: number, rows: models.ShippingRatePrices}>({count: 0, rows: []})
    const [fetchParams, setFetchParams] = useState<{offset: number}>({offset: 0})

    useEffect(() => {
        setLoading(true)
        api.list({...fetchParams, rate_id: rate.id})
            .then(r => setPayload(r.data))
            .then(() => setLoading(false))
    }, [fetchParams, rate])

    return <div className="col-12 mb-3">
        <div className="card border border-1">
            <div className="card-header">
                <div className="row align-items-center">
                    <div className="col">
                        <h6>
                            Tarifs
                        </h6>
                    </div>
                    <div className="col-auto">
                        <button className={'btn btn-light'} data-bs-toggle={"offcanvas"} data-bs-target={"#offcanvasShippingRatePriceAdd"}>
                            <i className={'bi bi-plus-circle'}></i> Ajouter un tarif
                        </button>
                    </div>
                </div>

            </div>
            <div className="card-body">
                <div className="col-12 mb-3 text-center">
                    {loading ? <Loader /> : <>{payload.count} résultats</>}
                </div>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>
                                Prix
                            </th>
                            <th>
                                Limite de poids
                            </th>
                            <th>
                                Limite de volume
                            </th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {payload.rows.map(p => <tr>
                            <td>
                                {convertToDecimal(p.amount, p.currency)}
                            </td>
                            <td>
                                {p.weightLimit ? <>{p.weightLimit.toLocaleString("fr-FR")} grammes</> : <i className={'bi bi-x'}></i>}
                            </td>
                            <td>
                                {p.volumeLimit ?  <>{p.volumeLimit.toLocaleString("fr-FR")} cm cube</> : <i className={'bi bi-x'}></i>}
                            </td>
                            <td>
                                <button className="btn btn-xxs btn-dark" data-bs-toggle={"offcanvas"} data-bs-target={`#offcanvasShippingRatePriceEdit_${p.id}`}>
                                    <i className={'bi bi-pencil'}></i> Modifier
                                </button>
                            </td>
                            <td>
                                <button className="btn btn-xxs">
                                    Autres devises <i className={'bi bi-arrow-right'}></i>
                                </button>
                            </td>
                        </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        </div>
        {payload.rows.map((price, index) => <SupplierShippingRatePriceEdit key={index} price={price} handleSuccess={updated => {
            const rowsCopy = [...payload.rows];
            rowsCopy[index] = updated;
            setPayload(prev => ({...prev, rows: rowsCopy}));
        }} />)}
        <SupplierShippingRatePriceAdd rate={rate} handleSuccess={p => setPayload(prev => ({count: prev.count + 1, rows: [p, ...prev.rows]}))} />
    </div>

}

export default SupplierShippingRatePriceList;