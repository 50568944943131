import {useAppSelector} from "../../../../hooks";
import {CorporateAccount} from "../../../../models/account";
import SupplierDashboard from "../../supplier/dashboard";
import ProviderDashboard from "../../provider/dashboard";
import React from "react";
import DashboardPartnership from "./partnership";
import {Link} from "react-router-dom";

const Dashboard = () => {

    const activeAccount = useAppSelector(state => state.auth.user?.activeAccount) as CorporateAccount
    const authOrganization = activeAccount.organization;

    return <div className={'container mx-auto'}>
        <div className="row align-items-center">
            <div className="col mb-5">
                <h2 className="mb-0">
                    Heureux de vous revoir {activeAccount.firstname}
                </h2>
                <div className="col">
                    <span><i className={"bi bi-trophy"}></i> {activeAccount.organization.ranking?.score || 0}</span>
                    <span className={'mx-2'}>|</span>
                    <DashboardPartnership />
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6 mb-3 flex-grow-1">
                <div className="card shadow h-100">
                    <div className="card-body">
                        <h6>Des questions ? Un feedback ?</h6>
                        <p className={"mb-0"}>
                            Contactez nous à <span className={'fw-semibold text-dark'}>contact@varar.co</span> ou <Link to={"https://meetings-eu1.hubspot.com/varar"} target={'_blank'}>prendre rendez-vous</Link>
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-6 mb-3 flex-grow-1">
                <Link to={'/corporate/sponsorship'} className="card shadow h-100">
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="row">
                                    <div className="col">
                                        <h6>Parrainage</h6>
                                    </div>
                                    <div className="col-auto">
                                        <i className={"bi bi-arrow-right"}></i>
                                    </div>
                                </div>
                                <p className={"mb-0 text-muted"}>
                                    Gagnez jusqu'à <span className="fw-semibold">5 000€</span> par filleul
                                </p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
        <div className="row">
            {!authOrganization.logoName && <div className="col-md mb-3">
                <Link to={'/corporate/setting/organization'} className="card shadow">
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col">
                                <h6>Completez votre profil</h6>
                                <p className={"mb-0 text-muted"}>
                                    Ajoutez une photo de profil ou un logo.
                                </p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>}
        </div>

        {authOrganization.type === "supplier" && <SupplierDashboard />}
        {authOrganization.type === "provider" && <ProviderDashboard />}
    </div>
}

export default Dashboard;