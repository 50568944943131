import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query?: string, offset: number}){
    return get(`/corporate/supplier/pack${encodeQueryData(requestData)}`);
}

export function show(pack: number)
{
    return get(`/corporate/supplier/pack/${pack}`)
}

export function add(requestData: {name: string})
{
    return post(`/corporate/supplier/pack`, requestData)
}

export function update(pack: number, requestData: {name?: string, activated?: boolean})
{
    return post(`/corporate/supplier/pack/${pack}`, requestData)
}


