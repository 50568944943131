import encodeQueryData from "../../../../../utils/encodeQueryData";
import {post, get} from "../../../../xhr";

interface Interface {
    countryId?: number
    city?: string
    zip?: string
    addressLine1?: string
    addressLine2?: string,
    shippingId?: number,
}

export function list(requestData: { query: string, offset: number, shipping?: number }){
    return get(`corporate/supplier/shipping/address${encodeQueryData(requestData)}`)
}

export function show(address: number){
    return get(`corporate/supplier/shipping/address/${address}`)
}

export function add(requestData: Interface){
    return post(`corporate/supplier/shipping/address`, requestData)
}

export function edit(address: number, requestData: Interface){
    return post(`corporate/supplier/shipping/address/${address}/update`, requestData);
}

