import {Parcel} from "../../../../../../models/order";
import React, {useEffect, useState} from "react";
import * as api from "../../../../../../adapters/corporate/supplier/parcel";
import ParcelStatusPlaceholder from "../../../../../common/parcel/status/placeholder";

const ParcelStatusDropdown = ({parcel, setParcel}: { parcel: Parcel, setParcel: React.Dispatch<any> }) => {

    function updateStatus(status: "Processing"|"Shipped"|"Delivered"|"OnHold"|"Canceled"){
        api.update(parcel!.id, {status: status})
            .then(r => setParcel(r.data))
    }

    const [allowedStatuses, setAllowedStatuses] = useState<("Processing"|"Shipped"|"Delivered"|"OnHold"|"Canceled")[]>([]);

    useEffect(() => {

        switch (parcel.status){
            case "OnHold":
                setAllowedStatuses(['Processing', 'Shipped', 'Delivered', "Canceled"])
                break;
            case "Confirmed":
                setAllowedStatuses(['Processing', "Canceled", "OnHold"])
                break;
            case "Processing":
                setAllowedStatuses(['Shipped', "Canceled", "OnHold"])
                break;
            case "Shipped":
                setAllowedStatuses(['Delivered', "Canceled", "OnHold"])
                break;
            case "Canceled":
            case "Delivered":
            case "Pending":
                setAllowedStatuses([])
                break;
        }

    }, [parcel.status])

    return <div className="dropdown">
        <button className="btn btn-xs" type="button"
                disabled={allowedStatuses.length === 0}
                id="range" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
            <ParcelStatusPlaceholder status={parcel.status} displayCaret={allowedStatuses.length > 0} />
        </button>
        <ul className="dropdown-menu">
            {allowedStatuses.map(s => <li key={s} className={'dropdown-item'} onClick={() => updateStatus(s)}>
                <ParcelStatusPlaceholder status={s} />
            </li>)}
        </ul>
    </div>
}

export default ParcelStatusDropdown;