import React, {useEffect, useState} from "react";

const Loader: React.FC<{color?: "info"|"primary"|"warning"|"danger", size?: "sm"}> = (props) => {

    const {color, size} = props;

    const [className, setClassName] = useState<string>('');

    useEffect(() => {
        let _className: string = '';

        if (color){
            _className += ' color-' + color;
        }

        if (size){
            _className += ' spinner-border-sm'
        }

        setClassName(_className)

    }, [color, size])

    return <div className={"spinner-border" + className} role="status">
        <span className="visually-hidden">Loading...</span>
    </div>
}

export default Loader;