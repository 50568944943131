import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import FormError from "../../../../../utils/formError";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../adapters/corporate/common/onboarding";
import React, {useState} from "react";
import authSlice from "../../../../../reducers/auth";
import Loader from "../../../../../utils/loader";
import {useNavigate} from "react-router-dom";
import {Account, AccountCorporate} from "../../../../../models/account";
import ErrorCard from "../../../../error";

const OnBoardingAccount = () => {

    const account = useAppSelector(state => state.auth.user!.activeAccount) as Account & AccountCorporate
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(null)

    const formik = useFormik({
        initialValues: {
            firstname: account.firstname,
            lastname: account.lastname
        },
        validationSchema: Yup.object().shape({
            firstname: Yup.string().required(),
            lastname: Yup.string().required()
        }),
        onSubmit: (values, {setSubmitting}) => {
            setError(null)
            api.account(values)
                .then(r => dispatch(authSlice.actions.editAccount(r.data)))
                .then(() => navigate('/onboarding/information'))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    })

    return <>
        <h3 className={"mb-8"}>
            Commençons par votre vous !
        </h3>
        <form onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className="form-control" defaultValue={account.firstname} id={"firstname"} name={"firstname"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                    <label htmlFor="firstname">
                        Prénom *
                    </label>
                </div>
                <FormError form={formik} field={"firstname"} />
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className="form-control" defaultValue={account.lastname} id={"lastname"} name={"lastname"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                    <label htmlFor="lastname">
                        Nom *
                    </label>
                </div>
                <FormError form={formik} field={"lastname"} />
            </div>
            {error && <div className={'col-12 mb-3'}>
                <ErrorCard error={error} />
            </div>}
            <button className="btn btn-outline-primary w-100 mb-3">
                {formik.isSubmitting ? <Loader /> : <>Suivant <i className={'bi bi-arrow-right'}></i></>}
            </button>
        </form>
    </>

}

export default OnBoardingAccount;