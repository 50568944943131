import {Shipping} from "../../../../../../models/shipping";
import React from "react";
import {Link} from "react-router-dom";
import SupplierShippingAddressSelect from "./select";
import * as shippingApi from "../../../../../../adapters/corporate/supplier/shipping";
import * as models from "../../../../../../models/shipping";

const SupplierShippingAddress = ({shipping, setShipping}: {shipping: Shipping, setShipping: React.Dispatch<any>}) => {

    const redirectAfterAdd = encodeURIComponent(`/corporate/supplier/shipping/${shipping.id}/address`)

    function unlink(address: models.ShippingAddress){
        shippingApi.unlinkAddress(shipping.id, address.id)
            .then(() => setShipping((p: models.Shipping) => ({...p, addresses: [...p.addresses.filter(a => a.id !== address.id)]})))
    }

    return <div className="row align-items-center">
        <div className="col-12 mb-3">
            <div className="alert alert-info fw-semibold">
                Ajouter ici tout vos lieux de traitement partageant les mêmes zones d'expédition.
            </div>
        </div>
        <div className="col-auto mb-3">
            <button
                data-bs-toggle="modal"
                aria-controls="shippingAddressSelectModal"
                data-bs-target={'#shippingAddressSelectModal'}
                className={'btn btn-dark'} >
                <i className={"bi bi-plus-circle"}></i> Séléctionner une addresse existante
            </button>
        </div>
        <div className="col-auto mb-3">
            <Link to={`/corporate/supplier/shipping/address/add?redirect=${redirectAfterAdd}&shippingId=${shipping.id}`} className={'btn btn-dark'} >
                <i className={"bi bi-plus-circle"}></i> Créer une addresse
            </Link>
        </div>
        {shipping.addresses.map(a => <div className={'col-12 mb-3'}>
            <div className="p-3 border border-1 rounded bg-white position-relative">
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-text">
                            Pays
                        </div>
                        <div>
                            {a.country.flag && <img style={{height: 18, width: "auto"}} src={a.country.flag} alt={a.country.name}/>} {a.country.name}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-text">
                            Ville
                        </div>
                        <p>
                            {a.city}
                        </p>
                    </div>
                    <div className="col-md-3">
                        <div className="form-text">
                            Code postal
                        </div>
                        <p>
                            {a.postalCode}
                        </p>
                    </div>
                    <div className="col-md-3">
                        <div className="btn-group">
                            <button onClick={() => unlink(a)} className={'btn btn-light'}>
                                <i className={'bi bi-dash text-danger'}></i> Retirer
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-text">
                            Adresse
                        </div>
                        <p>
                            {a.line1}
                        </p>
                    </div>
                    <div className="col-md-6">
                        <div className="form-text">
                            Complément d'adresse
                        </div>
                        <p>
                            {a.line2}
                        </p>
                    </div>
                </div>
            </div>
        </div>)}
        <SupplierShippingAddressSelect shipping={shipping} setShipping={setShipping} />
    </div>
}

export default SupplierShippingAddress;