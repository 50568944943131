import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function show(organization: number){
    return get(`/corporate/common/organization/${organization}`);
}

export function list(requestData: {
    excludePartners?: boolean,
    offset: number,
    count?: number;
    query?: string
    sectors?: number[]
    specialisations?: number[]
    sort?: 'ranking'|'latest'|'trending'
}){
    return get(`/corporate/common/organization${encodeQueryData(requestData)}`);
}

export function products(organization: number, requestData: {query: string, offset: number}){
    return post(`/corporate/common/organization/${organization}/products`, requestData);
}

export function affiliations(organization: number, requestData: {query: string, offset: number}){
    return post(`/corporate/common/organization/${organization}/affiliations`, requestData);
}