import {PartnershipRequest, PartnershipRequests} from "../../../../../../models/partnership";
import {getLogoUrl} from "../../../../../../utils/image";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import * as api from "../../../../../../adapters/corporate/common/partnership/request";
import {Payload} from "../../../../../../models/global";
import ModalComponent from "../../../../../modal";
import PartnershipRequestAccept from "../accept";

const PartnershipRequestListItem = ({item, setPayload}: {item: PartnershipRequest, setPayload: React.Dispatch<any>}) => {


    const [showAcceptModal, setShowAcceptModal] = useState(false)

    function decline(){
        api.decline(item.id)
            .then(r => setPayload((prev: Payload<PartnershipRequests>) => ({count: prev.count - 1, rows: prev.rows.filter(p => p.id !== item.id)})))
    }

    return <div className="col-12 mb-3">
        <div className="card border border-1">
            <div className="card-body">
                <div className="row align-items-center">
                    <div className="col-auto mb-3">
                        <span className="avatar avatar-xl">
                            <img src={getLogoUrl(item.applicant)} alt={item.applicant.name} className="avatar-img rounded-circle" />
                        </span>
                    </div>
                    <div className="col mb-3">
                        <h6>{item.applicant.name}</h6>
                        <p className={"mb-0 text-primary"}>
                            <i className={'bi bi-trophy'}></i> {item.applicant.ranking.score.toLocaleString()}
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <p className={"text-muted"}>Message</p>
                        <p className={""} style={{whiteSpace: "pre-wrap"}}>
                            {item.message}
                        </p>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="row">
                            <div className="col">
                                <Link to={`/corporate/organization/${item.applicant.id}`} className={'btn btn-light btn-xxs'}>
                                    Consulter le profil <i className={"bi bi-arrow-right"}></i>
                                </Link>
                            </div>
                            <div className="col-auto">
                                <button className={'btn btn-light btn-xxs'} onClick={() => setShowAcceptModal(true)}>
                                    <i className={"bi bi-check text-success"}></i> Accepter
                                </button>
                            </div>
                            <div className="col-auto">
                                <button className={'btn btn-light btn-xxs'} onClick={() => decline()}>
                                    <i className={"bi bi-x text-danger"}></i> Refuser
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {showAcceptModal && <ModalComponent
            size={"modal-lg"}
            title={`Accepter la demande de ${item.applicant.name}`}
            show={showAcceptModal}
            onHide={() => setShowAcceptModal(false)}
        >
            <PartnershipRequestAccept partnershipRequest={item} onSuccess={() => {
                setPayload((prev: Payload<PartnershipRequests>) => ({
                    count: prev.count - 1,
                    rows: prev.rows.filter(p => p.id !== item.id)
                }))
                setShowAcceptModal(false)
            }} />
        </ModalComponent>}
    </div>
}

export default PartnershipRequestListItem;