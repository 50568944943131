import encodeQueryData from "../../../../utils/encodeQueryData";
import {get, post} from "../../../xhr";

export function list(requestData: { query?: string, offset: number, status?: string, organization_id?: number, assignment_id?: number}){
    return get(`corporate/supplier/application${encodeQueryData(requestData)}`)
}

export function show(application: number){
    return get(`corporate/supplier/application/${application}`)
}

export function update(application: number, requestData: {status: 'Accepted' | 'Refused'}){
    return post(`corporate/supplier/application/${application}`, requestData)
}