import {useFormik} from "formik";
import * as models from "../../../../../../../models/subscription";
import * as api from "../../../../../../../adapters/corporate/supplier/subscription/item";
import * as Yup from "yup"
import React, {useState} from "react";
import Loader from "../../../../../../../utils/loader";
import FormError from "../../../../../../../utils/formError";
import ErrorCard from "../../../../../../error";
import {SubscriptionItem} from "../../../../../../../models/subscription";

const SupplierSubscriptionItemUpdate = ({subscription, item, onSuccess}: {subscription: models.Subscription, item: SubscriptionItem, onSuccess: (item: models.SubscriptionItem) => void}) => {

    const [error, setError] = useState(null);

    const formik = useFormik({
        initialValues: {
            quantity: item.quantity
        },
        validationSchema: Yup.object().shape({
            quantity: Yup.number().required().min(1),
        }),
        enableReinitialize: true,
        onSubmit: (values, {setSubmitting}) => {
            api.update(subscription.id, item.id, {...values})
                .then(r => onSuccess(r.data))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    })

    return <form onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="number" defaultValue={item.quantity} step={item.price.product.unit.step} name={'quantity'} id={'quantity'} onChange={formik.handleChange} onBlur={formik.handleBlur} className="form-control"/>
                <label htmlFor="quantity">
                    Quantité
                </label>
            </div>
            <FormError form={formik} field={'quantity'} />
        </div>
        {error && <ErrorCard error={error} />}
        <button className={"btn btn-primary w-100"}>
            {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-plus'}></i> Mettre à jour</>}
        </button>
    </form>
}

export default SupplierSubscriptionItemUpdate;