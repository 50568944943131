import FormError from "../../../../../../utils/formError";
import Loader from "../../../../../../utils/loader";
import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../../adapters/corporate/supplier/shipping/address";
import Select from "../../../../../../utils/select";
import {getCountries} from "../../../../../../utils/countries";
import {useNavigate} from "react-router-dom";
import {useQuery} from "../../../../../../utils/useQuery";

const SupplierShippingAddressAdd = () => {

    const navigate = useNavigate();
    const query = useQuery();

    const formik = useFormik({
        initialValues: {
            countryId: 0,
            city: '',
            postalCode: '',
            line1: '',
            line2: '',
            shippingId: query.has('shippingId') ? Number(query.get("shippingId")!) : undefined,
        },
        validationSchema: Yup.object().shape({
            countryId: Yup.number().required().min(1),
            city: Yup.string().required(),
            postalCode: Yup.string().required(),
            line1: Yup.string().required(),
            line2: Yup.string().nullable(),
            shippingId: Yup.string().nullable(),
        }),
        onSubmit: (values, formikHelpers) => {
            api.add({...values})
                .then(r => navigate(query.has('redirect') ? decodeURIComponent(query.get("redirect")!) :`/corporate/supplier/shipping/address/${r.data.id}`))
        }
    })

    return <div className={'container-fluid'}>
        <ol className="breadcrumb mb-3 fs-xs">
            <li className="breadcrumb-item">
                Expédition
            </li>
            <li className="breadcrumb-item">
                Lieux de traitement
            </li>
            <li>
                Ajouter
            </li>
        </ol>
        <form onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3">
                <Select
                    required={true}
                    label={"Pays *"}
                    options={getCountries()}
                    onChange={o => formik.setFieldValue('countryId', o.id)}
                />
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className={'form-control'} name={"line1"} id={"line1"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                    <label htmlFor="line1">
                        Addresse *
                    </label>
                </div>
                <FormError form={formik} field={"line1"} />
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className={'form-control'} name={"line2"} id={"line2"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                    <label htmlFor="line2">
                        Complément d'addresse (optionel)
                    </label>
                </div>
                <FormError form={formik} field={"line2"} />
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className={'form-control'} name={"city"} id={"city"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                    <label htmlFor="city">
                        Ville
                    </label>
                </div>
                <FormError form={formik} field={"city"} />
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className={'form-control'} name={"postalCode"} id={"postalCode"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                    <label htmlFor="postalCode">
                        Code postale
                    </label>
                </div>
                <FormError form={formik} field={"postalCode"} />
            </div>
            <button className="btn btn-dark w-100 mb-3">
                {formik.isSubmitting ? <Loader /> : <><i className={'bi bi-check'}></i> Valider</>}
            </button>
        </form>
    </div>
}

export default SupplierShippingAddressAdd;