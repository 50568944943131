import React, {useEffect, useState} from "react";
import * as models from '../../../../../models/touch'
import {getLogoUrl} from "../../../../../utils/image";
import {Link, useParams} from "react-router-dom";
import * as api from "../../../../../adapters/corporate/supplier/touch";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import CommonTouchStatus from "../../../common/touch/status";
import SupplierFeeList from "../../fee/list";

const SupplierTouchShow = () => {

    const params = useParams();
    const [touch, setTouch] = useState<models.Touch>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    function accept(){
        setLoading(true)
        api.accept(Number(params.id))
            .then(r => setTouch(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }

    function refuse(){
        setLoading(true)
        api.refuse(Number(params.id))
            .then(r => setTouch(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        setLoading(true)
        api.show(Number(params.id))
            .then(r => setTouch(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [params.id])

    if (loading){
        return <div className={'container-fluid py-10 text-center'}>
            <Loader />
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!touch){
        return <></>
    }

    return <div className={"container-fluid"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/corporate/supplier/touch">Mise en relation</Link>
                </li>
                <li className="breadcrumb-item">
                    {touch.title}
                </li>
            </ol>
        </div>
        {touch.touchSuppliers[0].status === "Pending" && <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-body">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col">
                                    <button className={'btn btn-light w-100'} onClick={() => accept()}>
                                       <i className={"bi bi-check text-success"}></i> Accepter
                                    </button>
                                </div>
                                <div className="col">
                                    <button className={'btn btn-light w-100'} onClick={() => refuse()}>
                                        <i className={"bi bi-x text-danger"}></i> Refuser
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
        {touch.touchSuppliers[0].status === "Accepted" && <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <div className="row">
                        <h6>
                            Commissions
                        </h6>
                    </div>
                </div>
                <div className="card-body">
                   <SupplierFeeList status={"All"} touch={touch} />
                </div>
            </div>
        </div>}
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Informations
                    </h6>
                </div>
                <div className="card-body">
                    <table className={'table'}>
                        <tbody>
                            <tr>
                                <th>Apporteur</th>
                                <td>
                                    <Link to={`/corporate/organization/${touch.organization.id}`}>
                                        <div className="avatar avatar-lg">
                                            <img src={getLogoUrl(touch.organization)} alt={touch.organization.name} className="avatar-img rounded-circle" />
                                        </div>
                                        {touch.organization.name}
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <th>Statut</th>
                                <td>
                                    <CommonTouchStatus status={touch.touchSuppliers[0].status} />
                                </td>
                            </tr>
                            <tr>
                                <th>Lancement du projet</th>
                                <td>
                                    {touch.launch === 'Asap' && 'Le plus vite possible'}
                                    {touch.launch === 'Unknown' && 'Ne sait pas'}
                                    {touch.launch === 'Date' && <>
                                        {new Date(touch.launchAt!).toLocaleDateString()} {touch.isFlexible && <>+/- 1 {touch.flexibleType}</>}
                                    </>}
                                </td>
                            </tr>
                            <tr>
                                <th>Lieux du projet</th>
                                <td>
                                    {touch.location === 'Distant' && 'A distance'}
                                    {touch.location === 'Unknown' && 'Ne sait pas'}
                                    {touch.location === 'OnSite' && <>
                                        {touch.city}, {touch.postalCode}
                                    </>}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        {touch.touchSuppliers[0].status !== "Refused" && <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Contact
                    </h6>
                </div>
                <div className="card-body">
                    {touch.touchSuppliers[0].status === 'Pending' && <>
                        <div className="alert alert-info">
                            <i className={"bi bi-info"}></i> Acceptez la mise en relation pour obtenir les informations
                            du contact
                        </div>
                    </>}
                    {touch.touchSuppliers[0].status === "Accepted" && <>
                        <table className={'table table-borderless'}>
                            <tbody>
                            <tr>
                                <th>Tel</th>
                                <td>{touch.contact.countryCode} {touch.contact.tel}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{touch.contact.email}</td>
                            </tr>
                            <tr>
                                <th>Type</th>
                                <td>{touch.contact.type}</td>
                            </tr>
                            {touch.contact.type === 'company' && <>
                                <tr>
                                    <th>Entreprise</th>
                                    <td>{touch.contact.companyName}</td>
                                </tr>
                                <tr>
                                    <th>Fonction</th>
                                    <td>{touch.contact.jobTitle}</td>
                                </tr>
                            </>}
                            </tbody>
                        </table>
                    </>}
                </div>
            </div>
        </div>}
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Description
                    </h6>
                </div>
                <div className="card-body">
                    <p className={'mb-0'} style={{whiteSpace: "pre-wrap"}}>
                        {touch.description}
                    </p>
                </div>
            </div>
        </div>
    </div>
}

export default SupplierTouchShow;