import Select from "../../../../../utils/select";
import React, {useEffect, useState} from "react";
import {Product, Products} from "../../../../../models/product";
import * as api from "../../../../../adapters/corporate/supplier/product";

const SupplierProductSelect = (props: {required: boolean, handleChange: (choice: number) => void, defaultValue?: {label: string, value: any}}) => {

    const [loading, setLoading] = useState(true)
    const [payload, setPayload] = useState<{count: number, rows: Products}>({count: 0, rows: []})
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string}>({offset: 0, query: ""})
    const [defaultValue, setDefaultValue] = useState({label: props.defaultValue?.label || "", value: props.defaultValue?.value || null});

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => console.log(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    return <Select
        key={defaultValue.value}
        value={defaultValue}
        multiple={false}
        disabled={loading}
        required={props.required}
        label={"Produit / Service"}
        options={payload.rows.map(c => ({label: c.name, value: c.id}))}
        onChange={(c: any) => props.handleChange(c.value)}
        handleScrollBottom={() => setFetchParams(p => ({...p, offset: p.offset + 1}))}
        handleQueryChange={(query) => setFetchParams(p => ({query: query, offset: 0}))}
    />
}

export default SupplierProductSelect;