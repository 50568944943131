export const API_PROD_URl = "https://api.varar.co";
export const APP_URL = "https://app.varar.co/";
export const API_DEV_URl = "http://127.0.0.1:7000";
export const API_LOCAL_NETWORK_URl = "http://192.168.1.174:7000";
export const S3_BUCKET_URL = "https://varar-public.s3.eu-west-3.amazonaws.com/";
export const S3_SHOP_BUCKET = "shop/";
export const S3_ORGANIZATION_BUCKET = "organization/";
export const S3_PRODUCT_BUCKET = "product/";
export const S3_POST_BUCKET = "post/";
export const S3_MESSAGE_BUCKET = "message/";
export const S3_AFFILIATION_BUCKET = "affiliation/";
