import React, {useEffect, useRef, useState} from "react";
import deepCompare from "../deepCompare";
import deepCompareArray from "../deepCompareArray";

export interface Option {
    label: string,
    value: any,
    id?: number
    icon?: string
    img?: string
}

export type Options = Option[];

interface Base {
    label: string,
    placeholder?: string,
    name?: string,
    enableReinitialize?: boolean,
    disabled?: boolean,
    options: Options,
    autoFocus?: boolean,
    size?: string,
    handleQueryChange?: (query: string) => void;
    handleScrollBottom?: () => void;
}

interface Single {
    multiple?: false,
    value?: Option,
}

interface SinglePropsRequired {
    required: true,
    onChange: (option: Option) => any
}

interface SinglePropsOptional {
    required?: false,
    onChange: (option: Option|undefined) => any
}

interface MultipleProps {
    multiple: true,
    values?: Options,
    max?: number,
    onChange: (options: Options) => void,
    required?: true,
}

type SingleProps = Single & (SinglePropsRequired | SinglePropsOptional)

type Select = Base & (SingleProps | MultipleProps)

const Select:React.FC<Select> = (props) => {

    const {
        label, name, placeholder, autoFocus, options, disabled, required, enableReinitialize, size, handleQueryChange, handleScrollBottom
    } = props;

    const [isOpen, setIsOpen] = useState(!!autoFocus);

    if (props.multiple)
    {
        if (required){
            return <MultipleSelector handleQueryChange={handleQueryChange} handleScrollBottom={handleScrollBottom} disabled={disabled} size={size} enableReinitialize={enableReinitialize} options={options} label={label} isOpen={isOpen} name={name} placeholder={placeholder} autoFocus={autoFocus} multiple={true} onChange={props.onChange} values={props.values} setIsOpen={setIsOpen} required={true}/>
        }else{
            return <MultipleSelector handleQueryChange={handleQueryChange} handleScrollBottom={handleScrollBottom} disabled={disabled} size={size} enableReinitialize={enableReinitialize} options={options} label={label} isOpen={isOpen} name={name} placeholder={placeholder} autoFocus={autoFocus} multiple={true} onChange={props.onChange} values={props.values} setIsOpen={setIsOpen}/>
        }
    }

    if (!props.multiple)
    {
        if (required){
            return <SingleSelector handleQueryChange={handleQueryChange} handleScrollBottom={handleScrollBottom} disabled={disabled} size={size} enableReinitialize={enableReinitialize} options={options} label={label} isOpen={isOpen} name={name} placeholder={placeholder} autoFocus={autoFocus} multiple={false} onChange={props.onChange} value={props.value} required={true} setIsOpen={setIsOpen}/>
        }else{
            return <SingleSelector handleQueryChange={handleQueryChange} handleScrollBottom={handleScrollBottom} disabled={disabled} size={size} enableReinitialize={enableReinitialize} options={options} label={label} isOpen={isOpen} name={name} placeholder={placeholder} autoFocus={autoFocus} multiple={false} onChange={props.onChange} value={props.value} setIsOpen={setIsOpen}/>
        }
    }

    return <></>
}

const MultipleSelector: React.FC<Base & MultipleProps & {isOpen: boolean, setIsOpen: React.Dispatch<boolean>}> = (props) => {

    const {label, size, onChange, max, isOpen, required, handleQueryChange, handleScrollBottom, autoFocus, options, disabled, placeholder, name, values, setIsOpen, enableReinitialize} = props;

    const [_values, setValues] = useState(values || []);
    const [query, setQuery] = useState("")
    const menuContainer = useRef<HTMLUListElement|null>(null);

    function handleScroll(){
        if (menuContainer.current){
            if (menuContainer.current?.scrollTop + menuContainer.current?.offsetHeight > menuContainer.current?.scrollHeight - 5){
                if (typeof handleScrollBottom === "function"){
                    handleScrollBottom();
                }
            }
        }
    }

    let timer: NodeJS.Timeout;
    function _handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        if (typeof handleQueryChange === 'function'){
            clearTimeout(timer)
            timer = setTimeout(() => handleQueryChange(query), 1000)
        }else{
            setQuery(e.target.value);
        }
    }

    useEffect(() => {
        setQuery('');
    }, [isOpen])

    useEffect(() => {
        if (enableReinitialize){
            setValues(values || [])
        }
    }, [values])

    function handleChange(option: Option)
    {
        let copy = [..._values];
        let ret = [];

        if (_values.find(v => v.value === option.value)){
            ret = [...copy.filter(c => c.value !== option.value)]
        }else{

            if (max && _values.length === max){
                return;
            }

            ret = [...copy, option]
        }

        setValues(ret)
        onChange(ret);
    }

    function _setIsOpen(_isOpen: boolean){
        if (!disabled){
            setIsOpen(_isOpen)
        }
    }

    return <div className={'position-relative'} style={{minWidth: 120}}>
        <div className="input-group">
            <div className={'form-floating'}>
                {!isOpen && <div className={"select-form-control border-end-0 form-control " + (size ? `form-control-${size}` : '')} onClick={() => _setIsOpen(true)}>
                    {placeholder && !_values.length && <>{placeholder}</>}
                    {!!_values.length && _values.map(v => <small
                        onMouseDown={e => {
                            e.preventDefault()
                            handleChange(v)
                        }}
                        className={'d-inline-flex align-items-center bg-light p-1 rounded me-2'}
                    >
                        {v.icon && <i className={`bi bi-${v.icon}`}></i>} {v.img && <img style={{height: 18, width: "auto"}} className={'me-1'} src={v.img} alt={v.label} />} {v.label} {(!required || _values.length > 1)  && <i className={'bi bi-x text-danger'}></i>}
                    </small>)}
                </div>}
                {isOpen && !disabled && <input onChange={e => _handleQueryChange(e)} className={"form-control " + (size ? `form-control-${size}` : '')} type={'text'} autoFocus={true} onBlur={() => _setIsOpen(false)} />}
                <label htmlFor={name}>
                    {label}
                </label>
            </div>
            <div className="input-group-text border-start-0" onClick={e => {
                e.preventDefault()
                _setIsOpen(!isOpen)
            }}>
                {isOpen ? <i className={'bi bi-chevron-up'}></i> : <i className={'bi bi-chevron-down'}></i>}
            </div>
        </div>
        {isOpen && !disabled && <ul ref={menuContainer} onScroll={handleScroll}  className={'list-group list-group-flush position-absolute border border-1 shadow-sm mt-0 overflow-auto'} style={{maxHeight: 400, width: "100%", zIndex: 10}} onMouseDown={e => e.preventDefault()}>
            {options.filter(o => (!query || o.label.toLowerCase().includes(query.toLowerCase()))).map(o => <li
                className={'list-group-item list-group-item-action'}
                onMouseDown={e => {
                    e.preventDefault();
                    handleChange(o);
                }}
            >
                {o.icon && <i className={`bi bi-${o.icon}`}></i>} {o.img && <img style={{height: 18, width: "auto"}} className={'me-1'} src={o.img} alt={o.label} />} {o.label} {_values.find(v => v.value === o.value) && <i className={'bi bi-check'}></i>}
            </li>)}
        </ul>}
    </div>
}

const SingleSelector: React.FC<Base & SingleProps & {isOpen: boolean, setIsOpen: React.Dispatch<boolean>}> = (props) => {

    const {label, size, onChange, isOpen, required, handleQueryChange, handleScrollBottom, autoFocus, options, disabled, placeholder, name, value, setIsOpen, enableReinitialize} = props;

    const [_value, setValue] = useState(value);
    const [query, setQuery] = useState("")
    const menuContainer = useRef<HTMLUListElement|null>(null);

    function handleScroll(){
        if (menuContainer.current){
            if (menuContainer.current?.scrollTop + menuContainer.current?.offsetHeight > menuContainer.current?.scrollHeight - 5){
                if (typeof handleScrollBottom === "function"){
                    handleScrollBottom();
                }
            }
        }
    }

    let timer: NodeJS.Timeout;
    function _handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        if (typeof handleQueryChange === 'function'){
            clearTimeout(timer)
            timer = setTimeout(() => handleQueryChange(query), 1000)
        }else{
            setQuery(e.target.value);
        }
    }

    useEffect(() => {
        setQuery('');
    }, [isOpen])

    useEffect(() => {
        if (enableReinitialize && value?.value !== _value?.value){
            setValue(value)
        }
    }, [value])

    function handleChange(option: Option)
    {
        setValue(option)
        onChange(option);
        _setIsOpen(false)
    }

    function _setIsOpen(_isOpen: boolean){
        if (!disabled){
            setIsOpen(_isOpen)
        }
    }

    return <div className={'position-relative'} style={{minWidth: 120}}>
        <div className="input-group">
            <div className={'form-floating'}>
                {!isOpen && <div className={"form-control border-end-0 text-truncate" + (size ? `form-control-${size}` : '')} onClick={() => _setIsOpen(true)}>
                    {placeholder && !_value && <>{placeholder}</>}
                    {!!_value && <small
                        onMouseDown={e => {
                            e.preventDefault()
                            if (!required){
                                setValue(undefined)
                            }
                        }}
                        className={'d-inline-flex align-items-center bg-light p-1 rounded me-2'}
                    >
                        {_value.icon && <i className={`bi bi-${_value.icon}`}></i>} {_value.img && <img style={{height: 18, width: "auto"}} className={'me-1'} src={_value.img} alt={_value.label} />} {_value.label} {!required && <i className={'bi bi-x text-danger'}></i>}
                    </small>}
                </div>}
                {isOpen && !disabled && <input onChange={e => _handleQueryChange(e)} className={"form-control " + (size ? `form-control-${size}` : '')} type={'text'} autoFocus={true} onBlur={() => _setIsOpen(false)} />}
                <label htmlFor={name}>
                    {label}
                </label>
            </div>
            <div className="input-group-text border-start-0" onClick={e => {
                e.preventDefault()
                _setIsOpen(!isOpen)
            }}>
                {isOpen ? <i className={'bi bi-chevron-up'}></i> : <i className={'bi bi-chevron-down'}></i>}
            </div>
        </div>
        {isOpen && !disabled && <ul onScroll={handleScroll} className={'list-group list-group-flush position-absolute border border-1 shadow-sm mt-0 overflow-auto'} ref={menuContainer} style={{maxHeight: 400, width: "100%", zIndex: 10}} onMouseDown={e => e.preventDefault()}>
            {options.filter(o => (!query || o.label.toLowerCase().includes(query.toLowerCase()))).map(o => <li
                className={'list-group-item list-group-item-action'}
                onMouseDown={e => {
                    e.preventDefault();
                    handleChange(o);
                }}
            >
                {o.icon && <i className={`bi bi-${o.icon}`}></i>} {o.img && <img style={{height: 18, width: "auto"}} className={'me-1'} src={o.img} alt={o.label} />} {o.label} {!!_value && _value.value === o.value && <i className={'bi bi-check'}></i>}
            </li>)}
        </ul>}
    </div>
}

export default Select;