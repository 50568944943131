import React, {useEffect, useState} from "react";
import {Products} from "../../../../../models/product";
import * as api from "../../../../../adapters/corporate/provider/product";
import SearchInput from "../../../../../utils/searchInput";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";
import {Shop} from "../../../../../models/shop";
import ModalComponent from "../../../../modal";
import ProviderShopShowProductSelector from "./selector";
import ErrorCard from "../../../../error";
import CommonProductListItem from "../../../common/product/listItem";

const ProviderShopShowProductList = ({shop}: {shop: Shop}) => {

    const [payload, setPayload] = useState<{ count: number, rows: Products }>({count: 0, rows: []});
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const [showSelectModal, setShowSelectModal] = useState(false)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number}>({query: "", offset: 0})

    useEffect(() => {
        setLoading(true)
        api.list({offset: fetchParams.offset, query: fetchParams.query, type: 'private', shop_id: shop.id})
            .then(resp => setPayload(resp.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher un produit..."} onChange={handleQueryChange} size={"default"} />
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary" onClick={() => setShowSelectModal(true)}>
                        <i className={"bi bi-pencil"}></i> Mettre à jour
                    </button>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        {error && <ErrorCard error={error} />}
        <div className={'row row-cols-2 row-cols-md-4'}>
            {payload.rows.map(r => <CommonProductListItem item={r} />)}
        </div>
        <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        <ModalComponent title={"Mettre à jour le cataloque"} size={"modal-xl"} show={showSelectModal} onHide={() => setShowSelectModal(false)}
            footerContent={<button className={"btn btn-primary"} onClick={() => setShowSelectModal(false)}>J'ai terminé</button>}
        >
            <ProviderShopShowProductSelector
                handleUnlink={p => setPayload(prev => ({count: prev.count - 1, rows: [...prev.rows.filter(r => r.id !== p.id)]}))}
                handleLink={p => setPayload(prev => ({count: prev.count + 1, rows: [p, ...prev.rows]}))}
                shop={shop}
            />
        </ModalComponent>
    </div>
}

export default ProviderShopShowProductList;