import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import * as api from "../../../../../adapters/corporate/common/customer"
import Loader from "../../../../../utils/loader";
import {CustomerAccount} from "../../../../../models/account";
import ErrorCard from "../../../../error";
import SupplierQuoteList from "../../../supplier/quote/list";
import SupplierSubscriptionList from "../../../supplier/subscription/list";
import SupplierOrderList from "../../../supplier/order/list";
import SupplierReturnsList from "../../../supplier/returns/list";
import SupplierParcelList from "../../../supplier/parcel/list";

const CommonCustomerShow = () => {

    const {id} = useParams()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [customer, setCustomer] = useState<CustomerAccount>()
    const [tab, setTab] = useState<"quote"|"order"|'subscription'|'returns'|'parcel'>('order')

    useEffect(() => {
        setLoading(true)
        api.show(Number(id))
            .then(r => setCustomer(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false));
    }, [id])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!customer){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <h6>
                    Customer not found.
                </h6>
            </div>
        </div>
    }

    return <div className={'container-fluid'}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/corporate/supplier/customer">Clients</Link>
                </li>
                <li className="breadcrumb-item">
                    {customer.user.email}
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>Informations de contact</h6>
                </div>
                <div className="card-body">
                    <table className={'table table-sm table-borderless align-middle'}>
                        <tbody>
                            <tr><th><i className={'bi bi-person'}></i></th><td>{customer.firstname} {customer.lastname}</td></tr>
                            <tr><th><i className={'bi bi-phone'}></i></th><td>{customer.countryCode} {customer.tel}</td></tr>
                            <tr><th><i className={'bi bi-envelope'}></i></th><td>{customer.user.email}</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="col-12 mb-3">
                <div className="mb-2 mb-lg-0 border-bottom-lg">
                    <nav className="nav nav-tabs nav-overflow fs-xs border-bottom border-bottom-lg-0">
                        <button onClick={() => setTab('order')} className={"nav-link text-uppercase" + (tab === "order" ? " active " : "")}>
                            Commandes
                        </button>
                        <button onClick={() => setTab('returns')} className={"nav-link text-uppercase" + (tab === 'returns' ? " active " : "")}>
                            Retours
                        </button>
                        <button onClick={() => setTab('parcel')} className={"nav-link text-uppercase" + (tab === 'parcel' ? " active " : "")}>
                            Colis
                        </button>
                        <button onClick={() => setTab('quote')} className={"nav-link text-uppercase" + (tab === 'quote' ? " active " : "")}>
                            Devis
                        </button>
                        <button onClick={() => setTab('subscription')} className={"nav-link text-uppercase" + (tab === 'subscription' ? " active " : "")}>
                            Abonnements
                        </button>
                    </nav>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            {tab === "quote" && <SupplierQuoteList customer={customer} />}
            {tab === "subscription" && <SupplierSubscriptionList customer={customer} />}
            {tab === "order" && <SupplierOrderList customer={customer} />}
            {tab === "returns" && <SupplierReturnsList customer={customer} />}
            {tab === "parcel" && <SupplierParcelList customer={customer} />}
        </div>
    </div>
}

export default CommonCustomerShow;