import {Currency} from "../../models/currency";

export default function convertToDecimal(centAmount: number|null|undefined, currency: Currency){

    if (centAmount === null || centAmount === undefined){
        return <i className={'bi bi-x'}></i>
    }

    const decimalAmount = getDecimalAmount(centAmount, currency)

    return decimalAmount.toLocaleString(navigator.language, {
        style: 'currency',
        currency: currency.code
    });
}

export function getCentAmount(decimalAmount: number, currency: Currency): number {
    return decimalAmount * Math.pow(10, currency.decimalDigits);
}

export function getDecimalAmount(centAmount: number, currency: Currency): number {
    return centAmount / Math.pow(10, currency.decimalDigits);
}