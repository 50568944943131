import {get, post, destroy} from "../xhr";
import encodeQueryData from "../../utils/encodeQueryData";

export function show(queryData: {countryId: number, currencyId: number, source: "link"|"shop", sourceId: string, localCartId?: string}) {
    return get(`/cart${encodeQueryData(queryData)}`);
}

export function addToCart(uuid: string, requestData: {
    local_cart_id: number|null,
    source: 'shop'|'link',
    source_uuid: string,
    product_id: number,
    quantity: number,
    declinations: number[],
    country_id: number,
    currency_id: number,
}) {
    return post(`/cart/add-to-cart`, requestData);
}

export function removeFromCart(itemId: number, queryData: {country_id: number, currency_id: number}) {
    return get(`/cart/remove-from-cart/${itemId}${encodeQueryData(queryData)}`);
}

export function checkout(cartId: number, queryData: {country_id: number, currency_id: number}){
    return get(`/cart/${cartId}/checkout${encodeQueryData(queryData)}`);
}

export function updateQuantity(itemId: number, queryData: {country_id: number, currency_id: number, quantity: number}){
    return get(`/cart/update-quantity/${itemId}${encodeQueryData(queryData)}`);
}

export function updateDeclinationChoice(itemId: number, queryData: {country_id: number, currency_id: number, declination: number, choice: number}){
    return get(`/cart/update-declination/${itemId}${encodeQueryData(queryData)}`);
}