import React from 'react';
import Navigation from "./navigation";
import { Provider } from 'react-redux'
import store from "./store";
import {GoogleOAuthProvider} from "@react-oauth/google";
import './i18n'; // Ensure this is imported

function App() {
  return <GoogleOAuthProvider clientId="837416552229-8fa6nsqi9choujnl20qj2rfk68c96901.apps.googleusercontent.com">
      <Provider store={store}>
          <Navigation />
      </Provider>
  </GoogleOAuthProvider>;
}

export default App;
