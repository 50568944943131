import React, {useEffect, useState} from "react";
import {Returns} from "../../../../../models/returns";
import {Link, useParams} from "react-router-dom";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import * as api from "../../../../../adapters/corporate/supplier/returns"
import ReturnsStatusPlaceholder from "../../../../common/returns/status/placeholder";
import {getCoverUrl} from "../../../../../utils/image";
import convertToDecimal from "../../../../../utils/currencyConverter";
import {reason_labels} from "../../../../../constants/returns";
import SupplierReturnsShowRefused from "./refused";
import SupplierReturnsShowAccepted from "./accept";
import ReturnReplacementStatusDropdown from "./replacementStatus";
import ReturnReturnStatusDropdown from "./returnStatus";
import SupplierRefundAdd from "../../order/refund/add";
import ModalComponent from "../../../../modal";

const SupplierReturnsShow = () => {

    const {id} = useParams();
    const [returns, setReturns] = useState<Returns>()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [showRefusedModal, setShowRefusedModal] = useState(false);
    const [showAcceptedModal, setShowAcceptedModal] = useState(false);
    const [showRefundAddModal, setShowRefundAddModal] = useState(false);
    const [showReturnsPolicyModal, setShowReturnsPolicyModal] = useState(false)

    useEffect(() => {
        setLoading(true)
        api.show(Number(id))
            .then(r => setReturns(r.data))
            .catch(error => setError(error))
            .finally(() => setLoading(false))
    }, [id])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!returns){
        return <></>
    }

    return <>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <ol className="breadcrumb mb-3 fs-xs">
                        <li className="breadcrumb-item">
                            <Link to="/corporate/supplier/returns">Demande de retours</Link>
                        </li>
                        <li className="breadcrumb-item">
                            {returns.item.name}
                        </li>
                    </ol>
                </div>
                <div className="col-auto ms-auto">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <ReturnsStatusPlaceholder status={returns.status} />
                        </div>
                        {returns.status === "Pending" && <>
                            <div className="col-auto">
                                <button className={'btn btn-xxs btn-success'} onClick={() => setShowAcceptedModal(true)}>
                                   <i className={"bi bi-check"}></i> Accepter
                                </button>
                            </div>
                            <div className="col-auto">
                                <button className={'btn btn-xxs btn-danger'} onClick={() => setShowRefusedModal(true)}>
                                    <i className={'bi bi-x'}></i> Refuser
                                </button>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </div>
        <div className={"col-12 mb-3"}>
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Informations sur la demande
                    </h6>
                </div>
                <div className="card-body">
                    <table className={"table table-borderless"}>
                        <tbody>
                        <tr>
                            <th>
                                Client
                            </th>
                            <td>
                                <Link to={`/corporate/supplier/customer/${returns.account.id}`}>
                                    {returns.account.firstname} {returns.account.lastname}
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Politique de retour
                            </th>
                            <td>
                                <span className={'text-decoration-underline cursor-pointer'} onClick={() => setShowReturnsPolicyModal(true)}>
                                    {returns.returnsPolicy.name}
                                </span>
                                <ModalComponent size={"modal-lg"} title={"Politique de retour"} show={showReturnsPolicyModal} onHide={() => setShowReturnsPolicyModal(false)}>
                                    <div dangerouslySetInnerHTML={{__html: returns.returnsPolicy.details}} />
                                </ModalComponent>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                N° de commande
                            </th>
                            <td>
                                <Link to={`/corporate/supplier/order/${returns.order.id}`}>
                                    {returns.order.number}
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Adresse de livraison
                            </th>
                            <td>
                                {returns.order.shipping ? <>{returns.order.shipping.line1}, {returns.order.shipping.city}, {returns.order.shipping.postalCode}, {returns.order.shipping.country.name}</> : <i className={"bi bi-x"}></i>}
                            </td>
                        </tr>
                        {returns.status === "Refused" && <tr>
                            <th>
                                Motif de refus
                            </th>
                            <td>
                                {returns.refusedReason}
                            </td>
                        </tr>}
                        {returns.status === "Accepted" && <>
                            <tr>
                                <th>
                                    Retour des articles
                                </th>
                                <td>
                                    {returns.hasReturn ? "Oui": "Non"}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Statut du retour
                                </th>
                                <td>
                                    <ReturnReturnStatusDropdown returns={returns} setReturns={setReturns} />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Type de compensation
                                </th>
                                <td>
                                    {returns.type}
                                </td>
                            </tr>
                            {returns.type === "Replacement" && <>
                                <tr>
                                    <th>
                                        Statut du remplacement
                                    </th>
                                    <td>
                                        <ReturnReplacementStatusDropdown returns={returns} setReturns={setReturns} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        N° de tracking
                                    </th>
                                    <td>
                                        {returns.replacementTrackingNumber}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Envoyé le
                                    </th>
                                    <td>
                                        {returns.shippedAt ? new Date(returns.shippedAt).toLocaleDateString() : <i className={"bi bi-x"}></i>}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Reçu le
                                    </th>
                                    <td>
                                        {returns.deliveredAt ? new Date(returns.deliveredAt).toLocaleDateString() : <i className={"bi bi-x"}></i>}
                                    </td>
                                </tr>
                            </>}
                            {returns.type === "Refund" && <>
                                <tr>
                                    <th>
                                        Remboursement
                                    </th>
                                    <td>
                                        {returns.refund ? <>{convertToDecimal(returns.refund.priceData.amount, returns.refund.priceData.currency)} le {new Date(returns.refund.createdAt).toLocaleDateString()} <button className="btn btn-xxs btn-light"><i className={'bi bi-eye'}></i></button></> : <><button onClick={() => setShowRefundAddModal(true)} className="btn btn-xxs btn-primary"><i className={'bi bi-plus-circle'}></i> Ajouter un remboursement</button></>}
                                    </td>
                                </tr>
                            </>}
                        </>}
                        </tbody>
                    </table>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="card br-0">
                                <img src={`${getCoverUrl(returns.item.price.product)}`} alt="" className="card-img-top br-0"/>
                                <div className="card-body bg-white">
                                    <div className="row">
                                        <div className="col">
                                            <p className="d-block fw-bold text-body mb-1">
                                                {returns.item.name}
                                            </p>
                                            <p className="fs-xs text-muted mb-0">
                                                Quantité: {returns.quantity}
                                            </p>
                                        </div>
                                        <div className="col-auto">
                                            <div className="fs-sm fw-bold text-primary">
                                                {convertToDecimal(returns.item.priceData.total_amount_including_tax, returns.item.priceData.currency)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="card-body">
                                <div className="col-12 mb-3">
                                    <p className={'fw-semibold'}>
                                        Description du problème
                                    </p>
                                    <ul>
                                        <li>
                                            {reason_labels[returns.reason1]}
                                        </li>
                                        {returns.reason2 && <li>
                                            {reason_labels[returns.reason2]}
                                        </li>}
                                        {returns.reason3 && <li>
                                            {reason_labels[returns.reason3]}
                                        </li>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SupplierReturnsShowRefused returns={returns} setReturns={setReturns} show={showRefusedModal} setShow={setShowRefusedModal} />
        <SupplierReturnsShowAccepted returns={returns} setReturns={setReturns} show={showAcceptedModal} setShow={setShowAcceptedModal} />
        {returns.type === "Refund" && <ModalComponent size={"modal-lg"} title={"Ajouter un remboursement"} show={showRefundAddModal} onHide={() => setShowRefundAddModal(false)}>
            <SupplierRefundAdd order={returns.order} returns={returns} parcels={[]} items={[returns.item]} onSuccess={refund => {
                setReturns(prev => ({...prev!, refund: refund}))
                setShowRefundAddModal(false)
            }}/>
        </ModalComponent>}
    </>
}

export default SupplierReturnsShow;