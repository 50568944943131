import {Organizations} from "../../../../../models/organization";
import React, {useEffect, useState} from "react";
import SupplierSearchListItem from "./item";
import * as api from "../../../../../adapters/corporate/common/organization";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import Pagination from "../../../../../utils/pagination";
import {Sectors} from "../../../../../models/sector";
import {Specialisations} from "../../../../../models/specialisation";
import {FetchParams} from "../../../../../models/global";

const SupplierSearchList = ({sectors, specialisations, query, sort, count}: {sectors: Sectors, specialisations: Specialisations, query: string, sort: "ranking"|'latest'|'trending', count: number}) => {

    const [loading, setLoading] = useState(true)
    const [payload, setPayload] = useState<{count: number, rows: Organizations}>({count: 0, rows: []});
    const [error, setError] = useState(null)
    const [fetchParams, setFetchParams] = useState<FetchParams>({offset: 0, query: query})

    useEffect(() => {
        if (query !== fetchParams.query){
            setFetchParams({query: query, offset: 0})
        }
    }, [query])

    useEffect(() => {
        setLoading(true)
        api.list({sectors: sectors.map(s => s.id), specialisations: specialisations.map(s => s.id), ...fetchParams, sort: sort, count: count})
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [sectors, specialisations, fetchParams, count, sort])

    if (loading){
        return <div className={'container-fluid py-10 text-center'}>
            <Loader />
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }


    if (!payload.count){
        return <div className={'container-fluid py-10 text-center'}>
            <h6 className="text-muted">
               <i className={"bi bi-search"}></i> Aucun réultat
            </h6>
        </div>
    }

    return <>
        <div className={'row row-cols-1 row-cols-md-3'}>
            {payload.rows.map(r => <SupplierSearchListItem item={r} />)}
        </div>
        <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
    </>
}

export default SupplierSearchList;