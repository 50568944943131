import {Quote} from "../../../models/quote";
import React from "react";

const QuoteCondition = ({quote}: {quote: Quote}) => {

    return <div className={'card border border-1'}>
        <div className="card-header">
            <div className="row">
                <div className="col">
                    <h6>
                        Conditions du devis
                    </h6>
                </div>
            </div>
        </div>
        <div className="card-body">
            <table className="table-borderless">
                <div className={"row"}>
                    <div className={"col-12 mb-3"}>
                        <p className={"fw-semibold mb-1"}>
                            Date d'expiration
                        </p>
                        <p className={"mb-0"}>
                            {new Date(quote.condition.expiredAt).toLocaleDateString()}
                        </p>
                    </div>
                    <div className={"col-12 mb-3"}>
                        <p className={"fw-semibold mb-1"}>
                            Accompte
                        </p>
                        <p className={"mb-0"}>
                            {quote.condition.deposit}%
                        </p>
                    </div>
                    <div className={"col-12 mb-3"}>
                        <p className={"fw-semibold mb-1"}>
                            Délais de réalisation / livraison
                        </p>
                        <p className={"mb-0"} style={{whiteSpace: "pre-wrap"}}>
                            {quote.condition.delay}
                        </p>
                    </div>
                </div>
            </table>
        </div>
    </div>
}

export default QuoteCondition