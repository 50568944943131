import * as models from "../../../../../../models/pack";
import React, {useEffect, useState} from "react";
import {FetchParams, Payload} from "../../../../../../models/global";
import {PackItems} from "../../../../../../models/pack";
import * as api from "../../../../../../adapters/corporate/provider/pack/item";
import SearchInput from "../../../../../../utils/searchInput";
import Loader from "../../../../../../utils/loader";
import ErrorCard from "../../../../../error";
import Pagination from "../../../../../../utils/pagination";
import ProviderPackItemListItem from "./item";

const ProviderPackItemList = ({pack}: {pack: models.Pack}) => {

    const [payload, setPayload] = useState<Payload<PackItems>>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<FetchParams>({query: "", offset: 0})
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        api.list(pack.id, {offset: fetchParams.offset, query: fetchParams.query})
            .then(resp => setPayload(resp.data))
            .catch(error => setError(error))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher un article..."} onChange={handleQueryChange} size={"default"} />
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        {error && <ErrorCard error={error} />}
        <div className="col-md-12 flex-grow-1">
            {!loading && <div className={'row row-cols-1 row-cols-md-3 row-cols-lg-4'}>
                {payload.rows.map(item => <ProviderPackItemListItem
                    pack={pack}
                    item={item}

                />)}
            </div>}
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        </div>
    </div>
}

export default ProviderPackItemList;