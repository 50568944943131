import React, {useState} from "react";
import {Webhook, WebhookEvent} from "../../../../../models/webhook";
import SupplierWebhookEventList from "./list";
import SupplierWebhookEventShow from "./show";

const SupplierWebhookEvent = ({webhook}: {webhook: Webhook}) => {

    const [active, setActive] = useState<WebhookEvent>()

    return <div className="row">
        <div className="col-md-6 mb-3">
            <div className="card border border-1">
                <div className="card-body">
                    <SupplierWebhookEventList webhook={webhook} setActive={setActive} active={active} />
                </div>
            </div>
        </div>
        <div className="col-md-6 mb-3">
            <SupplierWebhookEventShow event={active} webhook={webhook} key={active?.id} />
        </div>
    </div>
}

export default SupplierWebhookEvent;