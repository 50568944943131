
const CommonProposalStatus = ({status}: {status: string}) => {


    switch (status){
        case 'Pending':
            return <span className={'badge bg-warning'}>Brouillon</span>
        case 'Published':
            return <span className={'badge bg-info'}>Publié</span>
        case 'Accepted':
            return <span className={'badge bg-success'}>Accepté</span>
        case 'Refused':
            return <span className={'badge bg-danger'}>Refusé</span>
        case 'Canceled':
            return <span className={'badge bg-dark'}>Annulé</span>
        case 'Paid':
            return <span className={'badge bg-success'}>Payé</span>
        default:
            return <span className={'badge bg-light text-dark'}>Statut invalide</span>
    }
}

export default CommonProposalStatus;