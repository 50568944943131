import {get} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query: string, offset: number, order_id?: number, order_status?: "Completed"})
{
    return get(`customer/order-item${encodeQueryData(requestData)}`)
}
export function show(item: number)
{
    return get(`customer/order-item/${item}`)
}