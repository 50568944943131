import {getLogoUrl} from "../../../../../utils/image";
import React, {useState} from "react";
import {Subscription} from "../../../../../models/subscription";
import ModalComponent from "../../../../modal";
import CommonPaymentMethodSelect from "../../../../common/paymentMethod/select";
import {PaymentMethod} from "../../../../../models/paymentMethod";
import * as api from "../../../../../adapters/customer/subscription";
import CustomerAddressSelect from "../../../address/select";
import {CustomerAddress} from "../../../../../models/account";

const CustomerSubscriptionInformation = ({subscription, setSubscription}: {subscription: Subscription, setSubscription: React.Dispatch<any>}) => {

    const [showPaymentMethodSelect, setShowPaymentMethodSelect] = useState(false)
    const [showBillingSelect, setShowBillingSelect] = useState(false)
    const [error, setError] = useState(null)

    function handlePmChange(pm: PaymentMethod){
        api.update(subscription.id, {payment_method_id: pm.id})
            .then(r => setSubscription(r.data))
            .catch(e => setError(e))
            .finally(() => setShowPaymentMethodSelect(false))
    }

    function handleBillingChange(billing: CustomerAddress){
        api.update(subscription.id, {billing_id: billing.id})
            .then(r => setSubscription(r.data))
            .catch(e => setError(e))
            .finally(() => setShowBillingSelect(false))
    }

    return <div className={"card border border-1"}>
        <div className="card-header">
            <h6>
                Détails de l'abonnement
            </h6>
        </div>
        <div className="card-body">
            <div className="row">
                <div className="col-auto mb-3">
                    <table className={"table table-borderless"}>
                        <tbody>
                        <tr>
                            <th>Fournisseur de service</th>
                            <td>
                                <span className="avatar avatar-sm me-2">
                                     <img src={getLogoUrl(subscription.partnership.supplier)} alt={subscription.partnership.supplier.name} className="avatar-img rounded-circle" />
                                 </span>
                                {subscription.partnership.supplier.name}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Actif depuis le
                            </th>
                            <td>
                                {new Date(subscription.startDate).toLocaleDateString()}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Moyen de paiement
                            </th>
                            <td>
                                {subscription.paymentMethod.brand}, **** **** **** {subscription.paymentMethod.last4} <button className={"btn btn-xxs text-decoration-underline text-info"} onClick={() => setShowPaymentMethodSelect(true)}><i className={'bi bi-pencil'}></i> Mettre à jour</button>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Adresse de facturation
                            </th>
                            <td>
                                {subscription.billing.line1}, {subscription.billing.city}, {subscription.billing.postalCode}, {subscription.billing.country.name}
                                <button className={"btn btn-xxs text-decoration-underline text-info"} onClick={() => setShowBillingSelect(true)}><i className={'bi bi-pencil'}></i> Mettre à jour</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <ModalComponent size={'modal-lg'} title={"Mettre à jour le moyen de paiement"} show={showPaymentMethodSelect} onHide={() => setShowPaymentMethodSelect(false)}>
            <CommonPaymentMethodSelect handleSelect={handlePmChange} selected={subscription.paymentMethod} />
        </ModalComponent>
        <ModalComponent size={'modal-lg'} title={"Mettre à jour l'adresse de facturation"} show={showBillingSelect} onHide={() => setShowBillingSelect(false)}>
            <CustomerAddressSelect handleSelect={handleBillingChange} selected={subscription.billing} />
        </ModalComponent>
    </div>
}

export default CustomerSubscriptionInformation;