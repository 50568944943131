import Select from "../../../../../utils/select";
import React, {useEffect, useState} from "react";
import {ProductUnits} from "../../../../../models/product";
import * as api from "../../../../../adapters/corporate/supplier/unit";
import SupplierUnitAdd from "../add";
import ModalComponent from "../../../../modal";

const SupplierUnitSelect = (props: {required: boolean, handleChange: (choice: number) => void, allowAdd: boolean, defaultValue?: {label: string, value: any}}) => {

    const [loading, setLoading] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [payload, setPayload] = useState<{count: number, rows: ProductUnits}>({count: 0, rows: []})
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string}>({offset: 0, query: ""})
    const [defaultValue, setDefaultValue] = useState({label: props.defaultValue?.label || "", value: props.defaultValue?.value || null});

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => console.log(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    return <>
        <div className="row align-items-center">
            <div className="col">
                <Select
                    key={defaultValue.value}
                    value={defaultValue}
                    multiple={false}
                    disabled={loading}
                    required={props.required}
                    label={"Unité de mesure"}
                    options={payload.rows.map(c => ({label: c.label, value: c.id}))}
                    onChange={(c: any) => props.handleChange(c.value)}
                    handleScrollBottom={() => setFetchParams(p => ({...p, offset: p.offset + 1}))}
                    handleQueryChange={(query) => setFetchParams(p => ({query: query, offset: 0}))}
                />
            </div>
            {props.allowAdd && <div className="col-auto">
                <button className={"btn btn-light btn-xs rounded"} type={"button"} onClick={() => setShowModal(true)}>
                    <i className={'bi bi-plus-circle'}></i>
                </button>
            </div>}
        </div>
        <ModalComponent title={"Ajouter une unité"} show={showModal} onHide={() => setShowModal(false)}>
            <SupplierUnitAdd handleSuccess={(unit) => {
                setDefaultValue({label: unit.label, value: unit.id})
                setPayload(prev => ({count: prev.count + 1, rows: [unit, ...prev.rows]}))
                props.handleChange(unit.id)
                setShowModal(false)
            }}/>
        </ModalComponent>
    </>
}

export default SupplierUnitSelect;