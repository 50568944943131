import {Returns} from "../../../../../../models/returns";
import React, {useState} from "react";
import ModalComponent from "../../../../../modal";
import * as api from "../../../../../../adapters/corporate/supplier/returns"
import ErrorCard from "../../../../../error";

const SupplierReturnsShowRefused = ({returns, setReturns, show, setShow}: {returns: Returns, setReturns: React.Dispatch<any>, show: boolean, setShow: React.Dispatch<any>}) => {

    const [reason, setReason] = useState("");
    const [updating, setUpdating] = useState(false)
    const [error, setError] = useState(null);

    function handleSave(){
        setUpdating(true)
        api.update(returns.id, {status: "Refused", refusedReason: reason})
            .then(r => {
                setReturns(r.data)
                setShow(false)
            })
            .catch(e => setError(e))
            .finally(() => setUpdating(false))
    }

    return <ModalComponent title={"Refuser un retour"} show={show} onHide={() => setShow(false)}>
        <h6>
            Souhaitez vous préciser la raison de votre refus ?
        </h6>
        <div className="col-12 mb-3">
            <textarea className={"form-control"} style={{height: 400}} onChange={e => setReason(e.target.value)}></textarea>
        </div>
        {error && <ErrorCard error={error} />}
        <button disabled={updating} className="btn btn-primary w-100" onClick={() => handleSave()}>
            Enregistrer
        </button>
    </ModalComponent>
}

export default SupplierReturnsShowRefused;