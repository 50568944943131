import {Subscription} from "../../../../../models/subscription";
import React, {useEffect, useState} from "react";
import * as models from "../../../../../models/subscription";
import * as api from "../../../../../adapters/customer/subscription/item";
import ErrorCard from "../../../../error";
import CustomerSubscriptionItemListItem from "./item";
import Loader from "../../../../../utils/loader";

const CustomerSubscriptionItemList = ({subscription}: {subscription: Subscription}) => {

    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string}>({offset: 0, query: ''})
    const [payload, setPayload] = useState<{count: number, rows: models.SubscriptionItems}>({count: 0, rows: []})
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        setError(null)
        api.list(subscription.id, fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [subscription.id, fetchParams])

    if (loading){
        return <div className={"col-12 mb-3"}><Loader /></div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    return <div className={'row'}>
        {payload.rows.map(r => <CustomerSubscriptionItemListItem item={r} key={r.id} />)}
    </div>
}

export default CustomerSubscriptionItemList;