import React, {useEffect, useState} from "react";
import {FetchParams, Payload} from "../../../../../models/global";
import {Assignments} from "../../../../../models/assignment";
import * as api from "../../../../../adapters/corporate/supplier/assignment";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import Pagination from "../../../../../utils/pagination";
import SearchInput from "../../../../../utils/searchInput";
import {Link} from "react-router-dom";

const SupplierAssignmentList = () => {

    const [loading, setLoading] = useState(true);
    const [payload, setPayload] = useState<Payload<Assignments>>({count: 0, rows: []});
    const [fetchParams, setFetchParams] = useState<FetchParams>({offset: 0})
    const [error, setError] = useState(null);

    useEffect(() => {
        api.list(fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    return <>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher une annonce..."} onChange={handleQueryChange} size={"default"} />
                </div>
                <div className="col-auto">
                    <Link to={'/corporate/supplier/assignment/add'} className={"btn btn-xs rounded btn-primary"}>
                        <i className={'bi bi-plus-circle'}></i> Ajouter
                    </Link>
                </div>
            </div>
        </div>
        <table className={"table"}>
            <thead>
                <tr>
                    <th>Nom</th>
                    <th>Date de publication</th>
                    <th>Candidatures</th>
                    <th>Statut</th>
                </tr>
            </thead>
            <tbody>
            {payload.rows.map(r => <tr>
                <td><Link to={`/corporate/supplier/assignment/${r.id}`}>{r.name}</Link></td>
                <td>{new Date(r.createdAt).toLocaleDateString()}</td>
                <td>{r.applications.length}</td>
                <td>{r.status}</td>
            </tr>)}
            </tbody>
        </table>
        <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
    </>
}

export default SupplierAssignmentList;