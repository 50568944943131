import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Loader from "../../../../utils/loader";
import * as api from "../../../../adapters/shop/product";
import {Product} from "../../../../models/product";
import {ShopDetails} from "../../../../models/shop";
import ShopProductSuggested from "./suggested";
import ShopProductShowAddToCart from "./addToCart";
import ProductShowCarousel from "../../../corporate/provider/product/carousel";
import * as models from "../../../../models/product";

const ShopProductShow = ({uuid, shopDetails}: {uuid: string, shopDetails: ShopDetails}) => {

    const {id} = useParams();
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState<models.Product & models.SellableProduct>()
    const [tab, setTab] = useState<'description'|'return-policy'>("description")

    useEffect(() => {
        setLoading(true)
        api.show(uuid, Number(id), {countryId: shopDetails.country.id, currencyId: shopDetails.currency.id})
            .then(r => setProduct(r.data))
            .finally(() => setLoading(false))
    }, [id, shopDetails.country.id, shopDetails.currency.id])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (!product){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                Product not found
            </div>
        </div>
    }

    return <>
        <section className={'mb-10'}>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <ProductShowCarousel product={product} key={product.id} />
                </div>
                <div className="col-md-6 mb-3">
                    <ShopProductShowAddToCart product={product} country={shopDetails.country} key={product.id} currency={shopDetails.currency} uuid={uuid} />
                </div>
            </div>
        </section>
        <section className={'py-10 text-center'}>
            <div className="row justify-content-center mb-3">
                <div className="col-auto">
                    <nav className="nav nav-tabs nav-overflow fs-xs mb-3">
                        <button onClick={() => setTab("description")} className={"nav-link text-uppercase" + (tab === "description" ? " active " : "")}>
                            Description
                        </button>
                        <button onClick={() => setTab("return-policy")} className={"nav-link text-uppercase" + (tab === "return-policy" ? " active " : "")}>
                            Politique de retour
                        </button>
                    </nav>
                </div>
            </div>
            {tab === "description" && <div className={"col-12 mb-3"}>
                <p className={'text-muted'}>
                    {product.description}
                </p>
            </div>}
            {tab === "return-policy" && <div className={"col-12 mb-3 text-start"}>
                <div dangerouslySetInnerHTML={{__html: product.returnsPolicy.details}} />
            </div>}

        </section>
        <div className="section mb-10">
            <div className="col-12 mb-3">
                <h3 className={"text-center mb-5"}>Suggestions</h3>
                <ShopProductSuggested uuid={uuid} country={shopDetails.country} currency={shopDetails.currency} categoryId={product.category?.id || null} />
            </div>
        </div>
    </>
}

export default ShopProductShow;