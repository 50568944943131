import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import FormError from "../../../../../utils/formError";
import Select from "../../../../../utils/select";
import {getCurrencies, getCurrencyChoices} from "../../../../../utils/currencies";
import {getCountries, getCountryChoices} from "../../../../../utils/countries";
import * as api from "../../../../../adapters/corporate/supplier/affiliation"
import {Link, useNavigate} from "react-router-dom";
import {Affiliation} from "../../../../../models/affiliation";
import Loader from "../../../../../utils/loader";
import {getAttributionLabel, getUrlTypeLabel} from "../../../../../utils/affiliation";
import ErrorCard from "../../../../error";

const SupplierAffiliationUpdate = ({affiliation, onSuccess}: {affiliation: Affiliation, onSuccess: (affiliation: Affiliation) => void}) => {

    const [error, setError] = useState(null)

    const formik = useFormik({
        initialValues:  {
            name: affiliation.name,
            url: affiliation.url,
            conversionUrl: affiliation.conversionUrl,
            urlType: affiliation.urlType,
            currencies: affiliation.currencies.map((c) => c.id),
            countries: affiliation.countries.map(c => c.id),
            allowedCountries: affiliation.allowedCountries.map(c => c.id),
            attribution: affiliation.attribution
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            url: Yup.string().required(),
            conversionUrl: Yup.string().required(),
            urlType: Yup.string().required(),
            currencies:  Yup.array().of(Yup.string()).min(1).required(),
            countries: Yup.array().of(Yup.string()).min(1).required(),
            allowedCountries: Yup.array().of(Yup.string()).min(1).required(),
            attribution: Yup.string().required(),
        }),
        onSubmit: (values, {setSubmitting}) => {
            api.update(affiliation.id, values)
                .then(r => onSuccess(r.data))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    })

    return <form className={'col-12'} onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" id={'name'} name={"name"} defaultValue={affiliation.name} onChange={formik.handleChange} onBlur={formik.handleBlur} className={'form-control'} />
                <label htmlFor="name">
                    Nom *
                </label>
            </div>
            <FormError form={formik} field={"name"}/>
        </div>
        <div className="col-12 mb-3">
            <div className="input-group">
                <div className="form-floating">
                    <input type="text" id={'url'} name={"url"} defaultValue={affiliation.url} onChange={formik.handleChange} onBlur={formik.handleBlur} className={'form-control'} />
                    <label htmlFor="name">
                        Url * (précisez le http:// ou https://)
                    </label>
                </div>
            </div>
            <FormError form={formik} field={"url"}/>
        </div>
        <div className="col-12 mb-3">
            <div className="input-group">
                <div className="form-floating">
                    <input type="text" id={'conversionUrl'} name={"conversionUrl"} defaultValue={affiliation.conversionUrl} onChange={formik.handleChange} onBlur={formik.handleBlur} className={'form-control'} />
                    <label htmlFor="name">
                        Url de conversion * (précisez le http:// ou https://)
                    </label>
                </div>
            </div>
            <FormError form={formik} field={"conversionUrl"}/>
        </div>
        <div className="col-12 mb-3">
            <Select
                label={"Type d'url *"}
                required={true}
                value={{label: getUrlTypeLabel(affiliation.urlType), value: affiliation.urlType}}
                options={[{label: "SEO", value: "SEO"}, {label: "Paramètre", value: "PARAMETER"}, {label: "Redirection", value: "REDIRECT"}]}
                onChange={c => formik.setFieldValue('urlType', c.value)}
            />
            <FormError form={formik} field={"urlType"}/>
        </div>
        <div className="col-12 mb-3">
            <Select
                label={"Devises *"}
                multiple={true}
                required={true}
                options={getCurrencies()}
                onChange={choices => formik.setFieldValue('currencies', choices.map(c => c.id))}
                values={getCurrencyChoices(affiliation.currencies.map(c => c.code))}
            />
            <FormError form={formik} field={"currencies"}/>
        </div>
        <div className="col-12 mb-3">
            <Select
                label={"Pays d'opération *"}
                multiple={true}
                required={true}
                options={getCountries()}
                onChange={choices => formik.setFieldValue('countries', choices.map(c => c.id))}
                values={getCountryChoices(affiliation.countries.map(c => c.alpha2))}
            />
            <FormError form={formik} field={"countries"}/>
        </div>
        <div className="col-12 mb-3">
            <Select
                label={"Traffic autorisé par pays *"}
                multiple={true}
                required={true}
                options={getCountries()}
                onChange={choices => formik.setFieldValue('allowedCountries', choices.map(c => c.id))}
                values={getCountryChoices(affiliation.allowedCountries.map(c => c.alpha2))}
            />
            <FormError form={formik} field={"allowedCountries"}/>
        </div>
        <div className="col-12 mb-3">
            <Select
                label={"Attribution de conversion *"}
                required={true}
                value={{label: getAttributionLabel(affiliation.attribution), value: affiliation.attribution}}
                options={[{label: "Partage (recommandé)", value: "share"}, {label: "Premier clic", value: "first"}, {label: "Dernier clic", value: "last"}]}
                onChange={c => formik.setFieldValue('attribution', c.value)}
            />
            <FormError form={formik} field={"attribution"}/>
        </div>
        {error && <div className={'col-12'}>
            <ErrorCard error={error} />
        </div>}
        <button className={'btn btn-primary w-100'}>
            {formik.isSubmitting ? <Loader /> : <><i className={'bi bi-check'}></i> Enregistrer</>}
        </button>
    </form>
}

export default SupplierAffiliationUpdate;