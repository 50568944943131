import {post, get} from "../../../../xhr";
import encodeQueryData from "../../../../../utils/encodeQueryData";

interface Interface {
    name?: string,
    delay?: number,
    area_id?: number
}

export function list(requestData: { offset: number, area_id: number }){
    return get(`corporate/supplier/shipping/rate${encodeQueryData(requestData)}`)
}

export function show(id: number){
    return get(`corporate/supplier/shipping/rate/${id}`)
}

export function add(requestData: Interface){
    return post(`corporate/supplier/shipping/rate`, requestData)
}

export function edit(rate: number, requestData: Interface){
    return post(`corporate/supplier/shipping/rate/${rate}`, requestData);
}

