import {useQuery} from "../../../utils/useQuery";
import {useState} from "react";
import AccountAddCustomer from "./customer";

const AccountAdd = () => {

    const query = useQuery();
    const [type, setType] = useState(query.get('type'))

    if (!type){
        return <div className={'row'}>
            <div className="col-md-12 mb-3">
                <button className={'btn btn-outline-primary w-100'} onClick={() => setType('customer')}>
                    Ajouter un compte client <i className={'bi bi-chevron-right'}></i>
                </button>
            </div>
            <div className="col-md-12">
                <button className={'btn btn-outline-primary w-100'} onClick={() => setType('corporate')}>
                    Ajouter un compte fournisseur ou vendeur  <i className={'bi bi-chevron-right'}></i>
                </button>
            </div>
        </div>
    }

    return (<>
        {type === "customer" && <AccountAddCustomer />}
    </>)
}

export default AccountAdd;