import React, {useEffect, useState} from "react";
import {FetchParams, Payload} from "../../../../../models/global";
import {Proposal, Proposals} from "../../../../../models/proposal";
import * as api from "../../../../../adapters/corporate/provider/proposal";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import {Assignment} from "../../../../../models/assignment";
import SearchInput from "../../../../../utils/searchInput";
import {Link} from "react-router-dom";
import ProviderProposalListItem from "./item";

const ProviderProposalList = ({assignment}: {assignment?: Assignment}) => {

    const [loading, setLoading] = useState(true);
    const [payload, setPayload] = useState<Payload<Proposals>>({count: 0, rows: []});
    const [fetchParams, setFetchParams] = useState<FetchParams>({offset: 0})
    const [error, setError] = useState(null);

    useEffect(() => {
        api.list(fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    return <>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher un devis..."} onChange={handleQueryChange} size={"default"} />
                </div>
                {assignment && <div className="col-auto">
                    <Link className={'btn btn-xs btn-primary rounded'} to={`/corporate/provider/proposal/add?assignment_id=${assignment.id}`}>
                        Soumettre un devis <i className={"bi bi-arrow-right"}></i>
                    </Link>
                </div>}
            </div>
        </div>
        <ul className="list-group">
            {payload.rows.map(r => <ProviderProposalListItem _item={r} />)}
        </ul>
    </>
}

export default ProviderProposalList;