import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as api from "../../../../../../adapters/corporate/supplier/product/declination"
import * as models from "../../../../../../models/product"
import FormError from "../../../../../../utils/formError";
import Loader from "../../../../../../utils/loader";
import * as Yup from "yup";
import {Offcanvas} from "bootstrap";

const SupplierProductDeclinationAdd = ({product, setProduct}: {product: models.Product & models.SellableProduct, setProduct: React.Dispatch<any>}) => {

    const [offcanvas, setOffcanvas] = useState<Offcanvas>();

    useEffect(() => {
        let el = document.getElementById('offcanvasSupplierProductDeclinationAdd') as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));
    }, [])

    const formik = useFormik({
        initialValues: {
            name: "",
            multiple: false,
            required: false,
            stockLinked: false,
            fileLinked: false,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            multiple: Yup.boolean().required(),
            required: Yup.boolean().required(),
            stockLinked: Yup.boolean().required(),
            fileLinked: Yup.boolean().required(),
        }),
        onSubmit: values => {
            api.add(product.id, values)
                .then(resp => setProduct((prev: models.Product & models.SellableProduct) => ({...prev, declinations: [...prev.declinations || [], resp.data]})))
                .then(() => formik.resetForm())
                .then(() => formik.setSubmitting(false))
                .then(() => offcanvas!.hide())
                .catch(e => console.log(e))
        }
    });

    return <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasSupplierProductDeclinationAdd"
                aria-labelledby="offcanvasSupplierProductDeclinationAddLabel">
        <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasSupplierProductDeclinationAddLabel"><i className={'bi bi-plus-circle'}></i> Ajouter une déclinaison</h5>
            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvasSupplierProductDeclinationAdd" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input disabled={formik.isSubmitting} type="text" className="form-control" id={'name'} name={'name'}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                        />
                        <label htmlFor="name">
                            Nom
                        </label>
                    </div>
                    <FormError form={formik} field={'name'} />
                </div>
                <div className="col-12 mb-3">
                    <div className="form-check">
                        <input className="form-check-input" onChange={formik.handleChange} type="checkbox" name={'required'} id="required" />
                        <label className="form-check-label" htmlFor="required">
                            Champs obligatoire
                        </label>
                    </div>
                    <FormError form={formik} field={'required'} />
                </div>
                <div className="col-12 mb-3">
                    <div className="form-check">
                        <input className="form-check-input" onChange={formik.handleChange} type="checkbox" name={'multiple'} id="multiple" />
                        <label className="form-check-label" htmlFor="multiple">
                            Choix multiple
                        </label>
                    </div>
                    <FormError form={formik} field={'multiple'} />
                </div>
                <div className="col-12 mb-3">
                    <div className="form-check">
                        <input className="form-check-input" onChange={formik.handleChange} type="checkbox" name={'stockLinked'} id="stockLinked" />
                            <label className="form-check-label" htmlFor="stockLinked">
                                Lié au stock
                            </label>
                    </div>
                    <FormError form={formik} field={'stockLinked'} />
                </div>
                <div className="col-12 mb-3">
                    <div className="form-check">
                        <input className="form-check-input" onChange={formik.handleChange} type="checkbox" name={'fileLinked'} id="fileLinked" />
                            <label className="form-check-label" htmlFor="fileLinked">
                                Lié au média
                            </label>
                    </div>
                    <FormError form={formik} field={'fileLinked'} />
                </div>
                <button disabled={formik.isSubmitting} type={'submit'} className={"btn btn-outline-dark w-100"}>
                    {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-plus-circle'}></i> Ajouter</>}
                </button>
            </form>
        </div>
    </div>
}

export default SupplierProductDeclinationAdd;