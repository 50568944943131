import React, {useEffect, useState} from "react";
import * as api from "../../../../../../adapters/corporate/supplier/taxRate";
import * as productTaxApi from "../../../../../../adapters/corporate/supplier/product/taxRate";
import {TaxRate, TaxRates} from "../../../../../../models/taxRate";
import Pagination from "../../../../../../utils/pagination";
import Loader from "../../../../../../utils/loader";
import * as models from "../../../../../../models/product";

const SupplierProductTaxRateAdd = ({ product, setProduct, unlink }: { product: models.Product & models.SellableProduct, setProduct: React.Dispatch<any>, unlink: (taxRate: TaxRate) => void }) => {

    const [loading, setLoading] = useState(true)
    const [payload, setPayload] = useState<{rows: TaxRates, count: number}>({rows: [], count: 0})
    const [fetchParams, setFetchParams] = useState<{query: string, offset: number}>({query: "", offset: 0})

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(r => setPayload(r.data))
            .then(() => setLoading(false))
    }, [fetchParams])

    function handleChange(taxRate: TaxRate, checked: boolean){
        if (checked){
            productTaxApi.link(product.id, taxRate.id)
                .then(() => setProduct((prev: models.Product& models.SellableProduct) => ({...prev, taxRates: [...prev.taxRates, taxRate]})))
        }else{
            productTaxApi.unlink(product.id, taxRate.id)
                .then(() => unlink(taxRate))
        }
    }

    return <>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        <table className={'table'}>
            <thead>
            <tr>
                <th>

                </th>
                <th>
                    Nom
                </th>
                <th>
                    Description
                </th>
                <th>
                    Pourcentage
                </th>
                <th>
                    Juridiction
                </th>
                <th>
                    Pays
                </th>
            </tr>
            </thead>
            <tbody>
            {payload.rows.map(item => <tr>
                <td>
                    <div className="form-check">
                        <input
                            key={item.id}
                            defaultChecked={product.taxRates.findIndex(i => i.id === item.id) !== -1}
                            className="form-check-input"
                            type="checkbox"
                            onChange={e => handleChange(item, e.target.checked)}
                            id={`item_${item.id}`}
                        />
                    </div>
                </td>
                <td>
                    {item.name}
                </td>
                <td>
                    {item.description}
                </td>
                <td>
                    {item.percentage}%
                </td>
                <td>
                    {item.jurisdiction}
                </td>
                <td>
                    <img style={{height: 18, width: "auto"}} src={item.country.flag} alt={item.country.name}/> {item.country.name}
                </td>
            </tr>)}
            </tbody>
        </table>
        <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
    </>
}

export default SupplierProductTaxRateAdd;