import React from "react";
import SupplierProductList from "../product/list";
import SupplierTaxRateList from "../taxRate/list";
import SupplierUnitList from "../unit/list";
import SupplierBundleList from "../bundle/list";
import {Link, Navigate} from "react-router-dom";
import SupplierFeatureList from "../feature/list";
import SupplierPackList from "../pack/list";
import SupplierFeeRuleList from "../feeRule/list";

const SupplierCatalog = ({tab}: {tab?: "product"|"feeRule"|"tax-rate"|"bundle"|"unit"|"feature"|"pack"}) => {

    const Content = () => {
        switch (tab){
            case "product":
                return <SupplierProductList />
            case "feeRule":
                return <SupplierFeeRuleList />
            case "pack":
                return <SupplierPackList />
            case "tax-rate":
                return <SupplierTaxRateList />
            case "unit":
                return <SupplierUnitList />
            case "bundle":
                return <SupplierBundleList />
            case "feature":
                return <SupplierFeatureList />
            default:
                return <Navigate to={"/corporate/supplier/catalog/product"} />
        }
    }

    return <div className={'container'}>
        <div className="col-12 mb-3">
            <div className="mb-2 mb-lg-0 border-bottom-lg">
                <nav className="nav nav-tabs nav-overflow fs-xs border-bottom border-bottom-lg-0">
                    <Link to={'/corporate/supplier/catalog/product'} className={"nav-link text-uppercase" + (tab === 'product' ? " active " : "")}>
                        Produits / Services
                    </Link>
                    <Link to={'/corporate/supplier/catalog/fee-rule'} className={"nav-link text-uppercase" + (tab === 'feeRule' ? " active " : "")}>
                        Règles de commissions
                    </Link>
                    <Link to={'/corporate/supplier/catalog/pack'} className={"nav-link text-uppercase" + (tab === 'pack' ? " active " : "")}>
                        Packages
                    </Link>
                    <Link to={'/corporate/supplier/catalog/bundle'} className={"nav-link text-uppercase" + (tab === "bundle" ? " active " : "")}>
                        Grille tarifaire
                    </Link>
                    <Link to={'/corporate/supplier/catalog/tax-rate'} className={"nav-link text-uppercase" + (tab === "tax-rate" ? " active " : "")}>
                        Taux de taxe
                    </Link>
                    <Link to={'/corporate/supplier/catalog/unit'} className={"nav-link text-uppercase" + (tab === "unit" ? " active " : "")}>
                        Unités de mesure
                    </Link>
                    <Link to={'/corporate/supplier/catalog/feature'} className={"nav-link text-uppercase" + (tab === "feature" ? " active " : "")}>
                        Fonctionnalités
                    </Link>
                </nav>
            </div>
        </div>
        <Content />
    </div>
}

export default SupplierCatalog;