import React, { useState } from 'react';
import FormError from "../../../utils/formError";

const TextAreaComponent = ({ formik, name, maxLength, label }: {formik: any, name: string, maxLength: number, label: string}) => {
    const [charCount, setCharCount] = useState(0);

    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        if (value.length <= 255) {
            formik.handleChange(e); // Met à jour formik
            setCharCount(value.length); // Met à jour le compteur
        }
    };

    return (
        <div className="col-12 mb-3">
            <div className="form-floating">
                <textarea
                    style={{ minHeight: 200 }}
                    className={'form-control'}
                    name={name}
                    id={name}
                    onBlur={formik.handleBlur}
                    onChange={handleTextChange}
                    value={formik.values[`${name}`]} // Assure que le champ reste contrôlé
                    maxLength={maxLength} // Ajout de la limite
                ></textarea>
                <label htmlFor={name}>
                    {label}
                </label>
            </div>
            <div className="d-flex justify-content-end">
                <small>{charCount}/255</small>
            </div>
            <FormError form={formik} field={name} />
        </div>
    );
};

export default TextAreaComponent;