import encodeQueryData from "../../../../utils/encodeQueryData";
import {post, get} from "../../../xhr";

export function list(requestData: { query: string, offset: number}){
    return get(`corporate/provider/touch${encodeQueryData(requestData)}`)
}

export function show(touch: number){
    return get(`corporate/provider/touch/${touch}`)
}

export function add(requestData: any){
    return post(`corporate/provider/touch`, requestData)
}

export function update(touch: number, requestData: any){
    return post(`corporate/provider/touch/${touch}`, requestData);
}

