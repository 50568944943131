import React, {useEffect, useState} from "react";
import * as paymentMethodApi from "../../../adapters/common/paymentMethod";
import {PaymentMethod, PaymentMethods} from "../../../models/paymentMethod";
import * as api from "../../../adapters/checkout";
import * as models from "../../../models/checkout";
import Pagination from "../../../utils/pagination";
import ModalComponent from "../../modal";
import CommonPaymentMethodAdd from "../../common/paymentMethod/add";
import Loader from "../../../utils/loader";
import ErrorCard from "../../error";

const CheckoutPaymentMethod = ({checkout, setCheckout}: {checkout: models.CheckoutResponse, setCheckout: React.Dispatch<any>}) => {

    const [loading, setLoading] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [payload, setPayload] = useState<{count: number, rows: PaymentMethods}>({count: 0, rows: []})
    const [fetchParams, setFetchParams] = useState<{offset: number}>({offset: 0})
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        paymentMethodApi.list(fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    function handleChange(paymentMethod: PaymentMethod){
        api.paymentMethod(checkout.uuid, paymentMethod.id)
            .then(r => setCheckout(r.data))
            .catch(e => setError(e))
            .finally(() => setUpdating(false))
    }

    return <>
        <p className={"fw-semibold mb-3"}>
            Séléctionner un moyen de paiement
        </p>
        <ul className={'list-group'}>
            {loading && <Loader />}
            {error && <ErrorCard error={error} />}
            {payload.rows.map(r => <li className={'list-group-item'}>
                <div className="row align-items-center">
                    <div className="col-auto">
                        <div className="form-check">
                            <input disabled={loading || updating} checked={r.id === checkout.payment?.paymentMethod?.id} className="form-check-input" type="checkbox" onChange={() => handleChange(r)} id={`payment_method_${r.id}`} />
                        </div>
                    </div>
                    <div className="col">
                        <label className="form-check-label" htmlFor={`payment_method_${r.id}`}>
                            {r.brand}, **** **** **** {r.last4}
                        </label>
                    </div>
                </div>
            </li>)}
            <li className={"list-group-item"}>
                <span className={'text-decoration-underline cursor-pointer'} onClick={() => setShowModal(true)}>
                    <i className={"bi bi-plus-circle"}></i> Ajouter un moyen de payement
                </span>
            </li>
        </ul>
        <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        <ModalComponent title={"Ajouter un moyen de paiement"} show={showModal} onHide={() => setShowModal(false)}>
            <CommonPaymentMethodAdd onSuccess={pm => {
                setPayload(prev => ({count: prev.count + 1, rows: [pm, ...prev.rows]}))
                handleChange(pm)
                setShowModal(false)
            }} />
        </ModalComponent>
    </>
}

export default CheckoutPaymentMethod;