import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function add(requestData: any){
    return post("corporate/supplier/affiliation", requestData)
}

export function update(affiliationId : number, requestData: any){
    return post(`corporate/supplier/affiliation/${affiliationId}`, requestData)
}

export function activation(affiliationId : number, requestData: any){
    return post(`corporate/supplier/affiliation/${affiliationId}/activation`, requestData)
}

export function list(requestData: {query?: string, offset: number}){
    return get(`corporate/supplier/affiliation${encodeQueryData(requestData)}`);
}

export function show(affiliation: number){
    return get(`corporate/supplier/affiliation/${affiliation}`);
}

export function statistics(affiliation: number, requestData: {startDate: Date, endDate: Date, granularity: string}){
    return post(`corporate/supplier/affiliation/${affiliation}/statistics`, requestData);
}

export function partnerships(affiliation: number, requestData: {startDate: Date, endDate: Date}){
    return post(`corporate/supplier/affiliation/${affiliation}/partnerships`, requestData);
}