import * as api from "../../../adapters/checkout"
import React, {useEffect, useState} from "react";
import Loader from "../../../utils/loader";
import {useNavigate} from "react-router-dom";
import * as models from "../../../models/checkout";
import CheckoutDeliveryParcel from "./parcel";

const CheckoutDelivery = ({checkout, setCheckout}: {checkout: models.CheckoutResponse, setCheckout: React.Dispatch<any>}) => {

    const [loading, setLoading] = useState(true);
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true);
        api.delivery(checkout.uuid)
            .then(r => setCheckout(r.data))
            .finally(() => setLoading(false))
    }, [])

    const validateParcels = () => {
        setShowError(false)
        if (checkout.parcels.filter(s => s.status === "UnShippable").length > 0){
            setErrorMessage("Veuillez retirer les articles non supportés pour continuer");
        }else if (checkout.parcels.filter(p => p.options.filter(o => o.selected).length === 0).length > 0){
            setShowError(true)
            setErrorMessage('Veuillez choisir une option pour chaque colis.')
        }else{
            navigate(`/checkout/${checkout.uuid}/payment`)
        }
    }

    return <div className={'col-12'}>
        {loading && <div className={'col-12 py-5 text-center'}><Loader /></div>}
        {!loading && checkout.parcels.map((s, index) => <CheckoutDeliveryParcel showError={showError} parcel={s} checkout={checkout} setCheckout={setCheckout} />)}
        <button onClick={() => validateParcels()} className={'btn btn-light w-100'}>
            Suivant <i className={'bi bi-arrow-right'}></i>
        </button>
        {errorMessage && <p className={"text-danger"}>{errorMessage}</p>}
    </div>
}

export default CheckoutDelivery;