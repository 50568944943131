import InvoiceStatusPlaceholder from "../../status";
import CorporateInvoiceDownload from "../../../invoice/download";
import React from "react";
import {Invoice} from "../../../../../../models/invoice";

const CommonInvoiceListItem = ({invoice}: {invoice: Invoice}) => {

    return <tr>
        <td>
            {invoice.number}
        </td>
        <td>
            <InvoiceStatusPlaceholder status={invoice.status} />
        </td>
        <td>
            <CorporateInvoiceDownload invoice={invoice} />
        </td>
    </tr>
}

export default CommonInvoiceListItem;