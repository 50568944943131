import * as models from "../../../../../../../../../models/affiliation";
import React, {useState} from "react";
import ModalComponent from "../../../../../../../../modal";
import convertToDecimal from "../../../../../../../../../utils/currencyConverter";

const ProviderAffiliationClickRuleListItem = ({item}: {item: models.ClickRule}) => {

    const [showModal, setShowModal] = useState(false);

    return <tr>
        <td>
            {item.name}
        </td>
        <td>
            {item.lifetime} {item.lifetimeType}
        </td>
        <td>
            {item.validation}
        </td>
        <td>
            <button className={'btn btn-xxs'} onClick={() => setShowModal(true)}>
                <i className={'bi bi-eye'}></i> Compensations
            </button>
            <ModalComponent title={`Compensations ${item.name}`} show={showModal} onHide={() => setShowModal(false)}>
                <table className={'table'}>
                    <tbody>
                        {item.compensations.map(c => <tr>
                            <th>{c.currency.name} ({c.currency.symbol})</th>
                            <td>{convertToDecimal(c.amount, c.currency)}</td>
                        </tr>)}
                    </tbody>
                </table>
            </ModalComponent>
        </td>
    </tr>
}

export default ProviderAffiliationClickRuleListItem;