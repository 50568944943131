import encodeQueryData from "../../../../utils/encodeQueryData";
import {post, get} from "../../../xhr";

export function list(requestData: { query?: string, offset: number}){
    return get(`corporate/provider/service${encodeQueryData(requestData)}`)
}

export function show(service: number){
    return get(`corporate/provider/service/${service}`)
}

export function add(requestData: any){
    return post(`corporate/provider/service`, requestData)
}

export function update(service: number, requestData: any){
    return post(`corporate/provider/service/${service}`, requestData);
}