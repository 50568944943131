import React, {useState} from "react";
import * as models from "../../../../../../../models/product";
import * as api from "../../../../../../../adapters/corporate/supplier/product/support";
import ModalComponent from "../../../../../../modal";
import FileViewer from "../../../../../../fileViewer";
import {getProductSupportUrl} from "../../../../../../../utils/image";

const SupplierProductSupportListItem = ({product, item, handleDestroy}: {product: models.Product, item: models.ProductSupport, handleDestroy: (support: models.ProductSupport) => void}) => {

    const [showModal, setShowModal] = useState(false)

    function _destroy(){
        api.remove(product.id, item.id)
            .then(r => handleDestroy(item))
    }

    return <tr>
        <td>{item.originalName}</td>
        <td>{new Date(item.createdAt).toLocaleDateString()}</td>
        <td>{item.mimeType}</td>
        <td>
            <button className={"btn btn-light btn-xxs me-2"} onClick={() => setShowModal(true)}>
                <i className={'bi bi-eye'}></i>
            </button>
            <ModalComponent size={"modal-xl"} title={item.originalName} show={showModal} onHide={() => setShowModal(false)}>
                <FileViewer url={getProductSupportUrl(product, item)} mimeType={item.mimeType} />
            </ModalComponent>
            <button className={"btn btn-light btn-xxs"} onClick={() => _destroy()}>
                <i className={'bi bi-dash-circle text-danger'}></i>
            </button>
        </td>
    </tr>
}

export default SupplierProductSupportListItem;