import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as api from "../../../../../adapters/corporate/supplier/feature"
import FormError from "../../../../../utils/formError";
import Loader from "../../../../../utils/loader";
import * as Yup from "yup";
import {Feature} from "../../../../../models/feature";
import ErrorCard from "../../../../error";

const SupplierFeatureAdd = ({handleSuccess}: {handleSuccess: (feature: Feature) => void}) => {

    const [error, setError] = useState(null);

    const formik = useFormik({
        initialValues: {
            description: ""
        },
        validationSchema: Yup.object().shape({
            description: Yup.string().required()
        }),
        enableReinitialize: true,
        onSubmit: (values: any) => {
            api.add(values)
                .then(r => handleSuccess(r.data))
                .catch(error => setError(error))
                .finally(() => formik.setSubmitting(false))
        }
    });

    return <form onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input className="form-control" type="text" id="description" name={"description"} onChange={formik.handleChange} />
                <label htmlFor="description">
                    Description
                </label>
            </div>
            <FormError form={formik} field={"description"} />
        </div>
        {error && <ErrorCard error={error} />}
        <button disabled={formik.isSubmitting} type={'submit'} className={"btn btn-light w-100"}>
            {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-plus-circle'}></i> Ajouter</>}
        </button>
    </form>
}

export default SupplierFeatureAdd;