import {post} from "../../../../xhr";

export function edit(pid: number, did: number, requestData: {}){
    return post(`/corporate/supplier/product/${pid}/dimension/${did}`, requestData);
}

export function add(pid: number, requestData: {
    height: number,
    width: number,
    depth: number,
    length: number,
    count: number,
    weight: number,
}){
    return post(`/corporate/supplier/product/${pid}/dimension`, requestData);
}