import React from "react";

const ReturnStatusPlaceholder = ({status, displayCaret}: {status: string, displayCaret?: boolean}) => {

    switch (status){
        case "Pending":
            return <span className={'badge bg-light text-dark'}>
                    En attente
                </span>
        case "All":
            return <span className={'badge bg-light text-dark'}>
                    Tous les statuts
                </span>
        case "Processing":
            return <span className={'badge bg-warning'}>
                    Traitement {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Shipped":
            return <span className={'badge bg-info'}>
                    Envoyé {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Delivered":
            return <span className={'badge bg-success'}>
                    Délivré {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Received":
            return <span className={'badge bg-success'}>
                    Reçu {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
    }

    return <>Invalid status</>
}

export default ReturnStatusPlaceholder;