import {Proposal} from "../../../../../../models/proposal";
import convertToDecimal from "../../../../../../utils/currencyConverter";
import CommonProposalStatus from "../../../../common/proposal/status";
import React, {useState} from "react";
import * as api from '../../../../../../adapters/corporate/provider/proposal';
import ErrorCard from "../../../../../error";
import ModalComponent from "../../../../../modal";
import ProviderProposalUpdate from "../../update";

const ProviderProposalListItem = ({_item}: {_item: Proposal}) => {

    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState(null);
    const [item, setItem] = useState<Proposal>(_item)
    const [showModal, setShowModal] = useState(false)
    const [showUpdateModal, setShowUpdateModal] = useState(false)

    function cancel(){
        api.update(item.id, {status: "Canceled"})
            .then(r => setItem(r.data))
            .catch(e => setError(e))
            .finally(() => setUpdating(false))
    }

    function publish(){
        api.update(item.id, {status: "Published"})
            .then(r => setItem(r.data))
            .catch(e => setError(e))
            .finally(() => setUpdating(false))
    }

    if (error){
        return <li className={'list-group-item'}>
            <ErrorCard error={error} />
        </li>
    }

    return <li className={'list-group-item'}>
        <div className="row align-items-center">
            <div className="col mb-3">
                <p className={"fw-semibold mb-1"}>{convertToDecimal(item.priceData.amount_including_tax, item.priceData.currency)} TTC</p>
                <p className={"text-muted mb-0"}>{new Date(item.createdAt).toLocaleDateString()}</p>
            </div>
            <div className="col-auto mb-3">
                <CommonProposalStatus status={item.status} />
            </div>
        </div>
        <div className="row">
            <div className="col-auto mb-3">
                <button className="btn btn-light btn-xxs" onClick={() => setShowModal(true)}>
                    Afficher <i className={'bi bi-arrow-right'}></i>
                </button>
            </div>
            {['Pending'].includes(item.status) && <>
                <div className="col-auto mb-3">
                    <button className="btn btn-light btn-xxs" onClick={() => setShowUpdateModal(true)} disabled={updating}>
                        <i className={'bi bi-pencil'}></i> Modifier
                    </button>
                </div>
                <div className="col-auto mb-3">
                    <button className="btn btn-light btn-xxs" onClick={() => publish()} disabled={updating}>
                        <i className={'bi bi-send'}></i> Publier
                    </button>
                </div>
            </>}
            {['Pending', 'Published'].includes(item.status) && <>
                <div className="col-auto mb-3">
                    <button className="btn btn-light btn-xxs" onClick={() => cancel()} disabled={updating}>
                        <i className={'bi bi-x text-danger'}></i> Annuler
                    </button>
                </div>
            </>}
        </div>
        {showModal && <ModalComponent title={'Détail du devis'} size={"modal-lg"} show={showModal} onHide={() => setShowModal(false)}>
            <div className="col-12 mb-3">
                <table className={'table'}>
                    <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Description</th>
                        <th>Prix unité HT</th>
                        <th>Quantité</th>
                        <th>Unité</th>
                        <th>Taux de taxe</th>
                    </tr>
                    </thead>
                    <tbody>
                    {item.rows.map(r => <tr>
                        <td>{r.name}</td>
                        <td>{r.description}</td>
                        <td>{convertToDecimal(r.amount, item.currency)}</td>
                        <td>{r.quantity}</td>
                        <td>{r.unit}</td>
                        <td>{r.taxRate}%</td>
                    </tr>)}
                    </tbody>
                </table>
            </div>
            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-body">
                        <h6>Récapitulatif du devis</h6>
                        <table className={'table table-borderless'}>
                            <tbody>
                            <tr>
                                <th>Montant HT</th>
                                <td>{convertToDecimal(item.priceData.amount_excluding_tax, item.currency)}</td>
                            </tr>
                            <tr>
                                <th>Montant taxes</th>
                                <td>{convertToDecimal(item.priceData.tax_amount, item.currency)}</td>
                            </tr>
                            <tr>
                                <th>Montant TTC</th>
                                <td>{convertToDecimal(item.priceData.amount_including_tax, item.currency)}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ModalComponent>}
        {showUpdateModal && <ModalComponent title={'Mettre à jour la proposition'} size={"modal-xl"} show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
            <ProviderProposalUpdate proposal={item} onSuccess={updatedItem => {
                setItem(updatedItem)
                setShowUpdateModal(false)
            }} />
        </ModalComponent>}
    </li>
}

export default ProviderProposalListItem;