import {Affiliation} from "../../../../../../models/affiliation";
import {useState} from "react";
import SupplierClickList from "../../../click/list";
import SupplierConversionList from "../../../conversion/list";

const SupplierAffiliationTracking = ({affiliation}: {affiliation: Affiliation}) => {

    const [tab, setTab] = useState<'conversion'|'click'>('conversion')
    const [status, setStatus] = useState<'All'|'Pending'|'Accepted'|'Refused'|'Paid'>('All')

    return <>
        <div className={'row justify-content-between'}>
            <div className="col-auto my-3">
                <ul className="nav nav-pills mx-auto">
                    <li className="nav-item">
                        <button className={`nav-link ${tab === "conversion" ? 'active' : ''}`} onClick={() => setTab('conversion')}>
                            Conversion
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link ${tab === "click" ? 'active' : ''}`} onClick={() => setTab('click')}>
                            Click
                        </button>
                    </li>
                </ul>
            </div>
            <div className="col-auto my-3">
                <ul className="nav nav-pills mx-auto">
                    <li className="nav-item">
                        <button className={`nav-link ${status === "All" ? 'active' : ''}`} onClick={() => setStatus('All')}>
                            Tout les statuts
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link ${status === "Pending" ? 'active' : ''}`} onClick={() => setStatus('Pending')}>
                            En attente
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link ${status === "Accepted" ? 'active' : ''}`} onClick={() => setStatus('Accepted')}>
                            Accepté
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link ${status === "Refused" ? 'active' : ''}`} onClick={() => setStatus('Refused')}>
                            Refusé
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link ${status === "Paid" ? 'active' : ''}`} onClick={() => setStatus('Paid')}>
                            Payé
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <div className="col-12">
            {tab === "conversion" && <SupplierConversionList affiliation={affiliation} status={status} key={`${affiliation.id}_${status}`} />}
            {tab === "click" && <SupplierClickList affiliation={affiliation} status={status} key={`${affiliation.id}_${status}`} />}
        </div>
    </>
}

export default SupplierAffiliationTracking;