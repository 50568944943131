import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query?: string, offset: number, count?: number, status: string, customer_id?: number}){
    return get(`corporate/supplier/order${encodeQueryData(requestData)}`);
}

export function show(order: number){
    return get(`corporate/supplier/order/${order}`);
}

export function update(order: number, requestData: {status: string}){
    return post(`corporate/supplier/order/${order}`, requestData);
}
