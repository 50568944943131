import React, {useEffect, useState} from "react";
import * as models from "../../../../../models/webhook";
import * as api from "../../../../../adapters/corporate/supplier/webhook"
import SearchInput from "../../../../../utils/searchInput";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import Pagination from "../../../../../utils/pagination";
import {Link} from "react-router-dom";

const SupplierWebhookList = () => {

    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{query: string, offset: number}>({query: "", offset: 0});
    const [payload, setPayload] = useState<{count: number, rows: models.Webhooks}>({count: 0, rows: []});
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        setError(null)
        api.list(fetchParams)
            .then(r => setPayload(r.data))
            .catch(error => setError(error))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={'container container-fluid'}>

        <div className="col-12 mb-3">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher un endpoint..."} onChange={handleQueryChange} size={"default"} />
                </div>
                <div className="col-auto">
                    <Link to={"/corporate/supplier/webhook/add"} className={'btn btn-primary'}>
                        <i className={'bi bi-plus-circle'}></i> Ajouter
                    </Link>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        {error && <ErrorCard error={error} />}
        <table className={'table'}>
            <thead>
                <tr>
                    <th>
                        Endpoint
                    </th>
                    <th>
                        Description
                    </th>
                    <th>
                        État
                    </th>
                </tr>
            </thead>
            <tbody>
                {payload.rows.map(r => <tr>
                    <td>
                        <Link to={`${r.id}`}>
                            {r.endpoint}
                        </Link>
                    </td>
                    <td>
                        {r.description}
                    </td>
                    <td>
                        {r.active ? <span className={'badge bg-success'}> <i className={"bi bi-check"}></i> Activé</span> : <span className={"badge bg-danger"}><i className={"bi bi-x"}></i> Desactivé</span>}
                    </td>
                </tr>)}
            </tbody>
        </table>
        <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
    </div>
}

export default SupplierWebhookList;