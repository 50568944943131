import React, {useEffect} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useAppDispatch} from "../../hooks";
import authSlice from "../../reducers/auth";

const ErrorCard = ({error}: {error: any}) => {

    const location = useLocation();
    const dispatch = useAppDispatch()

    const Icon = () => {
        if (error.response){
            switch (error.response.status){
                case 404:
                    return <i className={'bi bi-search'}></i>
                case 500:
                    return <i className={'bi bi-exclamation-circle text-danger'}></i>
                case 400:
                    return <i className={'bi bi-exclamation-triangle text-warning'}></i>
                case 302:
                    return <i className={'bi bi-arrow-left-right text-dark'}></i>
            }
        }

        return <i className={'bi bi-exclamation-circle text-danger'}></i>
    }
    const getColor = () => {
        if (error.response){
            switch (error.response.status){
                case 404:
                    return "info";
                case 500:
                    return "danger";
                case 400:
                    return "warning";
                case 302:
                    return "light";
            }
        }


        return "danger"
    }

    const StatusText = () => {
        if (error.response){
            return <>{error.response.statusText}</>
        }

        return <>Une erreur est survenue</>
    }

    if (error.response && error.response.status === 401)
    {
        dispatch(authSlice.actions.logout())
        return <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname)}`} replace={true}  />
    }

    if (error.response && error.response.status === 302)
    {
        if (error.response.data.user){
            dispatch(authSlice.actions.refreshAuth(error.response.data.user))
        }

        return <Navigate to={`${error.response.data.redirectTo}`} replace={true}  />
    }

    return <div className={`card card-xxxs border border-1 border-${getColor()} bg-${getColor()}-subtle mb-3`}>
        <div className="card-body">
            <p className={'fw-semibold mb-0'}>
                <Icon /> <StatusText />
            </p>
            {error.response && error.response.data.error && <p className={'mb-0 mt-2'}>
                {error.response.data.error}
            </p>}
        </div>
    </div>
}

export default ErrorCard;