import * as models from "../../../../../../models/post";
import {Link} from "react-router-dom";
import {getLogoUrl, getPostFileUrl} from "../../../../../../utils/image";
import React, {useRef, useState} from "react";
import ModalComponent from "../../../../../modal";
import PostCommentList from "../../comment/list";
import * as api from "../../../../../../adapters/corporate/common/post/reaction"
import PostCommentAdd from "../../comment/add";

const PostListItem = ({post}: {post: models.Post}) => {

    const [showModal, setShowModal] = useState(false);
    const [commentCount, setCommentCount] = useState(post.commentCount);
    const [reactionCount, setReactionCount] = useState(post.reactionCount);
    const [myReaction, setMyReaction] = useState<models.Reaction | null>(post.myReaction);
    const commentListRef = useRef<{ addComment: (comment: models.PostComment) => void }>();

    function handleReactionButtonClick(){
        if(myReaction){
            api.remove(post.id, myReaction.id)
                .then(r => setMyReaction(null)).then(() => setReactionCount(p => p - 1))
                .catch(e => console.log(e))
        }else{
            api.add(post.id, {type: 'heart'})
                .then(r => setMyReaction(r.data)).then(() => setReactionCount(p => p + 1))
                .catch(e => console.log(e))
        }
    }

    const PostContent = () => {
        return (
            <>
                <div className="d-flex align-items-center mb-2">
                    <div className="flex-grow-0">
                        <Link to={`/corporate/organization/${post.account.organization.id}`} className="avatar avatar-lg" id={`popoverContainer_${post.id}`}>
                            <img src={getLogoUrl(post.account.organization)} alt={post.account.organization.name} className="avatar-img rounded-circle" />
                        </Link>
                    </div>
                    <div className="flex-grow-0 d-flex flex-column ms-2">
                        <Link className={'text-dark'} to={`organization/${post.account.organization.id}`}>
                            {post.account.firstname} {post.account.lastname}
                        </Link>
                        <Link to={`organization/${post.account.organization.id}`} className={'text-muted fs-xs'}>
                            {post.account.organization.name}
                        </Link>
                    </div>
                    <div className="flex-grow-1 text-end">
                        <small className={`badge ${post.account.organization.type === "provider" ? "bg-info" : "bg-success"}`}>
                            {post.account.organization.type === "provider" ? "Prestataire" : "Fournisseur"}
                        </small>
                    </div>
                </div>
                <div className={'mb-2'} style={{whiteSpace: "pre-wrap"}}>
                    {post.content}
                </div>
            </>
        )
    }

    const PostMetaData = () => {
        return <div className="row align-items-center">
            <div className="col">
                <button className={'btn btn-xs text-decoration-underline'}>
                    <i className={'bi bi-hand-thumbs-up'}></i> {reactionCount}
                </button>
            </div>
            <div className="col-auto">
                <button className={'btn btn-xxs text-decoration-underline'} onClick={() => setShowModal(true)}>
                    {commentCount} Commentaires
                </button>
            </div>
        </div>
    }

    return <div className={"col-12 mb-3"}>
        <div className="card border border-1 rounded">
            <div className="card-body">
                <PostContent />
                <div className="col-12">
                    <hr className={"mt-0 mb-1"}/>
                </div>
                <div className="col-12">
                    <PostMetaData />
                    <hr className={'my-1'} />
                    <div className="row">
                        <div className="col">
                            <button className={'btn btn-xxs w-100 text-nowrap'} onClick={() => handleReactionButtonClick()}>
                                <i className={myReaction ? 'bi bi-hand-thumbs-up-fill text-primary' : 'bi bi-hand-thumbs-up'}></i> <span className={'d-none d-md-inline'}>J'aime</span>
                            </button>
                        </div>
                        <div className="col">
                            <button className={'btn btn-xxs w-100 text-nowrap'} onClick={() => setShowModal(true)} >
                                <i className={'bi bi-chat'}></i> <span className={'d-none d-md-inline'}>Commenter</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {showModal && <ModalComponent
            size={"modal-lg"}
            title={`Publication de ${post.account.firstname} ${post.account.lastname}`}
            show={showModal}
            onHide={() => setShowModal(false)}
            footerContent={<PostCommentAdd post={post} handleSuccess={c => {
                if (commentListRef.current){
                    commentListRef.current.addComment(c)
                }
            }}/>}
        >
            <PostContent />
            <PostMetaData />
            <hr className={'my-1'} />
            <div className="row">
                <div className="col">
                    <button className={'btn btn-xxs w-100 text-nowrap'} onClick={() => handleReactionButtonClick()}>
                        <i className={myReaction ? 'bi bi-hand-thumbs-up-fill text-primary' : 'bi bi-hand-thumbs-up'}></i> <span className={'d-none d-md-inline'}>J'aime</span>
                    </button>
                </div>
                <div className="col">
                    <button className={'btn btn-xxs w-100 text-nowrap'} onClick={() => setShowModal(true)} >
                        <i className={'bi bi-share'}></i> <span className={'d-none d-md-inline'}>Republier</span>
                    </button>
                </div>
            </div>
            <hr className={'my-1'} />
            <div className="col-12 mb-0 position-relative">
                <PostCommentList
                    handleAddedComment={() => setCommentCount(p => p + 1)}
                    post={post}
                    depth={0}
                    setCallback={(callback) => commentListRef.current = { addComment: callback }}
                />
            </div>
        </ModalComponent>}
        </div>
}

export default PostListItem;