import React, {useState} from "react";
import ModalComponent from "../../../../../modal";
import {Returns} from "../../../../../../models/returns";
import {useFormik} from "formik";
import * as Yup from "yup";
import SupplierReturnsAddressesSelect from "../../../returnsAddress/select";
import Loader from "../../../../../../utils/loader";
import * as api from "../../../../../../adapters/corporate/supplier/returns";
import ErrorCard from "../../../../../error";

const SupplierReturnsShowAccepted = ({show, setShow, returns, setReturns}: {show: boolean, setShow: React.Dispatch<any>, returns: Returns, setReturns: React.Dispatch<any>}) => {

    const [error, setError] = useState(null)
    const [initialValues, setInitialValues] = useState<any>({
        status: "Accepted",
        hasReturn: true,
        address_id: null,
        type: "Refund"
    });

    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        status: Yup.string().required(),
        hasReturn: Yup.boolean().required(),
        address_id: Yup.number().required(),
        type: Yup.string().required(),
    }))

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            api.update(returns.id, values)
                .then(r => {
                    setReturns(r.data)
                    setShow(false)
                })
                .catch(e => setError(e))
                .finally(() => formik.setSubmitting(false))
        }
    })

    function handleHasReturnChange(event: React.ChangeEvent<HTMLInputElement>)
    {
        const values = {...formik.values};
        const _fields = {...validationSchema.fields};
        let hasReturn = event.target.value === '1';

        if (hasReturn)
        {
            values.address_id = null;
            _fields['address_id'] = Yup.number().required();
        }else{
            delete _fields['address_id']
            delete values["address_id"]
        }

        setInitialValues({...values, hasReturn: hasReturn})
        setValidationSchema(Yup.object().shape(_fields))
    }

    return <ModalComponent title={"Accepter un retour"} show={show} onHide={() => setShow(false)}>
        <form onSubmit={formik.handleSubmit}>
            <div className={'col-12 mb-3'}>
                <h6>
                    Le client doit-il vous retourner les articles ?
                </h6>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        value={1}
                        defaultChecked={initialValues.hasReturn === true}
                        name="hasReturn"
                        id="hasReturnYes"
                        onChange={handleHasReturnChange}
                    />
                    <label className="form-check-label" htmlFor="hasReturnYes">
                        Oui
                    </label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        value={0}
                        defaultChecked={initialValues.hasReturn === false}
                        name="hasReturn"
                        id="hasReturnNo"
                        onChange={handleHasReturnChange}
                    />
                    <label className="form-check-label" htmlFor="hasReturnNo">
                        Non
                    </label>
                </div>
            </div>
            {formik.values.hasReturn && <div className="col-12 mb-3">
                <SupplierReturnsAddressesSelect
                    label={"Addresse de retour"}
                    required={true}
                    handleChange={c => formik.setFieldValue('address_id', c)}
                />
            </div>}
            <div className={'col-12 mb-3'}>
                <div className="row">
                    <div className="col">
                        <h6>
                            Comment souhaiter vous compenser votre client ?
                        </h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className={`card border border-1 ${formik.values.type === "Replacement" ? "border-success": ""}`}>
                            <div className="card-body">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        value={"Replacement"}
                                        defaultChecked={initialValues.type === "Replacement"}
                                        name="type"
                                        id="typeReplace"
                                        onChange={formik.handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="typeReplace">
                                        Remplacement
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={`card border border-1 ${formik.values.type === "Refund" ? "border-success": ""}`}>
                            <div className="card-body">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        value={"Refund"}
                                        defaultChecked={initialValues.type === false}
                                        name="type"
                                        id="typeRefund"
                                        onChange={formik.handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="typeRefund">
                                        Remboursement
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {error && <ErrorCard error={error} />}
            <button className={'btn btn-primary w-100'} type={"submit"}>
                {formik.isSubmitting ? <Loader size={'sm'} /> : <>Enregistrer <i className={'bi bi-arrow-right'}></i></>}
            </button>
        </form>
    </ModalComponent>
}

export default SupplierReturnsShowAccepted;