import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import * as api from '../../../../../adapters/corporate/supplier/product';
import SearchInput from "../../../../../utils/searchInput";
import {Products} from "../../../../../models/product";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";
import {getCoverUrl} from "../../../../../utils/image";
import Error from "../../../../error";

const SupplierProductList = () => {

    const [error, setError] = useState(null);
    const [payload, setPayload] = useState<{ count: number, rows: Products }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number}>({query: "", offset: 0})
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        api.list({offset: fetchParams.offset, query: fetchParams.query})
            .then(resp => setPayload(resp.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher un produit..."} onChange={handleQueryChange} size={"default"} />
                </div>
                <div className="col-auto">
                    <Link to={"/corporate/supplier/product/add"} className={'btn btn-primary btn-xs rounded'}>
                        <i className={'bi bi-plus-circle'}></i> Ajouter
                    </Link>
                </div>
            </div>
        </div>
        {loading && <div className="col-12 mb-3 text-center">
            {loading ? <Loader/> : <>{payload.count} résultats</>}
        </div>}
        {error && <Error error={error} />}
        <div className="col-md-12 flex-grow-1">
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                {payload.rows.map(r => <div className={"col mb-3"}>
                    <div className="card border border-1 h-100 position-relative cursor-pointer shadow-hover" onClick={() => navigate(`/corporate/supplier/product/${r.id}`)}>
                        <span style={{top: 10, left: -10}} className={`badge position-absolute ${r.visibility === "public" ? "bg-success" : "bg-danger"}`}>
                            {r.visibility === "public" ? <><i className={"bi bi-check-circle"}></i> Publié</> : <><i className={"bi bi-x-circle"}></i> Brouillon</>}
                        </span>
                        <img alt={r.name} src={getCoverUrl(r)} className="card-img-top" />
                        <div className="card-body">
                            <p className={"mb-1 fw-semibold"}>
                                {r.name}
                            </p>
                            <p className={"form-text"}>{r.category?.name || "Non catégorisé"}</p>
                        </div>
                    </div>
                </div>)}
            </div>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        </div>
    </div>
}

export default SupplierProductList;