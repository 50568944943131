import {MULTIPART_FORM_DATA, post, destroy, get} from "../../../../xhr";
import {FetchParams} from "../../../../../models/global";
import encodeQueryData from "../../../../../utils/encodeQueryData";

export function list(product: number, requestData: FetchParams){
    return get(`/corporate/supplier/product/${product}/support${encodeQueryData(requestData)}`);
}

export function add(product: number, requestData: FormData){
    return post(`/corporate/supplier/product/${product}/support`, requestData, {headers: [MULTIPART_FORM_DATA]});
}

export function remove(product: number, support: number){
    return destroy(`/corporate/supplier/product/${product}/support/${support}`);
}