import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query: string, offset: number}){
    return get(`corporate/supplier/unit${encodeQueryData(requestData)}`);
}

export function show(unit: number){
    return get(`corporate/supplier/unit/${unit}`);
}

export function add(requestData: {label: string, step: string}){
    return post(`corporate/supplier/unit`, requestData);
}

export function update(unit: number, requestData: {label?: string, step?: string}){
    return post(`corporate/supplier/unit/${unit}`, requestData);
}
