import React, {useEffect, useState} from "react";
import * as api from "../../../adapters/checkout";
import {Navigate, useParams} from "react-router-dom";
import Loader from "../../../utils/loader";

const CheckoutProceed = () => {

    const [loading, setLoading] = useState(true)
    const {uuid} = useParams();
    const [nextUrl, setNextUrl] = useState<string>()

    useEffect(() => {
        if (uuid){
            setLoading(true)
            api.proceed(uuid)
                .then(r => setNextUrl(r.data.nextUrl))
                .finally(() => setLoading(false))
        }
    }, [uuid])

    if (loading){
        return <div className={'col-12 h-100 d-flex align-items-center'}>
            <div className="col-12 text-center">
                <div className="">
                    <Loader /> Chargement de votre commande...
                </div>
            </div>
        </div>
    }

    if (!nextUrl){
        return <div className={'col-12 py-5 text-center'}>Une erreur est survenue.</div>
    }

    return <Navigate to={nextUrl} />
}

export default CheckoutProceed;