import {Quote, QuoteRow} from "../../../../../../models/quote";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import FormError from "../../../../../../utils/formError";
import Loader from "../../../../../../utils/loader";
import SupplierTaxRateSelect from "../../../taxRate/select";
import SupplierUnitSelect from "../../../unit/select";
import * as api from "../../../../../../adapters/corporate/supplier/quote/row"
import SupplierProductSelect from "../../../product/select";
import ErrorCard from "../../../../../error";

const SupplierQuoteRowUpdate = ({handleSuccess, quote, quoteRow}: {handleSuccess: (row: QuoteRow) => void, quote: Quote, quoteRow: QuoteRow}) => {

    const [error, setError] = useState(null)

    const [initialValues, setInitialValues] = useState<any>({
        type: "custom",
        name: quoteRow.name,
        description: quoteRow.description,
        shippable: quoteRow.shippable,
        amountExcludingTax: quoteRow.amountExcludingTax / Math.pow(10, quote.currency.decimalDigits),
        tax_rate_id: quoteRow.taxRate?.id,
        quantity: quoteRow.quantity,
        unit_id: quoteRow.unit?.id
    });

    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        type: Yup.string().required(),
        product_id: Yup.number().required(),
        quantity: Yup.number().required().min(1),
    }))

    useEffect(() => {
        switch (quoteRow.type){
            case "custom":
                setInitialValues({
                    type: quoteRow.type,
                    name: quoteRow.name,
                    description: quoteRow.description,
                    shippable: quoteRow.shippable,
                    amountExcludingTax: quoteRow.amountExcludingTax / Math.pow(10, quote.currency.decimalDigits),
                    tax_rate_id: quoteRow.taxRate?.id,
                    quantity: quoteRow.quantity,
                    unit_id: quoteRow.unit?.id
                })

                setValidationSchema(Yup.object().shape({
                    type: Yup.string().required(),
                    name: Yup.string().required(),
                    description: Yup.string().required(),
                    shippable: Yup.boolean().required(),
                    amountExcludingTax: Yup.number().required().min(1),
                    tax_rate_id: Yup.string().nullable(),
                    quantity: Yup.number().min(1).required(),
                    unit_id: Yup.number().nullable()
                }))
                break;
        }
    }, [quoteRow?.id])

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            api.update(quote.id, quoteRow.id,{...values})
                .then(r => handleSuccess(r.data))
                .catch(error => setError(error))
                .finally(() => formikHelpers.setSubmitting(false))
        }
    })

    function handleTypeChange(type: "custom"|"product"){

        switch (type) {
            case "product":
                setInitialValues((prev: any) => ({type: "product", product_id: null, quantity: prev.quantity}))
                setValidationSchema(Yup.object().shape({
                    type: Yup.string().required(),
                    product_id: Yup.number().required(),
                    quantity: Yup.number().required().min(1),
                }))
                break;
            case "custom":
                setInitialValues((prev: any) => ({
                    type: "custom",
                    name: "",
                    description: "",
                    shippable: false,
                    amountExcludingTax: 0,
                    tax_rate_id: null,
                    quantity: prev.quantity,
                    unit_id: null
                }))
                setValidationSchema(Yup.object().shape({
                    type: Yup.string().required(),
                    name: Yup.string().required(),
                    description: Yup.string().required(),
                    shippable: Yup.boolean().required(),
                    amountExcludingTax: Yup.number().required().min(1),
                    tax_rate_id: Yup.string().nullable(),
                    quantity: Yup.number().required(),
                    unit_id: Yup.number().nullable()
                }))
                break;
        }
    }

    return <form onSubmit={formik.handleSubmit} key={quoteRow?.id}>
        {formik.values.type === "custom" && <>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input className="form-control" type="text" defaultValue={quoteRow.type === "custom" ? quoteRow.name : undefined} id="name" name={"name"} onChange={formik.handleChange} />
                    <label htmlFor="name">
                        Nom *
                    </label>
                </div>
                <FormError form={formik} field={"name"} />
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input className="form-control" type="text" id="description" name={"description"} defaultValue={quoteRow.type === "custom" ? quoteRow.description : undefined} onChange={formik.handleChange} />
                    <label htmlFor="description">
                        Description
                    </label>
                </div>
                <FormError form={formik} field={"description"} />
            </div>
            <div className="col-12 mb-3">
                <div className="form-check form-switch">
                    <input className="form-check-input" defaultChecked={quoteRow.type === "custom" && quoteRow.shippable} onChange={e => formik.setFieldValue("shippable", e.target.checked)} type="checkbox" role="switch" id="shippable" />
                    <label className="form-check-label" htmlFor="shippable">
                        Produit livrable
                    </label>
                </div>
                <FormError form={formik} field={"shippable"} />
            </div>
            <div className="col-12 mb-3">
                <div className="input-group">
                    <div className="form-floating">
                        <input className="form-control" type="number" step={0.01} id="amountExcludingTax" name={"amountExcludingTax"} defaultValue={quoteRow.type === "custom" ? quoteRow.amountExcludingTax : undefined} onChange={formik.handleChange} />
                        <label htmlFor="amountExcludingTax">
                            Prix HT
                        </label>
                    </div>
                    <span className="input-group-text">
                        {quote.currency.symbol}
                    </span>
                </div>
                <FormError form={formik} field={"amountExcludingTax"} />
            </div>
            <div className="col-12 mb-3">
                <SupplierTaxRateSelect
                    required={false}
                    defaultValue={quoteRow.type === "custom" && quoteRow.taxRate ? {label: quoteRow.taxRate.name, value: quoteRow.taxRate.id} : undefined}
                    handleChange={tax_rate_id => formik.setFieldValue('tax_rate_id', tax_rate_id)}
                    allowAdd={true}
                />
            </div>
            <div className="col-12 mb-3">
                <SupplierUnitSelect
                    required={false}
                    handleChange={unit_id => formik.setFieldValue('unit_id', unit_id)}
                    allowAdd={true}
                    defaultValue={quoteRow.type === "custom" && quoteRow.unit ? {label: quoteRow.unit.label, value: quoteRow.unit.id} : undefined}
                />
            </div>
        </>}
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input className="form-control" defaultValue={quoteRow.quantity} type="number" id="quantity" name={"quantity"} onChange={formik.handleChange} />
                <label htmlFor="quantity">
                    Quantité
                </label>
            </div>
            <FormError form={formik} field={"quantity"} />
        </div>
        {error && <ErrorCard error={error} />}
        <button disabled={formik.isSubmitting} type={'submit'} className={"btn btn-primary w-100"}>
            {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-check'}></i> Enregistrer</>}
        </button>
    </form>
}

export default SupplierQuoteRowUpdate;