import * as models from "../../../../../../../../../models/subscription";
import convertToDecimal from "../../../../../../../../../utils/currencyConverter";

const ProviderSubscriptionOccurrenceShowItemListItem = ({subscription, item}: {subscription: models.Subscription, item: models.SubscriptionOccurrenceItem}) => {

    return <tr key={item.id}>
        <td>
            {item.price.product.name}
        </td>
        <td>
            {item.quantity}
        </td>
        <td>
            {convertToDecimal(item.priceData.total_amount_including_tax, item.priceData.currency)}
        </td>
    </tr>
}

export default ProviderSubscriptionOccurrenceShowItemListItem;