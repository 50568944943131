import {Affiliation} from "../../../../../../models/affiliation";
import {useState} from "react";
import SupplierAffiliationConversionRuleList from "./conversionRule/list";
import SupplierAffiliationClickRuleList from "./clickRule/list";

const SupplierAffiliationRules = ({affiliation}: {affiliation: Affiliation}) => {

    const [tab, setTab] = useState<'conversion'|'click'>('conversion')

    return <div className={'row'}>
        <div className="col-12 mb-3">
            <ul className="nav nav-pills">
                <li className="nav-item">
                    <button className={`nav-link ${tab === "conversion" ? 'active' : ''}`} onClick={() => setTab('conversion')}>
                        Conversion
                    </button>
                </li>
                <li className="nav-item">
                    <button className={`nav-link ${tab === "click" ? 'active' : ''}`} onClick={() => setTab('click')}>
                        Click
                    </button>
                </li>
            </ul>
        </div>
        <div className="col-12">
            {tab === "conversion" && <SupplierAffiliationConversionRuleList affiliation={affiliation} />}
            {tab === "click" && <SupplierAffiliationClickRuleList affiliation={affiliation} />}
        </div>
    </div>
}

export default SupplierAffiliationRules;