import {useAppSelector} from "../../../../../hooks";
import {CorporateAccount} from "../../../../../models/account";
import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from 'yup';
import FormError from "../../../../../utils/formError";
import Select from "../../../../../utils/select";
import {getCountryCodes, getCountryCodesChoice} from "../../../../../utils/tel";
import * as api from "../../../../../adapters/corporate/common/sponsorship"

const SponsorshipFeature = () => {

    const activeAccount = useAppSelector(state => state.auth.user?.activeAccount) as CorporateAccount
    const authOrganization = activeAccount.organization;

    const [copied, setCopied] = useState(false)
    let timer: NodeJS.Timeout;
    function copyLink(){
        clearTimeout(timer);
        setCopied(true)
        navigator.clipboard.writeText(`https://app.varar.co/register?sponsor=${authOrganization.id}`);
        timer = setTimeout(() => setCopied(false), 1500);
    }

    // Configuration des réseaux sociaux
    const socialNetworks: {name: 'Twitter'|'Facebook'|'Linkedin', icon: string, url: string, extraParam: string}[] = [
        {
            name: 'Twitter',
            icon: 'bi bi-twitter-x',
            url: 'https://twitter.com/intent/tweet?url=',
            extraParam: '&text='
        },
        {
            name: 'Facebook',
            icon: 'bi bi-facebook',
            url: 'https://www.facebook.com/sharer/sharer.php?u=',
            extraParam: '&quote='
        },
        {
            name: 'Linkedin',
            icon: 'bi bi-linkedin',
            url: 'https://www.linkedin.com/sharing/share-offsite/?url=',
            extraParam: '&summary='
        }
    ];

    const postContent = {
        provider: {
            Twitter: "💼 Vous êtes un commercial freelance à la recherche de nouvelles missions ? Rejoignez notre plateforme et connectez-vous avec des entreprises à la recherche de talents comme vous ! 🚀 #Freelance #Opportunités #Networking",
            Facebook: "Vous êtes commercial freelance et à la recherche de nouvelles missions ? 🚀 Rejoignez notre communauté de professionnels et connectez-vous avec des entreprises qui ont besoin de votre expertise ! Cliquez ici pour en savoir plus 👉 [votre-plateforme.com](https://votre-plateforme.com) #Freelance #Opportunités #Missions",
            Linkedin: "🎯 Commercial Freelance à la recherche de nouvelles opportunités ? Rejoignez notre plateforme dédiée pour vous connecter avec des entreprises à la recherche de talents comme le vôtre ! 🚀 🔗 [En savoir plus](https://votre-plateforme.com) #Freelance #Opportunités #RéseauProfessionnel #Missions"
        },
        supplier: {
            Twitter: "🚀 Vous cherchez à booster vos ventes avec des commerciaux freelance expérimentés ? Découvrez notre plateforme pour trouver le talent qu’il vous faut ! 💼 #Business #Recrutement #Freelance",
            Facebook: "Votre entreprise a besoin d’un coup de pouce pour ses ventes ? Trouvez les meilleurs commerciaux freelance sur notre plateforme ! 💼 En savoir plus 👉 [votre-plateforme.com](https://votre-plateforme.com) #Recrutement #Business #Freelance",
            Linkedin: "Vous êtes une entreprise à la recherche de commerciaux freelance expérimentés pour développer vos ventes ? 📈 Rejoignez notre plateforme et trouvez le talent idéal pour atteindre vos objectifs commerciaux. 🔗 [En savoir plus](https://votre-plateforme.com) #Recrutement #DéveloppementCommercial #Business #Freelance"
        }
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            company: "",
            countryCode: "+33",
            tel: "",
            email: ""
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            company: Yup.string().optional(),
            countryCode: Yup.string().required(),
            tel: Yup.string().required(),
            email: Yup.string().email().required()
        }),
        onSubmit: (values, {setSubmitting}) => {
            api.lead(values)
                .then(r => alert("Message envoyé"))
                .finally(() => setSubmitting(false))
        }
    })

    return <>
        <div className="col-12 my-8 text-center">
            <h4>
                Recommandez Varar et soyez récompensé(e)
            </h4>
            <h4>
                Gagnez jusqu’à 5000 euros pour chaque parrainage !
            </h4>
            <p>
                Vous utilisez Varar ?
                <br/>
                Parlez en autour de vous !
            </p>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-body">
                    <h5>
                        Comment ça fonctionne ?
                    </h5>
                    <div className="row row-cols-1 row-cols-md-3">
                        <div className="col mb-3">
                            <div className="bg-body-secondary p-3  h-100 d-flex align-items-center">
                                <p className={'mb-0 fw-semibold text-center flex-grow-1'}>
                                    1. Mettez nous en relation avec vos contact
                                </p>
                            </div>
                        </div>
                        <div className="col mb-3">
                            <div className="bg-body-secondary p-3  h-100 d-flex align-items-center">
                                <p className={'mb-0 fw-semibold text-center flex-grow-1'}>
                                    2. Nous accompagnons vos contact
                                </p>
                            </div>
                        </div>
                        <div className="col mb-3">
                            <div className="bg-body-secondary p-3  h-100 d-flex align-items-center">
                                <p className={'mb-0 fw-semibold text-center flex-grow-1'}>
                                    3. Vos contact génèrent des revenues, vous êtes récompensés !
                                </p>
                            </div>
                        </div>
                    </div>
                    <p>
                        Votre entreprise reçoit 20% de commission calculée sur le montant des revenues générés pour la plateforme (jusqu’à 5000 euros) et la société de votre filleul reçoit 10% de rétro-commission calculée sur sa première année d’utilisation !
                    </p>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-body">
                    <div className="col-12 mb-5">
                        <h6>
                            Votre lien de parrainage :
                        </h6>
                        <p>
                            Faites découvrir Varar à toutes vos relations : clients, fournisseurs, amis,...
                        </p>
                        <div className="row gx-0 align-items-center">
                            <div className="col-auto border border-1">
                            <span className={'px-2'}>
                                https://app.varar.co/register?sponsor={authOrganization.id}
                            </span>
                                <button className="btn btn-xxs btn-primary" onClick={() => copyLink()}>
                                    {copied ? <><i className={'bi bi-check'}></i> Lien copié</> : <><i className={'bi bi-link'}></i> Copier le lien</>}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-5">
                        <h6>
                            Partagez sur vos réseaux sociaux
                        </h6>
                        <div className="row align-items-center">
                            {socialNetworks.map((network) => (
                                <div className="col-auto mb-3" key={network.name}>
                                    <button
                                        className="btn btn-sm btn-light"
                                        onClick={() => {
                                            const shareUrl = `${network.url}${encodeURIComponent(`https://app.varar.co/register?sponsor=${authOrganization.id}`)}`;
                                            const content = network.extraParam ? `${network.extraParam}${encodeURIComponent(postContent[authOrganization.type][network.name])}` : '';
                                            window.open(shareUrl + content, '_blank');
                                        }}
                                    >
                                        <i className={network.icon}></i> {network.name}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-12 mb-5">
                        <h6>
                            Ou entrez directement les informations de votre contact.
                        </h6>
                        <p>
                            Nous le contacterons de votre part.
                        </p>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="col-12 mb-3">
                                <div className="form-floating">
                                    <input type="text" id={'name'} name={'name'} onChange={formik.handleChange} onBlur={formik.handleBlur} className="form-control"/>
                                    <label htmlFor="name">
                                        Nom du contact
                                    </label>
                                    <FormError form={formik} field={'name'} />
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="form-floating">
                                    <input type="text" id={'company'} name={'company'} onChange={formik.handleChange} onBlur={formik.handleBlur} className="form-control"/>
                                    <label htmlFor="company">
                                        Entreprise
                                    </label>
                                    <FormError form={formik} field={'company'} />
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="input-group">
                                    <Select
                                        label={"Code pays"}
                                        required={true}
                                        value={getCountryCodesChoice('+33')}
                                        options={getCountryCodes()}
                                        onChange={c => formik.setFieldValue('countryCode', c.value)}
                                    />
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id={"tel"} name={"tel"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                        <label htmlFor="firstname">
                                            Tel
                                        </label>
                                    </div>
                                </div>
                                <FormError form={formik} field={"tel"} />
                                <FormError form={formik} field={'countryCode'} />
                            </div>
                            <div className="col-12 mb-3">
                                <div className="form-floating">
                                    <input type="text" id={'email'} name={'email'} onChange={formik.handleChange} onBlur={formik.handleBlur} className="form-control"/>
                                    <label htmlFor="email">
                                        Email
                                    </label>
                                    <FormError form={formik} field={'email'} />
                                </div>
                            </div>
                            <button disabled={formik.isSubmitting} className={"btn btn-primary w-100"}>
                                Envoyer
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default SponsorshipFeature;