import * as models from "../../../../../../../../../models/shipping";
import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../../../../../adapters/corporate/supplier/shipping/price";
import {Offcanvas} from "bootstrap";
import FormError from "../../../../../../../../../utils/formError";
import Loader from "../../../../../../../../../utils/loader";
import Select from "../../../../../../../../../utils/select";
import {getCurrencies} from "../../../../../../../../../utils/currencies";

const SupplierShippingRatePriceAdd = ({rate, handleSuccess}: {rate: models.ShippingRate, handleSuccess: (p: models.ShippingRatePrice) => void}) => {

    const formik = useFormik({
        initialValues: {
            amount: 0,
            weightLimit: 0,
            volumeLimit: 0,
            freeTreshold: null,
            currency_id: null,
        },
        validationSchema: Yup.object().shape({
            amount: Yup.number().required(),
            weightLimit: Yup.number().required(),
            volumeLimit: Yup.number().required(),
            freeTreshold: Yup.number().nullable(),
            currency_id: Yup.number().required().min(1),
        }),
        onSubmit: (values, formikHelpers) => {
            api.add({...values, rate_id: rate!.id})
                .then(r => handleSuccess(r.data))
                .then(() => formikHelpers.setSubmitting(false))
                .then(() => close())
        }
    })

    function close(){
        let el = document.getElementById("offcanvasShippingRatePriceAdd")

        if (el){
            (new Offcanvas(el)).hide()
        }
    }

    return <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasShippingRatePriceAdd"
                aria-labelledby="offcanvasShippingRatePriceAddLabel">
        <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasShippingRatePriceAddLabel"><i className={'bi bi-plus-circle'}></i> Ajouter un prix</h5>
            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvasShippingRatePriceAdd" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            {rate && <form onSubmit={formik.handleSubmit}>
                <div className="col-12 mb-3">
                    <Select
                        multiple={false}
                        required={true}
                        label={"Devise *"}
                        options={getCurrencies()}
                        onChange={o => formik.setFieldValue('currency_id', o.id)}
                    />
                </div>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="number" step={0.01} className={'form-control'} name={"amount"} id={"amount"}
                               onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                        <label htmlFor="amount">
                            Prix de base *
                        </label>
                    </div>
                    <FormError form={formik} field={"amount"}/>
                </div>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="number" step={0.01} className={'form-control'} name={"weightLimit"}
                               id={"weightLimit"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                        <label htmlFor="weightLimit">
                            Limite de poid (grammes)
                        </label>
                    </div>
                    <FormError form={formik} field={"weightLimit"}/>
                </div>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="number" step={0.01} className={'form-control'} name={"volumeLimit"}
                               id={"volumeLimit"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                        <label htmlFor="volumeLimit">
                            Limite de dimension (cm cube)
                        </label>
                    </div>
                    <FormError form={formik} field={"volumeLimit"}/>
                </div>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="number" step={0.01} className={'form-control'} name={"freeTreshold"}
                               id={"freeTreshold"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                        <label htmlFor="discount">
                            Livraison offerte à partir de
                        </label>
                    </div>
                    <FormError form={formik} field={"freeTreshold"}/>
                </div>
                <button className="btn btn-light w-100 mb-3">
                    {formik.isSubmitting ? <Loader/> : <><i className={'bi bi-check'}></i> Valider</>}
                </button>
            </form>}
        </div>
    </div>
}

export default SupplierShippingRatePriceAdd;