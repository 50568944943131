import React, {useEffect, useState} from "react";
import * as api from "../../../../adapters/customer/address";
import SearchInput from "../../../../utils/searchInput";
import Loader from "../../../../utils/loader";
import Pagination from "../../../../utils/pagination";
import {CustomerAddresses} from "../../../../models/account";
import CustomerAddressAdd from "../add";
import ModalComponent from "../../../modal";
import ErrorCard from "../../../error";

const CustomerAddressList = () => {

    const [payload, setPayload] = useState<{ count: number, rows: CustomerAddresses }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number}>({query: "", offset: 0})

    useEffect(() => {
        setLoading(true)
        setError(null)
        api.list({offset: fetchParams.offset, query: fetchParams.query})
            .then(resp => setPayload(resp.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    Vos adresses
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher une adresse..."} onChange={handleQueryChange} size={"default"} />
                </div>
                <div className="col-auto">
                    <button className="btn btn-light" onClick={() => setShowModal(true)}>
                        Ajouter une adresse
                    </button>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        {error && <ErrorCard error={error} />}
        <div className="col-md-12 flex-grow-1">
            {!loading && <div className={'row'}>
                {payload.rows.map(r => <div className={"card border border-1 mb-3"}>
                    <div className="card-body d-flex align-items-center">
                        <div className="flex-grow-1">
                            {r.line1}
                            <div className="clearfix"></div>
                            {r.city}, {r.postalCode}
                            <div className="clearfix"></div>
                            {r.country?.name}
                        </div>
                        <div className="flex-grow-0">
                            <button className="btn btn-xxs btn-light">
                                <i className={'bi bi-x text-danger'}></i> Archiver
                            </button>
                        </div>
                    </div>
                </div>)}
            </div>}
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        </div>
        <ModalComponent title={"Ajouter une adresse"} show={showModal} onHide={() => setShowModal(false)}>
            <CustomerAddressAdd onSuccess={a => {
                setPayload(prevState => ({count: prevState.count + 1, rows: [a, ...prevState.rows]}))
                setShowModal(false)
            }} />
        </ModalComponent>
    </div>
}

export default CustomerAddressList;