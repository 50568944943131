import {Transfer} from "../../../../../models/transfer";
import {Link} from "react-router-dom";
const CommonTransferType = ({transfer}: {transfer: Transfer}) => {

    switch (transfer.type) {
        case "order_provider":
            return <Link to={`/corporate/provider/order/${transfer.order?.id}`}>{transfer.order?.number}</Link>
        case "order_supplier":
            return <Link to={`/corporate/supplier/order/${transfer.order?.id}`}>{transfer.order?.number}</Link>
        case "subscription_provider":
        case "subscription_supplier":
            return <>Abonnement</>
        case "quote_deposit_supplier":
            return <>Acompte</>
        case "quote_supplier":
        case "quote_provider":
            return <>Devis</>
        case "conversion_provider":
            return <>Conversions</>
        case "click_provider":
            return <>Clics</>
    }

    return <>Invalid type</>
}

export default CommonTransferType;