import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Partnership} from "../../../../../models/partnership";
import * as api from "../../../../../adapters/corporate/common/partnership";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import {getLogoUrl} from "../../../../../utils/image";
import {useAppSelector} from "../../../../../hooks";
import {CorporateAccount} from "../../../../../models/account";

const CommonPartnershipShow = () => {

    const activeAccount = useAppSelector(state => state.auth.user?.activeAccount) as CorporateAccount
    const authOrganization = activeAccount.organization;

    const activeCompanyKey = authOrganization.type;
    const partnerKey = activeCompanyKey === 'provider' ? "supplier" : "provider";
    const {id} = useParams();
    const [loading, setLoading] = useState(true)
    const [updating, setUpdating] = useState(false)
    const [error, setError] = useState(null)
    const [partnership, setPartnership] = useState<Partnership>()

    useEffect(() => {
        api.show(Number(id))
            .then(r => setPartnership(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [id])

    function updateAccess(key: 'accessAffiliation'|'accessSale', value: 'Granted'|'Denied'|'Requested'){
        if (partnership){
            setUpdating(true)
            api.update(partnership.id, {[key]: value})
                .then(r => setPartnership(r.data))
                .catch(e => setError(e))
                .finally(() => setUpdating(false))
        }
    }

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!partnership){
        return <></>
    }

    return <div className={"container-fluid"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb fs-xs">
                <Link to={'/corporate/partnership'} className="breadcrumb-item">
                    Partenaires
                </Link>
                <li className="breadcrumb-item">
                    {partnership[partnerKey].name}
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-body">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="row align-items-center">
                                <div className="col text-center">
                                    <div className="avatar avatar-xxl mb-3">
                                        <img src={getLogoUrl(partnership[activeCompanyKey])} className="avatar-img rounded-circle"  alt={partnership[activeCompanyKey].name}/>
                                    </div>
                                    <p className="fw-bold mb-1">
                                        <Link to={`/corporate/organization/${partnership[activeCompanyKey].id}`}>
                                            {partnership[activeCompanyKey].name} <i className={'bi bi-arrow-right'}></i>
                                        </Link>
                                    </p>
                                </div>
                                <div className="col-auto">
                                    <i className={'bi bi-x display-2'}></i>
                                </div>
                                <div className="col">
                                    <div className="col text-center">
                                        <div className="avatar avatar-xxl mb-3">
                                            <img src={getLogoUrl(partnership[partnerKey])} className="avatar-img rounded-circle"  alt={partnership[partnerKey].name}/>
                                        </div>
                                        <p className="fw-bold mb-1">
                                            <Link to={`/corporate/organization/${partnership[partnerKey].id}`}>
                                                {partnership[partnerKey].name} <i className={'bi bi-arrow-right'}></i>
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-body">
                    <h6>
                        Paramètres du partenariat
                    </h6>
                    <div className="col-12">
                        <div className="row align-items-center">
                            <div className="col">
                                Accès aux produit / services en vente direct
                            </div>
                            <div className="col-auto">
                                {partnership.accessSale === 'Denied' && <span className={'badge bg-danger me-2'}>
                                Désactivé
                            </span>}
                                {partnership.accessSale === 'Requested' && <span className={'badge bg-info me-2'}>
                                Demandé
                            </span>}
                                {partnership.accessSale === 'Granted' && <span className={'badge bg-success me-2'}>
                                Activé
                            </span>}
                                {activeAccount.organization.type === 'supplier' && <>
                                    <div className="dropdown">
                                        <button className="btn btn-xs btn-light" type="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className={'bi bi-three-dots-vertical'}></i>
                                        </button>
                                        <ul className="dropdown-menu">
                                            {partnership.accessSale === 'Denied' && <>
                                                <button disabled={updating} className="dropdown-item" onClick={() => updateAccess('accessSale', 'Granted')}>Activer l'accès</button>
                                            </>}
                                            {partnership.accessSale === 'Requested' && <>
                                                <button disabled={updating} className="dropdown-item" onClick={() => updateAccess('accessSale', 'Granted')}>Accepter l'accès</button>
                                                <button disabled={updating} className="dropdown-item" onClick={() => updateAccess('accessSale', 'Denied')}>Refuser l'accès</button>
                                            </>}
                                            {partnership.accessSale === 'Granted' && <button className={'btn btn-xs btn-light'}>
                                                <button disabled={updating} className="dropdown-item" onClick={() => updateAccess('accessSale', 'Denied')}>Désactiver l'accès</button>
                                            </button>}
                                        </ul>
                                    </div>
                                </>}
                                {activeAccount.organization.type === 'provider' && <>
                                    {partnership.accessSale === 'Denied' && <div className="dropdown">
                                        <button className="btn btn-xs btn-light" type="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className={'bi bi-three-dots-vertical'}></i>
                                        </button>
                                        <ul className="dropdown-menu">
                                            {partnership.accessSale === 'Denied' && <>
                                                <button disabled={updating} className="dropdown-item" onClick={() => updateAccess('accessSale', 'Requested')}>Demander l'accès</button>
                                            </>}
                                        </ul>
                                    </div>}
                                </>}
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="col-12">
                        <div className="row align-items-center">

                            <div className="col">
                                Accès aux programmes d'affiliation
                            </div>
                            <div className="col-auto">
                                {partnership.accessAffiliation === 'Denied' && <span className={'badge bg-danger me-2'}>
                                Désactivé
                            </span>}
                                {partnership.accessAffiliation === 'Requested' && <span className={'badge bg-info me-2'}>
                                Demandé
                            </span>}
                                {partnership.accessAffiliation === 'Granted' && <span className={'badge bg-success me-2'}>
                                Activé
                            </span>}
                                {activeAccount.organization.type === 'supplier' && <>
                                    <div className="dropdown">
                                        <button className="btn btn-xs btn-light" type="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className={'bi bi-three-dots-vertical'}></i>
                                        </button>
                                        <ul className="dropdown-menu">
                                            {partnership.accessAffiliation === 'Denied' && <>
                                                <button disabled={updating} className="dropdown-item" onClick={() => updateAccess('accessAffiliation', 'Granted')}>Activer l'accès</button>
                                            </>}
                                            {partnership.accessAffiliation === 'Requested' && <>
                                                <button disabled={updating} className="dropdown-item" onClick={() => updateAccess('accessAffiliation', 'Granted')}>Accepter l'accès</button>
                                                <button disabled={updating} className="dropdown-item" onClick={() => updateAccess('accessAffiliation', 'Denied')}>Refuser l'accès</button>
                                            </>}
                                            {partnership.accessAffiliation === 'Granted' && <button className={'btn btn-xs btn-light'}>
                                                <button disabled={updating} className="dropdown-item" onClick={() => updateAccess('accessAffiliation', 'Denied')}>Désactiver l'accès</button>
                                            </button>}
                                        </ul>
                                    </div>
                                </>}
                                {activeAccount.organization.type === 'provider' && <>
                                    {partnership.accessAffiliation === 'Denied' && <div className="dropdown">
                                        <button className="btn btn-xs btn-light" type="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className={'bi bi-three-dots-vertical'}></i>
                                        </button>
                                        <ul className="dropdown-menu">
                                            {partnership.accessAffiliation === 'Denied' && <>
                                                <button disabled={updating} className="dropdown-item" onClick={() => updateAccess('accessAffiliation', 'Requested')}>Demander l'accès</button>
                                            </>}
                                        </ul>
                                    </div>}
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default CommonPartnershipShow;