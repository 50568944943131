import FormError from "../../../../../../../utils/formError";
import React from "react";
import {OrderItem} from "../../../../../../../models/order";

const SupplierOrderRefundAddOrderItem = ({item, index, formik}: {item: OrderItem, index: number, formik: any}) => {


    return <li key={index} className={'list-group-item'}>
        <div className="row">
            <div className="col-md-6 mb-3">
                <p className={'mb-2 fw-semibold'}>
                    {item.name}
                </p>
                <p className={'mb-0'}>
                    Quantité max: {item.quantity}
                </p>
            </div>
            <div className="col-md-6 mb-3">
                <div className="form-floating">
                    <input
                        type="number"
                        className={'form-control'}
                        name={`items[${index}].quantity`}
                        defaultValue={0}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <label htmlFor={`items[${index}].quantity`}>
                        Quantité
                    </label>
                </div>
                <FormError form={formik} field={`items[${index}].quantity`} />
            </div>
        </div>
    </li>
}

export default SupplierOrderRefundAddOrderItem;