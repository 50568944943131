import {Affiliation} from "../../../../../../../../models/affiliation";

const OrganizationAffiliationShow = ({item}: {item: Affiliation}) => {


    return <div className={"col"}>

    </div>
}

export default OrganizationAffiliationShow;