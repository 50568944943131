import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import * as api from '../../../../../adapters/corporate/provider/link';
import SearchInput from "../../../../../utils/searchInput";
import Loader from "../../../../../utils/loader";
import {Links} from "../../../../../models/link";
import Pagination from "../../../../../utils/pagination";
import {APP_URL} from "../../../../../constants/global";

const ProviderLinkList = () => {

    const [payload, setPayload] = useState<{ count: number, rows: Links }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number}>({query: "", offset: 0})

    useEffect(() => {
        setLoading(true)
        api.list({offset: fetchParams.offset, query: fetchParams.query})
            .then(resp => setPayload(resp.data))
            .then(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher un lien de paiement..."} onChange={handleQueryChange} size={"default"} />
                </div>
                <div className="col-auto">
                    <Link to={'/corporate/provider/link/add'} className={"btn btn-xs rounded btn-primary"}>
                        <i className={'bi bi-plus-circle'}></i> Ajouter
                    </Link>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        {!loading && <>
        <ul className={"list-group"}>
            {payload.rows.map(o => <li className={'list-group-item'}>
                <Link to={`/corporate/provider/link/${o.id}`}>
                    {o.name}
                </Link>
            </li>)}
        </ul>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        </>}
    </div>
}

export default ProviderLinkList;