import React, {useEffect, useState} from "react";
import {Projects} from "../../../../../models/project";
import * as api from "../../../../../adapters/corporate/provider/project"
import SearchInput from "../../../../../utils/searchInput";
import ProjectStatusPlaceholder from "../../../common/project/status";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";
import {Link, useNavigate} from "react-router-dom";

const ProviderProjectList = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [payload, setPayload] = useState<{count: number, rows: Projects}>({count: 0, rows: []})
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string, status: "All"|"Pending"|"Processing"|"Completed"|"Canceled"}>({offset: 0, query: "", status: "All"})
    const status_array: ("All"|"Pending"|"Processing"|"Completed"|"Canceled")[] = ["All", "Pending", "Processing", "Completed", "Canceled"]

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => console.log(e))
            .finally(() => setLoading(false))
    }, [fetchParams])


    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams(p => ({...p, query: e.target.value})), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    Appel d'offre
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col-12 mb-3">
                    <div className="row">
                        <div className="col">
                            <SearchInput placeholder={"Rechercher un projet..."} onChange={handleQueryChange} size={"default"} />
                        </div>
                        <div className="col-auto">
                            <Link to={"add"} className="btn btn-primary rounded btn-xs">
                                <i className={'bi bi-plus-circle'}></i> Ajouter
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-auto">
                            <div className="dropdown">
                                <button className="btn btn-light btn-xxs dropdown-toggle" type="button"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                    Statut: <ProjectStatusPlaceholder status={fetchParams.status} />
                                </button>
                                <ul className="dropdown-menu">
                                    {status_array.filter(s => s !== fetchParams.status).map((s) => <li>
                                        <span className="dropdown-item cursor-pointer" onClick={() => setFetchParams(prev => ({...prev, status: s}))}>
                                            <ProjectStatusPlaceholder status={s} />
                                        </span>
                                    </li>)}
                                </ul>
                            </div>
                        </div>
                        <div className="col-auto">
                            <span className="text-muted">
                                {loading ? <Loader size={"sm"} /> : <>{payload.count} résultats</>}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-12 flex-grow-1">
            <div className={'table-responsive'}>
                <table className={'table table-hover'}>
                    <thead>
                    <tr>
                        <th>
                            Titre
                        </th>
                        <th>
                            Client
                        </th>
                        <th>
                            Devis
                        </th>
                        <th>
                            Statut
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {payload.rows.map(r => <tr onClick={() => navigate(`${r.id}`)}>
                        <td>
                            {r.title}
                        </td>
                        <td>
                            {r.city}, {r.postalCode}
                        </td>
                        <td>
                            {r.quotes.length} devis
                        </td>
                        <td>
                            <ProjectStatusPlaceholder status={r.status} />
                        </td>
                    </tr>)}
                    </tbody>
                </table>
            </div>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        </div>
    </div>
}

export default ProviderProjectList;