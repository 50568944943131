import React from "react";
import CountrySelector from "../../../country";
import CurrencySelector from "../../../currency";
import {Country} from "../../../../models/country";
import {Currency} from "../../../../models/currency";
import {Link} from "react-router-dom";

const PackNavFooter = ({setCountry, setCurrency}: {setCountry: (c: Country) => void, setCurrency: (c: Currency) => void}) => {

    return <>
        <div className="col-12 bg-dark pt-10">
            <div className="container mx-auto">
                <div className="row">
                    <div className="col">

                    </div>
                    <div className="col">

                    </div>
                    <div className="col">

                    </div>
                    <div className="col">

                    </div>
                </div>
                <hr className={'text-white'}/>
                <div className="col-12 d-flex align-items-center ps-3 text-white-50 pb-3">
                    <span className="fs-xxs">Powered by <Link to={'https://varar.co'} target={"_blank"} replace><img src={process.env.PUBLIC_URL + '/img/logo.png'} alt="" height={25} width={'auto'}/></Link></span>
                </div>
            </div>
        </div>
        <CountrySelector setCountry={c => setCountry(c)} />
        <CurrencySelector setCurrency={c => setCurrency(c)} />
    </>
}

export default PackNavFooter;