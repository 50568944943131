import React, {useState} from "react";
import PostList from "../post/list";
import HomeSuggestion from "./suggestion";

const CorporateHome = () => {

    const [type, setType] = useState<"personalized"|"partners">('personalized')

    return <div className={'col-12'}>
        <div className="row">
            <div className="col-12 col-md-8">
                <div className="row justify-content-center sticky-top bg-body-tertiary">
                    <div className="col-auto">
                        <div className="mb-2 mb-lg-0 border-bottom-lg">
                            <nav className="nav nav-tabs nav-overflow fs-xs border-bottom border-bottom-lg-0">
                                <button className={"nav-link text-uppercase" + (type === "personalized" ? " active " : "")} data-bs-toggle="tab" onClick={() => setType("personalized")}>
                                    Personnalisé
                                </button>
                                <button className={"nav-link text-uppercase" + (type === "partners" ? " active " : "")} data-bs-toggle="tab" onClick={() => setType("partners")}>
                                    Partenaires
                                </button>
                            </nav>
                        </div>
                    </div>
                </div>
                {(type === "personalized" || type === "partners") && <PostList type={type} key={type}/>}
            </div>
            <div className="col-md-4 d-none d-md-block">
                <h6>Suggestions</h6>
                <HomeSuggestion query={''} sort={'trending'} count={8} />
            </div>
        </div>
    </div>
}

export default CorporateHome;