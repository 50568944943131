import React, {useState} from "react";
import {Contact} from "../../../../../../models/contact";
import ModalComponent from "../../../../../modal";

const ProviderContactListItem = ({contact}: {contact: Contact}) => {

    const [showModal, setShowModal] = useState(false)


    return <li className={"list-group-item"}>
        <div className="row align-items-center">
            <div className="col">
                <p className={'mb-0'}>{contact.firstname} {contact.lastname}</p>
                {contact.type === 'company' && <p className={'mt-1 mb-0 form-text'}>{contact.jobTitle}, {contact.companyName}</p>}
            </div>
            <div className="col-auto">
                <button className="btn btn-light btn-xs" onClick={() => setShowModal(true)}>
                    Voir plus <i className={'bi bi-arrow-right'}></i>
                </button>
            </div>
        </div>
        <ModalComponent size={"modal-lg"} title={`${contact.firstname} ${contact.lastname}`} show={showModal} onHide={() => setShowModal(false)}>
            <table className={'table table-borderless'}>
                <tbody>
                    <tr>
                        <th>Tel</th>
                        <td>{contact.countryCode} {contact.tel}</td>
                    </tr>
                    <tr>
                        <th>Email</th>
                        <td>{contact.email}</td>
                    </tr>
                    <tr>
                        <th>Type</th>
                        <td>{contact.type}</td>
                    </tr>
                    {contact.type === 'company' && <>
                        <tr>
                            <th>Entreprise</th>
                            <td>{contact.companyName}</td>
                        </tr>
                        <tr>
                            <th>Fonction</th>
                            <td>{contact.jobTitle}</td>
                        </tr>
                    </>}
                </tbody>
            </table>
        </ModalComponent>
    </li>
}

export default ProviderContactListItem;

