import {Parcel} from "../../../../models/order";
import {getCoverUrl} from "../../../../utils/image";
import convertToDecimal from "../../../../utils/currencyConverter";
import React, {useState} from "react";
import * as api from "../../../../adapters/checkout";
import {CheckoutResponse} from "../../../../models/checkout";

const CheckoutDeliveryParcel = ({parcel, checkout, setCheckout, showError}: {parcel: Parcel, checkout: CheckoutResponse, setCheckout: React.Dispatch<any>, showError: boolean}) => {

    const [updating, setUpdating] = useState(false);

    function handleOptionChange(option: number)
    {
        setUpdating(true)
        api.deliveryOption(checkout.uuid, option)
            .then(r => setCheckout(r.data))
            .finally(() => setUpdating(false))
    }

    return <div className={'col-12 mb-3 ' + (parcel.status === "UnShippable" ? "  border-danger " : "")}>
        <div className="card border border-1">
            <div className="card-header">
                <h6>
                    Colis N°{parcel.trackingNumber}
                </h6>
                {showError && parcel.options.find(o => o.selected) === undefined && <div className={"text-danger"}>
                    <i className={'bi bi-exclamation-circle'}></i> Veuillez séléctionner un mode de livraison
                </div>}
                {parcel.status === "UnShippable" &&  <div className={"text-danger"}>
                    <i className={'bi bi-exclamation-circle'}></i> La livraison n'est pas supporté pour les articles suivants
                </div>}
            </div>
            <div className="card-body">
                <ul className="list-group list-group-flush">
                    {parcel.items.map(i => <li className={'list-group-item'}>
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div className="avatar avatar-xl">
                                    <img src={getCoverUrl(i.item.price.product)} alt="" className={'avatar-img'}/>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-text">
                                    {i.item.price.product.name}
                                </div>
                                <div className="form-text">
                                    Quantité: {i.item.quantity}
                                </div>
                            </div>
                            {parcel.status === "UnShippable" && <div className={'col-auto'}>
                                    <span className={"text-muted"}>
                                        <i className={"bi bi-x"}></i> Retirer
                                    </span>
                            </div>}
                        </div>
                    </li>)}
                </ul>
                {parcel.status !== "UnShippable" && <>
                    <hr/>
                    <p className={'fw-semibold mb-1'}>
                        Mode de livraison
                    </p>
                    <ul className="list-group">
                        {parcel.options.map(o => <li className={'list-group-item d-flex align-items-center'}>
                            <div className="form-check flex-grow-0">
                                <input disabled={updating} checked={o.selected} type="radio" name={`option_${parcel.id}`} id={`s_${parcel.id}_o_${o.id}`} onChange={e => handleOptionChange(o.id)} className="form-check-input"/>
                            </div>
                            <div className="flex-grow-1">
                                <label className="row align-items-center" htmlFor={`s_${parcel.id}_o_${o.id}`}>
                                        <span className="col">
                                            {o.name}
                                            <span className="clearfix"></span>
                                            <span className="form-text">
                                                {o.delayMin} à {o.delayMax} jours
                                            </span>
                                         </span>
                                    <span className="col-auto">
                                            {convertToDecimal(o.priceData.amount_including_tax, checkout.currency)}
                                        </span>
                                </label>
                            </div>
                        </li>)}
                    </ul>
                </>}
            </div>
        </div>
    </div>
}

export default CheckoutDeliveryParcel;