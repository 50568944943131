import {Link, useLocation, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Loader from "../../../utils/loader";
import {Quote} from "../../../models/quote";
import * as api from "../../../adapters/quote";
import {AxiosError} from "axios";
import ErrorCard from "../../error";
import QuoteShowRows from "../rows";
import QuoteCondition from "../condition";
import QuoteSignature from "../signature";
import QuoteNavHeader from "../nav/header";
import QuoteNavFooter from "../nav/footer";
import QuotePayment from "../payment";
import QuoteAddress from "../address";

const QuoteShow = () => {

    const {uuid} = useParams()
    const [loading, setLoading] = useState(true)
    const [quote, setQuote] = useState<Quote>()
    const [error, setError] = useState<AxiosError>()

    useEffect(() => {
        setLoading(true)
        api.show(uuid)
            .then(r => setQuote(r.data))
            .catch((error: AxiosError) => setError(error))
            .finally(() => setLoading(false))
    }, [uuid])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!quote){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <h6>
                    Devis introuvable
                </h6>
            </div>
        </div>
    }

    if (new Date(quote.expiredAt).getTime() > (new Date()).getTime()){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <h6 className={"text-center"}>
                    Ce devis a expiré.
                </h6>
                <p className={"text-center"}>
                    Veuillez contacter votre représentant commercial pour plus de détails.
                </p>
                <div className="card border border-1">
                    <div className="card-body">
                        <p>
                           <i className={'bi bi-person'}></i> {quote.project.account.firstname} {quote.project.account.lastname}
                        </p>
                        <p>
                            <i className={'bi bi-tel'}></i> {quote.project.account.countryCode} {quote.project.account.tel}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    }

    if (quote.status === "Pending"){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <h6>
                    Ce devis n'est pas encore disponible
                </h6>
            </div>
        </div>
    }

    if (quote.status === "Accepted"){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <h6>
                    Ce devis est déjà signé. <Link to={`/customer/quote/${quote.id}`}> Retouvez les informations du devis dans votre compte client <i className={'bi bi-arrow-right'}></i></Link>
                </h6>
            </div>
        </div>
    }

    if (quote.status === "Refused"){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <h6>
                    Ce devis a été refusé. <Link to={`/customer/quote/${quote.id}`}> Retouvez les informations du devis dans votre compte client <i className={'bi bi-arrow-right'}></i></Link>
                </h6>
            </div>
        </div>
    }

    if (quote.status === "Canceled"){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <h6>
                    Ce devis a été annulé.
                </h6>
            </div>
        </div>
    }

    return (
        <>
            <QuoteNavHeader quote={quote} />
            <div className={"container container-fluid"}>
                <div className="row">
                    <div className="col-md-3">
                        <div className="col-12 mb-3">
                            <div className="card border border-1">
                                <div className="card-header">
                                    <h6>
                                        Représentant commercial
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <table className={"table-borderless"}>
                                        <tbody>
                                        <tr>
                                            <th>
                                                <i className={'bi bi-person'}></i>
                                            </th>
                                            <td>
                                                {quote.project.account.firstname} {quote.project.account.lastname}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <i className={'bi bi-phone'}></i>
                                            </th>
                                            <td>
                                                {quote.project.account.countryCode} {quote.project.account.tel}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {quote.signature && <div className={'col-12 mb-3'}>
                            <div className={"card border border-1 border-success bg-success-soft text-success"}>
                                <div className="card-body">
                                    <p>
                                        Devis signé le {new Date(quote.signature.signedAt).toLocaleDateString()} à {new Date(quote.signature.signedAt).toLocaleTimeString()}.
                                    </p>
                                    <p>
                                        Signature: {quote.signature.signature}
                                    </p>
                                </div>
                            </div>
                        </div>}
                        <div className="col-12 mb-3">
                            <QuoteCondition quote={quote} />
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-12 mb-3">
                                <QuoteShowRows quote={quote} />
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <div className="card border border-1">
                                <div className="card-header">
                                    <h6>
                                        Addresses
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <QuoteAddress quote={quote} setQuote={setQuote} />
                                </div>
                            </div>
                        </div>
                        {((quote.shippable && quote.shipping && quote.billing) || (!quote.shippable && quote.billing)) && <div className="col-12 mb-3">
                            {quote.signature ? <div className={'col-12 mb-3'}>
                                <div className="card border border-1">
                                    <div className="card-header">
                                        <h6>
                                            Procéder au paiement pour valider le devis
                                        </h6>
                                    </div>
                                    <div className="card-body">
                                        <QuotePayment quote={quote}/>
                                    </div>
                                </div>
                            </div> : <QuoteSignature quote={quote} setQuote={setQuote}/>}
                        </div>}
                    </div>
                </div>
            </div>
            <QuoteNavFooter />
        </>
    )
}

export default QuoteShow;