import React, {useState} from 'react';
import {useStripe, useElements} from '@stripe/react-stripe-js';
import {useNavigate} from "react-router-dom";

const QuotePaymentForm = ({disabled, uuid, clientSecret}: {disabled: boolean, uuid: string, clientSecret: string}) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState<string|undefined|null>(null);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const {error} = await stripe.confirmCardPayment(clientSecret);

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            navigate(`/quote/${uuid}/confirm`)
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <button className={"btn btn-primary w-100"} disabled={!stripe || disabled}>
                Payer
            </button>
            {errorMessage && <div>{errorMessage}</div>}
        </form>
    )
};

export default QuotePaymentForm;