import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Organization} from "../../../../../models/organization";
import * as api from "../../../../../adapters/corporate/common/organization";
import * as partnershipRequestApi from "../../../../../adapters/corporate/common/partnership/request";
import * as partnershipApi from "../../../../../adapters/corporate/common/partnership";
import Loader from "../../../../../utils/loader";
import SupplierShow from "./supplier";
import ProviderShow from "./provider";
import {getLogoUrl} from "../../../../../utils/image";
import {useAppSelector} from "../../../../../hooks";
import PartnershipAdd from "../../partnership/add";
import ModalComponent from "../../../../modal";
import {Partnership, PartnershipRequest, PartnershipRequests} from "../../../../../models/partnership";
import PrivateChatAdd from "../../chat/add/private";
import {CorporateAccount} from "../../../../../models/account";
import PartnershipRequestAccept from "../../partnership/request/accept";
import {Payload} from "../../../../../models/global";
import {formatURL} from "../../../../../utils/url";

const OrganizationShow = () => {

    const params = useParams();
    const [showPartnershipAddModal, setShowPartnershipAddModal] = useState(false)
    const activeAccount = useAppSelector(state => state.auth.user?.activeAccount) as CorporateAccount
    const authOrganization = activeAccount.organization;
    const [loading, setLoading] = useState<boolean>(true)
    const [organization, setOrganization] = useState<Organization>()
    const [showContactModal, setShowContactModal] = useState(false)
    const [showAcceptModal, setShowAcceptModal] = useState(false)
    const [showAllBio, setShowAllBio] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        api.show(Number(params.id))
            .then(resp => setOrganization(resp.data))
            .finally(() => setLoading(false))
    }, [params.id])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    function cancelRequest(request: PartnershipRequest){
        partnershipRequestApi.cancel(request.id)
            .then(r => setOrganization(prev => ({...prev!, myPartnershipRequest: null})))
            .catch(e => console.log(e))
    }

    function declineRequest(request: PartnershipRequest){
        partnershipRequestApi.decline(request.id)
            .then(() => setOrganization(prev => ({...prev!, myPartnershipRequest: null})))
            .catch(e => console.log(e))
    }

    function endPartnership(partnership: Partnership)
    {
        partnershipApi.update(partnership.id, {status: "Ended"})
            .then(() => setOrganization(prev => ({...prev!, myPartnership: null})))
            .catch(e => console.log(e))
    }

    if (!organization){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <i className={'bi bi-search'}></i> Entreprise introuvable
            </div>
        </div>
    }



    return <div className={'container-fluid'}>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col">
                            <div className="row align-items-center">
                                <div className="col-auto d-flex align-items-center">
                                    <div className="avatar avatar-xxl">
                                        <img src={getLogoUrl(organization)} alt={organization.name} className="avatar-img rounded-circle" />
                                    </div>
                                    <div className="col ps-3">
                                        <p className={'mb-0 text-muted'}>
                                            {organization.type === "provider" ? <>Vendeur <span className={`badge ${organization.information.type === "company" ? 'bg-success' : 'bg-info'}`}>{organization.information.type === "company" ? "Entreprise" : "Particulier"}</span></> : "Fournisseur"}
                                        </p>
                                        <h5 className={'mb-1'}>
                                            {organization.name} {organization.status === "Verified" && <i className={"bi bi-check-circle text-success"}></i>}
                                        </h5>
                                        <div className="fs-5 text-success">
                                            <i className={"bi bi-trophy"}></i> {organization.ranking.score.toLocaleString()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {authOrganization.type !== organization.type && <div className="col-auto">
                            <div className="row">
                                <div className="col-auto">
                                    {organization.myPartnership ? <div className="dropdown">
                                        <button className="btn btn-primary btn-xxs dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className={'bi bi-check'}></i> Partenaire
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li className="dropdown-item" onClick={() => endPartnership(organization!.myPartnership!)}><a>Terminer le partenariat</a></li>
                                        </ul>
                                    </div> : (organization.myPartnershipRequest ? (organization.myPartnershipRequest.applicant.id === authOrganization.id ? <div className="dropdown">
                                        <button className="btn btn-primary btn-xxs dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className={'bi bi-clock'}></i> Invitation en attente
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li className="dropdown-item" onClick={() => cancelRequest(organization!.myPartnershipRequest!)}>Annuler l'invitation</li>
                                        </ul>
                                    </div> : <div className={"row"}>
                                        <div className="col-auto">
                                            <button className="btn btn-success btn-xxs" onClick={() => setShowAcceptModal(true)} type="button">
                                                <i className={'bi bi-check'}></i> Accepter l'invitation
                                            </button>
                                        </div>
                                        <div className="col-auto">
                                            <button className="btn btn-danger btn-xxs" type="button" onClick={() => declineRequest(organization!.myPartnershipRequest!)}>
                                                <i className={'bi bi-x'}></i> Refuser l'invitation
                                            </button>
                                        </div>
                                    </div>) :  <button className="btn btn-xxs btn-primary" type="button" onClick={() => setShowPartnershipAddModal(true)}>
                                        <i className={'bi bi-send'}></i> Inviter
                                    </button>)}
                                </div>
                                {organization.myPartnership ? <div className="col-auto">
                                    <button className="btn btn-primary btn-xxs" onClick={() => setShowContactModal(true)}>
                                        <i className={'bi bi-chat'}></i> Contacter
                                    </button>
                                    <ModalComponent title={`Contacter ${organization.name}`} show={showContactModal} onHide={() => setShowContactModal(false)} >
                                        <PrivateChatAdd onSuccess={chat => navigate(`/corporate/chat/${chat.id}`)} organization={organization} />
                                    </ModalComponent>
                                </div> : <div className="col-auto">
                                    <button className="btn btn-primary btn-xxs" disabled={true}>
                                        <i className={'bi bi-chat'}></i> Contacter
                                    </button>
                                </div>}
                            </div>
                        </div>}
                    </div>
                    <div className="col-12 mb-3">
                        <div className="row">
                            <div className="col">
                                {organization.bio.website && <>
                                    <div className="text-body-secondary small">
                                        Site internet
                                    </div>
                                    <Link to={`${formatURL(organization.bio.website)}`} target={'_blank'}>
                                        {formatURL(organization.bio.website)}
                                    </Link>
                                </>}
                            </div>
                            <div className="col-auto">
                                {organization.bio.instagram && <button className={'btn btn-light btn-xxs btn-circle me-2'}>
                                    <i className={'bi bi-instagram'}></i>
                                </button>}
                                {organization.bio.x && <button className={'btn btn-light btn-xxs btn-circle me-2'}>
                                    <i className={'bi bi-twitter-x'}></i>
                                </button>}
                                {organization.bio.facebook && <button className={'btn btn-light btn-xxs btn-circle me-2'}>
                                    <i className={'bi bi-facebook'}></i>
                                </button>}
                                {organization.bio.linkedin && <button className={'btn btn-light btn-xxs btn-circle me-2'}>
                                    <i className={'bi bi-linkedin'}></i>
                                </button>}
                            </div>
                        </div>
                    </div>
                    <p className={"fw-semibold mb-1"}>
                        Description de l'activité
                    </p>
                    <p style={{whiteSpace: "pre-wrap"}}>
                        {organization.bio.description.slice(0, showAllBio ? organization.bio.description.length : 500)}
                        {organization.bio.description.length > 500 && (showAllBio ? <span className={"text-info text-decoration-underline cursor-pointer"} onClick={() => setShowAllBio(false)}>Voir moins</span> : <>...<span className={"text-info text-decoration-underline cursor-pointer"} onClick={() => setShowAllBio(true)}>Voir plus</span></>)}
                    </p>
                </div>
            </div>
        </div>
        {organization.type === "supplier" && <SupplierShow organization={organization} />}
        {organization.type === "provider" && <ProviderShow organization={organization} />}
        <ModalComponent size={"modal-lg"} title={"Envoyer une invitation"} show={showPartnershipAddModal} onHide={() => setShowPartnershipAddModal(false)}>
            <PartnershipAdd key={organization.id} organization={organization} handleSuccess={p => {
                setOrganization(prev => ({...prev!, myPartnershipRequest: p}))
                setShowPartnershipAddModal(false)
            }} />
        </ModalComponent>
        {showAcceptModal && organization.myPartnershipRequest && <ModalComponent
            size={"modal-lg"}
            title={`Accepter la demande de ${organization.myPartnershipRequest.applicant.name}`}
            show={showAcceptModal}
            onHide={() => setShowAcceptModal(false)}
        >
            <PartnershipRequestAccept partnershipRequest={organization.myPartnershipRequest} onSuccess={partnership => {
                setOrganization(prev => ({...prev!, myPartnershipRequest: null, myPartnership: partnership}))
                setShowAcceptModal(false)
            }} />
        </ModalComponent>}
    </div>
}

export default OrganizationShow;