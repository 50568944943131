import {useFormik} from "formik";
import React, {useEffect, useState} from "react";
import * as Yup from 'yup'
import FormError from "../../../../../utils/formError";
import Loader from "../../../../../utils/loader";
import * as api from "../../../../../adapters/corporate/provider/touch";
import ProviderContactSelect from "../../contact/select";
import TextAreaComponent from "../../../../form/textArea";
import {format} from "date-fns";
import ErrorCard from "../../../../error";
import {getLogoUrl} from "../../../../../utils/image";
import {Partnership, Partnerships} from "../../../../../models/partnership";
import ModalComponent from "../../../../modal";
import ProviderPartnerSelectorIcon from "../../partner/selector/icon";
import {Link} from "react-router-dom";

const ProviderTouchAdd = () => {

    const [error, setError] = useState(null)

    const [partners, setPartners] = useState<Partnerships>([]);
    const [showPartnersSelector, setShowPartnersSelector] = useState(false)

    const [initialValues, setInitialValues] = useState<any>({
        title: "",
        description: "",
        contact_id: "",
        suppliers: [],
        launch: "Unknown",
        location: "Unknown",
    })

    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        title: Yup.string().required(),
        description: Yup.string().required(),
        contact_id: Yup.number().required(),
        suppliers: Yup.array().of(Yup.number()).min(1).required(),
        launch: Yup.string().required(),
        location: Yup.string().required(),
    }))

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, {setSubmitting}) => {
            api.add(values)
                .then(r => console.log(r.data))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    })

    useEffect(() => {
        formik.setFieldValue('suppliers', partners.map(p => p.supplier.id))
    }, [partners])

    function handleLaunchChange(value: "Asap"|"Date"|"Unknown"){

        let _schema: any = {...validationSchema.fields};
        let _values: any = {...formik.values};
        _values.launch = value;

        switch (value){
            case "Unknown":
            case "Asap":
                delete _values.launchAt
                delete _schema.launchAt
                delete _values.isFlexible
                delete _schema.isFlexible
                delete _values.flexibleType
                delete _schema.flexibleType
                break;
            case "Date":
                _values.launchAt = new Date();
                _schema.launchAt = Yup.date().required()
                _values.isFlexible = false;
                _schema.isFlexible = Yup.boolean().required()
                break;
        }

        setInitialValues({..._values});
        setValidationSchema(Yup.object().shape({..._schema}))
    }

    function handleIsFlexibleChange(isFlexible: boolean){

        let _schema: any = {...validationSchema.fields};
        let _values: any = {...formik.values};
        _values.isFlexible = isFlexible;

        if(isFlexible){
            _values.flexibleType = 'day';
            _schema.flexibleType = Yup.string().required();
        }else{
            delete _values.flexibleType
            delete _schema.flexibleType
        }

        setInitialValues({..._values});
        setValidationSchema(Yup.object().shape({..._schema}))
    }

    function handleLocationChange(value: "Distant"|"OnSite"|"Unknown"){

        let _schema: any = {...validationSchema.fields};
        let _values: any = {...formik.values};
        _values.location = value;

        switch (value){
            case "Unknown":
            case "Distant":
                delete _values.city
                delete _schema.postalCode
                break;
            case "OnSite":
                _values.city = '';
                _schema.city = Yup.string().required()
                _values.postalCode = '';
                _schema.postalCode = Yup.string().required()
                break;
        }

        setInitialValues({..._values});
        setValidationSchema(Yup.object().shape({..._schema}))
    }

    function handlePartnerClick(partner: Partnership){
        let index = partners.findIndex(p => p.id === partner.id)

        if (index !== -1){
            setPartners(prevState => [...prevState.filter(p => p.id !== partner.id)])
        }else{
            setPartners(prevState => [...prevState, partner])
        }
    }

    return <div className={"container-fluid"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to={`/corporate/provider/touch`}>
                        Mise en relation
                    </Link>
                </li>
                <li className="breadcrumb-item">
                    Ajouter
                </li>
            </ol>
        </div>
        <form onSubmit={formik.handleSubmit} className={"col-12 mb-3"}>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className="form-control" name={'title'} id={"title"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                    <label htmlFor="title">
                        Titre
                    </label>
                </div>
            </div>
            <TextAreaComponent formik={formik} name={"description"} maxLength={2000} label={"Description"} />
            <div className="col-12 mb-3">
                <ProviderContactSelect label={"Contact lié"} allowAdd={true} required={true} handleChange={c => formik.setFieldValue("contact_id", c)}  />
                <FormError form={formik} field={'contact_id'} />
            </div>
            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h6>Partenaies séléctionnés</h6>
                            </div>
                            <div className="col-auto">
                                <button type={'button'} className={"btn btn-xs btn-primary"} onClick={() => setShowPartnersSelector(true)}>
                                    Séléctionner des partenaires <i className={'bi bi-arrow-right'}></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        {!partners.length && <div className={'col-12 text-center fs-4 text-muted'}>
                            <i className={'bi bi-search'}></i> Aucun partenaires séléctionné
                        </div>}
                        <div className={"row row-cols-1 row-cols-md-3"}>
                            {partners.map(r => <div className={"col mb-3"}>
                                <div className="card h-100 border border-1 position-relative">
                                <button type={"button"} style={{top: 10, right: 10}} className={'btn btn-xs rounded bg-light position-absolute cursor-pointer'} onClick={() => handlePartnerClick(r)}>
                                    <i className={'bi bi-x text-danger'}></i>
                                </button>
                                    <img src={`${getLogoUrl(r.supplier)}`} alt="" className="card-img-top"/>
                                    <div className="card-body p-2">
                                        <div className="card-body">
                                            <p className="d-block fw-semibold text-body mb-1">
                                                {r.supplier.name} {r.supplier.status === 'Verified' && <i className={'bi bi-check-circle text-success'}></i>}
                                            </p>
                                            <div className="fs-sm fw-bold text-primary">
                                                <i className={"bi bi-trophy"}></i> {r.supplier.ranking?.score.toLocaleString() || 0}
                                            </div>
                                            <div className="col-12">
                                                {r.supplier.sectors.slice(0,2).map(s => <span className={'badge bg-light text-dark me-2 mb-2'}>{s.name}</span>)} {r.supplier.sectors.length > 2 && <span className={'badge bg-light text-dark me-2 mb-2'}>+ {r.supplier.sectors.length - 2}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>
                            Lancement prévu du projet
                        </h6>
                    </div>
                    <div className="card-body">
                        <div className="col-12 mb-3">
                            <div className={"card border border-1 " + (formik.values.launch === "Asap" ? " border-success shadow " : "")}>
                                <div className="card-body">
                                    <div className="form-check">
                                        <input onChange={() => handleLaunchChange("Asap")} className="form-check-input" type="radio" name="launch" value={"Asap"} id="launch_asap" />
                                        <label className="form-check-label" htmlFor="launch_asap">
                                            Dès que possible
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <div className={"card border border-1 " + (formik.values.launch === "Date" ? " border-success shadow " : "")}>
                                <div className="card-body">
                                    <div className="form-check">
                                        <input onChange={() => handleLaunchChange("Date")} className="form-check-input" type="radio" name="launch" value={"Date"} id="launch_date" />
                                        <label className="form-check-label" htmlFor="launch_date">
                                            A une date donnée <span className="form-text">(Fixe ou flexible)</span>
                                        </label>
                                    </div>
                                    {formik.values.launch === "Date" && <>
                                        <div className={'col-12 my-3'}>
                                            <div className="form-floating">
                                                <input type="date" defaultValue={format(formik.values.launchAt, "uuuu-MM-dd")} className={"form-control"} id={"launchAt"} name={"launchAt"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                <label htmlFor={"launchAt"}>
                                                    Date de lancement
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" defaultChecked={false} id="isFlexible" name={"isFlexible"} onChange={e => handleIsFlexibleChange(e.target.checked)} />
                                                        <label className="form-check-label" htmlFor="isFlexible">
                                                            Date de lancement flexible
                                                        </label>
                                                    </div>
                                                </div>
                                                {formik.values.isFlexible === true && <div className={"col"}>
                                                    <div className="btn-group">
                                                        <button type={'button'} className={'btn ' + (formik.values.flexibleType === "day" ? " btn-primary " : " btn-light " )} onClick={() => formik.setFieldValue('flexibleType', "day")}>
                                                            +/- 2 jours
                                                        </button>
                                                        <button type={'button'} className={'btn ' + (formik.values.flexibleType === "week" ? " btn-primary " : " btn-light " )} onClick={() => formik.setFieldValue('flexibleType', "week")}>
                                                            +/- 1 semaine
                                                        </button>
                                                        <button type={'button'} className={'btn ' + (formik.values.flexibleType === "month" ? " btn-primary " : " btn-light " )} onClick={() => formik.setFieldValue('flexibleType', "month")}>
                                                            +/- 1 mois
                                                        </button>
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </>}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <div className={"card border border-1 " + (formik.values.launch === "Unknown" ? " border-success shadow " : "")}>
                                <div className="card-body">
                                    <div className="form-check">
                                        <input defaultChecked={true} onChange={() => handleLaunchChange("Unknown")} className="form-check-input" type="radio" name="launch" value={"Unknown"} id="launch_unknown" />
                                        <label className="form-check-label" htmlFor="launch_unknown">
                                            Je ne sais pas
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>
                            Lieux du projet
                        </h6>
                    </div>
                    <div className="card-body">
                        <div className="col-12 mb-3">
                            <div className={"card border border-1 " + (formik.values.location === "Distant" ? " border-success shadow " : "")}>
                                <div className="card-body">
                                    <div className="form-check">
                                        <input onChange={() => handleLocationChange("Distant")} className="form-check-input" type="radio" name="location" value={"Distant"} id="location_distant"  />
                                        <label className="form-check-label" htmlFor="location_asap">
                                            Projet à distance
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <div className={"card border border-1 " + (formik.values.location === "OnSite" ? " border-success shadow " : "")}>
                                <div className="card-body">
                                    <div className="form-check">
                                        <input onChange={() => handleLocationChange("OnSite")} className="form-check-input" type="radio" name="location" value={"Date"} id="location_onSite" />
                                        <label className="form-check-label" htmlFor="location_onSite">
                                            Sur un lieux donnée
                                        </label>
                                    </div>
                                    {formik.values.location === "OnSite" && <>
                                        <div className={'col-12 my-3'}>
                                            <div className="form-floating">
                                                <input type="text" className={"form-control"} id={"city"} name={"city"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                <label htmlFor={"city"}>
                                                    Ville
                                                </label>
                                            </div>
                                            <FormError form={formik} field={'city'} />
                                        </div>
                                        <div className={'col-12 my-3'}>
                                            <div className="form-floating">
                                                <input type="text" className={"form-control"} id={"postalCode"} name={"postalCode"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                <label htmlFor={"postalCode"}>
                                                    Code postal
                                                </label>
                                            </div>
                                            <FormError form={formik} field={'postalCode'} />
                                        </div>
                                    </>}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <div className={"card border border-1 " + (formik.values.location === "Unknown" ? " border-success shadow " : "")}>
                                <div className="card-body">
                                    <div className="form-check">
                                        <input defaultChecked={true} onChange={() => handleLocationChange("Unknown")} className="form-check-input" type="radio" name="location" value={"Unknown"} id="location_unknown" />
                                        <label className="form-check-label" htmlFor="location_unknown">
                                            Je ne sais pas
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {error && <div className={"col-12 mb-3"}>
                <ErrorCard error={error} />
            </div>}
            <button type="submit" className="btn btn-primary w-100">
                {formik.isSubmitting ? <Loader size="sm" /> : <><i className="bi bi-check"></i> Enregistrer</>}
            </button>
            {showPartnersSelector && <ModalComponent
                size={'modal-xl'}
                title={"Séléctionner des partenaires"}
                show={showPartnersSelector}
                onHide={() => setShowPartnersSelector(false)}
                footerContent={<button type={"button"} className={'btn btn-light btn-sm'} onClick={() => setShowPartnersSelector(false)}>Terminé</button>}
            >
                <ProviderPartnerSelectorIcon handleClick={handlePartnerClick} selected={partners} />
            </ModalComponent>}
        </form>
    </div>


}

export default ProviderTouchAdd;