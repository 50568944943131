import {Chat} from "../../../../../../models/chat";
import React from "react";
import * as models from "../../../../../../models/chat";
import {Link, useParams} from "react-router-dom";
import {getLogoUrl} from "../../../../../../utils/image";
import {useAppSelector} from "../../../../../../hooks";
import {Account, CorporateAccount} from "../../../../../../models/account";

const ChatListItem = ({chat, handleClick}: {chat: Chat, handleClick: () => void}) => {

    const {id} = useParams();
    const authAccount = useAppSelector(state => state.auth.user!.activeAccount)

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const now = new Date();

        const isToday = date.toDateString() === now.toDateString();
        const isYesterday = date.toDateString() === new Date(now.setDate(now.getDate() - 1)).toDateString();

        if (isToday) {
            return date.toLocaleTimeString(undefined, {timeStyle: "short"});
        } else if (isYesterday) {
            return date.toLocaleDateString(undefined, {dateStyle: 'short'});
        } else {
            return date.toLocaleDateString();
        }
    };

    const PrivateChat:React.FC<{chat: models.Chat & models.PrivateChat}> = ({chat}) => {

        let account = chat.accounts.find(p => p.id !== authAccount.id) as CorporateAccount;

        return <div onClick={() => handleClick()} className={"col-12 cursor-pointer position-relative rounded py-3 "}>
            <div className="row align-items-center">
                <div className="col-auto">
                    <div className="avatar avatar-sm">
                        <img src={getLogoUrl(account.organization)} alt={account.organization.name} className="avatar-img rounded-circle" />
                    </div>
                </div>
                <div className={"col text-nowrap overflow-hidden"}  style={{textOverflow: "ellipsis"}}>
                    <p className="mb-0 fs-xs text-dark">
                        {account.firstname} {account.lastname}
                    </p>
                    {chat.latestMessage && <p className="mb-0 fs-xxs form-text">
                        {chat.latestMessage.account.id === authAccount.id ? "Vous" : chat.latestMessage.account.firstname}: {chat.latestMessage.content}
                    </p>}
                </div>
                {chat.latestMessage && <div className="col-auto">
                    <p className="mb-0 form-text fs-xxs">{formatDate(chat.latestMessage.createdAt)}</p>
                    {chat.unreadMessagesCount > 0 && <div className={'text-end'}><div className="badge bg-primary">{chat.unreadMessagesCount}</div></div>}
                </div>}
            </div>
        </div>
    }

    const GroupChat:React.FC<{chat: models.Chat & models.GroupChat}> = ({chat}) => {

        let accounts = chat.accounts.filter(p => p.id !== authAccount.id)

        return <Link to={`/corporate/chat/${chat.id}`} className={"col-12 cursor-pointer position-relative rounded py-3"}>
            <div className="row align-items-center">
                <div className="col-auto">
                    <div className="avatar-group">
                        {accounts.slice(0, 3).map(p => <div className="avatar avatar-sm">
                            <img src={getLogoUrl(p.organization)} alt={p.organization.name} className="avatar-img rounded-circle" />
                        </div>)}
                        {accounts.length > 3 && <div className="avatar">
                            <span className="avatar-title rounded-circle"><i className={'bi bi-plus'}></i> {accounts.length - 3}</span>
                        </div>}
                    </div>
                </div>
                <div className={"col text-nowrap overflow-hidden"}  style={{textOverflow: "ellipsis"}}>
                    <p className="mb-0 fs-xs text-dark">
                        {chat.name}
                    </p>
                    {chat.latestMessage && <p className="mb-0 fs-xxs form-text">
                        {chat.latestMessage.account.id === authAccount.id ? "Vous" : chat.latestMessage.account.firstname}: {chat.latestMessage.content}
                    </p>}
                </div>
                {chat.latestMessage && <div className="col-auto">
                    <p className="mb-0 form-text fs-xxs">{formatDate(chat.latestMessage.createdAt)}</p>
                    {chat.unreadMessagesCount > 0 && <div className={'text-end'}><div className="badge bg-primary">{chat.unreadMessagesCount}</div></div>}
                </div>}
            </div>
        </Link>
    }

    return <li className={'list-group-item list-group-item-action' + ((id && chat.id === Number(id)) ? " chat-active " : "  ")}>
        {chat.type === "private" && <PrivateChat chat={chat} />}
        {chat.type === "group" && <GroupChat chat={chat} />}
    </li>
}

export default ChatListItem;