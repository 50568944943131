import {reason_labels} from "../../../../../../constants/returns";

const CustomerReturnAddReason3 = ({reason2, handleNext, handlePrev}: {reason2: "return_mismatch"|"return_unwanted"|"return_missing"|"return_else", handleNext: (reason: "reason3", value: string) => void, handlePrev: (reason: string) => void}) => {

    return <div className="card h-100">
        <div className="card-header">
            <div className="row">
                <div className="col-auto">
                    <div className="btn btn-xxs btn-light" onClick={() => handlePrev("reason3")}>
                        <i className={'bi bi-arrow-left'}></i>
                    </div>
                </div>
                <div className="col">
                    <h6>Comment pouvons-nous vous aider avec cet article ?</h6>
                </div>
            </div>
        </div>
        <div className="card-body">
            <ul className="list-group list-group-flush">
                {reason2 === "return_mismatch" && <>
                    <li className={'list-group-item list-group-item-action'} onClick={() => handleNext('reason3', "mismatch_item" )}>
                        {reason_labels.mismatch_item} <i className={'bi bi-chevron-right'}></i>
                    </li>
                    <li className={'list-group-item list-group-item-action'} onClick={() => handleNext('reason3', "mismatch_description" )}>
                        {reason_labels.mismatch_description} <i className={'bi bi-chevron-right'}></i>
                    </li>
                </>}

                {reason2 === "return_unwanted" && <>
                    <li className={'list-group-item list-group-item-action'} onClick={() => handleNext('reason3', "unwanted_switch_mind" )}>
                        {reason_labels.unwanted_switch_mind} <i className={'bi bi-chevron-right'}></i>
                    </li>
                    <li className={'list-group-item list-group-item-action'} onClick={() => handleNext('reason3', "unwanted_mistake" )}>
                        {reason_labels.unwanted_mistake} <i className={'bi bi-chevron-right'}></i>
                    </li>
                </>}
            </ul>
        </div>
    </div>
}

export default CustomerReturnAddReason3;
