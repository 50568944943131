import {Price} from "../../../../../models/price";
import convertToDecimal from "../../../../../utils/currencyConverter";
import React, {useState} from "react";
import ListEmpty from "../../../../list/empty";
import ModalComponent from "../../../../modal";

const SupplierPriceShow = ({price}: {price: Price}) => {

    const [showOptionsModal, setShowOptionsModal] = useState(false)

    return <>
        <div className="row align-items-center">
            <div className="col d-flex align-items-center">
                {price.recurring && <span className={'badge bg-primary text-white ms-2'}>Abonnement</span>}
            </div>
        </div>
        <div className={"row row-cols-3"}>
            <div className="col d-flex flex-column mb-3">
                <div className="form-text">
                    Montant HT
                </div>
                <div>
                    <p className={'mb-1'}>
                        {convertToDecimal(price.amount, price.currency)} {price.recurring && <>Tout les {price.recurring.interval}</>}
                    </p>
                    {price.recurring && price.recurring.trialPeriodDays > 0 && <span className={"text-muted small"}>{price.recurring.trialPeriodDays} jours de période d'essai gratuite</span>}
                </div>
            </div>
            <div className="col d-flex flex-column mb-3">
                <div className="form-text">
                    Commission
                </div>
                <span>
                    {price.feePercent}%
                </span>
            </div>
        </div>
        <div className="col-auto">
            <button className="btn btn-xxs" onClick={() => setShowOptionsModal(true)}>
                Autres devises <i className={'bi bi-arrow-right'}></i>
            </button>
        </div>
        <ModalComponent title={"Autres devises"} show={showOptionsModal} onHide={() => setShowOptionsModal(false)}>
            {price.options.length === 0 ? <ListEmpty /> : <ul className={"list-group"}>
                {price.options.map(o => <li className={'list-group-item'}>
                    {convertToDecimal(o.amount, o.currency)}
                </li>)}
            </ul>}
        </ModalComponent>
    </>
}

export default SupplierPriceShow;