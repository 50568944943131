import React from "react";

const QuoteStatusPlaceholder = ({status, displayCaret}: {status: "All"|"Pending"|"Published"|"Accepted"|"Validation"|"Completed"|"Refused"|"Canceled", displayCaret?: boolean}) => {

    switch (status){
        case "All":
            return <span className={'badge bg-light text-dark'}>
                    Tout les statuts
                </span>
        case "Pending":
            return <span className={'badge bg-warning'}>
                    En attente {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Published":
            return <span className={'badge bg-info'}>
                    Publié {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Canceled":
            return <span className={'badge bg-danger'}>
                    Annulé {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Accepted":
            return <span className={'badge bg-success'}>
                    Accepté {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Validation":
            return <span className={'badge bg-info'}>
                    Validation client {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Completed":
            return <span className={'badge bg-info'}>
                    Terminé {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Refused":
            return <span className={'badge bg-danger'}>
                    Refusé {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
    }

    return <>Invalid status</>
}

export default QuoteStatusPlaceholder;