import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Webhook} from "../../../../../models/webhook";
import * as api from "../../../../../adapters/corporate/supplier/webhook";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import SupplierWebhookEvent from "../event";
import SupplierWebhookShowIntegration from "./integration";
import ModalComponent from "../../../../modal";
import SupplierWebhookUpdate from "../update";

const SupplierWebhookShow = () => {

    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [webhook, setWebhook] = useState<Webhook>()
    const [error, setError] = useState();
    const [tab, setTab] = useState<'event'|'integration'>("event");
    const [updateModalShow, setUpdateModalShow] = useState(false)

    useEffect(() => {

        api.show(Number(id))
            .then(r => setWebhook(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))

    }, [id])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!webhook){
        return <></>
    }

    return <div className={'container-fluid'}>
        <div className="col-12 mb-3">
            <div className="row align-items-center">
                <div className="col">
                    <ol className="breadcrumb mb-3 fs-xs">
                        <Link to={'/corporate/supplier/webhook'} className="breadcrumb-item">
                            Webhook
                        </Link>
                        <li className="breadcrumb-item">
                            {webhook.endpoint}
                        </li>
                    </ol>
                </div>
                <div className="col-auto">
                    <button className={"btn btn-light btn-xxs"} onClick={() => setUpdateModalShow(true)}>
                        <i className={'bi bi-pencil'}></i> Modifier
                    </button>
                </div>
            </div>
        </div>
        <div className="col-md-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Informations
                    </h6>
                </div>
                <div className="card-body">
                    <div className="table table-borderless">
                        <tbody>
                        <tr>
                            <th>
                                État
                            </th>
                            <td>
                                {webhook.active ? <span className={'badge bg-success'}> <i className={"bi bi-check"}></i> Activé</span> : <span className={"badge bg-danger"}><i className={"bi bi-x"}></i> Desactivé</span>}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Clé secrète
                            </th>
                            <td>
                                {webhook.secret}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Description
                            </th>
                            <td>
                                {webhook.description}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Évènements écoutés
                            </th>
                            <td>
                                {webhook.scope.length}
                            </td>
                        </tr>
                        </tbody>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="mb-2 mb-lg-0 border-bottom-lg">
                <nav className="nav nav-tabs nav-overflow fs-xs border-bottom border-bottom-lg-0">
                    <button onClick={() => setTab('event')} className={"nav-link text-uppercase" + (tab === 'event' ? " active " : "")}>
                        Évènements
                    </button>
                    <button onClick={() => setTab('integration')} className={"nav-link text-uppercase" + (tab === "integration" ? " active " : "")}>
                        Intégration
                    </button>
                </nav>
            </div>
        </div>
        {tab === "event" && <SupplierWebhookEvent webhook={webhook} key={webhook.id}/>}
        {tab === "integration" && <SupplierWebhookShowIntegration webhook={webhook} />}
        <ModalComponent title={"Modifier le webhook"} show={updateModalShow} onHide={() => setUpdateModalShow(false)}>
            <SupplierWebhookUpdate webhook={webhook} setWebhook={setWebhook} />
        </ModalComponent>
    </div>
}

export default SupplierWebhookShow