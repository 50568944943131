import {useQuery} from "../../../utils/useQuery";
import React, {useEffect, useState} from "react";
import {Accounts, Account, CorporateAccount, CustomerAccount} from "../../../models/account";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import * as api from "../../../adapters/account";
import {getLogoUrl} from "../../../utils/image";
import Loader from "../../../utils/loader";
import ErrorCard from "../../error";
import {Link, Navigate, useNavigate} from "react-router-dom";
import encodeQueryData from "../../../utils/encodeQueryData";
import {urlSearchParamsToObject} from "../../../utils/urlSearchParamsToObject";
import authSlice from "../../../reducers/auth";

const AccountList = () => {

    const dispatch = useAppDispatch();
    const activeAccount = useAppSelector(state => state.auth.user?.activeAccount) as Account;
    const [payload, setPayload] = useState<Accounts>([])
    const [loading, setLoading] = useState(true)
    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState(null)
    const query = useQuery();
    const type = (query.get("type") || activeAccount.type) as 'customer'|'corporate';
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        api.list({type: type})
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [type])

    function handleClick(account: Account){
        if (!submitting){
            api.select(account.id)
                .then(r => dispatch(authSlice.actions.setAccount(r.data)))
                .then(() => navigate(query.has("redirect") ? query.get("redirect")! : `/${type}`))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    }

    if (!loading && !error && payload.length === 0){
        return <Navigate to={`/account/add${encodeQueryData(urlSearchParamsToObject(query))}`} />
    }

    const CorporateAccountItem = ({account}: {account: CorporateAccount}) => {
        return <li className={"list-group-item list-group-item-action"} onClick={() => handleClick(account)}>
            <div className="row align-items-center">
                <div className="col-auto d-flex align-items-center">
                    <div className="avatar me-2">
                        <img src={getLogoUrl(account.organization)} alt="" className="avatar-img rounded-circle"/>
                    </div>
                    <div>
                        {account.organization.name}
                    </div>
                </div>
                <div className="col-auto">
                    <i className={"bi bi-chevron-right"}></i>
                </div>
            </div>
        </li>
    }

    const CustomerAccountItem = ({account}: {account: CustomerAccount}) => {
        return <li className={"list-group-item list-group-item-action"} onClick={() => handleClick(account)}>
            <div className="row align-items-center">
                <div className="col-auto">
                    {account.firstname} {account.lastname}
                </div>
                <div className="col-auto">
                    <i className={"bi bi-chevron-right"}></i>
                </div>
            </div>
        </li>
    }

    return (<>
        <div className="card border border-1 rounded">
            <div className="card-body">
                <div className="card-header">
                    {query.has('next') ? <>
                        {query.get('next') === "checkout" && <h6 className={"text-center"}>
                            Séléctionner un compte pour effectuer votre commande
                        </h6>}
                        {query.get('next') === "quote" && <h6 className={"text-center"}>
                            Séléctionner un compte pour accepter votre devis
                        </h6>}
                    </> : <div className="card-header">
                        <h6 className={'text-center'}>
                            Séléctionner un compte
                        </h6>
                    </div>}
                </div>
                {loading && <div className={'vol-12 mb-3 text-center'}>
                    <Loader size={"sm"} />
                </div>}
                {error && <ErrorCard error={error} />}
                <ul className={"list-group list-group-flush"}>
                    {payload.map(r => r.type === 'corporate' ? <CorporateAccountItem account={r} /> : <CustomerAccountItem account={r} />)}
                </ul>
                {payload.length === 0 && <div className={'col-12 mb-3 text-muted text-center'}>Aucun résultat</div>}
                <div className="row align-items-center">
                    <div className="col"><hr/></div>
                    <div className="col-auto">
                        <span className="fs-5">Ou</span>
                    </div>
                    <div className="col"><hr/></div>
                </div>
                <Link to={`/account/add${encodeQueryData(urlSearchParamsToObject(query))}`} className={'btn btn-primary w-100'}>
                    Ajouter un compte <i className={'bi bi-arrow-right'}></i>
                </Link>
            </div>
        </div>
    </>)
}

export default AccountList;