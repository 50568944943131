import React, {useEffect, useState} from "react";
import * as api from '../../../../../../../adapters/corporate/supplier/shipping/address';
import * as shippingApi from '../../../../../../../adapters/corporate/supplier/shipping';
import SearchInput from "../../../../../../../utils/searchInput";
import Loader from "../../../../../../../utils/loader";
import * as models from "../../../../../../../models/shipping";

const SupplierShippingAddressSelect = ({shipping, setShipping}: {shipping: models.Shipping, setShipping: React.Dispatch<any>}) => {

    const [payload, setPayload] = useState<{ count: number, rows: models.ShippingAddresses }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number}>({query: "", offset: 0})

    useEffect(() => {
        setLoading(true)
        api.list({offset: fetchParams.offset, query: fetchParams.query})
            .then(resp => setPayload(resp.data))
            .then(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    function handleChange(checked: boolean, address: models.ShippingAddress)
    {
        if (checked){
            shippingApi.linkAddress(shipping.id, address.id)
                .then(() => setShipping((p: models.Shipping) => ({...p, addresses: [...p.addresses, address]})))
        }else{
            shippingApi.unlinkAddress(shipping.id, address.id)
                .then(() => setShipping((p: models.Shipping) => ({...p, addresses: [...p.addresses.filter(a => a.id !== address.id)]})))
        }
    }

    return <div className="modal fade" id="shippingAddressSelectModal" tabIndex={-1} aria-labelledby="shippingAddressSelectModalLabel"
                aria-hidden="true">
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="shippingAddressSelectModalLabel">
                        Gérer les lieux de traitements de {shipping.name}
                    </h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="col-12 mb-3 flex-grow-0">
                        <div className="row align-items-center">
                            <div className="col">
                                <SearchInput placeholder={"Rechercher un lieu de traitement..."} onChange={handleQueryChange} size={"default"} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-3 text-center">
                        {loading ? <Loader /> : <>{payload.count} résultats</>}
                    </div>
                    <div className="col-md-12 flex-grow-1">
                        {!loading && <div className={'table-responsive'}>
                            <table className={'table'}>
                                <thead>
                                <tr>
                                    <th>

                                    </th>
                                    <th>
                                        Pays
                                    </th>
                                    <th>
                                        Ville
                                    </th>
                                    <td>
                                        Code postal
                                    </td>
                                    <td>
                                        Adresse
                                    </td>
                                </tr>
                                </thead>
                                <tbody>
                                {payload.rows.map(a => <tr>
                                    <td>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" defaultChecked={shipping.addresses.findIndex(_a => _a.id === a.id) !== -1} onChange={e => handleChange(e.target.checked, a)} type="checkbox" role="switch" key={a.id} />
                                        </div>
                                    </td>
                                    <td>
                                        {a.country.flag && <img style={{height: 18, width: "auto"}} src={a.country.flag} alt={a.country.name}/>} {a.country.name}
                                    </td>
                                    <td>
                                        {a.city}
                                    </td>
                                    <td>
                                        {a.postalCode}
                                    </td>
                                    <td>
                                        {a.line1}
                                    </td>
                                </tr>)}
                                </tbody>
                            </table>
                        </div>}
                        {payload.count > 20 && <div className="col-12 py-3 text-center">
                            <ul className="pagination mx-auto">
                                <li className="page-item">
                                    <button disabled={!fetchParams.offset} className="page-link page-link-arrow"
                                            onClick={() => setFetchParams(prev => ({...prev, offset: prev.offset - 1}))}>
                                        <i className="bi bi-caret-left"></i>
                                    </button>
                                </li>
                                {Array.from(Array(Math.ceil(payload.count / 20)).keys()).map(i => <li className={"page-item " + (i === fetchParams.offset ? " active" : '')}>
                                    <button className="page-link" onClick={() => setFetchParams(prev => ({...prev, offset: i}))}>{i + 1}</button>
                                </li>)}
                                <li className="page-item">
                                    <button disabled={fetchParams.offset + 1 === (payload.count / 20)}
                                            className="page-link page-link-arrow"
                                            onClick={() => setFetchParams(prev => ({...prev, offset: prev.offset + 1}))}>
                                        <i className="bi bi-caret-right"></i>
                                    </button>
                                </li>
                            </ul>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default SupplierShippingAddressSelect;