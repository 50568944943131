import React, {useEffect, useState} from "react";
import {Categories, Category} from "../../../../../models/category";
import * as api from "../../../../../adapters/category";
import * as productApi from "../../../../../adapters/corporate/supplier/product";
import Pagination from "../../../../../utils/pagination";
import SearchInput from "../../../../../utils/searchInput";
import Loader from "../../../../../utils/loader";
import {Product} from "../../../../../models/product";

const SupplierProductCategory = ({product, setProduct}: {product: Product, setProduct: React.Dispatch<any>}) => {

    const [payload, setPayload] = useState<{count: number, rows: Categories}>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{query: string, offset: number, parent: number|null}>({query: "", offset: 0, parent: product.category?.parent?.id || null});
    const [parent, setParent] = useState<Category|null>(product.category?.parent || null)

    useEffect(() => {
        setLoading(true);
        api.list(fetchParams)
            .then(r => setPayload(r.data))
            .then(() => setLoading(false))
    }, [fetchParams])

    function handleCategoryChange(cat: number|null){
        if (cat !== null){
            api.show(cat)
                .then(r => {
                    setParent(r.data)
                    setFetchParams({query: "", offset: 0, parent: r.data.id})
                })
        }else{
            setParent(null)
            setFetchParams({query: "", offset: 0, parent: null})
        }
    }

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams(p => ({...p, query: e.target.value})), 500)
    }

    function handleChange(cat: Category){
        productApi.update(product.id, {category_id: cat.id})
            .then(r => setProduct((prev: any) => ({...prev, category: cat})))
    }

    return <div className="modal fade" id="productCategoryModal" tabIndex={-1} aria-labelledby="productCategoryModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <div className="col-12 mb-3 flex-grow-0">
                        <div className="row align-items-center">
                            {!!parent && <div className="col-auto">
                                <button className="btn btn-sm btn-light" onClick={() => handleCategoryChange(parent?.parentId || null)}>
                                    <i className={'bi bi-arrow-left'}></i> {parent.name.slice(0, 12)}{parent.name.length > 12 && <>...</>}
                                </button>
                            </div>}
                            <div className="col">
                                <SearchInput placeholder={"Rechercher une catégorie..."} onChange={handleQueryChange} size={"default"} />
                            </div>
                            <div className="col-auto">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="col-12 mb-3">
                        {loading ? <Loader /> : <>{payload.count} résultats</>}
                    </div>
                    <ul className="list-group">
                        {payload.rows.map(r => <li className={'list-group-item d-flex align-items-center'} key={r.id}>
                            <div className="form-check form-switch flex-grow-1">
                                <input onChange={() => handleChange(r)} checked={r.id === product.category?.id}
                                       className="form-check-input" type="checkbox" role="switch" id={`cat_${r.id}`}/>
                                <label className="form-check-label" htmlFor={`cat_${r.id}`}>
                                    {r.name}
                                </label>
                            </div>
                            {r.childrenCount > 0 && <div className="flex-grow-0">
                                <button className="btn btn-sm text-decoration-underline"
                                        onClick={() => handleCategoryChange(r.id)}>
                                    {r.childrenCount} sous catégories <i className={'bi bi-arrow-right'}></i>
                                </button>
                            </div>}
                        </li>)}
                    </ul>
                </div>
                <div className="modal-footer justify-content-center">
                    <Pagination key={parent?.id} count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
                </div>
            </div>
        </div>
    </div>
}

export default SupplierProductCategory;