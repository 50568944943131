import React, {useEffect, useState} from "react";
import {Products} from "../../../../../../../models/product";
import * as api from "../../../../../../../adapters/corporate/provider/product";
import {Organization} from "../../../../../../../models/organization";
import Loader from "../../../../../../../utils/loader";
import Pagination from "../../../../../../../utils/pagination";
import ErrorCard from "../../../../../../error";
import CommonProductListItem from "../../../../product/listItem";

const OrganizationProductList: React.FC<{organization: Organization}> = ({organization}) => {

    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState(null)
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string}>({offset: 0, query: ""})
    const [products, setProducts] = useState<{ count: number, rows: Products }>({count: 0, rows: []});

    useEffect(() => {
        setLoading(true)
        setError(null)
        api.list({offset: fetchParams.offset, query: fetchParams.query, type: 'public', organization_ids: [organization.id]})
            .then(resp => setProducts(resp.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    return <>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{products.count} résultats</>}
        </div>
        {error && <ErrorCard error={error} />}
        {!loading && <div className="row row-cols-2 row-cols-lg-3">
            {products.rows.map(p => <CommonProductListItem item={p} />)}
        </div>}
        <Pagination count={products.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
    </>
}

export default OrganizationProductList;