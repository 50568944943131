import React, { useState, useEffect, useRef } from "react";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import * as api from "../../../adapters/auth"
import authSlice from "../../../reducers/auth";
import {useFormik} from "formik";
import * as Yup from "yup";
import FormError from "../../../utils/formError";
import ErrorCard from "../../error";

const Activate = () => {
    const state = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();

    const [error, setError] = useState(null)

    const formik = useFormik({
        initialValues: {
            activationCode: ''
        },
        validationSchema: Yup.object().shape({
            activationCode: Yup.string().required()
        }),
        onSubmit: (values, {setSubmitting}) => {
            setError(null)
            api.activate(values.activationCode)
                .then(() => dispatch(authSlice.actions.activateSuccess()))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    })

    function refreshActivationCode(){
        api.refreshActivationCode()
            .then(r => console.log(r))
    }

    return (
        <>
            <div className={"container mx-auto h-100"}>
                <div className="row h-100 align-items-center justify-content-center">
                    <div className="col-md-6">
                        <div className="row justify-content-center">
                            <div className="col-auto d-flex align-items-center mb-5">
                                <img src={process.env.PUBLIC_URL + "/img/logo.png"} className={"logo me-2"} alt="" />
                            </div>
                        </div>
                        <div className="card border border-1 rounded mb-3">
                            <div className="card-body text-center">
                                <h3>Activer votre compte</h3>
                                <p>
                                    Un email contenant un code de confirmation a été envoyer à l'email <span className="fw-semibold">{state.user!.email}</span>
                                </p>
                                <p>Veuillez saisir le code reçu ci-dessous pour activer votre compte.</p>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="col-12 mb-3">
                                        <div className="form-floating">
                                            <input name={"activationCode"} id={"activationCode"} onChange={formik.handleChange} onBlur={formik.handleBlur} type="text" className="form-control form-control-lg shadow-sm"/>
                                            <label htmlFor="activationCode">Code d'activation</label>
                                        </div>
                                        <FormError form={formik} field={'activationCode'} />
                                    </div>
                                    {error && <ErrorCard error={error} />}
                                    <button className={'btn btn-primary w-100'} disabled={formik.isSubmitting} type={'submit'}>
                                        <i className={"bi bi-check"}></i> Valider
                                    </button>
                                </form>
                                <button onClick={() => refreshActivationCode()} className={'btn btn-sm text-decoration-underline mx-auto'}>
                                    Renvoyer le code d'activation
                                </button>
                                <button onClick={() => dispatch(authSlice.actions.logout())} className={'btn btn-sm text-decoration-underline mx-auto'}>
                                    Déconnexion
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Activate;




