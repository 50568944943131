import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../adapters/auth";
import Loader from "../../../utils/loader";
import FormError from "../../../utils/formError";
import {useQuery} from "../../../utils/useQuery";
import ErrorCard from "../../error";
import {forgotPassword} from "../../../adapters/auth";

const ForgotPassword = () => {

    const [error, setError] = useState<any>(null)
    const query = useQuery();
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false)

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email().required(),
        }),
        onSubmit: (values, formikHelpers) => {
            setLoading(true)
            api.forgotPassword(values)
                .then(resp => setSent(true))
                .catch(error => setError(error))
                .finally(() => setLoading(false))
        }
    })

    return <div className={'container mx-auto h-100'}>
        <div className="row h-100 align-items-center justify-content-center">
            <div className="col-md-6">
                <div className="row justify-content-center">
                    <div className="col-auto d-flex align-items-center mb-5">
                        <img src={process.env.PUBLIC_URL + '/img/logo.png'} className={'logo me-2'} alt=""/>
                    </div>
                </div>
                <div className="card border border-1 rounded mb-3">
                    <div className="card-body">
                        <h6>Mot de passe oublié.</h6>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="col-12 mb-3">
                                <div className="form-floating">
                                    <input disabled={loading} defaultValue={query.get("email") || undefined} name={"email"} id={"email"} onBlur={formik.handleBlur} onChange={formik.handleChange} type="email" className="form-control" />
                                    <label htmlFor="email">
                                        Saisissez votre email
                                    </label>
                                </div>
                                <FormError form={formik} field={'email'} />
                            </div>
                            <button className={`btn w-100 mb-3 ${sent ? "btn-success" : "btn-dark"}`} disabled={loading || sent}>
                                {loading ? <Loader size={"sm"} /> : (sent ? <>Email envoyé <i className={'bi bi-check-circle'}></i></> : <>Valider <i className={'bi bi-arrow-right'}></i></>)}
                            </button>
                            {error && <ErrorCard error={error} />}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ForgotPassword;