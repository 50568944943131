import {Quote} from "../../../models/quote";
import React, {useState} from "react";
import QuoteSignatureSign from "./sign";
import QuoteSignatureVerify from "./verify";

const QuoteSignature = ({quote, setQuote}: {quote: Quote, setQuote: React.Dispatch<any>}) => {

    const [verificationEmail, setVerificationEmail] = useState<string|null>(null)

    if (quote.signature){
        return <></>
    }

    if (!verificationEmail){
        return <QuoteSignatureSign quote={quote} handleSign={(email) => setVerificationEmail(email)} />
    }


    return <QuoteSignatureVerify verificationEmail={verificationEmail} quote={quote} setQuote={setQuote} />
}

export default QuoteSignature;