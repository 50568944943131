import {Project} from "../../../../../models/project";
import * as api from "../../../../../adapters/corporate/provider/quote"
import React, {useEffect, useState} from "react";
import {Quotes} from "../../../../../models/quote";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";
import ProviderProjectQuoteListItem from "./item";

const ProviderProjectQuoteList = ({project}: {project: Project}) => {

    const [loading, setLoading] = useState(true);
    const [fetchParams, setFetchParams] = useState({offset: 0, query: "", status: "All"});
    const [payload, setPayload] = useState<{count: number, rows: Quotes}>({count: 0, rows: []});
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        api.list({...fetchParams, project_id: project?.id})
            .then(r => setPayload(r.data))
            .catch(error => setError(error))
            .finally(() => setLoading(false))
    }, [project?.id, fetchParams])

    return <>
        <ul className={"list-group list-group-flush"}>
            {loading ? <li className={'list-group-item'}><Loader /> chargement en cours...</li> : <>
                {payload.rows.length ? payload.rows.map(quote => <ProviderProjectQuoteListItem project={project} quote={quote} />) : <tr><td colSpan={3} className={'list-group-item text-muted'}><i className={'bi bi-search'}></i> Aucun résultat</td></tr>}</>}
        </ul>
        <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
    </>
}

export default ProviderProjectQuoteList;