import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import * as api from "../../../../../adapters/corporate/common/onboarding";
import React, {useState} from "react";
import authSlice from "../../../../../reducers/auth";
import {useNavigate} from "react-router-dom";
import ErrorCard from "../../../../error";

const OnBoardingType = () => {

    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState(null)
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    function handleClick(value: 'provider'|'supplier') {
        setSubmitting(true)
        api.type({type: value})
            .then(r => dispatch(authSlice.actions.editAccountOrganization(r.data)))
            .then(() => navigate('/onboarding/account'))
            .catch(e => setError(e))
            .finally(() => setSubmitting(false))
    }

    return <>
        <h3 className={"mb-8"}>
            Quel type de compte souhaitez vous créer ?
        </h3>
        {error && <ErrorCard error={error} />}
        <div className="row">
            <div className="col-12 mb-3">
                <div className="card border border-1 shadow-hover" onClick={() => handleClick('supplier')}>
                    <div className="card-body">
                        <h6>Compte fournisseur</h6>
                        <p className={"mb-0 form-text"}>
                            Le compte fournisseur vous permet de connecter avec des apporteur d'affaire, agent commercial, revendeur dans le but de commercialiser vos produits contre commission.
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="card border border-1 shadow-hover" onClick={() => handleClick('provider')}>
                    <div className="card-body">
                        <h6>Compte vendeur</h6>
                        <p className={"mb-0 form-text"}>
                            Le compte vendeur vous permets d'agir en tant qu'apporteur d'affaire, agent commercial, revendeur pour le compte d'entreprises.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </>

}

export default OnBoardingType;