import {get, post} from "../xhr";

export function proceed(uuid?: string)
{
    return get(`/quote/${uuid}`);
}

export function show(uuid?: string)
{
    return get(`/quote/${uuid}`);
}

export function summary(uuid: string)
{
    return get(`/quote/${uuid}/summary`);
}

export function rows(uuid: string)
{
    return get(`/quote/${uuid}/rows`);
}

export function signature(uuid: string, requestData: {signature: string}){
    return post(`/quote/${uuid}/signature`, requestData)
}

export function verifySignature(uuid: string, requestData: {verificationCode: number}){
    return post(`/quote/${uuid}/verify-signature`, requestData)
}

export function paymentMethod(uuid: string, paymentMethodId: number){
    return get(`/quote/${uuid}/payment-method/${paymentMethodId}`);
}

export function shipping(uuid: string, addressId: number)
{
    return get(`/quote/${uuid}/shipping/${addressId}`);
}

export function billing(uuid: string, addressId: number)
{
    return get(`/quote/${uuid}/billing/${addressId}`);
}