import React, {useEffect, useState} from "react";
import * as api from "../../../../../../adapters/corporate/common/account";
import * as chatApi from "../../../../../../adapters/corporate/common/chat";
import * as models from "../../../../../../models/account";
import ErrorCard from "../../../../../error";
import Loader from "../../../../../../utils/loader";
import {getLogoUrl} from "../../../../../../utils/image";
import {useNavigate} from "react-router-dom";

const ChatSearchAccount = ({query, setShowSearch}: {query: string, setShowSearch: React.Dispatch<any>}) => {

    const [loading, setLoading] = useState(false)
    const [payload, setPayload] = useState<{count: number, rows: models.CorporateAccounts}>({count: 0, rows: []})
    const [error, setError] = useState(null)
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string}>({offset: 0, query: ""});
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(r => setPayload(prev => ({count: r.data.count, rows: [...prev.rows, ...r.data.rows]})))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams.offset, fetchParams.query])

    useEffect(() => {
        handleQueryChange(query)
    }, [query])

    let timer: NodeJS.Timer;
    function handleQueryChange(_query: string){
        clearTimeout(timer)
        timer = setTimeout(() => {

            if (query !== _query){
                setPayload({count: 0, rows: []})
            }

            setFetchParams({query: _query, offset: 0})
        }, 1000)
    }

    async function handleClick(account: models.Account){
        try{
            const {data} = await chatApi.add({type: "private", account_id: account.id})
            navigate(`/corporate/chat/${data.id}`)
        }catch (e) {
            console.log(e)
        }finally {
            setShowSearch(false)
        }
    }

    return <div className="col-12 px-0">
        <p className={'text-muted'}>Contacts {loading && <Loader size={"sm"} />}</p>
        {error && <ErrorCard error={error} />}
        {payload.rows.length && <>
            <ul className={'list-group list-group-flush'}>
                {payload.rows.map(account => <li className={'list-group-item list-group-item-action d-flex align-items-center'} onClick={() => handleClick(account)}>
                    <div className="avatar avatar-lg flex-grow-0">
                        <img src={getLogoUrl(account.organization)} alt={account.organization.name} className="avatar-img rounded-circle" />
                    </div>
                    <div className="flex-grow-1">
                        <p className="mb-1">
                            {account.firstname} {account.lastname}
                        </p>
                        <span className="form-text">
                            {account.organization.name}
                        </span>
                    </div>
                </li>)}
                {payload.rows.length < payload.count && <li className={"list-group-item"}>
                    <span className={"text-decoration-underline cursor-pointer"} onClick={() => setFetchParams(prev => ({...prev, offset: prev.offset + 1}))}>Charger plus de conversations</span>
                </li>}
            </ul>
        </>}
    </div>
}

export default ChatSearchAccount;