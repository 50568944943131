export function urlSearchParamsToObject(urlSearchParams: URLSearchParams): { [key: string]: string | string[] } {
    const result: { [key: string]: string | string[] } = {};

    Array.from(urlSearchParams.entries()).forEach(([key, value]) => {
        // Check if the key already exists
        if (result.hasOwnProperty(key)) {
            // If the key already exists and it's not an array, convert it to an array
            if (!Array.isArray(result[key])) {
                result[key] = [result[key] as string];
            }
            // Add the new value to the array
            (result[key] as string[]).push(value);
        } else {
            // If the key does not exist, simply add the key-value pair
            result[key] = value;
        }
    });

    return result;
}