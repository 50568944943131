import React from "react";
import {Organization} from "../../../../../../../models/organization";
import {services} from "../../../../../../../utils/service";

const CommonOrganizationService = ({organization}: {organization: Organization}) => {

    const specialisations_ids = organization.specialisations.map(s => s.id);

    return <div className="card border border-1">
        <div className="card-body">
            <h6>Services proposés</h6>
            <ul className={'list-unstyled ms-3'}>
                {services.filter(s => s.items.filter(i => specialisations_ids.includes(i.id)).length > 0).map(gs => <li>
                    <p className={"fw-bold my-3"}>{gs.title}</p>
                    <ul className={'list-unstyled ms-3'}>
                        {gs.items.filter(gsi => specialisations_ids.includes(gsi.id)).map(fs => <li className={'mb-3'}>
                            <span className={'fw-semibold'}>{fs.title}</span>
                            <span className="clearfix"></span>
                            <span className="form-text">{fs.description}</span>
                        </li>)}
                    </ul>
                </li>)}
            </ul>
        </div>
    </div>
}

export default CommonOrganizationService;