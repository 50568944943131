import {get, post} from "../../../../xhr";
import encodeQueryData from "../../../../../utils/encodeQueryData";

export function add(affiliation: number, requestData: any){
    return post(`corporate/supplier/affiliation/${affiliation}/click-rule`, requestData)
}
export function list(affiliation: number, requestData: { offset: number }){
    return get(`corporate/supplier/affiliation/${affiliation}/click-rule${encodeQueryData(requestData)}`)
}
export function activate(affiliation: number, ruleId: number){
    return get(`corporate/supplier/affiliation/${affiliation}/click-rule/${ruleId}/activate`)
}