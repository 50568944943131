
const CommonTouchStatus = ({status}: {status: string}) => {

    switch (status)
    {
        case "Pending":
            return <span className={'badge bg-warning'}>En attente</span>
        case "Accepted":
            return <span className={'badge bg-info'}>Accepté</span>
        case "Refused":
            return <span className={'badge bg-danger'}>Refusé</span>
        case "Win":
            return <span className={'badge bg-success'}>Gagné</span>
        case "Lost":
            return <span className={'badge bg-danger'}>Perdu</span>
        default:
            return <span><i className={'bi bi-exclamation-circle text-danger'}></i> Invalid status</span>
    }
}

export default CommonTouchStatus;