import encodeQueryData from "../../../../utils/encodeQueryData";
import {post, get} from "../../../xhr";

interface Interface {
    address_id?: number,
    status?: "Accepted"|"Refused",
    refusedReason?: string,
    replacementStatus?: "Pending"|"Processing"|"Shipped"|"Delivered",
    returnStatus?: "Pending"|"Received",
}

export function list(requestData: { query?: string, offset: number, count?: number, customer_id?: number, status?: "Pending"|'Accepted'|"Refused"}){
    return get(`corporate/supplier/returns${encodeQueryData(requestData)}`)
}

export function show(returnId: number){
    return get(`corporate/supplier/returns/${returnId}`)
}

export function update(returnId: number, requestData: Interface){
    return post(`corporate/supplier/returns/${returnId}`, requestData);
}

