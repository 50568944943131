import React from "react";
import FeePaymentForm from "./form";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {Fees} from "../../../../../../models/fee";
import {Currency} from "../../../../../../models/currency";
import {PaymentMethod} from "../../../../../../models/paymentMethod";

const stripePromise = loadStripe("pk_live_51OHqm6H46Kmwvu6gtsFGdaMUw4mgkXMquclVtkENiHwv4DN4s00c3i7pHXP5db9Vo6SS0omYqRIvDFiHC7oxtWPx00GupdBdOQ");

const FeePayment = ({fees, currency, paymentMethod, handlePaymentSucceed}: {fees?: Fees, currency: Currency, paymentMethod?: PaymentMethod, handlePaymentSucceed: (fees: Fees) => void}) => {

    return <Elements options={{appearance: {theme: 'stripe'}}} stripe={stripePromise}>
        <FeePaymentForm fees={fees} paymentMethod={paymentMethod} currency={currency} handlePaymentSucceed={handlePaymentSucceed} />
    </Elements>
}

export default FeePayment