import * as models from "../../../../../../../models/shipping";
import React, {useEffect, useState} from "react";
import SupplierShippingAreaAdd from "../add";
import SupplierShippingAreaEdit from "../edit";
import SearchInput from "../../../../../../../utils/searchInput";
import Loader from "../../../../../../../utils/loader";
import Pagination from "../../../../../../../utils/pagination";
import * as api from "../../../../../../../adapters/corporate/supplier/shipping/area";
import {Link} from "react-router-dom";

const SupplierShippingAreaList = ({shipping}: {shipping: models.Shipping}) => {

    const [payload, setPayload] = useState<{count: number, rows: models.ShippingAreas}>({count: 0, rows: []})
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number}>({query: "", offset: 0})

    useEffect(() => {
        setLoading(true)
        api.list({...fetchParams, shipping_id: shipping.id})
            .then(resp => setPayload(resp.data))
            .then(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={'row'}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher une zone d'expédition..."} onChange={handleQueryChange} size={"default"} />
                </div>
                <div className="col-auto">
                    <button data-bs-toggle={"offcanvas"} data-bs-target={"#offcanvasShippingAreaAdd"} className={'btn btn-light'}>
                        <i className={'bi bi-plus-circle'}></i> Ajouter une zone d'expédition
                    </button>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        <div className="col-md-12 flex-grow-1">
            <div className="row">
                {payload.rows.map((area, index) => <div key={index} className={'col-12 mb-3'}>
                    <div className="card border border-1">
                        <div className="card-header">
                            <Link to={`/corporate/supplier/shipping/area/${area.id}`}>
                                {area.name}
                            </Link>
                        </div>
                        <div className="card-body">
                            {area.countries.map(c => <span className={'px-2 py-1 bg-light me-2'}>
                                <img style={{height: 18, width: "auto"}} src={c.flag} alt={c.name}/> <small>{c.name}</small>
                            </span>)}
                        </div>
                    </div>
                </div>)}
            </div>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        </div>
        <SupplierShippingAreaAdd shipping={shipping} handleSuccess={(area) => setPayload(prev => ({count: prev.count + 1, rows: [area, ...prev.rows]}))} />
    </div>
}

export default SupplierShippingAreaList;