import React, {useEffect, useState} from "react";
import {Affiliations} from "../../../../../../../models/affiliation";
import * as api from "../../../../../../../adapters/corporate/provider/affiliation";
import {Organization} from "../../../../../../../models/organization";
import Loader from "../../../../../../../utils/loader";
import Pagination from "../../../../../../../utils/pagination";
import ErrorCard from "../../../../../../error";
import OrganizationAffiliationShow from "./show";

const OrganizationAffiliationList: React.FC<{organization: Organization}> = ({organization}) => {

    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState(null)
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string}>({offset: 0, query: ""})
    const [affiliations, setAffiliations] = useState<{ count: number, rows: Affiliations }>({count: 0, rows: []});

    useEffect(() => {
        setLoading(true)
        setError(null)
        api.list({offset: fetchParams.offset, query: fetchParams.query, type: 'public', organization_ids: [organization.id]})
            .then(resp => setAffiliations(resp.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    return <>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{affiliations.count} résultats</>}
        </div>
        {error && <ErrorCard error={error} />}
        {!loading && <div className="row row-cols-2 row-cols-lg-3">
            {affiliations.rows.map(p => <OrganizationAffiliationShow item={p} />)}
        </div>}
        <Pagination count={affiliations.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
    </>
}

export default OrganizationAffiliationList;