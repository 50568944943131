import encodeQueryData from "../../../../utils/encodeQueryData";
import {MULTIPART_FORM_DATA, post, get} from "../../../xhr";

export function personalized(requestData: { offset: number}){
    return post(`/corporate/common/post/personalized`, requestData);
}

export function partners(requestData: { offset: number}){
    return post(`/corporate/common/post/partners`, requestData);
}

export function list(requestData: { offset: number, type: "partners"|"personalized"|"organization", organization_id?: number}){
    return get(`/corporate/common/post${encodeQueryData(requestData)}`);
}

export function feed(requestData: {timestamp: string}){
    return get(`/corporate/common/post/feed${encodeQueryData(requestData)}`);
}

export function add(requestData: FormData){
    return post(`/corporate/common/post`, requestData, {headers: [MULTIPART_FORM_DATA]});
}