import {useFormik, FieldArray} from "formik";
import * as Yup from "yup";
import FormError from "../../../../../utils/formError";
import {Assignment} from "../../../../../models/assignment";
import {getCurrency} from "../../../../../utils/currencies";
import {Proposal} from "../../../../../models/proposal";
import * as api from "../../../../../adapters/corporate/provider/proposal";
import {useState} from "react";
import ErrorCard from "../../../../error";

interface RowInterface {
    name: string,
    description: string,
    quantity: number,
    amount: number,
    unit: string,
    taxRate: number
}

interface ValuesInterface {
    status: string,
    currency_id: number;
    rows: RowInterface[]
}

const ProviderProposalAdd = ({assignment, onSuccess}: { assignment: Assignment, onSuccess: (proposal: Proposal) => void }) => {

    const [error, setError] = useState(null)

    const [initialValues, setInitialValues] = useState<ValuesInterface>({
        status: "Pending",
        currency_id: getCurrency('EUR').id,
        rows: [
            {
                name: '',
                description: '',
                quantity: 1,
                amount: 0,
                unit: '',
                taxRate: 0
            }
        ]
    });

    const formik = useFormik({
        initialValues:  initialValues,
        validationSchema: Yup.object().shape({
            status: Yup.string().required(),
            message: Yup.string().nullable(),
            rows: Yup.array().of(Yup.object().shape({
                name: Yup.string().required(),
                description: Yup.string().nullable(),
                quantity: Yup.number().required(),
                amount: Yup.number().required(),
                unit: Yup.string().required(),
                taxRate: Yup.number().nullable()
            }))
        }),
        onSubmit: (values, {setSubmitting}) => {
            setError(null)
            api.add({...values, assignment_id: assignment.id})
                .then(r => onSuccess(r.data))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    });

    function addRow(){
        const _values = {...formik.values};

        _values.rows.push({
            name: '',
            description: '',
            quantity: 1,
            amount: 0,
            unit: '',
            taxRate: 0
        })

        setInitialValues(_values);
    }

    function removeRow(index: number){

        const rows = {...formik.values.rows};

        rows.splice(index, 1);

        setInitialValues({...formik.values, rows: rows})
    }

    return <form onSubmit={formik.handleSubmit}>
        {formik.values.rows.map((row: RowInterface, index) => (
            <div className={'col-12 mb-3'}>
                <div className="card border border-1">
                    <div className="card-body">
                        <div key={index} className="row align-items-center">
                            <div className="col-md-12 mb-3 form-floating">
                                <input
                                    type="text"
                                    name={`rows[${index}].name`}
                                    className={`form-control`}
                                    placeholder="Name"
                                    value={row.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <label>Nom du Service</label>
                                <FormError form={formik} field={`rows[${index}].name`} />
                            </div>
                            <div className="col-md-12 mb-3 form-floating">
                                <input
                                    type="text"
                                    name={`rows[${index}].description`}
                                    className="form-control"
                                    placeholder="Description"
                                    value={row.description || ""}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <label>Description</label>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className="col-md form-floating">
                                <input
                                    type="number"
                                    name={`rows[${index}].amount`}
                                    className={`form-control`}
                                    placeholder="Amount"
                                    value={row.amount}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <label>Montant unité HT</label>
                                <FormError form={formik} field={`rows[${index}].amount`} />
                            </div>

                            <div className="col-md form-floating">
                                <input
                                    type="number"
                                    name={`rows[${index}].quantity`}
                                    className={`form-control`}
                                    placeholder="Quantity"
                                    value={row.quantity}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <label>Quantité</label>
                                <FormError form={formik} field={`rows[${index}].quantity`} />
                            </div>

                            <div className="col-md form-floating">
                                <input
                                    type="text"
                                    name={`rows[${index}].unit`}
                                    className={`form-control`}
                                    placeholder="Unit"
                                    value={row.unit}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <label>Unité de mesure</label>
                                <FormError form={formik} field={`rows[${index}].unit`} />
                            </div>
                            <div className="col-md form-floating">
                                <input
                                    type="number"
                                    name={`rows[${index}].taxRate`}
                                    className="form-control"
                                    placeholder="Taux de taxe"
                                    value={row.taxRate || ""}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <label>Taux de taxe</label>
                                <FormError form={formik} field={`rows[${index}].taxRate`} />
                            </div>
                            <div className="col-md-auto">
                                <button className="btn btn-dark rounded" onClick={() => removeRow(index)}>
                                    <i className={'bi bi-x text-danger'}></i> Retirer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))}
        <button className={'btn btn-light rounded'} type={'button'} onClick={() => addRow()}>
           <i className={'bi bi-plus'}></i> Ajouter une ligne
        </button>

        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-body">
                    <h6>Récapitulatif du devis</h6>
                    <table className={'table table-borderless'}>
                        <tbody>
                        <tr>
                            <th>Montant HT</th>
                            <td>{formik.values.rows.reduce((previousValue, currentValue) => previousValue + (currentValue.amount * currentValue.quantity) , 0)}{getCurrency('EUR').symbol}</td>
                        </tr>
                        <tr>
                            <th>Montant taxes</th>
                            <td>{formik.values.rows.reduce((previousValue, currentValue) => previousValue + (((currentValue.amount / 100) * currentValue.taxRate) * currentValue.quantity) , 0)}{getCurrency('EUR').symbol}</td>
                        </tr>
                        <tr>
                            <th>Montant TTC</th>
                            <td>{formik.values.rows.reduce((previousValue, currentValue) => previousValue + ((currentValue.amount + ((currentValue.amount / 100) * currentValue.taxRate)) * currentValue.quantity) , 0)}{getCurrency('EUR').symbol}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        {error && <div className={"col-12 mb-3"}>
            <ErrorCard error={error} />
        </div>}
        <div className="row">
            <div className="col-md-6 mb-3">
                <button disabled={formik.isSubmitting} type={"button"} onClick={() => {
                    formik.setFieldValue('status', 'Pending')
                    formik.submitForm();
                }} className="btn btn-light w-100">
                    <i className={'bi bi-check'}></i> Enregistrer
                </button>
            </div>
            <div className="col-md-6 mb-3">
                <button disabled={formik.isSubmitting} type={"button"} onClick={() => {
                    formik.setFieldValue('status', 'Published')
                    formik.submitForm();
                }} className="btn btn-primary w-100">
                    <i className={'bi bi-send'}></i> Enregistrer et publier
                </button>
            </div>
        </div>
    </form>
}

export default ProviderProposalAdd;