import {get, post} from "../xhr";
import encodeQueryData from "../../utils/encodeQueryData";

export function show(token: string, requestData: {country_id?: number, currency_id?: number})
{
    return get(`/bundle/${token}${encodeQueryData(requestData)}`);
}

export function checkout(token: string, price: number, requestData: {country_id: number, currency_id: number})
{
    return post(`/bundle/${token}/checkout/${price}`, requestData);
}