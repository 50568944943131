import {getLogoUrl} from "../../../../../../utils/image";
import CommonConversionStatus from "../../../../common/conversion/status";
import React, {useState} from "react";
import {Conversion, Conversions} from "../../../../../../models/affiliation";
import convertToDecimal from "../../../../../../utils/currencyConverter";
import OffcanvasComponent from "../../../../../offcanvas";
import {getAttributionLabel} from "../../../../../../utils/affiliation";

const SupplierConversionListItem = ({status, handleSelect, selected, item}: {status: string, handleSelect: (conversion: Conversion) => void, selected: Conversions, item: Conversion}) => {

    const [showDetailModal, setShowDetailModal] = useState(false)

    return <>
        <tr>
            {['Pending', 'Accepted'].includes(status) && <td>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id={`conversion_${item.id}`}
                           onChange={() => handleSelect(item)} checked={selected.map(s => s.id).includes(item.id)}/>
                </div>
            </td>}
            <td>
                {item.affiliation.name}
            </td>
            <td>
                {item.rule.name}
            </td>
            <td>
                {item.priceData ? convertToDecimal(item.priceData.amount, item.priceData.currency) : <i className={"bi bi-x"}></i>}
            </td>
            <td>
                {item.priceData ? convertToDecimal(item.priceData.fee, item.priceData.currency) : <i className={"bi bi-x"}></i>}
            </td>
            <td>
                <div className="avatar-group">
                    {item.partnerships.map(partnership => <span className="avatar avatar-sm">
                        <img src={getLogoUrl(partnership.provider)} alt={partnership.provider.name} className="avatar-img rounded-circle" />
                    </span>)}
                </div>
            </td>
            <td>
                {new Date(item.createdAt).toLocaleDateString()} à {new Date(item.createdAt).toLocaleTimeString()}
            </td>
            <td>
                <CommonConversionStatus status={item.status} />
            </td>
            <td>
                <button className="btn btn-xxs byn-light" onClick={() => setShowDetailModal(true)}>
                    <i className={'bi bi-eye'}></i>
                </button>
                <OffcanvasComponent title={"Détails de la conversion"} show={showDetailModal} onHide={() => setShowDetailModal(false)}>
                    <table className={"table"}>
                        <tbody>
                        <tr>
                            <th>Statut</th>
                            <td><CommonConversionStatus status={item.status} /></td>
                        </tr>
                        <tr>
                            <th>Affiliation</th>
                            <td>{item.affiliation.name}</td>
                        </tr>
                        <tr>
                            <th>Règle</th>
                            <td>{item.rule.name}</td>
                        </tr>
                        {item.rule.type === "Percent" && <>
                            <tr>
                                <th>Montant</th>
                                <td>{convertToDecimal(item.priceData.amount, item.priceData.currency)}</td>
                            </tr>
                            <tr>
                                <th>Commission</th>
                                <td>{convertToDecimal(item.priceData.fee, item.priceData.currency)}</td>
                            </tr>
                        </>}
                        <tr>
                            <th>Id externe</th>
                            <td>{item.externalId || <i className={"bi bi-x"}></i>}</td>
                        </tr>
                        <tr>
                            <th>Client</th>
                            <td>{item.customer || <i className={"bi bi-x"}></i>}</td>
                        </tr>
                        <tr>
                            <th>Pays</th>
                            <td>{item.country || <i className={"bi bi-x"}></i>}</td>
                        </tr>
                        <tr>
                            <th>Ville</th>
                            <td>{item.city || <i className={"bi bi-x"}></i>}</td>
                        </tr>
                        <tr>
                            <th>Type de paiement</th>
                            <td>{item.paymentType || <i className={"bi bi-x"}></i>}</td>
                        </tr>
                        <tr>
                            <th>Date</th>
                            <td>{new Date(item.createdAt).toLocaleDateString()} à {new Date(item.createdAt).toLocaleTimeString()}</td>
                        </tr>
                        <tr>
                            <th>Produits</th>
                            <td>{item.products}</td>
                        </tr>
                        <tr>
                            <th>Code promo</th>
                            <td>{item.vouchers}</td>
                        </tr>
                        <tr>
                            <th>Date</th>
                            <td>{new Date(item.createdAt).toLocaleDateString()} à {new Date(item.createdAt).toLocaleTimeString()}</td>
                        </tr>
                        <tr>
                            <th>Partenaire(s)</th>
                            <td>
                                <ul className={'list-group list-group-flush'}>
                                    {item.partnerships.map(p => <li className={'list-group-item'}>
                                        <span className="avatar avatar-sm">
                                            <img src={getLogoUrl(p.provider)} alt={p.provider.name} className="avatar-img rounded-circle" />
                                        </span>
                                        <span>
                                            {p.provider.name}
                                        </span>
                                    </li>)}
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </OffcanvasComponent>
            </td>
        </tr>
        </>
}

export default SupplierConversionListItem;