import * as models from "../../../../../../../models/pack";
import {getCoverUrl} from "../../../../../../../utils/image";
import React from "react";

const ProviderPackItemListItem = ({pack, item}: {pack: models.Pack, item: models.PackItem}) => {

    return <div className={"col"}>
        <div className={"card border border-1 h-100"}>
            <img src={`${getCoverUrl(item.product)}`} alt="" className="card-img-top"/>
            <div className="card-body">
                <p className="fw-semibold mb-1">
                    {item.product.name}
                </p>
                <p className={"small mb-1"}>Quantité: {item.quantity}</p>
                <p className={"small mb-3"}>Modifiable: {item.editable ? "Oui" : "Non"}</p>
            </div>
        </div>
    </div>
}

export default ProviderPackItemListItem;