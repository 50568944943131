import * as models from "../../../../../../../models/shipping";
import * as api from "../../../../../../../adapters/corporate/supplier/shipping/area";
import React, {useEffect, useState} from "react";
import Loader from "../../../../../../../utils/loader";
import {Link, useParams} from "react-router-dom";
import SupplierShippingRateList from "../rate/list";
import SupplierShippingAreaEdit from "../edit";

const SupplierShippingAreaShow = () => {

    const params = useParams();
    const [loading, setLoading] = useState(true)
    const [area, setArea] = useState<models.ShippingArea>();

    useEffect(() => {
        setLoading(true)
        api.show(Number(params.id))
            .then(r => setArea(r.data))
            .then(() => setLoading(false))
    }, [params.id])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (!area){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                Area not found
            </div>
        </div>
    }

    return (<div className={'container-fluid'}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb fs-xs">
                <li className="breadcrumb-item">
                    <Link to={`/corporate/supplier/shipping`}>Profil d'expédition</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to={`/corporate/supplier/shipping/${area.shipping.id}/area`}>{area.shipping.name}</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to={`/corporate/supplier/shipping/${area.shipping.id}/area`}>Zones d'expédition</Link>
                </li>
                <li className="breadcrumb-item">
                    {area.name}
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h6>
                                {area.name}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-light" data-bs-toggle={"offcanvas"} data-bs-target={"#offcanvasShippingAreaEdit"}>
                                <i className={'bi bi-pencil'}></i> Mettre à jour
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {area.countries.map(c => <span className={'px-2 py-1 bg-light me-2'}>
                        <img style={{height: 18, width: "auto"}} src={c.flag} alt={c.name}/> <small>{c.name}</small>
                    </span>)}
                </div>
            </div>
        </div>
        <SupplierShippingRateList area={area} />
        <SupplierShippingAreaEdit handleSuccess={area => setArea(prev => ({...prev, ...area}))} area={area} key={area.id} />
    </div>)
}

export default SupplierShippingAreaShow;