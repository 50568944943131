import {get, post} from "../xhr";

export function show(uuid: string)
{
    return get(`/checkout/${uuid}`);
}

export function proceed(uuid: string)
{
    return get(`/checkout/${uuid}/proceed`);
}

export function confirm(uuid: string)
{
    return get(`/checkout/${uuid}/confirm`);
}

export function shipping(uuid: string, addressId: number)
{
    return get(`/checkout/${uuid}/shipping/${addressId}`);
}

export function billing(uuid: string, addressId: number)
{
    return get(`/checkout/${uuid}/billing/${addressId}`);
}

export function confirmAddress(uuid: string)
{
    return get(`/checkout/${uuid}/confirm-address`);
}

export function delivery(uuid: string)
{
    return get(`/checkout/${uuid}/delivery`);
}

export function deliveryOption(uuid: string, option: number)
{
    return get(`/checkout/${uuid}/delivery/${option}`);
}

export function customer(uuid: string, requestData: any)
{
    return post(`/checkout/${uuid}/customer`, requestData);
}

export function payment(uuid: string)
{
    return get(`/checkout/${uuid}/payment`);
}

export function confirmPayment(uuid: string)
{
    return get(`/checkout/${uuid}/confirm-payment`);
}

export function termsOfServiceAcceptance(uuid: string, requestData: {termsOfServiceAcceptance: boolean})
{
    return post(`/checkout/${uuid}/tos-acceptance`, requestData);
}

export function paymentMethod(uuid: string, id: number)
{
    return get(`/checkout/${uuid}/payment-method/${id}`);
}