import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function show(partnership: number){
    return get(`/corporate/common/partnership/${partnership}`);
}

export function update(partnership: number, requestData: {status?: "Ended", accessAffiliation?: 'Granted'|'Denied'|'Requested', accessSale?: 'Granted'|'Denied'|'Requested'}){
    return post(`/corporate/common/partnership/${partnership}`, requestData);
}

export function list(requestData: {query?: string, offset: number}){
    return get(`/corporate/common/partnership${encodeQueryData(requestData)}`);
}

export function accounts(requestData: {query: string, offset: number}){
    return post(`/corporate/common/partnership/accounts`, requestData);
}