import React from "react";
import ChatList from "./list";
import ChatShow from "./show";
import {useParams} from "react-router-dom";

const Chat = () => {

    const {id} = useParams();

    return <div className={"container-fluid chat-container"}>
        <div className="row h-100">
            <div className={"col-md-4 h-100 border-end border-1 " + (id ? " d-none d-md-block " : "")}>
                <ChatList />
            </div>
            <ChatShow />
        </div>
    </div>
}

export default Chat;