import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup"
import * as accountApi from "../../../../../adapters/corporate/common/account";
import * as api from "../../../../../adapters/corporate/common/chat";
import SearchInput from "../../../../../utils/searchInput";
import FormError from "../../../../../utils/formError";
import Loader from "../../../../../utils/loader";
import {Account, Accounts, CorporateAccounts} from "../../../../../models/account";
import {getLogoUrl} from "../../../../../utils/image";
import {Chat} from "../../../../../models/chat";
import Pagination from "../../../../../utils/pagination";
import ErrorCard from "../../../../error";

const ChatAdd = ({onSuccess}: {onSuccess: (chat: Chat) => void}) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null)
    const [payload, setPayload] = useState<{ count: number, rows: CorporateAccounts }>({count: 0, rows: []});
    const [selected, setSelected] = useState<Accounts>([]);
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number }>({query: "", offset: 0})

    const formik = useFormik({
        initialValues: {
            name: "",
            accounts: [],
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            accounts: Yup.array().of(Yup.number()).min(1).required()
        }),
        onSubmit: (values, {setSubmitting}) => {
            api.add({...values, type: "group"})
                .then(r => onSuccess(r.data))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    })

    useEffect(() => {
        setLoading(true)
        accountApi.list(fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    useEffect(() => {
        formik.setFieldValue("accounts", selected.map(s => s.id));
    }, [selected])

    function handleClick(account: Account){
        let index = selected.findIndex(s => s.id === account.id)

        if (index !== -1){
            setSelected(p => [...p.filter(p => p.id !== account.id)]);
        }else{
            setSelected(p => [...p, account]);
        }
    }

    let timer: NodeJS.Timer;
    function handleQueryChange(query: string){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: query, offset: 0}), 1000)
    }

    function isSelected(account: Account){
        return selected.findIndex(s => s.id === account.id) !== -1
    }

    return <form onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className={"form-control"} name={"name"} id={"name"} onChange={formik.handleChange} />
                    <label htmlFor="name">
                        Nom du groupe
                    </label>
                </div>
                <FormError form={formik} field={"name"} />
            </div>
        </div>
        <div className="col-12 mb-3">
            <h6>
                Contact à ajouter
            </h6>
            {error && <ErrorCard error={error} />}
            {selected.length ? <div className="col-12 mb-3">
                {selected.map((s, key) => <span key={key} className={'badge bg-primary'}>
                        <span className={"me-2"}>
                            {s.firstname} {s.lastname}
                        </span>
                        <span onClick={() => handleClick(s)}>
                            <i className={'bi bi-x-circle'}></i>
                        </span>
                    </span>)}
            </div> : <div className={"form-text"}>
                Séléctionnez dans la liste ci-dessous
            </div>}
            <FormError form={formik} field={"accounts"} />
            <div className="col-12 mb-2">
                <SearchInput onChange={e => handleQueryChange(e.target.value)} size={'sm'} />
            </div>
            <ul className={'list-group overflow-auto'}>
                {payload.rows.map(account => <li className={'list-group-item list-group-item-action d-flex align-items-center'} onClick={() => handleClick(account)}>
                    <div className="avatar avatar-lg flex-grow-0">
                        <img src={getLogoUrl(account.organization)} alt={account.organization.name} className="avatar-img rounded-circle" />
                    </div>
                    <div className="flex-grow-1">
                        <p className="mb-1">
                            {account.firstname} {account.lastname}
                        </p>
                        <span className="form-text">
                            {account.organization.name}
                        </span>
                    </div>
                    <div className="flex-grow-0">
                        {isSelected(account) && <i className={'bi bi-check'}></i>}
                    </div>
                </li>)}
                <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
            </ul>
        </div>
        <button className={"btn btn-primary btn-xs w-100"}>
            {formik.isSubmitting ? <Loader size={"sm"} /> : <><i className={'bi bi-check'}></i> Valider</>}
        </button>
    </form>
}

export default ChatAdd;