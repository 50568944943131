import React, {useEffect, useState} from "react";
import * as api from '../../../../../adapters/corporate/provider/fee';
import SearchInput from "../../../../../utils/searchInput";
import {Fees} from "../../../../../models/fee";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";
import ErrorCard from "../../../../error";
import {FetchParams, Payload} from "../../../../../models/global";
import convertToDecimal from "../../../../../utils/currencyConverter";
import {Touch} from "../../../../../models/touch";

const ProviderFeeList = ({status, touch}: {status: 'All'|'Pending'|'Accepted'|'Refused'|'Paid', touch?: Touch}) => {

    const [payload, setPayload] = useState<Payload<Fees>>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<FetchParams & {status?: string}>({offset: 0})
    const [error, setError] = useState(null)


    useEffect(() => {
        setLoading(true)
        api.list({...fetchParams, status: status, touch_id: touch?.id})
            .then(resp => setPayload(resp.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams, status])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher une commission..."} onChange={handleQueryChange} size={"default"} />
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        {error && <ErrorCard error={error} />}
        <div className="col-md-12 flex-grow-1">
            {!loading && <div className={'table-responsive'}>
                <table className={'table'}>
                    <thead>
                        <tr>
                            <th>
                                Partenaire
                            </th>
                            <th>
                                Transaction total
                            </th>
                            <th>
                                Commission
                            </th>
                            <th>
                                Statut
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {payload.rows.map(item => <tr>
                            <td>
                                {item.supplier.name}
                            </td>
                            <td>
                                {convertToDecimal(item.amount, item.currency)}
                            </td>
                            <td>
                                {convertToDecimal(Math.floor(item.amount / 100 * item.percentage), item.currency)} ({item.percentage}%)
                            </td>
                            <td>
                                {item.status}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>}
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        </div>
    </div>
}

export default ProviderFeeList;