import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import * as api from "../../adapters/pack";
import {Country} from "../../models/country";
import {Currency} from "../../models/currency";
import Loader from "../../utils/loader";
import PackNavHeader from "./nav/header";
import {Organization} from "../../models/organization";
import PackNavFooter from "./nav/footer";
import PackCart from "./cart";
import ErrorCard from "../error";

const PackShow = () => {

    const {uuid} = useParams();
    const [loading, setLoading] = useState(!!uuid);
    const [error, setError] = useState(null)
    const [packDetails, setPackDetails] = useState<{
        provider: Organization,
        country: Country,
        currency: Currency,
    }>()

    useEffect(() => {
        if (uuid){
            setLoading(true)
            api.show(uuid)
                .then(r => setPackDetails(r.data))
                .catch(e => setError(e))
                .finally(() => setLoading(false))
        }
    }, [uuid])

    if (!uuid){
        return <div className={'col-12 py-10 text-center'}>
            <h6>
                <i className={'bi bi-exclamation-circle'}></i> Lien invalide
            </h6>
        </div>
    }

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!packDetails){
        return <div className={'col-12 py-10 text-center'}>
            <h6>
                <i className={'bi bi-search'}></i> Lien non trouvé
            </h6>
        </div>
    }

    return <div className={'container-fluid h-100 d-flex flex-column px-0'}>
        <PackNavHeader uuid={uuid} country={packDetails.country} currency={packDetails.currency} provider={packDetails.provider} />
        <div className="container mx-auto flex-grow-1">
            <PackCart uuid={uuid} currency={packDetails.currency} country={packDetails.country} />
        </div>
        <PackNavFooter setCurrency={currency => setPackDetails(prev => ({...prev!, currency: currency}))} setCountry={country => setPackDetails(prev => ({...prev!, country: country}))} />
    </div>
}

export default PackShow;