import React, {useState} from "react";
import Select from "../../../../../../../../utils/select";
import FormError from "../../../../../../../../utils/formError";
import * as Yup from "yup";
import {useFormik} from "formik";
import {Affiliation} from "../../../../../../../../models/affiliation";
import * as api from "../../../../../../../../adapters/corporate/supplier/affiliation/conversionRule";
import * as models from "../../../../../../../../models/affiliation";
import ErrorCard from "../../../../../../../error";
import Loader from "../../../../../../../../utils/loader";

const SupplierAffiliationConversionRuleAdd: React.FC<{ affiliation: Affiliation, onSuccess: (rule: models.ConversionRule) => void}> = ({affiliation, onSuccess}) => {

    const [error, setError] = useState(null);

    const formik = useFormik({
        initialValues: {
            name: "Toute les conversions",
            type: "Percent",
            validation: "Automatic",
            validationDelay: "20",
            compensations: affiliation.currencies.map(c => ({
                currency_id: c.id,
                amount: 0
            }))
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            type: Yup.string().required(),
            validation: Yup.string().required(),
            validationDelay: Yup.number().required(),
            compensations: Yup.array().of(Yup.object().shape({
                currency_id: Yup.number().required(),
                amount: Yup.number().required().min(0.01)
            })).required()
        }),
        enableReinitialize: false,
        onSubmit: (values, {setSubmitting}) => {
            api.add(affiliation.id, values)
                .then(r => onSuccess(r.data))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    })

    return <form onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className="form-control" defaultValue={formik.values.name} name={'name'} id={'name'} onChange={formik.handleChange}/>
                <label htmlFor="name">
                    Nom *
                </label>
            </div>
            <FormError form={formik} field={"name"} />
        </div>
        <div className="col-12 mb-3">
            <Select
                required={true}
                label={"Type *"}
                value={{label: "Pourcentage", value: 'Percent'}}
                options={[{label: "Pourcentage", value: 'Percent'}, {
                    label: "Fix",
                    value: 'Fix'
                }]}
                onChange={c => formik.setFieldValue("type", c.value)}
            />
            <FormError form={formik} field={'type'}/>
        </div>
        <div className="col-12 mb-3">
            <Select
                required={true}
                label={"Validation *"}
                value={{label: "Automatique", value: 'Automatic'}}
                options={[{label: "Automatique", value: 'Automatic'}, {
                    label: "Manuelle",
                    value: 'Manual'
                }]}
                onChange={c => formik.setFieldValue("validation", c.value)}
            />
            <FormError form={formik} field={'validation'}/>
        </div>
        {formik.values.validation === "Automatic" && <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="number" onChange={formik.handleChange} id={"validationDelay"} name={'validationDelay'} defaultValue={formik.values.validationDelay} className="form-control"/>
                <label htmlFor="validationDelay">
                    Délai de validation *
                </label>
            </div>
            <FormError form={formik} field={'validationDelay'}/>
        </div>}
        <div className="col-12 mb-3">
            <h6>
                Cout par conversion
            </h6>
            <ul className="list-unstyled">
                {affiliation.currencies.map((c, index) => <li className={'mb-3'}>
                    <div className="input-group">
                        <span className="input-group-text">
                            {c.name} {c.symbol}
                        </span>
                        <input type="number" step={0.01} className="form-control" name={`compensations[${index}].amount`} onChange={formik.handleChange}/>
                        {formik.values.type === "Percent" && <span className="input-group-text">%</span>}
                    </div>
                </li>)}
            </ul>
        </div>
        {error && <ErrorCard error={error} />}
        <button className={'btn btn-primary w-100'}>
            {formik.isSubmitting ? <Loader /> : <><i className={'bi bi-plus-circle'}></i> Ajouter</>}
        </button>
    </form>
}

export default SupplierAffiliationConversionRuleAdd;