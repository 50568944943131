import React, {useEffect, useState} from "react";
import * as api from '../../../../../adapters/corporate/supplier/fee';
import SearchInput from "../../../../../utils/searchInput";
import {Fee, Fees} from "../../../../../models/fee";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";
import SupplierFeeAdd from "../add";
import ModalComponent from "../../../../modal";
import ErrorCard from "../../../../error";
import {FetchParams, Payload} from "../../../../../models/global";
import convertToDecimal from "../../../../../utils/currencyConverter";
import {Click} from "../../../../../models/affiliation";
import {Touch} from "../../../../../models/touch";
import CommonFeeStatus from "../../../common/fee/status";
import SupplierFeePay from "../../fee/pay";
import {Assignment} from "../../../../../models/assignment";

const SupplierFeeList = ({status, touch, assignment}: {status: string, touch?: Touch, assignment?: Assignment}) => {

    const [payload, setPayload] = useState<Payload<Fees>>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<FetchParams & {status?: string}>({offset: 0})
    const [error, setError] = useState(null)
    const [showAddModal, setShowAddModal] = useState(false)
    const [selected, setSelected] = useState<Fees>([])
    const [selecting, setSelecting] = useState(false)
    const [showPaidModal, setShowPaidModal] = useState(false);
    const [submitting, setSubmitting] = useState(false)
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [showRefusedModal, setShowRefusedModal] = useState(false);
    const [paymentError, setPaymentError] = useState("");

    useEffect(() => {
        setLoading(true)
        api.list({...fetchParams, status: status, count: 20, touch_id: touch?.id, assignment_id: assignment?.id})
            .then(resp => setPayload(resp.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams, status])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    function handleSelect(fee: Fee)
    {
        if (selected.map(s => s.id).includes(fee.id)){
            setSelected(prev => [...prev.filter(p => p.id !== fee.id)])
        }else{
            setSelected(prev => [...prev, fee])
        }
    }
    function selectAll(){
        setSelecting(true)
        api.list({offset: fetchParams.offset, count: payload.count, status: status})
            .then(resp => setSelected(resp.data.rows))
            .finally(() => setSelecting(false))
    }

    function submitSelection(status: "Accepted"|"Refused"){
        if (selected.length){
            setSubmitting(true)
            api.bulkUpdate({ids: selected.map(s => s.id), status: status})
                .then(resp => {
                    const ids = resp.data.map((c: Click) => c.id);
                    setPayload(prev => ({count: prev.count - ids.length, rows: [...prev.rows.filter(r => !ids.includes(r.id))]}))
                    setSelected(prev => [...prev.filter(p => !ids.includes(p.id))])
                    setShowAcceptModal(false)
                })
                .finally(() => {
                    setSubmitting(false)
                })
        }
    }

    function confirmPaidSelection(){
        setPaymentError('');
        if (selected.length === 0) {
            setPaymentError('Aucune commission séléctionnée.')
        } else {
            const firstCurrencyId = selected[0].currency.id;

            const allSameCurrency = selected.every(item => item.currency.id === firstCurrencyId);

            if (allSameCurrency) {
                setShowPaidModal(true)
            } else {
                setPaymentError('Les commissions ne peuvent être payé que dans une seule devise simultanément.')
            }
        }
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher une commission..."} onChange={handleQueryChange} size={"default"} />
                </div>
                <div className="col-auto">
                    <button className={'btn btn-primary btn-xs rounded'} onClick={() => setShowAddModal(true)}>
                        <i className={'bi bi-plus-circle'}></i> Ajouter
                    </button>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        {["Pending", "Accepted"].includes(status) && <div className={'col-12.mb-3'}>
            <div className="card border border-1">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col fw-semibold">
                            {selected.length} commission(s) séléctionné(s)
                        </div>
                        <div className="col-auto">
                            <button className={'btn btn-xs btn-light'} onClick={() => selectAll()} disabled={selecting || !payload.count || selected.length === payload.count}>
                                {selecting ? <Loader /> : <>
                                    {selected.length === payload.count ? <>Toute les commissions {status !== "All" && <CommonFeeStatus status={status}/>} sont séléctionnés</> : <>Séléctionner toute les commissions {status !== "All" && <CommonFeeStatus status={status}/>} ({payload.count})</>}
                                </>}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <p>
                        Avec la séléction:
                    </p>
                    {status === "Pending" && <div className="btn-group">
                        <button className={"btn btn-xs btn-light"} disabled={selected.length === 0} onClick={() => setShowAcceptModal(true)}>
                            <i className={'bi bi-check text-success'}></i> Accepter
                        </button>
                        <button className={"btn btn-xs btn-light"} disabled={selected.length === 0} onClick={() => setShowRefusedModal(true)}>
                            <i className={'bi bi-x text-danger'}></i> Refuser
                        </button>
                    </div>}
                    {status === "Accepted" && <>
                        <div className="btn-group">
                            <button className={"btn btn-xs btn-light"} disabled={selected.length === 0} onClick={() => confirmPaidSelection()}>
                                <i className={'bi bi-credit-card-2-front'}></i> Payer
                            </button>
                        </div>
                        {paymentError && <p className={"form-text text-danger"}>
                            <i className={'bi bi-exclamation-circle'}></i> {paymentError}
                        </p>}
                    </>}
                </div>
            </div>
        </div>}
        {error && <ErrorCard error={error} />}
        <div className="col-md-12 flex-grow-1">
            {!loading && <div className={'table-responsive'}>
                <table className={'table'}>
                    <thead>
                        <tr>
                            {['Pending', 'Accepted'].includes(status) && <th></th>}
                            <th>
                                Partenaire
                            </th>
                            <th>
                                Transaction HT
                            </th>
                            <th>
                                Commission HT
                            </th>
                            <th>
                                Statut
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {payload.rows.map(item => <tr>
                            {['Pending', 'Accepted'].includes(status) && <td>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id={`fee_${item.id}`} onChange={() => handleSelect(item)} checked={selected.map(s => s.id).includes(item.id)}/>
                                </div>
                            </td>}
                            <td>
                                {item.provider.name}
                            </td>
                            <td>
                                {convertToDecimal(item.amount, item.currency)}
                            </td>
                            <td>
                                {convertToDecimal(Math.ceil(item.amount / 100 * item.percentage), item.currency)} ({item.percentage}%)
                            </td>
                            <td>
                                {item.status}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>}
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        </div>
        {showAddModal && <ModalComponent title={"Ajouter une commission"} show={true} onHide={() => setShowAddModal(false)}>
            <SupplierFeeAdd touch={touch} handleSuccess={fee => {
                setPayload(prev => ({count: prev.count + 1, rows: [fee, ...prev.rows]}))
                setShowAddModal(false)
            }}/>
        </ModalComponent>}
        {showPaidModal && <ModalComponent size={"modal-lg"} title={"Payer les commissions"} show={showPaidModal} onHide={() => setShowPaidModal(false)}>
            <SupplierFeePay
                fees={selected}
                handlePaymentSucceed={fees => {
                const ids = fees.map(c => c.id);
                setPayload(prev => ({count: prev.count - ids.length, rows: [...prev.rows.filter(r => !ids.includes(r.id))]}))
                setSelected(prev => [...prev.filter(p => !ids.includes(p.id))])
                setShowPaidModal(false)
            }} />
        </ModalComponent>}
        {showAcceptModal && <ModalComponent size={"modal-lg"} title={"Accepter les commissions"} show={showAcceptModal} onHide={() => setShowAcceptModal(false)}>
            <div className="col-12 text-center">
                <p className={'fw-semibold'}>
                    Êtes vous sur de vouloir accepté les {selected.length} commissions ?
                </p>
                <div className="btn-group">
                    <button className="btn btn-light" disabled={submitting} onClick={() => submitSelection("Accepted")}>
                        <i className={'bi bi-check text-success'}></i> Oui, je suis sur.
                    </button>
                    <button className="btn btn-light" disabled={submitting} onClick={() => setShowAcceptModal(false)}>
                        <i className={'bi bi-x text-danger'}></i> Annuler
                    </button>
                </div>
            </div>
        </ModalComponent>}
        {showRefusedModal && <ModalComponent size={"modal-lg"} title={"Refuser les commissions"} show={showRefusedModal} onHide={() => setShowRefusedModal(false)}>
            <div className="col-12 text-center">
                <p className={'fw-semibold'}>
                    Êtes vous sur de vouloir refuser les {selected.length} commissions ?
                </p>
                <div className="btn-group">
                    <button className="btn btn-light" disabled={submitting} onClick={() => submitSelection("Refused")}>
                        <i className={'bi bi-check text-success'}></i> Oui, je suis sur.
                    </button>
                    <button className="btn btn-light" disabled={submitting} onClick={() => setShowRefusedModal(false)}>
                        <i className={'bi bi-x text-danger'}></i> Annuler
                    </button>
                </div>
            </div>
        </ModalComponent>}
    </div>
}

export default SupplierFeeList;