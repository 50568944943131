import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import * as api from '../../../../../adapters/corporate/supplier/shipping';
import SearchInput from "../../../../../utils/searchInput";
import {Shippings} from "../../../../../models/shipping";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";

const SupplierShippingList = () => {

    const [payload, setPayload] = useState<{ count: number, rows: Shippings }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number}>({query: "", offset: 0})

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(resp => setPayload(resp.data))
            .then(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    Profil d'expédition
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher un profil d'expédition..."} onChange={handleQueryChange} size={"default"} />
                </div>
                <div className="col-auto">
                    <Link to={"/corporate/supplier/shipping/add"} className={'btn btn-light'}>
                        <i className={'bi bi-plus-circle'}></i> Ajouter un profil
                    </Link>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        <div className="col-md-12 flex-grow-1">
            <ul className={'list-group list-group-flush'}>
                {payload.rows.map(s => <li className={'list-group-item list-group-item-action'}>
                    <Link to={`${s.id}/area`}>
                        {s.name}
                    </Link>
                </li>)}
            </ul>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        </div>
    </div>
}

export default SupplierShippingList;