import React from "react";
import SupplierParcelList from "../../../../../components/corporate/supplier/parcel/list";

const SupplierParcelListPage = () => {


    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    Colis
                </li>
            </ol>
        </div>
        <SupplierParcelList />
    </div>
}

export default SupplierParcelListPage;