import {post, get} from "../../../../xhr";
import encodeQueryData from "../../../../../utils/encodeQueryData";

export function create(productId: number, requestData: any){
    return post(`corporate/supplier/product/${productId}/price`, requestData);
}

export function list(productId: number, requestData: { offset: number }){
    return get(`corporate/supplier/product/${productId}/price${encodeQueryData(requestData)}`);
}

export function _default(productId: number, priceId: number){
    return get(`corporate/supplier/product/${productId}/price/${priceId}/default`);
}