import {Link} from "react-router-dom";
import React, {useState} from "react";
import SupplierClickList from "../click/list";
import SupplierAffiliationList from "./list";
import SupplierConversionList from "../conversion/list";

const SupplierAffiliation = ({tab}: {tab?: "click"|"conversion"}) => {

    const [status, setStatus] = useState<"All"|"Pending"|"Accepted"|"Refused"|"Paid">("All")

    return <div className={"container"}>
        <div className="col-12 mb-3">
            <div className="mb-2 mb-lg-0 border-bottom-lg">
                <nav className="nav nav-tabs nav-overflow fs-xs border-bottom border-bottom-lg-0">
                    <Link to={'/corporate/supplier/affiliation'} className={"nav-link text-uppercase" + (!tab ? " active " : "")}>
                        Programmes
                    </Link>
                    <Link to={'/corporate/supplier/affiliation/click'} className={"nav-link text-uppercase" + (tab === 'click' ? " active " : "")}>
                        Tous les clics
                    </Link>
                    <Link to={'/corporate/supplier/affiliation/conversion'} className={"nav-link text-uppercase" + (tab === 'conversion' ? " active " : "")}>
                        Toutes les conversions
                    </Link>
                </nav>
            </div>
        </div>
        {!tab && <SupplierAffiliationList />}
        {tab && <div className={'col-12 mb-3'}>
            <ul className="nav nav-pills mx-auto">
                <li className="nav-item">
                    <button className={`nav-link ${status === "All" ? 'active' : ''}`} onClick={() => setStatus('All')}>
                        Tout les statuts
                    </button>
                </li>
                <li className="nav-item">
                    <button className={`nav-link ${status === "Pending" ? 'active' : ''}`} onClick={() => setStatus('Pending')}>
                        En attente
                    </button>
                </li>
                <li className="nav-item">
                    <button className={`nav-link ${status === "Accepted" ? 'active' : ''}`} onClick={() => setStatus('Accepted')}>
                        Accepté
                    </button>
                </li>
                <li className="nav-item">
                    <button className={`nav-link ${status === "Refused" ? 'active' : ''}`} onClick={() => setStatus('Refused')}>
                        Refusé
                    </button>
                </li>
                <li className="nav-item">
                    <button className={`nav-link ${status === "Paid" ? 'active' : ''}`} onClick={() => setStatus('Paid')}>
                        Payé
                    </button>
                </li>
            </ul>
        </div>}
        {tab === "click" && <SupplierClickList status={status} />}
        {tab === "conversion" && <SupplierConversionList status={status} />}
    </div>
}

export default SupplierAffiliation;