import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query?: string, offset: number, customer_id?: number}){
    return get(`corporate/supplier/subscription${encodeQueryData(requestData)}`);
}

export function show(subscription: number){
    return get(`corporate/supplier/subscription/${subscription}`);
}

export function update(subscription: number, requestData: {status?: string, quantity?: number}){
    return post(`corporate/supplier/subscription/${subscription}`, requestData);
}

export function add(requestData: any){
    return post(`corporate/supplier/subscription`, requestData);
}

export function events(subscription: number, requestData: {startDate: string, endDate: string}){
    return get(`corporate/supplier/subscription/${subscription}/events${encodeQueryData(requestData)}`);
}