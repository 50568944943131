import React, {useEffect, useState} from "react";
import * as models from '../../../../../models/touch'
import {getLogoUrl} from "../../../../../utils/image";
import {Link, useParams} from "react-router-dom";
import * as api from "../../../../../adapters/corporate/provider/touch";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import CommonTouchStatus from "../../../common/touch/status";
import ProviderFeeList from "../../fee/list";

const ProviderTouchShow = () => {

    const params = useParams();
    const [touch, setTouch] = useState<models.Touch>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        api.show(Number(params.id))
            .then(r => setTouch(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [params.id])

    if (loading){
        return <div className={'container-fluid py-10 text-center'}>
            <Loader />
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!touch){
        return <></>
    }

    return <div className={"container-fluid"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/corporate/provider/touch">Mise en relation</Link>
                </li>
                <li className="breadcrumb-item">
                    {touch.title}
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <div className="row">
                        <h6>
                            Commissions
                        </h6>
                    </div>
                </div>
                <div className="card-body">
                    <ProviderFeeList status={"All"} touch={touch} />
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Informations
                    </h6>
                </div>
                <div className="card-body">
                    <table className={'table'}>
                        <tbody>
                            <tr>
                                <th>Contact</th>
                                <td>
                                    <Link to={`/corporate/provider/contact/${touch.contact.id}`}>
                                        {touch.contact.firstname} {touch.contact.lastname}
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <th>Lancement du projet</th>
                                <td>
                                    {touch.launch === 'Asap' && 'Le plus vite possible'}
                                    {touch.launch === 'Unknown' && 'Ne sait pas'}
                                    {touch.launch === 'Date' && <>
                                        {new Date(touch.launchAt!).toLocaleDateString()} {touch.isFlexible && <>+/- 1 {touch.flexibleType}</>}
                                    </>}
                                </td>
                            </tr>
                            <tr>
                                <th>Lieux du projet</th>
                                <td>
                                    {touch.location === 'Distant' && 'A distance'}
                                    {touch.location === 'Unknown' && 'Ne sait pas'}
                                    {touch.location === 'OnSite' && <>
                                        {touch.city}, {touch.postalCode}
                                    </>}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Description
                    </h6>
                </div>
                <div className="card-body">
                    <p className={'mb-0'} style={{whiteSpace: "pre-wrap"}}>
                        {touch.description}
                    </p>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Entreprises
                    </h6>
                </div>
                <div className="card-body">
                    <div className="row row-cols-1 row-cols-md-3">
                        {touch.touchSuppliers.map(s => <div className="col mb-3">
                            <div className="card border border-1">
                                <img className="card-img-top" src={getLogoUrl(s.supplier)} alt={s.supplier.name} />
                                <div className="card-body card-sm">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <p className={'fw-bold mb-0'}>{s.supplier.name}</p>
                                        </div>
                                        <div className="col-auto">
                                            <CommonTouchStatus status={s.status} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ProviderTouchShow;