import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import {useAppSelector} from "../../hooks";

const AccountLayout = () => {

    let auth = useAppSelector(state => state.auth)

    if (!auth.user){
        return <Navigate to={'/login'} replace />
    }

    return <div className={'container mx-auto h-100'}>
        <div className="row h-100 align-items-center justify-content-center">
            <div className="col-md-6">
                <div className="row justify-content-center">
                    <div className="col-auto d-flex align-items-center mb-5">
                        <img src={process.env.PUBLIC_URL + '/img/logo.png'} className={'logo me-2'} alt=""/>
                    </div>
                </div>
                <Outlet />
            </div>
        </div>
    </div>
}

export default AccountLayout;