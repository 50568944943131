import {get, post} from "../xhr";

export function login(requestData: {email: string, password: string}){
    return post("/auth/login", requestData)
}

export function googleLogin(requestData: {accessToken: string, type: string}){
    return post("/auth/google-login", requestData)
}

export function register(requestData: {email: string, password: string, repeatPassword: string}){
    return post("/auth/register", requestData)
}

export function activate(verificationCode: string)
{
    return get(`auth/activate/${verificationCode}`)
}

export function refreshActivationCode()
{
    return get(`auth/refresh-activation-code`)
}

export function accounts()
{
    return get(`auth/accounts`)
}


export function forgotPassword(requestData: {email: string}){
    return post("/auth/forgot-password", requestData)
}

export function resetPassword(requestData: {token: string, password: string, repeatPassword: string}){
    return post("/auth/reset-password", requestData)
}

export function verifyPwdToken(requestData: {token: string}){
    return post("/auth/verify-password-token", requestData)
}
