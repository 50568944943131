import React from "react";
import SettingAccount from "./account";
import {Link} from "react-router-dom";
import SettingOrganization from "./organization";
import SettingInformation from "./information";
import SettingAddress from "./address";
import SettingBio from "./bio";
import CommonPaymentMethodList from "../../../common/paymentMethod/list";

const Setting = ({tab}: { tab: string }) => {

    return <div className={'container-fluid h-100 py-5'}>
        <div className="row">
            <div className="col-auto">
                <ul className="list-group position-sticky top-0">
                    <Link className={"list-group-item list-group-item-action cursor-pointer"} to={"/corporate/setting/account"}>
                        <span className={(tab === "account" ? " text-primary " : " text-body-secondary ")}>
                            Votre profil
                        </span>
                    </Link>
                    <Link className={"list-group-item list-group-item-action cursor-pointer"} to={"/corporate/setting/organization"}>
                        <span className={(tab === "organization" ? " text-primary " : " text-body-secondary ")}>
                            Entreprise et services
                        </span>
                    </Link>
                    <Link className={"list-group-item list-group-item-action cursor-pointer"} to={"/corporate/setting/information"}>
                        <span className={(tab === "information" ? " text-primary " : " text-body-secondary ")}>
                            Information légales
                        </span>
                    </Link>
                    <Link className={"list-group-item list-group-item-action cursor-pointer"} to={"/corporate/setting/bio"}>
                        <span className={(tab === "bio" ? " text-primary " : " text-body-secondary ")}>
                            A propos
                        </span>
                    </Link>
                    <Link className={"list-group-item list-group-item-action cursor-pointer"} to={"/corporate/setting/address"}>
                        <span className={(tab === "address" ? " text-primary " : " text-body-secondary ")}>
                            Adresse de l'entreprise
                        </span>
                    </Link>
                    <Link className={"list-group-item list-group-item-action cursor-pointer"} to={"/corporate/setting/payment-method"}>
                        <span className={(tab === "payment-method" ? " text-primary " : " text-body-secondary ")}>
                            Moyen de paiement
                        </span>
                    </Link>
                </ul>
            </div>
            <div className="col">
                {tab === "account" && <SettingAccount />}
                {tab === "organization" && <SettingOrganization />}
                {tab === "information" && <SettingInformation />}
                {tab === "address" && <SettingAddress />}
                {tab === "bio" && <SettingBio />}
                {tab === "payment-method" && <CommonPaymentMethodList />}
            </div>
        </div>
    </div>
}

export default Setting;