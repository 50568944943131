import {Project} from "../../../../../../models/project";
import {getLogoUrl} from "../../../../../../utils/image";
import {Link, useNavigate} from "react-router-dom";
import ProjectLaunch from "../../../../common/project/launch";
import convertToDecimal from "../../../../../../utils/currencyConverter";
import React, {useState} from "react";
import * as quoteApi from "../../../../../../adapters/corporate/supplier/quote";
import Loader from "../../../../../../utils/loader";
import ModalComponent from "../../../../../modal";
import SupplierProjectListItemContact from "./contact";

const SupplierProjectListItem = ({project}: {project: Project}) => {

    const [showModal, setShowModal] = useState(false);
    const [addingQuote, setAddingQuote] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);
    const navigate = useNavigate();

    function addQuote(){
        setAddingQuote(true)
        quoteApi.add({project_id: project.id})
            .then(r => navigate(`/corporate/supplier/quote/${r.data.id}`))
            .catch(error => console.log(error))
            .finally(() => setAddingQuote(false))
    }

    return <>
        <div className={'col-12 mb-3'}>
        <div className="card border border-1">
            <div className="card-header">
                <h6>
                    {project.title}
                </h6>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-text">
                            Apporteur
                        </div>
                        <p>
                            <span className="avatar avatar-sm me-2">
                                <img src={getLogoUrl(project.organization)} alt={project.organization.name} className="avatar-img rounded-circle" />
                            </span>
                            <Link to={`/corporate/organization/${project.organization.id}`} target={'_blank'}>
                                {project.organization.name}
                            </Link>
                        </p>
                    </div>
                    <div className="col-md-4">
                        <div className="form-text">
                            Sécteur
                        </div>
                        <p>
                            <span>
                                {project.sector.name}
                            </span>
                        </p>
                    </div>
                    <div className="col-md-4">
                        <div className="form-text">
                            Démarrage
                        </div>
                        <p>
                            <ProjectLaunch p={project} />
                        </p>
                    </div>
                    <div className="col-md-4">
                        <div className="form-text">
                            Localisation
                        </div>
                        <p>
                            {project.city}, {project.postalCode}, {project.country.name}
                        </p>
                    </div>
                    <div className="col-md-4">
                        <div className="form-text">
                            Budget
                        </div>
                        <p>
                            {convertToDecimal(project.condition.estimateAmount, project.currency)}
                        </p>
                    </div>
                    <div className="col-md-4">
                        <div className="form-text">
                            Devise
                        </div>
                        <p>
                            {project.currency.symbol} ({project.currency.name})
                        </p>
                    </div>
                    <div className="col-md-12">
                        <div className="form-text">
                            Description du projet
                        </div>
                        <div className="overflow-hidden position-relative" style={{height: 400}}>
                            <div dangerouslySetInnerHTML={{ __html: project.description }} />
                            <div className="w-100 position-absolute bottom-0 text-center bg-white" style={{opacity: 0.7}}>
                                <button style={{opacity: 1}} className="btn text-decoration-underline text-link" onClick={() => setShowModal(true)}>
                                    Afficher toute la description
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer text-end">
                <button type="button" className="btn btn-xs btn-light me-2" onClick={() => setShowContactModal(true)}>
                    <i className={'bi bi-envelope'}></i> Contacter {project.organization.name}
                </button>
                <button type="button" className="btn btn-xs btn-primary me-2" onClick={() => addQuote()}>
                    {addingQuote ? <Loader size={"sm"}/> : <> Soumettre un devis</>}
                </button>
                <Link to={`/corporate/supplier/project/${project.id}`} target={"_blank"} className={'btn btn-xs btn-info'}>
                    Voir plus <i className={'bi bi-arrow-right'}></i>
                </Link>
            </div>
        </div>
    </div>
        <ModalComponent
            title={`Description ${project.title}`}
            show={showModal}
            onHide={() => setShowModal(false)}
            size={"modal-lg"}
            footerContent={<>
                <button type="button" className="btn btn-xs btn-light me-2" onClick={() => setShowContactModal(true)}>
                    <i className={'bi bi-envelope'}></i> Contacter {project.organization.name}
                </button>
                <button type="button" className="btn btn-xs btn-primary me-2" onClick={() => addQuote()}>
                    {addingQuote ? <Loader size={"sm"}/> : <> Soumettre un devis</>}
                </button>
                <Link to={`/corporate/supplier/project/${project.id}`} target={"_blank"} className={'btn btn-xs btn-info'}>
                    Afficher le projet <i className={'bi bi-arrow-right'}></i>
                </Link>
            </>}
        >
            <div dangerouslySetInnerHTML={{ __html: project.description }} />
        </ModalComponent>
        <ModalComponent title={`Contacter ${project.organization.name}`} show={showContactModal} onHide={() => setShowContactModal(false)}>
            <SupplierProjectListItemContact project={project} />
        </ModalComponent>
        </>
}

export default SupplierProjectListItem;