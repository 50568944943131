import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query?: string, offset: number}){
    return get(`corporate/supplier/product${encodeQueryData(requestData)}`);
}

export function show(id: number){
    return get(`corporate/supplier/product/${id}`);
}

export function update(id: number, requestData: {name?: string, description?: string, shippable?: boolean, longDescription?: string, visibility?: "public"|"private", category_id?: number}){
    return post(`corporate/supplier/product/${id}`, requestData);
}

export function create(requestData: {name: string, description: string, type: string, shippable: boolean, longDescription: string}){
    return post(`corporate/supplier/product`, requestData);
}