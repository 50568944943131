import React, {useEffect, useState} from "react";
import {FetchParams, Payload} from "../../../../../models/global";
import {Services} from "../../../../../models/service";
import * as api from "../../../../../adapters/corporate/provider/service";
import ErrorCard from "../../../../error";
import Loader from "../../../../../utils/loader";
import ModalComponent from "../../../../modal";
import ProviderServiceAdd from "../add";

const ProviderServiceList = () => {

    const [loading, setLoading] = useState(true);
    const [payload, setPayload] = useState<Payload<Services>>({count: 0, rows: []});
    const [fetchParams, setFetchParams] = useState<FetchParams>({offset: 0})
    const [error, setError] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false)

    useEffect(() => {
        api.list(fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    return <>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row">
                <div className="col">
                    <ol className="breadcrumb mb-3 fs-xs">
                        <li className="breadcrumb-item">
                            Mes services
                        </li>
                    </ol>
                </div>
                <div className="col-auto">
                   <button className="btn btn-primary btn-xxs" onClick={() => setShowAddModal(true)}>
                       <i className={'bi bi-plus-circle'}></i> Ajouter un service
                   </button>
                </div>
            </div>
        </div>
        <div className="accordion accordion-flush" id="accordionService">
            {payload.rows.map(r => <div className="accordion-item">
                <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target={`#flush-collapse${r.id}`} aria-expanded="false" aria-controls="flush-collapseOne">
                        {r.name}
                    </button>
                </h2>
                <div id={`flush-collapse${r.id}`} className="accordion-collapse collapse" data-bs-parent="#accordionService">
                    <div className="accordion-body">
                        <div dangerouslySetInnerHTML={{__html: r.description}} />
                    </div>
                </div>
            </div>)}
        </div>
        {showAddModal && <ModalComponent size={"modal-lg"} title={"Ajouter un service"} show={showAddModal} onHide={() => setShowAddModal(false)}>
            <ProviderServiceAdd onSuccess={service => {
                setPayload(prevState => ({count: prevState.count + 1, rows: [service, ...prevState.rows]}))
                setShowAddModal(false)
            }} />
        </ModalComponent>}
    </>
}

export default ProviderServiceList;