import FormError from "../../../../../../utils/formError";
import Loader from "../../../../../../utils/loader";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../../adapters/corporate/supplier/shipping/address";
import * as models from "../../../../../../models/shipping";
import Select from "../../../../../../utils/select";
import {getCountries, getCountryChoice} from "../../../../../../utils/countries";
import {useNavigate, useParams} from "react-router-dom";

const SupplierShippingAddressEdit = () => {

    const params = useParams()
    const navigate = useNavigate();
    const [address, setAddress] = useState<models.ShippingAddress>()
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.show(Number(params.id))
            .then(r => setAddress(r.data))
            .then(() => setLoading(false))
    }, [])

    const formik = useFormik({
        initialValues: {
            countryId: address?.country.id,
            city: address?.city,
            postalCode: address?.postalCode,
            line1: address?.line1,
            line2: address?.line2
        },
        validationSchema: Yup.object().shape({
            countryId: Yup.string().required().min(1),
            city: Yup.string().required(),
            postalCode: Yup.string().required(),
            line1: Yup.string().required(),
            line2: Yup.string().nullable(),
        }),
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            api.edit(address!.id, values)
                .then(r => navigate(`/corporate/supplier/shipping/address/${r.data.id}`))
        }
    })

    if (loading){
        return <div className={'container-fluid py-10 text-center'}>
            <Loader />
        </div>
    }

    return <div className={"container-fluid"}>
        <ol className="breadcrumb mb-3 fs-xs">
            <li className="breadcrumb-item">
                Expédition
            </li>
            <li className="breadcrumb-item">
                Lieux de traitement
            </li>
            <li className="breadcrumb-item">
                {address?.line1}
            </li>
            <li className="breadcrumb-item">
                Modifier
            </li>
        </ol>
        {address && <form onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3">
                <Select
                    required={true}
                    label={"Pays *"}
                    value={getCountryChoice(address.country.alpha2)}
                    options={getCountries()}
                    onChange={o => formik.setFieldValue('countryId', o.id)}
                />
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className={'form-control'} defaultValue={address.line1} name={"line1"} id={"line1"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                    <label htmlFor="line1">
                        Addresse *
                    </label>
                </div>
                <FormError form={formik} field={"line1"}/>
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className={'form-control'} defaultValue={address.line2} name={"line2"} id={"line2"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                    <label htmlFor="line2">
                        Complément d'addresse (optionel)
                    </label>
                </div>
                <FormError form={formik} field={"line2"}/>
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className={'form-control'} defaultValue={address.city} name={"city"} id={"city"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                    <label htmlFor="city">
                        Ville
                    </label>
                </div>
                <FormError form={formik} field={"city"}/>
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className={'form-control'} defaultValue={address.postalCode} name={"postalCode"} id={"postalCode"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                    <label htmlFor="postalCode">
                        Code postale
                    </label>
                </div>
                <FormError form={formik} field={"postalCode"}/>
            </div>
            <button className="btn btn-dark w-100 mb-3">
                {formik.isSubmitting ? <Loader/> : <><i className={'bi bi-check'}></i> Valider</>}
            </button>
        </form>}
    </div>
}

export default SupplierShippingAddressEdit;