import encodeQueryData from "../../../../utils/encodeQueryData";
import {get, post} from "../../../xhr";

export function list(requestData: { query?: string, offset: number, status?: string, organization_id?: number, assignment_id?: number}){
    return get(`corporate/supplier/proposal${encodeQueryData(requestData)}`)
}

export function show(proposal: number){
    return get(`corporate/supplier/proposal/${proposal}`)
}

export function update(proposal: number, requestData: {status: 'Refused'|'Completed'}){
    return post(`corporate/supplier/proposal/${proposal}`, requestData)
}

export function payment(proposal: number, requestData: {payment_method_id: number}){
    return post(`corporate/supplier/proposal/${proposal}/payment`, requestData)
}