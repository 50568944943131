import React, {useEffect, useState} from "react";
import * as models from "../../../../../models/pack";
import * as api from "../../../../../adapters/corporate/supplier/pack";
import {Link, useParams} from "react-router-dom";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import ModalComponent from "../../../../modal";
import SupplierPackUpdate from "../update";
import SupplierPackItemList from "../item/list";

const SupplierPackShow = () => {

    const {id} = useParams();
    const [pack, setPack] = useState<models.Pack>()
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null)
    const [showUpdateModal, setShowUpdateModal] = useState(false)

    useEffect(() => {
        setLoading(true)
        api.show(Number(id))
            .then(r => setPack(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [id])

    function activate(){
        if (pack){
            setLoading(true)
            api.update(pack.id, {activated: true})
                .then(r => setPack(r.data))
                .catch(e => setError(e))
                .finally(() => setLoading(false))
        }
    }

    function deactivate(){
        if (pack){
            setLoading(true)
            api.update(pack.id, {activated: false})
                .then(r => setPack(r.data))
                .catch(e => setError(e))
                .finally(() => setLoading(false))
        }
    }

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!pack){
        return <></>
    }

    return <div className={"container-fluid h-100"}>
        <div className="row">
            <div className="col mb-3">
                <ol className="breadcrumb mb-3 fs-xs">
                    <li className="breadcrumb-item">
                        <Link to="/corporate/supplier/catalog/pack">Packages</Link>
                    </li>
                    <li className="breadcrumb-item">
                        {pack.name}
                    </li>
                </ol>
            </div>
            <div className="col-auto mb-3">
                <button className="btn btn-xxs btn-light">
                    <i className={"bi bi-pencil"}></i> Mettre à jour
                </button>
            </div>
        </div>
        {pack.activated ? <div className="col-12 mb-3 alert alert-success">
            <div className="row align-items-center">
                <div className="col">
                    Le package est activé
                </div>
                <div className="col-auto">
                    <button className="btn btn-light btn-sm" onClick={() => deactivate()}>
                        Désactiver <i className={'bi bi-arrow-right'}></i>
                    </button>
                </div>
            </div>
        </div> : <div className="col-12 mb-3 alert alert-warning">
            <div className="row align-items-center">
                <div className="col">
                    Le package est désactivé
                </div>
                <div className="col-auto">
                    <button className="btn btn-light btn-sm" onClick={() => activate()}>
                        Activer <i className={'bi bi-arrow-right'}></i>
                    </button>
                </div>
            </div>
        </div>}
        <div className="col-12 mb-3">
            <SupplierPackItemList pack={pack} />
        </div>
        <ModalComponent title={`Mettre à jour ${pack.name}`} show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
            <SupplierPackUpdate handleSuccess={p => setPack(p)} pack={pack} />
        </ModalComponent>
    </div>
}

export default SupplierPackShow;