import * as models from "../../../../models/checkout";
import React from "react";
import CheckoutAddressBillingSelector from "./selector";

const CheckoutAddressBilling = ({checkout, setCheckout}: {checkout: models.CheckoutResponse, setCheckout: React.Dispatch<any>}) => {

    return <div className={"col-12 mb-3"}>
        <div className="row align-items-center">
            <div className="col">
                <p className={'mb-0 fw-semibold'}>
                    Addresse de facturation
                </p>
            </div>
        </div>
        <CheckoutAddressBillingSelector checkout={checkout} setCheckout={setCheckout}/>
    </div>
}

export default CheckoutAddressBilling;