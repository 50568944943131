import React, {useEffect, useState} from "react";
import * as api from "../../../../adapters/customer/order";
import SearchInput from "../../../../utils/searchInput";
import Loader from "../../../../utils/loader";
import {Link, useNavigate} from "react-router-dom";
import Pagination from "../../../../utils/pagination";
import * as models from "../../../../models/order";
import {getCoverUrl} from "../../../../utils/image";
import OrderStatusPlaceholder from "../../../common/order/status/placeholder";
import convertToDecimal from "../../../../utils/currencyConverter";

const CustomerOrderList = () => {

    const [payload, setPayload] = useState<{ count: number, rows: models.Orders }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number}>({query: "", offset: 0})
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        api.list({offset: fetchParams.offset, query: fetchParams.query})
            .then(resp => setPayload(resp.data))
            .then(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/customer">Mon compte</Link>
                </li>
                <li className="breadcrumb-item">
                    Mes commandes
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher une commande..."} onChange={handleQueryChange} size={"default"} />
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        <div className="col-md-12 flex-grow-1">
            {!loading && <div className={'row'}>
                {payload.rows.map(o => <div className="col-12 mb-3">
                    <div className="card border border-1 shadow-hover" onClick={() => navigate(`/customer/order/${o.id}`)}>
                        <div className="card-header">
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    #{o.number}
                                </div>
                                <div className="flex-grow-0">
                                    <OrderStatusPlaceholder status={o.status} />
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <p className={'mb-0'}>
                                Commandé le {new Date(o.orderedAt).toLocaleDateString()}
                            </p>
                            <p className={"form-text"}>
                                {new Date(o.returnsLimitAt).getTime() < new Date().getTime() ? <>Fenetre de retour fermé {new Date(o.returnsLimitAt).toLocaleDateString()}</> : <>Retour possible jusqu'au {new Date(o.returnsLimitAt).toLocaleDateString()}</>}
                            </p>
                            <div className="row">
                                {o.items.map(item => <div className={"col-12 col-md-4 col-lg-3"}><div className="card border border-1">
                                    <img src={`${getCoverUrl(item.price.product)}`} alt="" className="card-img-top br-0"/>
                                    <div className="card-body bg-white">
                                        <div className="row">
                                            <div className="col">
                                                <p className="d-block fw-bold text-body mb-1">
                                                    {item.name}
                                                </p>
                                                <p className="fs-xs text-muted mb-0">
                                                    Quantité: {item.quantity}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div></div>)}
                            </div>
                        </div>
                    </div>
                </div>)}

            </div>}
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        </div>
    </div>
}

export default CustomerOrderList;