import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../../../adapters/corporate/supplier/affiliation/support";
import * as models from "../../../../../../../models/affiliation";
import Dropzone from "react-dropzone";
import FormError from "../../../../../../../utils/formError";
import Loader from "../../../../../../../utils/loader";
import ErrorCard from "../../../../../../error";

const SupplierAffiliationSupportAdd = ({affiliation, onSuccess}: {affiliation: models.Affiliation, onSuccess: (supports: models.AffiliationSupports) => void}) => {

    let initialValues: { files: File[] } = {files: []}
    const [error, setError] = useState(null)

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            files: Yup.array().of(Yup.mixed().test("fileSize", "The file is too large", (file: any) => {
                return file;
            })),
        }),
        onSubmit: (values, formikHelpers) => {
            const formData = new FormData();
            for (const key in values.files) {
                formData.append('files', values.files[key], values.files[key].name);
            }

            api.add(affiliation.id, formData)
                .then(resp => onSuccess(resp.data))
                .then(() => formikHelpers.resetForm())
                .catch(e => setError(e))
                .finally(() => formikHelpers.setSubmitting(false))
        }
    })

    return <form onSubmit={formik.handleSubmit}>
        <Dropzone accept={{
            'image/*': ['.jpg', '.jpeg', '.png', '.tif', '.gif', '.svg'],
            'video/*': ['.mp4', '.mov'],
            'application/pdf': ['.pdf']
        }} disabled={formik.isSubmitting} onDrop={acceptedFiles => formik.setFieldValue('files', [...formik.values.files, ...acceptedFiles])}>
            {({getRootProps, getInputProps}) => (
                <section className={'h-100'}>
                    <div className={'h-100'} {...getRootProps()}>
                        <input {...getInputProps()} className={'d-none'} />
                        <div className={'py-8 bg-body-secondary border border-1 text-center'}>
                            <i className={'bi bi-images'}></i> Déposez vos fichiers ici ou cliquez pour importer
                            <div className="clearfix"></div>
                            {!!formik.values.files.length && <ul className={'list-group text-center mt-3'}>
                                {formik.values.files.map((f: File) => <li className={'list-group-item'}>{f.name}</li>)}
                            </ul>}
                        </div>
                    </div>
                </section>
            )}
        </Dropzone>
        <FormError form={formik} field={'files'} />
        {!!formik.values.files.length && <button type={'submit'} disabled={!formik.values.files.length} className={"btn btn-dark w-100"}>
            {formik.isSubmitting ? <Loader /> : <><i className={'bi bi-check'}></i> Importer les fichiers</>}
        </button>}
        {error && <ErrorCard error={error} />}
    </form>
}

export default SupplierAffiliationSupportAdd;