import {Quote, QuoteRow} from "../../../../../../models/quote";
import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import FormError from "../../../../../../utils/formError";
import Loader from "../../../../../../utils/loader";
import SupplierTaxRateSelect from "../../../taxRate/select";
import SupplierUnitSelect from "../../../unit/select";
import * as api from "../../../../../../adapters/corporate/supplier/quote/row"
import SupplierProductSelect from "../../../product/select";

const SupplierQuoteRowAdd = ({handleSuccess, quote}: {handleSuccess: (row: QuoteRow) => void, quote: Quote}) => {

    const [initialValues, setInitialValues] = useState<any>({
        type: "custom",
        name: "",
        description: "",
        amountExcludingTax: 0,
        shippable: false,
        tax_rate_id: null,
        quantity: 1,
        unit_id: null
    });

    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        type: Yup.string().required(),
        name: Yup.string().required(),
        description: Yup.string().required(),
        amountExcludingTax: Yup.number().required().min(0.01),
        shippable: Yup.boolean().required(),
        tax_rate_id: Yup.string().nullable(),
        quantity: Yup.number().required(),
        unit_id: Yup.number().nullable()
    }))

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            api.add(quote.id, values)
                .then(r => handleSuccess(r.data))
                .catch(error => console.log(error))
                .finally(() => formikHelpers.setSubmitting(false))
        }
    })

    function handleTypeChange(type: "custom"|"product"){

        switch (type) {
            case "product":
                setInitialValues((prev: any) => ({type: "product", product_id: null, quantity: prev.quantity}))
                setValidationSchema(Yup.object().shape({
                    type: Yup.string().required(),
                    product_id: Yup.number().required(),
                    quantity: Yup.number().required().min(1),
                }))
                break;
            case "custom":
                setInitialValues((prev: any) => ({
                    type: "custom",
                    name: "",
                    description: "",
                    amountExcludingTax: 0,
                    shippable: false,
                    tax_rate_id: null,
                    quantity: prev.quantity,
                    unit_id: null
                }))
                setValidationSchema(Yup.object().shape({
                    type: Yup.string().required(),
                    name: Yup.string().required(),
                    description: Yup.string().required(),
                    amountExcludingTax: Yup.number().required().min(1),
                    shippable: Yup.boolean().required(),
                    tax_rate_id: Yup.string().nullable(),
                    quantity: Yup.number().required(),
                    unit_id: Yup.number().nullable()
                }))
                break;
        }


    }

    return <form onSubmit={formik.handleSubmit}>
        {formik.values.type === "product" && <div className={'col-12 mb-3'}>
            <SupplierProductSelect required={true} handleChange={productId => formik.setFieldValue('product_id', productId)} />
        </div>}
        {formik.values.type === "custom" && <>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input className="form-control" type="text" id="name" name={"name"} onChange={formik.handleChange} />
                    <label htmlFor="name">
                        Nom *
                    </label>
                </div>
                <FormError form={formik} field={"name"} />
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input className="form-control" type="text" id="description" name={"description"} onChange={formik.handleChange} />
                    <label htmlFor="description">
                        Description
                    </label>
                </div>
                <FormError form={formik} field={"description"} />
            </div>
            <div className="col-12 mb-3">
                <div className="form-check form-switch">
                    <input className="form-check-input" onChange={e => formik.setFieldValue("shippable", e.target.checked)} type="checkbox" role="switch" id="shippable" />
                    <label className="form-check-label" htmlFor="shippable">
                        Produit livrable
                    </label>
                </div>
                <FormError form={formik} field={"shippable"} />
            </div>
            <div className="col-12 mb-3">
                <div className="input-group">
                    <div className="form-floating">
                        <input className="form-control" type="number" step={0.01} id="amountExcludingTax" name={"amountExcludingTax"} onChange={formik.handleChange} />
                        <label htmlFor="amountExcludingTax">
                            Prix HT
                        </label>
                    </div>
                    <span className="input-group-text">
                                {quote.currency.symbol}
                            </span>
                </div>
                <FormError form={formik} field={"amountExcludingTax"} />
            </div>
            <div className="col-12 mb-3">
                <SupplierTaxRateSelect required={false} handleChange={tax_rate_id => formik.setFieldValue('tax_rate_id', tax_rate_id)} allowAdd={true} />
            </div>
            <div className="col-12 mb-3">
                <SupplierUnitSelect required={false} handleChange={unit_id => formik.setFieldValue('unit_id', unit_id)} allowAdd={true} />
            </div>
        </>}
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input className="form-control" type="number" id="quantity" name={"quantity"} onChange={formik.handleChange} />
                <label htmlFor="quantity">
                    Quantité
                </label>
            </div>
            <FormError form={formik} field={"quantity"} />
        </div>
        <button disabled={formik.isSubmitting} type={'submit'} className={"btn btn-primary w-100"}>
            {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-plus-circle'}></i> Ajouter</>}
        </button>
    </form>
}

export default SupplierQuoteRowAdd;