import React, {useEffect, useState} from "react";
import * as api from "../../../../../adapters/corporate/supplier/subscription";
import SearchInput from "../../../../../utils/searchInput";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";
import {Subscriptions} from "../../../../../models/subscription";
import ErrorCard from "../../../../error";
import SupplierSubscriptionListItem from "./item";
import {CustomerAccount} from "../../../../../models/account";
import SupplierSubscriptionAdd from "../add";
import OffcanvasComponent from "../../../../offcanvas";

const SupplierSubscriptionList = ({customer}: {customer?: CustomerAccount}) => {

    const [payload, setPayload] = useState<{ count: number, rows: Subscriptions }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number}>({query: "", offset: 0})
    const [error, setError] = useState();
    const [showAddOffcanvas, setShowAddOffcanvas] = useState(false)

    useEffect(() => {
        setLoading(true)
        api.list({offset: fetchParams.offset, query: fetchParams.query, customer_id: customer?.id})
            .then(resp => setPayload(resp.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher un abonnement..."} onChange={handleQueryChange} size={"default"} />
                </div>
                <div className="col-auto">
                    <button type={"button"} className="btn btn-xs btn-primary rounded" onClick={() => setShowAddOffcanvas(true)}>
                        <i className={'bi bi-plus-circle'}></i> Ajouter
                    </button>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        <div className="col-md-12 flex-grow-1">
            {error && <ErrorCard error={error} />}
            <div className={'table-responsive'}>
                <table className={"table table-hover"}>
                    <thead>
                        <tr>
                            <th>
                                Client
                            </th>
                            <th>
                                Vendeur
                            </th>
                            <th>
                                Statut
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {payload.rows.length ? payload.rows.map(r => <SupplierSubscriptionListItem item={r} key={r.id} />) : <tr className={'text-muted'}>
                            <i className={'bi bi-search'}></i> Aucun résultat
                        </tr>}
                    </tbody>
                </table>

            </div>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        </div>
        {showAddOffcanvas && <OffcanvasComponent title={"Ajouter un abonnement"} show={showAddOffcanvas}
                             onHide={() => setShowAddOffcanvas(false)} size={'mw-75'}>
            <SupplierSubscriptionAdd
                defaultValues={{customer: customer}}
                onSuccess={subscription => {
                    setPayload(prevState => ({count: prevState.count + 1, rows: [subscription, ...prevState.rows]}))
                    setShowAddOffcanvas(false)
                }}
            />
        </OffcanvasComponent>}
    </div>
}

export default SupplierSubscriptionList;