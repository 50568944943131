import React, {useEffect, useState} from "react";
import {Product, Products, SellableProduct} from "../../../../../../models/product";
import * as productApi from "../../../../../../adapters/corporate/provider/product";
import * as api from "../../../../../../adapters/corporate/provider/shop/product";
import SearchInput from "../../../../../../utils/searchInput";
import Loader from "../../../../../../utils/loader";
import {getCoverUrl, getLogoUrl} from "../../../../../../utils/image";
import Pagination from "../../../../../../utils/pagination";
import {Shop} from "../../../../../../models/shop";
import convertToDecimal from "../../../../../../utils/currencyConverter";
import ErrorCard from "../../../../../error";
import {Payload} from "../../../../../../models/global";

const ProviderShopShowProductSelector = ({shop, handleLink, handleUnlink}: {shop: Shop, handleLink: (selected: Product & SellableProduct) =>  void, handleUnlink: (selected: Product & SellableProduct) =>  void}) => {

    const [payload, setPayload] = useState<Payload<(Product & SellableProduct)[]>>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number}>({query: "", offset: 0})
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true)
        productApi.list({offset: fetchParams.offset, query: fetchParams.query, linked_shop_id: shop.id, sellable: true})
            .then(resp => setPayload(resp.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    function handleChange(product: Product & SellableProduct, checked: boolean){
        if (checked){
            api.link(shop.id, product.id).then(() => handleLink(product))
        }else{
            api.unlink(shop.id, product.id).then(() => handleUnlink(product))
        }
    }

    return (
        <>
            <div className="col-12 mb-3 flex-grow-0">
                <div className="row align-items-center">
                    <div className="col">
                        <SearchInput placeholder={"Rechercher un produit..."} onChange={handleQueryChange} size={"default"} />
                    </div>
                </div>
            </div>
            <div className="col-12 mb-3 text-center">
                {loading ? <Loader /> : <>{payload.count} résultats</>}
            </div>
            {error && <ErrorCard error={error} />}
            <div className="col-md-12 flex-grow-1">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>

                            </th>
                            <th>
                                Produit
                            </th>
                            <th>
                                Catégorie
                            </th>
                            <th>
                                Fournisseur
                            </th>
                            <th>
                                Prix
                            </th>
                            <th>
                                Commission
                            </th>
                            <th>
                                Score
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {payload.rows.map(r => <tr>
                            <td>
                                <div className="form-check">
                                    <input defaultChecked={r.shopLinked} onChange={e => handleChange(r, e.target.checked)} className="form-check-input" type="checkbox" id="flexCheckDefault" />
                                </div>
                            </td>
                            <td>
                                <div className="avatar avatar-lg">
                                    <img src={getCoverUrl(r)} alt={r.name} className={"avatar-img"} />
                                </div>
                                <span className={'fw-semibold'}>
                                        {r.name}
                                    </span>
                            </td>
                            <td>
                                {r.category?.name || "Non catégorisé"}
                            </td>
                            <td>
                                <div className="avatar avatar-sm me-2">
                                    <img src={getLogoUrl(r.organization)} alt={r.organization.name} className="avatar-img rounded-circle" />
                                </div>
                                {r.organization.name}
                            </td>
                            <td>
                                {convertToDecimal(r.price.amount, r.price.currency)}
                            </td>
                            <td className={'text-success'}>
                                {r.price.feePercent}%
                            </td>
                            <td className={'text-primary'}>
                                <i className={"bi bi-score"}></i> {r.ranking?.score || 0}
                            </td>
                        </tr>)}
                        </tbody>
                    </table>
                </div>
                <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
            </div>
        </>
    )
}

export default ProviderShopShowProductSelector;