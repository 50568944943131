import React, {useRef, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../../../adapters/corporate/common/chat/message";
import * as models from "../../../../../../../models/chat";
import Dropzone from "react-dropzone";

const ChatShowMessageAdd = ({chat, onSuccess, handleFocus}: {chat: models.Chat, onSuccess: (message: models.ChatMessage) => void, handleFocus: () => void}) => {

    const textBox = useRef<HTMLDivElement|null>(null);
    const [error, setError] = useState();

    const formik = useFormik({
        initialValues: {
            content: "",
            files: []
        } as {content: string, files: File[]},
        validationSchema: Yup.object().shape({
            content: Yup.string().required(),
            files: Yup.array()
                .of(
                    Yup.mixed()
                        .test(
                            'fileSize',
                            'The file is too large',
                            (value: any) => !value || (value && value.size <= 1024 * 1024 * 5)
                        )
                        .test(
                            'fileFormat',
                            'Unsupported file format',
                            (value: any) => !value || (value && ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(value.type))
                        )
                )
                .max(4, 'You can only upload up to 4 files.'),
        }),
        onSubmit: (values, {setSubmitting, resetForm}) => {
            let formData = new FormData();
            formData.append("content", values.content)

            if (values.files.length){
                for (const fKey in values.files) {
                    formData.append("files", values.files[fKey], values.files[fKey].name)
                }
            }

            api.add(chat.id, formData)
                .then(r => {
                    onSuccess(r.data)
                    if (textBox.current){
                        textBox.current.innerText = ""
                    }

                    resetForm();
                })
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    })

    const handleKeyDown = async (evt: React.KeyboardEvent<HTMLParagraphElement>) => {
        if (evt.key === 'Enter') {
            if(!evt.shiftKey){
                await formik.submitForm();
            }
        }
    }

    function removeFile(index: number){
        let files = [...formik.values.files];
        files.splice(index, 1)
        formik.setFieldValue('files', files);
    }

    return <form onSubmit={formik.handleSubmit} style={{borderRadius: 30}} className={"bg-body-secondary p-3 position-relative"}>
        {formik.values.files.length > 0 && <div className={'col-12 mb-3'}>
            <div className="row">
                {formik.values.files.map((f, index) => <div className={'col-auto'}>
                    <div className="position-relative">
                        <img style={{height: 100, width: "auto"}} src={URL.createObjectURL(f)} alt={f.name} className={'img-fluid rounded'} />
                        <button onClick={() => removeFile(index)} type={'button'} style={{top: 5, right: 5}} className={'btn btn-xxs btn-circle post-pic-preview-close text-white position-absolute'}>
                            <i className={'bi bi-x'}></i>
                        </button>
                    </div>
                </div>)}
            </div>
        </div>}
        <div className="row">
            <div className="col-auto d-flex align-items-start">
                <Dropzone accept={{
                    'image/*': ['.jpg', '.jpeg', '.png', '.tif'],
                    'video/*': ['.mp4', '.mov'],
                }} disabled={formik.isSubmitting} onDrop={acceptedFiles => formik.setFieldValue('files', [...formik.values.files, ...acceptedFiles])}>
                    {({getRootProps, getInputProps}) => (
                        <button type={"button"} className="btn btn-xxs rounded-pill btn-light me-2" {...getRootProps()}>
                            <input {...getInputProps()} className={'d-none'} />
                            <i className={'bi bi-image'}></i>
                        </button>
                    )}
                </Dropzone>
            </div>
            <div className="col position-relative px-0">
                <p ref={textBox}
                   onFocusCapture={() => handleFocus()}
                   onKeyDown={handleKeyDown}
                   className={"flex-grow-1 position-relative custom-text-area mb-0" + (!formik.values.content.length ? " text-muted " : "")}
                   style={{minHeight: 30, zIndex: 1}}
                   contentEditable={true}
                   autoFocus={true}
                   onInput={e => formik.setFieldValue("content", e.currentTarget.innerText)}
                   onBlur={e => {
                        formik.setFieldValue("content", e.currentTarget.innerText)
                    }}
                >
                </p>
                {!formik.values.content.length && <div style={{zIndex: 0}} onMouseDown={() => textBox.current?.focus()} className="position-absolute text-muted start-0 top-0">
                    Ecrivez un message...
                </div>}
            </div>
            <div className="col-auto d-flex align-items-end">
                <button disabled={formik.isSubmitting} className={'btn btn-xxs btn-circle btn-primary'} type={'submit'}>
                    <i className={'bi bi-send'}></i>
                </button>
            </div>
        </div>
    </form>
}

export default ChatShowMessageAdd;