import {get, MULTIPART_FORM_DATA, post} from "../../../../xhr";

export function add(pid: number, requestData: FormData){
    return post(`/corporate/supplier/product/${pid}/file`, requestData, {headers: [MULTIPART_FORM_DATA]});
}

export function remove(pid: number, fid: number){
    return get(`/corporate/supplier/product/${pid}/file/${fid}/remove`);
}

export function cover(pid: number, fid: number){
    return get(`/corporate/supplier/product/${pid}/file/${fid}/cover`);
}

export function declinationChoice(pid: number, fid: number, cid: number){
    return get(`/corporate/supplier/product/${pid}/file/${fid}/choice/${cid}`);
}