import React, {useEffect, useState} from "react";
import {Partnerships} from "../../models/partnership";
import * as api from "../../adapters/corporate/common/partnership";
import Select, {Option} from "../select";

const PartnershipSelect = ({handleChange, required, pkey, defaultValue}: {handleChange: (id: number) => void, required?: boolean, pkey: "provider"|"supplier", defaultValue?: Option}) => {

    const [partnerships, setPartnerships] = useState<{count: number, rows: Partnerships}>({rows: [], count: 0})
    const [loading, setLoading] = useState(false);
    const [fetchParams, setFetchParams] = useState<{query: string, offset: number}>({query: "", offset: 0})

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(r => setPartnerships(r.data))
            .then(() => setLoading(false))
    }, [fetchParams])

    return <Select
        multiple={false}
        disabled={loading}
        required={required}
        label={`Partenaire ${required ? "*" : '(optionnel)'}`}
        value={defaultValue}
        options={partnerships.rows.map(s => ({label: s[pkey].name, value: s[pkey].id}))}
        onChange={(c: any) => handleChange(c.value)}
        handleScrollBottom={() => setFetchParams(p => ({...p, offset: p.offset + 1}))}
        handleQueryChange={(query) => setFetchParams(p => ({query: query, offset: 0}))}
    />
}

export default PartnershipSelect;