import React, {useState} from "react";
import * as models from "../../../../../../models/link";
import {Link} from "react-router-dom";
import {APP_URL} from "../../../../../../constants/global";

const ProviderLinkShowSend = ({link}: {link: models.Link}) => {

    const [copied, setCopied] = useState(false)

    let timer: NodeJS.Timeout;
    function copyLink(){
        clearTimeout(timer);
        setCopied(true)
        navigator.clipboard.writeText(`${APP_URL}link/${link.uuid}`);
        timer = setTimeout(() => setCopied(false), 1500);
    }

    return <div className={"col-12 mb-3"}>
        <div className="card border border-1">
            <div className="card-header">
                <div className="row align-items-center">
                    <div className="col">
                        <h6>
                            Lien
                        </h6>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-xs btn-outline-dark" onClick={() => copyLink()}>
                            {copied ? <><i className={'bi bi-check'}></i> Lien copié</> : <><i className={'bi bi-link'}></i> Copier le lien</>}
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <Link to={`${APP_URL}link/${link.uuid}`} target={'_blank'}>
                    {`https://app.varar.co/link/${link.uuid}`}
                </Link>
            </div>
        </div>
    </div>
}

export default ProviderLinkShowSend;