import {getLogoUrl} from "../../../../../../utils/image";
import CommonClickStatus from "../../../../common/click/status";
import React from "react";
import {Click, Clicks} from "../../../../../../models/affiliation";
import {Link} from "react-router-dom";

const SupplierClickListItem = ({status, handleSelect, selected, item}: {status: string, handleSelect: (click: Click) => void, selected: Clicks, item: Click}) => {

    return <tr>
        {["Pending", "Accepted"].includes(status) && <td>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id={`click_${item.id}`} onChange={() => handleSelect(item)} checked={selected.map(s => s.id).includes(item.id)} />
            </div>
        </td>}
        <td>
            <Link to={`/corporate/supplier/affiliation/${item.affiliation.id}`}>
                {item.affiliation.name}
            </Link>
        </td>
        <td>
            {item.rule.name}
        </td>
        <td>
            <span className="avatar avatar-sm">
                <img src={getLogoUrl(item.partnership.provider)} alt={item.partnership.provider.name} className="avatar-img rounded-circle" />
            </span>
            <span>
                {item.partnership.provider.name}
            </span>
        </td>
        <td>
            {new Date(item.createdAt).toLocaleDateString()} à {new Date(item.createdAt).toLocaleTimeString()}
        </td>
        <td>
            <CommonClickStatus status={item.status} />
        </td>
        <td>
            <button className="btn btn-xxs byn-light">
                <i className={'bi bi-eye'}></i>
            </button>
        </td>
    </tr>
}

export default SupplierClickListItem;