import FormError from "../../../../../utils/formError";
import Loader from "../../../../../utils/loader";
import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../adapters/corporate/supplier/returns/address";
import Select from "../../../../../utils/select";
import {getCountries} from "../../../../../utils/countries";
import {ReturnAddress} from "../../../../../models/returns";
import ErrorCard from "../../../../error";
import {getCountryCodes} from "../../../../../utils/tel";

const SupplierReturnsAddressAdd = ({handleSuccess}: {handleSuccess: (address: ReturnAddress) => void}) => {

    const [error, setError] = useState(null)

    const formik = useFormik({
        initialValues: {
            countryCode: "",
            country_id: "",
            tel: "",
            city: '',
            postalCode: '',
            line1: '',
            line2: '',
        },
        validationSchema: Yup.object().shape({
            country_id: Yup.number().required().min(1),
            countryCode: Yup.string().required(),
            tel: Yup.string().required(),
            city: Yup.string().required(),
            postalCode: Yup.string().required(),
            line1: Yup.string().required(),
            line2: Yup.string().nullable(),
        }),
        onSubmit: (values, formikHelpers) => {
            api.add({...values})
                .then(r => handleSuccess(r.data))
                .catch(error => setError(error))
                .finally(() => formikHelpers.setSubmitting(false))
        }
    })

    return <form onSubmit={formik.handleSubmit}>
        {error && <ErrorCard error={error} />}
        <div className="col-12 mb-3">
            <div className="input-group">
                <Select
                    label={"Code pays"}
                    required={true}
                    options={getCountryCodes()}
                    onChange={c => formik.setFieldValue('countryCode', c.value)}
                />
                <div className="form-floating">
                    <input type="text" className="form-control" name={"tel"} id={"tel"} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                    <label htmlFor="tel">
                        Tel
                    </label>
                </div>
            </div>
            <FormError form={formik} field={"countryCode"} />
            <FormError form={formik} field={"tel"} />
        </div>
        <div className="col-12 mb-3">
            <Select
                required={true}
                label={"Pays *"}
                options={getCountries()}
                onChange={o => formik.setFieldValue('country_id', o.id)}
            />
            <FormError form={formik} field={"country_id"} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className={'form-control'} name={"line1"} id={"line1"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                <label htmlFor="line1">
                    Addresse *
                </label>
            </div>
            <FormError form={formik} field={"line1"} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className={'form-control'} name={"line2"} id={"line2"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                <label htmlFor="line2">
                    Complément d'addresse (optionel)
                </label>
            </div>
            <FormError form={formik} field={"line2"} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className={'form-control'} name={"city"} id={"city"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                <label htmlFor="city">
                    Ville
                </label>
            </div>
            <FormError form={formik} field={"city"} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className={'form-control'} name={"postalCode"} id={"postalCode"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                <label htmlFor="postalCode">
                    Code postale
                </label>
            </div>
            <FormError form={formik} field={"postalCode"} />
        </div>
        <button className="btn btn-dark w-100 mb-3" type={"submit"}>
            {formik.isSubmitting ? <Loader /> : <><i className={'bi bi-check'}></i> Valider</>}
        </button>
    </form>
}

export default SupplierReturnsAddressAdd;