import * as models from "../../../../../../../models/chat";
import * as api from "../../../../../../../adapters/corporate/common/chat/message";
import React, {useEffect, useRef, useState} from "react";
import * as chatApi from "../../../../../../../adapters/corporate/common/chat";
import {socket} from "../../../../../../../socket";
import ChatMessageListItem from "./item";
import ChatShowMessageAdd from "../add";
import Loader from "../../../../../../../utils/loader";
import ErrorCard from "../../../../../../error";

const useUpdatedRef = (value: any) => {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref;
};

const ChatMessageList = ({chat}: {chat: models.Chat}) => {

    const [loading, setLoading] = useState(true);
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string}>({offset: 0, query: ""});
    const [payload, setPayload] = useState<{count: number, rows: models.ChatMessages}>({count: 0, rows: []});
    const [error, setError] = useState(null);
    const [isAttach, setIsAttach] = useState(true);
    const messageContainer = useRef<HTMLDivElement>(null);
    const loadingRef = useUpdatedRef(loading);
    const payloadRef = useUpdatedRef(payload);

    useEffect(() => {
        socket.connect();
        socket.on(`message.received.${chat.id}`, handleMessageReceived);
        socket.on(`message.update.${chat.id}`, handleMessageUpdate);
        socket.on(`message.bulkUpdate.${chat.id}`, handleMessageBulkUpdate);
        socket.on(`message.deleted.${chat.id}`, handleMessageDeleted);

        return () => {
            socket.disconnect();
            socket.off(`message.received.${chat.id}`, handleMessageReceived);
            socket.off(`message.update.${chat.id}`, handleMessageUpdate);
            socket.off(`message.bulkUpdate.${chat.id}`, handleMessageBulkUpdate);
            socket.off(`message.deleted.${chat.id}`, handleMessageDeleted);
        };
    }, [chat.id]);

    const fetchMessages = () => {
        const container = messageContainer.current;
        const currentScrollHeight = container ? container.scrollHeight : 0;
        const currentScrollTop = container ? container.scrollTop : 0;

        setLoading(true);
        setError(null);
        api.list(chat.id, fetchParams)
            .then(r => {
                setPayload(prev => {
                    const newRows = [...r.data.rows.reverse(), ...prev.rows];
                    if (container) {
                        const newScrollHeight = container.scrollHeight;
                        container.scrollTop = currentScrollTop + (newScrollHeight - currentScrollHeight);
                    }
                    return { count: r.data.count, rows: newRows };
                });
            })
            .catch(e => setError(e))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchMessages();
    }, [chat.id, fetchParams]);

    useEffect(() => {
        if (isAttach) {
            scrollToBottom();
        }
    }, [payload.rows.length]);

    const seen = async () => {
        await chatApi.reads(chat.id)
            .then(resp => handleMessageBulkUpdate(resp.data));
    };

    const scrollToBottom = async () => {
        if (messageContainer.current) {
            messageContainer.current.scrollIntoView({ behavior: "smooth" });
            messageContainer.current.scrollTop = messageContainer.current.scrollHeight;
            setIsAttach(true);
            await seen();
        }
    };

    let offsetTimer: NodeJS.Timer;
    const handleScroll = async () => {
        if (messageContainer.current) {
            const scrollTop = messageContainer.current.scrollTop;
            const scrollHeight = messageContainer.current.scrollHeight;
            const innerHeight = messageContainer.current.clientHeight;

            const scrolledBottom = scrollTop + innerHeight + 10 >= scrollHeight;

            if (scrolledBottom) {
                if (!isAttach) {
                    await seen();
                    setIsAttach(true);
                }
            } else {
                setIsAttach(false);
            }

            if (scrollTop < 150) {
                clearTimeout(offsetTimer)
                const hasMoreResults = payloadRef.current.rows.length < payloadRef.current.count;
                console.log(payloadRef.current.rows.length, payloadRef.current.count)
                if (!loadingRef.current && hasMoreResults) {
                    setLoading(true)
                    offsetTimer = setTimeout(() => setFetchParams(prev => ({...prev, offset: prev.offset + 1})), 250)
                }
            }
        }
    };

    const handleMessageReceived = (message: models.ChatMessage) => {
        setPayload(prev => ({count: prev.count + 1, rows: [...prev.rows, message]}));
    };

    const handleMessageUpdate = (message: models.ChatMessage) => {
        setPayload(prev => ({count: prev.count + 1, rows: [...prev.rows.map(oldMessage => oldMessage.id === message.id ? message : oldMessage)]}));
    };

    const handleMessageBulkUpdate = (messages: models.ChatMessages) => {
        setPayload(prev => ({
            count: prev.count,
            rows: prev.rows.map(oldMessage => {
                const updatedMessage = messages.find(msg => msg.id === oldMessage.id);
                return updatedMessage ? updatedMessage : oldMessage;
            })
        }));
    };

    const handleMessageDeleted = (message: models.ChatMessage) => {
        setPayload(prev => ({count: prev.count + 1, rows: [...prev.rows.filter(oldMessage => oldMessage.id !== message.id)]}));
    };

    return (
        <>
            <div className={"card-body position-relative overflow-auto h-100 w-100"} ref={messageContainer} onScroll={handleScroll}>
                {chat.type === "group" && payload.rows.length >= payload.count && <div className={"row justify-content-center"}>
                    <div className="col-auto">
                        <div className={'alert alert-light'}>
                            Conversation créé le {new Date(chat.createdAt).toLocaleDateString()} à {new Date(chat.createdAt).toLocaleTimeString()}
                        </div>
                    </div>
                </div>}
                {loading && <div className={'col-12 py-5 text-center'}><Loader /></div>}
                {payload.count ? payload.rows.map((m, i) => <ChatMessageListItem key={m.id} chat={chat} message={m} next={payload.rows[i + 1]} />) : (loading ? <></> : <div className={'alert alert-info w-100'}><i className={'bi bi-search'}></i> Aucun message</div>)}
                {error && <ErrorCard error={error} />}
                {!isAttach && <button style={{bottom: 20, right: 20}} className={'btn btn-light btn-xs position-fixed'} onClick={() => scrollToBottom()}>
                    <i className={'bi bi-arrow-down-circle'}></i>
                </button>}
            </div>
            <div className={'card-footer bg-white'}>
                <ChatShowMessageAdd chat={chat} onSuccess={message => handleMessageReceived(message)} handleFocus={() => scrollToBottom()} />
            </div>
        </>
    );
};

export default ChatMessageList;