import React, {useEffect, useState} from "react";
import * as api from "../../../../adapters/common/paymentMethod";
import Loader from "../../../../utils/loader";
import Pagination from "../../../../utils/pagination";
import {PaymentMethod, PaymentMethods} from "../../../../models/paymentMethod";
import ModalComponent from "../../../modal";
import CommonPaymentMethodAdd from "../add";

const CommonPaymentMethodSelect = ({selected, handleSelect}: {selected?: PaymentMethod, handleSelect: (pm: PaymentMethod) => void}) => {

    const [payload, setPayload] = useState<{ count: number, rows: PaymentMethods }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ offset: number}>({offset: 0})
    const [showAddModal, setShowAddModal] = useState(false)

    useEffect(() => {
        setLoading(true)
        api.list({offset: fetchParams.offset})
            .then(resp => setPayload(resp.data))
            .then(() => setLoading(false))
    }, [fetchParams])

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="row align-items-center">
            <div className="col mb-3">
                {loading ? <Loader /> : <>{payload.count} résultats</>}
            </div>
            <div className="col-auto mb-3">
                <button className={"btn btn-xxs btn-light"} onClick={() => setShowAddModal(true)}>
                    <i className={'bi bi-plus'}></i> Ajouter un moyen de paiement
                </button>
            </div>
        </div>
        <div className="col-md-12 flex-grow-1">
            {!loading && <ul className={'list-group'}>
                {payload.rows.map(r => <li className={"list-group-item"}>
                    <div className="form-check">
                        <input checked={selected?.id === r.id} onChange={() => handleSelect(r)} className="form-check-input" type="radio" name="paymentMethod" id={`pm_${r.id}`} />
                        <label className="form-check-label" htmlFor={`pm_${r.id}`}>
                            {r.brand}, **** **** **** {r.last4}, expire en {r.expMonth}/{r.expYear}
                        </label>
                    </div>
                </li>)}
            </ul>}
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        </div>
        <ModalComponent title={"Ajouter un moyen de paiement"} show={showAddModal} onHide={() => setShowAddModal(false)}>
            <CommonPaymentMethodAdd onSuccess={pm => {
                setShowAddModal(false)
                setPayload(prevState => ({count: prevState.count + 1, rows: [pm, ...prevState.rows]}))
                handleSelect(pm)
            }} />
        </ModalComponent>
    </div>
}

export default CommonPaymentMethodSelect;