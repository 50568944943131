import FormError from "../../../../../../../utils/formError";
import React from "react";
import {Parcel} from "../../../../../../../models/order";
import convertToDecimal from "../../../../../../../utils/currencyConverter";

const SupplierOrderRefundAddParcel = ({parcel, index, formik}: {parcel: Parcel, index: number, formik: any}) => {

    return <li key={index} className={'list-group-item'}>
        <div className="row">
            <div className="col-md-6 mb-3">
                <label className="form-check-label" htmlFor={`items[${index}].included`}>
                    <p className={'mb-2 fw-semibold'}>
                        {parcel.trackingNumber}
                    </p>
                    <p className={'mb-0'}>
                        Montant TTC: {convertToDecimal(parcel.options[0].priceData.amount_including_tax, parcel.options[0].priceData.currency)}
                    </p>
                </label>
            </div>
            <div className="col-md-6 mb-3">
                <div className="form-check form-switch">
                    <input className="form-check-input" onChange={e => formik.setFieldValue(`items[${index}].included`, e.target.checked)} type="checkbox" role="switch" id={`items[${index}].included`} />
                </div>
                <FormError form={formik} field={`items[${index}].included`} />
            </div>
        </div>
    </li>
}

export default SupplierOrderRefundAddParcel;