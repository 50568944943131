import React from 'react';

interface OffcanvasProps {
    title: string;
    show: boolean;
    size?: 'mw-50'|'mw-75'
    onHide: () => void;
    children: React.ReactNode;
    footerContent?: React.ReactNode;
}

const OffcanvasComponent: React.FC<OffcanvasProps> = ({ title, show, size, onHide, children, footerContent }) => {

    return (
        <>
            <div className={`offcanvas offcanvas-end ${show ? 'show' : ''} ${size}`} tabIndex={-1}>
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title">
                        {title}
                    </h5>
                    <button type="button" className="btn-close btn-close" onClick={onHide}></button>
                </div>
                <div className="offcanvas-body overflow-auto">
                    {children}
                </div>
                {footerContent && <div className={"offcanvas-footer"}>
                    {footerContent}
                </div>}
            </div>
        </>
    );
};

export default OffcanvasComponent;
