import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import * as api from '../../../../../adapters/corporate/provider/affiliation';
import SearchInput from "../../../../../utils/searchInput";
import {Affiliations} from "../../../../../models/affiliation";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";
import {FetchParams, Payload} from "../../../../../models/global";
import {getLogoUrl} from "../../../../../utils/image";
import ErrorCard from "../../../../error";

const ProviderAffiliationList = () => {

    const [payload, setPayload] = useState<Payload<Affiliations>>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [fetchParams, setFetchParams] = useState<FetchParams>({query: "", offset: 0})

    useEffect(() => {
        setLoading(true)
        api.list({offset: fetchParams.offset, query: fetchParams.query})
            .then(resp => setPayload(resp.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher une affiliation..."} onChange={handleQueryChange} size={"default"} />
                </div>
            </div>
        </div>
        {error && <div className={'col-12 mb-3'}>
            <ErrorCard error={error} />
        </div>}
        <div className="col-md-12 flex-grow-1 position-relative">
            {loading && <div className={"h-100 w-100 position-absolute"}>
                <div className="row justifi-content-center">
                    <div className="col-auto py-10">
                        <Loader />
                    </div>
                </div>
            </div>}
            <div className={'table-responsive'}>
                <table className={'table'}>
                    <thead>
                    <tr>
                        <th>
                            Nom
                        </th>
                        <th>
                            Site
                        </th>
                        <td>
                            Devise
                        </td>
                        <td>
                            Pays
                        </td>
                        <td>
                            Fournisseur
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {payload.count ? payload.rows.map(a => <tr>
                        <td>
                            <Link className="col mb-3" to={`/corporate/provider/affiliation/${a.id}`}>{a.name}</Link>
                        </td>
                        <td>
                            {a.url}
                        </td>
                        <td>
                            {a.currencies.map(c => `${c.name} (${c.symbol})`).join(', ')}
                        </td>
                        <td>
                            {a.countries.map(c => `${c.name}`).join(', ')}
                        </td>
                        <td>
                            <span className="avatar avatar-sm">
                                <img src={getLogoUrl(a.organization)} alt={a.organization.name} className={'avatar-img rounded-circle'}/>
                            </span>
                            <span>
                                {a.organization.name}
                            </span>
                        </td>
                    </tr>) : <tr>
                        <td className={"text-muted"} colSpan={5}>
                            <i className={'bi bi-search'}></i> Aucun résultat
                        </td>
                    </tr>}
                    </tbody>
                </table>
            </div>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        </div>
    </div>
}

export default ProviderAffiliationList;