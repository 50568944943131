import {post, get} from "../../../../../xhr";

export function list(subId: number, occurrenceId: number){
    return get(`corporate/supplier/subscription/${subId}/occurrence/${occurrenceId}/refund`)
}

export function add(subId: number, occurrenceId: number, requestData: any){
    return post(`corporate/supplier/subscription/${subId}/occurrence/${occurrenceId}/refund`, requestData);
}

