import encodeQueryData from "../../../../utils/encodeQueryData";
import {get} from "../../../xhr";

export function list(requestData: { query?: string, offset: number, status?: string, organization_id?: number}){
    return get(`corporate/supplier/touch${encodeQueryData(requestData)}`)
}

export function show(touch: number){
    return get(`corporate/supplier/touch/${touch}`)
}

export function accept(touch: number){
    return get(`corporate/supplier/touch/${touch}/accept`)
}

export function refuse(touch: number){
    return get(`corporate/supplier/touch/${touch}/refuse`)
}