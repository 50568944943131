import * as models from "../../../../../models/order";
import {getCoverUrl} from "../../../../../utils/image";
import ParcelStatusPlaceholder from "../../../../common/parcel/status/placeholder";
import convertToDecimal from "../../../../../utils/currencyConverter";
import React from "react";

const CustomerParcelShow = ({parcel}: {parcel: models.Parcel}) => {

    return <div className={'col-12 mb-3'}>
        <div className="card border border-1">
            <div className="card-header">
                <div className="row">
                    <div className="col">
                        <h6>
                            Colis N°{parcel.trackingNumber}
                        </h6>
                    </div>
                    <div className="col-auto">
                        <ParcelStatusPlaceholder status={parcel.status} />
                    </div>
                </div>
            </div>
            <div className="card-body">
                <table className={'table'}>
                    <tbody>
                        <tr>
                            <th>
                                Forfait
                            </th>
                            <td>
                                {parcel.options[0].name}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Frais de livraison
                            </th>
                            <td>
                                {convertToDecimal(parcel.options[0].priceData.amount_including_tax, parcel.options[0].priceData.currency)}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Envoyé le
                            </th>
                            <td>
                                {parcel.shippedAt ? new Date(parcel.shippedAt).toLocaleDateString() : <i className={'bi bi-x'}></i>}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Délivré le
                            </th>
                            <td>
                                {parcel.deliveredAt ? new Date(parcel.deliveredAt).toLocaleDateString() : <i className={'bi bi-x'}></i>}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h6>
                    Articles
                </h6>
                <div className={'row'}>
                    {parcel.items.map(i => <div className={'col-6 col-md-4 col-lg-3'}>
                        <div className="card border border-1">
                            <img src={`${getCoverUrl(i.item.price.product)}`} alt="" className="card-img-top br-0"/>
                            <div className="card-body bg-white">
                                <div className="row">
                                    <div className="col mb-3">
                                        <p className="small text-muted">
                                            {i.item.name}
                                        </p>
                                        <p className="fs-xs text-muted mb-0">
                                            Quantité: {i.quantity}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    </div>
}

export default CustomerParcelShow;