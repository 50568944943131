import {Products} from "../../../../../models/product";
import React, {useEffect, useState} from "react";
import * as api from "../../../../../adapters/corporate/provider/product";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import CommonProductListItem from "../../../common/product/listItem";
import Pagination from "../../../../../utils/pagination";
import {Sectors} from "../../../../../models/sector";

const ProviderSearchProduct = ({sectors, query, sort}: {sectors: Sectors, query: string, sort: "ranking"|'latest'|'trending'}) => {

    const [loading, setLoading] = useState(true)
    const [payload, setPayload] = useState<{count: number, rows: Products}>({count: 0, rows: []});
    const [offset, setOffset] = useState(0)
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        api.list({offset: offset, sectors: sectors.map(s => s.id), query: query, sort: sort})
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [sectors, query, offset])

    if (loading){
        return <div className={'container-fluid py-10 text-center'}>
            <Loader />
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }


    if (!payload.count){
        return <div className={'container-fluid py-10 text-center'}>
            <h6 className="text-muted">
               <i className={"bi bi-search"}></i> Aucun réultat
            </h6>
        </div>
    }

    return <>
        <div className="row row-cols-2 row-cols-md-3">
            {payload.rows.map(r => <CommonProductListItem item={r} />)}
        </div>
        <Pagination count={payload.count} offset={offset} setOffset={o => setOffset(o)} />
    </>
}

export default ProviderSearchProduct;