import * as models from "../../../../../models/quote";
import React, {useEffect, useState} from "react";
import {CustomerAddresses, CustomerAddress} from "../../../../../models/account";
import CustomerAddressAdd from "../../../../customer/address/add";
import ModalComponent from "../../../../modal";
import * as addressApi from "../../../../../adapters/customer/address";
import * as api from "../../../../../adapters/quote";
import Pagination from "../../../../../utils/pagination";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";

const QuoteAddressBillingSelector = ({quote, setQuote}: {quote: models.Quote, setQuote: React.Dispatch<any>}) => {

    const [showSelector, setShowSelector] = useState(!quote.billing);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [payload, setPayload] = useState<{count: number, rows: CustomerAddresses}>({count: 0, rows: []})
    const [offset, setOffset] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true)
        const params = quote.shippable ? {offset: offset} : {offset: offset, country_id: quote.project.country.id, postalCode: quote.project.postalCode}
        addressApi.list(params)
            .then(r => {
                setPayload(r.data)
            })
            .catch(e => setError(e))
            .finally(() => setLoading(false));
    }, [offset])

    function handleChange(address: CustomerAddress){
        setSubmitting(true)
        api.billing(quote.uuid, address.id)
            .then(r => {
                setQuote((prev: models.Quote) => ({...prev, billing: r.data}))
                setShowSelector(false)
            })
            .catch(e => setError(e))
            .finally(() => setSubmitting(false))
    }

    if (loading)
    {
        return <div className={"col-12 mb-3"}><Loader size={"sm"} /> Chargement des adresses...</div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    return <>
        {payload.count > 0 && <>
            {!showSelector && quote.billing && <>
                <div className={"card border border-1"}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                {quote.billing.line1}, {quote.billing.city}, {quote.billing.postalCode}, {quote.billing.country.name}
                            </div>
                            <div className="col-auto">
                        <span className={'cursor-pointer text-decoration-underline'}
                              onClick={() => setShowSelector(true)}>
                            <i className={'bi bi-pencil'}></i> Modifier
                        </span>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
            {(showSelector || !quote.billing) && <>
                <p>
                    Veuillez séléctionner une addresse ci-dessous
                </p>
                <ul className={'list-group mb-3'}>
                    {payload.rows.map((a, index) => <li key={index} className={'list-group-item list-group-item-action'} onClick={() => handleChange(a)}>
                        {a.line1}, {a.city}, {a.postalCode}, {a.country?.name} <i className={"bi bi-chevron-right"}></i>
                    </li>)}
                    <li className={'list-group-item'}>
                <span className={"text-decoration-underline cursor-pointer"} onClick={() => setShowModal(true)}>
                    Ajouter une adresse
                </span>
                    </li>
                </ul>
                <Pagination count={payload.count} offset={offset} setOffset={o => setOffset(o)} />
                <ModalComponent title={"Ajouter une adresse"} show={showModal} onHide={() => setShowModal(false)}>
                    <CustomerAddressAdd
                        values={{country: quote.project.country, city: quote.project.city, postalCode: quote.project.postalCode}}
                        onSuccess={address => {
                            setPayload(prev => ({count: prev.count + 1, rows: [address, ...prev.rows]}))
                            handleChange(address)
                            setShowModal(false)
                        }} />
                </ModalComponent>
            </>}
        </>}
        {payload.count === 0 && <CustomerAddressAdd
            values={{country: quote.project.country, city: quote.project.city, postalCode: quote.project.postalCode}}
            onSuccess={address => {
                setPayload(prev => ({count: prev.count + 1, rows: [address, ...prev.rows]}))
                handleChange(address)
                setShowModal(false)
            }} />}
    </>
}

export default QuoteAddressBillingSelector;