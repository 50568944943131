import React from "react";
import Cart from "../../cart";
import {ShopDetails} from "../../../models/shop";
import {useAppSelector} from "../../../hooks";

const ShopCart = ({shopDetails, uuid}: {shopDetails: ShopDetails, uuid: string}) => {

    const cart = useAppSelector(state => state.cart);

    if (cart){
        return <Cart source={"shop"} sourceId={uuid} country={shopDetails.country} currency={shopDetails.currency} />
    }

    return <div className={'col-12'}>
        <p className={"text-center text-muted py-5"}>
            Votre panier est vide.
        </p>
    </div>
}

export default ShopCart;