import {get} from "../../../../xhr";
import encodeQueryData from "../../../../../utils/encodeQueryData";

export function list(shop: number, requestData: {query: string, offset: number}){
    return get(`/corporate/provider/shop/${shop}/product${encodeQueryData(requestData)}`);
}

export function link(shop: number, product: number){
    return get(`/corporate/provider/shop/${shop}/product/${product}/link`);
}

export function unlink(shop: number, product: number){
    return get(`/corporate/provider/shop/${shop}/product/${product}/unlink`);
}