import {get} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query?: string, offset: number, from?: Date, to?: Date, number?: string}){
    return get(`corporate/common/invoice${encodeQueryData(requestData)}`);
}

export function show(invoice: number){
    return get(`corporate/common/invoice/${invoice}`);
}

export function download(invoice: number){
    return get(`corporate/common/invoice/${invoice}/download`);
}
