import React, {useState} from "react";
import * as models from "../../../../../../../../models/affiliation";
import * as api from "../../../../../../../../adapters/corporate/supplier/affiliation/support";
import ModalComponent from "../../../../../../../modal";
import FileViewer from "../../../../../../../fileViewer";
import {getAffiliationSupportUrl} from "../../../../../../../../utils/image";

const SupplierAffiliationSupportListItem = ({affiliation, item, handleDestroy}: {affiliation: models.Affiliation, item: models.AffiliationSupport, handleDestroy: (support: models.AffiliationSupport) => void}) => {

    const [showModal, setShowModal] = useState(false)

    function _destroy(){
        api.remove(affiliation.id, item.id)
            .then(r => handleDestroy(item))
    }

    return <tr>
        <td>{item.originalName}</td>
        <td>{new Date(item.createdAt).toLocaleDateString()}</td>
        <td>{item.mimeType}</td>
        <td>
            <button className={"btn btn-light btn-xxs me-2"} onClick={() => setShowModal(true)}>
                <i className={'bi bi-eye'}></i>
            </button>
            <ModalComponent size={"modal-xl"} title={item.originalName} show={showModal} onHide={() => setShowModal(false)}>
                <FileViewer url={getAffiliationSupportUrl(affiliation, item)} mimeType={item.mimeType} />
            </ModalComponent>
            <button className={"btn btn-light btn-xxs"} onClick={() => _destroy()}>
                <i className={'bi bi-dash-circle text-danger'}></i>
            </button>
        </td>
    </tr>
}

export default SupplierAffiliationSupportListItem;