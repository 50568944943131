import React, {useEffect, useState} from "react";
import * as models from "../../../../../../models/webhook";
import * as api from "../../../../../../adapters/corporate/supplier/webhook/event";
import SearchInput from "../../../../../../utils/searchInput";
import Loader from "../../../../../../utils/loader";
import ErrorCard from "../../../../../error";
import Pagination from "../../../../../../utils/pagination";

const SupplierWebhookEventList = ({webhook, active, setActive}: {webhook: models.Webhook, active?: models.WebhookEvent, setActive: React.Dispatch<any>}) => {

    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{query: string, offset: number}>({query: "", offset: 0});
    const [payload, setPayload] = useState<{count: number, rows: models.WebhookEvents}>({count: 0, rows: []});
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        setError(null)
        api.list(webhook.id, fetchParams)
            .then(r => setPayload(r.data))
            .catch(error => setError(error))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <>
        <div className="col-12 mb-3">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher un évènement..."} onChange={handleQueryChange} size={"sm"} />
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        {error && <ErrorCard error={error} />}
        <ul className={'list-group'}>
            {payload.rows.map(r => <li className={"list-group-item list-group-item-action " + (active?.id === r.id ? " active " : "")} onClick={() => setActive(r)}>
                <div className="d-flex align-items-center">
                    <span className="flex-grow-0">
                        <span className="badge bg-light text-dark me-2">
                            {r.responseStatus}
                        </span>
                    </span>
                    <span className="flex-grow-1">
                        {r.slug}
                    </span>
                    <span className="flex-grow-0 fs-xxs">
                        {new Date(r.createdAt).toLocaleDateString()} à {new Date(r.createdAt).toLocaleTimeString()}
                    </span>
                </div>
            </li>)}
        </ul>
        <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
    </>
}

export default SupplierWebhookEventList;