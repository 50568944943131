import React from "react";
import {LineItems} from "../../../../models/lineItem";
import CartItemShow from "../show";
import {Country} from "../../../../models/country";
import {Currency} from "../../../../models/currency";

const CartItemList = ({lineItems, country, currency, source, sourceId}: { lineItems: LineItems, country: Country, currency: Currency, source: "shop"|"link"|"pack", sourceId: string }) => {

    if (!lineItems.length){
        return <>
            <p className={"text-center text-muted py-5"}>
                Votre panier est vide.
            </p>
        </>
    }

    return <ul className="list-group list-group-lg list-group-flush overflow-auto">
        {lineItems.map(item => <CartItemShow source={source} sourceId={sourceId} item={item} key={item.id} country={country} currency={currency} />)}
    </ul>
}

export default CartItemList;