import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import * as api from '../../../../../../adapters/corporate/supplier/shipping/address';
import SearchInput from "../../../../../../utils/searchInput";
import Loader from "../../../../../../utils/loader";
import {ShippingAddresses} from "../../../../../../models/shipping";
import Pagination from "../../../../../../utils/pagination";
import ErrorCard from "../../../../../error";

const SupplierShippingAddressList = () => {

    const [payload, setPayload] = useState<{ count: number, rows: ShippingAddresses }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number}>({query: "", offset: 0})
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        api.list({offset: fetchParams.offset, query: fetchParams.query})
            .then(resp => setPayload(resp.data))
            .catch(error => setError(error))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    Expédition
                </li>
                <li className="breadcrumb-item">
                    Lieux de traitement
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher un lieu de traitement..."} onChange={handleQueryChange} size={"default"} />
                </div>
                <div className="col-auto">
                    <Link to={"/corporate/supplier/shipping/address/add"} className={'btn btn-dark'}>
                        <i className={'bi bi-plus-circle'}></i> Ajouter
                    </Link>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        {error && <ErrorCard error={error} />}
        <div className="col-md-12 flex-grow-1">
            {!loading && <div className={'table-responsive'}>
                <table className={'table'}>
                    <thead>
                    <tr>
                        <th>
                            Pays
                        </th>
                        <th>
                            Ville
                        </th>
                        <th>
                            Code postal
                        </th>
                        <th>
                            Adresse
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {payload.rows.map(a => <tr>
                        <td>
                            {a.country.flag && <img style={{height: 18, width: "auto"}} src={a.country.flag} alt={a.country.name}/>} {a.country.name}
                        </td>
                        <td>
                            {a.city}
                        </td>
                        <td>
                            {a.postalCode}
                        </td>
                        <td>
                            {a.line1}
                        </td>
                    </tr>)}
                    </tbody>
                </table>
            </div>}
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        </div>
    </div>
}

export default SupplierShippingAddressList;