import {post, get, MULTIPART_FORM_DATA} from "../../../../xhr";
import encodeQueryData from "../../../../../utils/encodeQueryData";

export function list(chat: number, requestData: {query?: string, offset: number})
{
    return get(`/corporate/common/chat/${chat}/message${encodeQueryData(requestData)}`)
}

export function add(chat: number, requestData: FormData)
{
    return post(`/corporate/common/chat/${chat}/message`, requestData, {headers: [MULTIPART_FORM_DATA]})
}