import React from "react";
import * as models from "../../../../../../../models/product"
import * as api from "../../../../../../../adapters/corporate/supplier/product/declination/choice"
import {S3_BUCKET_URL, S3_PRODUCT_BUCKET} from "../../../../../../../constants/global";
import Dropzone from "react-dropzone";

const SupplierProductDeclinationChoiceShow = ({product, setProduct, declination, choice}: {product: models.Product & models.SellableProduct, setProduct: React.Dispatch<any>, declination: models.ProductDeclination, choice : models.ProductDeclinationChoice}) => {

    function updateDeclinationChoice(declination: models.ProductDeclination, choice: models.ProductDeclinationChoice){

        const index = product.declinations.findIndex(d => d.id === declination.id)

        if(index !== -1){
            const choiceIndex = product.declinations[index].choices.findIndex(c => c.id === choice.id);
            if (choiceIndex !== -1){
                const copy = {...product};
                copy.declinations[index].choices[choiceIndex] = choice;
                setProduct(copy);
            }
        }

    }

    function handleChange(value: string){
        api.edit(product.id, declination.id, choice.id, {value: value})
            .then(resp => updateDeclinationChoice(declination, resp.data))
    }

    function handleFileChange(file: File){
        // if (file.size > 20000){
        //     alert('Fichier trop volumineux')
        //     return;
        // }

        let formData = new FormData();
        formData.append('file', file, file.name);
        api.file(product.id, declination.id, choice.id, formData)
            .then(resp => updateDeclinationChoice(declination, resp.data))
    }

    return <div className={'row'}>
        <div className="col-12">
            <div className="row">
                <div className="col-auto mb-3">
                    <Dropzone accept={{
                        'image/*': ['.jpg', '.jpeg', '.png', '.tif'],
                    }} onDrop={acceptedFiles => handleFileChange(acceptedFiles[0])}>
                        {({getRootProps, getInputProps}) => (
                            <section className={'declination-picture'}>
                                <div {...getRootProps()} className={'h-100 w-100 bg-cover'} style={choice.fileName ? {backgroundImage: `url("${S3_BUCKET_URL}${S3_PRODUCT_BUCKET}${product.id}/declination/${declination.id}/${choice.id}/${choice.fileName}")`} : {display: "flex", alignItems: "center", textAlign: "center", border: "solid black 1px", borderStyle: "dashed"}}>
                                    {!choice.fileName && <div className={'col-12'}><i className={'bi bi-image'}></i></div> }
                                    <input {...getInputProps()} className={'d-none'} />
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>
                <div className="col mb-3">
                    <div className="form-floating">
                        <input type="text" onBlur={e => handleChange(e.target.value)} className="form-control" defaultValue={choice.value} />
                        <label htmlFor="">
                            Valeur
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default SupplierProductDeclinationChoiceShow;