import {get, MULTIPART_FORM_DATA, post} from "../../../xhr";

export function account(requestData: {firstname: string, lastname: string}){
    return post(`corporate/common/onboarding/account`, requestData);
}

export function organization(requestData: {name: string, size: string, country: number, sectors: number[], specialisations: number[]}){
    return post(`corporate/common/onboarding/organization`, requestData, {headers: [MULTIPART_FORM_DATA]});
}

export function type(requestData: {type: "provider"|'supplier'}){
    return post(`corporate/common/onboarding/type`, requestData);
}

export function information(requestData: {type: number, name: string, tel: string, countryCode: string, email: string, code: string}){
    return post(`corporate/common/onboarding/information`, requestData);
}

export function address(requestData: {line1: string, line2: string|null, city: string, postalCode: string}){
    return post(`corporate/common/onboarding/address`, requestData);
}

export function bio(requestData: {description: string, website: string|null, instagram: string|null, x: string|null, facebook: string|null, linkedin: string|null}){
    return post(`corporate/common/onboarding/bio`, requestData);
}

export function getStatus()
{
    return get(`corporate/common/onboarding/status`);
}

export function validate()
{
    return get(`corporate/common/onboarding/validate`);
}