import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import FormError from "../../../../../utils/formError";
import {useFormik} from "formik";
import * as Yup from "yup";
import {ObjectSchema} from "yup";
import * as api from "../../../../../adapters/corporate/common/onboarding";
import React, {useEffect, useState} from "react";
import authSlice from "../../../../../reducers/auth";
import Loader from "../../../../../utils/loader";
import Select from "../../../../../utils/select";
import {sectors} from "../../../../../utils/sectors";
import {getCountries} from "../../../../../utils/countries";
import {useNavigate} from "react-router-dom";
import Dropzone from "react-dropzone";
import {getLogoUrl} from "../../../../../utils/image";
import {CorporateAccount} from "../../../../../models/account";
import ErrorCard from "../../../../error";
import imageCompression from "browser-image-compression";
import {services} from "../../../../../utils/service";

const OnBoardingOrganization = () => {

    const account = useAppSelector(state => state.auth.user!.activeAccount) as CorporateAccount;
    const organization = account.organization;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(null)

    const sizes = [
        {label: "1-2", value: 1},
        {label: "2-10", value: 2},
        {label: "10-30", value: 3},
        {label: "30-50", value: 4},
        {label: "50-100", value: 5},
        {label: "100+", value: 6},
    ];

    const [initialValues, setInitialValues] = useState<any>({
        name: organization.name,
        size: organization.size,
        file: null,
        country: organization.country?.id,
        sectors: organization.sectors.map(s => s.id),
    })

    const [validationSchema, setValidationSchema] = useState<ObjectSchema<any>>(Yup.object().shape({
        name: Yup.string().required(),
        size: Yup.number().required(),
        country: Yup.number().required(),
        sectors: Yup.array().of(Yup.number()).min(1).required(),
    }))

    useEffect( () => {

        const fetchImageAndCreateFile = async (imageUrl: string) => {
            try {
                const response = await fetch(imageUrl);
                const blob = await response.blob();

                // Create a File object from the blob
                // Now you can use the 'file' object as needed
                return new File([blob], 'image.jpg', {type: blob.type});
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };

        if (organization.logoName){
            const imageUrl = getLogoUrl(organization);
            fetchImageAndCreateFile(imageUrl).then(file => setInitialValues((p: any) => ({...p, file: file})));
        }
    }, []);

    useEffect(() => {
        if (organization.type === "provider")
        {
            setInitialValues({...initialValues, specialisations: organization.specialisations.map(s => s.id), pricing: null})
            setValidationSchema(Yup.object().shape({...validationSchema.fields, specialisations: Yup.array().of(Yup.number()).min(1).required(), pricing: Yup.string().required()}))
        }
    }, [])

    const handleFileChange = async (selectedFile: File) => {

        // Options for image compression
        const options = {
            maxSizeMB: 1,        // (1 MB) Maximum size allowed
            maxWidthOrHeight: 800,  // Max width or height to maintain aspect ratio
            useWebWorker: true,   // For better performance
        };

        try {
            // Compress the image file
            const compressedFile = await imageCompression(selectedFile, options);

            // Now you can upload the compressed file to the server
            await formik.setFieldValue('file', compressedFile);

            // Here you would normally send the file with a POST request using something like:
            // const formData = new FormData();
            // formData.append('file', compressedFile);
            // axios.post('your-server-endpoint', formData);
        } catch (error) {
            console.error('Error compressing the file:', error);
        }
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, {setSubmitting}) => {
            api.organization(values)
                .then(r => dispatch(authSlice.actions.editAccountOrganization(r.data)))
                .then(() => navigate('/onboarding/bio'))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    })

    return <>
        <h3 className={"mb-8"}>
            Completez le profil de votre entreprise
        </h3>
        <form onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className="form-control" defaultValue={organization.name} id={"name"} name={"name"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                    <label htmlFor="name">
                        {account.organization.information.type === 'company' ? "Nom de l'entreprise (Nom complet pour les auto-entreprises)" : "Nom complet"}
                    </label>
                </div>
                {organization.information?.type === "individual" && <p className={"form-text-mb-1"}>* Nom complet si vous êtes particulier</p>}
                <FormError form={formik} field={"name"} />
            </div>
            <div className="col-12 mb-3">
                <h6>
                    Logo de l'entreprise
                </h6>
                <Dropzone multiple={false} accept={{
                    'image/*': ['.jpg', '.jpeg', '.png', '.tif']
                }} disabled={formik.isSubmitting} onDrop={acceptedFiles => handleFileChange(acceptedFiles[0])}>
                    {({getRootProps, getInputProps}) => (
                        <div className={"avatar avatar-xxl"} {...getRootProps()}>
                            <input {...getInputProps()} className={'d-none'} />
                            <img src={formik.values.file ? URL.createObjectURL(formik.values.file) : `${process.env.PUBLIC_URL}/img/placeholder-avatar.jpg`} className={'avatar-img rounded-circle'} />
                        </div>
                    )}
                </Dropzone>
            </div>
            <div className="col-12 mb-3">
                <Select
                    label={"Pays de résidence"}
                    disabled={formik.isSubmitting}
                    value={organization.country ? {label: organization.country.name, value: organization.country.id} : undefined}
                    options={getCountries()}
                    required={true}
                    onChange={o => formik.setFieldValue('country', o.id)}
                />
                <FormError form={formik} field={"country"} />
            </div>
            <div className="col-12 mb-3">
                <Select
                    label={"Taille de l'entreprise"}
                    disabled={formik.isSubmitting}
                    value={sizes.find(s => s.value === formik.values.size)}
                    options={sizes}
                    required={true}
                    onChange={o => formik.setFieldValue('size', o.value)}
                />
                <FormError form={formik} field={"size"} />
            </div>
            <div className="col-12 mb-3">
                <Select
                    label={"Sécteur cibles"}
                    disabled={formik.isSubmitting}
                    placeholder={"Secteur cibles"}
                    options={sectors.map(s => ({label: s.name, value: s.id}))}
                    multiple={true}
                    values={organization.sectors.map(s => ({label: s.name, value: s.id}))}
                    required={true}
                    onChange={choices => formik.setFieldValue('sectors', choices.map(choice => choice.value))}
                />
                <small>
                    Séléctionnez le ou les sécteurs cibles correspondant le plus précisemment
                </small>
                <FormError form={formik} field={"sectors"} />
            </div>
            {organization.type === "provider" && <>
                <div className="col-12 mb-3">
                    <Select
                        label={'Modèle tarifaire pour vos services'}
                        options={[{label: 'Commissions sur résultat', value: 'Fee'}, {label: 'Montant fix', value: 'Fix'}, {label: 'Montant Fix + Commissions sur résultat', value: 'Hybrid'}]}
                        onChange={c => formik.setFieldValue('pricing', c.value)}
                        required={true}
                    />
                    <FormError form={formik} field={'pricing'} />
                </div>
                <div className="col-12 mb-3">
                    <h6>Séléctionnez les services que vous fournissez dans la liste ci-dessous</h6>
                    <FormError form={formik} field={"specialisations"} />
                    <ul className={"list-unstyled ps-3"}>
                        {services.map(s => <li>
                            <p className={"fw-semibold my-3"}>
                                {s.title}
                            </p>
                            <ul className={'list-unstyled ps-3'}>
                                {s.items.map(i => <li>
                                    <div className="form-check">
                                        <input defaultChecked={organization.specialisations.map(s => s.id).includes(i.id)} className="form-check-input" name={'specialisations'} type="checkbox" value={i.id} id={`service_${i.id}`} onChange={formik.handleChange} />
                                        <label className="form-check-label" htmlFor={`service_${i.id}`}>
                                            {i.title}
                                            <span className="clearfix"></span>
                                            <span className="form-text">{i.description}</span>
                                        </label>
                                    </div>
                                </li>)}
                            </ul>
                        </li>)}
                    </ul>
                </div>
            </>}
            {error && <div className={'col-12 mb-3'}>
                <ErrorCard error={error} />
            </div>}
            <button className="btn btn-outline-primary w-100 mb-3">
                {formik.isSubmitting ? <Loader /> : <>Suivant <i className={'bi bi-arrow-right'}></i></>}
            </button>
        </form>
    </>

}

export default OnBoardingOrganization;