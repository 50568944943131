import React from "react";
import {Touch} from "../../../../../../models/touch";
import {getLogoUrl} from "../../../../../../utils/image";
import {useNavigate} from "react-router-dom";

const ProviderTouchListItem = ({touch}: {touch: Touch}) => {

    const navigate = useNavigate();

    return <div className={"col-12 mb-3"}>
        <div className="card border border-1 shadow-hover" onClick={() => navigate(`/corporate/provider/touch/${touch.id}`)}>
            <div className="card-body">
                <div className="row align-items-center">
                    <div className="col">
                        <p className={'fw-semibold mb-0'}>
                            {touch.title}
                        </p>
                        <p className={'text-muted mb-0'}>
                            Créé le {new Date(touch.createdAt).toLocaleDateString()} à {new Date(touch.createdAt).toLocaleTimeString()
                        }
                        </p>
                    </div>
                    <div className="col-auto">
                        <div className="avatar-group">
                            {touch.touchSuppliers.slice(0, 3).map(ts => <div className="avatar avatar-lg">
                                <img src={getLogoUrl(ts.supplier)} alt={ts.supplier.name} className="avatar-img rounded-circle" />
                            </div>)}
                            {touch.touchSuppliers.length > 3 && <div className="avatar">
                                <span className="avatar-title rounded-circle"><i className={'bi bi-plus'}></i> {touch.touchSuppliers.length - 3}</span>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ProviderTouchListItem;

