import {Price} from "../../../../../../models/price";
import convertToDecimal from "../../../../../../utils/currencyConverter";
import {Product, SellableProduct} from "../../../../../../models/product";
import React, {useState} from "react";
import ModalComponent from "../../../../../modal";
import ListEmpty from "../../../../../list/empty";

interface SupplierPriceListItemBase {
    price: Price,
    product: Product & SellableProduct,
    type: 'selector'|'list'
}

interface SupplierPriceListSelectorItem {
    type: 'selector',
    selected?: Price
    handleSelect: (price: Price) => void
}

interface SupplierPriceListListItem {
    type: 'list',
    setDefault: (price: Price) => void
}

type SupplierPriceListInterface = SupplierPriceListItemBase & (SupplierPriceListSelectorItem | SupplierPriceListListItem)

const SupplierPriceListItem = (props: SupplierPriceListInterface) => {

    const [showOptionsModal, setShowOptionsModal] = useState(false)
    const label = convertToDecimal(props.price.amount, props.price.currency);

    return <li className={'list-group-item'}>
        <div className="row">
            <div className="col-auto">
                {props.type === "selector" ? <span className="form-check">
                    <input className="form-check-input" onChange={() => props.handleSelect(props.price)} type="radio" name="price_item" id={`price_item_${props.price.id}`} />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                        {label}
                    </label>
                </span> : label} {props.price.recurring && <span>tout les {props.price.recurring.interval}</span>}
            </div>
            <div className="col">
                {props.price.id === props.product.price.id && <span className="badge bg-primary">Prix par defaut</span>}
                {props.price.id !== props.product.price.id && props.type === "list" && <button className="btn btn-light btn-xxs" onClick={() => props.setDefault(props.price)}>
                    Définir par défaut
                </button>}
            </div>
            <div className="col-auto">
                <button type={"button"} className="btn btn-xxs" onClick={() => setShowOptionsModal(true)}>
                    Autres devises <i className={'bi bi-arrow-right'}></i>
                </button>
            </div>
        </div>
        <ModalComponent size={'modal-lg'} title={"Autres devises"} show={showOptionsModal} onHide={() => setShowOptionsModal(false)}>
            {props.price.options.length === 0 ? <ListEmpty /> : <ul className={"list-group"}>
                {props.price.options.map(o => <li className={'list-group-item'}>
                    {convertToDecimal(o.amount, o.currency)}
                </li>)}
            </ul>}
        </ModalComponent>
    </li>
}

export default SupplierPriceListItem;