import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query?: string, offset: number, count: number, affiliation_id?: number, status?: string}){
    return get(`corporate/supplier/conversion${encodeQueryData(requestData)}`);
}

export function update(conversion: number, requestData: {[key: string]: any}){
    return post(`corporate/supplier/conversion/${conversion}`, requestData);
}

export function bulkUpdate(requestData: {ids: number[], status?: "Accepted"|"Refused"}){
    return post(`corporate/supplier/conversion/bulk-update`, requestData);
}

export function paymentData(requestData: { currency_id: number, ids: number[] }){
    return post(`corporate/supplier/conversion/payment-data`, requestData);
}

export function confirmPayment(requestData: { currency_id: number, ids: number[], payment_method_id: number }){
    return post(`corporate/supplier/conversion/confirm-payment`, requestData);
}