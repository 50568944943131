import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import {useAppSelector} from "../../../hooks";
import CustomerNav from "../../../components/customer/nav";

const AppCustomerLayout = () => {

    const user = useAppSelector(state => state.auth.user);

    if (!user){
        return <Navigate to={"/login"} replace />
    }

    if (!user.activeAccount || user.activeAccount.type === 'corporate')
    {
        return <Navigate to={"/account?type=customer"} replace />
    }

    return <div className={'container mx-auto h-100'}>
        <CustomerNav />
        <div className="row h-100">
            <div className="col py-3 h-100">
                <Outlet />
            </div>
        </div>
    </div>
}

export default AppCustomerLayout;