import React, {useEffect, useState} from "react";
import * as api from "../../../../adapters/customer/address";
import Loader from "../../../../utils/loader";
import Pagination from "../../../../utils/pagination";
import {CustomerAddress, CustomerAddresses} from "../../../../models/account";
import ModalComponent from "../../../modal";
import CustomerAddressAdd from "../add";

const CustomerAddressSelect = ({selected, handleSelect}: {selected?: CustomerAddress, handleSelect: (address: CustomerAddress) => void}) => {

    const [payload, setPayload] = useState<{ count: number, rows: CustomerAddresses }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ offset: number}>({offset: 0})
    const [showAddModal, setShowAddModal] = useState(false)

    useEffect(() => {
        setLoading(true)
        api.list({offset: fetchParams.offset})
            .then(resp => setPayload(resp.data))
            .then(() => setLoading(false))
    }, [fetchParams])

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="row align-items-center">
            <div className="col mb-3">
                {loading ? <Loader /> : <>{payload.count} résultats</>}
            </div>
            <div className="col-auto mb-3">
                <button className={"btn btn-xxs btn-primary"} onClick={() => setShowAddModal(true)}>
                    <i className={'bi bi-plus-circle'}></i> Ajouter une adresse
                </button>
            </div>
        </div>
        <div className="col-md-12 flex-grow-1">
            {!loading && <ul className={'list-group'}>
                {payload.rows.map(r => <li className={"list-group-item"}>
                    <div className="form-check">
                        <input checked={selected?.id === r.id} onChange={() => handleSelect(r)} className="form-check-input" type="radio" name="address" id={`address_${r.id}`} />
                        <label className="form-check-label" htmlFor={`address_${r.id}`}>
                            {r.line1}, {r.city}, {r.postalCode}, {r.country.name}
                        </label>
                    </div>
                </li>)}
            </ul>}
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        </div>
        <ModalComponent title={"Ajouter une adresse"} show={showAddModal} onHide={() => setShowAddModal(false)}>
            <CustomerAddressAdd onSuccess={address => {
                setShowAddModal(false)
                setPayload(prevState => ({count: prevState.count + 1, rows: [address, ...prevState.rows]}))
                handleSelect(address)
            }} />
        </ModalComponent>
    </div>
}

export default CustomerAddressSelect;