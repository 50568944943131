import FullCalendar from "@fullcalendar/react";
import React, { useEffect, useRef, useState } from "react";
import dayGridPlugin from '@fullcalendar/daygrid';
import {Subscription} from "../../../../../../models/subscription";
import * as api from "../../../../../../adapters/corporate/provider/subscription";
import ErrorCard from "../../../../../error";
import {format} from "date-fns";
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import {EventClickArg, EventContentArg} from "@fullcalendar/core";
import ModalComponent from "../../../../../modal";
import ProviderSubscriptionShowOccurrenceShow from "../occurrence/show";
import Loader from "../../../../../../utils/loader";

const ProviderSubscriptionCalendar = ({ subscription }: { subscription: Subscription }) => {
    const calendarRef = useRef<FullCalendar>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [events, setEvents] = useState<{ title: string, start: Date }[]>([]);
    const [modalEventArg, setModalEventArg] = useState<EventClickArg>();
    const [showModal, setShowModal] = useState(false);
    const now = new Date();
    const [dates, setDates] = useState<{startDate: string, endDate: string}>({startDate: format( new Date(now.getFullYear(), now.getMonth(), 1), "uuuu-MM-dd"), endDate: format( new Date(now.getFullYear(), now.getMonth() + 1, 0),"uuuu-MM-dd")})

    useEffect(() => {
        setLoading(true);
        api.events(subscription.id, { startDate: dates.startDate, endDate: dates.endDate })
            .then(r => setEvents(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false));
    }, [subscription.id, dates.startDate, dates.endDate])

    const handleDatesSet = () => {
        console.log('handleDatesSet')
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            setDates({startDate: format(calendarApi.view.currentStart, 'uuuu-MM-dd'), endDate: format(calendarApi.view.currentEnd, 'uuuu-MM-dd')})
        }
    };

    const EventContent = ({arg}: { arg: EventContentArg }) => {

        return <div className={'bg-light w-100 p-1 overflow-hidden text-break'}>
            <p className={"mb-1"}>{arg.event.title}</p>
            {arg.event.extendedProps.exception && <p className={"mb-1 badge bg-danger"}><i className={"bi bi-x-circle"}></i> Désactivé</p>}
            {arg.event.extendedProps.occurrence && <p className={"mb-1 badge bg-success"}><i className={"bi bi-check-circle"}></i> Généré</p>}
        </div>
    }

    return (
        <div className={"col-12 mb-3 position-relative"}>
            {loading && <div style={{zIndex: 100, backgroundColor: "white", opacity: 0.7}} className={"h-100 w-100 position-absolute top-0 d-flex align-items-center"}>
                <div className="col-12 text-center">
                    <Loader />
                </div>
            </div>}
            {error && <ErrorCard error={error} />}
            <FullCalendar
                // validRange={{start: new Date(subscription.startDate), end: subscription.endDate ? new Date(subscription.endDate) : undefined}}
                displayEventTime={false}
                locale={'fr'}
                ref={calendarRef}
                initialView="dayGridMonth"
                plugins={[dayGridPlugin, bootstrap5Plugin]}
                events={events}
                datesSet={handleDatesSet}
                themeSystem={'bootstrap5'}
                eventClick={arg => {
                    setModalEventArg(arg)
                    setShowModal(true)
                }}
                eventContent={arg => <EventContent arg={arg} />}
            />
            {modalEventArg && <ModalComponent
                size={"modal-xl"}
                title={`${modalEventArg.event.title} | ${modalEventArg.event.start?.toLocaleDateString()}`}
                show={showModal}
                onHide={() => setShowModal(false)}
            >
                {modalEventArg.event.extendedProps.occurrence ? <ProviderSubscriptionShowOccurrenceShow subscription={subscription} occurrence={modalEventArg.event.extendedProps.occurrence} key={modalEventArg.event.extendedProps.occurrence.id} /> : <div className={"col-12 mb-3 text-center py-5"}>
                    <h6>Cette occurence n'a pas été généré.</h6>
                </div>}
            </ModalComponent>}
        </div>
    );
};

export default ProviderSubscriptionCalendar;
