import React, {useEffect, useState} from "react";
import * as api from '../../../../../../adapters/corporate/provider/product';
import SearchInput from "../../../../../../utils/searchInput";
import {Product, Products, SellableProduct} from "../../../../../../models/product";
import Loader from "../../../../../../utils/loader";
import Pagination from "../../../../../../utils/pagination";
import ProviderLinkItemSelectorItem from "./item";

const ProviderLinkItemSelector = ({handleSelect}: {handleSelect: (product: Product & SellableProduct) => void}) => {

    const [payload, setPayload] = useState<{ count: number, rows: (Product & SellableProduct)[] }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number}>({query: "", offset: 0})

    useEffect(() => {
        setLoading(true)
        api.list({offset: fetchParams.offset, query: fetchParams.query, sellable: true, type: "private"})
            .then(resp => setPayload(resp.data))
            .then(() => setLoading(false))
            .catch(e => console.log(e))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher un produit..."} onChange={handleQueryChange} size={"default"} />
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        <div className={'row row-cols-2 row-cols-md-4'}>
            {payload.rows.map(r => <ProviderLinkItemSelectorItem handleClick={product => handleSelect(product)} item={r} />)}
        </div>
        <Pagination count={payload.count} offset={0} setOffset={o => console.log(o)} />
    </>
}

export default ProviderLinkItemSelector;