import React, {useEffect, useState} from "react";
import QuotePaymentForm from "./form";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import Loader from "../../../utils/loader";
import * as api from "../../../adapters/quote"
import * as paymentMethodApi from "../../../adapters/common/paymentMethod"
import {PaymentMethods, PaymentMethod} from "../../../models/paymentMethod";
import Pagination from "../../../utils/pagination";
import {Quote} from "../../../models/quote";
import {Payment} from "../../../models/payment";
import ModalComponent from "../../modal";
import CommonPaymentMethodAdd from "../../common/paymentMethod/add";

const stripePromise = loadStripe("pk_live_51OHqm6H46Kmwvu6gtsFGdaMUw4mgkXMquclVtkENiHwv4DN4s00c3i7pHXP5db9Vo6SS0omYqRIvDFiHC7oxtWPx00GupdBdOQ");

const QuotePayment = ({quote}: {quote: Quote}) => {

    const [payload, setPayload] = useState<{count: number, rows: PaymentMethods}>({count: 0, rows: []})
    const [fetchParams, setFetchParams] = useState<{offset: number}>({offset: 0})

    const [loading, setLoading] = useState(true)
    const [updating, setUpdating] = useState(false)
    const [clientSecret, setClientSecret] = useState<string>();
    const [payment, setPayment] = useState<Payment>(quote.payment);
    const [showPaymentAddModal, setShowPaymentAddModal] = useState(false)

    useEffect(() => {
        setLoading(true)
        paymentMethodApi.list(fetchParams)
            .then(r => setPayload(r.data))
            .then(() => setLoading(false))
    }, [fetchParams])

    function handlePaymentMethodChange(paymentMethod: PaymentMethod){
        setUpdating(true)
        api.paymentMethod(quote.uuid, paymentMethod.id)
            .then(r => {
                setPayment(r.data.payment);
                setClientSecret(r.data.clientSecret)
            })
            .catch(error => console.log(error))
            .finally(() => setUpdating(false))
    }

    return <div className="col-12 mb-3" id={"payment"}>
        <div className="col-12 mb-3">
            <p className={'fw-semibold'}>
                Séléctionner un moyen de paiement
            </p>
            <ul className={'list-group'}>
                {loading && <li className={'list-group-item'}>
                    <Loader size={"sm"} /> Chargement des moyens de paiement
                </li>}
                {payload.rows.map(r => <li className={'list-group-item'}>
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <div className="form-check">
                                <input disabled={loading || updating} checked={r.id === payment?.paymentMethod?.id} className="form-check-input" type="checkbox" onChange={() => handlePaymentMethodChange(r)} id={`payment_method_${r.id}`} />
                            </div>
                        </div>
                        <div className="col">
                            <label className="form-check-label" htmlFor={`payment_method_${r.id}`}>
                                {r.brand}, **** **** **** {r.last4}
                            </label>
                        </div>
                    </div>
                </li>)}
                <li className={'list-group-item'}>
                    <button disabled={loading || updating} className="btn btn-xxs text-decoration-underline cursor-pointer" onClick={() => setShowPaymentAddModal(false)}>
                        <i className={"bi bi-plus-circle"}></i> Ajouter un moyen de paiement
                    </button>
                </li>
            </ul>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        </div>
        <ModalComponent title={"Ajouter un moyen de paiement"} show={showPaymentAddModal} onHide={() => setShowPaymentAddModal(false)}>
            <CommonPaymentMethodAdd onSuccess={pm => {
                setPayload(prevState => ({count: prevState.count + 1, rows: [pm, ...prevState.rows]}))
                handlePaymentMethodChange(pm)
            }} />
        </ModalComponent>
        <div className="p-3">
            {clientSecret && (
                <Elements options={{appearance: {theme: 'stripe'}, clientSecret: clientSecret}} stripe={stripePromise}>
                    <QuotePaymentForm disabled={loading || updating} uuid={quote.uuid} clientSecret={clientSecret} />
                </Elements>
            )}
        </div>
    </div>
}

export default QuotePayment