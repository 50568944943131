import {getLogoUrl} from "../../../../../utils/image";
import React, {useEffect, useState} from "react";
import {Assignment} from "../../../../../models/assignment";
import {Link, useParams} from "react-router-dom";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import * as api from "../../../../../adapters/corporate/provider/assignment";
import ModalComponent from "../../../../modal";
import ProviderApplicationAdd from "../../application/add";
import ProviderProposalList from "../../proposal/list";

const ProviderAssignmentShow = () => {

    const {id} = useParams();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false)
    const [assignment, setAssignment] = useState<Assignment>()
    const [showApplicationModal, setShowApplicationModal] = useState(false);

    useEffect(() => {
        setLoading(true)
        api.show(Number(id))
            .then(r => setAssignment(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [id])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!assignment){
        return <></>
    }

    return <div className={'container-fluid'}>
        <div className="col-12 mb-3">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/corporate/provider/search/assignment">Annonces</Link>
                </li>
                <li className="breadcrumb-item">
                    {assignment.name}
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1 position-sticky top-0">
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <div className="avatar avatar-lg">
                                <img src={getLogoUrl(assignment.organization)} alt="" className="avatar-img rounded-circle"/>
                            </div>
                        </div>
                        <div className="col">
                            <h6 className={'mb-1'}>{assignment.name}</h6>
                            <Link to={`/corporate/organization/${assignment.organization.id}`} target={'_blank'} className={"mb-0"}>{assignment.organization.name} <i></i></Link>
                        </div>
                        <div className="col-auto">
                            {assignment.myApplication ? <>
                                {assignment.myApplication.status === "Pending" && <span className={'badge bg-warning'}><i className={'bi bi-clock'}></i> En attente</span>}
                                {assignment.myApplication.status === "Accepted" && <span className={'badge bg-success'}><i className={'bi bi-check'}></i> Intéressé</span>}
                                {assignment.myApplication.status === "Refused" && <span className={'badge bg-danger'}><i className={'bi bi-x'}></i> Pas Intéressé</span>}
                            </> : <button className="btn btn-sm btn-primary" onClick={() => setShowApplicationModal(true)}>
                                Postuler
                            </button>}
                        </div>
                        {assignment.myApplication && assignment.myApplication.status === "Accepted" && <div className={"col-auto"}>
                            <button className="btn btn-xs btn-light">
                                <i className={'bi bi-chat'}></i> Contacter
                            </button>
                        </div>}
                    </div>
                    <hr/>
                    <div className="col-12">
                        {assignment.specialisations.map(s => <span className={'badge bg-light text-dark me-2 mb-2'}>{s.title}</span>)}
                    </div>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-body">
                    <table className="table table-borderless">
                        <tbody>
                        <tr>
                            <th>Lieux</th>
                            <td>
                                {assignment.location}
                            </td>
                        </tr>
                        <tr>
                            <th>Type de rémunération</th>
                            <td>
                                {assignment.compensation === 'Fee' && <>Comissions</>}
                                {assignment.compensation === 'Fix' && <>Fix</>}
                                {assignment.compensation === 'Hybrid' && <>Fix + Commissions</>}
                            </td>
                        </tr>
                        {['Fix', 'Hybrid'].includes(assignment.compensation) && <tr>
                            <th>Montant du fix</th>
                            <td>Entre {assignment.fixRangeMin}{assignment.currency.symbol} et {assignment.fixRangeMax}{assignment.currency.symbol} par {assignment.fixRangeDuration} </td>
                        </tr>}
                        {['Fee', 'Hybrid'].includes(assignment.compensation) && <tr>
                            <th>Montant des commissions</th>
                            <td>Entre {assignment.feeRangeMin}{assignment.currency.symbol} et {assignment.feeRangeMax}{assignment.currency.symbol} par {assignment.feeTrigger}</td>
                        </tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        {assignment.myApplication && assignment.myApplication.status === 'Accepted' && <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-body">
                    <h6>
                        Mes devis
                    </h6>
                    <ProviderProposalList assignment={assignment} />
                </div>
            </div>
        </div>}
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-body">
                    <h6>
                        Descriptif de l'annonce
                    </h6>
                    <div dangerouslySetInnerHTML={{__html: assignment.description}} />
                </div>
            </div>
        </div>
        <ModalComponent title={`Postuler à ${assignment.name}`} show={showApplicationModal} onHide={() => setShowApplicationModal(false)}>
            <ProviderApplicationAdd
                handleSuccess={application => {
                    setAssignment(prevState => ({...prevState!, myApplication: application}))
                    setShowApplicationModal(false)
                }}
                assignment={assignment}
            />
        </ModalComponent>
    </div>
}

export default ProviderAssignmentShow;