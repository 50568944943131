import React, {useState} from "react";
import {Term} from "../../../../../../models/term";
import {Link} from "react-router-dom";
import * as api from "../../../../../../adapters/corporate/supplier/term";
import ModalComponent from "../../../../../modal";

const SupplierTermListItem = ({term}: {term: Term}) => {

    const [updating, setUpdating] = useState(false)
    const [showModal, setShowModal] = useState(false)

    function setDefault(checked: boolean){
        setUpdating(true);
        api.update(term.id, {default: checked})
            .finally(() => setUpdating(false))
    }


    return <li className={"list-group-item"}>
        <div className="row">
            <div className="col">
                <Link to={``}>
                    {term.name}
                </Link>
            </div>
            <div className="col-auto">
                <button onClick={() => setShowModal(true)} className="btn btn-xxs">
                    <i className={'bi bi-eye'}></i>
                </button>
            </div>
            <div className="col-auto">
                <div className="form-check form-switch">
                    <input disabled={updating} className="form-check-input" type="checkbox" role="switch" id="canAccessProduct" name="canAccessProduct" defaultChecked={term.default} onChange={e => setDefault(e.target.checked)} />
                </div>
            </div>
        </div>
        <ModalComponent size={"modal-lg"} title={term.name} show={showModal} onHide={() => setShowModal(false)}>
            <div dangerouslySetInnerHTML={{__html: term.details}} />
        </ModalComponent>
    </li>
}

export default SupplierTermListItem;

