import React, {useEffect, useState} from "react";
import SearchInput from "../../../../../utils/searchInput";
import {Product, Products} from "../../../../../models/product";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";
import CommonProductSelectorItem from "./item";
import * as providerApi from "../../../../../adapters/corporate/provider/product";
import * as supplierApi from "../../../../../adapters/corporate/supplier/product";
import {useAppSelector} from "../../../../../hooks";
import {CorporateAccount} from "../../../../../models/account";
import ErrorCard from "../../../../error";

const CommonProductSelector = ({handleSelect, extendedFetchParams}: {handleSelect: (product: Product) => void,
    extendedFetchParams?: {
        sellable?: boolean;
        price_recurring_required?: boolean;
    }
}) => {

    const activeAccount = useAppSelector(state => state.auth.user?.activeAccount) as CorporateAccount
    const authOrganization = activeAccount.organization;
    const [error, setError] = useState<any>(null);
    const [payload, setPayload] = useState<{ count: number, rows: Products }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number}>({query: "", offset: 0})

    useEffect(() => {
        fetchProducts()
    }, [fetchParams])

    const fetchProducts = async () => {
        try {
            setLoading(true)
            let _payload = {count: 0, rows: []};
            let resp;
            switch (authOrganization.type){
                case "provider":
                    resp = await providerApi.list({...fetchParams, ...extendedFetchParams, type: "private"});
                    _payload = resp.data;
                    break;
                case "supplier":
                    resp = await supplierApi.list({...fetchParams, ...extendedFetchParams});
                    _payload = resp.data;
                    break;
            }

            setPayload(_payload);
        }catch (e) {
            setError(e)
        }finally {
            setLoading(false)
        }
    }

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher un produit..."} onChange={handleQueryChange} size={"default"} />
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        {error && <ErrorCard error={error} />}
        <div className={'row row-cols-2 row-cols-md-4'}>
            {payload.rows.map(r => <CommonProductSelectorItem handleClick={product => handleSelect(product)} item={r} />)}
        </div>
        <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
    </>
}

export default CommonProductSelector;