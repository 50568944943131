import Select from "../../../../../utils/select";
import React, {useEffect, useState} from "react";
import {ReturnAddresses} from "../../../../../models/returns";
import * as api from "../../../../../adapters/corporate/supplier/returns/address";
import SupplierReturnsAddressAdd from "../add";
import ErrorCard from "../../../../error";
import ModalComponent from "../../../../modal";

const SupplierReturnsAddressesSelect = (props: {allowAdd?: boolean, label: string, required: boolean, handleChange: (choice: number) => void, defaultValue?: {label: string, value: any}}) => {

    const [loading, setLoading] = useState(true)
    const [payload, setPayload] = useState<{count: number, rows: ReturnAddresses}>({count: 0, rows: []})
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string}>({offset: 0, query: ""})
    const [defaultValue, setDefaultValue] = useState({label: props.defaultValue?.label || "", value: props.defaultValue?.value || null});
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    return (<>
        <div className="row">
            <div className="col mb-3">
                <Select
                    key={defaultValue.value}
                    value={defaultValue}
                    multiple={false}
                    disabled={loading}
                    required={props.required}
                    label={props.label}
                    options={payload.rows.map(c => ({label: `${c.line1}, ${c.city}, ${c.postalCode}, ${c.country.name}`, value: c.id}))}
                    onChange={(c: any) => props.handleChange(c.value)}
                    handleScrollBottom={() => setFetchParams(p => ({...p, offset: p.offset + 1}))}
                    handleQueryChange={(query) => setFetchParams(p => ({query: query, offset: 0}))}
                />
            </div>
            {props.allowAdd && <div className={'col-auto mb-3'}>
                <button data-bs-toggle={"offcanvas"} data-bs-target={"#offcanvasSupplierReturnsAddressAdd"} type={"button"} className="btn btn-light">
                    <i className={'bi bi-plus'}></i> <span className={'d-none d-md-inline'}>Ajouter une adresse</span>
                </button>
            </div>}
        </div>
        <ModalComponent title={"Ajouter une adresse de retour"} show={showModal} onHide={() => setShowModal(false)}>
            <SupplierReturnsAddressAdd handleSuccess={(address) => {
                setShowModal(false)
                setDefaultValue({label: `${address.line1}, ${address.city}`, value: address.id})
                props.handleChange(address.id)
            }} />
        </ModalComponent>
        {error && <div className={"col-12 mb-3"}>
            <ErrorCard error={error} />
        </div>}
    </>)


}

export default SupplierReturnsAddressesSelect;