import React, {useEffect, useState} from "react";
import {XReturns} from "../../../../models/returns";
import * as api from "../../../../adapters/customer/return";
import {Link} from "react-router-dom";
import SearchInput from "../../../../utils/searchInput";
import Loader from "../../../../utils/loader";
import ErrorCard from "../../../error";
import {getCoverUrl} from "../../../../utils/image";
import convertToDecimal from "../../../../utils/currencyConverter";
import ReturnsStatusPlaceholder from "../../../common/returns/status/placeholder";
import {reason_labels} from "../../../../constants/returns";
import CustomerReturnListItem from "./item";

const CustomerReturnList = () => {

    const [loading,setLoading] = useState(true)
    const [payload, setPayload] = useState<{count: number, rows: XReturns}>({count: 0, rows: []});
    const [error, setError] = useState(null);
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string, status: "All"|"Pending"|"Accepted"|"Refused"}>({offset: 0, query: "", status: "All"});

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(r => setPayload(r.data))
            .catch(error => setError(error))
            .finally(() => setLoading(false))
    }, [])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams(prev => ({...prev, query: e.target.value, offset: 0})), 1000)
    }

    return <div className={"container-fluid"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/customer">Mon compte</Link>
                </li>
                <li className="breadcrumb-item">
                    Mes retours
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher un retour..."} onChange={handleQueryChange} size={"default"} />
                </div>
                <div className="col-auto">
                    <Link to={`add`} className={'btn btn-light'}>
                        J'ai besoin d'aide
                    </Link>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        {error && <ErrorCard error={error} />}
        <div className="row">
            {payload.rows.map(r => <CustomerReturnListItem returns={r} />)}
        </div>
    </div>
}

export default CustomerReturnList;