import React, {useEffect, useState} from "react";
import * as models from "../../../../../models/pack";
import * as api from "../../../../../adapters/corporate/provider/pack";
import {Link, useParams} from "react-router-dom";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import ProviderPackItemList from "../item/list";
import {APP_URL} from "../../../../../constants/global";

const ProviderPackShow = () => {

    const {id} = useParams();
    const [pack, setPack] = useState<models.Pack>()
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null)

    const [copied, setCopied] = useState(false)

    let timer: NodeJS.Timeout;
    function copyLink(){
        if (pack){
            clearTimeout(timer);
            setCopied(true)
            navigator.clipboard.writeText(`${APP_URL}pack/${pack.link}`);
            timer = setTimeout(() => setCopied(false), 1500);
        }
    }

    useEffect(() => {
        setLoading(true)
        api.show(Number(id))
            .then(r => setPack(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [id])


    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!pack){
        return <></>
    }

    return <div className={"container-fluid h-100"}>
        <div className="row">
            <div className="col mb-3">
                <ol className="breadcrumb mb-3 fs-xs">
                    <li className="breadcrumb-item">
                        <Link to="/corporate/provider/catalog/pack">Packages</Link>
                    </li>
                    <li className="breadcrumb-item">
                        {pack.name}
                    </li>
                </ol>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h6>
                                Lien
                            </h6>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-xs btn-outline-dark" onClick={() => copyLink()}>
                                {copied ? <><i className={'bi bi-check'}></i> Lien copié</> : <><i className={'bi bi-front'}></i></>}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <Link to={`${APP_URL}link/${pack.link}`} target={'_blank'}>
                        {`https://app.varar.co/pack/${pack.link}`}
                    </Link>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <ProviderPackItemList pack={pack} />
        </div>
    </div>
}

export default ProviderPackShow;