import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query?: string, offset: number, customer_id?: number, status: string, count?: number}){
    return get(`corporate/supplier/parcel${encodeQueryData(requestData)}`);
}

export function show(parcel: number){
    return get(`corporate/supplier/parcel/${parcel}`);
}

export function update(parcel: number, requestData: {status: string}){
    return post(`corporate/supplier/parcel/${parcel}`, requestData);
}
