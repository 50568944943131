import * as models from "../../../../../../../models/subscription";
import React, {useEffect, useState} from "react";
import * as api from "../../../../../../../adapters/corporate/supplier/subscription/item";
import SubscriptionShowItemListItem from "./item";
import SearchInput from "../../../../../../../utils/searchInput";
import ErrorCard from "../../../../../../error";
import Loader from "../../../../../../../utils/loader";
import ModalComponent from "../../../../../../modal";
import SupplierSubscriptionItemAdd from "../add";
import CommonProductSelector from "../../../../../common/product/selector";
import {Product, SellableProduct} from "../../../../../../../models/product";

const SupplierSubscriptionShowItemList = ({subscription}: {subscription: models.Subscription}) => {

    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string}>({offset: 0, query: ''})
    const [payload, setPayload] = useState<{count: number, rows: models.SubscriptionItems}>({count: 0, rows: []})
    const [error, setError] = useState(null)
    const [showSelectModal, setShowSelectModal] = useState(false)
    const [showAddModal, setShowAddModal] = useState(false)
    const [selected, setSelected] = useState<Product & SellableProduct>()

    useEffect(() => {
        setLoading(true)
        setError(null)
        api.list(subscription.id, fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [subscription.id, fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    function deactivate(item: models.SubscriptionItem){
        api.update(subscription.id, item.id, {activated: false})
            .then(() => setPayload(prev => ({count: prev.count - 1, rows: prev.rows.filter(r => r.id !== item.id)})))
            .catch(e => setError(e))
    }

    return <div className={'col-12 mb-3'}>
        <div className="row align-items-center">
            <div className="col">
                <SearchInput placeholder={"Rechercher un article..."} onChange={handleQueryChange} size={"default"} />
            </div>
            <div className="col-auto">
                <button onClick={() => setShowSelectModal(true)} className={'btn btn-primary'}>
                    <i className={'bi bi-plus-circle'}></i> Ajouter
                </button>
            </div>
        </div>
        <div className={"col-12 mb-3 text-center"}>{loading ? <Loader size={"sm"} /> : <>{payload.count} résultats</>}</div>
        {error && <ErrorCard error={error} />}
        <table className={'table'}>
            <thead>
            <tr>
                <th>Produit</th>
                <th>Quantité</th>
                <th>Tarif</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
                {payload.rows.map(r => <SubscriptionShowItemListItem subscription={subscription} item={r} key={r.id} deactivate={deactivate} handleUpdate={updated => setPayload(prev => ({count: prev.count, rows: [...prev.rows.map(row => row.id === updated.id ? updated : row)]}))} />)}
            </tbody>
        </table>
        <ModalComponent size={"modal-xl"} title={"Séléctionner un article"} show={showSelectModal} onHide={() => setShowSelectModal(false)}>
            <CommonProductSelector extendedFetchParams={{price_recurring_required: true, sellable: true}} handleSelect={(item) => {
                setSelected(item as Product & SellableProduct)
                setShowSelectModal(false)
                setShowAddModal(true)
            }} />
        </ModalComponent>
        <ModalComponent size={"modal-lg"} title={"Ajouter un article"} show={showAddModal} onHide={() => setShowAddModal(false)}>
            {selected && <SupplierSubscriptionItemAdd key={selected.id} product={selected} subscription={subscription} onSuccess={item => {
                setPayload(prev => ({count: prev.count + 1, rows: [item, ...prev.rows]}))
                setShowAddModal(false)
            }}/>}
        </ModalComponent>
    </div>
}

export default SupplierSubscriptionShowItemList;