import * as models from "../../../../../../../../models/subscription";
import convertToDecimal from "../../../../../../../../utils/currencyConverter";
import {useState} from "react";
import ModalComponent from "../../../../../../../modal";
import CommonProductShow from "../../../../../../common/product/show";

const ProviderSubscriptionShowItemListItem = ({item}: {item: models.SubscriptionItem}) => {

    const [showModal, setShowModal] = useState(false)

    return <tr key={item.id}>
        <td>
            <span onClick={() => setShowModal(true)}>
                {item.price.product.name}
            </span>
            {showModal && <ModalComponent title={item.price.product.name} show={showModal} onHide={() => setShowModal(false)}>
                <CommonProductShow id={item.price.product.id} />
            </ModalComponent>}
        </td>
        <td>
            {item.quantity}
        </td>
        <td>
            {convertToDecimal(item.priceData.total_amount_including_tax, item.priceData.currency)} tout les {item.priceData.recurring.intervalCount} {item.priceData.recurring.interval}
        </td>
    </tr>
}

export default ProviderSubscriptionShowItemListItem;