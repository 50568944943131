import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Subscription} from "../../../../models/subscription";
import * as api from "../../../../adapters/customer/subscription";
import Loader from "../../../../utils/loader";
import ErrorCard from "../../../error";
import CustomerSubscriptionShowInformation from "./information";
import CustomerSubscriptionShowOccurrenceList from "./occurrence/list";
import CustomerSubscriptionShowItemList from "../item/list";
import SubscriptionStatusPlaceholder from "../../../common/subscription/status/placeholder";
import ModalComponent from "../../../modal";

const CustomerSubscriptionShow = () => {

    const params = useParams();
    const [subscription, setSubscription] = useState<Subscription>();
    const [error, setError] = useState();
    const [loading, setLoading] = useState<boolean>(true);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [tab, setTab] = useState<"items"|"occurrences">("items")
    const [updating, setUpdating] = useState(false)

    useEffect(() => {
        setLoading(true)
        setError(undefined);
        api.show(Number(params.id))
            .then(r => setSubscription(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [params.id])


    function updateStatus(status: "Paused"|"Active"|"Canceled")
        {
            if (subscription){
                setUpdating(true)
                api.update(subscription.id, {status: status})
                    .then(r => setSubscription(prev => ({...prev, ...r.data})))
                    .then(() => setShowConfirmModal(false))
                    .catch(error => setError(error))
                    .finally(() => setUpdating(false))
            }
        }
    if (loading){
        return <div className={'container-fluid py-10 text-center'}>
            <Loader />
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!subscription){
        return <div className={'container-fluid py-10 text-center'}>
            <span className={'display-6'}>
                <i className={"bi bi-search"}></i> Abonnement non trouvé
            </span>
        </div>
    }

    return <div className={'container-fluid'}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <ol className="breadcrumb mb-3 fs-xs">
                        <li className="breadcrumb-item">
                            <Link to="/customer/subscription">Abonnement</Link>
                        </li>
                        <li className="breadcrumb-item">
                            {subscription.partnership.supplier.name}
                        </li>
                    </ol>
                </div>
                <div className="col-auto">
                    <div className="row">
                        <div className="col-auto">
                            <SubscriptionStatusPlaceholder status={subscription.status} />
                        </div>
                        {["Active", "Incomplete", "Trialing", "IncompleteExpired", "PastDue", "UnPaid"].includes(subscription.status) && <div className="col-auto">
                             <button className="btn btn-xxs btn-light"  disabled={updating} onClick={() => setShowConfirmModal(true)}>
                                <i className={'bi bi-x text-danger'}></i> Résilier l'abonnement
                            </button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <CustomerSubscriptionShowInformation subscription={subscription} setSubscription={setSubscription} />
        </div>
        <div className="row">
            <div className="col-md-6">

            </div>
            <div className="col-md-6">

            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="mb-2 mb-lg-0 border-bottom-lg">
                <nav className="nav nav-tabs nav-overflow fs-xs border-bottom border-bottom-lg-0">
                    <button onClick={() => setTab('items')} className={"nav-link text-uppercase" + (tab === 'items' ? " active " : "")}>
                        Articles
                    </button>
                    <button onClick={() => setTab('occurrences')} className={"nav-link text-uppercase" + (tab === "occurrences" ? " active " : "")}>
                        Factures
                    </button>
                </nav>
            </div>
        </div>
        {tab === "items" && <CustomerSubscriptionShowItemList subscription={subscription} />}
        {tab === "occurrences" && <CustomerSubscriptionShowOccurrenceList subscription={subscription} />}
        <ModalComponent
            title={"Confirmer l'arrêt de l'abonnement"}
            show={showConfirmModal}
            onHide={() => setShowConfirmModal(false)}
            footerContent={<button className={'btn btn-light'} onClick={() => setShowConfirmModal(false)}>Annuler</button>}
        >
            <div className="col-12 text-center">
                <p className={"text-center"}>
                    Attention ! Cette action est irréversible.
                </p>
                <button className={'btn btn-danger mx-auto'} onClick={() => updateStatus('Canceled')}>
                    <i className={'bi bi-x'}></i> Je souhaite résilier mon abonnement.
                </button>
            </div>
        </ModalComponent>
    </div>
}

export default CustomerSubscriptionShow;