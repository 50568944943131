import {Quote, QuoteRows} from "../../../../../../models/quote";
import React, {useEffect, useState} from "react";
import * as api from "../../../../../../adapters/corporate/provider/quote/row";
import Loader from "../../../../../../utils/loader";
import ProviderQuoteRowShow from "../show";
import ErrorCard from "../../../../../error";

const ProviderQuoteRowList = ({quote}: {quote: Quote}) => {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [payload, setPayload] = useState<{count: number, rows: QuoteRows}>({count: 0, rows: []})

    useEffect(() => {
        setLoading(true)
        api.list({quote_id: quote.id})
            .then(r => setPayload(r.data))
            .catch(reason => setError(reason))
            .finally(() => setLoading(false))
    }, [quote.id])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    return <div className="card border border-1">
        <div className="card-header">
            <h6>
                Détail de la prestation
            </h6>
        </div>
        <div className="card-body">
            <div className={'col-12 mb-3'}>
                <div className="table-responsive">
                    <table className={"table"}>
                        <thead>
                        <tr>
                            <th>
                                Produit
                            </th>
                            <th>
                                Description
                            </th>
                            <th>
                                PU HT
                            </th>
                            <th>
                                Tax
                            </th>
                            <th>
                                PU TTC
                            </th>
                            <th>
                                Quantité
                            </th>
                            <th>
                                Total TTC
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {payload.rows.length ? payload.rows.map((r, key) => <ProviderQuoteRowShow key={key} row={r} />) : <tr className={"list-group-item"}>
                            <td colSpan={6}><i className={'bi bi-search'}></i> Aucun résultat</td>
                        </tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
}

export default ProviderQuoteRowList;