import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import * as models from "../../models/category"
import {AxiosError} from "axios";

interface CategoryState {
    payload: models.Categories,
    single: models.Category|null,
    loading: boolean,
    errorMessage: string|null
}

const initialState = {
    payload: [],
    single: null,
    loading: false,
    errorMessage: ''
} as CategoryState;

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        failure: (state, action: PayloadAction<AxiosError>) => {
            state.loading = false;
            // @ts-ignore
            state.errorMessage = action.payload.response?.data?.message || action.payload.message;
        },
        load: (state) => {
            state.loading = true;
        },
        show: (state) => {
            state.single = null;
            state.loading = true;
        },
        showSuccess: (state, action: PayloadAction<models.Category>) => {
            state.single = action.payload;
            state.loading = false;
        },
        list: (state) => {
            state.payload = [];
            state.loading = true;
        },
        listSuccess: (state, action: PayloadAction<models.Categories>) => {
            state.payload = action.payload;
            state.loading = false;
        },
        edit: (state) => {
            state.loading = true;
        },
        editSuccess: (state, action: PayloadAction<models.Category>) => {
            state.single = action.payload;
            state.payload = [...state.payload.filter(category => category.id !== action.payload.id), action.payload];
            state.loading = false;
        },
        add: (state) => {
            state.loading = true;
        },
        addSuccess: (state, action: PayloadAction<models.Category>) => {
            state.single = action.payload;
            state.payload = [...state.payload, action.payload];
            state.loading = false;
        },
        remove: (state) => {
            state.loading = true;
        },
        removeSuccess: (state, action: PayloadAction<models.Category>) => {
            state.single = null;
            state.payload = [...state.payload.filter(c => c.id !== action.payload.id)];
            state.loading = false;
        },
    }
})

export default categorySlice