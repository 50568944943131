import React, {useEffect, useState} from "react";
import * as models from "../../../../../../../models/affiliation";
import {FetchParams, Payload} from "../../../../../../../models/global";
import ErrorCard from "../../../../../../error";
import * as api from "../../../../../../../adapters/corporate/provider/affiliation/support"
import Loader from "../../../../../../../utils/loader";
import Pagination from "../../../../../../../utils/pagination";
import SearchInput from "../../../../../../../utils/searchInput";
import ProviderAffiliationSupportListItem from "./item";

const ProviderAffiliationSupportList = ({affiliation}: {affiliation: models.Affiliation}) => {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [payload, setPayload] = useState<Payload<models.AffiliationSupports>>({count: 0, rows: []})
    const [fetchParams, setFetchParams] = useState<FetchParams>({query: '', offset: 0})

    useEffect(() => {
        setLoading(true)
        api.list(affiliation.id, fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [affiliation.id, fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={'col-12 mb-3'}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher un support..."} onChange={handleQueryChange} size={"default"} />
                </div>
            </div>
        </div>
        {error && <div className={"col-12 mb-3"}>
            <ErrorCard error={error} />
        </div>}
        <div className="col-12 mb-3 position-relative">
            {loading && <div className={'h-100 w-100 position-absolute'}>
                <div className="row justify-content-center">
                    <div className="col-auto py-10">
                        <Loader />
                    </div>
                </div>
            </div>}
            <div className="table-responsive">
                <table className={'table'}>
                    <thead>
                    <tr>
                        <th>
                            Nom
                        </th>
                        <th>
                            Date d'ajout
                        </th>
                        <th>
                            Type de fichier
                        </th>
                        <th>

                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        {payload.count ? payload.rows.map(r => <ProviderAffiliationSupportListItem affiliation={affiliation} item={r} handleDestroy={support => setPayload(prevState => ({count: prevState.count - 1, rows: [...prevState.rows.filter(r => r.id !== support.id)]}))} />) : <tr>
                            <td className={'text-muted'} colSpan={4}>
                                <i className={'bi bi-search'}></i> Aucun résultat
                            </td>
                        </tr>}
                    </tbody>
                </table>
            </div>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prevState => ({...prevState, offset: o}))} />
        </div>
    </div>
}

export default ProviderAffiliationSupportList;