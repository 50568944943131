import * as models from "../../../../../../models/subscription";
import React from "react";
import {useNavigate} from "react-router-dom";
import SubscriptionStatusPlaceholder from "../../../../../common/subscription/status/placeholder";
import {getLogoUrl} from "../../../../../../utils/image";

const SupplierSubscriptionListItem = ({item}: {item: models.Subscription}) => {

    const navigate = useNavigate();

    return <tr onClick={() => navigate(`/corporate/supplier/subscription/${item.id}`)}>
        <td>
            {item.account.user.email}
        </td>
        <td>
             <span className="avatar avatar-sm me-2">
                 <img src={getLogoUrl(item.partnership.provider)} alt={item.partnership.provider.name} className="avatar-img rounded-circle" />
             </span>
            {item.partnership.provider.name}
        </td>
        <td>
            <SubscriptionStatusPlaceholder status={item.status} />
        </td>
    </tr>
}

export default SupplierSubscriptionListItem;