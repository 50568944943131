import React, {useEffect, useState} from "react";
import {LineItem} from "../../../../models/lineItem";
import * as api from "../../../../adapters/cart";
import {Currency} from "../../../../models/currency";
import {Country} from "../../../../models/country";
import QuantityInput from "./quantity";
import convertToDecimal from "../../../../utils/currencyConverter";
import {useAppDispatch} from "../../../../hooks";
import cartSlice from "../../../../reducers/cart";
import {ProductDeclination, ProductDeclinationChoice} from "../../../../models/product";
import {getCoverUrl} from "../../../../utils/image";
import ModalComponent from "../../../modal";
import ProductShowCarousel from "../../../corporate/common/product/carousel";
import { useTranslation } from 'react-i18next';

const CartItemShow = ({item, currency, country, source, sourceId}: { item: LineItem, country: Country, currency: Currency, source: "shop"|"link"|"pack", sourceId: string }) => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [openedDeclinations, setOpenedDeclinations] = useState<{[key: number]: boolean}>({});
    const [showCarouselModal, setShowCarouselModal] = useState(false);

    useEffect(() => {

        let _initiallyOpened: any = {}

        for (const data of item.declination_data) {
            _initiallyOpened[data.declination.id] = !data.selected;
        }

        setOpenedDeclinations(_initiallyOpened);

    }, [])

    function remove(){
        api.removeFromCart(item.id, {currency_id: currency.id, country_id: country.id})
            .then(r => dispatch(cartSlice.actions.setCart({
                cart: r.data,
                storageKey: `${source}-${sourceId}`
            })))
    }

    function updateDeclinationChoice(declination: ProductDeclination, choice: ProductDeclinationChoice){
        api.updateDeclinationChoice(item.id, {currency_id: currency.id, country_id: country.id, declination: declination.id, choice: choice.id})
            .then(r => dispatch(cartSlice.actions.setCart({
                cart: r.data,
                storageKey: `${source}-${sourceId}`
            })))
            .then(() => setOpenedDeclinations(prev => ({...prev, [declination.id]: false})))
    }

    return <>
        <li className="list-group-item" key={item.id}>
            <div className="row align-items-center">
                <div className="col-md-4">
                    <img className="img-fluid cursor-pointer" src={getCoverUrl(item.product)} alt="..." onClick={() => setShowCarouselModal(true)}/>
                </div>
                <div className="col-md-8">
                    <p className="fs-sm fw-bold mb-3">
                        <span className="text-body">
                            {item.product.name}
                        </span>
                        <span className="clearfix"></span>
                        {item.isCurrencySupported ? <span className="text-muted">
                            {convertToDecimal(item.price_data.total_amount_including_tax, currency)} TTC
                            {item.price_data.recurring && <>
                                {item.price_data.recurring.intervalCount === 1 ? " par " : `tout les ${item.price_data.recurring.intervalCount}`} {t(`${item.price_data.recurring.interval}`)}
                                {item.price_data.recurring.trialPeriodDays > 0 && <>{item.price_data.recurring.trialPeriodDays} jours d'essai gratuit</>}
                            </>}
                            <span className="clearfix"></span>
                        </span> : <span className="text-danger"><i className={'bi bi-exclamation-circle'}></i> Indisponible en {currency.name}</span>}

                        <span className="clearfix"></span>
                    </p>
                    <p className={'mb-3'}>
                        {item.declination_data.length > 0 && item.declination_data.map(data => <>
                            <div className="form-text">{data.declination.name}: <span className={'fw-semibold'}>{data.selected ? data.selected.value : <i className={'bi bi-x'}></i>} {!openedDeclinations[data.declination.id] && <button className={'btn btn-xxs'} onClick={() => setOpenedDeclinations(prev => ({...prev, [data.declination.id]: true}))}><i className={"bi bi-pencil"}></i></button>}</span></div>
                            {!data.selected && <div className={'text-info'}>Veuillez séléctionner une option.</div>}
                            {openedDeclinations[data.declination.id] && <div className="mb-2">
                                {data.options.map((c, ck) => <div
                                    className="form-check form-check-inline form-check-size ps-0 mb-2">
                                    <input checked={data.selected?.id === c.id} type="radio" className="form-check-input"
                                           id={[data.declination.id, c.id].join('-')}
                                           onChange={() => updateDeclinationChoice(data.declination, c)}/>
                                    <label className="form-check-label"
                                           htmlFor={[data.declination.id, c.id].join('-')}>{c.value}</label>
                                </div>)}
                            </div>}
                        </>)}
                    </p>
                    {!item.isShippingSupported && <div className={'d-flex align-items-center'}>
                        {!item.isShippingSupported && <span className={'text-danger'}>
                            <i className={'bi bi-exclamation-circle'}></i> Indisponible en {country.name}
                        </span>}
                    </div>}
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            {item.editable ? <QuantityInput source={source} sourceId={sourceId} item={item} key={item.id} currency={currency} country={country} /> : <>
                                <span className="form-text">
                                    Quantité: <span className="fw-semibold">{item.quantity} {item.unit ? item.unit.label : ''}</span>
                                </span>
                            </>}
                            {!item.isQuantitySupported && <div className={"text-danger"}>
                                <i className={"bi bi-exclamation-circle"}></i> Quantité max : {item.supportedQuantity}
                            </div>}
                        </div>
                        {item.editable && <div className="col-md-4">
                            <button className="btn btn-xxs fs-xs text-gray-400 ms-auto" onClick={() => remove()}>
                                <i className="bi bi-x"></i> Retirer
                            </button>
                        </div>}
                    </div>
                </div>
            </div>
        </li>
        <ModalComponent size={"modal-lg"} title={`Images ${item.product.name}`} show={showCarouselModal} onHide={() => setShowCarouselModal(false)}>
            <ProductShowCarousel product={{...item.product}} />
        </ModalComponent>
    </>
}

export default CartItemShow;