import React, {ChangeEvent, useState} from "react";
import {useFormik} from "formik";
import FormError from "../../../../../utils/formError";
import * as Yup from "yup"
import * as api from "../../../../../adapters/corporate/supplier/product";
import Loader from "../../../../../utils/loader";
import {Link, useNavigate} from "react-router-dom";
import ShippingSelect from "../../../../../utils/shippingSelect";
import Select from "../../../../../utils/select";
import {getCurrencies} from "../../../../../utils/currencies";
import SupplierUnitSelect from "../../unit/select";
import SupplierFeeRuleSelect from "../../feeRule/select";
import ErrorCard from "../../../../error";

const SupplierProductAdd = () => {

    const navigate = useNavigate();
    const [priceType, setPriceType] = useState<"punctual"|"recurring">("punctual")
    const [error, setError] = useState(null)

    const [initialValues, setInitialValues] = useState<any>({
        name: '',
        description: '',
        type: 'product',
        sellable: false,
        fee_rule_id: null
    });

    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        name: Yup.string().required(),
        description: Yup.string().nullable(),
        type: Yup.string().required(),
        sellable: Yup.boolean().required(),
        fee_rule_id: Yup.number().required(),
    }));

    const formik = useFormik({
       initialValues: initialValues,
       validationSchema: validationSchema,
       enableReinitialize: true,
       onSubmit: (values, {setSubmitting}) => {
           setError(null)
           api.create(values)
               .then(resp => navigate(`/corporate/supplier/product/${resp.data.id}`))
               .catch(e => setError(e))
               .finally(() => setSubmitting(false))
       }
    });

    function handleSellableChange(e: ChangeEvent<HTMLInputElement>){

        let values = {...formik.values}
        let schema = {...validationSchema.fields}
        values.sellable = e.target.checked;

        if (e.target.checked){
            values.freeSale = true;
            schema.shippable = Yup.boolean().required()
            values.shippable = false;
            schema.shippable = Yup.boolean().required()
            values.storable = false;
            schema.storable = Yup.boolean().required()
            values.minQuantity = 1;
            schema.minQuantity = Yup.number().positive().required();;
            values.maxQuantity = null;
            schema.maxQuantity = Yup.number().positive().nullable();
            values.unit_id = null;
            schema.unit_id = Yup.number().required();
            values.price = {
                currency_id: "",
                amount: "",
                feePercent: ""
            }
            schema.price = Yup.object().shape({
                currency_id: Yup.number().required(),
                amount: Yup.number().required(),
                feePercent: Yup.number().required(),
            })
        }else{
            delete values.freeSale
            delete schema.freeSale
            delete values.shippable
            delete schema.shippable
            delete values.storable
            delete schema.storable
            delete values.minQuantity
            delete schema.minQuantity
            delete values.maxQuantity
            delete schema.maxQuantity
            delete values.unit_id
            delete schema.unit_id
            delete values.price
            delete schema.price
            delete values.shipping_id;
            delete schema.shipping_id;
        }

        setValidationSchema(Yup.object().shape({...schema}))
        setInitialValues({...values});
    }

    function handleShippableChange(e: ChangeEvent<HTMLInputElement>){

        let values = {...formik.values}
        let schema = {...validationSchema.fields}

        if (e.target.checked){
            values.shippable = true;
            values.shipping_id = null;
            schema.shipping_id = Yup.number().required();
        }else{
            values.shippable = false;
            delete values.shipping_id;
            delete schema.shipping_id;
        }

        setValidationSchema(Yup.object().shape({...schema}))
        setInitialValues({...values});
    }

    function handlePriceTypeChange(type: "punctual"|"recurring")
    {
        let _values = {...formik.values.price}
        let _schema = {...validationSchema.fields.price.fields}

        switch (type){
            case "punctual":
                delete _values.recurring;
                delete _schema.recurring
                break;
            case "recurring":
                _values.recurring = {interval: "month", intervalCount: 12, trialPeriodDays: 0}
                _schema.recurring = Yup.object().shape({
                    interval: Yup.string().required(),
                    intervalCount: Yup.number().required(),
                    trialPeriodDays: Yup.number().required(),
                })
                break;
        }

        setValidationSchema((p: any) => Yup.object().shape({...p.fields, price: Yup.object().shape(_schema)}))
        setInitialValues((p: any) => ({...formik.values, price: _values}))
        setPriceType(type);
    }

    return <div className={"container-fluid"}>
        <div className="col-12 mb-3">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/corporate/supplier/catalog">Produits</Link>
                </li>
                <li className="breadcrumb-item">
                    Ajouter
                </li>
            </ol>
        </div>
        <div className="p-3">
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12 mb-3">
                    <div className="form-floating bg-body-tertiary">
                        <input disabled={formik.isSubmitting} type="text" className="form-control" id={'name'} name={'name'} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                        <label htmlFor="name">
                            Nom *
                        </label>
                    </div>
                    <FormError form={formik} field={"name"} />
                </div>
                <div className="col-12 mb-3">
                    <div className="form-floating bg-body-tertiary">
                        <textarea disabled={formik.isSubmitting} className="form-control" id={'description'} style={{height: 100}} name={'description'} onChange={formik.handleChange} onBlur={formik.handleBlur}></textarea>
                        <label htmlFor="description">
                            Description
                        </label>
                    </div>
                    <FormError form={formik} field={"description"} />
                </div>
                <p className={'mb-1'}>Type de produit</p>
                <div className="col-12 mb-3">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" defaultChecked={true} type="radio" id="product" value="product" name={"type"} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                        <label className="form-check-label" htmlFor="product">
                            Bien
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" id="service" value="service" name={"type"} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                        <label className="form-check-label" htmlFor="service">
                            Service
                        </label>
                    </div>
                    <FormError form={formik} field={"type"} />
                </div>
                <div className="col-12 mb-3">
                    <SupplierFeeRuleSelect label={"Règle de commission"} required={true} handleChange={id => formik.setFieldValue('fee_rule_id', id)} allowAdd={true} />
                </div>
                <div className="card border border-1 mb-3">
                    <div className="card-body">
                        <h6>
                            Vente direct
                        </h6>
                        <div className="col-12 mb-3">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" role="switch" id="sellable" name={"sellable"} onBlur={formik.handleBlur} onChange={handleSellableChange} />
                                <label className="form-check-label" htmlFor="sellable">
                                    Produit accessible en vente direct
                                </label>
                            </div>
                        </div>
                        {formik.values.sellable && <>
                            <div className="col-12 mb-3">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="freeSale" name={"freeSale"} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                    <label className="form-check-label" htmlFor="freeSale">
                                        Produit accessible en vente libre (peut être vendu indépendamment d'un package impliquant d'autres produits)
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <SupplierUnitSelect required={true} handleChange={id => formik.setFieldValue('unit_id', id)} allowAdd={true} />
                            </div>
                            <p className={'mb-1'}>
                                Quantité par commande
                            </p>
                            <div className="col-12 mb-3">
                                <div className="row">
                                    <div className="col-6 col-md-auto">
                                        <div className="form-floating bg-body-tertiary">
                                            <input disabled={formik.isSubmitting} type="number" className="form-control" id={'minQuantity'} name={'minQuantity'} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                            <label htmlFor="minQuantity">
                                                Minimum *
                                            </label>
                                        </div>
                                        <FormError form={formik} field={"minQuantity"} />
                                    </div>
                                    <div className="col-6 col-md-auto">
                                        <div className="form-floating bg-body-tertiary">
                                            <input disabled={formik.isSubmitting} type="number" className="form-control" id={'maxQuantity'} name={'maxQuantity'} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                            <label htmlFor="maxQuantity">
                                                Maximum
                                            </label>
                                        </div>
                                        <FormError form={formik} field={"maxQuantity"} />
                                    </div>
                                </div>
                            </div>
                            <div className="card border border-1 mb-3">
                                <div className="card-body">
                                    <h6>
                                        livraison
                                    </h6>
                                    <div className="col-12 mb-3">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" role="switch" id="shippable" name={"shippable"} onBlur={formik.handleBlur} onChange={handleShippableChange} />
                                            <label className="form-check-label" htmlFor="shippable">
                                                Produit livrable
                                            </label>
                                        </div>
                                    </div>
                                    {formik.values.shippable && <>
                                        <div className="col-12 mb-3">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="storable" name={"storable"} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                                <label className="form-check-label" htmlFor="storable">
                                                    Activer la gestion des stocks avancé
                                                </label>
                                                <FormError form={formik} field={"storable"} />
                                            </div>
                                        </div>
                                        <div className={"col-12 mb-3"}>
                                            <ShippingSelect handleChange={v => formik.setFieldValue("shipping_id", v)} required={true} />
                                        </div>
                                    </>}
                                </div>
                            </div>
                            <div className="card border border-1 mb-3">
                                <div className="card-body">
                                    <h6>
                                        Prix
                                    </h6>
                                    <div className="col-12 mb-3">
                                        <p className={"mb-1"}>Type de prix</p>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" defaultChecked={true} id="punctual" value="Punctual" name={"priceType"} onChange={() => handlePriceTypeChange("punctual")} />
                                            <label className="form-check-label" htmlFor="punctual">
                                                Ponctuel
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" id="recurring" value="Recurring" name={"priceType"} onChange={() => handlePriceTypeChange("recurring")} />
                                            <label className="form-check-label" htmlFor="recurring">
                                                Recurrent
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <div className="input-group">
                                            <Select
                                                required={true}
                                                label={"Devise *"}
                                                options={getCurrencies()}
                                                onChange={choice => formik.setFieldValue("price.currency_id", choice.id)}
                                            />
                                            <div className="form-floating">
                                                <input type="number" id={"price.amount"} step={"any"} className="form-control" name={"price.amount"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                                                <label htmlFor="price.amount">
                                                    Montant
                                                </label>
                                            </div>
                                        </div>
                                        <FormError form={formik} field={"price.currency_id"} />
                                        <FormError form={formik} field={"price.amount"} />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <div className="form-floating">
                                            <input type="number" id={"price.feePercent"} className="form-control" name={"price.feePercent"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                                            <label htmlFor="price.feePercent">
                                                Pourcentage de commission
                                            </label>
                                        </div>
                                        <FormError form={formik} field={"price.feePercent"} />
                                    </div>
                                    {priceType === "recurring" && <>
                                        <div className="col-12 mb-3">
                                            <h6>
                                                Récurrence
                                            </h6>
                                        </div>
                                        <div className="row gx-0 align-items-center">
                                            <div className="col mb-3">
                                                Une transaction tout les
                                            </div>
                                            <div className="col mb-3">
                                                <div className="form-floating">
                                                    <input type="count" id={"price.recurring.intervalCount"} className="form-control" name={"price.recurring.intervalCount"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                                                    <label htmlFor="price.recurring.intervalCount">
                                                        Interval
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col mb-3">
                                                <Select
                                                    required={true}
                                                    label={"Fréquence"}
                                                    value={{label: "Mois", value: "month"}}
                                                    options={[
                                                        {label: "Jour", value: "day"},
                                                        {label: "7 jours", value: "week"},
                                                        {label: "Mois", value: "month"},
                                                        {label: "An", value: "year"},
                                                    ]}
                                                    onChange={c => formik.setFieldValue("price.recurring.interval", c.value)}
                                                />
                                            </div>
                                            <FormError form={formik} field={"price.recurring.intervalCount"} />
                                            <FormError form={formik} field={'price.recurring.interval'} />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="form-floating">
                                                <input type="count" id={"price.recurring.trialPeriodDays"} className="form-control" name={"price.recurring.trialPeriodDays"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                                                <label htmlFor="price.recurring.trialPeriodDays">
                                                    Durée de la période d'éssai
                                                </label>
                                            </div>
                                            <FormError form={formik} field={"price.recurring.trialPeriodDays"} />
                                        </div>
                                    </>}
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
                {error && <div className={'col-12 mb-3'}>
                    <ErrorCard error={error} />
                </div>}
                <div className="col-12 text-center">
                    <button disabled={formik.isSubmitting} type={"submit"} className={'btn btn-primary w-100'}>
                        {formik.isSubmitting ? <Loader size={"sm"} /> : <><i className={'bi bi-plus-circle'}></i> Ajouter</>}
                    </button>
                </div>
            </form>
        </div>
    </div>
}

export default SupplierProductAdd;