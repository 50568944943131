import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import FormError from "../../../../../utils/formError";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../adapters/corporate/common/onboarding";
import React, {useEffect, useState} from "react";
import authSlice from "../../../../../reducers/auth";
import Select from "../../../../../utils/select";
import Loader from "../../../../../utils/loader";
import {getCountryCodes} from "../../../../../utils/tel";
import {CorporateAccount} from "../../../../../models/account";

const SettingInformation = () => {

    const account = useAppSelector(state => state.auth.user?.activeAccount) as CorporateAccount;
    const information = account.organization.information
    const dispatch = useAppDispatch();
    const [initialValues, setInitialValues] = useState<any>({
        type: information?.type || "company",
        name:  information?.name || "",
        email: information?.email || "",
        code: information?.code || "",
    });

    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        type: Yup.string().required(),
        name: Yup.string().required(),
        email: Yup.string().email().required(),
        code: Yup.string().required(),
    }))

    useEffect(() => {
        switch (account.organization.type){
            case "supplier":
                setInitialValues({
                    type: "company",
                    name:  information?.name || "",
                    email: information?.email || "",
                    code: information?.code || "",
                })

                setValidationSchema(Yup.object().shape({
                    type: Yup.string().required(),
                    name: Yup.string().required(),
                    email: Yup.string().email().required(),
                    code: Yup.string().required(),
                }))
                break;
            case "provider":
                if (information){
                    switch (information.type){
                        case "individual":
                            setInitialValues({
                                type: "individual",
                                email: information?.email || "",
                            })

                            setValidationSchema(Yup.object().shape({
                                type: Yup.string().required(),
                                email: Yup.string().email().required(),
                            }))
                            break;
                        case "company":
                            setInitialValues({
                                type: "company",
                                name:  information?.name || "",
                                email: information?.email || "",
                                code: information?.code || "",
                            })

                            setValidationSchema(Yup.object().shape({
                                type: Yup.string().required(),
                                name: Yup.string().required(),
                                email: Yup.string().email().required(),
                                code: Yup.string().required(),
                            }))
                            break;
                    }
                }else{
                    setInitialValues({
                        type: "company",
                        name:  "",
                        email: "",
                        code: "",
                    })

                    setValidationSchema(Yup.object().shape({
                        type: Yup.string().required(),
                        name: Yup.string().required(),
                        email: Yup.string().email().required(),
                        code: Yup.string().required(),
                    }))
                }
                break;
        }
    }, [account.organization.type])

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            api.information(values)
                .then(resp => dispatch(authSlice.actions.upsertAccountOrganizationInformation(resp.data)))
                .then(() => formikHelpers.setSubmitting(false))
        }
    })

    return <form onSubmit={formik.handleSubmit}>
        {account.organization.type === "provider" && account.organization.information.type === 'individual' && <div className={"alert alert-info"}>
           <i className={'bi bi-information'}></i> Vous êtes déclarer en tant que particulier et souhaitez renseigner une entreprise ? Écrivez nous à contact@varar.co
        </div>}
        {formik.values.type === "company" && <>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className={'form-control'} defaultValue={information?.name} name={"name"} id={"name"}
                           onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                    <label htmlFor="name">
                        Nom de l'entreprise (raison sociale)
                    </label>
                </div>
                <FormError form={formik} field={"name"}/>
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className={'form-control'} defaultValue={information.code ? information.code : undefined} name={"code"} id={"code"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                    <label htmlFor="code">
                        N° de Siret
                    </label>
                </div>
                <FormError form={formik} field={"code"} />
            </div>
        </>}
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="email" className={'form-control'} defaultValue={information?.email} name={"email"} id={"email"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                <label htmlFor="email">
                    Email de l'entreprise
                </label>
            </div>
            <FormError form={formik} field={"email"} />
        </div>
        <button className="btn btn-dark w-100 mb-3">
            {formik.isSubmitting ? <Loader /> : <><i className={'bi bi-check'}></i> Valider </>}
        </button>
    </form>

}

export default SettingInformation;