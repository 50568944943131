import React from "react";
import {Link} from "react-router-dom";
import * as models from "../../../../models/checkout";

const CheckoutNavHeader = ({checkout}: {checkout: models.CheckoutResponse}) => {

    return <>
        <nav className="navbar navbar-expand-lg bg-body-tertiary mb-3 sticky-top">
            <div className="container mx-auto">
                <Link className="navbar-brand align-items-center" to={checkout.source.model === "bundle" ? `/bundle/${checkout.source.id}`  : `/${checkout.source.model}/${checkout.source.id}/cart`}>
                    <img width="auto" height="60" src={process.env.PUBLIC_URL + '/img/logo.png'} className={"navbar-brand-img"} alt=""/>
                </Link>
            </div>
        </nav>
        </>
}

export default CheckoutNavHeader;