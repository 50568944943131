import {get, post} from "../../xhr";
import encodeQueryData from "../../../utils/encodeQueryData";

export function list(requestData: {query: string, offset: number, status: string}){
    return get(`/customer/returns${encodeQueryData(requestData)}`);
}

export function show(id: number){
    return get(`/customer/returns/${id}`);
}

export function add(requestData: {
    item_id: number,
    quantity: number;
    reason1: string,
    reason2: string,
    reason3: string,
}){
    return post(`/customer/returns`, requestData);
}