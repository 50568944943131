import {useFormik} from "formik";
import * as Yup from "yup";
import {Link, useNavigate} from "react-router-dom";
import React from "react";
import FormError from "../../../../../utils/formError";
import * as api from "../../../../../adapters/corporate/provider/link";
import Loader from "../../../../../utils/loader";

const ProviderLinkAdd = () => {

    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            name: "",
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
        }),
        onSubmit: (values, formikHelpers) => {
            api.add(values).then(r => navigate(`/corporate/provider/link/${r.data.id}`))
        }
    })

    return <div className={'container-fluid'}>
        <div className="col-12 mb-3">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/corporate/provider/link">Liens de paiement</Link>
                </li>
                <li className="breadcrumb-item">
                    Ajouter
                </li>
            </ol>
        </div>
        <div className="p-3">
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="text" className="form-control" id="name" name={"name"} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                        <label htmlFor="name">Nom *</label>
                    </div>
                    <FormError form={formik} field={"label"} />
                </div>
                <button disabled={formik.isSubmitting} type={'submit'} className={"btn btn-light w-100"}>
                    {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-plus-circle'}></i> Ajouter</>}
                </button>
            </form>
        </div>
    </div>
}

export default ProviderLinkAdd;