import React, {useEffect, useState} from "react";
import * as models from "../../../../../../models/refund"
import * as api from "../../../../../../adapters/corporate/supplier/order/refund"
import Loader from "../../../../../../utils/loader";
import ErrorCard from "../../../../../error";
import {Order} from "../../../../../../models/order";
import ModalComponent from "../../../../../modal";
import SupplierRefundAdd from "../add";
import SupplierOrderRefundListItem from "./item";

const SupplierOrderRefundList = ({order}: {order: Order}) => {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [payload, setPayload] = useState<{count: number, rows: models.Refunds}>({count: 0, rows: []})
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        setLoading(true)
        setError(null)
        api.list(order.id)
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [order.id])

    return (
        <>
            <div className={"card border border-1"}>
                <div className="card-header">
                    <div className="row">
                        <div className="col">
                            <h6>
                                Remboursements
                            </h6>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-xs btn-primary" onClick={() => setShowModal(true)}>
                               <i className={"bi bi-plus-circle"}></i> Ajouter
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {error && <ErrorCard error={error} />}
                    <ul className={"list-group"}>
                        {loading && <li className={"list-group-item"}>
                            <Loader />
                        </li>}
                        {payload.rows.map(r => <SupplierOrderRefundListItem refund={r} />)}
                    </ul>
                </div>
            </div>
            <ModalComponent size={"modal-lg"} title={"Ajouter un remboursement"} show={showModal} onHide={() => setShowModal(false)}>
                <SupplierRefundAdd order={order} items={order.items} parcels={order.parcels} onSuccess={refund => {
                    setPayload(prev => ({count: prev.count + 1, rows: [refund, ...prev.rows]}))
                    setShowModal(false)
                }} />
            </ModalComponent>
        </>
    );
}

export default SupplierOrderRefundList;