import React, {useEffect, useState} from "react";
import * as api from '../../../../../adapters/corporate/provider/conversion';
import {Affiliation, Conversions} from "../../../../../models/affiliation";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";
import {Payload} from "../../../../../models/global";
import ProviderConversionListItem from "./item";

const ProviderConversionList = ({affiliation, status}: {affiliation?: Affiliation, status: "All"|"Pending"|"Accepted"|"Refused"|"Paid"}) => {

    const [payload, setPayload] = useState<Payload<Conversions>>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number}>({query: "", offset: 0})

    useEffect(() => {
        setLoading(true)
        api.list({offset: fetchParams.offset, count: 50, query: fetchParams.query, affiliation_id: affiliation?.id, status: status})
            .then(resp => setPayload(resp.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams, affiliation?.id, status])

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        <div className="col-md-12 flex-grow-1">
            {!loading && <div className={'table-responsive'}>
                <table className={'table'}>
                    <thead>
                    <tr>
                        <th>
                            Affiliation
                        </th>
                        <th>
                            Règle
                        </th>
                        <th>
                            Montant
                        </th>
                        <th>
                            Commission
                        </th>
                        <th>
                            Fournisseur
                        </th>
                        <th>
                            Date
                        </th>
                        <th>
                            Statut
                        </th>
                        <th>

                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {payload.rows.map(r => <ProviderConversionListItem item={r} />)}
                    </tbody>
                </table>
            </div>}
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        </div>
    </div>
}

export default ProviderConversionList;