import React from "react";
import {Link} from "react-router-dom";
import {Country} from "../../../../models/country";
import {Currency} from "../../../../models/currency";
import {Organization} from "../../../../models/organization";
import {getLogoUrl} from "../../../../utils/image";

const PackNavHeader = ({uuid, country, currency, provider}: {uuid: string, country: Country, currency: Currency, provider: Organization}) => {

    return <>
        <div className="navbar navbar-topbar navbar-expand-xl navbar-light bg-light z-index-1">
            <div className="container">

                <div className="me-xl-8 me-2 align-items-center">
                    <span className="fs-xxs">Powered by <Link to={'https://varar.co'} target={"_blank"} replace><img src={process.env.PUBLIC_URL + '/img/logo.png'} alt="" height={25} width={'auto'}/></Link></span>
                </div>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#topbarCollapse" aria-controls="topbarCollapse" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="topbarCollapse">
                    <ul className="nav nav-divided navbar-nav me-auto">
                        <li className="nav-item hovered">
                            <button className="nav-link" data-bs-toggle="modal" data-bs-target={"#countryModal"}>
                                <i className={'bi bi-geo-alt'}></i>
                                {country.name}
                            </button>
                        </li>
                        <li className="nav-item hovered">
                            <button className="nav-link" data-bs-toggle="modal" data-bs-target={"#currencyModal"}>
                                {currency.symbol} {currency!.name}
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <nav className="navbar navbar-expand-lg bg-body-tertiary mb-3 sticky-top">
            <div className="container mx-auto">
                <Link className="navbar-brand" to={`/pack/${uuid}`}>
                    <img width="auto" height="60" src={getLogoUrl(provider)} className={"navbar-brand-img"} alt=""/>
                </Link>
            </div>
        </nav>
        </>
}

export default PackNavHeader;