import {post, get, destroy} from "../../../../xhr";

export function edit(id: number, declinationId: number, requestData: {
    name: string;
    type: string;
}){
    return post(`corporate/supplier/product/${id}/declination/${declinationId}`, requestData);
}

export function remove(id: number, declinationId: number){
    return destroy(`corporate/supplier/product/${id}/declination/${declinationId}`);
}

export function add(id: number, requestData: {
    name: string;
    multiple: boolean;
    required: boolean;
    stockLinked: boolean;
}){
    return post(`corporate/supplier/product/${id}/declination`, requestData);
}