import * as models from "../../../../../../../../models/subscription";
import {Link} from "react-router-dom";
import convertToDecimal from "../../../../../../../../utils/currencyConverter";
import {useState} from "react";
import ModalComponent from "../../../../../../../modal";
import SupplierSubscriptionItemUpdate from "../../update";
import {SubscriptionItem} from "../../../../../../../../models/subscription";

const SubscriptionShowItemListItem = ({subscription, item, handleUpdate, deactivate}: {subscription: models.Subscription, item: models.SubscriptionItem, handleUpdate: (item: SubscriptionItem) => void, deactivate: (item: SubscriptionItem) => void}) => {

    const [showUpdateModal, setShowUpdateModal] = useState(false);

    return <tr key={item.id}>
        <td>
            <Link to={`/corporate/product/${item.price.product.id}`}>
                {item.price.product.name}
            </Link>
        </td>
        <td>
            {item.quantity}
        </td>
        <td>
            {convertToDecimal(item.priceData.total_amount_including_tax, item.priceData.currency)} tout les {item.priceData.recurring.intervalCount} {item.priceData.recurring.interval}
        </td>
        <td>
            <div className="dropdown">
                <button className="btn btn-xxs btn-light" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                    <i className="bi bi-three-dots-vertical"></i>
                </button>
                <ul className="dropdown-menu">
                    <li className="dropdown-item" onClick={() => setShowUpdateModal(true)}><i className={"bi bi-pencil text-info"}></i> Modifier</li>
                    <li className="dropdown-item" onClick={() => deactivate(item)}><i className={'bi bi-x text-danger'}></i> Retirer</li>
                </ul>
            </div>
            {showUpdateModal && <ModalComponent size={"modal-lg"} title={"Modifier un article"} show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
                <SupplierSubscriptionItemUpdate subscription={subscription} item={item} onSuccess={updatedItem => {
                    handleUpdate(updatedItem)
                    setShowUpdateModal(false)
                }}/>
            </ModalComponent>}
        </td>
    </tr>
}

export default SubscriptionShowItemListItem;