import React, {useState} from "react";
import * as models from "../../../../../../models/product";
import * as api from "../../../../../../adapters/corporate/supplier/product/feature";
import SupplierProductFeatureAdd from "../add";
import {Feature} from "../../../../../../models/feature";
import ModalComponent from "../../../../../modal";

const SupplierProductFeatureShow = ({ product, setProduct }: { product: models.Product & models.SellableProduct, setProduct: React.Dispatch<any> }) => {

    function unlink(feature: Feature){
        api.unlink(product.id, feature.id)
            .then(() => setProduct((prev: models.Product & models.SellableProduct) => ({...prev, features: prev.features.filter(tr => tr.id !== feature.id)})))
    }

    const [showLinkModal, setShowLinkModal] = useState(false)

    return <div className={'col-12 mb-3'} id={'Features'}>
        <div className="card border border-1">
            <div className="card-header">
                <div className="row align-items-center">
                    <div className="col">
                        <h6>
                            Fonctionnalités
                        </h6>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-xxs btn-primary" onClick={() => setShowLinkModal(true)}>
                            Lier des fonctionnalités
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="table-responsive">
                    <table className={'table'}>
                        <thead>
                        <tr>
                            <th>
                                Description
                            </th>
                            <th>

                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {product.features.map(item => <tr>
                            <td>
                                {item.description}
                            </td>
                            <td>
                                <button className="btn btn-xxs btn-light text-danger"
                                        onClick={() => unlink(item)}>
                                    <i className={'bi bi-dash-circle'}></i>
                                </button>
                            </td>
                        </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <ModalComponent title={"Lier des fonctionnalités"} show={showLinkModal} onHide={() => setShowLinkModal(false)}>
            <SupplierProductFeatureAdd product={product} setProduct={setProduct} unlink={unlink} />
        </ModalComponent>
    </div>
}

export default SupplierProductFeatureShow;