import encodeQueryData from "../../../../utils/encodeQueryData";
import {post, get} from "../../../xhr";

export function list(requestData: { query?: string, offset: number, assignment_id?: number}){
    return get(`corporate/provider/proposal${encodeQueryData(requestData)}`)
}

export function show(proposal: number){
    return get(`corporate/provider/proposal/${proposal}`)
}

export function add(requestData: any){
    return post(`corporate/provider/proposal`, requestData)
}

export function update(proposal: number, requestData: any){
    return post(`corporate/provider/proposal/${proposal}`, requestData);
}