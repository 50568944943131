import * as models from "../../../../../models/order";
import * as api from "../../../../../adapters/customer/order/parcel";
import React, {useEffect, useState} from "react";
import {AxiosError} from "axios";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import Pagination from "../../../../../utils/pagination";
import CustomerParcelShow from "../show";

const CustomerParcelList = ({order}: {order: models.Order}) => {

    const [payload, setPayload] = useState<{count: number, rows: models.Parcels}>({count: 0, rows: []})
    const [loading, setLoading] = useState(true);
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string}>({offset: 0, query: ""})
    const [error, setError] = useState<AxiosError>()

    useEffect(() => {
        setLoading(true)
        api.list(order.id, fetchParams)
            .then(r => setPayload(r.data))
            .catch(error => setError(error))
            .finally(() => setLoading(false))
    }, [fetchParams])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    return (<>
        <div className={'row'}>
            {!payload.rows.length && <div className={'text-muted'}><i className={"bi bi-search"}></i> Aucun résultat</div>}
            {payload.rows.map(r => <CustomerParcelShow parcel={r} key={r.id} />)}
        </div>
        <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
    </>)
}

export default CustomerParcelList;