import { Document, Page } from 'react-pdf';
import {useState} from "react";
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const FileViewer = ({url, mimeType}: {url: string, mimeType: string}) => {

    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    const renderFile = () => {
        if (mimeType.startsWith('image/')) {
            return <img src={url} alt="file" className={'img-fluid'} />;
        } else if (mimeType === 'application/pdf') {
            return (
                <>
                    <div className="row justify-content-center">
                        <div className="col-auto mb-3">
                            <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
                                <Page pageNumber={pageNumber} />
                            </Document>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-auto mb-3">
                            <div className="button-group">
                                <button className={"btn btn-xs"} disabled={pageNumber === 1} onClick={() => setPageNumber(prevState => prevState - 1)}>
                                    <i className={'bi bi-chevron-left'}></i>
                                </button>
                                <span className={'btn btn-xxs'}>
                                    {pageNumber}/{numPages}
                                </span>
                                <button className={"btn btn-xs"} disabled={pageNumber === numPages} onClick={() => setPageNumber(prevState => prevState + 1)}>
                                    <i className={'bi bi-chevron-right'}></i>
                                </button>
                                {/*<a href={url} type={'btn btn-xxs text-dark text-decoration-underline'} download="document.pdf">*/}
                                {/*    <i className={'bi bi-download'}></i> Télécharger*/}
                                {/*</a>*/}
                            </div>
                        </div>
                    </div>

                </>
            );
        } else if (mimeType.startsWith('video/')) {
            return (
                <video controls style={{ maxWidth: '100%', height: 'auto' }}>
                    <source src={url} type={mimeType} />
                    Your browser does not support the video tag.
                </video>
            );
        } else {
            return <p>Unsupported file type</p>;
        }
    };

    return <div className={"col"}>{renderFile()}</div>;
}

export default FileViewer;