import encodeQueryData from "../../../../../utils/encodeQueryData";
import {post, get} from "../../../../xhr";

interface Interface {
    name?: string,
    details?: string,
    delay?: number
    default?: boolean
}

export function list(requestData: { query: string, offset: number}){
    return get(`corporate/supplier/returns-policy${encodeQueryData(requestData)}`)
}

export function show(policy: number){
    return get(`corporate/supplier/returns-policy/${policy}`)
}

export function add(requestData: Interface){
    return post(`corporate/supplier/returns-policy`, requestData)
}

export function update(policy: number, requestData: Interface){
    return post(`corporate/supplier/returns-policy/${policy}`, requestData);
}

