import {Currency} from "../../../models/currency";
import {Country} from "../../../models/country";
import React, {useEffect} from "react";
import * as api from "../../../adapters/link";
import Loader from "../../../utils/loader";
import Cart from "../../cart";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import cartSlice from "../../../reducers/cart";

const LinkCart = ({uuid, currency, country}: {uuid: string, currency: Currency, country: Country}) => {

    const dispatch = useAppDispatch()
    const cart = useAppSelector(state => state.cart)

    useEffect(() => {
        dispatch(cartSlice.actions.loadCart())
        api.cart(uuid, {country_id: country.id, currency_id: currency.id})
            .then(r => dispatch(cartSlice.actions.setCart({cart: r.data, storageKey: null})))
    }, [uuid, currency, country])

    if (cart.loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    return <Cart source={"link"} sourceId={uuid} country={country} currency={currency} />
}

export default LinkCart;