import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query?: string, offset: number, organization_id?: number, sectors?: number[], sort?: "trending"|"latest"|"ranking"}){
    return get(`/corporate/provider/assignment${encodeQueryData(requestData)}`);
}

export function show(assignment: number){
    return get(`/corporate/provider/assignment/${assignment}`);
}

export function statistics(assignment: number, requestData: {startDate: Date, endDate: Date, granularity: string}){
    return post(`/corporate/provider/assignment/${assignment}/statistics`, requestData);
}