import {get, post, destroy} from "../../../../xhr";
import encodeQueryData from "../../../../../utils/encodeQueryData";

export function list(postId: number, requestData: { offset: number }){
    return get(`/corporate/common/post/${postId}/reaction${encodeQueryData(requestData)}`);
}

export function add(postId: number, requestData: { type: 'heart'|'hand-thumbs-up' }){
    return post(`/corporate/common/post/${postId}/reaction`, requestData);
}

export function remove(postId: number, reactionId: number){
    return destroy(`/corporate/common/post/${postId}/reaction/${reactionId}`);
}