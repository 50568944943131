import Loader from "../../../../../../utils/loader";
import React, {useEffect, useState} from "react";
import * as api from "../../../../../../adapters/corporate/supplier/shipping/address";
import * as models from "../../../../../../models/shipping";
import {Link, useParams} from "react-router-dom";

const SupplierShippingAddressShow = () => {

    const params = useParams()
    const [address, setAddress] = useState<models.ShippingAddress>()
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.show(Number(params.id))
            .then(r => setAddress(r.data))
            .then(() => setLoading(false))
    }, [])

    if (loading){
        return <div className={'container-fluid py-10 text-center'}>
            <Loader />
        </div>
    }

    return <div className={"container-fluid"}>
        <ol className="breadcrumb mb-3 fs-xs">
            <li className="breadcrumb-item">
                Expédition
            </li>
            <li className="breadcrumb-item">
                Lieux de traitement
            </li>
            <li className="breadcrumb-item">
                {address?.line1}
            </li>
        </ol>
        {address && <div className={'col-12 mb-3'}>
            <div className="p-3 border border-1 rounded bg-white position-relative">
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-text">
                            Pays
                        </div>
                        <div>
                            {address.country.flag && <img style={{height: 18, width: "auto"}} src={address.country.flag} alt={address.country.name}/>} {address.country.name}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-text">
                            Ville
                        </div>
                        <p>
                            {address.city}
                        </p>
                    </div>
                    <div className="col-md-3">
                        <div className="form-text">
                            Code postal
                        </div>
                        <p>
                            {address.postalCode}
                        </p>
                    </div>
                    <div className="col-md-3">
                        <Link to={`/corporate/supplier/shipping/address/${address.id}/update`} className={'btn btn-light'}>
                            <i className={'bi bi-pencil'}></i> Mettre à jour
                        </Link>
                    </div>
                    <div className="col-md-6">
                        <div className="form-text">
                            Adresse
                        </div>
                        <p>
                            {address.line1}
                        </p>
                    </div>
                    <div className="col-md-6">
                        <div className="form-text">
                            Complément d'adresse
                        </div>
                        <p>
                            {address.line2}
                        </p>
                    </div>
                </div>
            </div>
        </div>}
    </div>
}

export default SupplierShippingAddressShow;