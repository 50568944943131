import React, {useEffect, useState} from "react";
import * as api from "../../../adapters/checkout";
import {useNavigate} from "react-router-dom";
import * as models from "../../../models/checkout";
import CheckoutAddressUnavailable from "./unavailable";
import ErrorCard from "../../error";
import CheckoutAddressShipping from "./shipping";
import CheckoutAddressBilling from "./billing";
import Loader from "../../../utils/loader";

const CheckoutAddresses = ({checkout, setCheckout}: {checkout: models.CheckoutResponse, setCheckout: React.Dispatch<any>}) => {

    const navigate = useNavigate();
    const {shippable, uuid} = checkout;

    const [error, setError] = useState(null);
    const [billingSameAsShipping, setBillingSameAsShipping] = useState(true);
    const [submitting, setSubmitting] = useState(false)

    useEffect(() => {
        if (billingSameAsShipping && checkout.account_data.shipping?.id){
            api.billing(checkout.uuid, checkout.account_data.shipping?.id)
                .then(r => setCheckout(r.data))
        }
    }, [checkout.account_data.shipping?.id, billingSameAsShipping])

    function goToNextStep(data: models.CheckoutResponse){
        setSubmitting(true)
        api.confirmAddress(data.uuid)
            .then(() => navigate(shippable ? `/checkout/${uuid}/delivery` : `/checkout/${uuid}/payment`))
            .catch(e => setError(e))
            .finally(() => setSubmitting(false))
    }

    return <div className={"col-12"}>
        {error && <ErrorCard error={error} />}
        {shippable && <>
            <CheckoutAddressShipping checkout={checkout} setCheckout={setCheckout} />
            {checkout.account_data.shipping && <>
                <CheckoutAddressUnavailable checkout={checkout} setCheckout={setCheckout}/>
                <div className="col-12 mb-3">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" checked={billingSameAsShipping} id={"billingSameAsShipping"} onChange={e => setBillingSameAsShipping(e.target.checked)} />
                        <label className="form-check-label" htmlFor="billingSameAsShipping">
                            L'Adresse de facturation est identique à l'adresse de livraison
                        </label>
                    </div>
                </div>
            </>}
        </>}

        {(!shippable || !billingSameAsShipping) && <CheckoutAddressBilling checkout={checkout} setCheckout={setCheckout} />}

        {((shippable && checkout.account_data.shipping && checkout.account_data.billing) || (!shippable && checkout.account_data.billing)) && <button className={'btn btn-light w-100'} type={"button"} onClick={() => goToNextStep(checkout)}>
            {submitting ? <Loader size={'sm'}/> : <>Étape suivante <i className={'bi bi-arrow-right'}></i></>}
        </button>}
    </div>
}

export default CheckoutAddresses;