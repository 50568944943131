import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../../adapters/corporate/supplier/product/file";
import * as models from "../../../../../../models/product";
import Dropzone from "react-dropzone";
import FormError from "../../../../../../utils/formError";
import Loader from "../../../../../../utils/loader";
import ErrorCard from "../../../../../error";
import imageCompression from "browser-image-compression";

const SupplierProductFileAdd = ({product, setProduct}: {product: models.Product, setProduct: React.Dispatch<any>}) => {

    let initialValues: { files: File[] } = {files: []}
    const [error, setError] = useState(null)

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            files: Yup.array().of(Yup.mixed().test("fileSize", "The file is too large", (file: any) => {
                return file;
            })),
        }),
        onSubmit: (values, formikHelpers) => {
            const formData = new FormData();
            for (const key in values.files) {
                formData.append('files', values.files[key], values.files[key].name);
            }

            api.add(product.id, formData)
                .then(resp => setProduct((prev: models.Product) => ({...prev, files: [...prev.files, ...resp.data]})))
                .then(() => formikHelpers.resetForm())
                .catch(e => setError(e))
                .finally(() => formikHelpers.setSubmitting(false))
        }
    })

    const handleFileChange = async (selectedFiles: File[]) => {

        // Options for image compression
        const options = {
            maxSizeMB: 1,        // (1 MB) Maximum size allowed
            maxWidthOrHeight: 800,  // Max width or height to maintain aspect ratio
            useWebWorker: true,   // For better performance
        };

        try {

            let compressedFiles: File[] = []

            for (let i = 0; i < selectedFiles.length; i++) {
                const compressedFile = await imageCompression(selectedFiles[i], options);
                compressedFiles.push(compressedFile);
            }

            // Compress the image file

            // Now you can upload the compressed file to the server
            await formik.setFieldValue('files', compressedFiles);

            // Here you would normally send the file with a POST request using something like:
            // const formData = new FormData();
            // formData.append('file', compressedFile);
            // axios.post('your-server-endpoint', formData);
        } catch (error) {
            console.error('Error compressing the file:', error);
        }
    }

    return <form onSubmit={formik.handleSubmit}>
        <Dropzone accept={{
            'image/*': ['.jpg', '.jpeg', '.png', '.tif'],
            'video/*': ['.mp4', '.mov'],
        }} disabled={formik.isSubmitting} onDrop={acceptedFiles => formik.setFieldValue('files', [...formik.values.files, ...acceptedFiles])}>
            {({getRootProps, getInputProps}) => (
                <section className={'h-100'}>
                    <div className={'h-100'} {...getRootProps()}>
                        <input {...getInputProps()} className={'d-none'} />
                        <div className={'py-8 bg-body-secondary border border-1 text-center'}>
                            <i className={'bi bi-images'}></i> Déposez vos fichiers ici ou cliquez pour importer
                            <div className="clearfix"></div>
                            {!!formik.values.files.length && <ul className={'list-group text-center mt-3'}>
                                {formik.values.files.map((f: File) => <li className={'list-group-item'}>{f.name}</li>)}
                            </ul>}
                        </div>
                    </div>
                </section>
            )}
        </Dropzone>
        <FormError form={formik} field={'files'} />
        {!!formik.values.files.length && <button type={'submit'} disabled={!formik.values.files.length} className={"btn btn-dark w-100"}>
            {formik.isSubmitting ? <Loader /> : <><i className={'bi bi-check'}></i> Importer les fichiers</>}
        </button>}
        {error && <ErrorCard error={error} />}
    </form>
}

export default SupplierProductFileAdd;