import React from "react";
import {Navigate, useNavigate} from "react-router-dom";
import SupplierWebhookList from "../webhook/list";

const SupplierDeveloper = ({tab}: {tab?: "webhook"|"apikey"}) => {

    const navigate = useNavigate();

    if (!tab){
        return <Navigate to={'webhook'} />
    }

    return <div className={"container-fluid"}>
        <div className="col-12 mb-3">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    Développeur
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3">
            <div className="mb-2 mb-lg-0 border-bottom-lg">
                <nav className="nav nav-tabs nav-overflow fs-xs border-bottom border-bottom-lg-0">
                    <button onClick={() => navigate('products')} className={"nav-link text-uppercase" + (tab === 'webhook' ? " active " : "")}>
                        Webhook
                    </button>
                    <button onClick={() => navigate('taxRate')} className={"nav-link text-uppercase" + (tab === "apikey" ? " active " : "")}>
                        Clefs d'Api
                    </button>
                </nav>
            </div>
        </div>
        {tab === "webhook" && <SupplierWebhookList />}
    </div>
}

export default SupplierDeveloper;