import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {useAppDispatch} from "../../../hooks";
import authSlice from "../../../reducers/auth";
import {socket} from "../../../socket";

const CustomerNav = () => {

    const dispatch = useAppDispatch();

    useEffect(() => {
        socket.connect();
        socket.on('message.received', (e) => console.log(e))
        socket.on('notification.received', (e) => console.log(e))

        return () => {
            socket.disconnect();
            socket.off('message.received', (e) => console.log(e))
            socket.off('notification.received', (e) => console.log(e))
        }
    }, [])

    return <nav className="navbar bg-body-tertiary py-1 flex-grow-0">
        <div className="container mx-auto">
            <Link className="navbar-brand" to={"/customer"}>
                <img src={process.env.PUBLIC_URL + '/img/logo.png'} className={'logo'} alt=""/>
            </Link>
            <div className="row align-items-center ms-auto">
                <div className="col-auto">
                    <Link to={'/customer/account'}  className="btn btn-xxs btn-circle bg-body-secondary">
                        <i className={'bi bi-person-circle'}></i>
                    </Link>
                </div>
                <div className="col-auto">
                    <Link to={'/corporate/chat'} data-cart-items={3}  className="btn btn-xxs btn-circle bg-body-secondary">
                        <i className={'bi bi-chat'}></i>
                    </Link>
                </div>
                <div className="col-auto">
                    <Link to={'/corporate/notification'} data-cart-items={35}  className="btn btn-xxs btn-circle bg-body-secondary">
                        <i className={'bi bi-bell'}></i>
                    </Link>
                </div>
                <div className="col-auto">
                    <button onClick={() => dispatch(authSlice.actions.logout())} className="btn btn-xxs btn-circle bg-body-secondary">
                        <i className={"bi bi-toggle-off"}></i>
                    </button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-xxs btn-circle bg-body-secondary ms-auto d-block d-md-none" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseNav" aria-expanded="false" aria-controls="collapseNav">
                        <i className={"bi bi-list"}></i>
                    </button>
                </div>
            </div>
        </div>
    </nav>
}

export default CustomerNav;