import * as models from "../../../../../../../../../models/affiliation";
import React, {useState} from "react";
import ModalComponent from "../../../../../../../../modal";
import convertToDecimal from "../../../../../../../../../utils/currencyConverter";

const SupplierAffiliationConversionRuleListItem = ({item}: {item: models.ConversionRule}) => {

    const [showModal, setShowModal] = useState(false);

    return <tr>
        <td>
            {item.name}
        </td>
        <td>
            {item.uuid}
        </td>
        <td>
            {item.validation}
        </td>
        <td>
            {item.validationDelay} jours
        </td>
        <td>
            <button className={'btn btn-xxs'} onClick={() => setShowModal(true)}>
                <i className={'bi bi-eye'}></i> Compensations
            </button>
            <ModalComponent title={`Compensations ${item.name}`} show={showModal} onHide={() => setShowModal(false)}>
                <table className={'table'}>
                    <tbody>
                    {item.compensations.map(c => <tr>
                        <th>{c.currency.name} ({c.currency.symbol})</th>
                        {item.type === "Fix" && <td>{convertToDecimal(c.amount, c.currency)}</td>}
                        {item.type === "Percent" && <td>{c.amount}%</td>}
                    </tr>)}
                    </tbody>
                </table>
            </ModalComponent>
        </td>
    </tr>
}

export default SupplierAffiliationConversionRuleListItem;