import {useEffect, useState} from "react";
import * as models from "../../../../../../../models/subscription";
import * as api from "../../../../../../../adapters/corporate/supplier/subscription/occurrence";
import Loader from "../../../../../../../utils/loader";
import ErrorCard from "../../../../../../error";
import Pagination from "../../../../../../../utils/pagination";
import SupplierSubscriptionShowOccurrenceListItem from "./item";

const SupplierSubscriptionShowOccurrenceList = ({subscription}: {subscription: models.Subscription}) => {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState();
    const [payload, setPayload] = useState<{count: number, rows: models.SubscriptionOccurrences}>({count: 0, rows: []})
    const [fetchParams, setFetchParams] = useState<{offset: number}>({offset: 0});

    useEffect(() => {
        setLoading(true)
        setError(undefined);
        api.list(subscription.id, fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [subscription.id, fetchParams])

    if (loading){
        return <div className={"col-12 text-center"}>
            <Loader />
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    return (<>
        <ul className={"list-group"}>
            {payload.count ? payload.rows.map(r => <SupplierSubscriptionShowOccurrenceListItem subscription={subscription} occurrence={r} key={r.id} />) : <li className={'list-group-item text-center text-muted'}>
                <i className={'bi bi-search'}></i> Aucun résultat
            </li>}
        </ul>
        <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
    </>)

}

export default SupplierSubscriptionShowOccurrenceList;