import * as models from "../../../../../../../../models/shipping";
import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../../../../adapters/corporate/supplier/shipping/rate";
import {Offcanvas} from "bootstrap";
import FormError from "../../../../../../../../utils/formError";
import Loader from "../../../../../../../../utils/loader";

const SupplierShippingRateAdd = ({area, handleSuccess}: {area: models.ShippingArea, handleSuccess: (r: models.ShippingRate) => void}) => {

    const formik = useFormik({
        initialValues: {
            name: '',
            delayMin: 1,
            delayMax: 2,
        },
        validationSchema:  Yup.object().shape({
            name: Yup.string().required(),
            delayMin: Yup.number().required().min(1),
            delayMax: Yup.number()
                .required()
                .min(1)
                .test('is-greater', 'delayMax must be greater than delayMin', function (value) {
                    const { delayMin } = this.parent; // Accessing delayMin from the parent object
                    return value > delayMin;
                }),
        }),
        onSubmit: (values, formikHelpers) => {
            api.add({...values, area_id: area.id})
                .then(r => handleSuccess(r.data))
                .then(() => formikHelpers.setSubmitting(false))
                .then(() => close())
        }
    })

    function close(){
        let el = document.getElementById("offcanvasShippingRateAdd")

        if (el){
            (new Offcanvas(el)).hide()
        }
    }

    return <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasShippingRateAdd"
                aria-labelledby="offcanvasShippingRateAddLabel">
        <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasShippingRateAddLabel"><i className={'bi bi-plus-circle'}></i> Ajouter un forfait</h5>
            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvasShippingRateAdd" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="text" className={'form-control'} name={"name"} id={"name"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                        <label htmlFor="name">
                            Nom *
                        </label>
                    </div>
                    <FormError form={formik} field={"name"} />
                </div>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="number" defaultValue={1} className={'form-control'} name={"delayMin"} id={"delayMin"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                        <label htmlFor="delayMin">
                            Délai minimum (jour) *
                        </label>
                    </div>
                    <FormError form={formik} field={"delayMin"} />
                </div>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="number" defaultValue={2} className={'form-control'} name={"delayMax"} id={"delayMax"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                        <label htmlFor="delayMax">
                            Délai maximum (jour) *
                        </label>
                    </div>
                    <FormError form={formik} field={"delayMax"} />
                </div>
                <button className="btn btn-light w-100 mb-3">
                    {formik.isSubmitting ? <Loader /> : <><i className={'bi bi-check'}></i> Valider</>}
                </button>
            </form>
        </div>
    </div>
}

export default SupplierShippingRateAdd;