import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as api from "../../../../../adapters/corporate/supplier/unit"
import FormError from "../../../../../utils/formError";
import Loader from "../../../../../utils/loader";
import * as Yup from "yup";
import {Offcanvas} from "bootstrap";
import {ProductUnit} from "../../../../../models/product";

const SupplierUnitAdd = ({handleSuccess}: {handleSuccess: (unit: ProductUnit) => void}) => {

    const formik = useFormik({
        initialValues: {
            label: "",
            step: 1
        },
        validationSchema: Yup.object().shape({
            label: Yup.string().required(),
            step: Yup.string().required(),
        }),
        enableReinitialize: true,
        onSubmit: (values: any) => {
            api.add(values)
                .then(r => handleSuccess(r.data))
                .finally(() => formik.setSubmitting(false))
        }
    });

    return  <form onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input className="form-control" type="text" id="label" name={"label"} onChange={formik.handleChange} />
                <label htmlFor="label">
                    Label *
                </label>
            </div>
            <FormError form={formik} field={"label"} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input className="form-control" type="number" id="step" step={"any"} min={0.0001} name={"step"} onChange={formik.handleChange} />
                <label htmlFor="step">
                    Arrondi *
                </label>
            </div>
            <span className="form-text">
                        Décimal possible pour la quantité d'un produit. (Ex: 1, 0.1, 0.01)
                    </span>
            <FormError form={formik} field={"step"} />
        </div>
        <button disabled={formik.isSubmitting} type={'button'} onClick={() => formik.submitForm()} className={"btn btn-light w-100"}>
            {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-plus-circle'}></i> Ajouter</>}
        </button>
    </form>
}

export default SupplierUnitAdd;