import {post, get} from "../../../../xhr";

interface Interface {
    items?: {
        type: string,
        order_item_id?: number;
        quantity?: number;
        parcel_id?: number;
        amount?: number
    }[]
}

export function list(orderId: number){
    return get(`corporate/supplier/order/${orderId}/refund`)
}

export function add(orderId: number, requestData: any){
    return post(`corporate/supplier/order/${orderId}/refund`, requestData);
}

