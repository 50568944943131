import React, {useState} from "react";
import {Affiliation} from "../../../../../../models/affiliation";
import Highlight from "react-highlight";
const SupplierAffiliationShowSettings = ({affiliation}: { affiliation: Affiliation }) => {

    const [tab, setTab] = useState<'tag'|'img'|'iframe'>('tag')

    const clickTrackingScript = `
<!-- Varar Tag --> 
<script type="text/javascript">
    var _va = {
        "pid": "${affiliation.uuid}",
    };
    (function() {
        var element = document.createElement('script'); 
        element.type = 'text/javascript'; 
        element.async = true;
        element.src = '//varar.co/js/va-1.js';
        var scr = document.getElementsByTagName('script')[0]; 
        scr.parentNode.insertBefore(element, scr);
    })();
</script>
    `

    const conversionTrackingTag = `
<script type="text/javascript">
    var vaEvent = {};
    vaEvent.rule = '{{RULE_ID}}'; /* Required */
    /* Values below must be updated */
    vaEvent.externalId = '{{ID}}'; /* Unique Id of your choice (order_id, hash of user email etc.) Optional */
    
    vaEvent.amount = '{{AMOUNT}}'; /* Amount exluding tax. Required if rule type is percentage */
    vaEvent.currency = '{{CURRENCY_CODE}}'; /* currency code (EUR, USD, ...). Required if rule type is percentage */

    vaEvent.payment = '{{PAYMENT}}'; /* payment type (cb, SEPA,...) Optional */
    vaEvent.vouchers = '{{VOUCHER_CODE}}'; /* List of voucher code separated by ; Optional (eg. '123;323;65') */
    vaEvent.customer = '{{CUSTOMER_ID}}'; /* Id of user in your system (recomanded if rule attribution is share) Optional */
    vaEvent.products = '{{PRODUCT_ID}}'; // List of product id separated by ; (eg. '123;323;65')
    ('VaTracker' in window)
      ? VaTracker.sendConversion(vaEvent)
      : (window.VA = window.VA || []).push(vaEvent);
</script>
    `
    const conversionTrackingIframe = `
    <!-- Values in query string must be updated -->
    <iframe src="https://app.varar.co/conversion?key={{KEY}}&id={{UNIQUE_ID}}&amount={{AMOUNT}}&payment={{PAYMENT}}&customer={{CUSTOMER_ID}}&cv={{SUB_ID}}&voucher={{VOUCHER_CODE}}&currency={{CURRENCY_CODE}}" frameborder="0" width="1" height="1"></iframe>
    `
    const conversionTrackingImg = `
    <!-- Values in query string must be updated -->
    <img src="https://app.varar.co/conversion?key={{KEY}}&id={{UNIQUE_ID}}&amount={{AMOUNT}}&payment={{PAYMENT}}&customer={{CUSTOMER_ID}}&cv={SUB_ID}&voucher={{VOUCHER_CODE}}&currency={{CURRENCY_CODE}}" style="display: none;" width="0" border="0" height="0" />
    `
    return <>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Étape 1 : Tracking
                    </h6>
                    <p className={'mb-1 text-muted'}>
                        Inclure le code ci-dessous sur toutes les pages dans la balise <code>&lt;head&gt;</code>
                    </p>
                </div>
                <div className="card-body">
                    <Highlight className={"javascript"}>
                        {clickTrackingScript}
                    </Highlight>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Étape 2 : Conversions
                    </h6>
                    <p className={'mb-1 text-muted'}>
                        Inclure le code ci-dessous sur vos pages de conversion
                    </p>
                </div>
                <div className="card-body">
                    {/*<nav className="nav nav-tabs nav-overflow fs-xs border-bottom border-bottom-lg-0">*/}
                    {/*    <button className={"nav-link text-uppercase" + (tab === "tag" ? " active " : "")} onClick={() => setTab("tag")}>*/}
                    {/*        Tag javascript (recommandé)*/}
                    {/*    </button>*/}
                    {/*    <button className={"nav-link text-uppercase" + (tab === "img" ? " active " : "")} onClick={() => setTab("img")}>*/}
                    {/*        Pixel image*/}
                    {/*    </button>*/}
                    {/*    <button className={"nav-link text-uppercase" + (tab === "iframe" ? " active " : "")} onClick={() => setTab("iframe")}>*/}
                    {/*        Iframe*/}
                    {/*    </button>*/}
                    {/*</nav>*/}
                    {tab === 'tag' && <Highlight className={"javascript"}>
                        {conversionTrackingTag}
                    </Highlight>}
                    {/*{tab === 'img' && <Highlight className={"javascript"}>*/}
                    {/*    {conversionTrackingImg}*/}
                    {/*</Highlight>}*/}
                    {/*{tab === 'iframe' && <Highlight className={"javascript"}>*/}
                    {/*    {conversionTrackingIframe}*/}
                    {/*</Highlight>}*/}
                </div>
            </div>
        </div>
    </>
}

export default SupplierAffiliationShowSettings;