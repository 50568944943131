import React, {useEffect, useState} from "react";
import * as models from "../../../../../../../../models/refund"
import * as api from "../../../../../../../../adapters/corporate/provider/subscription/occurrence/refund"
import Loader from "../../../../../../../../utils/loader";
import ErrorCard from "../../../../../../../error";
import {Subscription, SubscriptionOccurrence} from "../../../../../../../../models/subscription";
import ProviderSubscriptionOccurrenceRefundListItem from "./item";

const ProviderSubscriptionOccurrenceRefundList = ({subscription, occurrence}: {subscription: Subscription, occurrence: SubscriptionOccurrence}) => {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [payload, setPayload] = useState<{count: number, rows: (models.Refund & models.SubscriptionRefund)[]}>({count: 0, rows: []})

    useEffect(() => {
        setLoading(true)
        setError(null)
        api.list(subscription.id, occurrence.id)
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [occurrence.id])

    return (
        <>
            {error && <ErrorCard error={error} />}
            <ul className={"list-group"}>
                {loading && <li className={"list-group-item"}>
                    <Loader />
                </li>}
                {payload.rows.map(r => <ProviderSubscriptionOccurrenceRefundListItem refund={r} />)}
            </ul>
        </>
    );
}

export default ProviderSubscriptionOccurrenceRefundList;