import {get, post} from "../xhr";
import encodeQueryData from "../../utils/encodeQueryData";

export function show(uuid: string)
{
    return get(`/link/${uuid}`);
}

export function cart(uuid: string, requestData: {currency_id: number, country_id: number})
{
    return get(`/link/${uuid}/cart${encodeQueryData(requestData)}`);
}

export function checkout(uuid: string, requestData: {country: number})
{
    return post(`/link/${uuid}/checkout`, requestData);
}