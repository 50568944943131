import {useAppSelector} from "../../../../../hooks";
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Loader from "../../../../../utils/loader";
import {CorporateAccount} from "../../../../../models/account";
import * as api from "../../../../../adapters/corporate/common/onboarding";
import ErrorCard from "../../../../error";

const OnBoardingVerification = () => {

    const user = useAppSelector(state => state.auth.user)!
    const account = useAppSelector(state => state.auth.user!.activeAccount) as CorporateAccount;
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState(null)

    function handleValidation(){
        setLoading(true)
        api.validate()
            .then(() => navigate('/'))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }

    return <>
        <h3 className={"mb-8"}>
            Vérification des informations
        </h3>
        <div className="row">
            <div className="col-12 mb-3">
                <div className="p-3 bg-body-tertiary">
                    <div className="row align-items-center">
                        <div className="col mb-3">
                            <h6>
                                Mon account
                            </h6>
                            {isAccountCompleted() ? <div className={"form-text text-success"}><i className={'bi bi-check-circle'}></i> Profil complété</div> : <div className={"form-text text-danger"}><i className={'bi bi-exclamation-circle'}></i> Informations manquantes</div>}
                        </div>
                        <div className="col-auto mb-3">
                            <Link to={'/onboarding/account'} className={'text-decoration-underline'}>
                                Modifier <i className={'bi bi-pencil'}></i>
                            </Link>
                        </div>
                        <div className="col-12">
                            <div className="row row-cols-2">
                                <div className="col">
                                    <div className="form-text">
                                        Prénom
                                    </div>
                                    <p>
                                        {account.firstname}
                                    </p>
                                </div>
                                <div className="col">
                                    <div className="form-text">
                                        Nom
                                    </div>
                                    <p>
                                        {account.lastname}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="p-3 bg-body-tertiary">
                    <div className="row align-items-center">
                        <div className="col mb-3">
                            <h6>
                                Mon entreprise
                            </h6>
                            {isOrganizationCompleted() ? <div className={"form-text text-success"}><i className={'bi bi-check-circle'}></i> Entreprise complété</div> : <div className={"form-text text-danger"}><i className={'bi bi-exclamation-circle'}></i> Informations manquantes</div>}
                        </div>
                        <div className="col-auto mb-3">
                            <Link to={'/onboarding/organization'} className={'text-decoration-underline'}>
                                Modifier <i className={'bi bi-pencil'}></i>
                            </Link>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-text">
                                        Type
                                    </div>
                                    <p>
                                        {account.organization.type === "provider" ? "Vendeur" : "Fournisseur"}
                                    </p>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-text">
                                        Nom usuel
                                    </div>
                                    <p>
                                        {account.organization.name}
                                    </p>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-text">
                                        Taille de l'entreprise
                                    </div>
                                    <p>
                                        {account.organization.size}
                                    </p>
                                </div>
                                <div className="col">
                                    <div className="form-text">
                                        Sécteurs
                                    </div>
                                    {account.organization.sectors.map(s => <span className={"badge bg-primary me-2 mb-2"}>
                                        {s.name}
                                    </span>)}
                                </div>
                                {account.organization.type === "provider" && <div className="col">
                                    <div className="form-text">
                                        Spécialisations
                                    </div>
                                    <div>
                                        {account.organization.specialisations.map(s => <span className={"badge bg-primary me-2 mb-2"}>
                                            {s.title}
                                        </span>)}
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="p-3 bg-body-tertiary">
                    <div className="row align-items-center">
                        <div className="col mb-3">
                            <h6>
                                Informations
                            </h6>
                            {isOrganizationInformationCompleted() ? <div className={"form-text text-success"}><i className={'bi bi-check-circle'}></i> Informations complété</div> : <div className={"form-text text-danger"}><i className={'bi bi-exclamation-circle'}></i> Informations manquantes</div>}
                        </div>
                        <div className="col-auto mb-3">
                            <Link to={'/onboarding/information'} className={'text-decoration-underline'}>
                                Modifier <i className={'bi bi-pencil'}></i>
                            </Link>
                        </div>
                        <div className="col-12">
                            <div className="row row-cols-3">
                                <div className="col">
                                    <div className="form-text">
                                        Vous êtes
                                    </div>
                                    <p>
                                        {account.organization.information?.type === "company" ? 'Entreprise' : 'Particulier'}
                                    </p>
                                </div>
                                {account.organization.information?.type === 'company' && <>
                                    <div className="col">
                                        <div className="form-text">
                                            Dénomination sociale
                                        </div>
                                        <p>
                                            {account.organization.information?.name}
                                        </p>
                                    </div>
                                    <div className="col">
                                        <div className="form-text">
                                            Siret
                                        </div>
                                        <p>
                                            {account.organization.information?.code}
                                        </p>
                                    </div>
                                </>}
                                <div className="col">
                                    <div className="form-text">
                                        Email
                                    </div>
                                    <p>
                                        {account.organization.information?.email}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="p-3 bg-body-tertiary">
                    <div className="row align-items-center">
                        <div className="col mb-3">
                            <h6>
                                Adresse
                            </h6>
                            {isOrganizationAddressCompleted() ? <div className={"form-text text-success"}><i className={'bi bi-check-circle'}></i> Adresse complété</div> : <div className={"form-text text-danger"}><i className={'bi bi-exclamation-circle'}></i> Informations manquantes</div>}
                        </div>
                        <div className="col-auto mb-3">
                            <Link to={'/onboarding/address'} className={'text-decoration-underline'}>
                                Modifier <i className={'bi bi-pencil'}></i>
                            </Link>
                        </div>
                        <div className="col-12">
                            <div className="row row-cols-3">
                                <div className="col">
                                    <div className="form-text">
                                        Pays
                                    </div>
                                    <p>
                                        {account.organization.country?.name}
                                    </p>
                                </div>
                                <div className="col">
                                    <div className="form-text">
                                        Adresse
                                    </div>
                                    <p>
                                        {account.organization.billing?.line1}
                                    </p>
                                </div>
                                <div className="col">
                                    <div className="form-text">
                                        Ville
                                    </div>
                                    <p>
                                        {account.organization.billing?.city}
                                    </p>
                                </div>
                                <div className="col">
                                    <div className="form-text">
                                        Code postale
                                    </div>
                                    <p>
                                        {account.organization.billing?.postalCode}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {error && <div className={"col-12 mb-3"}>
                <ErrorCard error={error} />
            </div>}
            <div className="col-12 mb-3">
                <button className="btn btn-primary w-100" disabled={!isAccountCompleted() || !isOrganizationCompleted() || !isOrganizationInformationCompleted() || !isOrganizationAddressCompleted()} onClick={() => handleValidation()}>
                    {loading ? <Loader /> : <>Confirmer mes informations <i className={"bi bi-arrow-right"}></i></>}
                </button>
            </div>
        </div>
    </>

    function isAccountCompleted(){
        let account = user.activeAccount;

        return account.firstname && account.lastname;
    }

    function isOrganizationCompleted(){

        if (account.organization.type)
        {
            switch (account.organization.type){
                case "provider":
                    return account.organization.country && account.organization.name && account.organization.size && account.organization.sectors.length > 0 && account.organization.specialisations.length > 0;
                case "supplier":
                    return account.organization.country && account.organization.name && account.organization.size && account.organization.sectors.length > 0;
            }

        }

        return false;
    }

    function isOrganizationInformationCompleted(){
        if (account.organization.information){
            switch (account.organization.information.type){
                case "company":
                    return account.organization.information.name && account.organization.information.type && account.organization.information.email && account.organization.information.code
                case "individual":
                    return account.organization.information.type && account.organization.information.email;
                default:
                    return false
            }
        }

        return false;
    }

    function isOrganizationAddressCompleted(){
        if (account.organization.billing){
            return account.organization.billing.postalCode && account.organization.billing.city
        }

        return false;
    }
}

export default OnBoardingVerification;