import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query: string, offset: number}){
    return get(`/corporate/provider/link${encodeQueryData(requestData)}`);
}

export function show(link: number)
{
    return get(`/corporate/provider/link/${link}`)
}

export function add(requestData: {name: string})
{
    return post(`/corporate/provider/link`, requestData)
}

export function update(link: number, requestData: {name?: string, quantity?: number})
{
    return post(`/corporate/provider/link/${link}`, requestData)
}

