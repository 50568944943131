import React from "react";
import {Quote} from "../../../../models/quote";
import {getLogoUrl} from "../../../../utils/image";
import {Link} from "react-router-dom";

const QuoteNavHeader = ({quote}: {quote: Quote}) => {

    return <>
        <div className="navbar navbar-topbar navbar-expand-xl navbar-light bg-light z-index-1">
            <div className="container">
                <div className="me-xl-8 me-2 align-items-center">
                    <span className="fs-xxs">Powered by <Link to={'https://varar.co'} target={"_blank"} replace><img src={process.env.PUBLIC_URL + '/img/logo.png'} alt="" height={25} width={'auto'}/></Link></span>
                </div>
            </div>
        </div>
        <nav className="navbar navbar-expand-lg bg-body-tertiary mb-3 sticky-top">
            <div className="container mx-auto">
                <div className="navbar-brand align-items-center">
                    <img width="auto" height="60" src={getLogoUrl(quote.organization)} className={"navbar-brand-img"} alt=""/> {quote.organization.name}
                </div>
            </div>
        </nav>
        </>
}

export default QuoteNavHeader;