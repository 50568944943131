import {get} from "../../../../xhr";
import encodeQueryData from "../../../../../utils/encodeQueryData";

export function list(pack: number, requestData: {query?: string, offset: number}){
    return get(`/corporate/provider/pack/${pack}/item${encodeQueryData(requestData)}`);
}

export function show(pack: number, item: number)
{
    return get(`/corporate/provider/pack/${pack}/item/${item}`)
}