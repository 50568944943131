import {getLogoUrl} from "../../../../../../utils/image";
import CommonClickStatus from "../../../../common/click/status";
import React from "react";
import {Click} from "../../../../../../models/affiliation";

const SupplierClickListItem = ({item}: {item: Click}) => {

    return <tr>
        <td>
            {item.affiliation.name}
        </td>
        <td>
            {item.rule.name}
        </td>
        <td>
            <span className="avatar avatar-sm">
                <img src={getLogoUrl(item.affiliation.organization)} alt={item.affiliation.organization.name} className="avatar-img rounded-circle" />
            </span>
            <span>
                {item.affiliation.organization.name}
            </span>
        </td>
        <td>
            {new Date(item.createdAt).toLocaleDateString()} à {new Date(item.createdAt).toLocaleTimeString()}
        </td>
        <td>
            <CommonClickStatus status={item.status} />
        </td>
    </tr>
}

export default SupplierClickListItem;