import React, {useEffect, useState} from "react";
import * as api from "../../../../adapters/customer/quote";
import SearchInput from "../../../../utils/searchInput";
import Loader from "../../../../utils/loader";
import {useNavigate} from "react-router-dom";
import Pagination from "../../../../utils/pagination";
import * as models from "../../../../models/quote";
import QuoteStatusPlaceholder from "../../../corporate/common/quote/status";
import convertToDecimal from "../../../../utils/currencyConverter";
import ErrorCard from "../../../error";

const CustomerQuoteList = () => {

    const [payload, setPayload] = useState<{ count: number, rows: models.Quotes }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number, status: "All"|"Refused"|"Accepted"|"Completed"|"Validation"}>({query: "", offset: 0, status: "All"})
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(resp => setPayload(resp.data))
            .catch(error => setError(error))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams(prev => ({...prev, query: e.target.value, offset: 0})), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    Vos devis
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher un devis..."} onChange={handleQueryChange} size={"default"} />
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        {error && <div className="col-12 mb-3 text-center">
            <ErrorCard error={error}/>
        </div>}
        <div className="col-md-12 flex-grow-1">
            {!loading && <div className={'row'}>
                {payload.rows.map(q => <div className="col-12 mb-3">
                    <div className="card border border-1 shadow-hover" onClick={() => navigate(`/customer/quote/${q.id}`)}>
                        <div className="card-header">
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    Devis N°{q.number}
                                </div>
                                <div className="flex-grow-0">
                                    <QuoteStatusPlaceholder status={q.status} />
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <h6>
                                        Représentant commercial
                                    </h6>
                                    <table className={"table-borderless"}>
                                        <tbody>
                                        <tr>
                                            <th>
                                                <i className={'bi bi-person'}></i>
                                            </th>
                                            <td>
                                                {q.project.account.firstname} {q.project.account.lastname}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <i className={'bi bi-phone'}></i>
                                            </th>
                                            <td>
                                                {q.project.account.countryCode} {q.project.account.tel}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-6">
                                    <h6>
                                        Récapitulatif
                                    </h6>
                                    <table className={"table"}>
                                        <tbody>
                                        <tr>
                                            <th>
                                                Total HT
                                            </th>
                                            <td>
                                                {convertToDecimal(q.priceData.total_amount_excluding_tax, q.priceData.currency)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Taxes
                                            </th>
                                            <td>
                                                {convertToDecimal(q.priceData.total_tax_amount, q.priceData.currency)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Total TTC
                                            </th>
                                            <td>
                                                {convertToDecimal(q.priceData.total_amount_including_tax, q.priceData.currency)}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>)}

            </div>}
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        </div>
    </div>
}

export default CustomerQuoteList;