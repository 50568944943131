import {Webhook, WebhookEvent} from "../../../../../../models/webhook";
import React, {useState} from "react";
import * as api from "../../../../../../adapters/corporate/supplier/webhook/event";
import ErrorCard from "../../../../../error";
import Highlight from "react-highlight";
import 'highlight.js/styles/a11y-light.css';

const SupplierWebhookEventShow = ({event, webhook}: {event?: WebhookEvent, webhook: Webhook}) => {

    const [refreshing, setRefreshing] = useState(false);
    const [error, setError] = useState(null);

    const refresh = (ev: WebhookEvent) => {
        setRefreshing(true)
        setError(null)
        api.refresh(webhook.id, ev.id)
            .then(r => console.log(r))
            .catch(error => setError(error))
            .finally(() => setRefreshing(false))
    }

    if (!event){
        return <div className="card border border-1">
            <div className="card-body">
                <p className={"text-center text-muted"}>
                    Aucun évènement séléctionné
                </p>
            </div>
        </div>
    }

    return <div className={'card border border-1'}>
        <div className="card-header">
            <div className="row">
                <div className="col">
                    <h6>
                        {event.slug}
                    </h6>
                </div>
                <div className="col-auto">
                    <button disabled={refreshing} className={'btn btn-xs btn-light'} onClick={() => refresh(event)}>
                        <i className={'bi bi-arrow-repeat'}></i>
                    </button>
                </div>
            </div>
        </div>
        <div className="card-body">
            {error && <ErrorCard error={error} />}
            <div className={"row"}>
                <div className="col-12 mb-3">
                    <div className="card border border-1">
                        <div className="card-header">
                            <h6>
                                Réponse
                            </h6>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <p>
                                        Statut: {event.responseStatus}
                                    </p>
                                </div>
                                <div className="col-12 mb-3">
                                    <p>
                                        Corp de la réponse
                                    </p>
                                    <pre>
                                       <pre>
                                           {JSON.stringify(event.responseBody, null, 4)}
                                       </pre>
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <div className="card border border-1">
                        <div className="card-header">
                            <h6>
                                Requete
                            </h6>
                        </div>
                        <div className="card-body">
                            <Highlight className={"json"}>
                                {JSON.stringify(JSON.parse(event.body), null, 4)}
                            </Highlight>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default SupplierWebhookEventShow;