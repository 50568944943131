import {Link} from "react-router-dom";

const Customer = () => {


    const tabs = [
        {
            icon: "bi bi-bag",
            title: "Vos commandes",
            content: "Suivre, retourner ou acheter à nouveau.",
            link: "/customer/order"
        },
        {
            icon: "bi bi-bag",
            title: "Vos devis",
            content: "Suivre et gérer vos devis.",
            link: "/customer/quote"
        },
        {
            icon: "bi bi-arrow-repeat",
            title: "Vos abonnements",
            content: "Suivre et gérer vos abonnements",
            link: "/customer/subscription"
        },
        {
            icon: "bi bi-lock",
            title: "Connexion et sécurité",
            content: "Modifier vos identifiants et vos informations personnelles.",
            link: "/customer/account"
        },
        {
            icon: "bi bi-geo-alt",
            title: "Adresses",
            content: "Modifier vos identifiants et vos informations personnelles.",
            link: "/customer/address"
        },
        {
            icon: "bi bi-credit-card",
            title: "Moyens de paiement",
            content: "Modifier vos identifiants et vos informations personnelles.",
            link: "/customer/payment-method"
        },
        {
            icon: "bi bi-envelope",
            title: "Messagerie",
            content: "Échangez avec Varar et les vendeurs tiers.",
            link: "/customer/returns"
        },
    ]


    return <div className={"row row-cols-1 row-cols-md-3"}>
        {tabs.map(t => <div className="col mb-3">
            <Link to={t.link} className="card shadow">
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <i className={`${t.icon} fs-3`}></i>
                        </div>
                        <div className="col">
                            <h6>
                                {t.title}
                            </h6>
                            <p className={"text-muted mb-0"}>
                                {t.content}
                            </p>
                        </div>
                    </div>
                </div>
            </Link>
        </div>)}
    </div>
}

export default Customer;