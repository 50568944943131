import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import * as api from "../../adapters/bundle";
import {Country} from "../../models/country";
import {Currency} from "../../models/currency";
import Loader from "../../utils/loader";
import BundleNavHeader from "./nav/header";
import BundleNavFooter from "./nav/footer";
import ErrorCard from "../error";
import {Bundle, BundleItem, BundleItemPrice} from "../../models/bundle";
import {Feature, Features} from "../../models/feature";
import {useTranslation} from "react-i18next";
import BundleItemComponent from "./item";

const BundleShow = () => {

    const { t } = useTranslation();
    const {token} = useParams();
    const [loading, setLoading] = useState(!!token);
    const [error, setError] = useState<any>(null)
    const [bundle, setBundle] = useState<Bundle>();
    const [country, setCountry] = useState<Country>()
    const [currency, setCurrency] = useState<Currency>()
    const [sorted, setSorted] = useState<{[key: string]: {item: BundleItem, price: BundleItemPrice}[]}>({});
    const [features, setFeatures] = useState<Features>([]);

    useEffect(() => {
        if (token){
            fetch(currency?.id, country?.id);
        }
    }, [token])

    async function fetch(currency_id?: number, country_id?: number){
        if(token){
            try {
                setLoading(true)
                const {data} = await api.show(token, {currency_id: currency_id, country_id: country_id});

                setBundle(data.bundle)
                setCountry(data.country)
                setCurrency(data.currency)
            }catch (e) {
                setError(e)
            } finally {
                setLoading(false)
            }
        }
    }


    useEffect(() => {

        if(bundle){

            let _features: Features = [];

            // Create a map to count occurrences of each feature by its id
            const featureMap: Map<number, { feature: Feature; count: number }> = new Map();

            for (const item of bundle.items) {
                for (const feature of item.product.features) {
                    const featureEntry = featureMap.get(feature.id);
                    if (featureEntry) {
                        featureEntry.count += 1;
                    } else {
                        featureMap.set(feature.id, { feature, count: 1 });
                    }
                }
            }

            // Convert the map to an array of FeatureCount objects and sort by count in descending order
            _features = Array.from(featureMap.values())
                .sort((a, b) => b.count - a.count)
                .map(({ feature }) => feature); // Extract the features only

            setFeatures(_features);

            let _sorted: {[key: string]: {item: BundleItem, price: BundleItemPrice}[]} = {}

            for (const item of bundle.items) {
                for (const price of item.prices) {

                    if (price.data.recurring){

                        switch (price.data.recurring.intervalCount){
                            case 1:
                                switch (price.data.recurring.interval){
                                    case "day":
                                        _sorted['daily'] = [...(_sorted.daily || []), {item, price}]
                                        break;
                                    case "week":
                                        _sorted['weekly'] = [...(_sorted.weekly || []), {item, price}]
                                        break;
                                    case "month":
                                        _sorted['monthly'] = [...(_sorted.monthly || []), {item, price}]
                                        break;
                                    case "year":
                                        _sorted['annually'] = [...(_sorted.annually || []), {item, price}]
                                        break;
                                }
                                break;
                            default:
                                _sorted[`every_${price.data.recurring.intervalCount}_${price.data.recurring.interval}`] = [...(_sorted[`every_${price.data.recurring.intervalCount}_${price.data.recurring.interval}`] || []), {item, price}]
                        }

                    }else{
                        _sorted['oneTime'] = [...(_sorted.oneTime || []), {item, price}]
                    }
                }
            }

            setSorted(_sorted);
        }

    }, [bundle])

    const DisplayKey = ({value}: {value: string}) => {

        let ret = '';
        const parts = value.split('_');

        for (let i = 0; i < parts.length; i++) {
            ret += ` ${t(parts[i])}`
        }

        return <span className={'first-letter-capitalize'}>{ret}</span>
    }

    if (!token){
        return <div className={'col-12 py-10 text-center'}>
            <h6>
                <i className={'bi bi-exclamation-circle'}></i> Lien invalide
            </h6>
        </div>
    }

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!bundle || !currency || !country){
        return <></>
    }

    return <div className={'container-fluid h-100 d-flex flex-column px-0'}>
        <BundleNavHeader token={token} country={country} currency={currency} supplier={bundle.organization} />
        <div className="container mx-auto py-8">
            <h2 className="text-center mb-8">
                Plans & Pricing
            </h2>
            <div className="col-12 mb-8">
                <div className="row justify-content-center">
                    <div className="col-auto">
                        <div className="p-3 rounded bg-light">
                            <ul className="nav nav-pills" id="pricingTab" role="tablist">
                                {Object.keys(sorted).map((key, index) => <li className="nav-item" role="presentation">
                                    <button className={`nav-link ${index === 0 ? 'active' : ''}`} id={`${key}-tab`} data-bs-toggle="tab"
                                            data-bs-target={`#${key}-tab-pane`} type="button" role="tab"
                                            aria-controls={`${key}-tab-pane`} aria-selected="true">
                                        <DisplayKey value={key} />
                                    </button>
                                </li>)}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab-content" id="pricingTabContent">
                {Object.keys(sorted).map((key, index) => <div
                    className={`tab-pane fade show ${index === 0 ? 'active': ''}`}
                    id={`${key}-tab-pane`} role="tabpanel"
                    aria-labelledby={`${key}-tab`}
                    tabIndex={0}
                >
                    <div className="row justify-content-center row-cols row-cols-md-3">
                        {sorted[key].map(({item, price}, index) => <BundleItemComponent
                            item={item}
                            country={country}
                            currency={currency}
                            price={price}
                            token={token}
                            setError={setError}
                        />)}
                    </div>
                </div>)}
            </div>
        </div>
        {features.length > 0 && <div className="container mx-auto py-8">
            <h2 className={'text-center mb-8'}>
                Toutes les fonctionnalités
            </h2>
            <table className={"table align-middle table-striped"}>
                <thead>
                <tr>
                    <th></th>
                    {bundle.items.map(item => <th>
                        <h5 className="text-center">
                            {item.product.name}
                        </h5>
                    </th>)}
                </tr>
                </thead>
                <tbody>
                {features.map(feature => <tr>
                    <td className={'fw-light'}>{feature.description}</td>
                    {bundle.items.map(item => <td
                        className={'text-center'}>{item.product.features.map(f => f.id).includes(feature.id) ?
                        <i className={"bi bi-check fw-semibold text-success fs-3"}></i> :
                        <i className={"bi bi-x fw-semibold text-muted fs-3"}></i>}</td>)}
                </tr>)}
                </tbody>
            </table>
        </div>}
        <BundleNavFooter
            setCurrency={c => fetch(c.id, country.id)}
            setCountry={c => fetch(currency.id, c.id)}
        />
    </div>
}

export default BundleShow;