import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query: string, offset: number}){
    return get(`/corporate/provider/shop${encodeQueryData(requestData)}`);
}

export function show(id: number){
    return get(`/corporate/provider/shop/${id}`);
}

export function update(id: number, requestData: {name?: string}){
    return post(`/corporate/provider/shop/${id}`, requestData);
}

export function add(requestData: {name: string}){
    return post(`/corporate/provider/shop`, requestData);
}