import React, { useState, useEffect, useRef } from "react";
import * as api from "../../../../../../adapters/inventory";
import { Country } from "../../../../../../models/country";
import { Popover } from "bootstrap";
import {Product} from "../../../../../../models/product";
import * as models from "../../../../../../models/product";

const ShopProductShowQuantityInput = ({
                           product,
                           choices,
                           quantity,
                           setQuantity,
                           country,
                       }: {
    product: models.Product & models.SellableProduct;
    choices: {[key: number]: number},
    quantity: number;
    setQuantity: React.Dispatch<any>,
    country: Country;
}) => {

    const [inputType, setInputType] = useState<"input" | "select">(
        quantity > 9 ? "input" : "select"
    );
    const [updating, setUpdating] = useState(false);
    const popoverRef = useRef<HTMLDivElement>(null);
    const [inputValue, setInputValue] = useState(quantity);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as Element;
            const popoverElement = popoverRef.current;
            if (
                popoverElement &&
                !popoverElement.contains(target) &&
                !target.closest('[data-toggle="popover"]')
            ) {
                const popover = Popover.getInstance(popoverElement);
                if (popover) {
                    popover.dispose();
                }
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    function handleChange(
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) {
        e.preventDefault();

        switch (e.target.value) {
            case "10+":
                setInputType("input");
                break;
            default:
                updateQuantity(Number(e.target.value));
        }
    }

    async function handleUpdateClick() {
        const el = document.getElementById(`quantity_${product.id}`) as HTMLInputElement;

        if (el) {
            const value = el.value;
            await updateQuantity(Number(value));
        }
    }

    async function updateQuantity(selected_quantity: number) {
        setUpdating(true);
        const {data} = await api.availableQuantity({
            country_id: country.id,
            product_id: product.id,
            choices: Object.values(choices)
        })

        const available_quantity = data.available_quantity;

        if (available_quantity !== -1 && available_quantity < selected_quantity){
            selected_quantity = available_quantity;
            const el = document.getElementById(`container_quantity_${product.id}`);

            if (el) {
                const popover = new Popover(el, {
                    trigger: "focus",
                    placement: "top",
                    content: `Le nombre d'article disponible est limité à ${available_quantity}`,
                });
                popover.show();
            }
        }

        setQuantity(selected_quantity)
        setUpdating(false);
    }

    if (inputType === "select") {
        return (
            <div ref={popoverRef} className="form-floating" id={`container_quantity_${product.id}`}>
                <select
                    key={quantity}
                    disabled={updating}
                    tabIndex={0}
                    onChange={(e) => handleChange(e)}
                    className="form-select"
                    id={`quantity_${product.id}`}
                    aria-label="Floating label select example"
                >
                    {Array.from(Array(Math.ceil(10 / (product.unit ? product.unit.step : 1))).keys()).map((value) => (
                        <option selected={value * product.unit.step === quantity} value={value * product.unit.step}>
                            {value * product.unit.step} {product.unit.label} {value === 0 && "(Retirer)"}
                        </option>
                    ))}
                    <option value="10+">10+</option>
                </select>
                <label htmlFor="floatingSelect">Quantité</label>
            </div>
        );
    }

    return (
        <div ref={popoverRef} className={"input-group"} id={`container_quantity_${product.id}`}>
            <div className="form-floating">
                <input
                    key={quantity}
                    tabIndex={0}
                    type="number"
                    id={`quantity_${product.id}`}
                    step={product.unit ? product.unit.step : 1}
                    className="form-control form-control-xxs"
                    defaultValue={quantity}
                    onChange={e => setInputValue(Number(e.target.value))}
                    min={0}
                />
                <label htmlFor={`quantity_${product.id}`}>Quantité</label>
            </div>
            {quantity !== inputValue && <button disabled={updating} className={"btn btn-primary"} type={"button"} onClick={() => handleUpdateClick()}>
                Mettre à jour
            </button>}
        </div>
    );
};

export default ShopProductShowQuantityInput;
