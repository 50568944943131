import * as models from "../../../../../../../../models/affiliation";
import React, {useEffect, useState} from "react";
import SupplierAffiliationConversionRuleAdd from "../add";
import {Payload} from "../../../../../../../../models/global";
import {ConversionRules} from "../../../../../../../../models/affiliation";
import * as api from "../../../../../../../../adapters/corporate/supplier/affiliation/conversionRule";
import Loader from "../../../../../../../../utils/loader";
import ErrorCard from "../../../../../../../error";
import Pagination from "../../../../../../../../utils/pagination";
import ModalComponent from "../../../../../../../modal";
import SupplierAffiliationConversionRuleListItem from "./item";

const SupplierAffiliationConversionRuleList = ({affiliation}: {affiliation: models.Affiliation}) => {

    const [showAddModal, setShowAddModal] = useState(false);
    const [loading, setLoading] = useState(true)
    const [payload, setPayload] = useState<Payload<ConversionRules>>({count: 0, rows: []})
    const [offset, setOffset] = useState<number>(0)
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true);
        api.list(affiliation.id, {offset: offset})
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [affiliation.id, offset])


    return <>
        <div className="table-responsive">
            <table className="table">
                <thead>
                <tr>
                    <th>
                        Nom
                    </th>
                    <th>
                        Identifiant
                    </th>
                    <th>
                        Validation
                    </th>
                    <th>
                        Délai de valisation
                    </th>
                    <th>
                        <button className={'btn btn-primary btn-xs rounded'} onClick={() => setShowAddModal(true)}>
                            <i className={'bi bi-plus-circle'}></i> Ajouter
                        </button>
                    </th>
                </tr>
                </thead>
                <tbody className={"position-relative"}>
                {loading && <div className={"position-absolute h-100 w-100"}>
                    <div className="start-50 top-50 translate-middle-x translate-middle-y">
                        <Loader />
                    </div>
                </div>}
                {payload.rows.map(r => <SupplierAffiliationConversionRuleListItem item={r} />)}
                {error && <tr>
                    <td colSpan={5}><ErrorCard error={error} /></td>
                </tr>}
                </tbody>
            </table>
        </div>
        <Pagination count={payload.count} offset={offset} setOffset={o => setOffset(o)} />
        <ModalComponent size={"modal-lg"} title={"Ajouter un règle"} show={showAddModal} onHide={() => setShowAddModal(false)}>
            <SupplierAffiliationConversionRuleAdd affiliation={affiliation} onSuccess={rule => {
                setPayload(prev => ({count: prev.count + 1, rows: [rule, ...prev.rows]}))
                setShowAddModal(false)
            }} />
        </ModalComponent>
    </>
}

export default SupplierAffiliationConversionRuleList;