import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import FormError from "../../../../../utils/formError";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../adapters/corporate/common/onboarding";
import React, {useEffect, useState} from "react";
import authSlice from "../../../../../reducers/auth";
import Select from "../../../../../utils/select";
import Loader from "../../../../../utils/loader";
import {useNavigate} from "react-router-dom";
import {CorporateAccount} from "../../../../../models/account";
import ErrorCard from "../../../../error";

const OnBoardingInformation = () => {

    const auth = useAppSelector(state => state.auth);
    const account = auth.user!.activeAccount as CorporateAccount;
    const information = account.organization.information;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(null)

    const [initialValues, setInitialValues] = useState<any>({
        type: information?.type || "company",
        name:  information?.name || "",
        email: information?.email || auth.user?.email,
        code: information?.code || "",
    });

    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        type: Yup.string().required(),
        name: Yup.string().required(),
        email: Yup.string().email().required(),
        code: Yup.string().required(),
    }))

    useEffect(() => {
        switch (account.organization.type){
            case "supplier":
                setInitialValues({
                    type: "company",
                    name:  information?.name || "",
                    email: information?.email || auth.user?.email,
                    code: information?.code || "",
                })

                setValidationSchema(Yup.object().shape({
                    type: Yup.string().required(),
                    name: Yup.string().required(),
                    email: Yup.string().email().required(),
                    code: Yup.string().required(),
                }))
                break;
            case "provider":
                if (information){
                    switch (information.type){
                        case "individual":
                            setInitialValues({
                                type: "individual",
                                name:  information.name || `${account.firstname} ${account.lastname}`,
                                email: information?.email || auth.user?.email,
                            })

                            setValidationSchema(Yup.object().shape({
                                type: Yup.string().required(),
                                name: Yup.string().required(),
                                email: Yup.string().email().required(),
                            }))
                            break;
                        case "company":
                            setInitialValues({
                                type: "company",
                                name:  information?.name || "",
                                taxRatePercentage:  information?.taxRatePercentage || "",
                                email: information?.email || auth.user?.email,
                                code: information?.code || "",
                            })

                            setValidationSchema(Yup.object().shape({
                                type: Yup.string().required(),
                                name: Yup.string().required(),
                                taxRatePercentage: Yup.number().required(),
                                email: Yup.string().email().required(),
                                code: Yup.string().required(),
                            }))
                            break;
                    }
                }else{
                    setInitialValues({
                        type: "company",
                        name: '',
                        email: auth.user?.email || '',
                        code: "",
                    })

                    setValidationSchema(Yup.object().shape({
                        type: Yup.string().required(),
                        name: Yup.string().required(),
                        email: Yup.string().email().required(),
                        code: Yup.string().required(),
                    }))
                }
                break;
        }
    }, [account.organization.type])

    function handleTypeChange(type: "individual" | "company") {
        const _values = {...formik.values}
        if (type === "individual") {
            setInitialValues({
                type: "individual",
                name: _values?.name || "",
                email: _values.email || auth.user?.email,
            });

            setValidationSchema(Yup.object().shape({
                type: Yup.string().required(),
                name: Yup.string().required(),
                email: Yup.string().email().required(),
            }));
        } else {
            setInitialValues({
                type: "company",
                name: _values?.name || information?.name || "",
                taxRatePercentage: _values?.taxRatePercentage || information?.taxRatePercentage || "",
                email: _values.email || information?.email || auth.user?.email,
                code: _values?.code || information?.code || "",
            });

            setValidationSchema(Yup.object().shape({
                type: Yup.string().required(),
                name: Yup.string().required(),
                taxRatePercentage: Yup.number().required(),
                email: Yup.string().email().required(),
                code: Yup.string().required(),
            }));
        }
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            api.information(values)
                .then(resp => dispatch(authSlice.actions.upsertAccountOrganizationInformation(resp.data)))
                .then(() => navigate('/onboarding/organization'))
                .catch(e => setError(e))
                .finally(() => formikHelpers.setSubmitting(false))
        }
    })

    return <form onSubmit={formik.handleSubmit}>
        <h3 className={"mb-8"}>
            Dites-nous en plus sur votre entreprise
        </h3>
        {account.organization.type === "provider" && <>
            <div className="col-12 mb-3">
                <Select
                    label={"Vous exercez votre activité en tant que"}
                    options={[{label: "Entreprise", value: 'company'}, {label: "Particulier", value: "individual"}]}
                    required={true}
                    value={information?.type ? {label: information.type === "individual" ? "Particulier" : "Entreprise", value: information.type} : undefined}
                    onChange={o => handleTypeChange(o.value)}
                />
                <FormError form={formik} field={"type"} />
            </div>
        </>}
        {formik.values.type === "individual" && <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className={'form-control'} value={formik.values.name} placeholder={'Alexandre Dupont'} key={'name'} name={"name"} id={"name"}
                       onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                <label htmlFor="name">
                    Nom complet *
                </label>
            </div>
            <FormError form={formik} field={"name"}/>
        </div>}
        {account.organization.type === "provider" && formik.values.type === "company" && <>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className={'form-control'} defaultValue={information?.taxRatePercentage} key={'taxRatePercentage'} name={"taxRatePercentage"} id={"taxRatePercentage"}
                           onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                    <label htmlFor="taxRatePercentage">
                        Quel taux de TVA pratiquez vous ?
                    </label>
                </div>
                <FormError form={formik} field={"taxRatePercentage"}/>
            </div>
        </>}
        {formik.values.type === "company" && <>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className={'form-control'} defaultValue={formik.values.name} name={"name"} id={"name"}
                           onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                    <label htmlFor="name">
                        Nom de l'entreprise (raison sociale)
                    </label>
                </div>
                <FormError form={formik} field={"name"}/>
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className={'form-control'} defaultValue={information?.code ? information.code : undefined} name={"code"} id={"code"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                    <label htmlFor="code">
                        N° de Siret
                    </label>
                </div>
                <FormError form={formik} field={"code"} />
            </div>
        </>}
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="email" className={'form-control'} value={formik.values.email} name={"email"} id={"email"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                <label htmlFor="email">
                    Email (pour les notifications)
                </label>
            </div>
            <FormError form={formik} field={"email"} />
        </div>
        {error && <div className={'col-12 mb-3'}>
            <ErrorCard error={error} />
        </div>}
        <button className="btn btn-outline-primary w-100 mb-3">
            {formik.isSubmitting ? <Loader /> : <>Suivant <i className={'bi bi-arrow-right'}></i></>}
        </button>
    </form>

}

export default OnBoardingInformation;