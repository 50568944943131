import {useAppSelector} from "../../../../hooks";
import {CorporateAccount} from "../../../../models/account";
import SupplierDashboardTouch from "./touch";
import SupplierDashboardFee from "./fee";
import SupplierDashboardOrder from "./order";
import SupplierDashboardParcel from "./parcel";
import SupplierDashboardReturns from "./returns";
import React, {useState} from "react";
import ModalComponent from "../../../modal";
import SupplierFeeAdd from "../fee/add";
import {Link, useNavigate} from "react-router-dom";
import SupplierDashboardClick from "./click";
import SupplierDashboardConversion from "./conversion";
import SupplierDashboardApplication from "./application";

const SupplierDashboard = () => {

    const activeAccount = useAppSelector(state => state.auth.user?.activeAccount) as CorporateAccount

    const navigate = useNavigate();

    const [feeModalOpen, setFeeModalOpen] = useState(false);

    return <>
        {activeAccount.organization.status === "Completed" && <div className={'col-12 alert alert-info'}>
            <div className="container mx-auto">
                <div className="row align-items-center">
                    <div className="col">
                        Vérifiez votre compte avec stripe pour:
                        <ul>
                            <li>
                                recevoir les revenues générés
                            </li>
                            <li>
                                Apparaitre comme compte certifié dans la recherche
                            </li>
                        </ul>
                    </div>
                    <div className="col-auto">
                        <Link to={"/corporate/stripe"} className={'btn btn-primary btn-xs'}>
                            Connecter <i className={'bi bi-arrow-right'}></i>
                        </Link>
                    </div>
                </div>
            </div>
        </div>}
        <div className="col-md-12 mb-3">
            <div className="card shadow h-100">
                <div className="card-body">
                    <h6>Comment ça marche ?</h6>
                    <ol>
                        <li className={'mb-2'}>
                            <Link to={'/corporate/supplier/assignment/add'}>Postez une annonce</Link> ou trouvez les profils directement via la <Link to={'/corporate/supplier/search'}>recherche</Link>.
                        </li>
                        <li className={'mb-2'}>
                            Complétez votre <Link to={'/corporate/supplier/catalog'}>catalogue</Link> et vos <Link to={'/corporate/supplier/catalog/fee-rule'}>règles de commissions</Link> pour communiquer précisemment sur vos services ou pour les rendre disponible en vente direct.
                        </li>
                        <li className={'mb-2'}>
                            Invitez les profils qui conviennent à rejoindre votre réseau.
                        </li>
                        <li className={'mb-2'}>
                            Échangez sur les modalités de votre collaboration via la <Link to={'/corporate/chat'}>messagerie</Link>.
                        </li>
                        <li className={'mb-2'}>
                            Paramétrer les accès de votre partenaire pour les modules vente direct et affiliation via l'onglet <Link to={'/corporate/partnership'}>partenaires</Link>.
                        </li>
                        <li className={'mb-2'}>
                            <Link to={'/corporate/supplier/fee'}>Ajoutez et payez les commissions</Link> via la plateforme pour augmentez votre scoring <i className={'bi bi-trophy'}></i> et votre visibillité.
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card">
                <div className="card-body shadow">
                    <h6>Actions rapides</h6>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="card h-100 shadow-hover cursor-pointer" onClick={() => navigate('/corporate/supplier/assignment/add')}>
                                <div className="card-body border border-1">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <p className={'fw-semibold mb-0'}>
                                                Publier une annonce
                                            </p>
                                        </div>
                                        <div className="col-auto">
                                            <i className={'bi bi-arrow-right'}></i>
                                        </div>
                                    </div>
                                    <p className={'mb-0 mt-1 form-text'}>
                                        Un besoin spécifique ? Créez une annonce, recevez des candidatures, passez les entreriens et séléctionnez les partenaires qui vous correspondent.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card h-100 shadow-hover cursor-pointer" onClick={() => navigate('/corporate/supplier/product/add')}>
                                <div className="card-body border border-1">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <p className={'fw-semibold mb-0'}>
                                                Ajouter un produit / service
                                            </p>
                                        </div>
                                        <div className="col-auto">
                                            <i className={'bi bi-arrow-right'}></i>
                                        </div>
                                    </div>
                                    <p className={'mb-0 mt-1 form-text'}>
                                        Renseignez vos produits / services et taux de commissions pour mettre en avant votre entreprise. Activez la vente direct pour permettre à vos partenaires de commercialiser directement vos produits.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card h-100 shadow-hover cursor-pointer" onClick={() => navigate('/corporate/supplier/affiliation')}>
                                <div className="card-body border border-1">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <p className={'fw-semibold mb-0'}>
                                                Ajouter un programme d'affiliation
                                            </p>
                                        </div>
                                        <div className="col-auto">
                                            <i className={'bi bi-arrow-right'}></i>
                                        </div>
                                    </div>
                                    <p className={'mb-0 mt-1 form-text'}>
                                        Créez un programme d'affilliation pour augmenter le trafic vers votre site internet pour augmenter votre notoriété, vos inscriptions, vos ventes, etc.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card h-100 shadow-hover cursor-pointer" onClick={() => setFeeModalOpen(true)}>
                                <div className="card-body border border-1">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <p className={'fw-semibold mb-0'}>
                                                Ajouter une commission
                                            </p>
                                        </div>
                                        <div className="col-auto">
                                            <i className={'bi bi-arrow-right'}></i>
                                        </div>
                                    </div>
                                    <p className={'mb-0 mt-1 form-text'}>
                                        Ajouter les commissions de vos partenaires pour garder un suivi des transactions et payez les quand vous voulez.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card">
                <div className="card-body shadow">
                    <h6>Annonces</h6>
                    <div className="row">
                        <div className="col-12 col-md-6 mb-3">
                            <SupplierDashboardApplication />
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                            <SupplierDashboardApplication />
                        </div>
                    </div>
                </div>
            </div>
        </div><div className="col-12 mb-3">
            <div className="card">
                <div className="card-body shadow">
                    <h6>Apports d'affaires</h6>
                    <div className="row">
                        <div className="col-12 col-md-6 mb-3">
                            <SupplierDashboardTouch />
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                            <SupplierDashboardFee />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card">
                <div className="card-body shadow">
                    <h6>Vente direct</h6>
                    <div className="row">
                        <div className="col-12 col-md-4 mb-3">
                            <SupplierDashboardOrder />
                        </div>
                        <div className="col-12 col-md-4 mb-3">
                            <SupplierDashboardParcel />
                        </div>
                        <div className="col-12 col-md-4 mb-3">
                            <SupplierDashboardReturns />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card">
                <div className="card-body shadow">
                    <h6>Programmes d'affiliations</h6>
                    <div className="row">
                        <div className="col-12 col-md-4 mb-3">
                            <div className="card h-100">
                                <SupplierDashboardClick />
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-3">
                            <SupplierDashboardConversion />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {feeModalOpen && <ModalComponent title={"Ajouter une commission"} show={feeModalOpen} onHide={() => setFeeModalOpen(false)} size={"modal-lg"}>
            <SupplierFeeAdd handleSuccess={() => setFeeModalOpen(false)} />
        </ModalComponent>}
    </>
}

export default SupplierDashboard;