import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query?: string, offset: number})
{
    return get(`/corporate/common/chat${encodeQueryData(requestData)}`)
}

export function show(id: number)
{
    return get(`/corporate/common/chat/${id}`)
}

export function search(requestData: {query: string})
{
    return post(`/corporate/common/chat/search`, requestData)
}

export function add(requestData: any)
{
    return post(`/corporate/common/chat`, requestData)
}

export function reads(chat: number)
{
    return get(`/corporate/common/chat/${chat}/reads`)
}


export function unreads()
{
    return get(`/corporate/common/chat/unreads`)
}