import React, { useState, useEffect, useRef } from "react";
import * as api from "../../../../../adapters/cart";
import { LineItem } from "../../../../../models/lineItem";
import { Country } from "../../../../../models/country";
import { Currency } from "../../../../../models/currency";
import { useAppDispatch } from "../../../../../hooks";
import { Popover } from "bootstrap";
import {CartInterface} from "../../../../../models/cart";
import cartSlice from "../../../../../reducers/cart";

const QuantityInput = ({
                           item,
                           country,
                           currency,
                           source,
                           sourceId
                       }: {
    item: LineItem;
    country: Country;
    currency: Currency;
    source: "shop"|"link"|"pack",
    sourceId: string
}) => {

    const dispatch = useAppDispatch();
    const [inputType, setInputType] = useState<"input" | "select">(
        item.quantity > 9 ? "input" : "select"
    );
    const [updating, setUpdating] = useState(false);
    const [key, setKey] = useState(0);
    const [containerKey, setContainerKey] = useState(0);
    const popoverRef = useRef<HTMLDivElement>(null);
    const [inputValue, setInputValue] = useState(item.quantity);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as Element;
            const popoverElement = popoverRef.current;
            if (
                popoverElement &&
                !popoverElement.contains(target) &&
                !target.closest('[data-toggle="popover"]')
            ) {
                const popover = Popover.getInstance(popoverElement);
                if (popover) {
                    popover.dispose();
                }
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    function handleChange(
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) {
        e.preventDefault();

        switch (e.target.value) {
            case "10+":
                setInputType("input");
                break;
            default:
                updateQuantity(Number(e.target.value));
        }
    }

    function handleUpdateClick() {
        const el = document.getElementById(`quantity_${item.id}`) as HTMLInputElement;

        if (el) {
            const value = el.value;
            updateQuantity(Number(value));
        }
    }

    function updateQuantity(quantity: number) {
        setContainerKey((p) => p + 1);
        setUpdating(true);
        api
            .updateQuantity(item.id, {
                currency_id: currency.id,
                country_id: country.id,
                quantity: quantity,
            })
            .then((r) => {
                const cart: CartInterface = r.data;
                const lineItem: LineItem | undefined = cart.items.find(
                    (i) => i.id === item.id
                );

                if (lineItem) {
                    setInputType(lineItem.quantity > 9 ? "input" : "select")
                    if (lineItem.quantity < quantity) {
                        const el = document.getElementById(`container_quantity_${item.id}`);

                        if (el) {
                            const popover = new Popover(el, {
                                trigger: "focus",
                                placement: "top",
                                content: `Le nombre d'article disponible est limité à ${lineItem.quantity}`,
                            });
                            popover.show();
                        }
                    }
                }

                dispatch(cartSlice.actions.setCart({
                    cart: r.data,
                    storageKey: `${source}-${sourceId}`
                }));
                setUpdating(false);
                setKey((p) => p + 1);
            });
    }

    if (inputType === "select") {
        return (
            <div key={containerKey} ref={popoverRef} className="form-floating" id={`container_quantity_${item.id}`}>
                <select
                    key={item.quantity}
                    disabled={updating}
                    tabIndex={0}
                    onChange={(e) => handleChange(e)}
                    className="form-select"
                    id={`quantity_${item.id}`}
                    aria-label="Floating label select example"
                >
                    {Array.from(Array(Math.ceil(10 / (item.unit ? item.unit.step : 1))).keys()).map((value) => (
                        <option selected={value * item.unit.step === item.quantity} value={value * item.unit.step}>
                            {value * item.unit.step} {item.unit.label} {value === 0 && "(Retirer)"}
                        </option>
                    ))}
                    <option value="10+">10+</option>
                </select>
                <label htmlFor="floatingSelect">Quantité</label>
            </div>
        );
    }

    return (
        <div key={containerKey} ref={popoverRef} className={"input-group"} id={`container_quantity_${item.id}`}>
            <div className="form-floating">
                <input
                    key={item.quantity}
                    tabIndex={0}
                    type="number"
                    id={`quantity_${item.id}`}
                    step={item.unit ? item.unit.step : 1}
                    className="form-control form-control-xxs"
                    defaultValue={item.quantity}
                    onChange={e => setInputValue(Number(e.target.value))}
                    min={0}
                />
                <label htmlFor={`quantity_${item.id}`}>Quantité</label>
            </div>
            {item.quantity !== inputValue && <button disabled={updating} className={"btn btn-primary"} onClick={() => handleUpdateClick()}>
                Mettre à jour
            </button>}
        </div>
    );
};

export default QuantityInput;
