import {MULTIPART_FORM_DATA, post, get, destroy} from "../../../../../xhr";

export function add(productId: number, declinationId: number, requestData: {
    value?: string;
}){
    return post(`corporate/supplier/product/${productId}/declination/${declinationId}/choice`, requestData);
}

export function edit(productId: number, declinationId: number, choiceId: number, requestData: {
    value: string;
}){
    return post(`corporate/supplier/product/${productId}/declination/${declinationId}/choice/${choiceId}`, requestData);
}

export function remove(productId: number, declinationId: number, choiceId: number){
    return destroy(`corporate/supplier/product/${productId}/declination/${declinationId}/choice/${choiceId}`);
}

export function file(productId: number, declinationId: number, choiceId: number, requestData: FormData){
    return post(`corporate/supplier/product/${productId}/declination/${declinationId}/choice/${choiceId}/file`, requestData, {headers: [MULTIPART_FORM_DATA]});
}