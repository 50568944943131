import React, {useEffect, useState} from 'react';
import { useFormik } from "formik";
import * as Yup from 'yup';
import * as api from "../../../../../../../../adapters/corporate/supplier/subscription/occurrence/refund";
import * as itemApi from "../../../../../../../../adapters/corporate/supplier/subscription/occurrence/item";
import {
    SubscriptionOccurrence,
    SubscriptionOccurrenceItem,
    Subscription
} from "../../../../../../../../models/subscription";
import {Refund, SubscriptionRefund} from "../../../../../../../../models/refund";
import ErrorCard from "../../../../../../../error";
import * as models from "../../../../../../../../models/subscription";
import Loader from "../../../../../../../../utils/loader";
import ListEmpty from "../../../../../../../list/empty";
import FormError from "../../../../../../../../utils/formError";

const SupplierSubscriptionOccurrenceRefundAdd = ({ subscription,  occurrence, onSuccess }: { subscription: Subscription, occurrence: SubscriptionOccurrence, onSuccess: (refund: Refund & SubscriptionRefund) => void }) => {

    const [loading, setLoading] = useState(true)
    const [payload, setPayload] = useState<{count: number, rows: models.SubscriptionOccurrenceItems}>({count: 0, rows: []})
    const [error, setError] = useState<any>(null)

    useEffect(() => {
        setLoading(true)
        setError(null)
        itemApi.list(subscription.id, occurrence.id, {})
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [subscription.id, occurrence.id])

    const formik = useFormik({
        initialValues: {
            items: [...payload.rows.map((item: SubscriptionOccurrenceItem) => ({
                type: "SubscriptionOccurrenceItem",
                occurrence_item_id: item.id,
                quantity: 0, // Default quantity to 0 for initial state
            }))]
        },
        validationSchema: Yup.object().shape({
            items: Yup.array().of(Yup.object().shape({
                type: Yup.string().oneOf(['SubscriptionOccurrenceItem']).required(),
                occurrence_item_id: Yup.number().required(),
                quantity: Yup.number().required(),
            }))
        }),
        enableReinitialize: true,
        onSubmit: async (values, { setSubmitting }) => {
            try {
               const {data} = await api.add(subscription.id, occurrence.id, values);
               onSuccess(data)
            } catch (e) {
                setError(e)
            } finally {
                setSubmitting(false);
            }
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <h6>Articles</h6>
            {loading && <Loader />}
            {error && <ErrorCard error={error} />}
            {!loading && !error && !payload.count && <ListEmpty />}
            {!loading && payload.count && <ul className={'list-group list-group-flush'}>
                {payload.rows.map((item, index) => (
                    <li className={'list-group-item'}>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <p className={'mb-2 fw-semibold'}>
                                    {item.price.product.name}
                                </p>
                                <p className={'mb-0'}>
                                    Quantité max: {item.quantity}
                                </p>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="form-floating">
                                    <input
                                        type="number"
                                        className={'form-control'}
                                        name={`items[${index}].quantity`}
                                        defaultValue={0}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <label htmlFor={`items[${index}].quantity`}>
                                        Quantité
                                    </label>
                                </div>
                                <FormError form={formik} field={`items[${index}].quantity`} />
                            </div>
                        </div>
                    </li>
                ))}
            </ul>}
            {error && <ErrorCard error={error} />}
            <button type="submit" className={'btn btn-primary w-100'} disabled={formik.isSubmitting}>
                Rembourser
            </button>
        </form>
    );
}

export default SupplierSubscriptionOccurrenceRefundAdd;
