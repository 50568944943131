import React from "react";
import ClickPaymentForm from "./form";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {Clicks} from "../../../../../../models/affiliation";
import {Currency} from "../../../../../../models/currency";
import {PaymentMethod} from "../../../../../../models/paymentMethod";

const stripePromise = loadStripe("pk_live_51OHqm6H46Kmwvu6gtsFGdaMUw4mgkXMquclVtkENiHwv4DN4s00c3i7pHXP5db9Vo6SS0omYqRIvDFiHC7oxtWPx00GupdBdOQ");

const ClickPayment = ({clicks, currency, paymentMethod, handlePaymentSucceed}: {clicks?: Clicks, currency: Currency, paymentMethod?: PaymentMethod, handlePaymentSucceed: (clicks: Clicks) => void}) => {

    return <Elements options={{appearance: {theme: 'stripe'}}} stripe={stripePromise}>
        <ClickPaymentForm clicks={clicks} paymentMethod={paymentMethod} currency={currency} handlePaymentSucceed={handlePaymentSucceed} />
    </Elements>
}

export default ClickPayment