import React, {useEffect, useState} from "react";
import {Categories, Category} from "../../../../../../models/category";
import * as api from "../../../../../../adapters/shop/category";
import Loader from "../../../../../../utils/loader";
import ErrorCard from "../../../../../error";

const ShopProductListFilterCategory = ({setCategory, disabled, uuid}: {setCategory: (c: Category|null) => void, disabled: boolean, uuid: string}) => {

    const [loading, setLoading] = useState(true)
    const [allCategories, setAllCategories] = useState<Categories>([]);
    const [parent, setParent] = useState<Category|null>(null)
    const [error, setError] = useState<Category|null>(null)

    function updateParent(p: Category|null){
        if (disabled) return;

        setParent(p)
        setCategory(p);
    }

    useEffect(() => {
        setLoading(true);
        api.list(uuid)
            .then(r => setAllCategories(r.data))
            .catch(error => setError(error))
            .finally(() => setLoading(false));
    }, [uuid])

    function getCategories(cats: Categories): Categories {

        if (!parent){
            return cats;
        }

        const result: Category[] = [];
        for (const cat of cats) {
            if (cat.id === parent.id) {
                return cat.children || [];
            } else if (Array.isArray(cat.children)) {
                const subCategories = getCategories(cat.children);
                result.push(...subCategories);
            }
        }
        return result;
    }

    function getCategory(id: number | null, cats?: Categories): Category | null {
        if (!id) {
            return null;
        }
        for (const cat of (cats || allCategories)) {
            if (cat.id === id) {
                return cat;
            } else if (Array.isArray(cat.children)) {
                const foundCategory = getCategory(id, cat.children);
                if (foundCategory) {
                    return foundCategory;
                }
            }
        }
        return null;
    }

    return <div className={'d-flex text-nowrap'}>
        {getCategories(allCategories).map(r => <div className={'flex-grow-0 badge bg-light text-dark me-2 cursor-pointer'} onClick={() => updateParent(r)}>
            {r.name} {r.children?.length > 0 && <i className={'bi bi-arrow-right'}></i>}
        </div>)}
    </div>
}

export default ShopProductListFilterCategory;