import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import FormError from "../../../../../utils/formError";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../adapters/corporate/common/onboarding";
import React from "react";
import authSlice from "../../../../../reducers/auth";
import Select from "../../../../../utils/select";
import Loader from "../../../../../utils/loader";
import {useNavigate} from "react-router-dom";
import {CorporateAccount} from "../../../../../models/account";

const OnBoardingAddress = () => {

    const account = useAppSelector(state => state.auth.user!.activeAccount) as CorporateAccount
    const country = account.organization.country;
    const billing = account.organization.billing;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            line1: billing?.line1 || "",
            line2:  billing?.line2 || null,
            city:  billing?.city || "",
            postalCode: billing?.postalCode || "",
        },
        validationSchema: Yup.object().shape({
            line1: Yup.string().nullable(),
            line2: Yup.string().nullable(),
            city: Yup.string().required(),
            postalCode: Yup.number().required(),
        }),
        enableReinitialize: true,
        onSubmit: (values, {setSubmitting}) => {
            api.address(values)
                .then(resp => dispatch(authSlice.actions.upsertAccountOrganizationAddress(resp.data)))
                .then(() => navigate('/onboarding/verification'))
        }
    })

    return <form onSubmit={formik.handleSubmit}>
        <h3 className={'mb-8'}>
            Ou êtes vous situé ?
        </h3>
        <div className="col-12 mb-3">
            <Select
                disabled={true}
                required={true}
                label={"Pays"}
                options={[]}
                value={{value: country?.id, label: country?.name, img: country?.flag}}
                onChange={o => console.log(o)}
            />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className={'form-control'} defaultValue={billing?.line1} name={"line1"} id={"line1"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                <label htmlFor="line1">
                    Adresse (optionnel)
                </label>
            </div>
            <FormError form={formik} field={"line1"} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className={'form-control'} defaultValue={billing?.line2} name={"line2"} id={"line2"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                <label htmlFor="line2">
                    Complément d'adresse (optionnel)
                </label>
            </div>
            <FormError form={formik} field={"line2"} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className={'form-control'} defaultValue={billing?.city} name={"city"} id={"city"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                <label htmlFor="city">
                    Ville
                </label>
            </div>
            <FormError form={formik} field={"city"} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className={'form-control'} defaultValue={billing?.postalCode} name={"postalCode"} id={"postalCode"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                <label htmlFor="postalCode">
                    Code postale
                </label>
            </div>
            <FormError form={formik} field={"postalCode"} />
        </div>
        <button className="btn btn-outline-primary w-100 mb-3">
            {formik.isSubmitting ? <Loader /> : <>Suivant <i className={'bi bi-arrow-right'}></i></>}
        </button>
    </form>

}

export default OnBoardingAddress;