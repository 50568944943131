import { configureStore } from '@reduxjs/toolkit'
import authSlice from './reducers/auth'
import categorySlice from "./reducers/category";
import React from "react";
import cartSlice from "./reducers/cart";

const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        category: categorySlice.reducer,
        cart: cartSlice.reducer,
    }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;