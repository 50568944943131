import React, {useState} from "react";
import * as models from "../../../../../../../models/post";
import {getLogoUrl} from "../../../../../../../utils/image";
import PostCommentList from "../index";

const PostCommentListItem = ({post, comment, parent, depth}: { post: models.Post, comment: models.PostComment, depth: number, parent?: models.PostComment }) => {

    const [displayAnswers, setDisplayAnswers] = useState<boolean>(false);
    const [answerCount, setAnswerCount] = useState(comment.answerCount)
    const [reactionCount, setReactionCount] = useState(comment.reactionCount)
    const [myReaction, setMyReaction] = useState(comment.myReaction)

    return <>
        <div className="row">
            {Array.from(Array(Math.ceil(depth)).keys()).map(i => <div key={i} className={'col-auto position-relative'}>
                <div style={{width: "2.25rem", height: "2.25rem"}}></div>
                <div style={{zIndex: 0, position: "absolute", top: 0, width: 1, backgroundColor: '#bababa', height: "100%", transform: "translate(17px)"}}></div>
            </div>)}
            <div className="col">
                <div className={'row'}>
                    <div className="col-auto position-relative">
                        <div className="avatar avatar-lg" style={{zIndex: 1}}>
                            <img src={getLogoUrl(comment.account.organization)} alt={comment.account.organization.name} className="avatar-img rounded-circle" />
                        </div>
                        {displayAnswers && <div style={{zIndex: 0, position: "absolute", top: 0, width: 1, backgroundColor: '#bababa', height: "100%", transform: "translate(17px)"}}></div>}
                        {parent && <div style={{position: "absolute", top: 0, left: -31, height: 17, width: 35, borderBottom: "solid #bababa 1px", borderLeft: "solid #bababa 1px", borderBottomLeftRadius: 15}}></div>}
                    </div>
                    <div className="col">
                        <div className="w-100 rounded bg-body-secondary p-3 mb-1">
                            <div className="fw-semibold">
                                {comment.account.firstname} {comment.account.lastname}
                            </div>
                            <div className="">
                                {comment.content}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className={'col-auto position-relative'}>
                        <div style={{width: "2.25rem", height: "2.25rem"}}></div>
                        {displayAnswers && <div style={{zIndex: 0, top: 0, position: "absolute", width: 1, backgroundColor: '#bababa', height: "100%", transform: "translate(17px)"}}></div>}
                    </div>
                    <div className="col">
                        <div className="col-12">
                            <button className="btn btn-xxs me-2 text-hover text-hover-primary" onClick={() => setDisplayAnswers(prev => !prev)}>
                                <i className={'bi bi-chat'}></i> {answerCount}
                            </button>
                            <button className="btn btn-xxs me-2 text-hover text-hover-red">
                                <i className={'bi bi-heart'}></i> {reactionCount}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {displayAnswers && <PostCommentList handleAddedComment={() => setAnswerCount(p => p + 1)} post={post} parent={comment} depth={depth + 1} />}
        </>
}

export default PostCommentListItem;