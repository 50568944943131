import {Chat, ChatMessage} from "../../../../../../../../models/chat";
import {getLogoUrl, getMessageFileUrl} from "../../../../../../../../utils/image";
import {format} from "date-fns";
import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../../../../../../hooks";
import {Account} from "../../../../../../../../models/account";
import {socket} from "../../../../../../../../socket";
import {Link} from "react-router-dom";

const ChatMessageListItem = ({chat, message, next}: {chat: Chat, message: ChatMessage, next?: ChatMessage}) => {

    const authAccount = useAppSelector(state => state.auth.user?.activeAccount) as Account

    const [_message, setMessage] = useState<ChatMessage>(message)

    useEffect(() => {
        socket.connect();
        socket.on(`message.${message.id}.updated`, handleUpdate);

        return () => {
            socket.disconnect();
            socket.off(`message.${message.id}.updated`, handleUpdate);
        };
    }, [message.id]);

    function handleUpdate(message: ChatMessage){
        setMessage(message);
    }

    return <div id={`message_${message.id}`} className={"row " + (message.account.id === authAccount.id ? " justify-content-end" : " justify-content-start")}>
        <div className="col-auto mb-1" style={{maxWidth: "75%"}}>
            <div className="row">
                <div className={"col-auto" +  (message.account.id === authAccount.id ? " order-last" : " order-first")} style={{width: 60}}>
                    {next?.account.id !== message.account.id && <div className="avatar avatar-lg">
                        <img src={getLogoUrl(message.account.organization)} alt="..." className="avatar-img rounded-circle"/>
                    </div>}
                </div>
                <div className={"col position-relative p-2 rounded " + (message.account.id === authAccount.id ? " bg-primary-subtle " : " bg-success-subtle ")}>
                    {next?.account.id !== message.account.id && <div className={"d-flex align-items-center"}>
                        <p className={"text-muted fs-xxxs mb-1 flex-grow-1"}>
                            {message.account.firstname} {message.account.lastname} | {message.account.organization.name}
                        </p>
                    </div>}
                    <div className="row align-items-stretch">
                        <div className="col fs-sm pe-1" style={{whiteSpace: "pre-wrap"}}>
                            {message.content}
                        </div>
                        <div className="col-auto ps-0 d-flex">
                            <span className={'fs-xxs text-muted mt-auto'}>
                                <span>{format(new Date(message.createdAt), 'HH:MM')}</span> {message.account.id === authAccount.id && chat.accounts.filter(p => p.id !== authAccount.id).length === message.reads.length && <span><i className={'bi bi-check-all'}></i></span>}
                            </span>
                        </div>
                        {message.project && <div className={'col-12 mt-1'}>
                            <div className="bg-light rounded border border-1 p-2">
                                <Link target={'_blank'} to={''}>{message.project.title} <i className={'bi bi-arrow-right'}></i></Link>
                            </div>
                        </div>}
                    </div>
                    {message.files.map(f => <div className={'card border border-1 mt-3'}>
                        <img src={getMessageFileUrl(message, f)} alt={f.name} className={'img-fluid'}/>
                    </div>)}
                </div>
            </div>
        </div>
    </div>
}

export default ChatMessageListItem;