import React from "react";
import {useAppSelector} from "../../../hooks";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import CommonNav from "../../../components/corporate/common/nav";
import CorporateProviderNav from "../../../components/corporate/provider/nav";
import CorporateSupplierNav from "../../../components/corporate/supplier/nav";
import {CorporateAccount} from "../../../models/account";

const AppCorporateLayout = () => {

    const location = useLocation();
    const user = useAppSelector(state => state.auth.user);

    if (!user){
        return <Navigate to={'/login?redirect=' + encodeURIComponent(location.pathname)} />
    }

    if (!user.activeAccount){
        return <Navigate to={`/account?type=corporate`} />
    }

    if (user.activeAccount.type === "customer"){
        return <Navigate to={`/account?type=corporate`} />
    }

    if (user.activeAccount.organization.status === "Pending"){
        return <Navigate to={`/onboarding/${getCurrentStep(user.activeAccount)}`} />
    }

    const Navbar = ({type}: {type: "provider"|"supplier"}) => {

        switch (type){
            case "provider":
                return <CorporateProviderNav />
            case "supplier":
                return <CorporateSupplierNav />
        }

        return <></>
    }

    return <>
        <CommonNav />
        <div className="container mx-auto flex-grow-1">
            <div className="row h-100">
                <div className="col-12 col-md-3 col-lg-2">
                    <Navbar type={user.activeAccount.organization.type} key={user.activeAccount.organization.type} />
                </div>
                <div className="col-12 col-md-9 col-lg-10 pt-3 h-100">
                    <Outlet />
                </div>
            </div>
        </div>
    </>


    function getCurrentStep(account: CorporateAccount){
        if (!isAccountCompleted(account)) {
            return "account";
        }else if (!isOrganizationCompleted(account)) {
            return "organization";
        }else if (!isOrganizationInformationCompleted(account)) {
            return "information";
        }else if (!isOrganizationAddressCompleted(account)){
            return "address";
        }

        return "verification";
    }

    function isAccountCompleted(account: CorporateAccount){
        return account.firstname && account.lastname && account.tel;
    }

    function isOrganizationCompleted(account: CorporateAccount){
        let organization = account.organization;

        if (organization.type)
        {
            switch (organization.type){
                case "provider":
                    return organization.country && organization.name && organization.size && organization.sectors.length && organization.specialisations.length;
                case "supplier":
                    return organization.country && organization.name && organization.size && organization.sectors.length;
            }

        }

        return false;
    }

    function isOrganizationInformationCompleted(account: CorporateAccount){
        let organization = account.organization;

        if (organization.information){
            switch (organization.information.type){
                case "company":
                    return organization.information.name && organization.information.type && organization.information.email && organization.information.code
                case "individual":
                    return organization.information.type && organization.information.email;
                default:
                    return false
            }
        }

        return false;
    }

    function isOrganizationAddressCompleted(account: CorporateAccount){
        let organization = account.organization;

        if (organization.billing){
            return organization.billing.line1 && organization.billing.postalCode && organization.billing.city
        }

        return false;
    }
}

export default AppCorporateLayout;