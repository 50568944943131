import * as api from "../../../adapters/checkout";
import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Loader from "../../../utils/loader";
import * as models from "../../../models/checkout"
import CheckoutItemList from "../item/list";
import CheckoutNavHeader from "../nav/header";

const CheckoutConfirm = () => {

    const [loading, setLoading] = useState<boolean>(true);
    const {uuid} = useParams()
    const [checkout, setCheckout] = useState<models.CheckoutResponse>();

    useEffect(() => {
        setLoading(true)
        if (uuid){
            api.show(uuid)
                .then(r => setCheckout(r.data))
                .then(() => setLoading(false))
        }
    }, [uuid])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (!checkout)
    {
        return <div className={"container-fluid py-10 text-center fs-3"}>
            <i className={'bi bi-exclamation-triangle'}></i> Commande introuvable
        </div>
    }

    return <div className={"container-fluid h-100 d-flex flex-column px-0"}>
        <CheckoutNavHeader checkout={checkout} />
        <div className="container mx-auto">
            <div className={"col-12 "}>
                <div className="col-12 mb-3">
                    <h6>
                        Confirmation de votre commande
                    </h6>
                    <p>
                        Merci de votre confiance !
                    </p>
                    <p>
                        Un mail vous sera envoyé un fois votre commande confirmé par le marchand. <br/>
                        Vous pouvez suivre et gérer votre commande via votre compte client.
                    </p>
                </div>
                <div className="col-12 mb-3">
                    <div className="card border border-1">
                        <div className="card-header">
                            <h6>Vos articles</h6>
                        </div>
                        <div className="card-body">
                            <CheckoutItemList lineItems={checkout.items} country={checkout.country} currency={checkout.currency} />
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <Link to={`/customer/order`} className={"btn btn-light w-100"}>
                        Suivre et gérer mes commandes <i className={'bi bi-arrow-right'}></i>
                    </Link>
                </div>
            </div>
        </div>
    </div>
}

export default CheckoutConfirm;