export default function encodeQueryData(params: any) {
    const queryStringParts: string[] = [];
    for (const key in params) {
        const value = params[key];
        if (Array.isArray(value)) {
            for (const item of value) {
                queryStringParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(item))}`);
            }
        } else if(value !== null && value !== undefined){
            queryStringParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
        }
    }
    return `?${queryStringParts.join('&')}`;
}
