import {useFormik} from "formik";
import * as models from "../../../../../../../models/subscription";
import * as api from "../../../../../../../adapters/corporate/supplier/subscription/item";
import * as Yup from "yup"
import React, {useState} from "react";
import Loader from "../../../../../../../utils/loader";
import {Product, SellableProduct} from "../../../../../../../models/product";
import CommonProductShow from "../../../../../common/product/show";
import FormError from "../../../../../../../utils/formError";
import ErrorCard from "../../../../../../error";
import SupplierPriceList from "../../../../price/list";
import SupplierPriceShow from "../../../../price/show";

const SupplierSubscriptionItemAdd = ({subscription, product, onSuccess}: {subscription: models.Subscription, product: Product & SellableProduct, onSuccess: (item: models.SubscriptionItem) => void}) => {

    const [error, setError] = useState(null);
    const [price, setPrice] = useState(product.price);
    const [showPriceSelector, setShowPriceSelector] = useState(false)

    const formik = useFormik({
        initialValues: {
            quantity: 1,
            price_id: price.id
        },
        validationSchema: Yup.object().shape({
            quantity: Yup.number().required().min(1),
            price_id: Yup.number().required()
        }),
        enableReinitialize: true,
        onSubmit: (values, {setSubmitting}) => {
            api.add(subscription.id, {...values})
                .then(r => onSuccess(r.data))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    })

    return <form onSubmit={formik.handleSubmit}>
        <CommonProductShow id={product.id} />
        {!showPriceSelector && <div className="col-12 mb-3">
            <div className="card border border-1 border-success">
                <div className="card-header">
                    <div className="row">
                        <div className="col">
                            <h6>
                                Tarif séléctionné
                            </h6>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-xxs text-decoration-underline" onClick={() => setShowPriceSelector(true)}>
                                <i className={"bi bi-pencil"}></i> Modifier
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {price ? <SupplierPriceShow price={price} /> : <span className={'text-muted'}>Aucun tarif séléctionné</span>}
                </div>
            </div>
        </div>}
        {showPriceSelector && <SupplierPriceList product={product} type={'selector'} selected={price} handleSelect={p => {
            setPrice(p)
            setShowPriceSelector(false)
        }}/>}
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="number" step={product.unit.step} name={'quantity'} id={'quantity'} onChange={formik.handleChange} onBlur={formik.handleBlur} className="form-control"/>
                <label htmlFor="quantity">
                    Quantité
                </label>
            </div>
            <FormError form={formik} field={'quantity'} />
        </div>
        {error && <ErrorCard error={error} />}
        <button className={"btn btn-primary w-100"}>
            {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-plus-circle'}></i> Ajouter</>}
        </button>
    </form>
}

export default SupplierSubscriptionItemAdd;