import {OrderItem} from "../../../../models/order";
import React, {useEffect, useState} from "react";
import CustomerReturnAddItems from "./items";
import CustomerReturnAddReason from "./reason";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../adapters/customer/return";
import * as orderItemApi from "../../../../adapters/customer/order/item";
import {getCoverUrl} from "../../../../utils/image";
import convertToDecimal from "../../../../utils/currencyConverter";
import {useNavigate} from "react-router-dom";
import ErrorCard from "../../../error";
import {useQuery} from "../../../../utils/useQuery";
import Loader from "../../../../utils/loader";

const CustomerReturnAdd = () => {

    const navigate = useNavigate();
    const query = useQuery();
    const [loading, setLoading] = useState(query.has('orderItem'))
    const [error, setError] = useState(null);
    const [step, setStep] = useState<"item"|"quantity"|"reason"|"confirm">("item");
    const [orderItem, setOrderItem] = useState<OrderItem|undefined>();
    const [submitted, setSubmitted] = useState(false)

    const [initialValues, setInitialValues] = useState<any>({
        item_id: orderItem?.id,
        quantity: orderItem?.quantity || 1,
        reason1: null,
        reason2: null,
        reason3: null,
    });
    const [validationSchema, setValidationSchema] = useState(Yup.object().shape({
        item_id: Yup.number().required(),
        quantity: Yup.number().required(),
        reason1: Yup.string().required(),
        reason2: Yup.string().nullable(),
        reason3: Yup.string().nullable(),
    }));

    useEffect(() => {
        if (query.has('orderItem')){
            setLoading(true)
            setError(null)
            orderItemApi.show(Number(query.get('orderItem')))
                .then(r => handleItemChange(r.data))
                .catch(e => setError(e))
                .finally(() => setLoading(false))
        }
    }, [])

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            setError(null)
            api.add(values)
                .then(r => setSubmitted(true))
                .catch(e => setError(e))
                .finally(() => formikHelpers.setSubmitting(false))
        }
    })

    function handleItemChange(item: OrderItem){
        setOrderItem(item)
        setInitialValues((prev: any) => ({...prev, item_id: item.id, quantity: item.quantity}))
        setStep("quantity")
    }

    if (submitted){
        return <div className={'container-fluid'}>
            <h6>
                Votre demande à été transmise avec succès.
            </h6>
            <p>
                Le commercant s'occupe de traiter votre demande dans les meilleurs délais.
            </p>
        </div>
    }

    return <div className={'container-fluid'}>
        <div className="col-12 mb-3">
            <div className="row align-items-center">
                <div className="col-md-4 mb-3">
                    <span className={"btn btn-xs me-2 btn-circle " + (["item", "quantity", "reason", "confirm"].includes(step) ? " btn-primary " : " btn-light")}>
                        1
                    </span>
                    <span className={"fw-semibold"}>
                        Séléctionnez un article
                    </span>
                </div>
                <div className="col-md-4 mb-3">
                    <span className={"btn btn-xs me-2 btn-circle " + (["reason", "confirm"].includes(step) ? " btn-primary " : "btn-light")}>
                        2
                    </span>
                    <span className={"fw-semibold"}>
                        Décrivez ce qui se passe
                    </span>
                </div>
                <div className="col-md-4 mb-3">
                    <span className={"btn btn-xs me-2 btn-circle " + (["confirm"].includes(step) ? " btn-primary " : " btn-light ")}>
                        3
                    </span>
                    <span className={"fw-semibold"}>
                        Obtenez une réponse
                    </span>
                </div>
            </div>
        </div>
        {error && <ErrorCard error={error} />}
        <div className="col-12 mb-3">
            {loading ? <Loader /> : <>
                {step === "item" && <CustomerReturnAddItems handleSelectItem={item => handleItemChange(item)} />}
                {orderItem && step !== "item" && <>
                    <div className="card border border-1">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-5 d-none d-md-block">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="row">
                                                <div className="col">
                                                    <h6>
                                                        Article
                                                    </h6>
                                                </div>
                                                <div className="col-auto">
                                                    <button className="btn btn-xxs btn-light" onClick={() => setStep("item")}>
                                                        <i className={'bi bi-pencil'}></i> Modifier
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="card border border-1">
                                                <img src={`${getCoverUrl(orderItem.price.product)}`} alt="" className="card-img-top"/>
                                                <div className="card-body bg-white">
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="d-block fw-bold text-body mb-1">
                                                                {orderItem.name}
                                                            </p>
                                                            <p className="fs-xs text-muted">
                                                                Quantité: {orderItem.quantity}
                                                            </p>
                                                        </div>
                                                        <div className="col-auto">
                                                            <div className="fs-sm fw-bold text-primary">
                                                                {convertToDecimal(orderItem.priceData.total_amount_including_tax, orderItem.priceData.currency)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    {step === "quantity" && orderItem && <div className={'card h-100'}>
                                        <div className="card-header">
                                            <h6>
                                                Sur quel quantité avez vous besoin d'aide ?
                                            </h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="input-group">
                                                <input type="number" name={"quantity"} onChange={formik.handleChange} max={orderItem.quantity} step={orderItem.unit.step} defaultValue={orderItem.quantity} className="form-control"/>
                                                <span className="input-group-text">
                                            {orderItem.unit.label}
                                        </span>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <button className={"btn btn-primary ms-auto"} onClick={() => setStep("reason")}>
                                                Suivant <i className={"bi bi-arrow-right"}></i>
                                            </button>
                                        </div>
                                    </div>}
                                    {step === "reason" && <CustomerReturnAddReason setParentStep={setStep} initialValues={initialValues} setInitialValues={setInitialValues} validationSchema={validationSchema} setValidationSchema={setValidationSchema} item={orderItem} />}
                                    {step === "confirm" && <>
                                        <h6>
                                            Nous avons les informations requise.
                                        </h6>
                                        <p>
                                            En cliquant sur le bouton ci-dessous, le marchand responsable de la commande recevra votre demande dont vous pourrez suivre la résolution dans votre compte client.
                                        </p>
                                        <button className="btn btn-primary w-100 mb-3" disabled={formik.isSubmitting} onClick={() => formik.submitForm()}>
                                            Entamer une procédure de retour et de remplacement
                                        </button>
                                        <button className="btn btn-light w-100 mb-3" disabled={formik.isSubmitting}>
                                            Contacter le commerçant
                                        </button>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
            </>}
        </div>
    </div>

}

export default CustomerReturnAdd;