import {get} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query: string, offset: number, status: string, project_id: number}){
    return get(`/corporate/provider/quote${encodeQueryData(requestData)}`);
}

export function show(id: number){
    return get(`/corporate/provider/quote/${id}`);
}

export function summary(id: number){
    return get(`/corporate/provider/quote/${id}/summary`);
}