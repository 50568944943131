import React, {useEffect, useState} from "react";
import * as api from "../../adapters/country";
import {Countries, Country} from "../../models/country";
import SearchInput from "../../utils/searchInput";
import Loader from "../../utils/loader";

const ShopCountry = ({setCountry}: {setCountry: (c: Country) => void}) => {

    const [loading, setLoading] = useState(true)
    const [countries, setCountries] = useState<Countries>([]);
    const [query, setQuery] = useState("")

    useEffect(() => {
        api.list()
            .then(r => setCountries(r.data))
            .then(() => setLoading(false))
    }, [])

    return <div className="modal fade" id="countryModal" tabIndex={-1} aria-labelledby="countryModalLabel"
                aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
                <div className="modal-header">
                    <SearchInput onChange={e => setQuery(e.target.value)} size={"sm"} placeholder={"Rechercher un pays..."} />
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    {loading && <div className={'col-12 py-10'}>
                        <Loader />
                    </div>}
                    <ul className="list-group">
                        {countries.filter(c => !query || c.name.toLowerCase().includes(query)).map((c, i) => <li key={i} className={'list-group-item list-group-item-action'} onClick={() => setCountry(c)} data-bs-dismiss="modal">
                            <img style={{height: 18, width: "auto"}} src={c.flag} alt={c.name}/> {c.name}
                        </li>)}
                    </ul>
                </div>
            </div>
        </div>
    </div>

}

export default ShopCountry;