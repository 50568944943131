import React, {useState} from "react";
import {Product} from "../../../../../models/product";
import ErrorCard from "../../../../error";
import * as api from "../../../../../adapters/corporate/supplier/product";

const SupplierProductPublish = ({product, setProduct}: {product: Product, setProduct: React.Dispatch<any>}) => {

    const [publishing, setPublishing] = useState(false)
    const [error, setError] = useState(null)

    function publish(value: "public"|"private"){
        setPublishing(true)
        setError(null)
        api.update(product!.id, {visibility: value})
            .then(() => setProduct((p: Product) => ({...p, visibility: value})))
            .catch(e => setError(e))
            .finally(() => setPublishing(false))
    }

    return (
        <>
            {product.visibility === "public" && <div className={'col-12 mb-3 alert alert-success'}>
                <div className="row align-items-center">
                    <div className="col">
                        <div className="fw-semibold">
                            <i className={'bi bi-check'}></i> Produit publié
                        </div>
                        <div className="form-text">
                            Votre produit est visible et peut être commercialisé par vos partenaires
                        </div>
                    </div>
                    <div className="col-auto">
                        <button disabled={publishing} className={'btn btn-light'} onClick={() => publish("private")}>
                            Retirer <i className={'bi bi-arrow-right'}></i>
                        </button>
                    </div>
                </div>
            </div>}
            {product.visibility === "private" && <div className={'col-12 mb-3 alert alert-warning'}>
                <div className="row align-items-center">
                    <div className="col">
                        <div className="fw-semibold">
                            <i className={'bi bi-exclamation-triangle'}></i> Produit non publié
                        </div>
                        <div className="form-text">
                            Votre produit n'est pas visible et ne peut pas être commercialisé par vos partenaires
                        </div>
                    </div>
                    <div className="col-auto">
                        <button disabled={publishing} className={'btn btn-light'} onClick={() => publish("public")}>
                            Publier <i className={'bi bi-arrow-right'}></i>
                        </button>
                    </div>
                </div>
            </div>}
            {error && <ErrorCard error={error} />}
        </>
    )
}

export default SupplierProductPublish;