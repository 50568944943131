import React from "react";
import {LineItem} from "../../../../models/lineItem";
import {Currency} from "../../../../models/currency";
import {Country} from "../../../../models/country";
import convertToDecimal from "../../../../utils/currencyConverter";
import {getCoverUrl} from "../../../../utils/image";
import {useTranslation} from "react-i18next";

const CheckoutItemShow = ({item, country, currency}: { item: LineItem, country?: Country, currency: Currency }) => {
    const { t } = useTranslation();

    return <li className="list-group-item" key={item.id}>
        <div className="row align-items-center">
            <div className="col-4">
                <img className="img-fluid" src={getCoverUrl(item.product)} alt="..."/>
            </div>
            <div className="col-8">
                <p className="fs-sm fw-bold mb-2">
                    <div className="text-body">
                        {item.product.name}
                    </div>
                    <div className="text-muted">{convertToDecimal(item.price_data.unit_amount_including_tax, currency)}</div>
                    {item.price_data.recurring && <>
                        {<div className="text-muted">Tout les {item.price_data.recurring.intervalCount} {t(`${item.price_data.recurring.interval}`)}</div>}
                        {item.price_data.recurring.trialPeriodDays > 0 && <div className="text-muted">{item.price_data.recurring.trialPeriodDays} jours d'essai gratuit</div>}
                    </>}
                    {item.declination_data.length > 0 && item.declination_data.map(d => <>
                        <br />
                        <span className="form-text">{d.declination.name}: <span className={'fw-semibold'}>{d.selected?.value || <i className={'bi bi-x'}></i>}</span></span>
                    </>)}
                </p>
                {(!item.isShippingSupported || !item.isCurrencySupported) && <div className={'d-flex align-items-center'}>
                    {!item.isCurrencySupported && <span className={'alert alert-danger'}>
                        <i className={'bi bi-exclamation-circle'}></i> Devise non pris en charge
                    </span>}
                    {!item.isShippingSupported && country && <span className={'alert alert-danger'}>
                        <i className={'bi bi-exclamation-circle'}></i> Indisponible en {country.name}
                    </span>}
                </div>}
                <div className="d-flex align-items-center">
                    <span className={'text-muted'}>
                        Quantité: {item.quantity} {item.unit.label}
                    </span>
                </div>
                <h6 className="text-primary">
                    {convertToDecimal(item.price_data.total_amount_including_tax, currency)}
                </h6>
            </div>
        </div>
    </li>
}

export default CheckoutItemShow;