import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import * as models from "../../../models/checkout";
import convertToDecimal from "../../../utils/currencyConverter";
import {getCoverUrl} from "../../../utils/image";

const CheckoutSummary = ({checkout}: {checkout: models.CheckoutResponse}) => {

    const [cartLink, setCartLink] = useState("")

    useEffect(() => {
        switch (checkout.source.model){
            case "bundle":
                setCartLink(`/bundle/${checkout.source.id}`)
                break;
            case "link":
                setCartLink(`/${checkout.source.model}/${checkout.source.id}`)
                break;
            case "shop":
                setCartLink(`/${checkout.source.model}/${checkout.source.id}/cart`)
                break;
            case "pack":
                setCartLink(`/${checkout.source.model}/${checkout.source.id}`)
                break;
        }
    }, [checkout.source.model, checkout.source.id])

    return <div className="col-lg-3 mb-3">
        <div className="sticky-top py-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Récapitulatif de votre commande
                    </h6>
                </div>
                <div className="card-body">
                    {checkout.shippable && checkout.account_data.shipping && <div className="col-12 mb-3">
                        <div className="d-flex">
                            <h6 className={"flex-grow-1"}>
                                <i className={'bi bi-geo-alt'}></i> Livraison
                            </h6>
                            <div className="flex-grow-0">
                                <Link to={`/checkout/${checkout.uuid}/address`} className="text-decoration-underline text-muted">
                                    Modifier
                                </Link>
                            </div>
                        </div>
                        <div className="row flex-column">
                            <div className="col mb-2">
                                {checkout.account_data.shipping.line1}
                            </div>
                            {checkout.account_data.shipping.line2 && <div className="col mb-2">
                                {checkout.account_data.shipping.line2}
                            </div>}
                            <div className="col mb-2">
                                {checkout.account_data.shipping.city}, {checkout.account_data.shipping.country?.name}
                            </div>
                        </div>
                    </div>}
                    <div className="col-12 mb-3">
                        <div className="d-flex">
                            <h6 className={'flex-grow-1'}>Articles</h6>
                            <Link to={cartLink} className="text-decoration-underline text-muted">
                                Modifier
                            </Link>
                        </div>
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="row gx-1">
                                    {checkout.items.slice(0, 3).map(item => <div className={'col-auto'}>
                                        <div className="avatar avatar-xl">
                                            <img src={getCoverUrl(item.product)} alt="" className="avatar-img"/>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                            <div className="col-auto">
                                <button className="btn btn-sm btn-circle btn-light"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasCheckoutItem"
                                        aria-controls="offcanvasCheckoutItem"
                                >
                                    <small>{checkout.items.length > 3 && <>+{checkout.items.length - 3}</>} <i className={'bi bi-arrow-right'}></i></small>
                                </button>
                            </div>
                        </div>
                    </div>
                    {checkout.price_data.amount_including_tax > 0 && <div className="col-12 mb-3">
                        <h6>
                            Tarifs
                        </h6>
                        <table className="table mb-3">
                            <tbody>
                            {checkout.shippable && <>
                                <tr>
                                    <th>
                                        Articles
                                    </th>
                                    <td>
                                        {convertToDecimal(checkout.price_data.cart.amount_including_tax, checkout.currency)}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Frais de livraison
                                    </th>
                                    <td>
                                        <span className="form-text">
                                            {(convertToDecimal(checkout.price_data.shipping?.amount_including_tax, checkout.currency))}
                                        </span>
                                    </td>
                                </tr>
                            </>}
                            <tr>
                                <th className={"fw-semibold text-primary"}>
                                    Total
                                </th>
                                <td className={"fw-semibold text-primary"}>
                                    {convertToDecimal(checkout.price_data.amount_including_tax, checkout.currency)}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>}
                </div>
            </div>
        </div>
    </div>
}

export default CheckoutSummary;