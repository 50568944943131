import {Product, SellableProduct} from "../../../../../models/product";
import * as api from "../../../../../adapters/corporate/supplier/product/price";
import {useEffect, useState} from "react";
import {Price, Prices} from "../../../../../models/price";
import {FetchParams, Payload} from "../../../../../models/global";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import ListEmpty from "../../../../list/empty";
import SupplierPriceListItem from "./item";
import SupplierPriceShow from "../show";

interface SupplierPriceListBase {
    product: Product & SellableProduct,
    type: 'selector'|'list'
}

interface SupplierPriceListSelector {
    type: 'selector',
    selected?: Price
    handleSelect: (price: Price) => void
}

interface SupplierPriceListList {
    type: 'list',
    setDefault: (price: Price) => void
}

type SupplierPriceListInterface = SupplierPriceListBase & (SupplierPriceListSelector | SupplierPriceListList)

const SupplierPriceList = (props: SupplierPriceListInterface) => {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [fetchParams, setFetchParams] = useState<FetchParams>({offset: 0})
    const [payload, setPayload] = useState<Payload<Prices>>({count: 0, rows: []})

    useEffect(() => {
        setLoading(true)
        setError(null)
        api.list(props.product.id, fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [props.product.id, fetchParams.offset])

    if (loading){
        return <div className={"col-12 mb-3"}>*
            <Loader />
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!payload.count){
        return <ListEmpty />
    }

    return <>
        <ul className={"list-group mb-3"}>
            {payload.rows.map(r => props.type === "selector" ?
                <SupplierPriceListItem price={r} product={props.product} type={props.type} selected={props.selected} handleSelect={props.handleSelect} key={r.id}  /> :
                <SupplierPriceListItem price={r} setDefault={props.setDefault} product={props.product} type={props.type}  />)}
        </ul>
    </>
}

export default SupplierPriceList;