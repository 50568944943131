import React, {useEffect, useState} from "react";
import * as api from "../../../../../../adapters/corporate/supplier/returns";
import {Returns} from "../../../../../../models/returns";
import ErrorCard from "../../../../../error";
import ReturnStatusPlaceholder from "../../../../../common/return/status/placeholder";

const ReturnReplacementStatusDropdown = ({returns, setReturns}: { returns: Returns, setReturns: React.Dispatch<any> }) => {

    const [updating, setUpdating] = useState(false)
    const [error, setError] = useState(null)

    function updateStatus(status: "Processing"|"Shipped"|"Delivered"){
        setUpdating(true)
        api.update(returns.id, {replacementStatus: status})
            .then(r => setReturns(r.data))
            .catch(e => setError(e))
            .finally(() => setUpdating(false))
    }

    const [allowedStatuses, setAllowedStatuses] = useState<("Processing"|"Shipped"|"Delivered")[]>([]);

    useEffect(() => {

        switch (returns.replacementStatus){
            case "Processing":
                setAllowedStatuses(['Shipped'])
                break;
            case "Shipped":
                setAllowedStatuses(['Delivered'])
                break;
            case "Delivered":
                setAllowedStatuses([])
                break;
            case "Pending":
                setAllowedStatuses(["Processing"])
                break;
        }

    }, [returns.replacementStatus])

    return <><div className="dropdown">
        <button className="btn btn-xs" type="button"
                disabled={allowedStatuses.length === 0 || updating}
                id="replacementStatusSelector" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
            <ReturnStatusPlaceholder status={returns.replacementStatus} displayCaret={allowedStatuses.length > 0} />
        </button>
        <ul className="dropdown-menu">
            {allowedStatuses.map(s => <li key={s} className={'dropdown-item'} onClick={() => updateStatus(s)}>
                <ReturnStatusPlaceholder status={s} />
            </li>)}
        </ul>
    </div>
        {error && <ErrorCard error={error} />}
    </>
}

export default ReturnReplacementStatusDropdown;