import {Organization} from "../../../../../../models/organization";
import {getLogoUrl} from "../../../../../../utils/image";
import React from "react";
import {Link} from "react-router-dom";

const ProviderSearchSupplierItem = ({item}: {item: Organization}) => {

    return <div className={"col mb-3"}>
        <Link className="card h-100 border border-1 shadow-hover cursor-pointer position-relative" to={`/corporate/organization/${item.id}`} target={'_blank'}>
            {item.myPartnership && <span style={{top: 10, left: -10}} className={'badge bg-success position-absolute'}>
                <i className={'bi bi-check'}></i> Partenaire
            </span>}
            <img src={`${getLogoUrl(item)}`} alt="" className="card-img-top"/>
            <div className="card-body p-2">
                <div className="card-body">
                    <p className="d-block fw-semibold text-body mb-1">
                        {item.name} {item.status === 'Verified' && <i className={'bi bi-check-circle text-success'}></i>}
                    </p>
                    <div className="fs-sm fw-bold text-primary">
                        <i className={"bi bi-trophy"}></i> {item.ranking?.score.toLocaleString() || 0}
                    </div>
                    <div className="fs-sm text-muted">
                        {item.billing?.city}, {item.billing?.country.name}
                    </div>
                    <div className="col-12">
                        {item.sectors.slice(0,2).map(s => <span className={'badge bg-light text-dark me-2 mb-2'}>{s.name}</span>)} {item.sectors.length > 2 && <span className={'badge bg-light text-dark me-2 mb-2'}>+ {item.sectors.length - 2}</span>}
                    </div>
                </div>
            </div>
        </Link>
    </div>
}

export default ProviderSearchSupplierItem;