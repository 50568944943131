import * as models from "../../../../../models/subscription";
import React from "react";
import {useNavigate} from "react-router-dom";
import SubscriptionStatusPlaceholder from "../../../../common/subscription/status/placeholder";
import {getLogoUrl} from "../../../../../utils/image";
import CustomerSubscriptionItemList from "../../item/list";

const CustomerSubscriptionListItem = ({subscription}: {subscription: models.Subscription}) => {

    const navigate = useNavigate();

    return <div className={"col-12 mb-3"}>
        <div className="card border border-1 shadow-hover" onClick={() => navigate(`/customer/subscription/${subscription.id}`)}>
            <div className="card-body">
                <div className="row">
                    <div className="col-auto mb-3">
                        <table className={"table table-borderless"}>
                            <tbody>
                            <tr>
                                <th>Fournisseur de service</th>
                                <td>
                                <span className="avatar avatar-sm me-2">
                                     <img src={getLogoUrl(subscription.partnership.supplier)} alt={subscription.partnership.supplier.name} className="avatar-img rounded-circle" />
                                 </span>
                                    {subscription.partnership.supplier.name}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Actif depuis le
                                </th>
                                <td>
                                    {new Date(subscription.startDate).toLocaleDateString()}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Moyen de paiement
                                </th>
                                <td>
                                    {subscription.paymentMethod.brand}, **** **** **** {subscription.paymentMethod.last4}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col mb-3 text-end">
                        <SubscriptionStatusPlaceholder status={subscription.status} />
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <h6>Articles</h6>
                    <CustomerSubscriptionItemList subscription={subscription} />
                </div>
            </div>
        </div>
    </div>
}

export default CustomerSubscriptionListItem;