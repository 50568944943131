import React from "react";
import {Link, Navigate, Outlet} from "react-router-dom";
import {useAppSelector} from "../../hooks";
import {useQuery} from "../../utils/useQuery";

const VisitorLayout = () => {

    let state = useAppSelector(state => state.auth)
    const query = useQuery();


    if (state.user){

        if (query.has('redirect')){
            return <Navigate to={query.get("redirect")!} />
        }

        return <Navigate to={"/"} />
    }

    return <Outlet />
}

export default VisitorLayout;