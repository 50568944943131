import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query?: string, offset: number, organization_ids?: number[], type?: "public"|"private", sectors?: number[], sort?: "trending"|"latest"|"ranking"}){
    return get(`/corporate/provider/affiliation${encodeQueryData(requestData)}`);
}

export function show(affiliation: number){
    return get(`/corporate/provider/affiliation/${affiliation}`);
}

export function statistics(affiliation: number, requestData: {startDate: Date, endDate: Date, granularity: string}){
    return post(`/corporate/provider/affiliation/${affiliation}/statistics`, requestData);
}