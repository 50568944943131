import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query: string, offset: number, status: string}){
    return get(`/corporate/provider/project${encodeQueryData(requestData)}`);
}

export function show(id: number){
    return get(`/corporate/provider/project/${id}`);
}

export function update(id: number, requestData: {status: "Published"|"Pending"|"Canceled"}){
    return post(`/corporate/provider/project/${id}`, requestData);
}

export function add(requestData: {name: string}){
    return post(`/corporate/provider/project`, requestData);
}