import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../adapters/corporate/supplier/fee";
import React, {useState} from "react";
import PartnershipSelect from "../../../../../utils/partnershipSelect";
import Select from "../../../../../utils/select";
import {getCurrencies} from "../../../../../utils/currencies";
import FormError from "../../../../../utils/formError";
import TextAreaComponent from "../../../../form/textArea";
import ErrorCard from "../../../../error";
import Loader from "../../../../../utils/loader";
import {Fee} from "../../../../../models/fee";
import SupplierProductSelect from "../../product/select";
import SupplierTouchSelect from "../../touch/select";
import {Touch} from "../../../../../models/touch";
import {Assignment} from "../../../../../models/assignment";

const SupplierFeeAdd = ({handleSuccess, touch, assignment}: {handleSuccess: (fee: Fee) => void, touch?: Touch, assignment?: Assignment}) => {

    const [error, setError] = useState(null);

    const formik = useFormik({
        initialValues: {
            type: assignment ? 'assignment' : (touch ? 'touch' : 'other'),
            provider_id: touch ? touch.organization.id : null,
            amount: 0,
            percentage: 0,
            currency_id: null,
            touch_id: touch ? touch.id : null,
            assignment_id: assignment ? assignment.id : null,
            product_id: null,
            description: "",
            status: "Accepted"
        },
        validationSchema: Yup.object().shape({
            description: Yup.string().required(),
            status: Yup.string().required(),
            amount: Yup.number().required(),
            percentage: Yup.number().required(),
            provider_id: Yup.number().required(),
            touch_id: Yup.number().nullable(),
            assignment_id: Yup.number().nullable(),
            product_id: Yup.number().nullable(),
            currency_id: Yup.number().required()
        }),
        onSubmit: (values, {setSubmitting}) => {
            api.add(values)
                .then(r => handleSuccess(r.data))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    })

    return <form onSubmit={formik.handleSubmit}>
        {!touch && <div className="col-12 mb-3">
            <PartnershipSelect
                required={true} handleChange={c => formik.setFieldValue('provider_id', c)} pkey={"provider"}/>
            <FormError form={formik} field={"provider_id"}/>
        </div>}
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="number" step={"any"} name={"amount"} id={"amount"} onChange={formik.handleChange} className="form-control"/>
                <label htmlFor={"amount"}>
                    Montant de la transaction *
                </label>
            </div>
            <FormError form={formik} field={"amount"} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="number" step={"any"} name={"percentage"} id={"percentage"} onChange={formik.handleChange} className="form-control"/>
                <label htmlFor={"percentage"}>
                    Pourcentage de commission *
                </label>
            </div>
            <FormError form={formik} field={"percentage"} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input value={Math.ceil(formik.values.amount / 100 * formik.values.percentage)} disabled={true} type="number" step={"any"} name={"fee"} className="form-control"/>
                <label htmlFor={"percentage"}>
                    Commission HT
                </label>
            </div>
        </div>
        <div className="col-12 mb-3">
            <Select
                label={"Devise *"}
                disabled={formik.isSubmitting}
                placeholder={"Séléctionnez la devise"}
                options={getCurrencies()}
                multiple={false}
                required={true}
                onChange={choices => formik.setFieldValue('currency_id', choices.id)}
            />
            <FormError form={formik} field={"currency_id"} />
        </div>
        <div className="col-12 mb-3">
            <SupplierProductSelect required={false} handleChange={productId => formik.setFieldValue('product_id', productId)} />
        </div>
        <TextAreaComponent formik={formik} name={"description"} maxLength={255} label={"Description"} />
        <div className="col-12 mb-3">
            <Select
                label={"Statut"}
                required={true}
                options={[{label: "En attente", value: "Pending"}, {label: "Accepté", value: "Accepted"}]}
                onChange={c => formik.setFieldValue("status", c.value)}
                value={{label: "Accepté", value: "Accepted"}}
            />
        </div>
        {error && <div className="col-12 mb-3">
            <ErrorCard error={error} />
        </div>}
        <button type="submit" className="btn btn-primary w-100">
            {formik.isSubmitting ? <Loader size="sm" /> : <><i className="bi bi-check"></i> Enregistrer</>}
        </button>
    </form>
}

export default SupplierFeeAdd;