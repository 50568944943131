import {get, post} from "../../../../xhr";
import encodeQueryData from "../../../../../utils/encodeQueryData";

export function create(organization: number, requestData: {message: string}){
    return post(`/corporate/common/request/${organization}`, requestData);
}

export function decline(request: number){
    return get(`/corporate/common/request/${request}/decline`);
}

export function accept(request: number, requestData: {accessSale: string, accessAffiliation: string}){
    return get(`/corporate/common/request/${request}/accept${encodeQueryData(requestData)}`);
}

export function cancel(request: number){
    return get(`/corporate/common/request/${request}/cancel`);
}

export function list(){
    return get(`/corporate/common/request`);
}