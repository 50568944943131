import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query?: string, offset: number, status?: string, count: number, touch_id?: number, assignment_id?: number}){
    return get(`corporate/supplier/fee${encodeQueryData(requestData)}`);
}

export function show(fee: number){
    return get(`corporate/supplier/fee/${fee}`);
}

export function add(requestData: {description: string}){
    return post(`corporate/supplier/fee`, requestData);
}

export function update(fee: number, requestData: {active: boolean}){
    return post(`corporate/supplier/fee/${fee}`, requestData);
}

export function bulkUpdate(requestData: {ids: number[], status?: "Accepted"|"Refused"}){
    return post(`corporate/supplier/fee/bulk-update`, requestData);
}

export function paymentData(requestData: {ids: number[], currency_id: number}){
    return post(`corporate/supplier/fee/payment-data`, requestData);
}

export function confirmPayment(requestData: { currency_id: number, ids: number[], payment_method_id: number }){
    return post(`corporate/supplier/fee/confirm-payment`, requestData);
}