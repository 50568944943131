import React, {useEffect, useState} from "react";
import {FetchParams, Payload} from "../../../../../models/global";
import {Proposals} from "../../../../../models/proposal";
import * as api from "../../../../../adapters/corporate/supplier/proposal";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import Pagination from "../../../../../utils/pagination";
import SearchInput from "../../../../../utils/searchInput";
import {Assignment} from "../../../../../models/assignment";
import SupplierProposalListItem from "./item";

const SupplierProposalList = ({assignment}: {assignment?: Assignment}) => {

    const [loading, setLoading] = useState(true);
    const [payload, setPayload] = useState<Payload<Proposals>>({count: 0, rows: []});
    const [fetchParams, setFetchParams] = useState<FetchParams>({offset: 0})
    const [error, setError] = useState(null);
    const [status, setStatus] = useState<"All"|"Published"|'Accepted'|'Refused'|'Paid'>('All')

    useEffect(() => {
        api.list({...fetchParams, assignment_id: assignment?.id, status: status})
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    return <>
        <div className="row align-items-center">
            <div className="col mb-3">
                <SearchInput placeholder={"Rechercher une candidature..."} onChange={handleQueryChange} size={"default"} />
            </div>
            <div className="col-auto mb-3">

            </div>
        </div>
        {!loading && payload.count === 0 && <div className={'col-12 mb-3 text-muted'}><i className={'bi bi-search'}></i> Aucun résultat</div>}
        <div className="row">
            {payload.rows.map(r => <SupplierProposalListItem item={r} />)}
        </div>
        <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
    </>
}

export default SupplierProposalList;