import {get, post} from "../../xhr";
import encodeQueryData from "../../../utils/encodeQueryData";

export function list(requestData: {offset: number})
{
    return get(`customer/subscription${encodeQueryData(requestData)}`)
}

export function show(subscription: number)
{
    return get(`customer/subscription/${subscription}`)
}

export function update(subscription: number, requestData: {status?: string, payment_method_id?: number, billing_id?: number})
{
    return post(`customer/subscription/${subscription}`, requestData)
}