import {getLogoUrl} from "../../../../../../utils/image";
import React from "react";
import {Proposal} from "../../../../../../models/proposal";
import convertToDecimal from "../../../../../../utils/currencyConverter";
import {Link} from "react-router-dom";

const SupplierProposalListItem = ({item}: {item: Proposal}) => {

    return <div className="col-12 mb-3">
        <Link to={`/corporate/supplier/proposal/${item.id}`} className="card border border-1 shadow-hover">
            <div className="card-body">
                <div className="row align-items-center">
                    <div className="col-auto">
                        <div className="avatar avatar-lg">
                            <img src={getLogoUrl(item.application.organization)} alt="" className="avatar-img rounded-circle"/>
                        </div>
                    </div>
                    <div className="col">
                        <h6 className={'mb-1'}>{item.application.organization.name}</h6>
                        <p className={'mb-0'}>{item.assignment.name}</p>
                    </div>
                    <div className="col-auto">
                        <p className={"mb-0"}>{convertToDecimal(item.priceData.amount_including_tax, item.currency)} TTC</p>
                        {item.status === "Published" && <span className={'badge bg-warning'}><i className={'bi bi-clock'}></i> En attente</span>}
                        {item.status === "Accepted" && <span className={'badge bg-success'}><i className={'bi bi-check'}></i> Accepté</span>}
                        {item.status === "Refused" && <span className={'badge bg-danger'}><i className={'bi bi-x'}></i> Refusé</span>}
                    </div>
                    <div className="col-auto">
                        <i className={'bi bi-arrow-right'}></i>
                    </div>
                </div>
            </div>
        </Link>
    </div>
}

export default SupplierProposalListItem;