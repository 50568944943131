import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import * as api from "../../../../../adapters/corporate/common/customer";
import SearchInput from "../../../../../utils/searchInput";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";
import {CustomerAccounts} from "../../../../../models/account";

const CommonCustomerList = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [payload, setPayload] = useState<{count: number, rows: CustomerAccounts}>({count: 0, rows: []})
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string}>({offset: 0, query: ""})

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => console.log(e))
            .finally(() => setLoading(false))
    }, [fetchParams])


    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams(p => ({...p, query: e.target.value})), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    Clients
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col-12 mb-3">
                    <SearchInput placeholder={"Rechercher un client..."} onChange={handleQueryChange} size={"default"} />
                </div>
                <div className="col-12">
                    <span className="text-muted">
                        {loading ? <Loader size={"sm"} /> : <>{payload.count} résultats</>}
                    </span>
                </div>
            </div>
        </div>
        <div className="col-md-12 flex-grow-1">
            <div className={'table-responsive'}>
                <table className={'table table-hover'}>
                    <thead>
                    <tr>
                        <th>
                            Prénom
                        </th>
                        <th>
                            Nom
                        </th>
                        <th>
                            Email
                        </th>
                        <th>
                            Tel
                        </th>
                        <th>
                            Type
                        </th>
                        <th>
                            Nom de l'entreprise
                        </th>
                        <th>
                            Siret/Siren
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {payload.rows.map(r => <tr onClick={() => navigate(`${r.id}`)}>
                        <td>
                            {r.firstname}
                        </td>
                        <td>
                            {r.lastname}
                        </td>
                        <td>
                            {r.user.email}
                        </td>
                        <td>
                            {r.countryCode} {r.tel}
                        </td>
                        <td>
                            {r.customerType === "individual" ? "Particulier" : "Entreprise"}
                        </td>
                        <td>
                            {r.customerType === 'company' ? r.companyName : <i  className={"bi bi-x"}></i>}
                        </td>
                        <td>
                            {r.customerType === 'company' ? r.companyCode : <i  className={"bi bi-x"}></i>}
                        </td>
                    </tr>)}
                    </tbody>
                </table>
            </div>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        </div>
    </div>
}

export default CommonCustomerList;