import {useEffect, useRef, useState} from "react";
import {FetchParams, Payload} from "../../../../../../models/global";
import {Product, Products, SellableProduct} from "../../../../../../models/product";
import * as api from "../../../../../../adapters/corporate/supplier/product";
import Loader from "../../../../../../utils/loader";
import convertToDecimal from "../../../../../../utils/currencyConverter";
import {Price} from "../../../../../../models/price";

const SupplierProductPriceSelect = ({label, handleChange}: {label: string, handleChange: (product: Product & SellableProduct, price: Price) => void}) => {

    const [showMenu, setShowMenu] = useState(false);
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true);
    const [payload, setPayload] = useState<Payload<(Product & SellableProduct)[]>>({count: 0, rows: []})
    const [fetchParams, setFetchParams] = useState<FetchParams>({query: "", offset: 0})
    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(r => setPayload(prev => ({count: r.data.count, rows: [...prev.rows, ...r.data.rows]})))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])


    let timer: NodeJS.Timer;
    function handleScroll() {
        if (container.current) {
            const scrollTop = container.current.scrollTop;
            const scrollHeight = container.current.scrollHeight;
            const innerHeight = container.current.clientHeight;

            const scrolledBottom = scrollTop + innerHeight + 10 >= scrollHeight;

            if (scrolledBottom && payload.rows.length < payload.count) {
                clearTimeout(timer);
                timer = setTimeout(() => setFetchParams(prevState => ({...prevState, offset: prevState.offset + 1})), 500);
            }
        }
    }

    // function checkAndFetchMoreData() {
    //     if (container.current) {
    //         const scrollHeight = container.current.scrollHeight;
    //         const innerHeight = container.current.clientHeight;
    //
    //         if (scrollHeight <= innerHeight && payload.rows.length < payload.count) {
    //             setFetchParams(prevState => ({...prevState, offset: prevState.offset + 1}));
    //         }
    //     }
    // }
    //
    // useEffect(() => {
    //     checkAndFetchMoreData();
    // }, [payload]);

    return <div className={'w-100'}>
        <div className="form-floating mb-0">
            <input type="text" onFocus={() => setShowMenu(true)} onBlur={() => setShowMenu(false)} className="form-control" name={"query"} />
            <label htmlFor="query">
                {label}
            </label>
        </div>
        <div className="position-relative pt-2">
            <div className={`position-absolute top-0 overflow-auto collapse p-3 shadow border border-1 w-100 bg-white ${showMenu ? "show" : ""}`} style={{maxHeight: 400}} ref={container} onScroll={handleScroll}>
                {payload.count ? <ul className={"list-group list-group-flush"}>
                    {payload.rows.map(r => <li className={"list-group-item"}>
                        <div className={"w-100 fw-semibold mb-3"}>
                            {r.name}
                        </div>
                        <ul className={"list-group"}>
                            {r.prices.map(price => <li className={"list-group-item list-group-item-action text-muted"} onMouseDown={() => handleChange(r, price)}>
                                {convertToDecimal(price.amount, price.currency)} {price.recurring && <>/ {price.recurring.intervalCount} {price.recurring.interval}</>}
                            </li>)}
                        </ul>
                    </li>)}
                </ul> : (!loading ? <div className={"text-muted"}><i className={'bi bi-search'}></i> Aucun résultat</div> : <></>)}
                {loading && <div className={"col-12 text-center"}>
                    <Loader />
                </div>}
            </div>
        </div>
    </div>
}

export default SupplierProductPriceSelect;