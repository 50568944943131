import React from "react";

const InvoiceStatusPlaceholder = ({status, displayCaret}: {status: "All"| "Pending"|"Paid"|"PastDue"|"Canceled", displayCaret?: boolean}) => {

    switch (status){
        case "All":
            return <span className={'badge bg-light text-dark'}>
                    Tout les statuts
                </span>
        case "Pending":
            return <span className={'badge bg-light text-dark'}>
                    En attente {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Paid":
            return <span className={'badge bg-success'}>
                    Payé {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "PastDue":
            return <span className={'badge bg-danger'}>
                    <i className={'bi bi-exclamation-circle'}></i> Retard {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Canceled":
            return <span className={'badge bg-light'}>
                    <i className={'bi bi-x'}></i> Annulé {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
    }

    return <>Invalid status</>
}

export default InvoiceStatusPlaceholder;