import React, {useEffect, useState} from "react";
import {Bundles} from "../../../../../models/bundle";
import * as api from "../../../../../adapters/corporate/provider/bundle";
import SearchInput from "../../../../../utils/searchInput";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import Pagination from "../../../../../utils/pagination";
import {Link} from "react-router-dom";
import {getLogoUrl} from "../../../../../utils/image";

const ProviderBundleList = () => {

    const [payload, setPayload] = useState<{ count: number, rows: Bundles }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number}>({query: "", offset: 0})
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(resp => setPayload(resp.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }
    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher un taux..."} onChange={handleQueryChange} size={"default"} />
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        {error && <ErrorCard error={error} />}
        <div className="col-md-12 flex-grow-1">
            {!loading && <div className={'table-responsive'}>
                <table className={'table'}>
                    <thead>
                    <tr>
                        <th>
                            Nom
                        </th>
                        <th>
                            Description
                        </th>
                        <th>
                            Entreprise
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {payload.rows.map(item => <tr>
                        <td>
                            <Link to={`/corporate/provider/bundle/${item.id}`}>
                                {item.name}
                            </Link>
                        </td>
                        <td>
                            {item.description}
                        </td>
                        <td>
                            <Link to={`/corporate/organization/${item.id}`} className={"avatar avatar-lg text-nowrap"}>
                                <img src={getLogoUrl(item.organization)} alt={item.organization.name} className="avatar-img rounded-circle" /> {item.organization.name}
                            </Link>
                        </td>
                    </tr>)}
                    </tbody>
                </table>
            </div>}
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        </div>
    </div>
}

export default ProviderBundleList;