import * as models from "../../../../../models/order";
import {getCoverUrl} from "../../../../../utils/image";
import convertToDecimal from "../../../../../utils/currencyConverter";
import {OrderItem} from "../../../../../models/order";
import React from "react";
import {Link} from "react-router-dom";

const CustomerOrderItemShow = ({item}: {item: models.OrderItem}) => {

    return <div className={'col-12 col-md-3 col-lg-4'}>
        <div className="card border border-1">
            <img src={`${getCoverUrl(item.price.product)}`} alt="" className="card-img-top br-0"/>
            <div className="card-body bg-white">
                <div className="row">
                    <div className="col mb-3">
                        <p className="d-block fw-bold text-body mb-1">
                            {item.name}
                        </p>
                        <p className="fs-xs text-muted mb-0">
                            Quantité: {item.quantity}
                        </p>
                    </div>
                    <div className="col-auto mb-3">
                        <p className={'fw-semibold text-primary fs-5'}>
                            {convertToDecimal(item.priceData.total_amount_including_tax, item.priceData.currency)}
                        </p>
                    </div>
                </div>
                <div className="col-12 mb-3 text-end">
                    <Link to={`/customer/returns/add?orderItem=${item.id}`} className={'text-decoration-underline'}>
                        J'ai besoin d'aide avec cet article
                    </Link>
                </div>
            </div>
        </div>
    </div>
}

export default CustomerOrderItemShow;