import React, {useState} from 'react';
import {useStripe, useElements} from '@stripe/react-stripe-js';
import {useNavigate} from "react-router-dom";
import * as models from "../../../../models/checkout";
import * as api from "../../../../adapters/checkout";
import ErrorCard from "../../../error";
import Loader from "../../../../utils/loader";

const CheckoutPaymentForm = ({checkout}: {checkout: models.CheckoutResponse}) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState<string|undefined|null>(null);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const confirmSetupOrPayment = async ({stripeObject, client_secret}: {stripeObject: "payment_intent"|"setup_intent", client_secret: string}) => {
        switch (stripeObject){
            case "payment_intent":
               return await stripe!.confirmCardPayment(client_secret);
            case "setup_intent":
               return await stripe!.confirmCardSetup(client_secret);
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

        setLoading(true)
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        try {
            const { data } = await api.confirmPayment(checkout.uuid);

            const { error } = await confirmSetupOrPayment({client_secret: data.client_secret, stripeObject: data.stripeObject});

            if (error) {
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Show error to your customer (for example, payment
                // details incomplete)
                setErrorMessage(error.message);
            } else {
                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
                navigate(`/checkout/${checkout.uuid}/confirm`)
            }
        }catch (error){
            setError(error)
        } finally {
            setLoading(false)
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <button className={"btn btn-primary w-100"} disabled={!stripe || loading}>
                {loading ? <Loader /> : <><i className={'bi bi-check'}></i> Valider ma commande</>}
            </button>
            {errorMessage && <div>{errorMessage}</div>}
            {error && <ErrorCard error={error} />}
        </form>
    )
};

export default CheckoutPaymentForm;