import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Project} from "../../../../../models/project";
import * as api from "../../../../../adapters/corporate/supplier/project"
import * as quoteApi from "../../../../../adapters/corporate/supplier/quote"
import Loader from "../../../../../utils/loader";
import {getLogoUrl} from "../../../../../utils/image";
import convertToDecimal from "../../../../../utils/currencyConverter";
import ProjectLaunch from "../../../common/project/launch";
import SupplierQuoteList from "../../quote/list";
import ErrorCard from "../../../../error";

const SupplierProjectShow = () => {

    const {id} = useParams()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [project, setProject] = useState<Project>()
    const [addingQuote, setAddingQuote] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        api.show(Number(id))
            .then(r => setProject(r.data))
            .catch(error => setError(error))
            .finally(() => setLoading(false));
    }, [id])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!project){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <h6>
                    Project not found.
                </h6>
            </div>
        </div>
    }

    function addQuote(p: Project){
        setAddingQuote(true)
        quoteApi.add({project_id: p.id})
            .then(r => navigate(`/corporate/supplier/quote/${r.data.id}`))
            .catch(error => console.log(error))
            .finally(() => setAddingQuote(false))
    }

    return <div className={'container-fluid'}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/corporate/provider/project">Appels d'offres</Link>
                </li>
                <li className="breadcrumb-item">
                    {project.title}
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Détails du projet
                    </h6>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-text">
                                Apporteur
                            </div>
                            <p>
                                <span className="avatar avatar-sm me-2">
                                    <img src={getLogoUrl(project.organization)} alt={project.organization.name} className="avatar-img rounded-circle" />
                                </span>
                                <Link to={`/corporate/organization/${project.organization.id}`} target={'_blank'}>
                                    {project.organization.name}
                                </Link>
                            </p>
                        </div>
                        <div className="col-md-4">
                            <div className="form-text">
                                Sécteur
                            </div>
                            <p>
                                {project.sector.name}
                            </p>
                        </div>
                        <div className="col-md-4">
                            <div className="form-text">
                                Démarrage
                            </div>
                            <p>
                                <ProjectLaunch p={project} />
                            </p>
                        </div>
                        <div className="col-md-4">
                            <div className="form-text">
                                Localisation
                            </div>
                            <p>
                                {project.city}, {project.postalCode}, {project.country.name}
                            </p>
                        </div>
                        <div className="col-md-4">
                            <div className="form-text">
                                Budget
                            </div>
                            <p>
                                {convertToDecimal(project.condition.estimateAmount, project.currency)}
                            </p>
                        </div>
                        <div className="col-md-4">
                            <div className="form-text">
                                Devise
                            </div>
                            <p>
                                {project.currency.symbol} ({project.currency.name})
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h6>
                                Mes propositions
                            </h6>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-primary btn-xxs" onClick={() => addQuote(project)}>
                                {addingQuote ? <Loader /> : "Soumettre un devis"}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <SupplierQuoteList project={project} />
                </div>
            </div>
        </div>
        <div className="col-md-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Description du projet
                    </h6>
                </div>
                <div className="card-body">
                    <div dangerouslySetInnerHTML={{ __html: project.description}} />
                </div>
            </div>
        </div>
    </div>

}

export default SupplierProjectShow;