import * as models from "../../../../../../models/post";
import React, {useEffect, useState} from "react";
import * as api from "../../../../../../adapters/corporate/common/post/comment"
import Loader from "../../../../../../utils/loader";
import PostCommentAdd from "../add";
import PostCommentListItem from "./item";

const PostCommentList = ({post, parent, depth, setCallback, handleAddedComment}: {post: models.Post, depth: number, handleAddedComment: () => void, setCallback?: (callbackFn: (comment: models.PostComment) => void) => void, parent?: models.PostComment}) => {

    const [offset, setOffset] = useState<number>(0)
    const [loading, setLoading] = useState(true)
    const [comments, setComments] = useState<{count: number, rows: models.PostComments}>({count: 0, rows: []})

    useEffect(() => {
        setLoading(true)
        api.list(post.id, {offset: offset, parent: parent?.id})
            .then(r => setComments(p => ({count: r.data.count, rows: [...p.rows, ...r.data.rows]})))
            .then(() => setLoading(false))
    }, [post, parent, offset])

    function handleCommentAdd(comment: models.PostComment){
        setComments(prev => ({
            count: prev.count + 1,
            rows: [comment, ...prev.rows]
        }))
        handleAddedComment();
    }

    useEffect(() => {
        if(setCallback){
            setCallback(handleCommentAdd)
        }
    }, [setCallback])

    return <>
            {comments.rows.map((c, i) => <PostCommentListItem key={i} post={post} comment={c} depth={depth} />)}
            {loading && <div className={"row"}>
                {Array.from(Array(Math.ceil(depth)).keys()).map(i => <div key={i} className={'col-auto position-relative'}>
                    <div style={{width: "2.25rem", height: "2.25rem"}}></div>
                    <div style={{zIndex: 0, position: "absolute", top: 0, width: 1, backgroundColor: '#bababa', height: "100%", transform: "translate(17px)"}}></div>
                </div>)}
                <div className="col">
                    <div className={'col-12 text-center'} style={{zIndex: 0}}><Loader size={"sm"}/> chargement des commentaires...</div>
                </div>
            </div>}
            {comments.rows.length < comments.count && <div className={"row"}>
                {Array.from(Array(Math.ceil(depth)).keys()).map(i => <div key={i} className={'col-auto position-relative'}>
                    <div style={{width: "2.25rem", height: "2.25rem"}}></div>
                    <div style={{zIndex: 0, position: "absolute", top: 0, width: 1, backgroundColor: '#bababa', height: "100%", transform: "translate(17px)"}}></div>
                </div>)}
                <div className="col">
                    <div className="cursor-pointer text-decoration-underline <-100 text-center" onClick={() => setOffset(p => p + 1)}>
                        Charger plus de commentaires
                    </div>
                </div>
            </div>}

        {/*{!parent && <>*/}
        {/*    <hr className="my-1"/>*/}
        {/*    <div className={'position-sticky bottom-0 start-0 p-2 bg-white w-100'} style={{zIndex: 10}}>*/}
        {/*        <PostCommentAdd post={post} handleSuccess={handleCommentAdd}/>*/}
        {/*    </div>*/}
        {/*    </>}*/}
        {parent && <div className="row">
            {Array.from(Array(Math.ceil(depth)).keys()).map(i => <div key={i} className={'col-auto position-relative'}>
                <div style={{width: "2.25rem", height: "2.25rem"}}></div>
                <div style={{
                    zIndex: 0,
                    top: 0,
                    position: "absolute",
                    width: 1,
                    backgroundColor: '#bababa',
                    height: "100%",
                    transform: "translate(17px)"
                }}></div>
            </div>)}
            <div className="col">
                <div className="row">
                    <div className="col position-relative">
                        <PostCommentAdd post={post} parent={parent} handleSuccess={handleCommentAdd}/>
                        {depth > 0 && <div style={{
                            position: "absolute",
                            top: 0,
                            left: -31,
                            height: 17,
                            width: 35,
                            borderBottom: "solid #bababa 1px",
                            borderLeft: "solid #bababa 1px",
                            borderBottomLeftRadius: 15
                        }}></div>}
                    </div>
                </div>
            </div>
        </div>}
    </>
}

export default PostCommentList;