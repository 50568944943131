import * as models from "../../../../../../models/subscription";
import React from "react";
import CustomerSubscriptionOccurrenceShowItemList from "./item/list";
import convertToDecimal from "../../../../../../utils/currencyConverter";
import CustomerSubscriptionOccurrenceRefundList from "../refund/list";

const CustomerSubscriptionShowOccurrenceShow = ({subscription, occurrence}: {subscription: models.Subscription, occurrence: models.SubscriptionOccurrence}) => {

    return <div className={'row'}>
        <div className="col-12 mb-3">
            <h6>
                Tarifs
            </h6>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <table className={"table table-borderless"}>
                        <tbody>
                            <tr>
                                <th>Montant HT</th>
                                <td>{convertToDecimal(occurrence.priceData.amount_excluding_tax, occurrence.priceData.currency)}</td>
                            </tr>
                            <tr>
                                <th>Tax</th>
                                <td>{convertToDecimal(occurrence.priceData.tax_amount, occurrence.priceData.currency)}</td>
                            </tr>
                            <tr>
                                <th>Montant TTC</th>
                                <td>{convertToDecimal(occurrence.priceData.amount_including_tax, occurrence.priceData.currency)}</td>
                            </tr>
                            <tr>
                                <th>Remboursé</th>
                                <td>{convertToDecimal(occurrence.priceData.refunded_amount, occurrence.priceData.currency)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <h6>
                Remboursements
            </h6>
            <CustomerSubscriptionOccurrenceRefundList subscription={subscription} occurrence={occurrence} />
        </div>
        <div className="col-12 mb-3">
            <h6>
                Articles
            </h6>
            <CustomerSubscriptionOccurrenceShowItemList subscription={subscription} occurrence={occurrence} />
        </div>
    </div>
}

export default CustomerSubscriptionShowOccurrenceShow;