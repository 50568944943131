import React from "react";

const SubOrderStatusPlaceholder = ({status, displayCaret}: {status: "All"|"Pending"|"AwaitingConfirmation"|"Processing"|"Completed"|"Canceled", displayCaret?: boolean}) => {

    switch (status){
        case "All":
            return <span className={'badge bg-light text-dark'}>
                    Tout les statuts
                </span>
        case "Pending":
            return <span className={'badge bg-light text-dark'}>
                    En attente {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "AwaitingConfirmation":
            return <span className={'badge bg-warning'}>
                    En attente de confirmation {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Processing":
            return <span className={'badge bg-info'}>
                    En cours de traitement {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Completed":
            return <span className={'badge bg-success'}>
                    Terminé {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Canceled":
            return <span className={'badge bg-danger'}>
                    <i className={'bi bi-x'}></i> Annulé {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
    }

    return <>Invalid status</>
}

export default SubOrderStatusPlaceholder;