import React, {useState} from "react";
import {FeeRule} from "../../../../../../models/feeRule";
import ModalComponent from "../../../../../modal";

const SupplierFeeRuleListItem = ({feeRule}: {feeRule: FeeRule}) => {

    const [showModal, setShowModal] = useState(false)

    return <li className={"list-group-item"}>
        <div className="row">
            <div className="col">
                {feeRule.name}
            </div>
            <div className="col-auto">
                <button onClick={() => setShowModal(true)} className="btn btn-xxs">
                    <i className={'bi bi-eye'}></i>
                </button>
            </div>
        </div>
        <ModalComponent size={"modal-lg"} title={feeRule.name} show={showModal} onHide={() => setShowModal(false)}>
            <div dangerouslySetInnerHTML={{__html: feeRule.description}} />
        </ModalComponent>
    </li>
}

export default SupplierFeeRuleListItem;

