import React, {useState} from 'react';
import { useFormik } from "formik";
import * as Yup from 'yup';
import {Order, OrderItem, OrderItems, Parcels} from "../../../../../../models/order";
import * as api from "../../../../../../adapters/corporate/supplier/order/refund";
import SupplierOrderRefundAddOrderItem from "./orderItem";
import SupplierOrderRefundAddParcel from "./parcel";
import {Refund} from "../../../../../../models/refund";
import ErrorCard from "../../../../../error";
import {Returns} from "../../../../../../models/returns";

const SupplierOrderRefundAdd = ({ order, returns, items, parcels, onSuccess }: { order: Order, items: OrderItems, returns?: Returns, parcels: Parcels, onSuccess: (refund: Refund) => void }) => {

    const [error, setError] = useState<any>(null)

    const formik = useFormik({
        initialValues: {
            items: [...items.map((item: OrderItem) => ({
                type: "OrderItem",
                order_item_id: item.id,
                quantity: 0, // Default quantity to 0 for initial state
            })), ...parcels.map(p => ({
                type: "Parcel",
                parcel_id: p.id,
                included: false,
            }))]
        },
        validationSchema: Yup.object().shape({
            items: Yup.array().of(Yup.object().shape({
                type: Yup.string().oneOf(['OrderItem', 'Parcel']).required(),
                order_item_id: Yup.number().when('type', {
                    is: 'OrderItem',
                    then: (schema) => schema.required('Order item ID is required for OrderItem')
                }),
                quantity: Yup.number().when('type', {
                    is: 'OrderItem',
                    then: (schema) => schema.required('Amount is required for Parcel')
                        .min(0)
                        .test('max-quantity', 'Cannot refund more than ordered quantity', function (value) {
                            const { order_item_id } = this.parent;
                            const item = items.find(p => p.id === Number(order_item_id));

                            if (item) {
                                return value <= item.quantity;
                            }
                            return false;
                        })
                }),
                parcel_id: Yup.string().when('type', {
                    is: 'Parcel',
                    then: (schema) => schema.required('Parcel ID is required for Parcel')
                }),
                included: Yup.boolean().when('type', {
                    is: 'Parcel',
                    then: (schema) => schema.required('Included is required')
                }),
            }))
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
               const formatted = await values.items
                   .filter((i: any) => i.type === 'OrderItem' || (i.type === "Parcel" && i.included === true))
                   .map((i: any) => {
                       switch (i.type){
                           case "OrderItem":
                               return i;
                           case "Parcel":
                               return {
                                   type: i.type,
                                   parcel_id: i.parcel_id
                               }
                       }
                   })
               const {data} = await api.add(order.id, {items: formatted, returns_id: returns?.id});
               onSuccess(data)
            } catch (e) {
                setError(e)
            } finally {
                setSubmitting(false);
            }
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <h6>Articles</h6>
            <ul className={'list-group list-group-flush'}>
                {items.map((item, index) => (
                    <SupplierOrderRefundAddOrderItem
                        key={item.id}
                        item={item}
                        index={index}
                        formik={formik}
                    />
                ))}
            </ul>
            <h6>Frais de livraison</h6>
            <ul className={'list-group list-group-flush'}>
                {parcels.map((parcel, index) => (
                    <SupplierOrderRefundAddParcel
                        key={parcel.id}
                        parcel={parcel}
                        index={index + items.length}
                        formik={formik}
                    />
                ))}
            </ul>
            {error && <ErrorCard error={error} />}
            <button type="submit" className={'btn btn-primary w-100'} disabled={formik.isSubmitting}>
                Rembourser
            </button>
        </form>
    );
}

export default SupplierOrderRefundAdd;
