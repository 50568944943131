import {useFormik} from "formik";
import * as Yup from 'yup';
import TextAreaComponent from "../../../../form/textArea";
import ErrorCard from "../../../../error";
import Loader from "../../../../../utils/loader";
import React, {useState} from "react";
import * as api from '../../../../../adapters/corporate/provider/application';
import {Application} from "../../../../../models/application";
import {Assignment} from "../../../../../models/assignment";

const ProviderApplicationAdd = ({handleSuccess, assignment}: {handleSuccess: (application: Application) => void, assignment: Assignment}) => {

    const [error, setError] = useState(null)

    const formik = useFormik({
        initialValues: {
            message: ''
        },
        validationSchema: Yup.object().shape({
            message: Yup.string().required()
        }),
        onSubmit: (values, {setSubmitting}) => {
            api.add({...values, assignment_id: assignment.id})
                .then(r => handleSuccess(r.data))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    })

    return <form onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <TextAreaComponent formik={formik} name={'message'} maxLength={255} label={"Message"} />
        </div>
        {error && <ErrorCard error={error} />}
        <button type="submit" disabled={formik.isSubmitting} className="btn btn-primary w-100">
            {formik.isSubmitting ? <Loader size="sm" /> : <><i className="bi bi-send"></i> Envoyer</>}
        </button>
    </form>
}

export default ProviderApplicationAdd;