import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query: string, offset: number}){
    return get(`/corporate/supplier/webhook${encodeQueryData(requestData)}`);
}

export function show(id: number){
    return get(`/corporate/supplier/webhook/${id}`);
}

export function update(id: number, requestData:  {endpoint: string, description: string | null, scope: string[]}){
    return post(`/corporate/supplier/webhook/${id}`, requestData);
}

export function add(requestData: {endpoint: string, description: string | null, scope: string[]}){
    return post(`/corporate/supplier/webhook`, requestData);
}