import React, {useEffect, useState} from "react";
import * as accountApi from "../../../../../../adapters/corporate/common/account";
import * as api from "../../../../../../adapters/corporate/common/chat";
import SearchInput from "../../../../../../utils/searchInput";
import {CorporateAccount, CorporateAccounts} from "../../../../../../models/account";
import {getLogoUrl} from "../../../../../../utils/image";
import {Chat} from "../../../../../../models/chat";
import Pagination from "../../../../../../utils/pagination";
import ErrorCard from "../../../../../error";
import {Organization} from "../../../../../../models/organization";

const PrivateChatAdd = ({onSuccess, organization}: {onSuccess: (chat: Chat) => void, organization?: Organization}) => {

    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(null)
    const [payload, setPayload] = useState<{ count: number, rows: CorporateAccounts }>({count: 0, rows: []});
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number }>({query: "", offset: 0})

    function handleClick(account: CorporateAccount){
        if (!submitting){
            setSubmitting(true)
            api.add({account_id: account.id, type: "private"})
                .then(r => onSuccess(r.data))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    }

    useEffect(() => {
        setLoading(true)
        accountApi.list({...fetchParams, organization_id: organization?.id})
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timer;
    function handleQueryChange(query: string){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: query, offset: 0}), 1000)
    }

    return  <div className="col-12 mb-3">
        {error && <ErrorCard error={error} />}
        <div className="col-12 mb-2">
            <SearchInput placeholder={"Rechercher un contact..."} onChange={e => handleQueryChange(e.target.value)} size={'sm'} />
        </div>
        <ul className={'list-group overflow-auto'}>
            {payload.rows.map(account => <li className={'list-group-item list-group-item-action d-flex align-items-center'} onClick={() => handleClick(account)}>
                <div className="avatar avatar-lg flex-grow-0">
                    <img src={getLogoUrl(account.organization)} alt={account.organization.name} className="avatar-img rounded-circle" />
                </div>
                <div className="flex-grow-1">
                    <p className="mb-1">
                        {account.firstname} {account.lastname} | {account.jobTitle}
                    </p>
                    <span className="form-text">
                        {account.organization.name}
                    </span>
                </div>
            </li>)}
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        </ul>
    </div>
}

export default PrivateChatAdd;