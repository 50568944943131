import * as api from "../../../../../../adapters/corporate/common/stripe"
import React, {useEffect, useState} from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import Loader from "../../../../../../utils/loader";
import ErrorCard from "../../../../../error";

const StripeStatus = () => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);
    const [status, setStatus] = useState<string>();

    useEffect(() => {
        setLoading(true)
        api.status()
            .then(r => setStatus(r.data.status))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    return <div className={'h-100 w-100 d-flex align-items-center'}>
        <div className="col-auto mx-auto">
            <div className={"card border border-1"}>
                <div className="card-body">
                    {status === "processing" && <>
                        <h6>
                            Vos informations sont en cours de vérification.
                        </h6>
                        <p>
                            Vous receverez une notification par mail en cas de changement.
                        </p>
                        <button className={"btn btn-primary"}>
                            Actualiser
                        </button>
                    </>}
                    {status === "incomplete" && <>
                        <h6>
                            Des informations sont manquantes.
                        </h6>
                        <p>
                            Veuillez renseigner les informations manquantes pour poursuivre.
                        </p>
                        <Link to={'/onboarding/stripe'} className={"btn btn-primary"}>
                            Completer mes informations <i className={'bi bi-arrow-right'}></i>
                        </Link>
                    </>}
                </div>
            </div>
        </div>
    </div>
}

export default StripeStatus;