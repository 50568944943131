import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import * as api from '../../../../../adapters/corporate/supplier/affiliation';
import SearchInput from "../../../../../utils/searchInput";
import {Affiliation, Affiliations} from "../../../../../models/affiliation";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";

const SupplierAffiliationList = () => {

    const [payload, setPayload] = useState<{ count: number, rows: Affiliations }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number}>({query: "", offset: 0})

    useEffect(() => {
        setLoading(true)
        api.list({offset: fetchParams.offset, query: fetchParams.query})
            .then(resp => setPayload(resp.data))
            .then(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    function activation(affiliation: Affiliation, activate: boolean){
        api.activation(affiliation.id, {active: activate})
            .then(r => setPayload(prevState => ({count: prevState.count, rows: prevState.rows.map(oldRow => oldRow.id === r.data.id ? r.data : oldRow)})))
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher une affiliation..."} onChange={handleQueryChange} size={"default"} />
                </div>
                <div className="col-auto">
                    <Link to={"/corporate/supplier/affiliation/add"} className={'btn btn-xs rounded btn-primary'}>
                        <i className={'bi bi-plus-circle'}></i> Ajouter
                    </Link>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        <div className="col-md-12 flex-grow-1">
            {!loading && <div className={'table-responsive'}>
                <table className={'table'}>
                    <thead>
                        <tr>
                            <th>
                                Nom
                            </th>
                            <th>
                                Site
                            </th>
                            <td>
                                Devise
                            </td>
                            <td>
                                Pays
                            </td>
                            <td>
                                Activé
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {payload.rows.map(a => <tr>
                            <td>
                                <Link className="col mb-3" to={`/corporate/supplier/affiliation/${a.id}`}>{a.name}</Link>
                            </td>
                            <td>
                                {a.url}
                            </td>
                            <td>
                                {a.currencies.map(c => `${c.name} (${c.symbol})`).join(', ')}
                            </td>
                            <td>
                                <div className="btn-group">
                                    {a.countries.map(c => <span className={"me-1 mb-1"}>
                                        <img style={{height: 18, width: "auto"}} src={c.flag} alt={c.name} />
                                    </span>)}
                                </div>
                            </td>
                            <td>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" checked={a.active} onChange={e => activation(a, e.target.checked)} />
                                </div>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>}
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        </div>
    </div>
}

export default SupplierAffiliationList;