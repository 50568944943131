import React from "react";

const ReturnsStatusPlaceholder = ({status, displayCaret}: {status: string, displayCaret?: boolean}) => {

    switch (status){
        case "Pending":
            return <span className={'badge bg-warning text-white'}>
                    En attente
                </span>
        case "All":
            return <span className={'badge bg-light text-dark'}>
                    Tous les statuts
                </span>
        case "Accepted":
            return <span className={'badge bg-success'}>
                    Accepté {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
        case "Refused":
            return <span className={'badge bg-danger'}>
                    Refusé {displayCaret && <i className={'bi bi-caret-down'}></i>}
                </span>
    }

    return <>Invalid status</>
}

export default ReturnsStatusPlaceholder;