import React, {useEffect, useState} from "react";
import {Partnership, Partnerships} from "../../../../../../models/partnership";
import * as api from "../../../../../../adapters/corporate/common/partnership";
import Loader from "../../../../../../utils/loader";
import ErrorCard from "../../../../../error";
import {Payload} from "../../../../../../models/global";
import {getLogoUrl} from "../../../../../../utils/image";
import Pagination from "../../../../../../utils/pagination";
import SearchInput from "../../../../../../utils/searchInput";

const ProviderPartnerSelectorIcon = ({handleClick, selected}: {handleClick: (partnership: Partnership) => void, selected: Partnerships}) => {

    const [payload, setPayload] = useState<Payload<Partnerships>>({rows: [], count: 0})
    const [loading, setLoading] = useState(false);
    const [fetchParams, setFetchParams] = useState<{query: string, offset: number}>({query: "", offset: 0})
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timer;
    function handleQueryChange(query: string){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: query, offset: 0}), 1000)
    }

    if (error){
        return <ErrorCard error={error} />
    }

    return <div className={'col-12'}>
        <div className="row">
            <div className="col-12 mb-3">
                <SearchInput onChange={e => handleQueryChange(e.target.value)} size={"sm"} />
            </div>
        </div>
        {loading ? <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div> : <>
            {payload.count === 0 && <div className={'col-12 text-center fs-4 text-muted'}>
                <i className={'bi bi-search'}></i> Aucun partenaires trouvé
            </div>}
            <div className={"row row-cols-1 row-cols-md-3"}>
                {payload.rows.map(r => <div className={"col mb-3"}>
                    <div className="card h-100 border border-1 shadow-hover cursor-pointer position-relative" onClick={() => handleClick(r)}>
                        {selected.map(s => s.id).includes(r.id) && <span style={{top: 10, left: -10}} className={'badge bg-success position-absolute'}>
                    <i className={'bi bi-check'}></i> Séléctionné
                </span>}
                        <img src={`${getLogoUrl(r.supplier)}`} alt="" className="card-img-top"/>
                        <div className="card-body p-2">
                            <div className="card-body">
                                <p className="d-block fw-semibold text-body mb-1">
                                    {r.supplier.name} {r.supplier.status === 'Verified' && <i className={'bi bi-check-circle text-success'}></i>}
                                </p>
                                <div className="fs-sm fw-bold text-primary">
                                    <i className={"bi bi-trophy"}></i> {r.supplier.ranking?.score.toLocaleString() || 0}
                                </div>
                                <div className="col-12">
                                    {r.supplier.sectors.slice(0,2).map(s => <span className={'badge bg-light text-dark me-2 mb-2'}>{s.name}</span>)} {r.supplier.sectors.length > 2 && <span className={'badge bg-light text-dark me-2 mb-2'}>+ {r.supplier.sectors.length - 2}</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
            </div>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prevState => ({...prevState, offset: o}))} />
        </>}
    </div>
}

export default ProviderPartnerSelectorIcon;