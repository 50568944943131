import {Product} from "../../../../../models/product";
import {getCoverUrl} from "../../../../../utils/image";
import React, {useState} from "react";
import ModalComponent from "../../../../modal";
import CommonProductShow from "../show";

const CommonProductListItem = ({item}: {item: Product}) => {

    const [showModal, setShowModal] = useState(false)

    return <div className={"col mb-3"} style={{height: 360}}>
        <div className="card card-sm border border-1 position-relative h-100">
            {item.sellable && item.price.recurring && <span style={{top: 10, left: -10}} className={'badge bg-primary position-absolute'}>
                <i className={'bi bi-arrow-repeat'}></i> Abonnement
            </span>}
            <img src={`${getCoverUrl(item)}`} alt="" className="card-img-top card-img-top-product-list-item"/>
            <div className="card-body d-flex flex-column">
                <div className="col-12 mb-3 flex-grow-1">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <p className="d-block fw-bold text-body mb-1 text-nowrap overflow-hidden" style={{textOverflow: "ellipsis"}}>
                                {item.name}
                            </p>
                            <p className="d-flex align-items-center text-body mb-1 text-muted fs-xxs">
                                <span>{item.category?.name || "Non catégorisé"}</span>
                            </p>
                            <div className="fs-sm fw-bold text-primary">
                                <i className={"bi bi-trophy"}></i> {item.ranking?.score.toLocaleString() || 0}
                            </div>
                            {item.sellable && <div className="fs-sm fw-bold text-success">
                                {item.price.feePercent}% <span className="text-muted">Commission</span>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="col-12 flex-grow-0">
                    <button className="btn btn-sm btn-light btn-xxs w-100" onClick={() => setShowModal(true)}>
                        Voir plus
                    </button>
                </div>
            </div>
        </div>
        <ModalComponent size={"modal-lg"} title={`${item.name}`} show={showModal} onHide={() => setShowModal(false)}>
            {showModal && <CommonProductShow id={item.id}/>}
        </ModalComponent>
    </div>
}

export default CommonProductListItem;