import React, {useState} from "react";
import {ReturnPolicy} from "../../../../../../models/returns";
import {Link} from "react-router-dom";
import * as api from "../../../../../../adapters/corporate/supplier/returns/policy";
import ModalComponent from "../../../../../modal";

const SupplierReturnsPolicyListItem = ({policy}: {policy: ReturnPolicy}) => {

    const [updating, setUpdating] = useState(false)
    const [showModal, setShowModal] = useState(false)

    function activation(checked: boolean){
        setUpdating(true);
        api.update(policy.id, {default: checked})
            .finally(() => setUpdating(false))
    }

    return <li className={"list-group-item"}>
        <div className="row">
            <div className="col">
                <Link to={``}>
                    {policy.name}
                </Link>
            </div>
            <div className="col-auto">
                <button onClick={() => setShowModal(true)} className="btn btn-xxs">
                    <i className={'bi bi-eye'}></i>
                </button>
            </div>
            <div className="col-auto">
                <div className="form-check form-switch">
                    <input disabled={updating} className="form-check-input" type="radio" role="switch" name="default" defaultChecked={policy.default} onChange={e => activation(e.target.checked)} />
                </div>
            </div>
        </div>
        <ModalComponent size={"modal-lg"} title={policy.name} show={showModal} onHide={() => setShowModal(false)}>
            <div dangerouslySetInnerHTML={{__html: policy.details}} />
        </ModalComponent>
    </li>
}

export default SupplierReturnsPolicyListItem;

