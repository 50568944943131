import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Order} from "../../../../../models/order";
import * as api from "../../../../../adapters/corporate/provider/order";
import Loader from "../../../../../utils/loader";
import {S3_BUCKET_URL, S3_ORGANIZATION_BUCKET} from "../../../../../constants/global";
import convertToDecimal from "../../../../../utils/currencyConverter";
import OrderStatusPlaceholder from "../../../../common/order/status/placeholder";
import CommonTransferList from "../../../common/transfer/list";
import CorporateCommonOrderItemList from "../../../common/order/item/list";

const ProviderOrderShow = () => {

    const params = useParams();
    const [order, setOrder] = useState<Order>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true)
        api.show(Number(params.id))
            .then(r => setOrder(r.data))
            .then(() => setLoading(false))
    }, [params.id])

    if (loading){
        return <div className={'container-fluid py-10 text-center'}>
            <Loader />
        </div>
    }

    if (!order){
        return <div className={'container-fluid py-10 text-center'}>
            <span className={'display-6'}>
                <i className={"bi bi-search"}></i> Commande non trouvé
            </span>
        </div>
    }

    return <div className={'container-fluid'}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/corporate/provider/order">Commande</Link>
                </li>
                <li className="breadcrumb-item">
                    #{order.number}
                </li>
            </ol>
        </div>
        <div className="row">
            <div className="col-md-12 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h6>
                                    Informations
                                </h6>
                            </div>

                        </div>
                    </div>
                    <div className="card-body">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>
                                        Statut
                                    </th>
                                    <td>
                                        <OrderStatusPlaceholder status={order.status} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Date
                                    </th>
                                    <td>
                                        {new Date(order.checkout.orderedAt).toLocaleString()}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Fournisseur
                                    </th>
                                    <td>
                                        <div className="avatar avatar-xs me-2">
                                            <img src={`${S3_BUCKET_URL}${S3_ORGANIZATION_BUCKET}${order.partnership.supplier.id}/logo/${order.partnership.supplier.logoName}`} alt={order.partnership.supplier.name} className="avatar-img rounded-circle" />
                                        </div>
                                        <span>
                                            {order.partnership.supplier.name}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Commission
                                    </th>
                                    <td>
                                        {convertToDecimal(order.feeData.provider, order.checkout.currency)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>Transfers</h6>
                    </div>
                    <div className="card-body">
                        <CommonTransferList order={order} />
                    </div>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>
                            Articles
                        </h6>
                    </div>
                    <div className="card-body">
                        <CorporateCommonOrderItemList order={order} />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ProviderOrderShow;