export const services = [
    {
        "title": "Prospection et Acquisition de Clients",
        "slug": "prospecting_and_customer_acquisition",
        "items": [
            {
                "id": 1,
                "title": "Génération de leads",
                "description": "Identification et collecte de prospects potentiels à travers des campagnes de marketing, inbound marketing, réseaux sociaux, ou plateformes de génération de leads.",
                "slug": "lead_generation"
            },
            {
                "id": 2,
                "title": "Prospection commerciale",
                "description": "Prise de contact directe avec des prospects via le téléphone (téléprospection), les e-mails, ou les réseaux sociaux.",
                "slug": "sales_prospecting"
            },
            {
                "id": 3,
                "title": "Qualification de leads",
                "description": "Analyse et validation des prospects pour identifier ceux qui sont les plus susceptibles de devenir clients.",
                "slug": "lead_qualification"
            },
            {
                "id": 4,
                "title": "Automatisation de la prospection",
                "description": "Utilisation d'outils d’automatisation pour organiser, planifier et automatiser les campagnes de prospection.",
                "slug": "prospecting_automation"
            }
        ]
    },
    {
        "title": "Stratégie et Conseil en Développement Commercial",
        "slug": "strategy_and_business_development",
        "items": [
            {
                "id": 5,
                "title": "Stratégie commerciale",
                "description": "Définition d’une stratégie de vente, positionnement, et segmentation de marché.",
                "slug": "sales_strategy"
            },
            {
                "id": 6,
                "title": "Conseil en développement d’affaires",
                "description": "Aide pour identifier de nouvelles opportunités de croissance, expansions sur de nouveaux marchés, partenariats stratégiques.",
                "slug": "business_development_consulting"
            },
            {
                "id": 7,
                "title": "Stratégie de fidélisation client",
                "description": "Élaboration de plans pour augmenter la rétention des clients, améliorer leur satisfaction et développer leur cycle de vie.",
                "slug": "customer_retention_strategy"
            },
            {
                "id": 8,
                "title": "Stratégie de pricing et d’optimisation des ventes",
                "description": "Analyse des politiques de prix et des offres pour maximiser les revenus tout en restant compétitif.",
                "slug": "pricing_and_sales_optimization"
            }
        ]
    },
    {
        "title": "Marketing Digital et Acquisition en Ligne",
        "slug": "digital_marketing_and_online_acquisition",
        "items": [
            {
                "id": 9,
                "title": "Stratégie digitale",
                "description": "Planification de la présence en ligne incluant SEO, publicité en ligne, et réseaux sociaux.",
                "slug": "digital_strategy"
            },
            {
                "id": 10,
                "title": "SEO et SEA",
                "description": "Optimisation pour les moteurs de recherche et gestion des campagnes publicitaires sur Google, Bing, etc.",
                "slug": "seo_sea"
            },
            {
                "id": 11,
                "title": "Content Marketing",
                "description": "Création de contenus tels que des articles, vidéos, et infographies pour attirer des prospects.",
                "slug": "content_marketing"
            },
            {
                "id": 12,
                "title": "Social Media Management",
                "description": "Gestion des réseaux sociaux pour créer de l’engagement, attirer des prospects et générer de la notoriété.",
                "slug": "social_media_management"
            },
            {
                "id": 13,
                "title": "Marketing Automation",
                "description": "Utilisation d'outils pour automatiser les campagnes marketing et les parcours clients.",
                "slug": "marketing_automation"
            }
        ]
    },
    {
        "title": "Apport d’Affaires et Partenariats Stratégiques",
        "slug": "business_introduction_and_strategic_partnerships",
        "items": [
            {
                "id": 14,
                "title": "Apport d'affaires",
                "description": "Mise en relation avec des prospects qualifiés ou des partenaires d’affaires potentiels en échange d’une commission ou rémunération.",
                "slug": "business_introduction"
            },
            {
                "id": 15,
                "title": "Développement de partenariats",
                "description": "Identification, négociation, et gestion de partenariats stratégiques pour créer des opportunités de marché.",
                "slug": "partnership_development"
            },
            {
                "id": 16,
                "title": "Co-marketing et co-branding",
                "description": "Collaboration avec des entreprises complémentaires pour augmenter la visibilité mutuelle et attirer de nouveaux clients.",
                "slug": "co_marketing_co_branding"
            }
        ]
    },
    {
        "title": "Ventes et Gestion des Comptes",
        "slug": "sales_and_account_management",
        "items": [
            {
                "id": 17,
                "title": "Ventes B2B",
                "description": "Services de vente auprès des entreprises, incluant les présentations, démonstrations et négociations.",
                "slug": "b2b_sales"
            },
            {
                "id": 18,
                "title": "Account management",
                "description": "Gestion de la relation avec les clients existants pour maximiser la satisfaction, les ventes additionnelles et la fidélisation.",
                "slug": "account_management"
            },
            {
                "id": 19,
                "title": "Customer Success",
                "description": "Accompagnement des clients pour garantir qu'ils tirent le maximum de valeur des produits ou services.",
                "slug": "customer_success"
            },
            {
                "id": 20,
                "title": "Upsell et cross-sell",
                "description": "Identification d’opportunités pour vendre des produits ou services complémentaires ou de gamme supérieure aux clients existants.",
                "slug": "upsell_cross_sell"
            }
        ]
    },
    {
        "title": "Formation et Coaching Commercial",
        "slug": "sales_training_and_coaching",
        "items": [
            {
                "id": 21,
                "title": "Formation en prospection et vente",
                "description": "Cours et séminaires pour améliorer les techniques de prospection, de négociation et de closing.",
                "slug": "prospecting_sales_training"
            },
            {
                "id": 22,
                "title": "Coaching commercial",
                "description": "Accompagnement individuel ou en équipe pour booster les compétences de vente.",
                "slug": "sales_coaching"
            },
            {
                "id": 23,
                "title": "Formation sur les outils CRM et d’automatisation",
                "description": "Sessions de formation pour maîtriser les logiciels de gestion de la relation client et de prospection.",
                "slug": "crm_automation_training"
            },
            {
                "id": 24,
                "title": "Mentorat en développement d’affaires",
                "description": "Programme de mentorat pour aider les responsables commerciaux à renforcer leur expertise.",
                "slug": "business_development_mentorship"
            }
        ]
    },
    {
        "title": "Intelligence et Analyse Commerciale",
        "slug": "sales_intelligence_and_analysis",
        "items": [
            {
                "id": 25,
                "title": "Analyse de marché et de concurrence",
                "description": "Études pour comprendre les tendances de marché, la position de l'entreprise et les forces concurrentielles.",
                "slug": "market_competitor_analysis"
            },
            {
                "id": 26,
                "title": "Data analytics et scoring",
                "description": "Utilisation de l’analyse de données pour évaluer les leads, prédire les ventes, et améliorer les campagnes.",
                "slug": "data_analytics_scoring"
            },
            {
                "id": 27,
                "title": "Reporting et tableaux de bord",
                "description": "Création de rapports et de KPIs pour suivre les performances des ventes et ajuster les stratégies.",
                "slug": "reporting_dashboards"
            }
        ]
    },
    {
        "title": "Support Technologique aux Ventes",
        "slug": "sales_technology_support",
        "items": [
            {
                "id": 28,
                "title": "Outils CRM",
                "description": "Mise en place, gestion et optimisation des logiciels CRM pour suivre les prospects, les opportunités et les clients.",
                "slug": "crm_tools"
            },
            {
                "id": 29,
                "title": "Solutions de marketing automation",
                "description": "Intégration d’outils pour automatiser les tâches marketing.",
                "slug": "marketing_automation_solutions"
            },
            {
                "id": 30,
                "title": "Outils d’aide à la vente",
                "description": "Fourniture de solutions de présentations, de démonstrations produits virtuelles, d'outils de personnalisation pour les clients.",
                "slug": "sales_enablement_tools"
            }
        ]
    }
]
