import Select from "../../../../../utils/select";
import React, {useEffect, useState} from "react";
import {TaxRates} from "../../../../../models/taxRate";
import * as api from "../../../../../adapters/corporate/supplier/taxRate";
import SupplierTaxRateAdd from "../add";
import ModalComponent from "../../../../modal";

const SupplierTaxRateSelect = (props: {required: boolean, handleChange: (choice: number) => void, allowAdd: boolean, defaultValue?: {label: string, value: any}}) => {

    const [loading, setLoading] = useState(true)
    const [payload, setPayload] = useState<{count: number, rows: TaxRates}>({count: 0, rows: []})
    const [fetchParams, setFetchParams] = useState<{offset: number, query: string}>({offset: 0, query: ""})
    const [defaultValue, setDefaultValue] = useState({label: props.defaultValue?.label || "", value: props.defaultValue?.value || null});
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => console.log(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    return <>
        <div className="row align-items-center">
            <div className="col">
                <Select
                    key={defaultValue.value}
                    value={defaultValue}
                    multiple={false}
                    disabled={loading}
                    required={props.required}
                    label={"Taux de taxe"}
                    options={payload.rows.map(c => ({label: c.name, value: c.id}))}
                    onChange={(c: any) => props.handleChange(c.value)}
                    handleScrollBottom={() => setFetchParams(p => ({...p, offset: p.offset + 1}))}
                    handleQueryChange={(query) => setFetchParams(p => ({query: query, offset: 0}))}
                />
            </div>
            {props.allowAdd && <div className="col-auto">
                <button className={"btn btn-light btn-xs rounded"} type={"button"}>
                    <i className={'bi bi-plus-circle'}></i>
                </button>
            </div>}
        </div>
        <ModalComponent title={"Ajouter un taux de taxe"} show={showModal} onHide={() => setShowModal(false)}>
            <SupplierTaxRateAdd handleSuccess={(taxRate) => {
                setDefaultValue({label: taxRate.name, value: taxRate.id})
                props.handleChange(taxRate.id)
                setShowModal(false)
            }} />
        </ModalComponent>
    </>
}

export default SupplierTaxRateSelect;