import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {LineItems} from "../../models/lineItem";
import {CartInterface} from "../../models/cart";

const initialState = {
    id: null,
    items: [],
    price_data: {
        total_amount_excluding_tax: 0,
        total_tax_amount: 0,
        total_amount_including_tax: 0
    },
    loading: false
} as CartInterface;

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setCart: (state, action: PayloadAction<{ storageKey: string | null, cart: CartInterface }>) => {
            state.id = action.payload.cart.id;
            state.price_data = action.payload.cart.price_data;
            state.items = action.payload.cart.items;
            state.loading = false;
            if (action.payload.storageKey){
                saveCart(action.payload.storageKey, action.payload.cart);
            }
        },
        clearCart: (state, action: PayloadAction<{ storageKey: string, cart: CartInterface }>) => {
            state.id = null;
            state.price_data = {
                total_amount_excluding_tax: 0,
                total_tax_amount: 0,
                total_amount_including_tax: 0
            };
            state.items = [];
            clearCart(action.payload.storageKey, action.payload.cart);
        },
        loadCart: (state) => {
            state = {
                id: null,
                items: [],
                price_data: {
                    total_amount_excluding_tax: 0,
                    total_tax_amount: 0,
                    total_amount_including_tax: 0
                },
                loading: true
            };
        },
    }
})

function saveCart(key: string, cart: CartInterface){
    localStorage.setItem(`${key}`, JSON.stringify(cart));
}

function clearCart(key: string, cart: CartInterface){
    localStorage.setItem(`${key}`, JSON.stringify(cart));
}

export default cartSlice