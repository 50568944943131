import * as models from "../../../../../../models/pack";
import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup"
import * as api from "../../../../../../adapters/corporate/supplier/pack/item"
import SupplierProductSelect from "../../../product/select";
import Loader from "../../../../../../utils/loader";

const SupplierPackItemAdd = ({pack, handleSuccess}: {pack: models.Pack, handleSuccess: (item: models.PackItem) => void}) => {

    const [error, setError] = useState(null)

    const formik = useFormik({
        initialValues: {
            product_id: null,
            quantity: 1,
            editable: true
        },
        validationSchema: Yup.object().shape({
            product_id: Yup.number().required(),
            quantity: Yup.number().required(),
            editable: Yup.boolean().required()
        }),
        onSubmit: (values, {setSubmitting}) => {
            api.add(pack.id, values)
                .then(r => handleSuccess(r.data))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    })

    return <form onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <SupplierProductSelect required={true} handleChange={productId => formik.setFieldValue('product_id', productId)} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="number" name={'quantity'} id={'quantity'} className="form-control" onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                <label htmlFor="quantity">
                    Quantitée
                </label>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch" id="editable" name={"editable"} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                <label className="form-check-label" htmlFor="editable">
                    Quantitée modifiable
                </label>
            </div>
        </div>
        <button type={'submit'} className="btn btn-primary w-100">
            {formik.isSubmitting ? <Loader size={"sm"} /> : <><i className={"bi bi-check"}></i> Ajouter</>}
        </button>
    </form>
}

export default SupplierPackItemAdd;