import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../adapters/quote";
import convertToDecimal from "../../../../utils/currencyConverter";
import {Quote} from "../../../../models/quote";
import React, {useState} from "react";
import Loader from "../../../../utils/loader";

const QuoteSignatureSign = ({quote, handleSign}: {quote: Quote, handleSign: (email: string) => void}) => {
    
    const [error, setError] = useState<any>(null)

    const formik = useFormik({
        initialValues: {
            signature: `${quote.account.firstname} ${quote.account.lastname}`,
        },
        validationSchema: Yup.object().shape({
            signature: Yup.string().required()
        }),
        onSubmit: (values, formikHelpers) => {
            setError(null);
            api.signature(quote.uuid, values)
                .then(r => handleSign(r.data.email))
                .catch(error => setError(error))
                .finally(() => formikHelpers.setSubmitting(false))
        }
    })

    return <div className="card border border-1 border-success">
        <div className="card-header">
            <h6>
                Valider le devis
            </h6>
        </div>
        <div className="card-body">
            <p>
                En signant ce devis, j'accepte les conditions suivantes:
            </p>
            <ul>
                {quote.account.customerType === "company" && <li>
                    Je signe ce devis au nom de: <span className={"fw-semibold"}>{quote.account.companyName}</span>
                </li>}
                <li>
                    Je valide le montant du devis de: {convertToDecimal(quote.priceData.total_amount_including_tax, quote.priceData.currency)} TTC
                </li>
                <li>
                    Le paiement de la totalité du montant du devis immédiatement auprès de la plateforme Varar.
                    <ul>
                        {quote.condition.deposit > 0 && quote.condition.deposit < 100 && <>
                            <li>
                                Varar transferera sans délais un accompte au prestataire équivalant à {quote.condition.deposit}% du montant total.
                            </li>
                            <li>
                                Une fois la prestation livrée et validé par le prestataire et le client, le solde restant sera versé au prestataire.
                            </li>
                        </>}
                        {quote.condition.deposit === 0 && <li>
                            Le prestataire n'ayant pas demandé d'accompte, le montant lui sera transféré une fois la prestation terminé et validé par le prestataire et le client.
                        </li>}
                        {quote.condition.deposit === 100 && <li>
                            Varar transferera sans délais la totalité du montant au prestataire.
                        </li>}
                    </ul>
                </li>
            </ul>
            <form onSubmit={formik.handleSubmit}>
                <div className="card border border-1">
                    <div className="card-header">
                        Votre signature
                    </div>
                    <div className="card-body">
                        {error && <div className={'alert alert-danger'}>
                            {error.response.data.error}
                        </div>}
                        <div className="form-floating">
                            <input type="text" name={"signature"} id={"signature"} defaultValue={`${quote.account.firstname} ${quote.account.lastname}`} className="form-control"/>
                            <label htmlFor="signature">
                                Nom complet
                            </label>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button type={"submit"} className={"btn btn-primary"}>
                            {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-check'}></i> Signer</>}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
}

export default QuoteSignatureSign;