import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import * as models from "../../../../../models/shop";
import * as api from "../../../../../adapters/corporate/provider/shop";
import SearchInput from "../../../../../utils/searchInput";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";
import {APP_URL} from "../../../../../constants/global";
import ErrorCard from "../../../../error";

const ProviderShopList = () => {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [fetchParams, setFetchParams] = useState<{query: string, offset: number}>({query: "", offset: 0});
    const [payload, setPayload] = useState<{count: number, rows: models.Shops}>({count: 0, rows: []})

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher une boutique..."} onChange={handleQueryChange} size={"default"} />
                </div>
                <div className="col-auto">
                    <Link to={"add"} className="btn btn-xs rounded btn-primary">
                        <i className={'bi bi-plus-circle'}></i> Ajouter
                    </Link>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        {error && <ErrorCard error={error} />}
        <div className="col-md-12 flex-grow-1">
            <ul className="list-group">
                {payload.rows.map(r => <li className={"list-group-item"}>
                    <div className="row">
                        <div className="col">
                            <Link to={`/corporate/provider/shop/${r.id}`}>
                                {r.name}
                            </Link>
                        </div>
                        <div className="col-auto">
                            <Link to={`${APP_URL}shop/${r.uuid}`} target={'_blank'}>
                                Ouvrir la boutique <i className="bi bi-box-arrow-up-right"></i>
                            </Link>
                        </div>
                    </div>
                </li>)}
            </ul>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        </div>
    </div>
}

export default ProviderShopList;