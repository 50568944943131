import {get, post} from "../../../xhr";

export function add(requestData: any){
    return post("corporate/supplier/shipping/add", requestData)
}

export function list(requestData: {query: string, offset: number}){
    return post("corporate/supplier/shipping/list", requestData);
}

export function show(shipping: number){
    return get(`corporate/supplier/shipping/${shipping}`);
}

export function linkAddress(shipping: number, address: number){
    return get(`corporate/supplier/shipping/address/${shipping}/link/${address}`);
}

export function unlinkAddress(shipping: number, address: number){
    return get(`corporate/supplier/shipping/address/${shipping}/unlink/${address}`);
}
