import encodeQueryData from "../../../../utils/encodeQueryData";
import {post, get} from "../../../xhr";

export function list(requestData: { query?: string, offset: number}){
    return get(`corporate/provider/application${encodeQueryData(requestData)}`)
}

export function show(application: number){
    return get(`corporate/provider/application/${application}`)
}

export function add(requestData: any){
    return post(`corporate/provider/application`, requestData)
}

export function update(application: number, requestData: any){
    return post(`corporate/provider/application/${application}`, requestData);
}