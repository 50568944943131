import React from "react";

const QuoteNavFooter = () => {

    return <>
        <div className="col-12 bg-dark pt-10">
            <div className="container mx-auto">
                <div className="row">
                    <div className="col">

                    </div>
                    <div className="col">

                    </div>
                    <div className="col">

                    </div>
                    <div className="col">

                    </div>
                </div>
                <hr className={'text-white'}/>
                <div className="col-12 d-flex align-items-center ps-3 text-white-50 pb-3">
                    <img src={process.env.PUBLIC_URL + '/img/logo.png'} className={"rounded-circle me-2"} style={{height: 20, width: "auto"}} alt=""/> <span><small>Powered by <a className={"text-white-50 text-decoration-underline"} target={"_blank"} href={"https://varar.co"}>varar</a></small></span>
                </div>
            </div>
        </div>
    </>
}

export default QuoteNavFooter;