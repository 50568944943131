import React, {useEffect, useState} from "react";
import * as api from '../../../../../adapters/corporate/provider/contact';
import SearchInput from "../../../../../utils/searchInput";
import Loader from "../../../../../utils/loader";
import {Contacts} from "../../../../../models/contact";
import Pagination from "../../../../../utils/pagination";
import ErrorCard from "../../../../error";
import ProviderContactListItem from "./item";
import ModalComponent from "../../../../modal";
import ProviderContactAdd from "../add";

const ProviderContactList = () => {

    const [payload, setPayload] = useState<{ count: number, rows: Contacts }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number}>({query: "", offset: 0})
    const [error, setError] = useState(null)
    const [showAddModal, setShowAddModal] = useState(false)

    useEffect(() => {
        setLoading(true)
        api.list({offset: fetchParams.offset, query: fetchParams.query})
            .then(resp => setPayload(resp.data))
            .catch(error => setError(error))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    Contact
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher une politique de retour..."} onChange={handleQueryChange} size={"default"} />
                </div>
                <div className="col-auto">
                    <button className={'btn btn-primary btn-xs rounded'} onClick={() => setShowAddModal(true)}>
                        <i className={'bi bi-plus-circle'}></i> Ajouter
                    </button>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        {error && <ErrorCard error={error} />}
        <div className="col-md-12 flex-grow-1">
            {!loading && <ul className={'list-group'}>
                    {payload.rows.map(contact => <ProviderContactListItem contact={contact} />)}
            </ul>}
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        </div>
        {showAddModal && <ModalComponent title={"Ajouter un contact"} show={showAddModal} onHide={() => setShowAddModal(false)}>
            <ProviderContactAdd onSuccess={r => {
                setPayload(prevState => ({count: prevState.count + 1, rows: [r, ...prevState.rows]}))
                setShowAddModal(false)
            }} />
        </ModalComponent>}
    </div>
}

export default ProviderContactList;