import {Assignments} from "../../../../../../../models/assignment";
import React, {useEffect, useState} from "react";
import * as api from "../../../../../../../adapters/corporate/provider/assignment";
import Loader from "../../../../../../../utils/loader";
import ErrorCard from "../../../../../../error";
import Pagination from "../../../../../../../utils/pagination";
import ProviderAssignmentItem from "../../../../../provider/search/assignment/item";
import {Organization} from "../../../../../../../models/organization";

const OrganizationAssignment = ({organization}: {organization: Organization}) => {

    const [loading, setLoading] = useState(true)
    const [payload, setPayload] = useState<{count: number, rows: Assignments}>({count: 0, rows: []});
    const [offset, setOffset] = useState(0)
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        api.list({offset: offset, organization_id: organization.id})
            .then(r => setPayload(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [organization.id, offset])

    if (loading){
        return <div className={'container-fluid py-10 text-center'}>
            <Loader />
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }


    if (!payload.count){
        return <div className={'container-fluid py-10 text-center'}>
            <h6 className="text-muted">
                <i className={"bi bi-search"}></i> Aucun réultat
            </h6>
        </div>
    }

    return <>
        <div className="row">
            {payload.rows.map(r => <ProviderAssignmentItem item={r} />)}
        </div>
        <Pagination count={payload.count} offset={offset} setOffset={o => setOffset(o)} />
    </>
}

export default OrganizationAssignment;