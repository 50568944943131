import {get} from "../../../../xhr";
import encodeQueryData from "../../../../../utils/encodeQueryData";

export function list(webhookId: number, requestData: {query: string, offset: number}){
    return get(`/corporate/supplier/webhook/${webhookId}/event${encodeQueryData(requestData)}`);
}

export function show(webhookId: number, id: number){
    return get(`/corporate/supplier/webhook/${webhookId}/event/${id}`);
}

export function refresh(webhookId: number, id: number){
    return get(`/corporate/supplier/webhook/${webhookId}/event/${id}/refresh`);
}