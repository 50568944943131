import * as models from "../../../../../../models/pack";
import React, {useEffect, useState} from "react";
import {FetchParams, Payload} from "../../../../../../models/global";
import {PackItems} from "../../../../../../models/pack";
import * as api from "../../../../../../adapters/corporate/supplier/pack/item";
import SearchInput from "../../../../../../utils/searchInput";
import Loader from "../../../../../../utils/loader";
import ErrorCard from "../../../../../error";
import Pagination from "../../../../../../utils/pagination";
import ModalComponent from "../../../../../modal";
import SupplierPackItemAdd from "../add";
import SupplierPackItemListItem from "./item";

const SupplierPackItemList = ({pack}: {pack: models.Pack}) => {

    const [payload, setPayload] = useState<Payload<PackItems>>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<FetchParams>({query: "", offset: 0})
    const [error, setError] = useState(null)
    const [showAddModal, setShowAddModal] = useState(false);

    useEffect(() => {
        setLoading(true)
        api.list(pack.id, {offset: fetchParams.offset, query: fetchParams.query})
            .then(resp => setPayload(resp.data))
            .catch(error => setError(error))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher un article..."} onChange={handleQueryChange} size={"default"} />
                </div>
                <div className="col-auto">
                    <button onClick={() => setShowAddModal(true)} className={'btn btn-xs rounded btn-primary'}>
                        <i className={'bi bi-plus-circle'}></i> Ajouter
                    </button>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        {error && <ErrorCard error={error} />}
        <div className="col-md-12 flex-grow-1">
            {!loading && <div className={'row row-cols-1 row-cols-md-3 row-cols-lg-4'}>
                {payload.rows.map(item => <SupplierPackItemListItem
                    pack={pack}
                    item={item}
                    onRemove={removed => setPayload(prev => ({count: prev.count - 1, rows: [...prev.rows.filter(i => i.id !== removed.id)]}))}
                />)}
            </div>}
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        </div>
        <ModalComponent title={'Ajouter un article'} show={showAddModal} onHide={() => setShowAddModal(false)}>
            <SupplierPackItemAdd pack={pack} handleSuccess={item => {
                setPayload(prev => ({count: prev.count + 1, rows: [item, ...prev.rows]}))
                setShowAddModal(false)
            }} />
        </ModalComponent>
    </div>
}

export default SupplierPackItemList;