import {Affiliation} from "../../../../../../models/affiliation";
import {getLogoUrl} from "../../../../../../utils/image";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import ModalComponent from "../../../../../modal";
import ProviderAffiliationShowInformation from "../../../affiliation/show/information";
import ProviderAffiliationRules from "../../../affiliation/show/rules";

const ProviderSearchAffiliationItem = ({item}: {item: Affiliation}) => {

    const [showModal, setShowModal] = useState(false)

    return <div className={"col mb-3"} style={{height: 300}}>
            <div className="card card-sm border border-1 position-relative h-100">
                <div className="card-body">
                    <div className="row justify-content-center">
                        <div className="col-auto mb-1">
                            <div className="avatar avatar-xxl text-center">
                                <img src={`${getLogoUrl(item.organization)}`} alt="" className="avatar-img rounded-circle"/>
                            </div>
                        </div>
                        <div className="col-12 mb-3 text-center">
                            <h6 className={'mb-3'}>{item.organization.name}</h6>
                            <Link to={item.url} target={'_blank'} className={'mb-3'}>{item.url}</Link>
                            <p className="fs-sm fw-bold text-primary mb-3">
                                <i className={"bi bi-trophy"}></i> {item.ranking?.score.toLocaleString() || 0}
                            </p>
                        </div>
                    </div>
                    <div className="col-12 flex-grow-0">
                        <button className="btn btn-sm btn-light btn-xxs w-100" onClick={() => setShowModal(true)}>
                            Voir plus
                        </button>
                    </div>
                </div>
            </div>
            <ModalComponent size={"modal-xl"} title={`${item.name}`} show={showModal} onHide={() => setShowModal(false)}>
                <ProviderAffiliationShowInformation affiliation={item} />
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>
                            Règles de commissions
                        </h6>
                    </div>
                    <div className="card-body">
                        <ProviderAffiliationRules affiliation={item} />
                    </div>
                </div>
            </ModalComponent>
        </div>
}

export default ProviderSearchAffiliationItem;