import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../adapters/auth";
import authSlice from "../../../reducers/auth";
import Loader from "../../../utils/loader";
import FormError from "../../../utils/formError";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../../hooks";
import {useQuery} from "../../../utils/useQuery";
import {useGoogleLogin} from "@react-oauth/google";
import ErrorCard from "../../error";

const Login = () => {

    const dispatch = useAppDispatch();
    const [error, setError] = useState<any>(null)
    const navigate = useNavigate();
    const {search} = useLocation();
    const query = useQuery();
    const [loading, setLoading] = useState(false);
    const [googleLoading, setGoogleLoading] = useState(false);
    const type = query.get("type") || "corporate"

    // Define Google Analytics conversion function
    const sendConversion = () => {
        if (window && typeof window.gtag === "function") {
            window.gtag("event", "conversion", {
                send_to: "AW-16740144284/fyWbCPjn8uIZEJypqa4-"
            });
        } else {
            console.error("Google Analytics not initialized.");
        }
    };

    const googleLogin = useGoogleLogin({
        onSuccess: tokenResponse => {
            setGoogleLoading(true)
            api.googleLogin({accessToken: tokenResponse.access_token, type: type})
                .then(resp => {
                    if(resp.data.isNewUser){
                        sendConversion()
                    }
                    dispatch(authSlice.actions.loginSuccess(resp.data))
                    navigate(query.has('redirect') ? query.get("redirect")! : "/")
                })
                .catch(error => setError(error))
                .finally(() => setGoogleLoading(false))
        },
        onError: errorResponse => setError(errorResponse)
    });

    const formik = useFormik({
        initialValues: {
            email: query.get("email") || "",
            password: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email().required(),
            password: Yup.string().required(),
        }),
        onSubmit: (values, formikHelpers) => {
            setLoading(true)
            api.login(values)
                .then(resp => {
                    dispatch(authSlice.actions.loginSuccess(resp.data))
                    navigate(query.has('redirect') ? query.get("redirect")! : "/")
                })
                .catch(error => setError(error))
                .finally(() => setLoading(false))
        }
    })

    return <div className={'container mx-auto h-100'}>
        <div className="row h-100 align-items-center justify-content-center">
            <div className="col-md-6">
                <div className="row justify-content-center">
                    <div className="col-auto d-flex align-items-center mb-5">
                        <img src={process.env.PUBLIC_URL + '/img/logo.png'} className={'logo me-2'} alt=""/>
                    </div>
                </div>
                {type === "customer" && <div className={'col-12 mb-3'}>
                    <h6 className={"text-center"}>
                        Connectez vous pour poursuivre.
                    </h6>
                </div>}
                <div className="card border border-1 rounded mb-3">
                    <div className="card-body">
                        {query.has('alert_success') && <div className={"alert alert-success"}>
                            {query.get("alert_success")}
                        </div>}
                        {query.has('alert_danger') && <div className={"alert alert-danger"}>
                            {query.get("alert_danger")}
                        </div>}
                        <form onSubmit={formik.handleSubmit}>
                            <div className="col-12 mb-3">
                                <div className="form-floating">
                                    <input disabled={loading || googleLoading} defaultValue={query.get("email") || undefined} name={"email"} id={"email"} onBlur={formik.handleBlur} onChange={formik.handleChange} type="email" className="form-control" />
                                    <label htmlFor="email">
                                        Email
                                    </label>
                                </div>
                                <FormError form={formik} field={'email'} />
                            </div>
                            <div className="col-12 mb-3">
                                <div className="form-floating">
                                    <input disabled={loading || googleLoading} name={"password"} id={"password"} onBlur={formik.handleBlur} onChange={formik.handleChange} type="password" className="form-control" />
                                    <label htmlFor="password">
                                        Mot de passe
                                    </label>
                                </div>
                                <FormError form={formik} field={'password'} />
                            </div>
                            <button className="btn btn-dark w-100 mb-3" disabled={loading || googleLoading}>
                                {loading ? <Loader size={"sm"} /> : <>Connexion <i className={'bi bi-arrow-right'}></i></>}
                            </button>
                            {error && <ErrorCard error={error} />}
                            <div className="col-12 text-end">
                                <Link to={`/forgot-password`} className={'text-decoration-underline'}>
                                    Mot de passe oublié
                                </Link>
                            </div>
                        </form>
                        <div className="row align-items-center">
                            <div className="col"><hr/></div>
                            <div className="col-auto">
                                <span className="fs-5">Ou</span>
                            </div>
                            <div className="col"><hr/></div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <button onClick={() => googleLogin()} className="btn btn-light w-100">
                                    {googleLoading ? <Loader size={"sm"} /> : <><i className={'bi bi-google'}></i> Continuer avec Google</>}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card border border-1 rounded">
                    <div className="card-body">
                        <p className={'mb-0 text-center'}>
                            Vous n'avez pas encore de compte ? <Link to={`/register?${search}`} className={"text-decoration-underline"}>Inscrivez vous</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Login;