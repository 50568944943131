import encodeQueryData from "../../../../../utils/encodeQueryData";
import {post, get} from "../../../../xhr";

interface Interface {
    countryCode: string,
    tel: string,
    countryId?: number
    city?: string
    zip?: string
    addressLine1?: string
    addressLine2?: string,
}

export function list(requestData: { query: string, offset: number}){
    return get(`corporate/supplier/returns-address${encodeQueryData(requestData)}`)
}

export function show(address: number){
    return get(`corporate/supplier/returns-address/${address}`)
}

export function add(requestData: Interface){
    return post(`corporate/supplier/returns-address`, requestData)
}

export function update(address: number, requestData: Interface){
    return post(`corporate/supplier/returns-address/${address}`, requestData);
}

