import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Register from "../components/auth/register";
import Error404Page from "../pages/error/404";
import OrganizationShow from "../components/corporate/common/organization/show";
import PartnershipRequest from "../components/corporate/common/partnership/request";
import SupplierProductShow from "../components/corporate/supplier/product/show";
import SupplierProductAdd from "../components/corporate/supplier/product/add";
import SupplierAffiliationAdd from "../components/corporate/supplier/affiliation/add";
import SupplierAffiliationShow from "../components/corporate/supplier/affiliation/show";
import CommonPartnershipList from "../components/corporate/common/partnership/list";
import ProviderAffiliationList from "../components/corporate/provider/affiliation/list";
import ProviderAffiliationShow from "../components/corporate/provider/affiliation/show";
import Chat from "../components/corporate/common/chat";
import ProviderOrderList from "../components/corporate/provider/order/list";
import ProviderOrderShow from "../components/corporate/provider/order/show";
import OnBoarding from "../components/corporate/common/onBoarding";
import VisitorLayout from "./visitor";
import AppLayout from "./app";
import AppCorporateLayout from "./app/corporate";
import OnBoardingLayout from "./app/corporate/onboarding";
import AppCustomerLayout from "./app/customer";
import Login from "../components/auth/login";
import SupplierOrderList from "../components/corporate/supplier/order/list";
import SupplierOrderShow from "../components/corporate/supplier/order/show";
import Balance from "../components/corporate/common/stripe/balance";
import SupplierShippingList from "../components/corporate/supplier/shipping/list";
import SupplierShippingShow from "../components/corporate/supplier/shipping/show";
import SupplierShippingAdd from "../components/corporate/supplier/shipping/add";
import SupplierShippingAreaShow from "../components/corporate/supplier/shipping/show/area/show";
import SupplierShippingAddressList from "../components/corporate/supplier/shipping/address/list";
import SupplierShippingAddressAdd from "../components/corporate/supplier/shipping/address/add";
import SupplierShippingAddressEdit from "../components/corporate/supplier/shipping/address/edit";
import SupplierShippingAddressShow from "../components/corporate/supplier/shipping/address/show";
import ProviderLinkAdd from "../components/corporate/provider/link/add";
import ProviderLinkList from "../components/corporate/provider/link/list";
import ProviderLinkShow from "../components/corporate/provider/link/show";
import ProviderShopShow from "../components/corporate/provider/shop/show";
import ProviderShopAdd from "../components/corporate/provider/shop/add";
import ShopLayout from "./shop";
import LinkLayout from "./link";
import CheckoutLayout from "./checkout";
import Checkout from "../components/checkout";
import Setting from "../components/corporate/common/setting";
import AppProviderBl from "./app/corporate/provider/bl";
import AppSupplierBl from "./app/corporate/supplier/bl";
import SupplierSubscriptionList from "../components/corporate/supplier/subscription/list";
import SupplierSubscriptionShow from "../components/corporate/supplier/subscription/show";
import ActivateLayout from "./activate";
import Activate from "../components/auth/activate";
import Customer from "../components/customer";
import CustomerOrderList from "../components/customer/order/list";
import CustomerOrderShow from "../components/customer/order/show";
import CustomerAddressList from "../components/customer/address/list";
import CommonPaymentMethodList from "../components/common/paymentMethod/list";
import SupplierParcelListPage from "../pages/corporate/supplier/parcel/list";
import SupplierParcelShow from "../components/corporate/supplier/parcel/show";
import SupplierShippingRateShow from "../components/corporate/supplier/shipping/show/area/rate/show";
import CheckoutProceed from "../components/checkout/proceed";
import CheckoutConfirm from "../components/checkout/confirm";
import CommonTransferList from "../components/corporate/common/transfer/list";
import LinkShow from "../components/link";
import ShopShow from "../components/shop";
import ProviderProjectList from "../components/corporate/provider/project/list";
import ProviderProjectAdd from "../components/corporate/provider/project/add";
import ProviderProjectShow from "../components/corporate/provider/project/show";
import SupplierProjectList from "../components/corporate/supplier/project/list";
import SupplierProjectShow from "../components/corporate/supplier/project/show";
import SupplierQuoteShow from "../components/corporate/supplier/quote/show";
import SupplierQuoteListPage from "../pages/quote/list";
import QuoteLayout from "./quote";
import QuoteShow from "../components/quote/show";
import CustomerQuoteShow from "../components/customer/quote/show";
import CustomerQuoteList from "../components/customer/quote/list";
import QuoteConfirm from "../components/quote/confirm";
import SupplierDeveloper from "../components/corporate/supplier/developer";
import SupplierWebhookShow from "../components/corporate/supplier/webhook/show";
import SupplierWebhookAdd from "../components/corporate/supplier/webhook/add";
import CustomerReturnAdd from "../components/customer/returns/add";
import CustomerReturnList from "../components/customer/returns/list";
import SupplierReturnsAddressList from "../components/corporate/supplier/returnsAddress/list";
import SupplierReturnsPolicyList from "../components/corporate/supplier/returnsPolicy/list";
import SupplierReturnsPolicyAdd from "../components/corporate/supplier/returnsPolicy/add";
import SupplierReturnsList from "../components/corporate/supplier/returns/list";
import SupplierReturnsShow from "../components/corporate/supplier/returns/show";
import SupplierTermList from "../components/corporate/supplier/term/list";
import SupplierTermAdd from "../components/corporate/supplier/term/add";
import CorporateHome from "../components/corporate/common/home";
import StripeOnBoarding from "../components/corporate/common/stripe/onboarding";
import StripeStatus from "../components/corporate/common/stripe/onboarding/status";
import AccountLayout from "./account";
import AccountList from "../components/account/list";
import AccountAdd from "../components/account/add";
import ProviderSubscriptionList from "../components/corporate/provider/subscription/list";
import ProviderSubscriptionShow from "../components/corporate/provider/subscription/show";
import CustomerSubscriptionList from "../components/customer/subscription/list";
import CustomerSubscriptionShow from "../components/customer/subscription/show";
import CommonCustomerList from "../components/corporate/common/customer/list";
import CommonCustomerShow from "../components/corporate/common/customer/show";
import SupplierCatalog from "../components/corporate/supplier/catalog";
import SupplierBundleShow from "../components/corporate/supplier/bundle/show";
import ProviderBundleShow from "../components/corporate/provider/bundle/show";
import BundleLayout from "./bundle";
import BundleShow from "../components/bundle";
import SupplierFee from "../components/corporate/supplier/fee";
import ProviderFee from "../components/corporate/provider/fee";
import SupplierFeeRuleList from "../components/corporate/supplier/feeRule/list";
import ProviderBundleList from "../components/corporate/provider/bundle/list";
import ProviderShopList from "../components/corporate/provider/shop/list";
import ProviderContactList from "../components/corporate/provider/contact/list";
import ProviderTouchList from "../components/corporate/provider/touch/list";
import SupplierTouchList from "../components/corporate/supplier/touch/list";
import Sponsorship from "../components/corporate/common/sponsorship";
import CommonInvoiceList from "../components/corporate/common/invoice/list";
import PackShow from "../components/pack";
import SupplierPackShow from "../components/corporate/supplier/pack/show";
import ProviderCatalog from "../components/corporate/provider/catalog";
import ProviderPackShow from "../components/corporate/provider/pack/show";
import SupplierSearch from "../components/corporate/supplier/search";
import ProviderSearch from "../components/corporate/provider/search";
import RegisterProvider from "../components/auth/register/provider";
import RegisterSupplier from "../components/auth/register/supplier";
import ProviderTouchAdd from "../components/corporate/provider/touch/add";
import ProviderTouchShow from "../components/corporate/provider/touch/show";
import SupplierTouchShow from "../components/corporate/supplier/touch/show";
import Dashboard from "../components/corporate/common/dashboard";
import SupplierAffiliation from "../components/corporate/supplier/affiliation";
import ResetPassword from "../components/auth/resetPassword";
import ForgotPassword from "../components/auth/forgotPassword";
import CommonPartnershipShow from "../components/corporate/common/partnership/show";
import ProviderServiceList from "../components/corporate/provider/service/list";
import SupplierAssignmentListPage from "../pages/corporate/supplier/assignment/list";
import SupplierAssignmentAddPage from "../pages/corporate/supplier/assignment/add";
import ProviderAssignmentShow from "../components/corporate/provider/assignment/show";
import ProviderApplicationList from "../components/corporate/provider/application/list";
import SupplierApplicationList from "../components/corporate/supplier/application/list";
import SupplierAssignmentShow from "../components/corporate/supplier/assignment/show";
import ProviderProposalAddPage from "../pages/corporate/provider/proposal/add";
import SupplierProposalShow from "../components/corporate/supplier/proposal/show";

const Navigation = () => {

    const onBoardingTabs: ("type"|"account"|"bio"|"organization"|"information"|"address"|"verification")[] = ["type", "account", "organization", "information", "bio", "address", "verification"]
    const checkoutTabs: ("address"|"delivery"|"payment")[] = ["address", "delivery", "payment"]
    const supplierProductShowTabs: ("gallery"|"declination"|"inventory"|"description"|"support")[] = ["gallery", "declination", "inventory", 'description', 'support'];
    const supplierDeveloperTabs: ("webhook"|"apikey")[] = ["webhook", "apikey"];

    return <BrowserRouter>
        <Routes>
            <Route  path={''} element={<AppLayout />} />

            <Route path={'shop/:uuid'} element={<ShopLayout />}>
                <Route path={''} element={<ShopShow tab={"home"} />} />
                <Route path={'product/:id'} element={<ShopShow tab={"product"} />} />
                <Route path={'cart'} element={<ShopShow tab={"cart"} />} />
            </Route>

            <Route path={"link/:uuid"} element={<LinkLayout />}>
                <Route path={''} element={<LinkShow />} />
            </Route>

            <Route path={"pack/:uuid"} element={<LinkLayout />}>
                <Route path={''} element={<PackShow />} />
            </Route>

            <Route path={"bundle/:token"} element={<BundleLayout />}>
                <Route path={''} element={<BundleShow />} />
            </Route>

            <Route path={"checkout/:uuid"} element={<CheckoutLayout />}>
                <Route path={``} element={<CheckoutProceed />} />
                {checkoutTabs.map(tab => <Route path={`${tab}`} element={<Checkout tab={tab} />} />)}
                <Route path={`confirm`} element={<CheckoutConfirm />} />
            </Route>

            <Route path={"quote/:uuid"} element={<QuoteLayout />}>
                <Route path={``} element={<QuoteShow />} />
                <Route path={`confirm`} element={<QuoteConfirm />} />
            </Route>

            <Route element={<VisitorLayout />} path={"/"}>
                <Route path={'login'} element={<Login />}/>
                <Route path={'register'} element={<Register />}/>
                <Route path={'forgot-password'} element={<ForgotPassword />}/>
                <Route path={'reset-password/:token'} element={<ResetPassword />}/>
                <Route path={'register/provider'} element={<RegisterProvider />}/>
                <Route path={'register/supplier'} element={<RegisterSupplier />}/>
            </Route>

            <Route element={<ActivateLayout />} path={"/"}>
                <Route path={'activate'} element={<Activate />}/>
            </Route>

            <Route path={'/account'} element={<AccountLayout />}>
                <Route path={''} element={<AccountList />} />
                <Route path={'add'} element={<AccountAdd />} />
            </Route>

            {/* Customer */}
            <Route path={"/customer"} element={<AppCustomerLayout />}>
                <Route path={''} element={<Customer />} />
                <Route path={'quote'} element={<CustomerQuoteList />} />
                <Route path={'quote/:id'} element={<CustomerQuoteShow />} />
                <Route path={'order'} element={<CustomerOrderList />} />
                <Route path={'order/:id'} element={<CustomerOrderShow />} />
                <Route path={'subscription'} element={<CustomerSubscriptionList />} />
                <Route path={'subscription/:id'} element={<CustomerSubscriptionShow />} />
                <Route path={'address'} element={<CustomerAddressList />} />
                <Route path={'payment-method'} element={<CommonPaymentMethodList />} />
                <Route path={'returns'} element={<CustomerReturnList />} />
                <Route path={'returns/add'} element={<CustomerReturnAdd />} />
                <Route path={'returns/:id'} element={<CustomerReturnAdd />} />
            </Route>

            {/* Onboarding */}
            <Route element={<OnBoardingLayout />} path={"/onboarding"}>
                <Route path={''} element={<OnBoarding />}/>
                <Route path={"type"} element={<OnBoarding step={"type"} />}/>
                <Route path={"account"} element={<OnBoarding step={"account"} />}/>
                <Route path={"organization"} element={<OnBoarding step={"organization"} />}/>
                <Route path={"information"} element={<OnBoarding step={"information"} />}/>
                <Route path={"bio"} element={<OnBoarding step={"bio"} />}/>
                <Route path={"address"} element={<OnBoarding step={"address"} />}/>
                <Route path={"verification"} element={<OnBoarding step={"verification"} />}/>
            </Route>

            {/* Corporate */}
            <Route path={"/corporate"} element={<AppCorporateLayout />}>
                {/* COMMON */}
                <Route path={""} element={<Dashboard />}/>
                <Route path={"community"} element={<CorporateHome />}/>
                <Route path={"status"} element={<StripeStatus />}/>
                <Route path={"stripe"} element={<StripeOnBoarding />}/>
                <Route path={"sponsorship"} element={<Sponsorship />}/>
                <Route path={"invoice"} element={<CommonInvoiceList />}/>
                <Route path={`setting/account`} element={<Setting tab={'account'} />}/>
                <Route path={`setting/organization`} element={<Setting tab={'organization'} />}/>
                <Route path={`setting/information`} element={<Setting tab={'information'} />}/>
                <Route path={`setting/address`} element={<Setting tab={'address'} />}/>
                <Route path={`setting/bio`} element={<Setting tab={'bio'} />}/>
                <Route path={`setting/payment-method`} element={<Setting tab={'payment-method'} />}/>
                <Route path={"balance"} element={<Balance />}/>
                <Route path={"transfer"} element={<CommonTransferList />}/>
                <Route path={"chat/:id?"} element={<Chat />}/>
                <Route path={'organization/:id'} element={<OrganizationShow />}/>
                <Route path={'partnership/request'} element={<PartnershipRequest displayBreadcrumb={true} />}/>
                <Route path={'partnership'} element={<CommonPartnershipList />}/>
                <Route path={'partnership/:id'} element={<CommonPartnershipShow />}/>
                <Route path={'payment-method'} element={<CommonPaymentMethodList />} />

                {/* Customer */}
                <Route path={'customer'} element={<CommonCustomerList />}/>
                <Route path={'customer/:id'} element={<CommonCustomerShow />}/>

                {/* Provider */}
                <Route path={"provider"} element={<AppProviderBl />}>

                    {/* Service */}
                    <Route path={'service'} element={<ProviderServiceList />}/>

                    {/* Proposal */}
                    <Route path={'proposal/add'} element={<ProviderProposalAddPage />}/>

                    {/* Contact */}
                    <Route path={'contact'} element={<ProviderContactList />}/>

                    {/* Assignment */}
                    <Route path={'assignment/:id'} element={<ProviderAssignmentShow />}/>

                    {/* Applications */}
                    <Route path={'application'} element={<ProviderApplicationList />}/>

                    {/* Touch */}
                    <Route path={'touch'} element={<ProviderTouchList />}/>
                    <Route path={'touch/:id'} element={<ProviderTouchShow />}/>
                    <Route path={'touch/add'} element={<ProviderTouchAdd />}/>

                    {/* Pack */}
                    <Route path={'pack/:id'} element={<ProviderPackShow />}/>

                    {/* Affiliation */}
                    <Route path={'affiliation'} element={<ProviderAffiliationList />}/>
                    <Route path={'affiliation/:id'} element={<ProviderAffiliationShow />}/>
                    <Route path={'affiliation/:id/support'} element={<ProviderAffiliationShow tab={'support'} />}/>
                    <Route path={'affiliation/:id/tracking'} element={<ProviderAffiliationShow tab={"tracking"} />}/>
                    <Route path={'affiliation/:id/rules'} element={<ProviderAffiliationShow tab={"rules"} />}/>
                    <Route path={'affiliation/:id/statistics'} element={<ProviderAffiliationShow tab={"statistics"} />}/>


                    {/* Search */}
                    <Route path={'search'} element={<ProviderSearch />}/>
                    <Route path={'search/supplier'} element={<ProviderSearch type={"supplier"} />}/>
                    <Route path={'search/product'} element={<ProviderSearch type={'product'} />}/>
                    <Route path={'search/affiliation'} element={<ProviderSearch type={"affiliation"} />}/>
                    <Route path={'search/assignment'} element={<ProviderSearch type={"assignment"} />}/>

                    <Route path={'affiliation'} element={<ProviderAffiliationList />}/>
                    <Route path={'affiliation/:id'} element={<ProviderAffiliationShow />}/>

                    {/* Catalogue */}
                    <Route path={'catalog'} element={<ProviderCatalog />}/>
                    <Route path={'catalog/product'} element={<ProviderCatalog tab={"product"} />}/>
                    <Route path={'catalog/pack'} element={<ProviderCatalog tab={"pack"} />}/>
                    <Route path={'catalog/bundle'} element={<ProviderCatalog tab={"bundle"} />}/>

                    {/* Bundle */}
                    <Route path={'bundle'} element={<ProviderBundleList />}/>
                    <Route path={'bundle/:id'} element={<ProviderBundleShow />}/>

                    {/* Order */}
                    <Route path={'order'} element={<ProviderOrderList />}/>
                    <Route path={'order/:id'} element={<ProviderOrderShow />}/>

                    {/* Link */}
                    <Route path={'link'} element={<ProviderLinkList />}/>
                    <Route path={'link/add'} element={<ProviderLinkAdd />}/>
                    <Route path={'link/:id'} element={<ProviderLinkShow />}/>

                    {/* Shop */}
                    <Route path={'shop'} element={<ProviderShopList />}/>
                    <Route path={'shop/:id'} element={<ProviderShopShow />}/>
                    <Route path={'shop/add'} element={<ProviderShopAdd />}/>

                    {/* Project */}
                    <Route path={'project'} element={<ProviderProjectList />}/>
                    <Route path={'project/add'} element={<ProviderProjectAdd />}/>
                    <Route path={'project/:id'} element={<ProviderProjectShow />}/>

                    {/* Abonnements */}
                    <Route path={'subscription'} element={<ProviderSubscriptionList />}/>
                    <Route path={'subscription/:id'} element={<ProviderSubscriptionShow />}/>


                    {/* Commission */}
                    <Route path={'fee'} element={<ProviderFee />}/>
                </Route>

                {/* supplier */}
                <Route path={"supplier"} element={<AppSupplierBl/>}>

                    {/* Proposal */}
                    <Route path={'proposal/:id'} element={<SupplierProposalShow />}/>

                    {/* Assignment */}
                    <Route path={'assignment'} element={<SupplierAssignmentListPage />}/>
                    <Route path={'assignment/add'} element={<SupplierAssignmentAddPage />}/>
                    <Route path={'assignment/:id'} element={<SupplierAssignmentShow />}/>

                    {/* Application */}
                    <Route path={'application'} element={<SupplierApplicationList />}/>

                    {/* Pack */}
                    <Route path={'pack/:id'} element={<SupplierPackShow />}/>

                    {/* Project*/}
                    <Route path={'project'} element={<SupplierProjectList />}/>

                    {/* Touch */}
                    <Route path={'touch'} element={<SupplierTouchList />}/>
                    <Route path={'touch/:id'} element={<SupplierTouchShow />}/>


                    {/* Discover */}
                    <Route path={'search'} element={<SupplierSearch />}/>

                    {/* Projets */}
                    <Route path={'project'} element={<SupplierProjectList />}/>
                    <Route path={'project/:id'} element={<SupplierProjectShow />}/>

                    {/* Devis */}
                    <Route path={'quote'} element={<SupplierQuoteListPage />}/>
                    <Route path={'quote/:id'} element={<SupplierQuoteShow />}/>

                    {/* Commandes */}
                    <Route path={'order'} element={<SupplierOrderList />}/>
                    <Route path={'order/:id'} element={<SupplierOrderShow />}/>

                    {/* Colis */}
                    <Route path={'parcel'} element={<SupplierParcelListPage />}/>
                    <Route path={'parcel/:id'} element={<SupplierParcelShow />}/>

                    {/* Abonnements */}
                    <Route path={'subscription'} element={<SupplierSubscriptionList />}/>
                    <Route path={'subscription/:id'} element={<SupplierSubscriptionShow />}/>

                    {/* Expédition */}
                    <Route path={'shipping'} element={<SupplierShippingList />}/>
                    <Route path={'shipping/add'} element={<SupplierShippingAdd />}/>
                    <Route path={'shipping/:id/area'} element={<SupplierShippingShow tab={"area"} />}/>
                    <Route path={'shipping/:id/address'} element={<SupplierShippingShow tab={"address"} />}/>

                    <Route path={'shipping/area/:id'} element={<SupplierShippingAreaShow />} />
                    <Route path={'shipping/rate/:id'} element={<SupplierShippingRateShow />} />

                    <Route path={'shipping/address'} element={<SupplierShippingAddressList />} />
                    <Route path={'shipping/address/add'} element={<SupplierShippingAddressAdd />} />
                    <Route path={'shipping/address/:id'} element={<SupplierShippingAddressShow />} />
                    <Route path={'shipping/address/:id/update'} element={<SupplierShippingAddressEdit />} />

                    {/* Catalog */}
                    <Route path={'catalog'} element={<SupplierCatalog />}/>
                    <Route path={'catalog/product'} element={<SupplierCatalog tab={"product"} />}/>
                    <Route path={'catalog/fee-rule'} element={<SupplierCatalog tab={"feeRule"} />}/>
                    <Route path={'catalog/pack'} element={<SupplierCatalog tab={"pack"} />}/>
                    <Route path={'catalog/tax-rate'} element={<SupplierCatalog tab={"tax-rate"} />}/>
                    <Route path={'catalog/bundle'} element={<SupplierCatalog tab={"bundle"} />}/>
                    <Route path={'catalog/unit'} element={<SupplierCatalog tab={'unit'} />}/>
                    <Route path={'catalog/feature'} element={<SupplierCatalog tab={'feature'} />}/>

                    {/* Produits */}
                    <Route path={'product/:id'} element={<SupplierProductShow />}/>
                    {supplierProductShowTabs.map(tab => <Route path={`product/:id/${tab}`} element={<SupplierProductShow tab={tab} />} />)}
                    <Route path={'product/add'} element={<SupplierProductAdd />}/>

                    {/* Bundle */}
                    <Route path={'bundle/:id'} element={<SupplierBundleShow />}/>

                    {/* Affiliation */}
                    <Route path={'affiliation'} element={<SupplierAffiliation />}/>
                    <Route path={'affiliation/click'} element={<SupplierAffiliation tab={"click"} />}/>
                    <Route path={'affiliation/conversion'} element={<SupplierAffiliation tab={"conversion"} />}/>

                    <Route path={'affiliation/:id'} element={<SupplierAffiliationShow />}/>
                    <Route path={'affiliation/:id/support'} element={<SupplierAffiliationShow tab={'support'} />}/>
                    <Route path={'affiliation/:id/tracking'} element={<SupplierAffiliationShow tab={"tracking"} />}/>
                    <Route path={'affiliation/:id/rules'} element={<SupplierAffiliationShow tab={"rules"} />}/>
                    <Route path={'affiliation/:id/statistics'} element={<SupplierAffiliationShow tab={"statistics"} />}/>
                    <Route path={'affiliation/:id/settings'} element={<SupplierAffiliationShow tab={"settings"} />}/>

                    <Route path={'affiliation/add'} element={<SupplierAffiliationAdd />}/>

                    {/* Developeur */}
                    {supplierDeveloperTabs.map(tab => <Route path={`${tab}`} element={<SupplierDeveloper tab={tab} />} />)}

                    <Route path={'webhook/add'} element={<SupplierWebhookAdd />}/>
                    <Route path={'webhook/:id'} element={<SupplierWebhookShow />}/>

                    {/* Retours */}
                    <Route path={'returns'} element={<SupplierReturnsList />}/>
                    <Route path={'returns/:id'} element={<SupplierReturnsShow />}/>
                    {/* Addresse Retours */}
                    <Route path={'returns-address'} element={<SupplierReturnsAddressList />}/>
                    {/* Policy Retours */}
                    <Route path={'returns-policy'} element={<SupplierReturnsPolicyList />}/>
                    <Route path={'returns-policy/add'} element={<SupplierReturnsPolicyAdd />}/>

                    {/* Term and Conditions */}
                    <Route path={'term'} element={<SupplierTermList />}/>
                    <Route path={'term/add'} element={<SupplierTermAdd />}/>

                    {/* Commission */}
                    <Route path={'fee'} element={<SupplierFee />}/>
                    <Route path={'fee-rule'} element={<SupplierFeeRuleList />}/>

                </Route>
            </Route>
            <Route path={'*'} element={<Error404Page />} />
        </Routes>
    </BrowserRouter>
}

export default Navigation;