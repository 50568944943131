import React, {useState} from "react";
import {Link} from "react-router-dom";

const CorporateProviderNav = () => {

    const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>({});

    const toggleMenu = (menu: string) => {
        setOpenMenus((prev) => ({ ...prev, [menu]: !prev[menu] }));
    };

    return <div id={"collapseNav"} className="sticky-top pt-3 collapse d-md-block">
        <nav className={'nav-top'}>
            <ul className={'list-unstyled'}>
                <li>
                    <Link to={'/'} className={'nav-link'}>
                        <i className="bi bi-house"></i> Accueil
                    </Link>
                </li>
                <li>
                    <Link to={'/corporate/community'} className={'nav-link'}>
                        <i className="bi bi-rss"></i> Communauté
                    </Link>
                </li>
                <li>
                    <Link to={'/corporate/provider/search'} className="nav-link">
                        <i className={'bi bi-search'}></i> Rechercher
                    </Link>
                </li>
                <li>
                    <Link to={'/corporate/partnership'} className="nav-link">
                        <i className={'bi bi-people'}></i> Partenaires
                    </Link>
                </li>
                <li>
                    <Link to={'/corporate/provider/application'} className="nav-link">
                        <i className="bi bi-check2-all"></i> Mes candidatures
                    </Link>
                </li>
                <li>
                    <Link to={'/corporate/provider/fee'} className="nav-link">
                        <i className={'bi bi-cash-coin'}></i> Commissions
                    </Link>
                </li>
                <li>
                    <span className="cursor-pointer nav-link dropdown-toggle" onClick={() => toggleMenu('transaction')}>
                        <i className="bi bi-person-raised-hand"></i> Apport d'affaires
                    </span>
                    <div className={`collapse ${openMenus['transaction'] ? 'show' : ''}`}>
                        <ul className={"list-unstyled"}>
                            <li>
                                <Link to={'/corporate/provider/contact'} className="nav-link">
                                    Contacts
                                </Link>
                            </li>
                            <li>
                                <Link to={'/corporate/provider/touch'} className="nav-link">
                                    Mises en relation
                                </Link>
                            </li>
                            <li>
                                <Link to={'/corporate/provider/project'} className="nav-link">
                                    Appels d'offres
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <Link to={'/corporate/provider/affiliation'} className="nav-link">
                        <i className="bi bi-link"></i> Affiliations
                    </Link>
                </li>
                <li>
                    <Link to={'/corporate/provider/catalog'} className="nav-link">
                        <i className="bi bi-book"></i> Catalogue
                    </Link>
                </li>
                <li>
                    <span className="cursor-pointer nav-link dropdown-toggle" onClick={() => toggleMenu('commerce')}>
                        <i className="bi bi-cart"></i> Vente direct
                    </span>
                    <div className={`collapse ${openMenus['commerce'] ? 'show' : ''}`}>
                        <ul className={"list-unstyled"}>
                            <li>
                                <Link to={'/corporate/provider/link'} className="nav-link">
                                    Lien de paiement
                                </Link>
                            </li>
                            <li>
                                <Link to={'/corporate/provider/shop'} className="nav-link">
                                    Boutiques
                                </Link>
                            </li>
                            <li>
                                <Link to={'/corporate/provider/catalog/bundle'} className="nav-link">
                                    Grille tarifaire
                                </Link>
                            </li>
                            <li>
                                <Link to={'/corporate/provider/catalog/pack'} className="nav-link">
                                    Packages
                                </Link>
                            </li>
                            <li>
                                <span className="cursor-pointer nav-link dropdown-toggle" onClick={() => toggleMenu('commerce_sales')}>
                                    Ventes
                                </span>
                                <div className={`collapse ${openMenus['commerce_sales'] ? 'show' : ''}`}>
                                    <ul className={"list-unstyled"}>
                                        <li>
                                            <Link to={'/corporate/customer'} className="nav-link">
                                                Clients
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/corporate/provider/order'} className="nav-link">
                                                Commandes
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/corporate/provider/subscription'} className="nav-link">
                                                Abonnements
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <span className="cursor-pointer nav-link dropdown-toggle" onClick={() => toggleMenu('transaction')}>
                        <i className="bi bi-credit-card-2-front"></i> Transactions
                    </span>
                    <div className={`collapse ${openMenus['transaction'] ? 'show' : ''}`}>
                        <ul className={"list-unstyled"}>
                            <li>
                                <Link to={'/corporate/balance'} className="nav-link">
                                    Solde
                                </Link>
                            </li>
                            <li>
                                <Link to={'/corporate/transfer'} className="nav-link">
                                    Transfers
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </nav>
    </div>
}

export default CorporateProviderNav;