import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import * as models from "../../../../../models/link";
import * as api from "../../../../../adapters/corporate/provider/link";
import Loader from "../../../../../utils/loader";
import ProviderLinkShowSend from "./send";
import ProviderLinkItemList from "../item/list";

const ProviderLinkShow = () => {

    let params = useParams();
    const [loading, setLoading] = useState(true)
    const [link, setLink] = useState<models.Link>()

    useEffect(() => {
        if (params.id){
            setLoading(true)
            api.show(Number(params.id))
                .then(r => setLink(r.data))
                .then(() => setLoading(false))
        }
    }, [params.id])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (!link){
        return <div className={'h-100 w-100'}>
            Commande non trouvé
        </div>
    }

    return <div className={'container-fluid'}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/corporate/provider/link">Liens de paiement</Link>
                </li>
                <li className="breadcrumb-item">
                    {link.name}
                </li>
            </ol>
        </div>
        <ProviderLinkShowSend link={link} />
        <div className="col-12 mb-3">
            <ProviderLinkItemList link={link} />
        </div>
    </div>
}

export default ProviderLinkShow;