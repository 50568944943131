import React from "react";
import * as models from "../../../../../../models/product";
import ProductDeclinationChoiceShow from "../choice/show";
import * as api from "../../../../../../adapters/corporate/supplier/product/declination/choice";
import * as declinationApi from "../../../../../../adapters/corporate/supplier/product/declination";
import SupplierProductDeclinationAdd from "../add";

const SupplierProductDeclinationShow = ({ product, setProduct }: { product: models.Product & models.SellableProduct, setProduct: React.Dispatch<any> }) => {


    function addDeclinationChoice(declination: models.ProductDeclination)
    {
        api.add(product.id, declination.id, {}).then(resp => setProduct((prev: models.Product & models.SellableProduct) => {

            if (prev.declinations){
                const index = prev.declinations.findIndex(d => d.id === declination.id)

                if (index !== -1){
                    if (prev.declinations[index].choices){
                        prev.declinations[index].choices!.push(resp.data)
                    }else{
                        prev.declinations[index].choices = [resp.data]
                    }
                }
            }

            return prev;
        }))
    }

    function removeDeclination(declination: models.ProductDeclination)
    {
        declinationApi.remove(product.id, declination.id).then(resp => setProduct((prev: models.Product & models.SellableProduct) => ({...prev, declinations: prev.declinations?.filter(d => d.id !== declination.id)})))
    }

    function removeDeclinationChoice(declination: models.ProductDeclination, choice: models.ProductDeclinationChoice)
    {
        api.remove(product.id, declination.id, choice.id).then(resp => setProduct((prev: models.Product & models.SellableProduct) => {

            if (prev.declinations){
                const declinationIndex = prev.declinations.findIndex(d => d.id === declination.id)

                if (declinationIndex !== -1){
                    if (prev.declinations[declinationIndex].choices){
                        const choiceIndex = prev.declinations[declinationIndex].choices!.findIndex(c => c.id === choice.id);

                        if (choiceIndex !== -1){
                            prev.declinations[declinationIndex].choices!.splice(choiceIndex, 1)
                        }
                    }
                }
            }

            return prev;
        }))
    }

    return <>
        <div className="col-12 mb-3" id={'declination'}>
            <div className="bg-body-tertiary p-3">
                <div className="col-12 mb-3 d-flex align-items-center">
                    <button type={"button"} className={'btn text-decoration-underline'}
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasSupplierProductDeclinationAdd"
                            aria-controls="offcanvasSupplierProductDeclinationAdd"><i
                        className={'bi bi-plus-circle'}></i> Ajouter une déclinaison
                    </button>
                </div>
                <div className="col-12">
                    <div className="accordion accordion-flush" id="accordionDeclination">
                        {product.declinations?.map(d => <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed d-flex align-items-center" type="button" data-bs-toggle="collapse"
                                        data-bs-target={`#flush-${d.id}`} aria-expanded="false"
                                        aria-controls={`flush-${d.id}`}>
                                    <div>{d.name}</div>
                                    <div className={'form-text ms-2'}>{d.choices?.map(d => d.value).join(", ").slice(0, 50)}</div>
                                </button>
                            </h2>
                            <div id={`flush-${d.id}`} className="accordion-collapse collapse"
                                 data-bs-parent="#accordionDeclination">
                                <div className="accordion-body">
                                    <div className="col-12 mb-3">
                                        <div className="row">
                                            <div className="col text-start">
                                                <button className={'btn btn-xs btn-light'} onClick={() => addDeclinationChoice(d)}>
                                                    <i className={'bi bi-plus'}> </i> Ajouter un choix
                                                </button>
                                            </div>
                                            <div className="col text-end">
                                                <button className={'btn btn-xs btn-light ms-2'} onClick={() => removeDeclination(d)}>
                                                    <i className={'bi bi-dash-circle text-danger'}> </i> Supprimer
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {!!d.choices?.length ? <ul className={'list-group'}>
                                        {d.choices.map(c => <li className={'list-group-item'}>
                                            <div className="row">
                                                <div className="col-12 mb-2 text-end">
                                                    <button className={'btn btn-xxs btn-danger'} onClick={() => removeDeclinationChoice(d, c)}>
                                                        <i className={'bi bi-dash-circle'}> </i> Supprimer la déclinaison
                                                    </button>
                                                </div>
                                                <div className="col-12">
                                                    <ProductDeclinationChoiceShow product={product} setProduct={setProduct} declination={d} choice={c} />
                                                </div>
                                            </div>
                                        </li>)}
                                    </ul> : <span className={"form-text"}>Aucun choix disponible</span>}
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
        <SupplierProductDeclinationAdd product={product} setProduct={setProduct} key={product.id} />
    </>
}

export default SupplierProductDeclinationShow;