import {Project} from "../../../../../models/project";
import React from "react";

const ProjectLaunch = ({p}: { p: Project }) => {
    switch (p.condition.launch){
        case "ASAP":
            return <>Dès que possible</>
        case "DATE":
            return <>
                le {new Date(p.condition.launchAt).toLocaleDateString()} {p.condition.isFlexible ? <>(+/- {p.condition.flexibleType})</> : ''}
            </>
        case "IDK":
            return <>Ne sait pas</>
    }

    return <></>
}

export default ProjectLaunch