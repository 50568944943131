import React, { useState, useRef } from "react";
import * as api from "../../../../../../../adapters/corporate/provider/link/item";
import {Link, LinkItem} from "../../../../../../../models/link";

const LinkItemQuantityInput = ({
    item,
    link,
    handleQuantityChange
                       }: {
    item: LinkItem,
    link: Link,
    handleQuantityChange: (qt: number) => void
}) => {
    const [inputType, setInputType] = useState<"input" | "select">(
        item.quantity > 9 ? "input" : "select"
    );
    const [updating, setUpdating] = useState(false);
    const [key, setKey] = useState(0);
    const [containerKey, setContainerKey] = useState(0);
    const popoverRef = useRef<HTMLDivElement>(null);
    const [quantity, setQuantity] = useState(item.quantity);
    const [error, setError] = useState('')

    function handleChange(
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) {
        e.preventDefault();

        switch (e.target.value) {
            case "10+":
                setInputType("input");
                break;
            default:
                updateQuantity(Number(e.target.value));
        }
    }

    function handleUpdateClick() {
        updateQuantity(quantity);
    }

    function updateQuantity(quantity: number) {

        setError("")
        if(item.product.minQuantity > quantity){
            setError(`Quantité minimmum par commande de ${item.product.minQuantity}`)
            return;
        }

        setUpdating(true);
        api
            .update(link.id, item.id, {
                quantity: quantity,
            })
            .then((r) => {
                setUpdating(false);
                setQuantity(r.data.quantity)
                handleQuantityChange(r.data.quantity)
            });
    }

    if (inputType === "select") {
        return (
            <div key={containerKey} ref={popoverRef} className="form-floating" id={`container_quantity_${item.id}`}>
                <select
                    key={key}
                    disabled={updating}
                    tabIndex={0}
                    onChange={(e) => handleChange(e)}
                    className="form-select"
                    id={`quantity_${item.id}`}
                    aria-label="Floating label select example"
                >
                    {Array.from(Array(Math.ceil(9)).keys()).map((value) => (
                        <option selected={value + 1 === item.quantity} value={value + 1}>
                            {value + 1}
                        </option>
                    ))}
                    <option value="10+">10+</option>
                </select>
                <label htmlFor="floatingSelect">Quantité</label>
            </div>
        );
    }

    return (
        <>
            <div key={containerKey} ref={popoverRef} className={"input-group"} id={`container_quantity_${item.id}`}>
                <div className="form-floating">
                    <input
                        key={key}
                        tabIndex={0}
                        type="number"
                        id={`quantity_${item.id}`}
                        step={1}
                        value={quantity}
                        onChange={(e) => setQuantity(Number(e.target.value))}
                        className="form-control form-control-xxs"
                        min={1}
                    />
                    <label htmlFor={`quantity_${item.id}`}>Quantité</label>
                </div>
                {item.quantity !== quantity && <button disabled={updating} className={"btn btn-primary"} onClick={() => handleUpdateClick()}>
                    Mettre à jour
                </button>}
            </div>
            {error && <p className={'mb-1 text-danger'}>{error}</p>}
        </>

    );
};

export default LinkItemQuantityInput;
