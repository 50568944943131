import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as api from "../../../../../../adapters/corporate/supplier/product/dimension"
import * as models from "../../../../../../models/product"
import FormError from "../../../../../../utils/formError";
import Loader from "../../../../../../utils/loader";
import * as Yup from "yup";
import {Offcanvas} from "bootstrap";

const SupplierProductDimensionAdd = ({ product, setProduct }: { product: models.Product & models.SellableProduct, setProduct: React.Dispatch<any> }) => {

    const [offcanvas, setOffcanvas] = useState<Offcanvas>();

    useEffect(() => {
        let el = document.getElementById('offcanvasSupplierProductDimensionAdd') as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));
    }, [])

    const formik = useFormik({
        initialValues: {
            height: 0,
            width: 0,
            length: 0,
            depth: 0,
            count: 0,
            weight: 0,
        },
        validationSchema: Yup.object().shape({
            height: Yup.number().required(),
            width: Yup.number().required(),
            length: Yup.number().required(),
            depth: Yup.number().required(),
            count: Yup.number().required(),
            weight: Yup.number().required(),
        }),
        onSubmit: values => {
            api.add(product.id, values)
                .then(resp => setProduct((prev: models.Product) => ({...prev, dimension: resp.data})))
                .then(() => formik.resetForm())
                .then(() => formik.setSubmitting(false))
                .then(() => offcanvas!.hide())
                .catch(e => console.log(e))
        }
    });

    return <div className="offcanvas offcanvas-end"
                tabIndex={-1}
                id="offcanvasSupplierProductDimensionAdd"
                aria-labelledby="offcanvasSupplierProductDimensionAddLabel">
        <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasSupplierProductDimensionAddLabel"><i className={'bi bi-plus-circle'}></i> Ajouter les dimensions</h5>
            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvasSupplierProductDimensionAdd" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12 mb-3">
                    <div className="input-group">
                        <div className="form-floating">
                            <input disabled={formik.isSubmitting} type="number" step={'any'} className="form-control" id={'height'} name={'height'} onChange={formik.handleChange} />
                            <label htmlFor="height">
                                Hauteur
                            </label>
                        </div>
                        <span className="input-group-text">
                            cm
                        </span>
                    </div>
                    <FormError form={formik} field={'height'} />
                </div>
                <div className="col-12 mb-3">
                    <div className="input-group">
                        <div className="form-floating">
                            <input disabled={formik.isSubmitting} type="number" step={'any'} className="form-control" id={'width'} name={'width'} onChange={formik.handleChange} />
                            <label htmlFor="width">
                                Largeur
                            </label>
                        </div>
                        <span className="input-group-text">
                            cm
                        </span>
                    </div>
                    <FormError form={formik} field={'width'} />
                </div>
                <div className="col-12 mb-3">
                    <div className="input-group">
                        <div className="form-floating">
                            <input disabled={formik.isSubmitting} type="number" step={'any'} className="form-control" id={'depth'} name={'depth'} onChange={formik.handleChange} />
                            <label htmlFor="depth">
                                Profondeur
                            </label>
                        </div>
                        <span className="input-group-text">
                            cm
                        </span>
                    </div>
                    <FormError form={formik} field={'depth'} />
                </div>
                <div className="col-12 mb-3">
                    <div className="input-group">
                        <div className="form-floating">
                            <input disabled={formik.isSubmitting} type="number" step={'any'} className="form-control" id={'length'} name={'length'} onChange={formik.handleChange} />
                            <label htmlFor="length">
                                Longueur
                            </label>
                        </div>
                        <span className="input-group-text">
                            cm
                        </span>
                    </div>
                    <FormError form={formik} field={'length'} />
                </div>
                <div className="col-12 mb-3">
                    <div className="input-group">
                        <div className="form-floating">
                            <input disabled={formik.isSubmitting} type="number" step={'any'} className="form-control" id={'count'} name={'count'} onChange={formik.handleChange} />
                            <label htmlFor="count">
                                Nombre de produit par colis
                            </label>
                        </div>
                    </div>
                    <FormError form={formik} field={'count'} />
                </div>
                <div className="col-12 mb-3">
                    <div className="input-group">
                        <div className="form-floating">
                            <input disabled={formik.isSubmitting} type="number" step={'any'} className="form-control" id={'weight'} name={'weight'} onChange={formik.handleChange} />
                            <label htmlFor="height">
                                Poids
                            </label>
                        </div>
                        <span className="input-group-text">
                            g
                        </span>
                    </div>
                    <FormError form={formik} field={'weight'} />
                </div>
                <button disabled={formik.isSubmitting} type={'submit'} className={"btn btn-light w-100"}>
                    {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-plus-circle'}></i> Ajouter</>}
                </button>
            </form>
        </div>
    </div>
}

export default SupplierProductDimensionAdd;