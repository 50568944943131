import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query: string, offset: number}){
    return get(`corporate/supplier/taxRate${encodeQueryData(requestData)}`);
}

export function show(taxRate: number){
    return get(`corporate/supplier/taxRate/${taxRate}`);
}

export function add(requestData: {name: string, description: string, percentage: number, country: number}){
    return post(`corporate/supplier/taxRate`, requestData);
}

export function update(taxRate: number, requestData: {active: boolean}){
    return post(`corporate/supplier/taxRate/${taxRate}`, requestData);
}
