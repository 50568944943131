import React from "react";
import * as api from "../../../../adapters/checkout/item"
import {LineItem} from "../../../../models/lineItem";
import * as models from "../../../../models/checkout";
import {getCoverUrl} from "../../../../utils/image";

const CheckoutAddressUnavailable = ({checkout, setCheckout}: {checkout: models.CheckoutResponse, setCheckout: React.Dispatch<any>}) => {

    function destroy(item: LineItem){
        api.remove(checkout.uuid, item.id)
            .then(r => setCheckout(r.data))
    }

    return <>{checkout.items.filter(i => !i.isShippingSupported).length > 0 && <div className={'col-12 mb-3'}>
            <div className="card border border-1 border-danger">
                <div className="card-body">
                    <h6 className={"text-danger"}>
                        Livraison non supportée pour les articles suivants
                    </h6>
                    <p>
                        Veuillez retirer les articles ou changer votre adresse de livraison
                    </p>
                    <ul className="list-group">
                        {checkout.items.filter(i => !i.isShippingSupported).map(item => <li className="list-group-item" key={item.id}>
                            <div className="row align-items-center">
                                <div className="col-4">
                                    <img className="img-fluid" src={getCoverUrl(item.product)} alt="..."/>
                                </div>
                                <div className="col-8">
                                    <p className="fs-sm fw-bold mb-6">
                                        <span className="text-body">
                                            {item.product.name}
                                        </span>
                                        <br />
                                        {item.declination_data.length > 0 && item.declination_data.map(d => <>
                                            <br />
                                            <span className="form-text">{d.declination.name}: <span className={'fw-semibold'}>{d.selected?.value || <i className={'bi bi-x'}></i>}</span></span>
                                        </>)}
                                        <br/>
                                        <span className="form-text">
                                                Quantité: {item.quantity}
                                            </span>
                                    </p>
                                    {!item.isShippingSupported && <div className={'d-flex align-items-center'}>
                                        {!item.isShippingSupported && <span className={'text-danger'}>
                                            <i className={'bi bi-exclamation-circle'}></i> Indisponible en {checkout.account_data.shipping?.country?.name}
                                        </span>}
                                    </div>}
                                    <div className="d-flex align-items-center">
                                        <button className="btn btn-xxs fs-xs text-gray-400 ms-auto" type={"button"} onClick={() => destroy(item)}>
                                            <i className="bi bi-x"></i> Retirer
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </li>)}
                    </ul>
                </div>
            </div>
        </div>}</>

}

export default CheckoutAddressUnavailable;