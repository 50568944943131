import React, {useEffect, useState} from "react";
import * as api from "../../../../../adapters/corporate/provider/subscription";
import SearchInput from "../../../../../utils/searchInput";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";
import {Subscriptions} from "../../../../../models/subscription";
import ErrorCard from "../../../../error";
import ProviderSubscriptionListItem from "./item";

const ProviderSubscriptionList = () => {

    const [payload, setPayload] = useState<{ count: number, rows: Subscriptions }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number}>({query: "", offset: 0})
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true)
        api.list({offset: fetchParams.offset, query: fetchParams.query})
            .then(resp => setPayload(resp.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher un abonnement..."} onChange={handleQueryChange} size={"default"} />
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        <div className="col-md-12 flex-grow-1">
            {error && <ErrorCard error={error} />}
            <div className={'table-responsive'}>
                <table className={"table table-hover"}>
                    <thead>
                        <tr>
                            <th>
                                Client
                            </th>
                            <th>
                                Fournisseur
                            </th>
                            <th>
                                Statut
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {payload.rows.length ? payload.rows.map(r => <ProviderSubscriptionListItem item={r} key={r.id} />) : <tr className={'text-muted'}>
                            <i className={'bi bi-search'}></i> Aucun résultat
                        </tr>}
                    </tbody>
                </table>

            </div>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        </div>
    </div>
}

export default ProviderSubscriptionList;