import {Pack} from "../../../../../models/pack";
import * as api from "../../../../../adapters/corporate/supplier/pack";
import {useFormik} from "formik";
import * as Yup from 'yup';
import {useState} from "react";
import FormError from "../../../../../utils/formError";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";

const SupplierPackAdd = ({handleSuccess}: { handleSuccess: (pack: Pack) => void }) => {

    const [error, setError] = useState(null)

    const formik = useFormik({
        initialValues: {
            name: ""
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required()
        }),
        onSubmit: (values, {setSubmitting}) => {
            api.add(values)
                .then(r => handleSuccess(r.data))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    })

    return <form onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className="form-control" id={"name"} name={"name"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                <label htmlFor="name">Nom du package *</label>
            </div>
            <FormError form={formik} field={"name"} />
        </div>
        {error && <div className={"col-12 mb-3"}>
            <ErrorCard error={error} />
        </div>}
        <button className="btn btn-primary w-100">
            {formik.isSubmitting ? <Loader size={"sm"} /> : <><i className={"bi bi-check"}></i> Ajouter</>}
        </button>
    </form>

}

export default SupplierPackAdd;