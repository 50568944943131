import {get} from "../../xhr";
import encodeQueryData from "../../../utils/encodeQueryData";

export function list(uuid: string, requestData: {
    offset: number,
    query: string,
    order: string,
    countryId: number,
    currencyId: number,
    categoryId: number|null
}) {
    return get(`/shop/${uuid}/product${encodeQueryData(requestData)}`);
}

export function show(uuid: string, id: number, queryData: {countryId: number, currencyId: number}) {
    return get(`/shop/${uuid}/product/${id}${encodeQueryData(queryData)}`);
}

export function popular(uuid: string, requestData: {
    offset: number,
    query: string,
    countryId: number
}) {
    return get(`/shop/${uuid}/product/popular${encodeQueryData(requestData)}`);
}

export function bestseller(uuid: string, requestData: {
    offset: number,
    query: string,
    countryId: number
}) {
    return get(`/shop/${uuid}/product/bestseller${encodeQueryData(requestData)}`);
}

export function latest(uuid: string, requestData: {
    offset: number,
    query: string,
    countryId: number
}) {
    return get(`/shop/${uuid}/product/latest${encodeQueryData((requestData))}`);
}