import React from "react";
import {Link} from "react-router-dom";
import SearchInput from "../../../../utils/searchInput";
import {getLogoUrl} from "../../../../utils/image";
import {Country} from "../../../../models/country";
import {Currency} from "../../../../models/currency";
import {Organization} from "../../../../models/organization";
import {useFormik} from "formik";
import {useAppSelector} from "../../../../hooks";

const ShopNavHeader = ({country, currency, uuid, provider}: {country: Country, currency: Currency, uuid: string, provider: Organization}) => {

    const cart = useAppSelector(state => state.cart);
    const user = useAppSelector(state => state.auth.user);

    const formik = useFormik({
        initialValues: {query: ""},
        onSubmit:(values, formikHelpers) => {
            console.log(values)
        }
    })

    return <>
        <div className="navbar navbar-topbar navbar-expand-xl navbar-light bg-light z-index-1">
            <div className="container">

                <div className="me-xl-8 me-2 align-items-center">
                    <span className="fs-xxs">Powered by <Link to={'https://varar.co'} target={"_blank"} replace><img src={process.env.PUBLIC_URL + '/img/logo.png'} alt="" height={25} width={'auto'}/></Link></span>
                </div>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#topbarCollapse" aria-controls="topbarCollapse" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="topbarCollapse">
                    <ul className="nav nav-divided navbar-nav me-auto">
                        <li className="nav-item hovered">
                            <button className="nav-link" data-bs-toggle="modal" data-bs-target={"#countryModal"}>
                                <i className={'bi bi-geo-alt'}></i>
                                {country.name}
                            </button>
                        </li>
                        <li className="nav-item hovered">
                            <button className="nav-link" data-bs-toggle="modal" data-bs-target={"#currencyModal"}>
                                {currency.symbol} {currency.name}
                            </button>
                        </li>
                    </ul>
                    <ul className={'nav ms-auto navbar-nav'}>
                        <li className="nav-item hovered">
                            {user ? <Link className={'nav-link'} to={"/customer"} target={'_blank'}>
                                Mon compte
                            </Link> : <Link className={'nav-link'} to={`/login?type=customer&redirect=/shop/${uuid}`}>
                                Connexion
                            </Link>}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <nav className="navbar navbar-expand-lg bg-body-tertiary mb-3 sticky-top">
        <div className="container mx-auto">
            <Link className="navbar-brand" to={`/shop/${uuid}`}>
                <img src={getLogoUrl(provider)} className={"navbar-brand-img shop-logo"} alt=""/>
            </Link>
            <form onSubmit={formik.handleSubmit} className={"flex-grow-1 px-3 d-none d-md-block"}>
                <SearchInput onChange={e => formik.setFieldValue("query", e.target.value)} size={"default"} placeholder={"Rechercher dans la boutique..."} />
            </form>
            <ul className="navbar-nav flex-row align-items-center">
                <li className="nav-item cursor-pointer">
                    <Link to={`/shop/${uuid}/cart`} className="nav-link">
                        <span className="btn btn-sm">
                            <span data-cart-items={cart?.items.length || 0}>
                                <i className="bi bi-cart"></i>
                            </span>
                        </span>
                    </Link>
                </li>
            </ul>
            <form onSubmit={formik.handleSubmit} className={"col-12 px-1 d-block d-md-none"}>
                <SearchInput onChange={e => formik.setFieldValue("query", e.target.value)} size={"sm"} placeholder={"Rechercher dans la boutique..."} />
            </form>
        </div>
    </nav>
        </>
}

export default ShopNavHeader;