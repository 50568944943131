import React, {useEffect, useState} from "react";
import CheckoutPaymentForm from "./form";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import Loader from "../../../utils/loader";
import * as api from "../../../adapters/checkout"
import * as models from "../../../models/checkout";
import CheckoutPaymentMethod from "../paymentMethod";
import CheckoutTermsOfService from "../termsOfService";
import ErrorCard from "../../error";
const stripePromise = loadStripe("pk_live_51OHqm6H46Kmwvu6gtsFGdaMUw4mgkXMquclVtkENiHwv4DN4s00c3i7pHXP5db9Vo6SS0omYqRIvDFiHC7oxtWPx00GupdBdOQ");

const CheckoutPayment = ({checkout, setCheckout}: {checkout: models.CheckoutResponse, setCheckout: React.Dispatch<any>}) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        api.payment(checkout.uuid)
            .then(r => setCheckout(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [])

    if (loading){
        return <div className={'col-12 py-5 text-center'}><Loader /></div>
    }

    return <div className="col-12 mb-3" id={"payment"}>
        {error && <ErrorCard error={error} />}
        <div className="col-12 mb-3">
            <CheckoutPaymentMethod checkout={checkout} setCheckout={setCheckout} />
        </div>
        <div className="col-12 mb-3">
            <CheckoutTermsOfService checkout={checkout} setCheckout={setCheckout} />
        </div>
        <div className="col-12 mb-3">
            <Elements options={{appearance: {theme: 'stripe'}}} stripe={stripePromise}>
                <CheckoutPaymentForm checkout={checkout} />
            </Elements>
        </div>
    </div>
}

export default CheckoutPayment