import {Navigate, Outlet, useLocation} from "react-router-dom";
import React from "react";
import {useAppSelector} from "../../hooks";

const CheckoutLayout = () => {

    const user = useAppSelector(state => state.auth.user)
    const location = useLocation();

    if (!user){
        return <Navigate to={`/login?type=customer&next=checkout&redirect=${encodeURIComponent(location.pathname)}`} replace />
    }else if (user.activeAccount.type === 'corporate'){
        return <Navigate to={`/account?type=customer&next=checkout&redirect=${encodeURIComponent(location.pathname)}`} replace />
    }

    return <Outlet />
}

export default CheckoutLayout;