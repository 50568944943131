import React, {useEffect, useState} from "react";
import {Affiliation} from "../../../../../../models/affiliation";
import * as api from "../../../../../../adapters/corporate/supplier/affiliation"
import Chart from 'react-apexcharts'
import Loader from "../../../../../../utils/loader";
import {format, setHours, setMinutes} from "date-fns";

type Range = "today"|"yesterday"|"currentWeek"|"lastWeek"|"currentMonth"|"lastMonth"|"last30Days"|"last6Month"|"last12Month"|"currentYear"|"lastYear"|"dateToDate"

const SupplierAffiliationShowStatistics: React.FC<{ affiliation: Affiliation }> = ({affiliation}) => {

    const [tab, setTab] = useState<'click-conversion'|'partnership'>('click-conversion')

    const rangeOptions: {label: string, value: Range}[] = [
        {value: "today", label: "Aujourd'hui"},
        {value: "yesterday", label: "Hier"},
        {value: "currentWeek", label: "Cette semaine"},
        {value: "lastWeek", label: "Semaine dernière"},
        {value: "currentMonth", label: "Ce mois ci"},
        {value: "lastMonth", label: "Mois dernier"},
        {value: "last30Days", label: "30 derniers jours"},
        {value: "last6Month", label: "6 derniers mois"},
        {value: "last12Month", label: "12 derniers mois"},
        {value: "currentYear", label: "Année en cours"},
        {value: "lastYear", label: "Année dernière"},
        {value: "dateToDate", label: "Date à date"},
    ]

    const [range, setRange] = useState<Range>("currentMonth");

    let today = new Date();
    const [dateRange, setDateRange] = useState<{start: Date, end: Date}>({start: new Date(today.getFullYear(), today.getMonth(), 1), end: new Date(today.getFullYear(), today.getMonth() + 1, 1)})
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({
        series: [
            {
                name: "clicks",
                data: []
            },
            {
                name: "conversions",
                data: []
            },
        ],
        xAxis: []
    })

    function getGranularity(){
        switch (range){
            case "today":
            case "yesterday":
                return "hour"
            case "currentWeek":
            case "lastWeek":
            case "currentMonth":
            case "lastMonth":
            case "last30Days":
                return "day"
            case "last6Month":
            case "last12Month":
            case "currentYear":
            case "lastYear":
                return "month"
            case "dateToDate":
                return "day";
        }
    }

    const [type, setType] = useState<"line"|"bar">("line")

    useEffect(() => {
        today = new Date();
        let _dateRange: {start: Date, end: Date} = {start: dateRange.start, end: dateRange.end};

        switch (range) {
            case "today":
                _dateRange.start = setHours(setMinutes(new Date(today.getFullYear(), today.getMonth(), today.getDate()), 0), 0);
                _dateRange.end = setHours(setMinutes(new Date(today.getFullYear(), today.getMonth(), today.getDate()), 59), 23);
                break;
            case "yesterday":
                _dateRange.start = setHours(setMinutes(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1), 0), 0);
                _dateRange.end = setHours(setMinutes(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1), 59), 23);
                break;
            case "currentWeek":
                _dateRange.start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - (today.getDay() - 1));
                _dateRange.end = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()));
                break;
            case "lastWeek":
                _dateRange.start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - (today.getDay() - 1) - 7);
                _dateRange.end = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()) - 7);
                break;
            case "currentMonth":
                _dateRange.start = new Date(today.getFullYear(), today.getMonth(), 1);
                _dateRange.end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                break;
            case "last30Days":
                _dateRange.start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30);
                _dateRange.end = today;
                break;
            case "lastMonth":
                _dateRange.start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                _dateRange.end = new Date(today.getFullYear(), today.getMonth(), 0);
                break;
            case "last6Month":
                _dateRange.start = new Date(today.getFullYear(), today.getMonth() - 6, 1);
                _dateRange.end = new Date(today.getFullYear(), today.getMonth(), 0);
                break;
            case "currentYear":
                _dateRange.start = new Date(today.getFullYear(), 0, 1);
                _dateRange.end = new Date(today.getFullYear(), 11, 31);
                break;
            case "lastYear":
                _dateRange.start = new Date(today.getFullYear() - 1, 0, 1);
                _dateRange.end = new Date(today.getFullYear() - 1, 11, 31);
                break;
            case "last12Month":
                _dateRange.start = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
                _dateRange.end = today;
                break;
        }

        setDateRange(_dateRange)
    }, [range])

    useEffect(() => {
        setLoading(true)
        switch (tab){
            case "click-conversion":
                api.statistics(affiliation.id, {startDate: dateRange.start, endDate: dateRange.end, granularity: getGranularity()})
                    .then(r => setData(r.data))
                    .then(() => setLoading(false))
                break;
            case "partnership":
                api.partnerships(affiliation.id, {startDate: dateRange.start, endDate: dateRange.end})
                    .then(r => setData(r.data))
                    .then(() => setLoading(false))
                break;
        }



    }, [dateRange, tab])

    return <div className={'col-12 mb-3'}>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <div className="row">
                        <div className="col">
                            <ul className="nav nav-pills">
                                <li className="nav-item">
                                    <button className={`nav-link ${tab === "click-conversion" ? 'active' : ''}`} onClick={() => setTab('click-conversion')}>
                                        Clicks & conversions
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${tab === "partnership" ? 'active' : ''}`} onClick={() => setTab('partnership')}>
                                        Partenaires
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="col-auto">
                            <div className="dropdown">
                                <button className="btn btn-xs btn-light" type="button"
                                        id="range" data-bs-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                    {rangeOptions.find(c => c.value === range)!.label} <i className={'bi bi-caret-down'}></i>
                                </button>
                                <ul className="dropdown-menu">
                                    {rangeOptions.map(o => <li key={o.value} className={'dropdown-item'} onClick={() => setRange(o.value)}>
                                        {o.label}
                                    </li>)}
                                </ul>
                            </div>
                        </div>
                        {range === "dateToDate" && <div className="col-auto">
                            <div className="input-group input-group-sm">
                                <input type="date" value={format(dateRange.start, "uuuu-MM-dd")} onChange={e => setDateRange(prev => ({start: new Date(e.target.value), end: prev.end}))} className={'form-control form-control-xs'}/>
                                <span className={"input-group-text py-2 px-3"}>
                                    <i className={'bi bi-arrow-left-right'}></i>
                                </span>
                                <input type="date" value={format(dateRange.end, "uuuu-MM-dd")} onChange={e => setDateRange(prev => ({start: prev.start, end: new Date(e.target.value)}))} className={'form-control form-control-xs'}/>
                            </div>
                        </div>}
                        {tab === "click-conversion" && <div className="col-auto">
                            <div className="btn-group">
                                <button className={'btn btn-xs ' + (type === "line" ? "btn-dark" : "btn-light")}
                                        onClick={() => setType("line")}>
                                    <i className={"bi bi-graph-up"}></i>
                                </button>
                                <button className={'btn btn-xs ' + (type === "bar" ? "btn-dark" : "btn-light")}
                                        onClick={() => setType("bar")}>
                                    <i className={'bi bi-bar-chart'}></i>
                                </button>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col mb-3">
                            <div className="card border border-1">
                                <div className="card-body text-center">
                                    <div className="fs-3 fw-semibold">
                                        {data.series[0].data.reduce((partialSum, a) => partialSum + a, 0)}
                                    </div>
                                    <div className="text-muted">
                                        Clicks
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-3">
                            <div className="card border border-1">
                                <div className="card-body text-center">
                                    <div className="fs-3 fw-semibold">
                                        {data.series[1].data.reduce((partialSum, a) => partialSum + a, 0)}
                                    </div>
                                    <div className="text-muted">
                                        Conversions
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-3">
                            <div className="card border border-1">
                                <div className="card-body text-center">
                                    <div className="fs-3 fw-semibold">
                                        {(Math.round(((data.series[1].data.reduce((partialSum, a) => partialSum + a, 0) * 100) / data.series[0].data.reduce((partialSum, a) => partialSum + a, 0)) * 100) / 100) || 0}%
                                    </div>
                                    <div className="text-muted">
                                        Taux de conversion / clics
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body position-relative">
                    <Chart
                        type={tab === "click-conversion" ? type : "bar"}
                        options={{
                            chart: {
                                width: "100%",
                            },
                            xaxis: {
                                categories: data.xAxis,
                            }
                        }}
                        series={data.series}
                    />
                    {loading && <div className="position-absolute mx-auto top-50 start-50 translate-middle">
                        <Loader/>
                    </div>}
                </div>
            </div>
        </div>
    </div>
}

export default SupplierAffiliationShowStatistics;