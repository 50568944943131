import React, {useState} from "react";
import * as api from "../../../../../../adapters/corporate/provider/link/item";
import * as models from "../../../../../../models/product";
import * as Yup from "yup";
import {useFormik} from "formik";
import {Link, LinkItem} from "../../../../../../models/link";
import FormError from "../../../../../../utils/formError";
import ProviderProductShow from "../../../product/show";
import ModalComponent from "../../../../../modal";

const ProviderLinkItemAdd = ({handleAdd, selected, link, showModal, setShowModal}: {link: Link, handleAdd: (item: LinkItem) => void, selected: models.Product & models.SellableProduct, showModal: boolean, setShowModal: React.Dispatch<any> }) => {

    const [error, setError] = useState(null);

    const formik = useFormik({
        initialValues: {
            product_id: selected.id,
            quantity: selected.minQuantity || 1,
            editable: false,
        },
        validationSchema: Yup.object().shape({
            product_id: Yup.number().required(),
            quantity: Yup.number().required().min(selected.minQuantity || 1, `Quantité minimmum de ${selected.minQuantity || 1}`),
            editable: Yup.boolean().required()
        }),
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            setError(null);
            api.add(link.id, values)
                .then(r => handleAdd(r.data))
                .catch((e: any) => setError(e))
                .finally(() => formikHelpers.setSubmitting(false))
        }
    })

    return <>
        <ModalComponent
            title={"Ajouter un produit"}
            size={"modal-xl"}
            show={showModal}
            onHide={() => setShowModal(false)}
            footerContent={<div className="row">
                {error && <div className={'alert alert-danger'}>{error}</div>}
                <div className="col">
                    <div className="input-group">
                        <div className="form-floating">
                            <input type="text" className="form-control" name={"quantity"} id={"quantity"} min={selected.minQuantity} max={selected.maxQuantity ? selected.maxQuantity : undefined} onChange={formik.handleChange} defaultValue={selected.minQuantity || 1} />
                            <label htmlFor="">
                                Quantité *
                            </label>
                        </div>
                    </div>
                    <FormError form={formik} field={'quantity'} />
                </div>
                <div className="col-auto">
                    <button onClick={formik.submitForm} type={"button"} className={'btn btn-primary'}>
                        <i className={'bi bi-plus-circle'}></i> Ajouter à la séléction
                    </button>
                </div>
            </div>}
        >
            <ProviderProductShow id={selected.id} />
        </ModalComponent>

    </>
}

export default ProviderLinkItemAdd;