import {get, post} from "../../../xhr";

export function createAccount()
{
    return get(`/corporate/common/stripe/create`);
}

export function processAccount()
{
    return get(`/corporate/common/stripe/process`);
}

export function balance()
{
    return get(`/corporate/common/stripe/balance`);
}
export function onboarding()
{
    return get(`/corporate/common/stripe/onboarding`);
}
export function status()
{
    return get(`/corporate/common/stripe/status`);
}
