import React from "react";
import {useAppSelector} from "../../hooks";
import {Navigate} from "react-router-dom";

const AppLayout = () => {

    const user = useAppSelector(state => state.auth.user);

    if (!user){
        return <Navigate to={"/login"} replace />
    }

    if (!user.activated){
        return <Navigate to={"/activate"} replace />
    }

    if (user.activeAccount){
        switch (user.activeAccount.type) {
            case "customer":
                return <Navigate to={"/customer"} replace />
            case "corporate":
                switch (user.activeAccount.organization.status) {
                    case "Pending":
                        return <Navigate to={"/onboarding/type"} replace />
                    case "Completed":
                    case "Verified":
                        return <Navigate to={"/corporate"} replace />
                }
                break;
        }
    }

    return <></>
}

export default AppLayout;