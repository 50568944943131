import {get, post} from "../../xhr";
import encodeQueryData from "../../../utils/encodeQueryData";

export function show(id: number) {
    return get(`/customer/address/${id}`);
}

export function list(requestData: {offset: number, query?: string, country_id?: number, postalCode?: string}) {
    return get(`/customer/address${encodeQueryData(requestData)}`);
}

export function add(requestData: any) {
    return post(`/customer/address`, requestData);
}

export function update(id: number, requestData: any) {
    return post(`/customer/address/${id}`, requestData);
}