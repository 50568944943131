import React, {useState} from "react";
import * as api from "../../../../../adapters/corporate/common/stripe"
import {loadConnectAndInitialize} from '@stripe/connect-js';
import {
    ConnectAccountOnboarding,
    ConnectComponentsProvider,
} from '@stripe/react-connect-js';
import {TEST_PUBLISHABLE_KEY} from "../../../../../constants/stripe";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import {useNavigate} from "react-router-dom";

const StripeOnBoarding = () => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);
    const navigate = useNavigate();

    const fetchClientSecret = async () => {
        try {
            const { data } = await api.onboarding();
            return data.client_secret
        }catch (e) {
            setError(e)
        }finally {
            setLoading(false)
        }
    };

    const connectInstance = loadConnectAndInitialize({
        publishableKey: TEST_PUBLISHABLE_KEY,
        fetchClientSecret: fetchClientSecret,
        appearance: {
            variables: {
                colorPrimary: '#ff6f61',
                fontFamily: "Arial"
            },
        }
    });

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    return <div className={"card border border-1"}>
        <div className="card-body">
            <div className={"row"}>
                <div className="col-md-4">
                    <h6 className={"text-center mt-10"}>
                        Varar s'associe à stripe pour proposer une service de paiement sécurisé
                    </h6>
                    <p className={'fw-semibold'}>La connectivité stripe a pour but de:</p>
                    <ul>
                        <li>
                            Vérifier votre identité pour éviter la fraude
                        </li>
                        <li>
                            Transférer vos revenues généré via Varar.
                        </li>
                    </ul>
                </div>
                <div className="col-md-8">
                    <ConnectComponentsProvider connectInstance={connectInstance}>
                        <ConnectAccountOnboarding
                            onExit={() => navigate('/onboarding/status')}
                            fullTermsOfServiceUrl={'https://varar.co/tos'}
                            privacyPolicyUrl={'https://varar.co/privacy'}
                            skipTermsOfServiceCollection={true}
                            collectionOptions={{
                                fields: "eventually_due"
                            }}
                        />
                    </ConnectComponentsProvider>
                </div>
            </div>
        </div>
    </div>

}

export default StripeOnBoarding;