import {useEffect, useState} from "react";
import * as api from "../../../../../adapters/corporate/supplier/click";
import {Currency} from "../../../../../models/currency";
import {Affiliation, Click, ClickRule, Clicks} from "../../../../../models/affiliation";
import convertToDecimal from "../../../../../utils/currencyConverter";
import Loader from "../../../../../utils/loader";
import {PaymentMethod} from "../../../../../models/paymentMethod";
import CommonPaymentMethodSelect from "../../../../common/paymentMethod/select";
import ClickPayment from "./payment";

const SupplierClickPay = ({clicks, affiliation, handlePaymentSucceed}: {clicks: Clicks, affiliation: Affiliation, handlePaymentSucceed: (clicks: Clicks) => void}) => {

    const [loading, setLoading] = useState(true);
    const [currency, setCurrency] = useState<Currency>(affiliation.currencies[0]);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();

    const [payment, setPayment] = useState<{
        summary: {rule: ClickRule, clicks: Click[], unit_amount: number, total_amount: number}[];
        clicks: Clicks;
        total_amount: number;
        currency: Currency;
    }>()

    useEffect(() => {
        setLoading(true)
        api.paymentData({currency_id: currency.id, ids: clicks.map(c => c.id)})
            .then(r => setPayment(r.data))
            .finally(() => setLoading(false))
    }, [currency.id, clicks])

    return <>
        <div className="col-12 mb-5">
            <h6>
                Devise
            </h6>
            <div className="btn-group">
                {affiliation.currencies.map(c => <button className={`btn btn-xs ${c.id === currency.id ? "btn-primary" : 'btn-light'}`} onClick={() => setCurrency(c)}>
                    {c.name} ({c.symbol})
                </button>)}
            </div>
        </div>
        <div className={"col-12 mb-5"}>
            <h6>
                Récapitulatif du paiement
            </h6>
            {loading ? <Loader /> : <>
                {payment && <table className={"table"}>
                    <thead>
                    <tr>
                        <th>
                            Règle
                        </th>
                        <th>
                            Quantité
                        </th>
                        <th>
                            Unité
                        </th>
                        <th>
                            Total
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {payment.summary.map(s => <tr>
                        <td>{s.rule.name}</td>
                        <td>{s.clicks.length}</td>
                        <td>{convertToDecimal(s.unit_amount, payment.currency)}</td>
                        <td>{convertToDecimal(s.total_amount, payment.currency)}</td>
                    </tr>)}
                    </tbody>
                    <tbody>
                        <tr>
                            <td colSpan={2}></td>
                            <th>
                                Quantité
                            </th>
                            <td>
                                {payment.clicks.length}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}></td>
                            <th>
                                Total
                            </th>
                            <td>
                                {convertToDecimal(payment.total_amount, payment.currency)}
                            </td>
                        </tr>
                    </tbody>
                </table>}
                </>}
            <div className="col-12 mb-3">
                <h6>Moyen de paiement</h6>
                <CommonPaymentMethodSelect handleSelect={pm => setPaymentMethod(pm)} selected={paymentMethod} />
            </div>
            <ClickPayment handlePaymentSucceed={handlePaymentSucceed} clicks={payment?.clicks} currency={currency} paymentMethod={paymentMethod} />
        </div>
    </>
}

export default SupplierClickPay;