import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../adapters/auth";
import Loader from "../../../utils/loader";
import FormError from "../../../utils/formError";
import ErrorCard from "../../error";
import {Link, useParams} from "react-router-dom";

const ResetPassword = () => {

    const [error, setError] = useState<any>(null)
    const {token} = useParams();
    const [loading, setLoading] = useState(true);
    const [verified, setVerified] = useState(false);
    const [updated, setUpdated] = useState(false);

    useEffect(() => {
        if (token){
            api.verifyPwdToken({token: token})
                .then(r => setVerified(r.data.verified))
                .catch(e => setError(e))
                .finally(() => setLoading(false))
        }
    }, [])

    const formik = useFormik({
        initialValues: {
            token: token!,
            password: "",
            repeatPassword: "",
        },
        validationSchema: Yup.object().shape({
            token: Yup.string().required(),
            password: Yup.string().min(8).required(),
            repeatPassword: Yup.string().min(8).oneOf([Yup.ref('password'), ''], 'Les mots de passe ne correspondent pas').required(),
        }),
        onSubmit: (values, formikHelpers) => {
            setLoading(true)
            api.resetPassword(values)
                .then(resp => setUpdated(true))
                .catch(error => setError(error))
                .finally(() => setLoading(false))
        }
    })

    return <div className={'container mx-auto h-100'}>
        <div className="row h-100 align-items-center justify-content-center">
            <div className="col-md-6">
                <div className="row justify-content-center">
                    <div className="col-auto d-flex align-items-center mb-5">
                        <img src={process.env.PUBLIC_URL + '/img/logo.png'} className={'logo me-2'} alt=""/>
                    </div>
                </div>
                <div className="card border border-1 rounded mb-3">
                    <div className="card-body">
                        {!loading && error && <ErrorCard error={error} />}
                        {loading ? <div className={'col-12 text-center'}><Loader /></div> : <>
                            {verified ? <>
                                {updated ? <>
                                    <h3>
                                        <i className={"bi bi-check"}></i> Mot de passe mis à jour.
                                    </h3>
                                    <Link to={'/login'} className={'btn btn-light'}>
                                        Connexion <i className={'bi bi-arrow-right'}></i>
                                    </Link>
                                </> : <>
                                    <h6>Mettre à jour le mot de passe</h6>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="col-12 mb-3">
                                            <div className="form-floating">
                                                <input disabled={loading} name={"password"} id={"password"} onBlur={formik.handleBlur} onChange={formik.handleChange} type="password" className="form-control" />
                                                <label htmlFor="password">
                                                    Nouveau mot de passe
                                                </label>
                                            </div>
                                            <FormError form={formik} field={'password'} />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="form-floating">
                                                <input disabled={loading} name={"repeatPassword"} id={"repeatPassword"} onBlur={formik.handleBlur} onChange={formik.handleChange} type="password" className="form-control" />
                                                <label htmlFor="repeatPassword">
                                                    Nouveau mot de passe
                                                </label>
                                            </div>
                                            <FormError form={formik} field={'repeatPassword'} />
                                        </div>
                                        <button className="btn btn-dark w-100 mb-3" disabled={loading}>
                                            {loading ? <Loader size={"sm"} /> : <>Valider <i className={'bi bi-arrow-right'}></i></>}
                                        </button>
                                        {error && <ErrorCard error={error} />}
                                    </form>
                                </>}
                            </> : <>Lien invalide</>}
                        </>}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ResetPassword;