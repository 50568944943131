import React from "react";
import authSlice from "../../../reducers/auth";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {Link} from "react-router-dom";

const Error404Page: React.FC = () => {

    const dispatch = useAppDispatch();
    const auth = useAppSelector(state => state.auth)

    return <div className={'container-fluid vh-100 d-flex align-items-center justify-content-center'}>
        <div className="col-md-6 text-center">
            <div className="display-6 mb-8">
                Erreur 404
            </div>
            <div className="btn-group">
                <Link className="btn btn-light me-3" to={'/'}>
                    <i className="bi bi-home"></i> Retour à la page d'accueil
                </Link>
                {auth.user && <button className="btn btn-dark" onClick={() => dispatch(authSlice.actions.logout())}>
                    <i className="bi bi-toggle-off"></i> Deconnexion
                </button>}
            </div>
        </div>
    </div>
}

export default Error404Page;