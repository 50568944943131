import React, {useState} from "react";
import CartItemList from "./item/list";
import {Country} from "../../models/country";
import {useNavigate} from "react-router-dom";
import {Currency} from "../../models/currency";
import * as api from "../../adapters/cart";
import Loader from "../../utils/loader";
import convertToDecimal from "../../utils/currencyConverter";
import {useAppSelector} from "../../hooks";

const Cart = ({country, currency, source, sourceId}: {country: Country, currency: Currency, source: "shop"|"link"|"pack", sourceId: string}) => {

    const cart = useAppSelector(state => state.cart);

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    function proceedToCheckout(){
        setLoading(true)
        api.checkout(Number(cart.id), {country_id: country.id, currency_id: currency.id})
            .then(r => navigate(`/checkout/${r.data.uuid}`))
            .then(() => setLoading(false))
        ;
    }

    return <div className="row">
        <div className="col-md-9 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Vos Articles
                    </h6>
                </div>
                <div className="card-body">
                    <CartItemList source={source} sourceId={sourceId} lineItems={cart.items} country={country} currency={currency} />
                </div>
            </div>
        </div>
        <div className="col-md-3 mb-3">
            <div className="sticky-top">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>
                            Récapitulatif de votre panier
                        </h6>
                    </div>
                    <div className="card-body">
                        <div className="col-12 mb-3">
                            <p className={'fw-semibold'}>
                                Livraison en
                            </p>
                            <div className="row">
                                <div className="col">
                                    <i className={'bi bi-geo-alt'}></i> {country.name}
                                </div>
                                <div className="col-auto">
                                    <span className="text-decoration-underline cursor-pointer" data-bs-toggle="modal" data-bs-target={"#countryModal"}>
                                        Modifier
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <table className="table mb-3">
                                <tbody>
                                    <tr>
                                        <th>
                                            Articles
                                        </th>
                                        <td>
                                            {convertToDecimal(cart.price_data.total_amount_including_tax, currency)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Frais de livraison
                                        </th>
                                        <td>
                                            <span className="form-text">
                                                Passez la commande pour calculer les frais de livraisons
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Total
                                        </th>
                                        <td>
                                            <i className={'bi bi-x'}></i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button disabled={loading || !cart.items?.length || cart.items?.filter(i => !i.isCurrencySupported || !i.isShippingSupported || !i.isQuantitySupported || i.declination_data.filter(data => !data.selected).length > 0).length > 0} className="btn w-100 btn-dark" onClick={() => proceedToCheckout()}>
                                {loading ? <Loader /> : <>Passer la commande <i className={'bi bi-arrow-right'}></i></>}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Cart;