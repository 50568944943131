import convertToDecimal from "../../../../../../../../utils/currencyConverter";
import React, {useState} from "react";
import * as models from "../../../../../../../../models/refund";
import ModalComponent from "../../../../../../../modal";

const CustomerOrderRefundListItem = ({refund}: {refund: models.Refund & models.SubscriptionRefund}) => {

    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <li className={"list-group-item"}>
                <div className="row align-items-center">
                    <div className="col">
                        {convertToDecimal(refund.priceData.amount, refund.priceData.currency)} le {new Date(refund.createdAt).toLocaleDateString()}
                    </div>
                    <div className="col-auto">
                        <button className={"btn btn-light btn-xxs"} onClick={() => setShowModal(true)}>
                            <i className={"bi bi-eye"}></i>
                        </button>
                    </div>
                </div>
            </li>
            <ModalComponent title={"Détail du remboursement"} show={showModal} onHide={() => setShowModal(false)}>
                <ul className={"list-group list-group-flush"}>
                    {refund.items.map(i => <li className={"list-group-item"}>
                        <div className="row align-items-center">
                            <div className="col">
                                <p className={"mb-1"}>
                                    <span className={"form-text"}>Article</span>: {i.occurrenceItem.price.product.name}
                                </p>
                                <p className={"mb-1"}>
                                    <span className={"form-text"}>Quantité</span>: {i.quantity} / {i.occurrenceItem.quantity}
                                </p>
                            </div>
                            <div className="col-auto">
                                {convertToDecimal(i.priceData.amount, i.priceData.currency)}
                            </div>
                        </div>
                    </li>)}
                </ul>
            </ModalComponent>
        </>
    )
}

export default CustomerOrderRefundListItem;