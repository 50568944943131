import {Product, SellableProduct} from "../../../../../../../models/product";
import {getCoverUrl} from "../../../../../../../utils/image";
import React from "react"

const ProviderLinkItemSelectorItem = ({item, handleClick}: {item: Product & SellableProduct, handleClick: (product: Product & SellableProduct) => void}) => {
    return <div className={"col mb-3"}>
        <div className="card border border-1 shadow-hover cursor-pointer position-relative h-100" onClick={() => handleClick(item)}>
            {item.price.recurring && <span style={{top: 10, left: -10}} className={'badge bg-primary position-absolute'}>
                <i className={'bi bi-arrow-repeat'}></i> Abonnement
            </span>}
            <img src={`${getCoverUrl(item)}`} alt="" className="card-img-top"/>
            <div className="card-body">
                <div className="row">
                    <div className="col">
                        <p className="d-block fw-bold text-body mb-1">
                            {item.name}
                        </p>
                        <p className="d-flex align-items-center text-body mb-1 text-muted fs-xxs">
                            <span>{item.category?.name || "Non catégorisé"}</span>
                        </p>
                    </div>
                    <div className="col-auto">
                        <div className="fs-sm fw-bold text-primary">
                            <i className={"bi bi-trophy"}></i> {item.ranking?.score.toLocaleString() || 0}
                        </div>
                        <div className="fs-sm fw-bold text-success">
                            {item.price.feePercent}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ProviderLinkItemSelectorItem;