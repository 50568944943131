import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import * as models from "../../../../../../../../models/shipping";
import * as api from "../../../../../../../../adapters/corporate/supplier/shipping/rate";
import Loader from "../../../../../../../../utils/loader";
import SupplierShippingPriceList from "../price/list";
import SupplierShippingRateEdit from "../edit";

const SupplierShippingRateShow = () => {

    const params = useParams();
    const [loading, setLoading] = useState(true)
    const [rate, setRate] = useState<models.ShippingRate>();

    useEffect(() => {
        setLoading(true)
        api.show(Number(params.id))
            .then(r => setRate(r.data))
            .then(() => setLoading(false))
    }, [params.id])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (!rate){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                Area not found
            </div>
        </div>
    }

    return <>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb fs-xs">
                <li className="breadcrumb-item">
                    <Link to={`/corporate/supplier/shipping`}>Profil d'expédition</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to={`/corporate/supplier/shipping/${rate.area.shipping.id}/area`}>{rate.area.shipping.name}</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to={`/corporate/supplier/shipping/${rate.area.shipping.id}/area`}>Zones d'expédition</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to={`/corporate/supplier/shipping/area/${rate.area.id}`}>
                        {rate.area.name}
                    </Link>
                </li>
                <li className="breadcrumb-item">
                    {rate.name}
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h6>
                                {rate.name}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-light" data-bs-toggle={"offcanvas"} data-bs-target={"#offcanvasShippingRateEdit"}>
                                <i className={'bi bi-pencil'}></i> Mettre à jour
                            </button>
                        </div>
                    </div>

                </div>
                <div className="card-body">
                    <table className={'table'}>
                        <tbody>
                            <tr>
                                <th>
                                    Délai minimum
                                </th>
                                <td>
                                    {rate.delayMin} jour(s)
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Délai maximum
                                </th>
                                <td>
                                    {rate.delayMax} jour(s)
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <SupplierShippingPriceList rate={rate} />
        <SupplierShippingRateEdit rate={rate} handleSuccess={newRate => setRate(oldRate => ({...oldRate, ...newRate}))} />
    </>
}

export default SupplierShippingRateShow;