import React, {KeyboardEventHandler, useEffect, useRef, useState} from "react";
import {Post, PostComment} from "../../../../../../models/post";
import * as api from "../../../../../../adapters/corporate/common/post/comment";
import {useFormik} from "formik";
import * as Yup from "yup";

const PostCommentAdd = ({parent, handleSuccess, post}: { parent?: PostComment, handleSuccess: (comment: PostComment) => void, post: Post }) => {

    const textBox = useRef<HTMLDivElement|null>(null);

    const formik = useFormik({
        initialValues: {
            content: ""
        },
        validationSchema: Yup.object().shape({
            content: Yup.string().required()
        }),
        onSubmit: (values, formikHelpers) => {
            api.add(post.id, {
                content: values.content,
                parent: parent?.id
            })
                .then(resp => handleSuccess(resp.data))
                .then(() => {
                    if (textBox.current){
                        textBox.current.innerText = ""
                    }

                    formikHelpers.resetForm();
                }).finally(() => formik.setSubmitting(false))
        }
    })

    const handleKeyDown = async (evt: React.KeyboardEvent<HTMLParagraphElement>) => {
        if (evt.key === 'Enter') {
            if(!evt.shiftKey){
                await formik.submitForm();
            }
        }
    }

    return <div className={'col-12 mb-3'}>
        <form onSubmit={formik.handleSubmit} style={{borderRadius: 30}} className={"bg-body-secondary p-3 position-relative"}>
            <div className="row">
                <div className="col position-relative">
                    <div ref={textBox} className="flex-grow-1 custom-text-area mb-0 position-relative"
                       style={{minHeight: 30, zIndex: 2}}
                       contentEditable={!formik.isSubmitting}
                       onKeyDown={handleKeyDown}
                       onInput={e => formik.setFieldValue("content", e.currentTarget.innerText)}
                       onBlur={e => formik.setFieldValue("content", e.currentTarget.innerText)}
                    >
                    </div>
                    {!formik.values.content.length && <div style={{zIndex: 0}} className="position-absolute ps-3 text-muted start-0 top-0">
                        {parent ? <>Répondre à {parent.account.firstname} {parent.account.lastname}...</> : <>Écrivez un commentaire...</>}
                    </div>}
                </div>
                <div className="col-auto d-flex align-items-end">
                    <button disabled={!formik.isValid} className={'btn btn-xxs btn-circle btn-primary'} type={'submit'}>
                        <i className={'bi bi-send'}></i>
                    </button>
                </div>
            </div>
        </form>
    </div>
}

export default PostCommentAdd;