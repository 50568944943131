import {useFormik} from "formik";
import * as Yup from "yup";
import React, {useState} from "react";
import FormError from "../../../../utils/formError";
import Loader from "../../../../utils/loader";
import * as api from "../../../../adapters/account"
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import authSlice from "../../../../reducers/auth";
import {useNavigate} from "react-router-dom";
import {useQuery} from "../../../../utils/useQuery";
import {getCountryCode, getCountryCodes, getCountryCodesChoice} from "../../../../utils/tel";
import Select from "../../../../utils/select";
import ErrorCard from "../../../error";

const AccountAddCustomer = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const query = useQuery();
    const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
    const [error, setError] = useState(null)

    const YupValidatePhoneNumber = (YupRef: any) => {
        return YupRef.string().required('Phone number is required').test(
            'is-valid-phone-number',
            'Invalid phone number',
            (value: any, context: any) => validatePhoneNumber(context.resolve(YupRef.ref("countryCode")), value))
    }

    const validatePhoneNumber = (countryCode: string, value: string) => {
        if (value){
            const countryCodeAlpha = getCountryCode(countryCode)?.code;

            if (countryCodeAlpha){
                const phoneNumber = phoneUtil.parseAndKeepRawInput(value, countryCodeAlpha);
                return phoneUtil.isValidNumber(phoneNumber);
            }

            return false;
        }
        return false;
    };

    const user = useAppSelector(state => state.auth.user)!;

    const [initialValues, setInitialValues] = useState<any>({
        firstname: user.activeAccount?.firstname || "",
        lastname: user.activeAccount?.lastname || "",
        countryCode: user.activeAccount?.countryCode || "",
        tel: user.activeAccount?.tel || "",
        customerType: "individual"
    })

    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        firstname: Yup.string().required(),
        lastname: Yup.string().required(),
        countryCode: Yup.string().required('Phone country code is required'),
        tel: YupValidatePhoneNumber(Yup),
        customerType: Yup.string().required(),
    }))

    function handleTypeChange(checked: boolean){

        let values = {...formik.values}
        let fields = {...validationSchema.fields}

        switch (checked){
            case false:
                values.customerType = "individual"
                delete values.companyCode;
                delete fields.companyCode;
                delete values.companyName;
                delete fields.companyName;
                break;
            case true:
                values.customerType = "company"
                values.companyCode = "";
                fields.companyCode = Yup.string().required();
                values.companyName = "";
                fields.companyName = Yup.string().required();
        }

        setInitialValues(values);
        setValidationSchema(Yup.object().shape(fields))
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit:(values, formikHelpers) => {
            api.add({...values, type: "customer"})
                .then(r => dispatch(authSlice.actions.addActiveCustomer(r.data)))
                .then(() => navigate(query.get("redirect") ? query.get("redirect")! : "/customer"))
                .catch(e => setError(e))
                .finally(() => formikHelpers.setSubmitting(false))

        }
    })

    return <div className={"card border border-1"}>
        <div className="card-header">
        {query.has('next') ? <>
            {query.get('next') === "checkout" && <h6 className={"text-center"}>
                Ajouter un compte pour effectuer votre commande
            </h6>}
            {query.get('next') === "quote" && <h6 className={"text-center"}>
                Ajouter un compte pour accepter votre devis
            </h6>}
        </> : <h6 className={'text-center'}>
            Ajouter un compte
        </h6>}
        </div>
        <div className="card-body">
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="text" defaultValue={user.activeAccount?.firstname || ""} className="form-control" name={"firstname"} id={"firstname"} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                        <label htmlFor="firstname">
                            Prénom
                        </label>
                    </div>
                    <FormError form={formik} field={"firstname"} />
                </div>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="text" defaultValue={user.activeAccount?.lastname || ""} className="form-control" name={"lastname"} id={"lastname"} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                        <label htmlFor="firstname">
                            Nom de famille
                        </label>
                    </div>
                    <FormError form={formik} field={"lastname"} />
                </div>
                <div className="col-12 mb-3">
                    <div className="input-group">
                        <Select
                            label={"Code pays"}
                            required={true}
                            options={getCountryCodes()}
                            value={getCountryCodesChoice(user.activeAccount?.countryCode)}
                            onChange={c => formik.setFieldValue('countryCode', c.value)}
                        />
                        <div className="form-floating">
                            <input type="text" defaultValue={user.activeAccount?.tel || ""} className="form-control" name={"tel"} id={"tel"} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                            <label htmlFor="tel">
                                Tel
                            </label>
                        </div>
                    </div>
                    <FormError form={formik} field={"tel"} />
                </div>
                <div className="col-12 mb-3">
                    <div className="form-check form-switch">
                        <input className="form-check-input" onChange={e => handleTypeChange(e.target.checked)} type="checkbox" role="switch" id="customerType" />
                        <label className="form-check-label" htmlFor="customerType">
                            Je suis un professionnel
                        </label>
                    </div>
                </div>
                {formik.values.type === "Company" && <>
                    <div className="col-12 mb-3">
                        <div className="form-floating">
                            <input type="text" className="form-control" name={"companyName"} id={"companyName"} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                            <label htmlFor="companyName">
                                Dénomination sociale
                            </label>
                        </div>
                        <FormError form={formik} field={"companyName"} />
                    </div>
                    <div className="col-12 mb-3">
                        <div className="form-floating">
                            <input type="text" className="form-control" name={"companyCode"} id={"companyCode"} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                            <label htmlFor="companyCode">
                                Siret/Siren
                            </label>
                        </div>
                        <FormError form={formik} field={"companyCode"} />
                    </div>
                </>}
                {error && <ErrorCard error={error} />}
                <button className="btn w-100 btn-light">
                    {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-check'}></i> Enregistrer</>}
                </button>
            </form>
        </div>
    </div>
}

export default AccountAddCustomer;