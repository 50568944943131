import * as api from "../../../../../adapters/corporate/provider/bundle";
import React, {useEffect, useState} from "react";
import {Bundle} from "../../../../../models/bundle";
import {Link, useParams} from "react-router-dom";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import convertToDecimal from "../../../../../utils/currencyConverter";
import ProviderBundlePreview from "../../../supplier/bundle/preview";
import {APP_URL} from "../../../../../constants/global";

const ProviderBundleShow = () => {

    const {id} = useParams()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [bundle, setBundle] = useState<Bundle>()

    const [copied, setCopied] = useState(false)

    let timer: NodeJS.Timeout;
    function copyLink(){
        clearTimeout(timer);
        setCopied(true)
        navigator.clipboard.writeText(`${APP_URL}bundle/${bundle?.link}`);
        timer = setTimeout(() => setCopied(false), 1500);
    }

    useEffect(() => {
        setLoading(true)
        api.show(Number(id))
            .then(r => setBundle(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [id])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!bundle){
        return <></>
    }

    return <div className={"container-fluid"}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row">
                <div className="col">
                    <ol className="breadcrumb mb-3 fs-xs">
                        <li className="breadcrumb-item">
                            <Link to="/corporate/provider/bundle">Grilles tarifaire</Link>
                        </li>
                        <li className="breadcrumb-item">
                            {bundle.name}
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div className={"col-12 mb-3"}>
            <div className="card border border-1">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h6>
                                Lien
                            </h6>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-xs btn-outline-dark" onClick={() => copyLink()}>
                                {copied ? <><i className={'bi bi-check'}></i> Lien copié</> : <><i className={'bi bi-link'}></i> Copier le lien</>}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <Link to={`${APP_URL}bundle/${bundle.link}`} target={'_blank'}>
                        {`https://app.varar.co/bundle/${bundle.link}`}
                    </Link>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Produits
                    </h6>
                </div>
                <div className="card-body">
                    <ul className={"list-group list-group-flush"}>
                        {bundle.items.map(i => <li className={"list-group-item"}>
                            <p className={'fw-semibold'}>
                                {i.product.name}
                            </p>
                            <ul className={"list-group"}>
                                {i.prices.map(p => <li className={"list-group-item"}>
                                    {convertToDecimal(p.price.amount, p.price.currency)} {p.price.recurring && <>/ {p.price.recurring.intervalCount} {p.price.recurring.interval}</>}
                                </li>)}
                            </ul>
                        </li>)}
                    </ul>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Preview
                    </h6>
                </div>
                <div className="card-body">
                    <ProviderBundlePreview organization={bundle.organization} items={bundle.items.map(item => ({product: item.product, prices: item.prices.map(p => p.price)}))} />
                </div>
            </div>
        </div>
    </div>
}

export default ProviderBundleShow;