import {Product, SellableProduct} from "../../../../../models/product";
import {Prices, Price} from "../../../../../models/price";
import React, {useEffect, useState} from "react";
import {getLogoUrl} from "../../../../../utils/image";
import convertToDecimal from "../../../../../utils/currencyConverter";
import {useAppSelector} from "../../../../../hooks";
import {CorporateAccount} from "../../../../../models/account";
import {Feature, Features} from "../../../../../models/feature";
import {useTranslation} from "react-i18next";
import {Organization} from "../../../../../models/organization";

const SupplierBundlePreview = ({items, organization}: {organization: Organization, items: {product: Product & SellableProduct, prices: Prices}[]}) => {

    const { t } = useTranslation();
    const [sorted, setSorted] = useState<{[key: string]: {product: Product & SellableProduct, price: Price}[]}>({});
    const [features, setFeatures] = useState<Features>([]);

    useEffect(() => {

        let _features: Features = [];

        // Create a map to count occurrences of each feature by its id
        const featureMap: Map<number, { feature: Feature; count: number }> = new Map();

        for (const item of items) {
            for (const feature of item.product.features) {
                const featureEntry = featureMap.get(feature.id);
                if (featureEntry) {
                    featureEntry.count += 1;
                } else {
                    featureMap.set(feature.id, { feature, count: 1 });
                }
            }
        }

        // Convert the map to an array of FeatureCount objects and sort by count in descending order
        _features = Array.from(featureMap.values())
            .sort((a, b) => b.count - a.count)
            .map(({ feature }) => feature); // Extract the features only

        setFeatures(_features);

        let _sorted: { [key: string]: { product: Product & SellableProduct, price: Price }[] } = {}

        for (const item of items) {
            for (const price of item.prices) {
                if (price.recurring){
                    switch (price.recurring.intervalCount){
                        case 1:
                            switch (price.recurring.interval){
                                case "day":
                                    _sorted['daily'] = [...(_sorted.daily || []), {product: item.product, price}]
                                    break;
                                case "week":
                                    _sorted['weekly'] = [...(_sorted.weekly || []), {product: item.product, price}]
                                    break;
                                case "month":
                                    _sorted['monthly'] = [...(_sorted.monthly || []), {product: item.product, price}]
                                    break;
                                case "year":
                                    _sorted['annually'] = [...(_sorted.annually || []), {product: item.product, price}]
                                    break;
                            }
                            break;
                        default:
                            _sorted[`every_${price.recurring.intervalCount}_${price.recurring.interval}`] = [...(_sorted[`every_${price.recurring.intervalCount}_${price.recurring.interval}`] || []), {product: item.product, price}]
                            break;
                    }
                }else{
                    _sorted['oneTime'] = [...(_sorted.oneTime || []), {product: item.product, price}]
                }
            }
        }

        setSorted(_sorted);

    }, [items])


    const DisplayKey = ({value}: {value: string}) => {

        let ret = '';
        const parts = value.split('_');

        for (let i = 0; i < parts.length; i++) {
            ret += ` ${t(parts[i])}`
        }

        return <span className={'first-letter-capitalize'}>{ret}</span>
    }

    return <div className="shadow border border-1">
        <nav className="navbar bg-body-tertiary mb-8">
            <div className="container mx-auto text-center">
                <div className="row align-items-center">
                    <div className="col-auto">
                        <div className="avatar avatar-xl">
                            <img src={getLogoUrl(organization)} alt="Logo" className="avatar-img" />
                        </div>
                    </div>
                    <div className="col">
                        <span className={"fw-semibold"}>
                            {organization.name}
                        </span>
                    </div>
                </div>
            </div>
        </nav>
        <div className="container mx-auto">
            <div className="col-12 mb-8">
                <div className="row justify-content-center">
                    <div className="col-auto">
                        <div className="p-3 rounded bg-light">
                            <ul className="nav nav-pills" id="pricingTab" role="tablist">
                                {Object.keys(sorted).map((key, index) => <li className="nav-item" role="presentation">
                                    <button className={`nav-link ${index === 0 ? 'active' : ''}`} id={`${key}-tab`} data-bs-toggle="tab"
                                            data-bs-target={`#${key}-tab-pane`} type="button" role="tab"
                                            aria-controls={`${key}-tab-pane`} aria-selected="true">
                                        <DisplayKey value={key} />
                                    </button>
                                </li>)}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab-content" id="pricingTabContent">
                {Object.keys(sorted).map((key, index) => <div
                    className={`tab-pane fade show ${index === 0 ? 'active': ''}`}
                    id={`${key}-tab-pane`} role="tabpanel"
                    aria-labelledby={`${key}-tab`}
                    tabIndex={0}
                >
                    <div className="row justify-content-center row-cols row-cols-md-3">
                        {sorted[key].map((item, index) => <div className={"col mb-3"}>
                            <div className="card border border-1 h-100">
                                <div className="card-body">
                                    <h5 className="text-center">
                                        {item.product.name}
                                    </h5>
                                    <h2 className="text-nowrap fw-bold text-center">
                                        {convertToDecimal(item.price.amount, item.price.currency)}
                                    </h2>
                                    {item.price.recurring && <p className="text-nowrap text-muted text-center small">
                                        Tout les {item.price.recurring.intervalCount} {item.price.recurring.interval}
                                    </p>}
                                    <table className="table table-borderless align-middle">
                                        <tbody>
                                        {item.product.features.map(f => <tr>
                                            <th><i className={"bi bi-check text-primary"}></i></th>
                                            <td className={"text-muted small"}>
                                                {f.description}
                                            </td>
                                        </tr>)}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <button type={"button"} className={"btn w-100 btn-primary"}>
                                        {item.price.recurring ? (item.price.recurring.trialPeriodDays > 0 ? "Démarrer l'essai gratuit" : "S'abonner") : 'Commander'}
                                    </button>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>)}
            </div>
        </div>
        {features.length > 0 && <div className="container mx-auto py-8">
            <h2 className={'text-center'}>
                Toutes les fonctionnalités
            </h2>
            <table className={"table align-middle table-striped"}>
                <thead>
                <tr>
                    <th></th>
                    {items.map(item => <th>
                        <h5 className="text-center">
                            {item.product.name}
                        </h5>
                    </th>)}
                </tr>
                </thead>
                <tbody>
                {features.map(feature => <tr>
                    <td className={'fw-semibold'}>{feature.description}</td>
                    {items.map(item => <td
                        className={'text-center'}>{item.product.features.map(f => f.id).includes(feature.id) ?
                        <i className={"bi bi-check fw-semibold text-success fs-3"}></i> :
                        <i className={"bi bi-x fw-semibold text-muted fs-3"}></i>}</td>)}
                </tr>)}
                </tbody>
            </table>
        </div>}
    </div>
}

export default SupplierBundlePreview;