import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";

import * as orderApi from "../../../../../adapters/corporate/supplier/order";
import * as parcelApi from "../../../../../adapters/corporate/supplier/parcel";
import * as returnsApi from "../../../../../adapters/corporate/supplier/returns";
import * as quoteApi from "../../../../../adapters/corporate/supplier/quote";
import {Payload} from "../../../../../models/global";
import {Orders, Parcels} from "../../../../../models/order";
import {XReturns} from "../../../../../models/returns";
import {Quotes} from "../../../../../models/quote";

interface CorporateSupplierNavSalesInterface {
    toggleMenu: (menu: string) => void,
    openMenus: { [key: string]: boolean }
}

const CorporateSupplierNavSales = ({toggleMenu, openMenus}: CorporateSupplierNavSalesInterface) => {

    const [orderPayload, setOrderPayload] = useState<Payload<Orders>>({count: 0, rows: []});
    const [parcelPayload, setParcelPayload] = useState<Payload<Parcels>>({count: 0, rows: []});
    const [returnsPayload, setReturnsPayload] = useState<Payload<XReturns>>({count: 0, rows: []});
    const [quotePayload, setQuotePayload] = useState<Payload<Quotes>>({count: 0, rows: []});

    useEffect(() => {
        orderApi.list({offset: 0, status: "AwaitingConfirmation", count: 0})
            .then(r => setOrderPayload(r.data))
            .catch(e => console.log(e))
    }, [])

    useEffect(() => {
        parcelApi.list({offset: 0, status: "Confirmed", count: 0})
            .then(r => setParcelPayload(r.data))
            .catch(e => console.log(e))
    }, [])

    useEffect(() => {
        returnsApi.list({offset: 0, status: "Pending", count: 0})
            .then(r => setReturnsPayload(r.data))
            .catch(e => console.log(e))
    }, [])

    useEffect(() => {
        quoteApi.list({offset: 0, status: "Pending", count: 0})
            .then(r => setQuotePayload(r.data))
            .catch(e => console.log(e))
    }, [])

    return <li>
        <span className="cursor-pointer nav-link dropdown-toggle" onClick={() => toggleMenu('sales')}>
            Ventes {orderPayload.count + parcelPayload.count + returnsPayload.count + quotePayload.count > 0 && <i className={"red-dot"}></i>}
        </span>
        <div className={`collapse ${openMenus['sales'] ? 'show' : ''}`}>
            <ul className={"list-unstyled"}>
                <li>
                    <Link to={'/corporate/supplier/order'} className="nav-link">
                        <span>
                            Commandes {orderPayload.count > 0 && <i className={"red-dot"}></i>}
                        </span>
                    </Link>
                </li>
                <li>
                    <Link to={'/corporate/supplier/subscription'} className="nav-link">
                        Abonnements
                    </Link>
                </li>
                <li>
                    <Link to={'/corporate/supplier/parcel'} className="nav-link">
                        Colis {parcelPayload.count > 0 && <i className={"red-dot"}></i>}
                    </Link>
                </li>
                <li>
                    <Link to={'/corporate/supplier/returns'} className="nav-link">
                        Retours {returnsPayload.count > 0 && <i className={"red-dot"}></i>}
                    </Link>
                </li>
            </ul>
        </div>
    </li>
}

export default CorporateSupplierNavSales;