import {Quote} from "../../../../../models/quote";
import ProviderQuoteRowList from "../row/list";
import React from "react";
import CommonQuoteSummary from "../../../common/quote/summary";

const ProviderQuoteShow = ({quote}: {quote: Quote}) => {

    return <>
        <div className="row">
            <div className="col-12 mb-3">
                <ProviderQuoteRowList quote={quote} />
            </div>
            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>
                            Récapitulatif du devis
                        </h6>
                    </div>
                    <div className="card-body">
                        <CommonQuoteSummary quote={quote} displayPriceData={true} displayFeeData={true} />
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ProviderQuoteShow;