import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as api from "../../../../../adapters/corporate/supplier/taxRate"
import FormError from "../../../../../utils/formError";
import Loader from "../../../../../utils/loader";
import * as Yup from "yup";
import {Offcanvas} from "bootstrap";
import {getCountries} from "../../../../../utils/countries";
import Select from "../../../../../utils/select";
import {TaxRate} from "../../../../../models/taxRate";
import ErrorCard from "../../../../error";

const SupplierTaxRateAdd = ({handleSuccess}: {handleSuccess: (taxRate: TaxRate) => void}) => {

    const [error, setError] = useState(null);

    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            percentage: 20,
            country: undefined,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            description: Yup.string().required(),
            percentage: Yup.number().required(),
            country: Yup.number().required(),
        }),
        enableReinitialize: true,
        onSubmit: (values: any) => {
            api.add(values)
                .then(r => handleSuccess(r.data))
                .catch(error => setError(error))
                .finally(() => formik.setSubmitting(false))
        }
    });

    return <form onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input className="form-control" type="text" id="name" name={"name"} onChange={formik.handleChange} />
                <label htmlFor="name">
                    Nom *
                </label>
            </div>
            <FormError form={formik} field={"name"} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input className="form-control" type="text" id="description" name={"description"} onChange={formik.handleChange} />
                <label htmlFor="description">
                    Description
                </label>
            </div>
            <FormError form={formik} field={"description"} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input className="form-control" type="number" step={'any'} id="percentage" name={"percentage"} onChange={formik.handleChange} />
                <label htmlFor="description">
                    Pourcentage *
                </label>
            </div>
            <FormError form={formik} field={"percentage"} />
        </div>
        <div className="col-12 mb-3">
            <Select
                label={"Pays *"}
                required={true}
                options={getCountries()}
                onChange={c => formik.setFieldValue("country", c.id)}
            />
        </div>
        {error && <ErrorCard error={error} />}
        <button disabled={formik.isSubmitting} type={'submit'} className={"btn btn-light w-100"}>
            {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-plus-circle'}></i> Ajouter</>}
        </button>
    </form>
}

export default SupplierTaxRateAdd;