import React, {useEffect} from "react";

const Pagination = ({
                        count,
                        offset,
                        setOffset
                    }: {
    count: number,
    offset: number,
    setOffset: (offset: number) => void
}) => {

    const totalPages = Math.ceil(count / 20);

    useEffect(() => {
        // Scroll to the top of the page whenever the offset changes
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [offset]);

    if (totalPages <= 1) {
        return <></>;  // No pagination needed if there’s only one page
    }

    const handlePrevious = () => {
        if (offset > 0) setOffset(offset - 1);
    };

    const handleNext = () => {
        if (offset < totalPages - 1) setOffset(offset + 1);
    };

    const renderPages = () => {
        const startPage = offset > 1 ? offset - 1 : 0;
        const endPage = offset > 1 ? offset + 2 : 3;

        return Array.from(
            { length: totalPages },
            (_, i) => i
        ).slice(startPage, endPage).map(i => (
            <li key={i} className={`page-item ${i === offset ? "active" : ""}`}>
                <button className="page-link" onClick={() => setOffset(i)}>{i + 1}</button>
            </li>
        ));
    };

    return (
        <div className="row justify-content-center">
            <div className="col-auto py-1">
                <ul className="pagination pagination-sm">
                    <li className="page-item">
                        <button
                            disabled={offset === 0}
                            className="page-link page-link-arrow"
                            onClick={handlePrevious}
                        >
                            <i className="bi bi-caret-left"></i>
                        </button>
                    </li>

                    {offset > 1 && (
                        <>
                            <li className={`page-item ${offset === 0 ? "active" : ""}`}>
                                <button className="page-link" onClick={() => setOffset(0)}>1</button>
                            </li>
                            <li className="page-item">
                                <button className="page-link" disabled>...</button>
                            </li>
                        </>
                    )}

                    {renderPages()}

                    {totalPages > 3 && offset < totalPages - 2 && (
                        <>
                            <li className="page-item">
                                <button className="page-link" disabled>...</button>
                            </li>
                            <li className={`page-item ${offset === totalPages - 1 ? "active" : ""}`}>
                                <button className="page-link" onClick={() => setOffset(totalPages - 1)}>{totalPages}</button>
                            </li>
                        </>
                    )}

                    <li className="page-item">
                        <button
                            disabled={offset === totalPages - 1}
                            className="page-link page-link-arrow"
                            onClick={handleNext}
                        >
                            <i className="bi bi-caret-right"></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Pagination;
