import React, {useEffect, useState} from "react";
import * as api from "../../../../adapters/common/paymentMethod";
import Loader from "../../../../utils/loader";
import Pagination from "../../../../utils/pagination";
import {PaymentMethods, PaymentMethod} from "../../../../models/paymentMethod";
import ModalComponent from "../../../modal";
import CommonPaymentMethodAdd from "../add";
import ErrorCard from "../../../error";

const CommonPaymentMethodList = () => {

    const [payload, setPayload] = useState<{ count: number, rows: PaymentMethods }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ offset: number}>({offset: 0})
    const [showAddModal, setShowAddModal] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        api.list({offset: fetchParams.offset})
            .then(resp => setPayload(resp.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    function deactivate(pm: PaymentMethod){
        api.update(pm.id, {activated: false})
            .then(() => setPayload(prev => ({count: prev.count - 1, rows: prev.rows.filter(r => r.id !== pm.id)})))
            .catch(e => setError(e))
    }

    function _default(pm: PaymentMethod, isDefault: boolean){
        api.update(pm.id, {default: isDefault})
            .then(() => setPayload(prev => ({count: prev.count, rows: prev.rows.map(r => r.id !== pm.id ? pm : {...pm, default: isDefault})})))
            .catch(e => setError(e))
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    Mes moyens de paiements
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col-auto">
                    <button className="btn btn-light" onClick={() => setShowAddModal(true)}>
                        Ajouter un moyen de paiement
                    </button>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        {error && <ErrorCard error={error} />}
        <div className="col-md-12 flex-grow-1">
            <table className="table">
                <thead>
                    <tr>
                        <th>Par défaut</th>
                        <th>Moyen de paiement</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {payload.rows.map(r => <tr>
                        <td>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" role="switch" checked={r.default} onChange={e =>_default(r, e.target.checked)} />
                            </div>
                        </td>
                        <td>
                            {r.brand}, **** **** **** {r.last4}, expire en {r.expMonth}/{r.expYear}
                        </td>
                        <td>
                            <button className="btn btn-light btn-xxs" onClick={() => deactivate(r)}>
                                <i className={'bi bi-x text-danger'}></i> Désactiver
                            </button>
                        </td>
                    </tr>)}
                </tbody>
            </table>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        </div>
        <ModalComponent title={"Ajouter un moyen de paiement"} show={showAddModal} onHide={() => setShowAddModal(false)}>
            <CommonPaymentMethodAdd onSuccess={pm => setPayload(prevState => ({count: prevState.count + 1, rows: [pm, ...prevState.rows]}))} />
        </ModalComponent>
    </div>
}

export default CommonPaymentMethodList;