import React, {useEffect, useState} from "react";
import * as api from "../../../../adapters/customer/subscription";
import SearchInput from "../../../../utils/searchInput";
import Loader from "../../../../utils/loader";
import Pagination from "../../../../utils/pagination";
import {Subscriptions} from "../../../../models/subscription";
import ErrorCard from "../../../error";
import CustomerSubscriptionListItem from "./item";

const CustomerSubscriptionList = () => {

    const [payload, setPayload] = useState<{ count: number, rows: Subscriptions }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ offset: number}>({offset: 0})
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true)
        api.list({offset: fetchParams.offset})
            .then(resp => setPayload(resp.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fetchParams])

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        <div className="col-md-12 flex-grow-1">
            {error && <ErrorCard error={error} />}
            <div className="row">
                {payload.rows.length ? payload.rows.map(r => <CustomerSubscriptionListItem subscription={r} key={r.id} />) : <div className={'col-12 text-muted'}>
                    <i className={'bi bi-search'}></i> Aucun résultat
                </div>}
            </div>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        </div>
    </div>
}

export default CustomerSubscriptionList;