import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as api from "../../../../../../adapters/corporate/supplier/product/price"
import * as models from "../../../../../../models/product"
import FormError from "../../../../../../utils/formError";
import Loader from "../../../../../../utils/loader";
import * as Yup from "yup";
import Select from "../../../../../../utils/select";
import {getCurrencies, getCurrencyChoiceById} from "../../../../../../utils/currencies";
import {getDecimalAmount} from "../../../../../../utils/currencyConverter";
import ErrorCard from "../../../../../error";
import {Price} from "../../../../../../models/price";

const SupplierProductPriceAdd = ({product, handleSuccess}: {product: models.Product & models.SellableProduct, handleSuccess: (price: Price) => void}) => {

    const [error, setError] = useState(null)

    const [initialValues, setInitialValues] = useState<any>({
        amount: getDecimalAmount(product.price.amount, product.price.currency),
        currency_id: product.price.currency.id,
        feePercent: product.price.feePercent,
        options: product.price.options.map(option => ({
            amount: option.amount,
            currency_id: option.currency.id
        }))
    });

    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        amount: Yup.number().min(0.01).required(),
        currency_id: Yup.string().required(),
        feePercent: Yup.number().required().min(1).max(100),
        options: Yup.array().of(Yup.object().shape({
            amount: Yup.number().required(),
            currency_id: Yup.number().required()
        }))
    }));

    useEffect(() => {
        if (product.price.recurring){
            setInitialValues((p: any) => ({...p, recurring: {
                interval : product.price.recurring!.interval,
                intervalCount : product.price.recurring!.intervalCount,
                trialPeriodDays : product.price.recurring!.trialPeriodDays,
            }}))

            setValidationSchema((p: any) => Yup.object().shape({...p.fields, recurring: Yup.object().shape({
                    interval: Yup.string().required(),
                    intervalCount: Yup.number().required(),
                    trialPeriodDays: Yup.number().required(),
                })}))
        }
    }, [product.price])

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: values => {
            api.create(product.id, {...values})
                .then(r => handleSuccess(r.data))
                .catch(e => setError(e))
                .finally(() => formik.setSubmitting(false))
        }
    });

    const addOption = () => {
        formik.setFieldValue('options', [
            ...formik.values.options,
            { amount: '', currency_id: '' }
        ]);
    };

    const removeOption = (indexToRemove: number) => {
        const updatedOptions = formik.values.options.filter((option: { amount: number, currency_id: number }, index: number) => index !== indexToRemove);
        formik.setFieldValue('options', updatedOptions);
    };

    return <form onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <div className="input-group">
                <Select
                    required={true}
                    label={"Devise *"}
                    value={{label: product.price.currency.name, value: product.price.currency.id}}
                    options={getCurrencies()}
                    onChange={choice => formik.setFieldValue("currency_id", choice.id)}
                />
                <div className="form-floating">
                    <input defaultValue={getDecimalAmount(product.price.amount, product.price.currency)} disabled={formik.isSubmitting} type="number" step={'any'} className="form-control" id={'amount'} name={'amount'} onChange={formik.handleChange} />
                    <label htmlFor="price">
                        Montant
                    </label>
                </div>
            </div>
            <FormError form={formik} field={'amount'} />
        </div>
        <div className="col-12 mb-3">
            <h6>Autres devises</h6>
            {formik.values.options.map((option: { amount: number, currency_id: number }, index: number) => (
                <div key={index} className="input-group input-group-sm mb-2">
                    <Select
                        required={true}
                        label={"Devise *"}
                        options={getCurrencies()}
                        value={getCurrencyChoiceById(option.currency_id)}
                        onChange={choice => formik.setFieldValue(`options[${index}].currency_id`, choice.id)}
                    />
                    <div className="form-floating">
                        <input
                            disabled={formik.isSubmitting}
                            type="number"
                            step={'any'}
                            className="form-control"
                            id={`options[${index}].amount`}
                            name={`options[${index}].amount`}
                            value={option.amount}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor={`options[${index}].amount`}>Montant</label>
                    </div>
                    <button className={'input-group-text'} type="button" onClick={() => removeOption(index)}>
                        <i className={'bi bi-x'}></i>
                    </button>
                </div>
            ))}
            <button type={'button'} className={"btn btn-xxs text-decoration-underline"} onClick={() => addOption()}>
                <i className={'bi bi-plus-circle'}></i> Ajouter une devise
            </button>
        </div>
        <div className="col-12 mb-3">
            <div className="input-group">
                <div className="form-floating">
                    <input defaultValue={product.price.feePercent} disabled={formik.isSubmitting} type="number" step={'any'} className="form-control" id={'feePercent'} name={'feePercent'} onChange={formik.handleChange} />
                    <label htmlFor="feePercent">
                        Commission
                    </label>
                </div>
                <span className="input-group-text">
                    %
                </span>
            </div>
            <FormError form={formik} field={'feePercent'} />
        </div>
        {product.price.recurring && <>
            <div className="col-12 mb-3">
                <h6>
                    Récurrence
                </h6>
            </div>
            <div className="row gx-0 align-items-center">
                <div className="col mb-3">
                    Une transaction tout les
                </div>
                <div className="col mb-3">
                    <div className="form-floating">
                        <input type="count" id={"recurring.intervalCount"} className="form-control" name={"recurring.intervalCount"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                        <label htmlFor="recurring.intervalCount">
                            Interval
                        </label>
                    </div>
                </div>
                <div className="col mb-3">
                    <Select
                        required={true}
                        label={"Fréquence"}
                        value={{label: "Mois", value: "month"}}
                        options={[
                            {label: "Jour", value: "day"},
                            {label: "7 jours", value: "week"},
                            {label: "Mois", value: "month"},
                            {label: "An", value: "year"},
                        ]}
                        onChange={c => formik.setFieldValue("recurring.interval", c.value)}
                    />
                </div>
                <FormError form={formik} field={"recurring.intervalCount"} />
                <FormError form={formik} field={'recurring.interval'} />
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="count" id={"recurring.trialPeriodDays"} className="form-control" name={"recurring.trialPeriodDays"} onBlur={formik.handleBlur} onChange={formik.handleChange}/>
                    <label htmlFor="recurring.trialPeriodDays">
                        Durée de la période d'éssai
                    </label>
                </div>
                <FormError form={formik} field={"recurring.trialPeriodDays"} />
            </div>
        </>}
        {error && <ErrorCard error={error} />}
        <button disabled={formik.isSubmitting} type={'submit'} className={"btn btn-light w-100"}>
            {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-check'}></i> Valider</>}
        </button>
    </form>
}

export default SupplierProductPriceAdd;