import React, {useRef, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../adapters/corporate/common/post";
import Loader from "../../../../../utils/loader";
import * as models from "../../../../../models/post"

const PostAdd = ({handleSuccess}: {handleSuccess: (post: models.Post) => void}) => {

    const textBox = useRef<HTMLDivElement|null>(null);

    const handleKeyDown = async (evt: React.KeyboardEvent<HTMLParagraphElement>) => {
        if (evt.key === 'Enter') {
            if(!evt.shiftKey){
                await formik.submitForm();
            }
        }
    }

    const formik = useFormik({
        initialValues: {
            content: '',
            visibility: 'Public',
            files: [],
            products: [],
        } as {content: string, visibility: string, files: File[], products: number[]},
        validationSchema: Yup.object().shape({
            content: Yup.string().required(),
            visibility: Yup.string().required(),
            files: Yup.array()
                .of(
                    Yup.mixed()
                        .test(
                            'fileSize',
                            'The file is too large',
                            (value: any) => !value || (value && value.size <= 1024 * 1024 * 5)
                        )
                        .test(
                            'fileFormat',
                            'Unsupported file format',
                            (value: any) => !value || (value && ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(value.type))
                        )
                )
                .max(4, 'You can only upload up to 4 files.'),
            products: Yup.array().of(Yup.number()).min(0),
        }),
        onSubmit: (values, {setSubmitting, resetForm}) => {

            let formData = new FormData();
            formData.append("content", values.content)
            formData.append("visibility", values.visibility)

            if (values.files.length){
                for (const fKey in values.files) {
                    formData.append("files", values.files[fKey], values.files[fKey].name)
                }
            }

            if (values.products.length){
                for (const pKey in values.products) {
                    formData.append("products", String(values.products[pKey]))
                }
            }

            api.add(formData)
                .then(r => {
                    handleSuccess(r.data)
                    if (textBox.current){
                        textBox.current.innerText = ""
                    }

                    resetForm();
                })
                .finally(() => {
                    setSubmitting(false)
                })
        }
    });

    return <div className={"col-12 mb-3"}>
        <form onSubmit={formik.handleSubmit}>
            <div className="form-floating mb-2">
                <div className="col position-relative bg-white border border-1 p-3 rounded-5">
                    <p ref={textBox} className="flex-grow-1 custom-text-area mb-0"
                       style={{minHeight: 30}}
                       contentEditable={!formik.isSubmitting}
                       onKeyDown={handleKeyDown}
                       onInput={e => formik.setFieldValue("content", e.currentTarget.innerText)}
                       onBlur={e => formik.setFieldValue("content", e.currentTarget.innerText)}>
                    </p>
                    {!formik.values.content.length && <div onMouseDown={() => textBox.current?.focus()} className="position-absolute p-3 text-muted start-0 top-0">
                        Quoi de neuf ?
                    </div>}
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col">
                    <div className="btn-group ps-5">
                        {/*<Dropzone accept={{*/}
                        {/*    'image/*': ['.jpg', '.jpeg', '.png', '.tif'],*/}
                        {/*    'video/*': ['.mp4', '.mov'],*/}
                        {/*}} disabled={formik.isSubmitting} onDrop={acceptedFiles => formik.setFieldValue('files', [...formik.values.files, ...acceptedFiles])}>*/}
                        {/*    {({getRootProps, getInputProps}) => (*/}
                        {/*        <button type={"button"} className="btn btn-xxs rounded-pill btn-light me-2" {...getRootProps()}>*/}
                        {/*            <input {...getInputProps()} className={'d-none'} />*/}
                        {/*            <i className={'bi bi-image'}></i>*/}
                        {/*        </button>*/}
                        {/*    )}*/}
                        {/*</Dropzone>*/}
                        <div className="dropdown">
                            <button className="btn btn-xxs rounded-pill btn-light me-2 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {formik.values.visibility === "Public" && <><i className="bi bi-globe-americas"></i> Tout le monde</>}
                                {formik.values.visibility === "Private" && <><i className="bi bi-globe-americas"></i> Partenaires seulement</>}
                            </button>
                            <ul className="dropdown-menu">
                                <li className={"dropdown-item"} onClick={() => formik.setFieldValue("visibility", "Public")}>
                                    Tout le monde
                                </li>
                                <li className={"dropdown-item"} onClick={() => formik.setFieldValue("visibility", "Private")}>
                                    Partenaires seulement
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-auto">
                    <button type={"submit"} disabled={!formik.isValid || formik.isSubmitting} className={'btn btn-xxs btn-dark rounded-pill'}>
                        {formik.isSubmitting ? <Loader size={"sm"} /> : <><i className={'bi bi-send'}></i> Publier</>}
                    </button>
                </div>
            </div>
        </form>
    </div>
}

export default PostAdd;