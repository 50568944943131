import {Link, Navigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Addresses from "./address";
import Delivery from "./delivery";
import Payment from "./payment";
import Summary from "./summary";
import CheckoutItemOffcanvas from "./item/offcanvas";
import * as api from "../../adapters/checkout";
import Loader from "../../utils/loader";
import CheckoutNavHeader from "./nav/header";
import CheckoutNavFooter from "./nav/footer";
import * as models from "../../models/checkout";
import ErrorCard from "../error";

const Checkout = ({tab}: {tab: "address"|"delivery"|"payment"}) => {

    const {uuid} = useParams();
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState(null);
    const [checkout, setCheckout] = useState<models.CheckoutResponse>();

    useEffect(() => {
        if (uuid){
            setLoading(true)
            api.show(uuid)
                .then(r => setCheckout(r.data))
                .catch(e => setError(e))
                .finally(() => setLoading(false))
        }
    }, [uuid])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!checkout)
    {
        return <div className={"container-fluid py-10 text-center fs-3"}>
            <i className={'bi bi-exclamation-triangle'}></i> Commande introuvable.
        </div>
    }



    return <div className={"container-fluid h-100 d-flex flex-column px-0"}>
        <CheckoutNavHeader checkout={checkout} />
        <div className="container mx-auto flex-grow-1">
            <div className={"row py-3"}>
                <div className="col-lg-9 mb-3">
                    <div className={"col-12 mb-3" + (tab === 'address' ? " text-dark " : " text-secondary ")} id={"address"}>
                        <div className="col-12 mb-3">
                            {["delivery", "payment"].includes(tab) ? <Link className={'h6'} to={`/checkout/${checkout.uuid}/address`} >
                                Adresse
                            </Link> : <h6>Adresse</h6>}
                        </div>
                        {tab === "address" && <Addresses checkout={checkout} setCheckout={setCheckout} />}
                    </div>
                    <hr/>
                    {checkout.shippable && <>
                        <div className={"col-12 mb-3" + (tab === 'delivery' ? " text-dark " : " text-secondary ")} id={"delivery"}>
                            <div className="col-12 mb-3">
                                {tab === "payment" ? <Link className={'h6'} to={`/checkout/${checkout.uuid}/delivery`}>
                                    Options de livraison
                                </Link> : <h6>Options de livraison</h6>}
                            </div>
                            {tab === "delivery" && <Delivery checkout={checkout} setCheckout={setCheckout} />}
                        </div>
                        <hr/>
                    </>}
                    <div className={"col-12 mb-3" + (tab === 'payment' ? " text-dark " : " text-secondary ")} id={"payment"}>
                        <div className="col-12 mb-3">
                            <h6 className={'h6'} >
                                Mode de paiement
                            </h6>
                        </div>
                        {tab === "payment" && <Payment checkout={checkout} setCheckout={setCheckout} />}
                    </div>
                </div>
                <Summary checkout={checkout} />
            </div>
            <CheckoutItemOffcanvas checkout={checkout} />
        </div>
        <CheckoutNavFooter />
    </div>
}

export default Checkout;