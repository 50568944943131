import React from "react";
import SupplierAssignmentList from "../../../../../components/corporate/supplier/assignment/list";

const SupplierAssignmentListPage = () => {

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    Annonces
                </li>
            </ol>
        </div>
        <SupplierAssignmentList />
    </div>
}

export default SupplierAssignmentListPage;