import {Link} from "react-router-dom";
import React from "react";
import * as models from "../../../../../../models/chat";
import ChatShowHeaderPrivate from "./private";
import ChatShowGroup from "./group";

const ChatShowHeader = ({chat}: {chat: models.Chat}) => {

    return <div className="card-header">
        <div className="row align-items-center">
            <div className="col-auto d-block d-md-none">
                <Link to={"/corporate/chat"} className="btn btn-xxs">
                    <i className={'bi bi-chevron-left'}></i>
                </Link>
            </div>
            <div className="col">
                {chat.type === "group" && <ChatShowGroup chat={chat} />}
                {chat.type === "private" && <ChatShowHeaderPrivate chat={chat} />}
            </div>
        </div>
    </div>
}

export default ChatShowHeader;