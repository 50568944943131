import {get, post} from "../../../../xhr";
import encodeQueryData from "../../../../../utils/encodeQueryData";

export function list(quoteId: number){
    return get(`/corporate/supplier/quote/${quoteId}/row`);
}

export function show(quoteId: number, id: number){
    return get(`/corporate/supplier/quote/${quoteId}/row/${id}`);
}

export function update(quoteId: number, id: number, requestData: {name?: string}){
    return post(`/corporate/supplier/quote/${quoteId}/row/${id}`, requestData);
}

export function add(quoteId: number, requestData: {name: string}){
    return post(`/corporate/supplier/quote/${quoteId}/row`, requestData);
}