import React, {useEffect, useState} from "react";
import * as api from '../../../../../adapters/corporate/provider/order';
import SearchInput from "../../../../../utils/searchInput";
import * as models from "../../../../../models/order";
import Loader from "../../../../../utils/loader";
import {useNavigate} from "react-router-dom";
import {getLogoUrl} from "../../../../../utils/image";
import Pagination from "../../../../../utils/pagination";
import OrderStatusPlaceholder from "../../../../common/order/status/placeholder";
import convertToDecimal from "../../../../../utils/currencyConverter";

const ProviderOrderList = () => {

    const [payload, setPayload] = useState<{ count: number, rows: models.Orders }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number, status: "All"|"AwaitingConfirmation"|"Processing"|"Completed"|"Canceled"}>({query: "", offset: 0, status: "All"})
    const navigate = useNavigate()
    const status_array: ("All"|"AwaitingConfirmation"|"Processing"|"Completed"|"Canceled")[] = ["All", "AwaitingConfirmation","Processing","Completed","Canceled"];

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(resp => setPayload(resp.data))
            .then(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams(p => ({...p, query: e.target.value, offset: 0})), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    Commandes
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col-12 mb-3">
                    <SearchInput placeholder={"Rechercher une commande..."} onChange={handleQueryChange} size={"default"} />
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-auto">
                            <div className="dropdown">
                                <button className="btn btn-light btn-xxs dropdown-toggle" type="button"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                    Statut: <OrderStatusPlaceholder status={fetchParams.status} />
                                </button>
                                <ul className="dropdown-menu">
                                    {status_array.filter(s => s !== fetchParams.status).map((s) => <li>
                                        <span className="dropdown-item cursor-pointer" onClick={() => setFetchParams(prev => ({...prev, status: s}))}>
                                            <OrderStatusPlaceholder status={s} />
                                        </span>
                                    </li>)}
                                </ul>
                            </div>
                        </div>
                        <div className="col-auto">
                            <span className="text-muted">
                                {loading ? <Loader size={"sm"} /> : <>{payload.count} résultats</>}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-12 flex-grow-1">
            <div className={'table-responsive'}>
                <table className={'table table-hover'}>
                    <thead>
                        <tr>
                            <th>
                                Numéro
                            </th>
                            <th>
                                Fournisseur
                            </th>
                            <th>
                                Pays
                            </th>
                            <th>
                                Commission
                            </th>
                            <th>
                                Statut
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {payload.rows.map(s => <tr onClick={() => navigate(`${s.id}`)}>
                            <td>
                                #{s.number}
                            </td>
                            <td>
                                <div className="avatar avatar-sm me-2">
                                    <img src={getLogoUrl(s.partnership.supplier)} alt={s.partnership.supplier.name} className="avatar-img rounded-circle" />
                                </div>
                                {s.partnership.supplier.name}
                            </td>
                            <td>
                                <img style={{height: 18, width: "auto"}} src={s.billing.country.flag} alt={s.billing.country.name}/> {s.billing.country.name}
                            </td>
                            <td>
                                {convertToDecimal(s.feeData.provider, s.checkout.currency)}
                            </td>
                            <td>
                                <OrderStatusPlaceholder status={s.status} />
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(prev => ({...prev, offset: o}))} />
        </div>
    </div>
}

export default ProviderOrderList;