import {get} from "../../xhr";
import encodeQueryData from "../../../utils/encodeQueryData";

export function list(requestData: {query: string, offset: number})
{
    return get(`customer/order${encodeQueryData(requestData)}`)
}

export function show(order: number)
{
    return get(`customer/order/${order}`)
}