import {useFormik} from "formik";
import React, {useState} from "react";
import * as Yup from "yup";
import Select from "../../../../../utils/select";
import {sectors} from "../../../../../utils/sectors";
import FormError from "../../../../../utils/formError";
import {useNavigate} from "react-router-dom";
import * as api from "../../../../../adapters/corporate/provider/project"
import Loader from "../../../../../utils/loader";
import {getCurrencies} from "../../../../../utils/currencies";
import {format} from "date-fns";
import {useQuery} from "../../../../../utils/useQuery";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {getCountries} from "../../../../../utils/countries";

const ProviderProjectAdd = () => {

    const navigate = useNavigate();
    const queryParams = useQuery();

    const [initialValues, setInitialValues] = useState<any>({
        title: "",
        description: "",
        visibility: "Public",
        status: "Published",
        sector_id: null,
        currency_id: null,
        country_id: null,
        city: "",
        postalCode: "",
        fee: 10,
        condition: {
            launch: "ASAP",
            estimateAmount: null
        }
    });

    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        title: Yup.string().required(),
        description: Yup.string().required().min(200).max(10000),
        visibility: Yup.string().required(),
        sector_id: Yup.number().required(),
        currency_id: Yup.number().required(),
        country_id: Yup.number().required(),
        city: Yup.string().required(),
        postalCode: Yup.string().required(),
        fee: Yup.number().required().min(1).max(99),
        condition: Yup.object().shape({
            launch: Yup.string().required(),
            estimateAmount: Yup.number().nullable()
        })
    }));

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: initialValues,
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            api.add(values)
                .then(r => navigate(`/corporate/provider/project/${r.data.id}`))
                .catch(error => console.log(error))
                .finally(() => formik.setSubmitting(false))
        }
    });

    function handleConditionLaunchChange(value: "ASAP"|"DATE"|"IDK"){

        let condition_values: any = {};

        condition_values.launch = value;
        condition_values.estimateAmount = formik.values.condition.estimateAmount

        let condition_schema: {launch: any, estimateAmount: any, launchAt?: any, isFlexible?: any} = {
            launch: Yup.string().required(),
            estimateAmount: Yup.number().nullable()
        }

        if (value === "DATE"){
            condition_values.launchAt = new Date();
            condition_values.isFlexible = false;

           condition_schema.launchAt = Yup.date().required()
           condition_schema.isFlexible = Yup.boolean().required()

        }

        setInitialValues({...formik.values, condition: condition_values});
        setValidationSchema(Yup.object().shape({
            title: Yup.string().required(),
            description: Yup.string().required().min(200).max(10000),
            visibility: Yup.string().required(),
            sector_id: Yup.number().required(),
            currency_id: Yup.number().required(),
            country_id: Yup.number().required(),
            city: Yup.string().required(),
            postalCode: Yup.string().required(),
            fee: Yup.number().required().min(1).max(99),
            status: Yup.string().required(),
            condition: Yup.object().shape(condition_schema)
        }));
    }

    function handleConditionIsFlexibleChange(isFlexible: boolean){

        let condition_values: { launch: string, estimateAmount: number | null, launchAt: Date, isFlexible: boolean, flexibleType?: string } = {
            launch: formik.values.condition.launch,
            estimateAmount: formik.values.condition.estimateAmount,
            launchAt: formik.values.condition.launchAt,
            isFlexible: isFlexible
        };

        let condition_schema: {launch: any, estimateAmount: any, launchAt: any, isFlexible: any, flexibleType?: any} = {
            launch: Yup.string().required(),
            estimateAmount: Yup.number().nullable(),
            launchAt: Yup.date().required(),
            isFlexible: Yup.boolean().required()
        }

        if (isFlexible){
            condition_values.flexibleType = "day";
            condition_schema.flexibleType = Yup.string().required()
       }

        setInitialValues({...formik.values, condition: condition_values});
        setValidationSchema(Yup.object().shape({
            title: Yup.string().required(),
            description: Yup.string().required().min(200).max(10000),
            visibility: Yup.string().required(),
            sector_id: Yup.number().required(),
            currency_id: Yup.number().required(),
            country_id: Yup.number().required(),
            city: Yup.string().required(),
            postalCode: Yup.string().required(),
            fee: Yup.number().required().min(1).max(99),
            status: Yup.string().required(),
            condition: Yup.object().shape(condition_schema)
        }));
    }

    return <div className={"container h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    Appel d'offre
                </li>
                <li className="breadcrumb-item">
                    Ajouter
                </li>
            </ol>
        </div>
        <form onSubmit={formik.handleSubmit} className={"mb-3"}>
            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>
                            Informations sur le projet
                        </h6>
                    </div>
                    <div className="card-body">
                        <div className="col-12 mb-3">
                            <div className="form-floating">
                                <input type="text" className="form-control" id="title" name={'title'} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                <label htmlFor="title">
                                    Titre *
                                </label>
                            </div>
                            <FormError form={formik} field={"title"} />
                        </div>
                        <div className="col-12 mb-3" style={{minHeight: 400}}>
                            <ReactQuill className={"mb-3"} style={{height: 390}} value={formik.values.description} onChange={value => formik.setFieldValue('description', value)} />
                            <FormError form={formik} field={"description"} />
                        </div>
                        <div className="col-12 mb-3">
                            <Select
                                label={"Sécteur d'activité"}
                                disabled={formik.isSubmitting}
                                placeholder={"Séléctionnez un secteur d'activité correspondant au projet"}
                                options={sectors.map(s => ({label: s.name, value: s.id}))}
                                multiple={false}
                                required={true}
                                onChange={choices => formik.setFieldValue('sector_id', choices.value)}
                            />
                            <FormError form={formik} field={"sector_id"} />
                        </div>
                        <div className="col-12 mb-3">
                            <Select
                                label={"Devise"}
                                disabled={formik.isSubmitting}
                                placeholder={"Séléctionnez la devise du client finale"}
                                options={getCurrencies()}
                                multiple={false}
                                required={true}
                                onChange={choices => formik.setFieldValue('currency_id', choices.id)}
                            />
                            <FormError form={formik} field={"currency_id"} />
                        </div>
                        <div className="col-12 mb-3">
                            <Select
                                label={"Visibilité"}
                                disabled={formik.isSubmitting}
                                options={[
                                    {label: "Tout le monde", value: "Public"},
                                    {label: "Partenaires seulement", value: "Private"}
                                ]}
                                value={{label: "Partenaires seulement", value: "Private"}}
                                required={true}
                                onChange={choices => formik.setFieldValue('visibility', choices.value)}
                            />
                            <FormError form={formik} field={"visibility"} />
                        </div>
                        <div className="col-12 mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="number" className="form-control" id="fee" name={'fee'} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                    <label htmlFor="fee">
                                        Votre pourcentage commission *
                                    </label>
                                </div>
                                <span className="input-group-text">
                                    <i className={'bi bi-percent'}></i>
                                </span>
                            </div>
                            <FormError form={formik} field={"fee"} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>
                            Lieu de la prestation
                        </h6>
                    </div>
                    <div className="card-body">
                        <div className="col-12 mb-3">
                            <Select label={"Pays *"} placeholder={"Séléctionner un pays"} options={getCountries()} required={true} onChange={c => formik.setFieldValue('country_id', c.id)} />
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="city" name={'city'} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                    <label htmlFor="city">
                                        Ville
                                    </label>
                                </div>
                                <FormError form={formik} field={"city"} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="postalCode" name={'postalCode'} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                    <label htmlFor="city">
                                        Code postal
                                    </label>
                                </div>
                                <FormError form={formik} field={"postalCode"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>
                            Lancement prévu du projet
                        </h6>
                    </div>
                    <div className="card-body">
                        <div className="col-12 mb-3">
                            <div className={"card border border-1 " + (formik.values.condition.launch === "ASAP" ? " border-success shadow " : "")}>
                                <div className="card-body">
                                    <div className="form-check">
                                        <input onChange={() => handleConditionLaunchChange("ASAP")} className="form-check-input" type="radio" name="launch" value={"ASAP"} id="launch_asap" defaultChecked={true} />
                                        <label className="form-check-label" htmlFor="launch_asap">
                                            Dès que possible
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <div className={"card border border-1 " + (formik.values.condition.launch === "DATE" ? " border-success shadow " : "")}>
                                <div className="card-body">
                                    <div className="form-check">
                                        <input onChange={() => handleConditionLaunchChange("DATE")} className="form-check-input" type="radio" name="launch" value={"DATE"} id="launch_date" />
                                        <label className="form-check-label" htmlFor="launch_date">
                                            A une date donnée <span className="form-text">(Fixe ou flexible)</span>
                                        </label>
                                    </div>
                                    {formik.values.condition.launch === "DATE" && <>
                                        <div className={'col-12 my-3'}>
                                            <div className="form-floating">
                                                <input type="date" defaultValue={format(formik.values.condition.launchAt, "uuuu-MM-dd")} className={"form-control"} id={"condition.launchAt"} name={"condition.launchAt"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                <label htmlFor={"condition.launchAt"}>
                                                    Date de lancement
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" defaultChecked={false} id="condition.isFlexible" name={"condition.isFlexible"} onChange={e => handleConditionIsFlexibleChange(e.target.checked)} />
                                                        <label className="form-check-label" htmlFor="condition.isFlexible">
                                                            Je suis flexible
                                                        </label>
                                                    </div>
                                                </div>
                                                {formik.values.condition.isFlexible === true && <div className={"col"}>
                                                    <div className="btn-group">
                                                        <button type={'button'} className={'btn ' + (formik.values.condition.flexibleType === "day" ? " btn-primary " : " btn-light " )} onClick={() => formik.setFieldValue('condition.flexibleType', "day")}>
                                                            +/- 2 jours
                                                        </button>
                                                        <button type={'button'} className={'btn ' + (formik.values.condition.flexibleType === "week" ? " btn-primary " : " btn-light " )} onClick={() => formik.setFieldValue('condition.flexibleType', "week")}>
                                                            +/- 1 semaine
                                                        </button>
                                                        <button type={'button'} className={'btn ' + (formik.values.condition.flexibleType === "month" ? " btn-primary " : " btn-light " )} onClick={() => formik.setFieldValue('condition.flexibleType', "month")}>
                                                            +/- 1 mois
                                                        </button>
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </>}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <div className={"card border border-1 " + (formik.values.condition.launch === "IDK" ? " border-success shadow " : "")}>
                                <div className="card-body">
                                    <div className="form-check">
                                        <input onChange={() => handleConditionLaunchChange("IDK")} className="form-check-input" type="radio" name="launch" value={"IDK"} id="launch_idk" />
                                        <label className="form-check-label" htmlFor="launch_idk">
                                            Je ne sais pas
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>
                            Statut du projet
                        </h6>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <div className={"card border border-1 " + (formik.values.status === "Published" ? " border-success shadow " : "")}>
                                    <div className="card-body">
                                        <div className="form-check">
                                            <input onChange={formik.handleChange} defaultChecked={true} className="form-check-input" type="radio" name="status" value={"Published"} id="status_published" />
                                            <label className="form-check-label" htmlFor="status_published">
                                                Publié
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className={"card border border-1 " + (formik.values.status === "Pending" ? " border-success shadow " : "")}>
                                    <div className="card-body">
                                        <div className="form-check">
                                            <input onChange={formik.handleChange} className="form-check-input" type="radio" name="status" value={"Pending"} id="status_pending" />
                                            <label className="form-check-label" htmlFor="status_pending">
                                                Brouillon
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button type={"submit"} className={"btn btn-primary w-100"}>
                {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-check'}></i> Enregistrer</>}
            </button>
        </form>
    </div>
}

export default ProviderProjectAdd;