import React, {useEffect, useState} from "react";
import * as api from '../../../../../adapters/corporate/supplier/unit';
import SearchInput from "../../../../../utils/searchInput";
import {ProductUnits} from "../../../../../models/product";
import Loader from "../../../../../utils/loader";
import Pagination from "../../../../../utils/pagination";
import SupplierUnitAdd from "../add";
import ModalComponent from "../../../../modal";

const SupplierUnitList = () => {

    const [payload, setPayload] = useState<{ count: number, rows: ProductUnits }>({count: 0, rows: []});
    const [loading, setLoading] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [fetchParams, setFetchParams] = useState<{ query: string, offset: number}>({query: "", offset: 0})

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(resp => setPayload(resp.data))
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }, [fetchParams])

    let timer: NodeJS.Timeout;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>){
        clearTimeout(timer)
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1000)
    }

    return <div className={"container-fluid h-100 d-flex flex-column"}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <SearchInput placeholder={"Rechercher une unité de mesure..."} onChange={handleQueryChange} size={"default"} />
                </div>
                <div className="col-auto">
                    <button className={'btn btn-primary rounded btn-xs'}
                            onClick={() => setShowModal(true)}
                    >
                        <i className={'bi bi-plus-circle'}></i> Ajouter
                    </button>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            {loading ? <Loader /> : <>{payload.count} résultats</>}
        </div>
        <div className="col-md-12 flex-grow-1">
            {!loading && <div className={'table-responsive'}>
                <table className={'table'}>
                    <thead>
                        <tr>
                            <th>
                                Label
                            </th>
                            <th>
                                Arrondi
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {payload.rows.map(item => <tr>
                            <td>
                                {item.label}
                            </td>
                            <td>
                                {item.step}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>}
            <Pagination count={payload.count} offset={fetchParams.offset} setOffset={o => setFetchParams(p => ({...p, offset: o}))} />
        </div>
        <ModalComponent title={"Ajouter une unité"} show={showModal} onHide={() => setShowModal(false)}>
            <SupplierUnitAdd handleSuccess={unit => {
                setPayload(prev => ({count: prev.count + 1, rows: [unit, ...prev.rows]}))
                setShowModal(false)
            }} />
        </ModalComponent>
    </div>
}

export default SupplierUnitList;