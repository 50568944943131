import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Shipping} from "../../../../../models/shipping";
import * as api from "../../../../../adapters/corporate/supplier/shipping";
import Loader from "../../../../../utils/loader";
import SupplierShippingAddress from "./address";
import SupplierShippingAreaList from "./area/list";

const SupplierShippingShow = ({tab}: {tab: "address"|"area"}) => {

    const {id} = useParams();
    const [shipping, setShipping] = useState<Shipping>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true)
        api.show(Number(id))
            .then(r => setShipping(r.data))
            .then(() => setLoading(false))
    }, [id])

    if (loading){
        return <div className={'container-fluid py-10 text-center'}>
            <Loader />
        </div>
    }

    if (!shipping){
        return <div className={'container-fluid py-10 text-center'}>
            <span className={'display-6'}>
                <i className={"bi bi-search"}></i> Profil d'expédition non trouvé
            </span>
        </div>
    }

    return <div className={'container-fluid'}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/corporate/supplier/shipping">Profil d'expédition</Link>
                </li>
                <li className="breadcrumb-item">
                    {shipping.name}
                </li>
            </ol>
        </div>
        <div className="row justify-content-center">
            <div className="col">
                <div className="mb-2 mb-lg-0 border-bottom-lg">
                    <nav className="nav nav-tabs nav-overflow fs-xs border-bottom border-bottom-lg-0">
                        <Link to={`/corporate/supplier/shipping/${shipping.id}/area`} className={"nav-link text-uppercase" + (tab === "area" ? " active " : "")}>
                            Zones d'expéditions
                        </Link>
                        <Link to={`/corporate/supplier/shipping/${shipping.id}/address`} className={"nav-link text-uppercase" + (tab === "address" ? " active " : "")}>
                            Lieux de traitements
                        </Link>
                    </nav>
                </div>
            </div>
        </div>
        <div className="col-12">
            {tab === "area" && <SupplierShippingAreaList shipping={shipping} />}
            {tab === "address" && <SupplierShippingAddress shipping={shipping} setShipping={setShipping} />}
        </div>
    </div>
}

export default SupplierShippingShow;