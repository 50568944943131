import React, {useState} from "react";
import * as api from "../../../../../../adapters/corporate/supplier/product/file";
import * as models from "../../../../../../models/product";
import ProductFileAdd from "../../../product/file/add";
import {S3_BUCKET_URL, S3_PRODUCT_BUCKET} from "../../../../../../constants/global";

const SupplierProductFileShow = ({product, setProduct}: {product: models.Product, setProduct: React.Dispatch<any>}) => {

    function remove(id: number)
    {
        api.remove(product.id, id)
            .then(() => setProduct((prev: models.Product) => ({...prev, files: prev.files.filter(f => f.id !== id)})))
    }

    function cover(id: number)
    {
        api.cover(product.id, id)
            .then(r => setProduct((prev: models.Product) => ({...prev, files: prev.files.map(f => f.id === r.data.id ? r.data : ({...f, cover: false}))})))
    }

    return <div className="col-lg-12 mb-3" id={'files'}>
        <div className="card border border-1">
            <div className="card-header">
                <div className="row">
                    <div className="col">
                        <h6>
                            Images
                        </h6>
                    </div>
                    <div className="col-auto">

                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="col-12 mb-3 flex-grow-0">
                    <ProductFileAdd product={product} setProduct={setProduct} />
                </div>
                <div className="flex-grow-1 overflow-auto">
                    <div className="row gx-0">
                        {product.files.map(file => <div className="col-6 col-md-4 col-lg-3 mb-3 p-1 position-relative">
                            <div className="btn-group position-absolute top-0 end-0 p-2">
                                <button className="btn btn-xs rounded-pill btn-light me-2" onClick={() => cover(file.id)}>
                                    <i className={file.cover ? 'bi bi-star-fill text-primary' : 'bi bi-star'}></i> {file.cover && 'Couverture'}
                                </button>
                                <button className="btn btn-xs rounded-pill btn-light" onClick={() => remove(file.id)}>
                                    <i className={'bi bi-dash-circle text-danger'}></i>
                                </button>
                            </div>
                            <img className={'img-fluid'} src={`${S3_BUCKET_URL}${S3_PRODUCT_BUCKET}${product.id}/${file.name}`} alt={file.name}/>
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default SupplierProductFileShow;