import React, {useEffect, useState} from "react";
import {Quote} from "../../../../../models/quote";
import {getLogoUrl} from "../../../../../utils/image";
import ProjectLaunch from "../../../common/project/launch";
import convertToDecimal from "../../../../../utils/currencyConverter";
import Loader from "../../../../../utils/loader";
import {Link, useParams} from "react-router-dom";
import * as api from "../../../../../adapters/corporate/supplier/quote"
import SupplierQuoteRowList from "../row/list";
import QuoteStatusPlaceholder from "../../../common/quote/status";
import QuoteConditionShow from "../condition/show";
import ModalComponent from "../../../../modal";
import ErrorCard from "../../../../error";
import CommonTransferList from "../../../common/transfer/list";

const SupplierQuoteShow = () => {

    const {id} = useParams();
    const [loading, setLoading] = useState(true);
    const [quote, setQuote] = useState<Quote>();
    const [publishing, setPublishing] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true)
        api.show(Number(id))
            .then(r => setQuote(r.data))
            .catch(error => setError(error))
            .finally(() => setLoading(false))
    }, [id])

    function updateStatus(status: "Pending"|"Published"|"Canceled"|"Validation"){
        setPublishing(true);
        api.update(Number(id), {status: status})
            .then(r => setQuote({...quote, ...r.data}))
            .catch(error => setError(error))
            .finally(() => setPublishing(false))
    }

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!quote){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <h6>
                   <i className={"bi bi-exclamation-triangle"}></i> Devis introuvable
                </h6>
            </div>
        </div>
    }

    return <div className={"container-fluid"}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <ol className="breadcrumb mb-3 fs-xs">
                        <li className="breadcrumb-item">
                            <Link to="/corporate/supplier/quote">Devis</Link>
                        </li>
                        <li className="breadcrumb-item">
                            {quote.number}
                        </li>
                    </ol>
                </div>
                <div className="col-auto ms-auto">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <QuoteStatusPlaceholder status={quote.status} />
                        </div>
                        {quote.status === "Pending" && <div className="col-auto">
                            <button disabled={publishing} className={'btn btn-xxs btn-light'} onClick={() => updateStatus("Published")}>
                                Publier <i className={'bi bi-arrow-right'}></i>
                            </button>
                        </div>}
                        {quote.status === "Published" && <div className="col-auto">
                            <button disabled={publishing} className={'btn btn-xxs btn-light'} onClick={() => updateStatus("Pending")}>
                                Repasser en brouillon <i className={'bi bi-arrow-right'}></i>
                            </button>
                        </div>}
                        {["Pending", "Published", "Refused"].includes(quote.status) && <div className="col-auto">
                            <button className="btn btn-xxs btn-light" onClick={() => updateStatus("Canceled")}>
                                <i className={'bi bi-x text-danger'}></i> Annuler
                            </button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        {quote.status === "Accepted" && <div className={'col-12 mb-3'}>
            <div className="card border border-success border-1">
                <div className="card-body">
                    <h6>
                        Votre devis est accepté !
                    </h6>
                    <p>
                        Une fois la prestation terminé, envoyez pour validation au client pour percevoir le paiement final.
                    </p>
                    <button type={"button"} className={"btn btn-primary"} onClick={() => updateStatus("Validation")}>
                        <i className={'bi bi-send'}></i> Envoyer au client pour validation.
                    </button>
                </div>
            </div>
        </div>}
        {quote.status === "Validation" && <div className={'col-12 mb-3'}>
            <div className="card border border-warning border-1">
                <div className="card-body">
                    <h6>
                        Validation client en attente.
                    </h6>
                    <p>
                        Vous avez renseigné avoir terminé la prestation. <br/>
                        Le paiement sera débloqué une fois que le client aura validé.
                    </p>
                </div>
            </div>
        </div>}
        <div className="row">
            <div className="col-md-3 mb-3">
                <div className="card border border-1 h-100">
                    <div className="card-header">
                        <h6>
                            {quote.project.title}
                        </h6>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-text">
                                    Apporteur
                                </div>
                                <p>
                                    <span className="avatar avatar-sm me-2">
                                        <img src={getLogoUrl(quote.project.organization)} alt={quote.project.organization.name} className="avatar-img rounded-circle" />
                                    </span>
                                    <Link to={`/corporate/organization/${quote.project.organization.id}`} target={"_blank"}>
                                        {quote.project.organization.name}
                                    </Link>
                                </p>
                            </div>
                            <div className="col-12">
                                <div className="form-text">
                                    Sécteur
                                </div>
                                <p>
                                    <span>
                                        {quote.project.sector.name}
                                    </span>
                                </p>
                            </div>
                            <div className="col-12">
                                <div className="form-text">
                                    Démarrage
                                </div>
                                <p>
                                    <ProjectLaunch p={quote.project} />
                                </p>
                            </div>
                            <div className="col-12">
                                <div className="form-text">
                                    Localisation
                                </div>
                                <p>
                                    {quote.project.city}, {quote.project.postalCode}, {quote.project.country.name}
                                </p>
                            </div>
                            <div className="col-12">
                                <div className="form-text">
                                    Budget
                                </div>
                                <p>
                                    {convertToDecimal(quote.project.condition.estimateAmount, quote.project.currency)}
                                </p>
                            </div>
                            <div className="col-12">
                                <div className="form-text">
                                    Commission demandé
                                </div>
                                <p>
                                    {quote.project.fee}%
                                </p>
                            </div>
                            <div className="col-12">
                                <div className="form-text">
                                    Devise
                                </div>
                                <p>
                                    {quote.currency.symbol} ({quote.currency.name})
                                </p>
                            </div>
                            <div className="col-12">
                                <button className="btn btn-xs btn-primary w-100" onClick={() => setShowModal(true)}>
                                    Afficher la description <i className={'bi bi-arrow-right'}></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-9 mb-3">
                <SupplierQuoteRowList quote={quote} />
            </div>
            <div className="col-12 mb-3">
                <QuoteConditionShow quote={quote} setQuote={setQuote} />
            </div>
            <div className="col-12 mb-3">
                <div className="card border border-1">
                    <div className="card-header">
                        <h6>Transfers</h6>
                    </div>
                    <div className="card-body">
                        <CommonTransferList quote={quote} />
                    </div>
                </div>
            </div>
        </div>
        <ModalComponent size={"modal-lg"} title={`Description ${quote.project.title}`} show={showModal} onHide={() => setShowModal(false)}>
            <div dangerouslySetInnerHTML={{ __html: quote.project.description }} />
        </ModalComponent>
    </div>
}

export default SupplierQuoteShow;