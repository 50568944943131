import React, {useEffect, useState} from "react";
import {Proposal} from "../../../../../models/proposal";
import {Link, useParams} from "react-router-dom";
import * as api from "../../../../../adapters/corporate/supplier/proposal";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";
import convertToDecimal from "../../../../../utils/currencyConverter";
import {getLogoUrl} from "../../../../../utils/image";
import ModalComponent from "../../../../modal";
import SupplierProposalPayment from "../payment";

const SupplierProposalShow = () => {

    const {id} = useParams()
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [proposal, setProposal] = useState<Proposal>()
    const [error, setError] = useState(null)
    const [showRefusedModal, setShowRefusedModal] = useState(false)
    const [showCompleteModal, setShowCompleteModal] = useState(false)

    useEffect(() => {
        setLoading(true)
        setError(null)
        api.show(Number(id))
            .then(r => setProposal(r.data))
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [id])

    function refuse(){
        if (proposal){
            setUpdating(true);
            api.update(proposal.id, {status: "Refused"})
                .then(r => setProposal(r.data))
                .catch(e => setError(e))
                .finally(() => {
                    setUpdating(false)
                    setShowRefusedModal(false)
                })
        }
    }

    function complete(){
        if (proposal){
            setUpdating(true);
            api.update(proposal.id, {status: "Completed"})
                .then(r => setProposal(r.data))
                .catch(e => setError(e))
                .finally(() => {
                    setUpdating(false)
                    setShowCompleteModal(false)
                })
        }
    }

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!proposal){
        return <></>
    }


    return <div className={"container-fluid"}>
        <div className="col-12 mb-3">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/corporate/supplier/assignment">Annonces</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to={`/corporate/supplier/assignment/${proposal.assignment.id}`}>{proposal.assignment.name}</Link>
                </li>
                <li className="breadcrumb-item">
                    Devis N°{proposal.id}
                </li>
            </ol>
        </div>
        <div className="row">
            <div className="col-md-4 mb-3">
                <div className="row">
                    <div className="col-12 mb-3">
                        <div className="card border border-1">
                            <div className="card-body text-center d-flex flex-column">
                                <h6 className={'mb-5'}>Emetteur du devis</h6>
                                <div className="avatar avatar-xxl mx-auto mb-5">
                                    <img src={getLogoUrl(proposal.application.organization)} alt={proposal.application.organization.name} className="avatar-img rounded-circle"/>
                                </div>
                                <Link className={'fs-4'} to={`/corporate/organization/${proposal.application.organization.id}`} target={'_blank'}>
                                    {proposal.application.organization.name}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="card border border-1">
                            <div className="card-body">
                                <h6>Comment ça marche ?</h6>
                                <ol>
                                    <li>
                                        Payer le devis pour l'accepter
                                    </li>
                                    <li>
                                        Varar conserve le paiement jusqu'à la finalisation de la prestation.
                                    </li>
                                    <li>
                                        Une fois la prestation terminé, passez le devis en terminé pour débloquer le paiement.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-8 mb-3">
                <div className="card border border-1">
                    <div className="card-body">
                        <h6>Votre devis</h6>
                        <div className="col-12 mb-3">
                            <table className={'table'}>
                                <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Description</th>
                                    <th>Prix unité HT</th>
                                    <th>Quantité</th>
                                    <th>Unité</th>
                                    <th>Taux de taxe</th>
                                </tr>
                                </thead>
                                <tbody>
                                {proposal.rows.map(r => <tr>
                                    <td>{r.name}</td>
                                    <td>{r.description}</td>
                                    <td>{convertToDecimal(r.amount, proposal.currency)}</td>
                                    <td>{r.quantity}</td>
                                    <td>{r.unit}</td>
                                    <td>{r.taxRate}%</td>
                                </tr>)}
                                </tbody>
                            </table>
                        </div>
                        <div className="col-12 mb-3">
                            <div className="card border border-1">
                                <div className="card-body">
                                    <h6>Récapitulatif du devis</h6>
                                    <table className={'table table-borderless'}>
                                        <tbody>
                                        <tr>
                                            <th>Montant HT</th>
                                            <td>{convertToDecimal(proposal.priceData.amount_excluding_tax, proposal.currency)}</td>
                                        </tr>
                                        <tr>
                                            <th>Montant taxes</th>
                                            <td>{convertToDecimal(proposal.priceData.tax_amount, proposal.currency)}</td>
                                        </tr>
                                        <tr>
                                            <th>Montant TTC</th>
                                            <td>{convertToDecimal(proposal.priceData.amount_including_tax, proposal.currency)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {proposal.status === 'Published' && <div className="row">
                            <div className="col-md-6 mb-3">
                                <button className={'btn btn-light w-100'} onClick={() => setShowRefusedModal(true)}>
                                    <i className={'bi bi-x text-danger'}></i> Refuser
                                </button>
                            </div>
                            <div className="col-md-6 mb-3">
                                <button className={'btn btn-success w-100'} disabled={updating}
                                        onClick={() => setShowPaymentModal(true)}>
                                    <i className={'bi bi-check'}></i> Payer pour accepter
                                </button>
                            </div>
                        </div>}
                        {proposal.status === 'Accepted' && <div className="col-md-12 mb-3">
                            <button className={'btn btn-light w-100'} onClick={() => setShowCompleteModal(true)}>
                                <i className={'bi bi-check-all'}></i> Débloquer le paiement et terminer la prestation.
                            </button>
                        </div>}
                        {proposal.status === 'Refused' && <div className="col-md-12 mb-3">
                            <div className="alert alert-danger">
                                Devis refusé
                            </div>
                        </div>}
                        {proposal.status === 'Completed' && <div className="col-md-12 mb-3">
                            <div className="alert alert-info">
                                Prestation terminée
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        {showRefusedModal && <ModalComponent title={"Êtes vous sur de vouloir refuser ?"} show={showRefusedModal} onHide={() => setShowRefusedModal(false)}>
            <div className="row">
                <div className="col-6">
                    <button disabled={updating} className={'btn btn-light w-100'} onClick={() => refuse()}>
                        Oui, refuser le devis
                    </button>
                </div>
                <div className="col-6">
                    <button disabled={updating} className={'btn btn-light w-100'} onClick={() => setShowRefusedModal(false)}>
                        Non, annuler
                    </button>
                </div>
            </div>
        </ModalComponent>}
        {showCompleteModal && <ModalComponent title={"Êtes vous sur de vouloir débloquer le paiement ?"} show={showCompleteModal} onHide={() => setShowCompleteModal(false)}>
            <div className="row">
                <div className="col-6">
                    <button disabled={updating} className={'btn btn-light w-100'} onClick={() => complete()}>
                        Oui, débloquer le paiement
                    </button>
                </div>
                <div className="col-6">
                    <button disabled={updating} className={'btn btn-light w-100'} onClick={() => setShowCompleteModal(false)}>
                        Non, annuler
                    </button>
                </div>
            </div>
        </ModalComponent>}
        {showPaymentModal && <ModalComponent size={"modal-lg"} title={"Accepter le devis"} show={showPaymentModal} onHide={() => setShowPaymentModal(false)}>
            <SupplierProposalPayment proposal={proposal} handleSuccess={() => setProposal(prev => ({...prev!, status: 'Accepted'}))} />
        </ModalComponent>}
    </div>
}

export default SupplierProposalShow;