import {Organization} from "../../models/organization";
import {
    S3_BUCKET_URL,
    S3_ORGANIZATION_BUCKET,
    S3_POST_BUCKET,
    S3_PRODUCT_BUCKET,
    S3_MESSAGE_BUCKET,
    S3_AFFILIATION_BUCKET
} from "../../constants/global";
import {Product, ProductFile, ProductSupport} from "../../models/product";
import {Post, PostFile} from "../../models/post";
import {ChatMessage, ChatMessageFile} from "../../models/chat";
import {Affiliation, AffiliationSupport} from "../../models/affiliation";

export function getLogoUrl(o: Organization){
    return o.logoName ? `${S3_BUCKET_URL}${S3_ORGANIZATION_BUCKET}${o.id}/logo/${o.logoName}` : `${process.env.PUBLIC_URL}/img/placeholder/avatar.svg`
}
export function getCoverUrl(p: Product){
    const cover = p.files.find(f => f.cover);
    return cover ? `${S3_BUCKET_URL}${S3_PRODUCT_BUCKET}${p.id}/${cover.name}` : `${process.env.PUBLIC_URL}/img/placeholder/product.svg`
}
export function getProductFileUrl(p: Product, f: ProductFile){
    return `${S3_BUCKET_URL}${S3_PRODUCT_BUCKET}${p.id}/${f.name}`
}
export function getProductSupportUrl(p: Product, f: ProductSupport){
    return `${S3_BUCKET_URL}${S3_PRODUCT_BUCKET}${p.id}/support/${f.name}`
}
export function getAffiliationSupportUrl(a: Affiliation, f: AffiliationSupport){
    return `${S3_BUCKET_URL}${S3_AFFILIATION_BUCKET}${a.id}/support/${f.name}`
}
export function getPostFileUrl(p: Post, f: PostFile){
    return `${S3_BUCKET_URL}${S3_POST_BUCKET}${p.id}/${f.name}`
}
export function getMessageFileUrl(p: ChatMessage, f: ChatMessageFile){
    return `${S3_BUCKET_URL}${S3_MESSAGE_BUCKET}${p.id}/${f.name}`
}