import React, {useEffect, useState} from "react";
import {Quote} from "../../../../../models/quote";
import {Currency} from "../../../../../models/currency";
import Loader from "../../../../../utils/loader";
import * as api from "../../../../../adapters/corporate/common/quote"
import convertToDecimal from "../../../../../utils/currencyConverter";
import ErrorCard from "../../../../error";

const CommonQuoteSummary = ({quote, displayFeeData, displayPriceData}: {quote: Quote, displayFeeData: boolean, displayPriceData: boolean}) => {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [data, setData] = useState<{
        priceData: {
            deposit: {
                percentage: number;
                amount_including_tax: number;
                tax_amount: number;
                amount_excluding_tax: number;
            },
            final: {
                amount_including_tax: number;
                tax_amount: number;
                amount_excluding_tax: number;
            },
            amount_including_tax: number;
            tax_amount: number;
            amount_excluding_tax: number;
            currency: Currency;
        },
        feeData: {
            deposit: {
                percentage: number;
                payment: number;
                provider: number;
                supplier: number;
                platform: number;
            },
            final: {
                payment: number;
                provider: number;
                supplier: number;
                platform: number;
            },
            payment: number;
            provider: number;
            supplier: number;
            platform: number;
            currency: Currency;
        }
    }>();

    useEffect(() => {
        setLoading(true)
        api.data(quote.id)
            .then(r => setData(r.data))
            .catch(error => setError(error))
            .finally(() => setLoading(false))
    }, [quote.id])

    if (loading){
        return <div className={'col-12 text-center'}>
            <Loader /> Chargement en cours
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!data){
        return <div className={'col-12'}>
            <i className={'bi bi-exclamation-triangle'}></i> Impossible de charger le récapitulatif du devis
        </div>
    }

    return <>
        {displayPriceData && <div className="col-12 mb-3">
            <div className="table-responsive">
                <table className={"table"}>
                    <tbody>
                    <tr>
                        <th>
                            Total HT
                        </th>
                        <td>
                            {convertToDecimal(data.priceData.amount_excluding_tax, data.priceData.currency)}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Taxes
                        </th>
                        <td>
                            {convertToDecimal(data.priceData.tax_amount, data.priceData.currency)}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Accompte
                        </th>
                        <td>
                            {convertToDecimal(data.priceData.deposit.amount_including_tax, data.priceData.currency)}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Paiement final
                        </th>
                        <td>
                            {convertToDecimal(data.priceData.final.amount_including_tax, data.priceData.currency)}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Total TTC
                        </th>
                        <td>
                            {convertToDecimal(data.priceData.amount_including_tax, data.priceData.currency)}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>}
        {displayFeeData && <div className="col-12 mb-3">
            <div className="table-responsive">
                <table className={"table"}>
                    <tbody>
                    <tr>
                        <th>
                            Commission apporteur
                        </th>
                        <td>
                            {convertToDecimal(data.feeData.provider, data.feeData.currency)}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Frais de paiement stripe
                        </th>
                        <td>
                            {convertToDecimal(data.feeData.payment, data.feeData.currency)}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Frais de plateforme
                        </th>
                        <td>
                            {convertToDecimal(data.feeData.platform, data.priceData.currency)}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Montant restant net
                        </th>
                        <td>
                            {convertToDecimal(data.feeData.supplier, data.feeData.currency)}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>}
    </>
}

export default CommonQuoteSummary;