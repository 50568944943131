import * as models from "../../../../../../models/order";
import React, {useEffect, useState} from "react";
import * as api from "../../../../../../adapters/corporate/supplier/order";
import OrderStatusPlaceholder from "../../../../../common/order/status/placeholder";
import ErrorCard from "../../../../../error";

const OrderStatusDropdown = ({order, setOrder}: { order: models.Order, setOrder: React.Dispatch<any> }) => {
    const [allowedStatuses, setAllowedStatuses] = useState<("Processing"|"Completed"|"Canceled")[]>([]);
    const [error, setError] = useState(null);
    const [updating, setUpdating] = useState(false)

    function updateStatus(status: "Processing"|"Completed"|"Canceled"){
        setUpdating(true)
        api.update(order!.id, {status: status})
            .then(r => setOrder( (p: models.Order) => ({...p, ...r.data})))
            .catch(error => setError(error))
            .finally(() => setUpdating(false))
    }

    useEffect(() => {

        switch (order.status){
            case "Pending":
                setAllowedStatuses([])
                break;
            case "AwaitingConfirmation":
                setAllowedStatuses(['Processing', "Canceled"])
                break;
            case "Processing":
                setAllowedStatuses(['Completed', "Canceled"])
                break;
            case "Completed":
                setAllowedStatuses([])
                break;
            case "Canceled":
                setAllowedStatuses([])
                break;
        }

    }, [order.status])

    const ActionButton = ({status}: { status: "Processing"|"Canceled"|"Completed" }) => {
            switch (status){
                case "Canceled":
                    return <button disabled={updating} className={'btn btn-xxs btn-danger'} onClick={() => updateStatus("Canceled")}>
                       <i className={"bi bi-x"}></i> Annuler la commande
                    </button>
                case "Processing":
                    return <button disabled={updating} className={'btn btn-xxs btn-info'} onClick={() => updateStatus("Processing")}>
                        <i className={"bi bi-check"}></i> Confirmer la commande
                    </button>
                case "Completed":
                    return <button disabled={updating} className={'btn btn-xxs btn-success'} onClick={() => updateStatus("Completed")}>
                       <i className={"bi bi-check-all"}></i> Completer la commande
                    </button>
            }
    }

    return <div className="row">
        <div className="col-auto">
            <OrderStatusPlaceholder status={order.status} />
        </div>
        {allowedStatuses.filter(s =>  allowedStatuses.includes(s)).map(s => <div key={s} className={'col-auto'}>
            <ActionButton status={s} />
        </div>)}
        {error && <div className={'col-12'}>
            <ErrorCard error={error} />
        </div>}
    </div>
}

export default OrderStatusDropdown;