import {reason_labels} from "../../../../../../constants/returns";

const CustomerReturnAddReason2 = ({handleNext, handlePrev}: {handleNext: (reason: "reason2", value: string) => void, handlePrev: (reason: string) => void}) => {

    return <div className="card h-100">
        <div className="card-header">
            <div className="row">
                <div className="col-auto">
                    <div className="btn btn-xxs btn-light" onClick={() => handlePrev("reason2")}>
                        <i className={'bi bi-arrow-left'}></i>
                    </div>
                </div>
                <div className="col">
                    <h6>Comment pouvons-nous vous aider avec cet article ?</h6>
                </div>
            </div>
        </div>
        <div className="card-body">
            <ul className="list-group list-group-flush">
                <li className={'list-group-item list-group-item-action'} onClick={() => handleNext('reason2', "return_mismatch" )}>
                    {reason_labels.return_mismatch} <i className={'bi bi-chevron-right'}></i>
                </li>
                <li className={'list-group-item list-group-item-action'} onClick={() => handleNext('reason2', "return_unwanted" )}>
                    {reason_labels.return_unwanted} <i className={'bi bi-chevron-right'}></i>
                </li>
                <li className={'list-group-item list-group-item-action'} onClick={() => handleNext('reason2', "return_missing" )}>
                    {reason_labels.return_missing} <i className={'bi bi-chevron-right'}></i>
                </li>
                <li className={'list-group-item list-group-item-action'} onClick={() => handleNext('reason2', "return_else" )}>
                    {reason_labels.return_else} <i className={'bi bi-chevron-right'}></i>
                </li>
            </ul>
        </div>
    </div>
}

export default CustomerReturnAddReason2;
