export const webhook_scope_options = [
    {
        description: "Lorsqu'un nouveau client est lié",
        slug: "customer.attached"
    },
    {
        description: "Lorsqu'un nouveau client lié est modifié",
        slug: "customer.updated"
    },
    {
        description: "Lorsqu'un abonnement est créé",
        slug: "subscription.created"
    },
    {
        description: "Lorsqu'un abonnement est modifié",
        slug: "subscription.updated"
    },
    {
        description: "Lorsqu'un abonnement est annulé",
        slug: "subscription.canceled"
    }
]