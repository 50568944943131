import React, {ChangeEvent, useEffect, useState} from "react";
import {useFormik} from "formik";
import FormError from "../../../../../utils/formError";
import * as models from "../../../../../models/product";
import * as Yup from "yup"
import * as api from "../../../../../adapters/corporate/supplier/product";
import Loader from "../../../../../utils/loader";
import SupplierUnitSelect from "../../unit/select";
import ShippingSelect from "../../../../../utils/shippingSelect";
import ErrorCard from "../../../../error";

const SupplierProductUpdate = ({product, onSuccess}: {product: models.Product, onSuccess: (product: models.Product) => void}) => {

    const [error, setError] = useState(null);
    const [initialValues, setInitialValues] = useState<any>({
        name: product.name,
        type: product.type,
        // shippable: product.shippable,
        // storable: product.storable,
        // minQuantity: product.minQuantity,
        // maxQuantity: product.maxQuantity,
        // description: product.description,
        // unit_id: product.unit?.id,
    });

    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        name: Yup.string().required(),
        type: Yup.string().required(),
        shippable: Yup.boolean().required(),
        storable: Yup.boolean().required(),
        minQuantity: Yup.number().positive().required(),
        maxQuantity: Yup.number().nullable(),
        description: Yup.string().nullable(),
        unit_id: Yup.number().required(),
    }));

    useEffect(() => {
        // if (product.shippable){
        //     let fields = {...validationSchema.fields}
        //     let values = {...initialValues}
        //
        //     values.shipping_id = product.shipping.id
        //     fields.shipping_id = Yup.number().required()
        //
        //     setInitialValues(values)
        //     setValidationSchema(Yup.object().shape({...fields}))
        // }
    }, [product.id])

    const formik = useFormik({
       initialValues: initialValues,
       validationSchema: validationSchema,
       enableReinitialize: true,
       onSubmit: (values, {setSubmitting}) => {
           setError(null)
           api.update(product.id, values)
               .then(r => onSuccess(r.data))
               .catch(e => setError(e))
               .finally(() => setSubmitting(false))
       }
    });

    function handleShippableChange(e: ChangeEvent<HTMLInputElement>){

        let values = {...formik.values}
        let schema = {...validationSchema.fields}

        if (e.target.checked){
            values.shippable = true;
            values.shipping_id = null;
            schema.shipping_id = Yup.number().required();
        }else{
            values.shippable = false;
            delete values.shipping_id;
            delete schema.shipping_id;
        }

        setValidationSchema(Yup.object().shape({...schema}))
        setInitialValues({...values});
    }

    return <form onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <div className="form-floating bg-body-tertiary">
                <input disabled={formik.isSubmitting} defaultValue={product.name} type="text" className="form-control" id={'name'} name={'name'} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                <label htmlFor="name">
                    Nom *
                </label>
            </div>
            <FormError form={formik} field={"name"} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating bg-body-tertiary">
                <textarea disabled={formik.isSubmitting} defaultValue={product.description} className="form-control" id={'description'} style={{height: 100}} name={'description'} onChange={formik.handleChange} onBlur={formik.handleBlur}></textarea>
                <label htmlFor="description">
                    Description
                </label>
            </div>
            <FormError form={formik} field={"description"} />
        </div>
        <p className={'mb-1'}>Type de produit</p>
        <div className="col-12 mb-3">
            <div className="form-check form-check-inline">
                <input className="form-check-input" defaultChecked={product.type === "goods"} type="radio" id="product" value="product" name={"type"} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                <label className="form-check-label" htmlFor="product">
                    Bien
                </label>
            </div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" defaultChecked={product.type === "needs"} id="service" value="service" name={"type"} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                <label className="form-check-label" htmlFor="service">
                    Service
                </label>
            </div>
            <FormError form={formik} field={"type"} />
        </div>
        <div className="col-12 mb-3">
            {/*<SupplierUnitSelect required={true} defaultValue={product.unit ? {label: product.unit.label, value: product.unit.id} : undefined} handleChange={id => formik.setFieldValue('unit_id', id)} allowAdd={true} />*/}
        </div>
        <p className={'mb-1'}>
            Quantité par commande
        </p>
        <div className="col-12 mb-3">
            {/*<div className="row">*/}
            {/*    <div className="col-6 col-md-auto">*/}
            {/*        <div className="form-floating bg-body-tertiary">*/}
            {/*            <input disabled={formik.isSubmitting} defaultValue={product.minQuantity} type="number" className="form-control" id={'minQuantity'} name={'minQuantity'} onChange={formik.handleChange} onBlur={formik.handleBlur}/>*/}
            {/*            <label htmlFor="minQuantity">*/}
            {/*                Minimum **/}
            {/*            </label>*/}
            {/*        </div>*/}
            {/*        <FormError form={formik} field={"minQuantity"} />*/}
            {/*    </div>*/}
            {/*    <div className="col-6 col-md-auto">*/}
            {/*        <div className="form-floating bg-body-tertiary">*/}
            {/*            <input disabled={formik.isSubmitting} type="number" defaultValue={product.maxQuantity ? product.maxQuantity : undefined} className="form-control" id={'maxQuantity'} name={'maxQuantity'} onChange={formik.handleChange} onBlur={formik.handleBlur}/>*/}
            {/*            <label htmlFor="maxQuantity">*/}
            {/*                Maximum*/}
            {/*            </label>*/}
            {/*        </div>*/}
            {/*        <FormError form={formik} field={"maxQuantity"} />*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
        <div className="card border border-1 mb-3">
            {/*<div className="card-body">*/}
            {/*    <h6>*/}
            {/*        livraison*/}
            {/*    </h6>*/}
            {/*    <div className="col-12 mb-3">*/}
            {/*        <div className="form-check form-switch">*/}
            {/*            <input className="form-check-input" defaultChecked={product.shippable} type="checkbox" role="switch" id="shippable" name={"shippable"} onBlur={formik.handleBlur} onChange={handleShippableChange} />*/}
            {/*            <label className="form-check-label" htmlFor="shippable">*/}
            {/*                Produit livrable*/}
            {/*            </label>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    {formik.values.shippable && <>*/}
            {/*        <div className="col-12 mb-3">*/}
            {/*            <div className="form-check form-switch">*/}
            {/*                <input defaultChecked={product.storable} className="form-check-input" type="checkbox" role="switch" id="storable" name={"storable"} onBlur={formik.handleBlur} onChange={formik.handleChange} />*/}
            {/*                <label className="form-check-label" htmlFor="storable">*/}
            {/*                    Activer la gestion des stocks avancé*/}
            {/*                </label>*/}
            {/*                <FormError form={formik} field={"storable"} />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className={"col-12 mb-3"}>*/}
            {/*            <ShippingSelect defaultValue={product.shipping ? {label: product.shipping.name, value: product.shipping.id} : undefined} handleChange={v => formik.setFieldValue("shipping_id", v)} required={true} />*/}
            {/*        </div>*/}
            {/*    </>}*/}
            {/*</div>*/}
        </div>
        {error && <div className={"mb-3"}>
            <ErrorCard error={error} />
        </div>}
        <div className="col-12 text-center">
            <button disabled={formik.isSubmitting} type={"submit"} className={'btn btn-primary w-100'}>
                {formik.isSubmitting ? <Loader size={"sm"} /> : <><i className={'bi bi-plus-circle'}></i> Enregistrer</>}
            </button>
        </div>
    </form>
}

export default SupplierProductUpdate;