import React from "react";
import {Link, useNavigate} from "react-router-dom";

const ProviderDashboard = () => {

    const navigate = useNavigate();

    return <>
        <div className="col-12 mb-3 alert alert-info shadow">
            <h6>Mise à jour</h6>
            <Link to={'/corporate/setting/organization'}>Renseignez vos services <i className={'bi bi-arrow-right'}></i></Link>
        </div>
        <div className="col-md-12 mb-3">
            <div className="card shadow h-100">
                <div className="card-body">
                    <h6>Comment ça marche ?</h6>
                    <ol>
                        <li className={'mb-2'}>
                            Parcourir les <Link to={'/corporate/provider/search/assignment'}>annonces</Link>, <Link to={'/corporate/provider/search/product'}>produits / services</Link>, <Link to={'/corporate/provider/search/affiliation'}>programmes d'affiliation</Link> et <Link to={'/corporate/provider/search/supplier'}>entreprises</Link> via la <Link to={'/corporate/provider/search/supplier'}>recherche</Link>.
                        </li>
                        <li className={'mb-2'}>
                            Invitez les entreprises qui vous interessent à rejoindre votre réseau.
                        </li>
                        <li className={'mb-2'}>
                            <span className={'mb-2'}>
                                Échangez sur les modalités de votre collaboration via la <Link to={'/corporate/chat'}>messagerie</Link>.
                            </span>
                            <ul>
                                <li className={'mb-2'}>
                                    <span className={"fw-semibold"}>Pour le dropshipping et la vente direct</span>, utilisez <Link to={'/corporate/provider/link'}>les liens de paiement</Link>, <Link to={'/corporate/provider/shop'}>boutiques</Link> et <Link to={'/corporate/provider/catalog/pack'}>packages</Link> avec les produits en vente direct de vos partenaires.
                                </li>
                                <li className={'mb-2'}>
                                    <span className={"fw-semibold"}>Pour l'apport d'affaires</span> ajoutez des <Link to={'/corporate/provider/touch'}>mises en relation</Link> entre vos <Link to={'/corporate/provider/contact'}>contact</Link> et vos partenaires.
                                </li>
                                <li className={'mb-2'}>
                                    <span className={"fw-semibold"}>Pour les agents commerciaux et téléprospécteurs</span>, négociez les modalités de vos missions directement avec vos partenaires.
                                </li>
                            </ul>
                        </li>
                        <li className={'mb-2'}>
                            Générez des ventes et <Link to={'/corporate/provider/fee'}>recevez des commissions</Link> via la plateforme pour augmentez votre scoring <i className={'bi bi-trophy'}></i> et votre visibillité.
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card">
                <div className="card-body shadow">
                    <h5>Parcourir</h5>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="card h-100 shadow-hover cursor-pointer" onClick={() => navigate('/corporate/provider/search/assignment')}>
                                <div className="card-body border border-1">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <p className={'fw-semibold mb-0'}>
                                                Parcourir les annonces
                                            </p>
                                        </div>
                                        <div className="col-auto">
                                            <i className={'bi bi-arrow-right'}></i>
                                        </div>
                                    </div>
                                    <p className={'mb-0 mt-1 form-text'}>
                                        Cherchez et postulez aux missions qui vous correspondent.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card h-100 shadow-hover cursor-pointer" onClick={() => navigate('/corporate/provider/search/company')}>
                                <div className="card-body border border-1">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <p className={'fw-semibold mb-0'}>
                                                Trouver des entreprises
                                            </p>
                                        </div>
                                        <div className="col-auto">
                                            <i className={'bi bi-arrow-right'}></i>
                                        </div>
                                    </div>
                                    <p className={'mb-0 mt-1 form-text'}>
                                        Cherchez et trouvez les entreprises qui vous correspondent.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card h-100 shadow-hover cursor-pointer" onClick={() => navigate('/corporate/provider/search/product')}>
                                <div className="card-body border border-1">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <p className={'fw-semibold mb-0'}>
                                                Parcourir les produits / services
                                            </p>
                                        </div>
                                        <div className="col-auto">
                                            <i className={'bi bi-arrow-right'}></i>
                                        </div>
                                    </div>
                                    <p className={'mb-0 mt-1 form-text'}>
                                        Trouvez des fournisseurs en recherchant les produits ou services qui vous intéressent.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card h-100 shadow-hover cursor-pointer" onClick={() => navigate('/corporate/community')}>
                                <div className="card-body border border-1">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <p className={'fw-semibold mb-0'}>
                                                Interagir avec la communauté
                                            </p>
                                        </div>
                                        <div className="col-auto">
                                            <i className={'bi bi-arrow-right'}></i>
                                        </div>
                                    </div>
                                    <p className={'mb-0 mt-1 form-text'}>
                                        Publiez vos actualités, saisissez des opportunités et développez votre réseau de partenaires.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card">
                <div className="card-body shadow">
                    <h5>Actions rapides</h5>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="card h-100 shadow-hover cursor-pointer" onClick={() => navigate('/corporate/provider/touch/add')}>
                                <div className="card-body border border-1">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <p className={'fw-semibold mb-0'}>
                                                Créer une mise en relation
                                            </p>
                                        </div>
                                        <div className="col-auto">
                                            <i className={'bi bi-arrow-right'}></i>
                                        </div>
                                    </div>
                                    <p className={'mb-0 mt-1 form-text'}>
                                        Créez des mises en relation directes entre vos partenaires et contacts finaux. Recevez une commission si affaire conclue.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card h-100 shadow-hover cursor-pointer" onClick={() => navigate('/corporate/provider/link/add')}>
                                <div className="card-body border border-1">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <p className={'fw-semibold mb-0'}>
                                                Créer un lien de paiement
                                            </p>
                                        </div>
                                        <div className="col-auto">
                                            <i className={'bi bi-arrow-right'}></i>
                                        </div>
                                    </div>
                                    <p className={'mb-0 mt-1 form-text'}>
                                        Générez un lien de paiement unique vers un panier prédéfinie avec les produits de vos partenaires et recevez des commissions a chaque commande.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card h-100 shadow-hover cursor-pointer" onClick={() => navigate('/corporate/provider/shop/add')}>
                                <div className="card-body border border-1">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <p className={'fw-semibold mb-0'}>
                                                Créer une boutique
                                            </p>
                                        </div>
                                        <div className="col-auto">
                                            <i className={'bi bi-arrow-right'}></i>
                                        </div>
                                    </div>
                                    <p className={'mb-0 mt-1 form-text'}>
                                        Créez une boutique en ligne avec les produits de vos partenaires et recevez des commissions sur chauque vente.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card h-100 shadow-hover cursor-pointer" onClick={() => navigate('/corporate/provider/project/add')}>
                                <div className="card-body border border-1">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <p className={'fw-semibold mb-0'}>
                                                Publier un appel d'offre
                                            </p>
                                        </div>
                                        <div className="col-auto">
                                            <i className={'bi bi-arrow-right'}></i>
                                        </div>
                                    </div>
                                    <p className={'mb-0 mt-1 form-text'}>
                                        Pubilez un appel d'offre avec le taux de commission souhaité et recevez des propositions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ProviderDashboard;