import React, {useState} from 'react';
import {useStripe, useElements} from '@stripe/react-stripe-js';
import * as api from "../../../../../../../adapters/corporate/supplier/conversion";
import {Conversions} from "../../../../../../../models/affiliation";
import {Currency} from "../../../../../../../models/currency";
import {PaymentMethod} from "../../../../../../../models/paymentMethod";
import Loader from "../../../../../../../utils/loader";
import ErrorCard from "../../../../../../error";

const ConversionPaymentForm = ({conversions, currency, paymentMethod, handlePaymentSucceed}: {conversions?: Conversions, currency: Currency, paymentMethod?: PaymentMethod, handlePaymentSucceed: (conversions: Conversions) => void}) => {
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState<string|undefined|null>(null);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

        setLoading(true)
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        if (!conversions || !paymentMethod) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        try {
            const { data } = await api.confirmPayment({currency_id: currency.id, ids: conversions.map(c => c.id), payment_method_id: paymentMethod.id});
            const { error } = await stripe!.confirmCardPayment(data.client_secret);

            if (error) {
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Show error to your customer (for example, payment
                // details incomplete)
                setErrorMessage(error.message);
            } else {
                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
                handlePaymentSucceed(data.conversions)
            }
        }catch (error){
            setError(error)
        } finally {
            setLoading(false)
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <button className={"btn btn-primary w-100"} disabled={!stripe || loading || !conversions || !paymentMethod}>
                {loading ? <Loader /> : <><i className={'bi bi-check'}></i> Payer</>}
            </button>
            {errorMessage && <div>{errorMessage}</div>}
            {error && <ErrorCard error={error} />}
        </form>
    )
};

export default ConversionPaymentForm;