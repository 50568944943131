import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import FormError from "../../../../../utils/formError";
import Select from "../../../../../utils/select";
import {getCurrencies} from "../../../../../utils/currencies";
import {getCountries} from "../../../../../utils/countries";
import * as api from "../../../../../adapters/corporate/supplier/affiliation"
import {Link, useNavigate} from "react-router-dom";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";

const SupplierAffiliationAdd: React.FC = () => {

    const navigate = useNavigate();
    const [error, setError] = useState(null)

    const formik = useFormik({
        initialValues:  {
            name: "",
            url: "",
            conversionUrl: "",
            urlType: "",
            currencies: [],
            countries: [],
            allowedCountries: [],
            attribution: "SHARE",
            config: {
                sessionTTLSecond: 0,
                sessionTTLMinute: 30,
                sessionTTLHour: 0,
                sessionTTLDay: 0
            }
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            url: Yup.string().required(),
            conversionUrl: Yup.string().required(),
            urlType: Yup.string().required(),
            currencies:  Yup.array().of(Yup.string()).min(1).required(),
            countries: Yup.array().of(Yup.string()).min(1).required(),
            allowedCountries: Yup.array().of(Yup.string()).min(1).required(),
            attribution: Yup.string().required(),
            config: Yup.object().shape(({
                sessionTTLSecond: Yup.number().required(),
                sessionTTLMinute: Yup.number().required(),
                sessionTTLHour: Yup.number().required(),
                sessionTTLDay: Yup.number().required()
            })).required()
        }),
        onSubmit: (values, {setSubmitting}) => {
            api.add(values)
                .then(r => navigate(`/corporate/supplier/affiliation/${r.data.id}`))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    })

    return <form className={'col-12'} onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/corporate/supplier/affiliation">Affiliation</Link>
                </li>
                <li className="breadcrumb-item">
                    Ajouter
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" id={'name'} name={"name"} onChange={formik.handleChange} onBlur={formik.handleBlur} className={'form-control'} />
                <label htmlFor="name">
                    Nom *
                </label>
            </div>
            <FormError form={formik} field={"name"}/>
        </div>
        <div className="col-12 mb-3">
            <div className="input-group">
                <div className="form-floating">
                    <input type="text" id={'url'} name={"url"} onChange={formik.handleChange} onBlur={formik.handleBlur} className={'form-control'} />
                    <label htmlFor="name">
                        Url * (précisez le http:// ou https://)
                    </label>
                </div>
            </div>
            <FormError form={formik} field={"url"}/>
        </div>
        <div className="col-12 mb-3">
            <div className="input-group">
                <div className="form-floating">
                    <input type="text" id={'conversionUrl'} name={"conversionUrl"} onChange={formik.handleChange} onBlur={formik.handleBlur} className={'form-control'} />
                    <label htmlFor="name">
                        Url de conversion * (précisez le http:// ou https://)
                    </label>
                </div>
            </div>
            <FormError form={formik} field={"conversionUrl"}/>
        </div>
        <div className="col-12 mb-3">
            <Select label={"Type d'url *"} required={true} value={{label: "SEO", value: "SEO"}} options={[{label: "SEO", value: "SEO"}, {label: "Paramètre", value: "PARAMETER"}, {label: "Redirection", value: "REDIRECT"}]} onChange={c => formik.setFieldValue('urlType', c.value)} />
            <FormError form={formik} field={"urlType"}/>
        </div>
        <div className="col-12 mb-3">
            <Select label={"Devises *"} multiple={true} required={true} options={getCurrencies()} onChange={choices => formik.setFieldValue('currencies', choices.map(c => c.id))} />
            <FormError form={formik} field={"currencies"}/>
        </div>
        <div className="col-12 mb-3">
            <Select label={"Pays d'opération *"} multiple={true} required={true} options={getCountries()} onChange={choices => formik.setFieldValue('countries', choices.map(c => c.id))} />
            <FormError form={formik} field={"countries"}/>
        </div>
        <div className="col-12 mb-3">
            <Select label={"Traffic autorisé par pays *"} multiple={true} required={true} options={getCountries()} onChange={choices => formik.setFieldValue('allowedCountries', choices.map(c => c.id))} />
            <FormError form={formik} field={"allowedCountries"}/>
        </div>
        <div className="col-12 mb-3">
            <Select label={"Attribution de conversion *"} required={true} value={{label: "Partage (recommandé)", value: "share"}} options={[{label: "Partage (recommandé)", value: "share"}, {label: "Premier clic", value: "first"}, {label: "Dernier clic", value: "last"}]} onChange={c => formik.setFieldValue('attribution', c.value)} />
            <FormError form={formik} field={"attribution"}/>
        </div>
        <div className="col-12 mb-3">
            <h6>
                Durée de session
            </h6>
            <p className="form-text">Durée entre deux clics de même provenance</p>
            <div className="input-group">
                <div className="form-floating">
                    <input type="text" defaultValue={0} name={"config.sessionTTLDay"} id={"config.sessionTTLDay"} onChange={formik.handleChange} onBlur={formik.handleBlur} className={"form-control"}/>
                    <label htmlFor="config.sessionTTLDay">Jours</label>
                </div>
                <div className="form-floating">
                    <input type="text" defaultValue={0} name={"config.sessionTTLHour"} id={"config.sessionTTLHour"} onChange={formik.handleChange} onBlur={formik.handleBlur} className={"form-control"}/>
                    <label htmlFor="config.sessionTTLHour">Heures</label>
                </div>
                <div className="form-floating">
                    <input type="text" defaultValue={30} name={"config.sessionTTLMinute"} id={"config.sessionTTLMinute"} onChange={formik.handleChange} onBlur={formik.handleBlur} className={"form-control"}/>
                    <label htmlFor="config.sessionTTLMinute">Minutes</label>
                </div>
                <div className="form-floating">
                    <input type="text" defaultValue={0} name={"config.sessionTTLSecond"} id={"config.sessionTTLSecond"} onChange={formik.handleChange} onBlur={formik.handleBlur} className={"form-control"}/>
                    <label htmlFor="config.sessionTTLSecond">Secondes</label>
                </div>
            </div>
            <FormError form={formik} field={"config.sessionTTLSecond"}/>
            <FormError form={formik} field={"config.sessionTTLMinute"}/>
            <FormError form={formik} field={"config.sessionTTLHour"}/>
            <FormError form={formik} field={"config.sessionTTLDay"}/>
        </div>
        {error && <div className={"col-12 mb-3"}>
            <ErrorCard error={error} />
        </div>}
        <button className={'btn btn-primary w-100'}>
            {formik.isSubmitting ? <Loader /> : <><i className={'bi bi-plus-circle'}></i> Ajouter</>}
        </button>
    </form>
}

export default SupplierAffiliationAdd;