import React, {useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {useFormik} from "formik";
import * as Yup from "yup";
import authSlice from "../../../reducers/auth";
import * as api from "../../../adapters/auth";
import {AxiosError} from "axios";
import FormError from "../../../utils/formError";
import Loader from "../../../utils/loader";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useQuery} from "../../../utils/useQuery";
import {useGoogleLogin} from "@react-oauth/google";

const Register = () => {

    const dispatch = useAppDispatch();
    const state = useAppSelector(state => state.auth)
    const {search} = useLocation();
    const query = useQuery();
    const navigate = useNavigate();
    const [googleLoading, setGoogleLoading] = useState(false);

    const googleLogin = useGoogleLogin({
        onSuccess: tokenResponse => {
            setGoogleLoading(true)
            api.googleLogin({accessToken: tokenResponse.access_token, type: query.get("type") || "corporate"})
                .then(resp => {
                    if(resp.data.isNewUser){
                        sendConversion()
                    }
                    dispatch(authSlice.actions.loginSuccess(resp.data))
                })
                .then(() => navigate(query.has('redirect') ? query.get("redirect")! : "/"))
                .catch((error: AxiosError) => dispatch(authSlice.actions.loginFailure(error)))
        },
        onError: errorResponse => console.log(errorResponse)
    });

    // Define Google Analytics conversion function
    const sendConversion = () => {
        if (window && typeof window.gtag === "function") {
            window.gtag("event", "conversion", {
                send_to: "AW-16740144284/fyWbCPjn8uIZEJypqa4-"
            });
        } else {
            console.error("Google Analytics not initialized.");
        }
    };


    const formik = useFormik({
        initialValues: {
            type: query.has('type') ? query.get("type")! : `corporate`,
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            repeatPassword: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email().required(),
            firstname: Yup.string().required(),
            lastname: Yup.string().required(),
            password: Yup.string().min(8).required(),
            repeatPassword: Yup.string().min(8).oneOf([Yup.ref('password'), ''], 'Les mots de passe ne correspondent pas').required(),
        }),
        onSubmit: (values, formikHelpers) => {
            dispatch(authSlice.actions.register())
            api.register(values)
                .then(r => {

                    if(r.data.isNewUser){
                        sendConversion()
                    }

                    dispatch(authSlice.actions.loginSuccess(r.data))
                })
                .catch((error: AxiosError) => dispatch(authSlice.actions.registerFailure(error)))
        }
    });

    return <div className={'container mx-auto h-100'}>
        <div className="row h-100 align-items-center justify-content-center">
            <div className="col-md-6">
                <div className="row justify-content-center">
                    <div className="col-auto d-flex align-items-center mb-5">
                        <img src={process.env.PUBLIC_URL + '/img/logo.png'} className={'logo me-2'} alt=""/>
                    </div>
                </div>
                <div className="card border border-1 rounded mb-3">
                    <div className="card-body">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="col-12 mb-3">
                                <div className="form-floating">
                                    <input disabled={state.loading} name={"firstname"} id={"firstname"} onBlur={formik.handleBlur} onChange={formik.handleChange} type="text" className="form-control" />
                                    <label htmlFor="email">
                                        Prénom
                                    </label>
                                </div>
                                <FormError form={formik} field={'firstname'} />
                            </div>
                            <div className="col-12 mb-3">
                                <div className="form-floating">
                                    <input disabled={state.loading} name={"lastname"} id={"lastname"} onBlur={formik.handleBlur} onChange={formik.handleChange} type="text" className="form-control" />
                                    <label htmlFor="email">
                                        Nom de famille
                                    </label>
                                </div>
                                <FormError form={formik} field={'lastname'} />
                            </div>
                            <div className="col-12 mb-3">
                                <div className="form-floating">
                                    <input disabled={state.loading} name={"email"} id={"email"} onBlur={formik.handleBlur} onChange={formik.handleChange} type="email" className="form-control" />
                                    <label htmlFor="email">
                                        Email
                                    </label>
                                </div>
                                <FormError form={formik} field={'email'} />
                            </div>
                            <div className="col-12 mb-3">
                                <div className="form-floating">
                                    <input disabled={state.loading} name={"password"} id={"password"} onBlur={formik.handleBlur} onChange={formik.handleChange} type="password" className="form-control" />
                                    <label htmlFor="password">
                                        Mot de passe
                                    </label>
                                </div>
                                <FormError form={formik} field={'password'} />
                            </div>
                            <div className="col-12 mb-3">
                                <div className="form-floating">
                                    <input disabled={state.loading} name={"repeatPassword"} id={"repeatPassword"} onBlur={formik.handleBlur} onChange={formik.handleChange} type="password" className="form-control" />
                                    <label htmlFor="repeatPassword">
                                        Répétez le mot de passe
                                    </label>
                                </div>
                                <FormError form={formik} field={'repeatPassword'} />
                            </div>
                            <button className="btn btn-dark w-100 mb-3" disabled={state.loading}>
                                {state.loading ? <Loader size={"sm"} /> : <>Créer mon compte <i className={'bi bi-arrow-right'}></i></>}
                            </button>
                        </form>
                        {!!state.errorMessage && <p className={"text-center text-danger"}>
                            {state.errorMessage}
                        </p>}
                        <div className="row align-items-center">
                            <div className="col"><hr/></div>
                            <div className="col-auto">
                                <span className="fs-5">Ou</span>
                            </div>
                            <div className="col"><hr/></div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <button onClick={() => googleLogin()} className="btn btn-light w-100">
                                    {googleLoading ? <Loader size={"sm"} /> : <><i className={'bi bi-google'}></i> Continuer avec Google</>}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card border border-1 rounded">
                    <div className="card-body">
                        <p className={'mb-0 text-center'}>
                            Vous avez déjà un compte ? <Link to={`/login?${search}`} className={"text-decoration-underline"}>Connectez vous</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Register;