import React from "react";
import {Specialisations} from "../../../../../models/specialisation";
import {Sectors} from "../../../../../models/sector";
import {sectors as allSectors} from "../../../../../utils/sectors";
import {services} from "../../../../../utils/service";
const SupplierSearchFilter = ({sort, setSort, specialisations, setSpecialisations, sectors, setSectors}: {sort: 'ranking'|'trending'|'latest', setSort: React.Dispatch<any>, specialisations: Specialisations, setSpecialisations: React.Dispatch<any>, sectors: Sectors, setSectors: React.Dispatch<any>}) => {

    return <>
        <div className="col-12">
            <h6 className={'fw-semibold'}>
                Trier par
            </h6>
            <div className="form-check">
                <input key={sort} checked={sort === 'ranking'} className="form-check-input" type="checkbox" name="sort" id="ranking" onChange={() => setSort('ranking')} />
                <label className="form-check-label" htmlFor="ranking">
                    Classement
                </label>
            </div>
            <div className="form-check">
                <input key={sort} checked={sort === 'trending'} className="form-check-input" type="checkbox" name="sort" id="trending" onChange={() => setSort('trending')} />
                <label className="form-check-label" htmlFor="trending">
                    Populaires
                </label>
            </div>
            <div className="form-check">
                <input key={sort} checked={sort === 'latest'} className="form-check-input" type="checkbox" name="sort" id="latest" onChange={() => setSort('latest')} />
                <label className="form-check-label" htmlFor="latest">
                    Nouveautés
                </label>
            </div>
        </div>
        <hr/>
        <div className="col-12">
            <h6 className={'fw-semibold'}>
                Services
            </h6>
            <ul className={"list-unstyled"}>
                {services.map(s => <li>
                    <p className={"fw-semibold my-3"}>
                        {s.title}
                    </p>
                    <ul className={'list-unstyled ps-3'}>
                        {s.items.map(i => <li>
                            <div className="form-check">
                                <input
                                    defaultChecked={specialisations.map(s => s.id).includes(i.id)} className="form-check-input" name={'specialisations'} type="checkbox" value={i.id} id={`service_${i.id}`}
                                    onChange={e => {
                                        if (e.target.checked){
                                            setSpecialisations((prev: Specialisations) => [...prev, i])
                                        }else{
                                            setSpecialisations((prev: Specialisations) => [...prev.filter(p => p.id !== i.id)])
                                        }
                                    }}
                                />
                                <label className="form-check-label" htmlFor={`service_${i.id}`}>
                                    {i.title}
                                </label>
                            </div>
                        </li>)}
                    </ul>
                </li>)}
            </ul>
        </div>
        <hr/>
        <div className="col-12">
            <h6 className={'fw-semibold'}>
                Sécteurs
            </h6>
            {allSectors.map(sector => <div className="form-check">
                <input
                    checked={sectors.map(s => s.id).includes(sector.id)}
                    className="form-check-input" type="checkbox" name={"sectors"}
                    id={`sec_${sector.id}`}
                    onChange={e => {
                        if (e.target.checked){
                            setSectors((prev: Sectors) => [...prev, sector])
                        }else{
                            setSectors((prev: Sectors) => [...prev.filter(p => p.id !== sector.id)])
                        }
                    }}
                />
                <label className="form-check-label" htmlFor={`sec_${sector.id}`}>
                    {sector.name}
                </label>
            </div>)}
        </div>
    </>
}

export default SupplierSearchFilter;