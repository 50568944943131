import {Webhook} from "../../../../../../models/webhook";
import React, {useEffect, useState} from "react";
import Highlight from "react-highlight";
import 'highlight.js/styles/a11y-light.css';

const SupplierWebhookShowIntegration = ({webhook}: {webhook: Webhook}) => {

    const [language, setLanguage] = useState<"javascript"|"php"|"python"|"go"|"java">("javascript");
    const [content, setContent] = useState<string>("");

    useEffect(() => {
        let content = "";
        switch (language){
            case "javascript":
                content = getJavascriptContent();
                break;
            case "php":
                content = getPhpContent();
                break;
        }

        setContent(content)
    }, [language])

    return <div className="card border border-1">
        <div className="card-body">
            <div className="btn-group">
                <button className={'btn btn-xxs ' + (language === "javascript" ? " btn-primary " : "btn-light")} onClick={() => setLanguage("javascript")}>
                    javascript
                </button>
                <button className={'btn btn-xxs ' + (language === "php" ? " btn-primary " : "btn-light")} onClick={() => setLanguage("php")}>
                    php
                </button>
                <button className={'btn btn-xxs ' + (language === "python" ? " btn-primary " : "btn-light")} onClick={() => setLanguage("python")}>
                    Python
                </button>
                <button className={'btn btn-xxs ' + (language === "go" ? " btn-primary " : "btn-light")} onClick={() => setLanguage("go")}>
                    Go
                </button>
                <button className={'btn btn-xxs ' + (language === "java" ? " btn-primary " : "btn-light")} onClick={() => setLanguage("java")}>
                    Java
                </button>
            </div>
            <Highlight className={language}>
                {content}
            </Highlight>
        </div>
    </div>

    function getJavascriptContent(){
        return `
const express = require('express');
const bodyParser = require('body-parser');
const crypto = require('crypto');

const app = express();
app.use(bodyParser.json());

const SECRET = '${webhook.secret}';

function decrypt(text, secret) {
    const textParts = text.split(':');
    const iv = Buffer.from(textParts.shift(), 'hex');
    const encryptedText = Buffer.from(textParts.join(':'), 'hex');
    const decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(secret, 'hex'), iv);
    let decrypted = decipher.update(encryptedText, 'hex', 'utf8');
    decrypted += decipher.final('utf8');
    return decrypted;
}

app.post('/webhook', (req, res) => {
    const encryptedData = req.body.data;

    try {
        const decryptedData = decrypt(encryptedData, SECRET);
        const event = JSON.parse(decryptedData);

        // Process the event
        console.log('Event received:', event);

        // Send a success response
        res.status(200).send('Event received successfully');
    } catch (error) {
        console.error('Error processing event:', error);

        // Send an error response
        res.status(500).send('Error processing event');
    }
});

const PORT = process.env.PORT || 3000;
app.listen(PORT, () => {
    console.log(\`Server is listening on port \${PORT}\`);
});`}

    function getPhpContent(){
        return `
            <?php
            // Function to generate a secret key
            function generateSecretKey() {
                return bin2hex(random_bytes(32)); // Generate a 256-bit key (32 bytes)
            }
            
            // Example generated secret key (in practice, this should be securely shared/stored)
            $secret = 'your_previously_shared_secret_key';
            
            // Function to decrypt the received data
            function decrypt($data, $secret) {
                list($iv, $encryptedData) = explode(':', $data);
                $iv = hex2bin($iv);
                $encryptedData = hex2bin($encryptedData);
                $decrypted = openssl_decrypt($encryptedData, 'aes-256-cbc', hex2bin($secret), OPENSSL_RAW_DATA, $iv);
                return $decrypted;
            }
            
            // Get the POST body
            $rawData = file_get_contents('php://input');
            $data = json_decode($rawData, true);
            
            if (isset($data['data'])) {
                try {
                    $decryptedData = decrypt($data['data'], $secret);
                    $event = json_decode($decryptedData, true);
            
                    // Process the event
                    handleEvent($event);
            
                    // Send a success response
                    http_response_code(200);
                    echo 'Event received successfully';
                } catch (Exception $e) {
                    // Handle error
                    error_log('Error processing event: ' . $e->getMessage());
            
                    // Send an error response
                    http_response_code(500);
                    echo 'Error processing event';
                }
            } else {
                // Send a bad request response
                http_response_code(400);
                echo 'Invalid request';
            }
            
            // Function to handle the event
            function handleEvent($event) {
                // Your event handling logic here
                error_log('Event received: ' . print_r($event, true));
            }
            ?>
        `
    }
}

export default SupplierWebhookShowIntegration;