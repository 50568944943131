import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Project} from "../../../../../models/project";
import * as api from "../../../../../adapters/corporate/provider/project"
import Loader from "../../../../../utils/loader";
import ProjectStatusPlaceholder from "../../../common/project/status";
import ProviderProjectQuoteList from "../../quote/list";
import ProjectLaunch from "../../../common/project/launch";
import convertToDecimal from "../../../../../utils/currencyConverter";
import ErrorCard from "../../../../error";

const ProviderProjectShow = () => {

    const {id} = useParams()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [project, setProject] = useState<Project>()
    const [publishing, setPublishing] = useState(false);

    useEffect(() => {
        setLoading(true)
        api.show(Number(id))
            .then(r => setProject(r.data))
            .catch(error => setError(error))
            .finally(() => setLoading(false));
    }, [id])

    function updateStatus(status: "Pending"|"Published"|"Canceled"){
        setPublishing(true);
        api.update(Number(id), {status: status})
            .then(r => setProject({...project, ...r.data}))
            .catch(error => console.log(error))
            .finally(() => setPublishing(false))
    }

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!project){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <h6>
                    Project not found.
                </h6>
            </div>
        </div>
    }

    return <div className={'container-fluid'}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row align-items-center">
                <div className="col">
                    <ol className="breadcrumb mb-3 fs-xs">
                        <li className="breadcrumb-item">
                            <Link to="/corporate/provider/project">Appels d'offres</Link>
                        </li>
                        <li className="breadcrumb-item">
                            {project.title}
                        </li>
                    </ol>
                </div>
                <div className="col-auto ms-auto">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <ProjectStatusPlaceholder status={project.status} />
                        </div>
                        {project.status === "Pending" && <div className="col-auto">
                            <button disabled={publishing} className={'btn btn-xxs btn-light'} onClick={() => updateStatus("Published")}>
                                Publier <i className={'bi bi-arrow-right'}></i>
                            </button>
                        </div>}
                        {project.status === "Published" && <div className="col-auto">
                            <button disabled={publishing} className={'btn btn-xxs btn-light'} onClick={() => updateStatus("Pending")}>
                                Repasser en brouillon <i className={'bi bi-arrow-right'}></i>
                            </button>
                        </div>}
                        <div className="col-auto">
                            <button className="btn btn-xxs btn-light">
                                <i className={'bi bi-pencil text-info'}></i> Modifier
                            </button>
                        </div>
                        {["Pending", "Published"].includes(project.status) && <div className="col-auto">
                            <button className="btn btn-xxs btn-light" disabled={publishing}
                                    onClick={() => updateStatus("Canceled")}>
                                <i className={'bi bi-x text-danger'}></i> Annuler
                            </button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Détails du projet
                    </h6>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-text">
                                Sécteur
                            </div>
                            <p>
                                {project.sector.name}
                            </p>
                        </div>
                        <div className="col-md-4">
                            <div className="form-text">
                                Démarrage
                            </div>
                            <p>
                                <ProjectLaunch p={project} />
                            </p>
                        </div>
                        <div className="col-md-4">
                            <div className="form-text">
                                Localisation
                            </div>
                            <p>
                                {project.city}, {project.postalCode}, {project.country.name}
                            </p>
                        </div>
                        <div className="col-md-4">
                            <div className="form-text">
                                Budget
                            </div>
                            <p>
                                {convertToDecimal(project.condition.estimateAmount, project.currency)}
                            </p>
                        </div>
                        <div className="col-md-4">
                            <div className="form-text">
                                Devise
                            </div>
                            <p>
                                {project.currency.symbol} ({project.currency.name})
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Devis
                    </h6>
                </div>
                <div className="card-body">
                    <ProviderProjectQuoteList project={project} />
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <div className="card border border-1">
                <div className="card-header">
                    <h6>
                        Descriptif du projet
                    </h6>
                </div>
                <div className="card-body">
                    <div dangerouslySetInnerHTML={{ __html: project.description }} />
                </div>
            </div>
        </div>
    </div>

}

export default ProviderProjectShow;