
export const getAttributionLabel = (type: "first"|'last'|'share') => {
    switch (type){
        case "first":
            return "Premier click";
        case "last":
            return "Dernier click"
        case "share":
            return 'Partage (Recommandé)'
    }
}

export const getUrlTypeLabel = (type: "SEO"|'PARAMETER'|'REDIRECT') => {
    switch (type){
        case "SEO":
            return "SEO";
        case "PARAMETER":
            return "Paramètre"
        case "REDIRECT":
            return 'Redirection'
    }
}