import React, {useEffect, useState} from "react";
import {Currency} from "../../../models/currency";
import * as api from "../../../adapters/quote";
import Loader from "../../../utils/loader";
import convertToDecimal from "../../../utils/currencyConverter";
import {Quote} from "../../../models/quote";
import ErrorCard from "../../error";

const QuoteSummary = ({quote}: {quote: Quote}) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const [summary, setSummary] = useState<{
        total_amount_excluding_tax: number,
        total_tax_amount: number,
        total_amount_including_tax: number,
        currency: Currency
    }>();

    useEffect(() => {
        setLoading(true)
        api.summary(quote.uuid)
            .then(r => setSummary(r.data))
            .catch(error => setError(error))
            .finally(() => setLoading(false))
    }, [quote.id])

    if (loading){
        return <div className={'col-12 text-center'}>
            <Loader /> Chargement en cours
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!summary){
        return <div className={'col-12'}>
            <i className={'bi bi-exclamation-triangle'}></i> Impossible de charger le récapitulatif du devis
        </div>
    }

    return <div className="table-responsive">
        <table className={"table"}>
            <tbody>
            <tr>
                <th>
                    Total HT
                </th>
                <td>
                    {convertToDecimal(summary.total_amount_excluding_tax, summary.currency)}
                </td>
            </tr>
            <tr>
                <th>
                    Taxes
                </th>
                <td>
                    {convertToDecimal(summary.total_tax_amount, summary.currency)}
                </td>
            </tr>
            <tr>
                <th>
                    Total TTC
                </th>
                <td>
                    {convertToDecimal(summary.total_amount_including_tax, summary.currency)}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
}

export default QuoteSummary;