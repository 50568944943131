import {useState} from "react";
import {Invoice} from "../../../../../models/invoice";
import * as invoiceApi from "../../../../../adapters/corporate/common/invoice";
import * as fileSaver from "file-saver";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../error";

const CorporateInvoiceDownload = ({invoice}: {invoice: Invoice}) => {

    const [downloading, setDownloading] = useState<boolean>(false);
    const [error, setError] = useState()
    function downloadInvoice(){
        setDownloading(true)
        invoiceApi.download(invoice.id)
            .then(r => {
                // Convert the array of numbers (buffer) to Uint8Array
                const uint8Array = new Uint8Array(r.data.data);
                // Create a Blob from the Uint8Array
                const blob = new Blob([uint8Array], { type: 'application/pdf' });

                // Use FileSaver.js to save the Blob as a file
                fileSaver.saveAs(blob, `${invoice.number}.pdf`);
            }).catch((error) => setError(error))
            .finally(() => setDownloading(false))
    }

    if (error){
        return <ErrorCard error={error} />
    }

    return <button onClick={() => downloadInvoice()} className={'btn btn-light btn-xxs'}>
        {downloading ? <Loader size={"sm"} /> : <><i className={'bi bi-download'}></i> Télécharger la facture</>}
    </button>
}

export default CorporateInvoiceDownload;