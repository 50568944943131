import ProviderProposalAdd from "../../../../../components/corporate/provider/proposal/add";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import * as api from '../../../../../adapters/corporate/provider/assignment';
import {useQuery} from "../../../../../utils/useQuery";
import Loader from "../../../../../utils/loader";
import ErrorCard from "../../../../../components/error";
import {Assignment} from "../../../../../models/assignment";

const ProviderProposalAddPage = () => {

    const query = useQuery();
    const navigate = useNavigate();
    const [assignment, setAssignment] = useState<Assignment>()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {

        const assignment_id = query.get('assignment_id')

        if (assignment_id){
            api.show(Number(assignment_id))
                .then(r => setAssignment(r.data))
                .catch(e => setError(e))
                .finally(() => setLoading(false))
        }else{
            setLoading(false)
        }

    }, [])

    if (loading){
        return <div className={'h-100 w-100 d-flex align-items-center'}>
            <div className="col-auto mx-auto">
                <Loader />
            </div>
        </div>
    }

    if (error){
        return <ErrorCard error={error} />
    }

    if (!assignment){
        return <></>
    }

    return <div className={'container-fluid'}>
        <div className="col-12 mb-3">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/corporate/provider/assignment">Annonces</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to={`/corporate/provider/assignment/${assignment.id}`}>{assignment.name}</Link>
                </li>
                <li className="breadcrumb-item">
                    soummetre un devis
                </li>
            </ol>
        </div>
        <div className="col-12 mb-3">
            <ProviderProposalAdd assignment={assignment} onSuccess={() => navigate(`/corporate/provider/assignment/${assignment.id}`)} />
        </div>
    </div>
}

export default ProviderProposalAddPage;