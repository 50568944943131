import {Assignment} from "../../../../../../models/assignment";
import React from "react";
import {Link} from "react-router-dom";
import {getLogoUrl} from "../../../../../../utils/image";

const ProviderSearchAssignmentItem = ({item}: {item: Assignment}) => {

    return <div className={'col-12 mb-3'}>
        <Link to={`/corporate/provider/assignment/${item.id}`} target={'_blank'} className="card border border-1 shadow-hover">
            <div className="card-body">
                <div className="row align-items-center">
                    <div className="col-auto">
                        <div className="avatar avatar-lg">
                            <img src={getLogoUrl(item.organization)} alt="" className="avatar-img rounded-circle"/>
                        </div>
                    </div>
                    <div className="col">
                        <h6 className={'mb-1'}>{item.name}</h6>
                        <p className={"text-muted mb-0"}>{item.organization.name}</p>
                    </div>
                </div>
                <table className="table table-borderless">
                    <tbody>
                        <tr>
                            <th>Lieux</th>
                            <td>
                                {item.location}
                            </td>
                        </tr>
                        <tr>
                            <th>Type de rémunération</th>
                            <td>
                                {item.compensation === 'Fee' && <>Comissions</>}
                                {item.compensation === 'Fix' && <>Fix</>}
                                {item.compensation === 'Hybrid' && <>Fix + Commissions</>}
                            </td>
                        </tr>
                        {['Fix', 'Hybrid'].includes(item.compensation) && <tr>
                            <th>Montant du fix</th>
                            <td>Entre {item.fixRangeMin}{item.currency.symbol} et {item.fixRangeMax}{item.currency.symbol} par {item.fixRangeDuration} </td>
                        </tr>}
                        {['Fee', 'Hybrid'].includes(item.compensation) && <tr>
                            <th>Montant des commissions</th>
                            <td>Entre {item.feeRangeMin}{item.currency.symbol} et {item.feeRangeMax}{item.currency.symbol} par {item.feeTrigger}</td>
                        </tr>}
                    </tbody>
                </table>
                <div className="col-md-12">
                    {item.specialisations.slice(0,5).map(s => <span className={'badge bg-light text-dark me-2 mb-2'}>{s.title}</span>)} {item.specialisations.length > 5 && <span className={'badge bg-light text-dark me-2 mb-2'}>+ {item.specialisations.length - 5}</span>}
                </div>
            </div>
        </Link>
    </div>
}

export default ProviderSearchAssignmentItem;