import {useFormik} from "formik";
import * as Yup from 'yup';
import FormError from "../../../../../utils/formError";
import ReactQuill from "react-quill";
import React, {useState} from "react";
import TextAreaComponent from "../../../../form/textArea";
import ErrorCard from "../../../../error";
import Loader from "../../../../../utils/loader";
import * as api from '../../../../../adapters/corporate/provider/service'
import {Service} from "../../../../../models/service";

const ProviderServiceAdd = ({onSuccess}: {onSuccess: (service: Service) => void}) => {

    const [error, setError] = useState(null)

    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            pricing: "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required(),
            description: Yup.string().required(),
            pricing: Yup.string().required(),
        }),
        onSubmit: (values, {setSubmitting}) => {
            setError(null)
            api.add(values)
                .then(r => onSuccess(r.data))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false))
        }
    })

    return <form onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input type="text" className="form-control" name={"name"} id={"name"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                <label htmlFor="name">Nom du service</label>
            </div>
        </div>
        <div className="col-12 mb-3">
            <p className={'mb-1'}>Description du service</p>
            <ReactQuill value={formik.values.description} onChange={value => formik.setFieldValue('description', value)} />
            <FormError form={formik} field={"description"} />
        </div>
        <div className="col-12 mb-3">
            <TextAreaComponent formik={formik} name={'pricing'} maxLength={255} label={"Tarification"} />
            <FormError form={formik} field={"pricing"} />
        </div>
        {error && <ErrorCard error={error} />}
        <button className="btn w-100 btn-light">
            {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-check'}></i> Enregistrer</>}
        </button>
    </form>
}

export default ProviderServiceAdd;