import {get, post} from "../../../xhr";
import encodeQueryData from "../../../../utils/encodeQueryData";

export function list(requestData: {query?: string, offset: number, status: string, project_id?: number, count?: number, customer_id?: number}){
    return get(`/corporate/supplier/quote${encodeQueryData(requestData)}`);
}

export function show(id: number){
    return get(`/corporate/supplier/quote/${id}`);
}

export function summary(id: number){
    return get(`/corporate/supplier/quote/${id}/summary`);
}

export function update(id: number, requestData: {status?: "Pending"|"Published"|"Canceled"|"Validation"}){
    return post(`/corporate/supplier/quote/${id}`, requestData);
}

export function add(requestData: {project_id: number}){
    return post(`/corporate/supplier/quote`, requestData);
}

export function condition(id: number, requestData: {
    expiredAt: Date,
    delay: string,
    deposit: number
}){
    return post(`/corporate/supplier/quote/${id}}/condition`, requestData);
}