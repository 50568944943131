import React from "react";
import {useFormik} from "formik";
import FormError from "../../../../../utils/formError";
import * as Yup from "yup"
import * as api from "../../../../../adapters/corporate/provider/shop";
import Loader from "../../../../../utils/loader";
import {Link, useNavigate} from "react-router-dom";

const ProviderShopAdd = () => {

    const navigate = useNavigate()

    const formik = useFormik({
       initialValues: {
           name: '',
       },
       validationSchema: Yup.object().shape({
           name: Yup.string().required(),
       }),
       onSubmit: (values) => {
           api.add(values)
               .then(resp => navigate(`/corporate/provider/shop/${resp.data.id}`))
       }
    });

    return <div className={"container-fluid"}>
        <div className="col-12 mb-3 flex-grow-0">
            <ol className="breadcrumb mb-3 fs-xs">
                <li className="breadcrumb-item">
                    <Link to="/corporate/provider/shop">Boutique en ligne</Link>
                </li>
                <li className="breadcrumb-item">
                    Ajouter
                </li>
            </ol>
        </div>
        <div className="p-3">
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="text" className="form-control" id="name" name={"name"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        <label htmlFor="name">Label *</label>
                    </div>
                    <FormError form={formik} field={"name"} />
                </div>
                <button disabled={formik.isSubmitting} type={'submit'} className={"btn btn-light w-100"}>
                    {formik.isSubmitting ? <Loader size={'sm'} /> : <><i className={'bi bi-plus-circle'}></i> Ajouter</>}
                </button>
            </form>
        </div>
    </div>
}

export default ProviderShopAdd;